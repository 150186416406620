<template>
    <div class="card-body user-list container-fluid pb-3">
        <PaginationThm v-model="route.query.pageIndex" :totalRecords="authorStore.totalRows" :pageSize="pageSize" @page="onPage" class="mb-2" />
        <!--<Paginator :totalRecords="authorStore.totalRows" :rows="pageSize" @page="onPage($event)" v-show="authorStore.authors.length" />-->

        <div class="text-center" v-if="authorStore.authors == null"><SpinnerThm class="spinner" size="3em" /></div>
        <p class="fst-italic text-muted ps-1 pt-4" v-else-if="Object.keys(route.query).length && !authorStore.authors.length">No authors found matching criteria.</p>

        <table class="table table-striped" v-else>
            <thead>
                <tr>
                <th scope="col">&nbsp;</th>
                <!--<th scope="col">Rank</th>-->
                <th scope="col" class="d-none d-md-table-cell">Type</th>
                <th scope="col" class="d-none d-md-table-cell text-center">Posts</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="author in authorStore.authors" :key="author.authorId" v-bind:class="{ active: route.params.authorId == author.authorId }">
                    <td scope="row">
                        <Button label="Edit" class="btn-edit me-2" @click="editUser(author.authorId)" />
                        <a href="#" @click="editUser(author.authorId)">
                            <span class="preview-image" v-bind:style="{ backgroundImage: 'url(' + authorStore.getAvatar(author) + ')' }"></span>
                        </a>
                        <a href="#" @click="editUser(author.authorId)" class="ps-2">{{ getAuthorName(author) }}</a>
                    </td>
                    <!--
                    <td v-if="author.rank > 0" class="d-none d-sm-tabel-cell">
                        {{ author.rank }}
                    </td>
                    -->
                    <td class="d-none d-md-table-cell">
                        <span v-if="author.authorTypeName == 'NotSet'">
                            &mdash;
                        </span>
                        <span v-else>
                            {{ author.authorTypeName }}
                        </span>
                    </td>
                    <td class="d-none d-md-table-cell text-center">
                        {{ shortenNumber(author.postCount) }}
                    </td>
                </tr>
            </tbody>
        </table>

        <!--
        <div class="row border-bottom pb-2 mb-1">
            <div class="col d-none d-md-block"></div>

            <div class="col-md-auto d-none d-sm-block fw-bold text-end" style="width: 100px;">Rank</div>
            <div class="col-md-auto d-none d-sm-block fw-bold text-center" style="width: 100px;" v-if="appSettings.features.authors.authorTypeFieldRole">Type</div>
        </div>

        <p class="fst-italic text-muted ps-1 pt-4" v-show="Object.keys(route.query).length && !authorStore.authors.length">No authors found matching criteria.</p>

        <div v-for="author in authorStore.authors" :key="author.authorId" class="row author item pt-2" v-bind:class="{ active: route.params.authorId == author.authorId }">
            <div class="col-md-auto d-none d-md-block">
                <Button label="Edit" class="btn-edit" @click="editUser(author.authorId)" />
            </div>
            <div class="col ps-1">
                <a href="#" @click="editUser(author.authorId)">
                    <span class="preview-image" v-bind:style="{ backgroundImage: 'url(' + authorStore.getAvatar(author) + ')' }"></span>
                </a>
                <a href="#" @click="editUser(author.authorId)" class="ps-2">{{ getAuthorName(author) }}</a>
            </div>
            <div class="col-md-auto d-none d-sm-block">
                <span v-if="author.rank > 0">
                    {{ author.rank }}
                </span>
            </div>
            <div class="col-md-auto d-none d-sm-block" v-if="appSettings.features.authors.authorTypeFieldRole">
                {{ author.authorTypeName }}
            </div>
        </div>
        -->

        <!--<Paginator :totalRecords="authorStore.totalRows" :rows="pageSize" @page="onPage($event)" />-->
        <PaginationThm v-model="route.query.pageIndex" :totalRecords="authorStore.totalRows" :pageSize="pageSize" @page="onPage($event)" class="mt-3" />
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { inject, onMounted, watch } from 'vue';
import { useAuthorStore } from '@/stores/AuthorStore';
import { shortenNumber } from '@/utils/ThmStringUtils';
import PaginationThm from '@/components/PaginationThm.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';

export default {
    name: 'AuthorsList',
    components: {
        PaginationThm,
        SpinnerThm,
    },
    setup() {
        const appSettings = inject('appSettings');
        const authorStore = useAuthorStore();
        const router = useRouter();
        const route = useRoute();
        const pageSize = 15;

        // Watch for route changes
        watch(
            // () => route.query, (params, fromParams) => {
            () => route.query, async (params) => {
                if (console.log.hide) console.log({ params });
                await authorStore.getAuthorsPage(params).then((res) => {
                    if (console.log.hide) console.log('res', res);
                    //currentPage.value = params.pageIndex || 0;
                });
            }
        );

        // Mounted
        onMounted(() => {
            authorStore.getAuthorsPage(route.query).then((res) => {
                if(console.log.hide) console.log('res', res);
            });
        });

        function editUser(authorId) {
            router.push({ path: `/settings/authors/${authorId}`, query: route.query });
        }

        function getAuthorName(author) {
            if(!author || typeof author != 'object') return null;
            if(author.fullName && author.fullName.trim()) {
                return author.fullName.trim();
            } else {
                return author.nickName;
            }
        }

        function onPage(e) {
            router.push({ query: { ...route.query, pageIndex: e.page } });
            authorStore.getAuthorsPage({ ...route.query, pageIndex: e.page });
        }

        return {
            appSettings,
            authorStore,
            editUser,
            getAuthorName,
            onPage,
            pageSize,
            shortenNumber,
            route,
        };
    },
};
</script>

<style lang="scss" scoped>
.user-list {
    /*
    .row.author {
        padding-bottom: 0.5em;

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }
    }
    */
    table {
        tr.active td {
                background-color: var(--bluegray-100);
        }
    }

    .preview-image {
        background: no-repeat center center / cover;
        display: inline-block;
        vertical-align: middle;
        width: 42px;
        height: 42px;
    }
}
</style>
