<template>
    <ConfirmDialog />
    <Breadcrumbs :post="post" :currentPage="route.params.postId ? 'Settings' : 'Add New'" class="mb-2" />

    <div class="row mb-3 liveblog-posts">
        <div class="col-sm-12 col-md-12">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">

                            <div class="mb-3">
                                <label for="Title" class="form-label fw-500 required">Title</label>
                                <InputText type="text" class="form-control" v-model="post.title" id="Title"/>
                            </div>

                            <div class="mb-3" v-if="postSlug">
                                <label for="UrlSlug" class="form-label required">URL Slug</label>
                                <div class="p-inputgroup">
                                    <Button @click="modifySlug = !modifySlug" :label="modifySlug ? 'Save' : 'Edit'" :disabled="!userHasAccessToRole('Admin')" />
                                    <InputText :disabled="!modifySlug" type="text" v-model="postSlug" placeholder="" id="UrlSlug" :class="{ 'modify-disabled': !modifySlug }" style="border-radius: 0;" />
                                    <span class="p-inputgroup-addon">-n{{ post.postId }}</span>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="Description" class="form-label fw-500">Description</label>
                                <InputText type="text" class="form-control" v-model="post.description" id="Description"/>
                                <div class="help">This field is used as the description for search engine results as well as social media sharing.</div>
                            </div>

                            <div class="mb-3" style="max-width: 300px">
                                <ImageLibrary v-model="post.imageObject" />
                            </div>

                            <div class="mb-2 row">
                                <div class="col-auto">
                                    <InputSwitch v-model="post.showComments" id="ShowComments" />
                                </div>
                                <label for="ShowComments" class="col-auto form-label">Show Comments</label>
                            </div>

                            <div class="mb-2 row">
                                <div class="col-auto">
                                    <InputSwitch v-model="post.showHighlightedStories" id="ShowHighlightedStories" />
                                </div>
                                <label for="ShowHighlightedStories" class="col-auto form-label">Show Highlighted Stories Widget</label>
                            </div>

                            <div class="mb-2 row">
                                <div class="col-auto">
                                    <InputSwitch v-model="post.showTimeline" id="ShowTimeline" />
                                </div>
                                <label for="ShowTimeline" class="col-auto form-label">Show Timeline</label>
                            </div>

                            <div class="mb-1 row">
                                <div class="col-auto">
                                    <InputSwitch v-model="status" id="Published" />
                                </div>
                                <label for="Published" class="col-auto form-label">Published</label>
                            </div>

                            <div class="row mb-4">
                                <div class="col-sm-12 col-lg-6 mt-2">
                                    <label for="DatePublished" class="form-label fw-500 required d-block">Publish Date/Time</label>
                                    <Calendar v-model="datePublished" :showButtonBar="false" :showIcon="true" :showTime="true" dateFormat="mm/dd/yy" hourFormat="12" placeholder="Date" id="DatePublished" />
                                </div>
                                <div class="col-sm-12 col-lg-6 mt-2">
                                    <label for="DateExpires" class="form-label fw-500 d-block">End Date/Time</label>
                                    <Calendar v-model="dateExpires" :showButtonBar="true" :showIcon="true" :showTime="true" dateFormat="mm/dd/yy" hourFormat="12" placeholder="Date" id="DateExpires" @today-click="todayClick" />
                                </div>
                            </div>

                            <p class="mt-3">
                                <Button label="Save" class="me-2" @click="save()" />
                                <Button label="Delete" class="me-2" severity="danger" v-show="route.params.postId && userHasAccessToRole('Admin')" @click="deletePost" />
                                <SplitButton label="Preview" :model="previewMenu" @click="preview" severity="secondary" outlined class="me-2" v-if="post.postId" />
                                <Button icon="fa-regular fa-message-dots" label="Threads" severity="secondary" outlined @click="router.push({ name: 'LiveBlogThread', params: { postId: post.postId } })" class="me-2" v-if="post.postId" />
                            </p>
                        </div>

                        <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                                <label for="VideoEmbed" class="form-label fw-500">Video Embed</label>
                                <Textarea v-model="post.videoEmbed" :autoResize="true" rows="3" class="form-control" id="VideoEmbed" />
                            </div>

                            <div class="mb-3" v-if="post.videoEmbed" v-html="post.videoEmbed"></div>

                            <Panel class="mb-0" header="Advanced Settings" :toggleable="true" :collapsed="true">
                                <div class="mb-3" v-if="post.showTimeline">
                                    <label for="TimelineHeading" class="form-label fw-500">Timeline Heading/Title</label>
                                    <InputText v-model="post.extendedProperties.timelineHeading" class="form-control" id="TimelineHeading" placeholder="Key moments, highlights and more..." />
                                </div>

                                <div class="mb-3">
                                    <label for="BackgroundDesktop" class="form-label">Background Image &ndash; Desktop</label>
                                    <IconField iconPosition="left">
                                        <InputIcon>
                                            <i class="pi pi-link" />
                                        </InputIcon>
                                        <InputText type="url" class="w-100" id="BackgroundDesktop" placeholder="https://" v-model="post.backgroundDesktop" />
                                    </IconField>
                                </div>

                                <div class="mb-3">
                                    <label for="TitleColorDesktop" class="form-label d-block">Title Color &ndash; Desktop</label>
                                    <ColorPickerThm v-model="post.extendedProperties.titleColorDesktop" x-inputId="TitleColorDesktop" />
                                </div>

                                <div class="mb-3">
                                    <label for="BackgroundMobile" class="form-label">Background Image &ndash; Mobile</label>
                                    <IconField iconPosition="left">
                                        <InputIcon>
                                            <i class="pi pi-link" />
                                        </InputIcon>
                                        <InputText type="url" class="w-100" id="BackgroundMobile" placeholder="https://" v-model="post.backgroundMobile" />
                                    </IconField>
                                </div>

                                <div class="mb-3">
                                    <label for="TitleColorMobile" class="form-label d-block">Title Color &ndash; Mobile</label>
                                    <ColorPickerThm v-model="post.extendedProperties.titleColorMobile" x-inputId="TitleColorMobile" />
                                </div>

                                <div class="mb-3">
                                    <label for="BlockCopyIdDesktop" class="form-label fw-500 d-block">
                                        Block Copy ID &ndash; Desktop
                                        <router-link :to="{ name: 'BlockCopy' }" class="label-link" target="_blank" title="Open Block Copy in new tab"><i class="pi pi-external-link"></i></router-link>
                                    </label>
                                    <InputNumber type="text" v-model="post.blockCopyIdDesktop" id="BlockCopyIdDesktop"/>
                                </div>

                                <div class="mb-3">
                                    <label for="BlockCopyIdMobile" class="form-label fw-500 d-block">
                                        Block Copy ID &ndash; Mobile
                                        <router-link :to="{ name: 'BlockCopy' }" class="label-link" target="_blank" title="Open Block Copy in new tab"><i class="pi pi-external-link"></i></router-link>
                                    </label>
                                    <InputNumber type="text" v-model="post.blockCopyIdMobile" id="BlockCopyIdMobile"/>
                                </div>
                            </Panel>
                        </div>
                    </div>

                    <div class="enddate-notice rounded py-1 px-2" v-show="!dateExpires">
                        Setting an <strong>End Date/Time</strong> is <em>highly</em> recommended, even if it's later than the expected end date/time. If you're not sure, try setting an expiration date a few hours or a day after the time you roughly plan to end the Live Blog.
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
//import { ref, computed, inject, onMounted } from 'vue';
//import { inject, onMounted } from 'vue';
import { ref, computed, onBeforeMount } from 'vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useRoute, useRouter } from 'vue-router';
import { getPermalink } from '@/utils/ThmLiveBlogUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { seoUrl } from '@/utils/ThmUtils';
import LiveBlogService from '@/service/LiveBlogService';
import ColorPickerThm from '@/components/ColorPickerThm.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';
import AdminService from '@/service/AdminService';
import InputNumber from 'primevue/inputnumber';
import Breadcrumbs from './_Breadcrumbs.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'LiveBlog',
    components: {
        Breadcrumbs,
        ColorPickerThm,
        ConfirmDialog,
        ImageLibrary,
        InputNumber,
    },
    setup() {
        const liveBlogService = new LiveBlogService();
        const adminService = new AdminService();
        const confirm = useConfirm();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const { user } = useAuth0();

        const modifySlug = ref(false);
        const original = ref({});

        const post = ref({
            datePublished: new Date(),
            dateModified: new Date(),
            dateCreated: new Date(),
            imageObject: '',
            userId: user.value.name,
            showComments: true,
            showHighlightedStories: true,
            extendedProperties: {
                timelineHeading: 'Key moments, highlights and more...',
                titleColorDesktop: '#000000',
                titleColorMobile: '#000000'
            }
        });
        const previewMenu = [
            {
                label: 'Desktop',
                command: () => {
                    let url = getPermalink(post.value, true);
                    window.open(url, '_preview');
                }
            },
            {
                label: 'Mobile',
                command: () => {
                    let url = getPermalink(post.value, true);
                    window.open(url, '_preview', 'width=375,height=700');
                }
            },
        ];

        onBeforeMount(async () => {
            if(route.params.postId) {
                post.value = await liveBlogService.getPost(route.params.postId);
                original.value = { ...post.value };
            }
        })

        const datePublished = computed({
            get() {
                return post.value.datePublished == null ? null : new Date(post.value.datePublished);
            },
            set(newValue) {
                post.value.datePublished = newValue ? newValue.toLocaleString('en-US') : newValue;
            },
        });
        const dateExpires = computed({
            get() {
                return post.value.dateExpires == null ? null : new Date(post.value.dateExpires);
            },
            set(newValue) {
                post.value.dateExpires = newValue ? newValue.toLocaleString('en-US') : newValue;
            },
        });
        const status = computed({
            get() {
                return post.value.status == 1 ? true : false;
            },
            set(newValue) {
                post.value.status = newValue == 1 ? true : false;
            },
        });
        const postSlug = computed({
            get() {
                return post.value.urlSlug;
            },
            set(newValue) {
                if(newValue.trim()) post.value.urlSlug = seoUrl(newValue);
            },
        });

        function todayClick(d) {
            if(console.log.hide) console.log(d);
            dateExpires.value = formatDateTime(null, 'MM/dd/yyyy hh:mm a');
        }

        function preview() {
            let url = getPermalink(post.value, true);
            window.open(url, '_preview');
        }

        async function save() {
            let isNew = !post.value.postId;
            let p = { ...post.value };

            // Set status and slug
            p.status = post.value.status ? 1 : 0;
            if(!p.urlSlug) p.urlSlug = seoUrl(p.title);

            // Check required fields
            var requiredFields = [ post.value.title, post.value.datePublished ], isFormInvalid = false;
            requiredFields.forEach((elm) => {
                if (!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            // Save post
            try {
                let result = await liveBlogService.savePost(p);
                post.value = result;

                // Log it
                adminService.log({ logTypeId: 21, referenceId: post.value.postId, message: (isNew ? 'Added: ' : 'Modified: ') + post.value.title, content: JSON.stringify(result, null, 2), original: isNew ? null : JSON.stringify(original.value, null, 2), language: 'json' });
                original.value = { ...result };

                toast.add({ severity: 'success', summary: 'Success', detail: 'Post successfully saved', life: 3000 });
                if(isNew) router.push({ name: 'LiveBlogPost', params: { postId: post.value.postId } });
            } catch (err) {
                toast.add({ severity: 'error', summary: 'Error', detail: err.message || err, life: 5000 });
            }
        }

        async function deletePost() {
            confirm.require({
                message: 'Are you sure you want to delete this live blog post?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    //toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Post deleted', life: 3000 });
                    await liveBlogService.deletePost(post.value.postId);
                    adminService.log({ logTypeId: 21, referenceId: post.value.postId, message: 'Delete: ' + post.value.title, content: null, original: JSON.stringify(post.value, null, 2), language: 'json' });
                    router.push({ name: 'LiveBlog' });
                },
                reject: () => {
                    //toast.add({ severity: 'warn', summary: 'Rejected', detail: 'Delete cancelled', life: 3000 });
                },
            });
        }

        return {
            datePublished,
            dateExpires,
            deletePost,
            modifySlug,
            post,
            postSlug,
            preview,
            previewMenu,
            route,
            router,
            save,
            status,
            todayClick,
            userHasAccessToRole,
        };
    },
};
</script>
