import AdminService from '@/service/AdminService';
import { getFilenameExtension } from '@/utils/ThmStringUtils';

/**
 * Returns supported attachment (non-image) MIME types
 */
export function getAttachmentMimeTypes(prop = null) {
    let mimeTypes = [
        {
            mime: 'application/pdf',
            ext: 'pdf'
        }
    ];

    if(prop) {
        let result = mimeTypes.map((item) => item[prop]);
        return result;
    }

    return mimeTypes;
}

/**
 * Returns the image URL prefix by license ID
 * @param {Number} licenseId - The license ID of the image
 * @example
 * // returns: https://media.townhall.com/cdn/hodl/
 * getImagePrefixByLicenseId(9);
 */
/*
export function getImagePrefixByLicenseId(licenseId, path = '') {
    switch(licenseId) {
        case 11:
            return 'https://media.townhall.com/cdn/hodl/tw/' + path;
        default:
            return 'https://media.townhall.com/cdn/hodl/' + path;
    }
}
*/

/**
 * Returns the VIP/VIP Gold image based on the provided subscription level
 * @param {Number} subscriptionLevel The subscription level (integer) for which to retrieve associated image
 * @returns {String} VIP badge image URL
 * @example
 * // returns: "https://cdn.townhall.com/web/thm/subscriptions-badge-vipgold.svg"
 * getVipBadge(8);
 */
export function getVipBadge(subscriptionLevel) {
    if(subscriptionLevel > 1) {
        const adminService = new AdminService();
        let subscriptionTypes = adminService.getSubscriptions();
        var level = subscriptionTypes.find(obj => obj.value == subscriptionLevel);
        //return level ? `https://admin.townhall.com/support/resources/img/th-${level.label.replace(' ', '')}.svg` : null;
        return level ? `https://cdn.townhall.com/web/thm/subscriptions-badge-${level.label.toLowerCase().replace(' ', '')}.svg` : null;
    }
    return null;
}

/**
 * Resize an image URL by adding dimensions to end of filename.
 * @param {string} url - The fully qualified image URL
 * @param {string} size - The desired image dimensions. Example: 200x200
 * @returns {string} Resized image URL
 * @example
 * // returns: https://media.townhall.com/cdn/hodl/2020/100/image.name-200x200.jpg
 * resizeImageUrl('https://media.townhall.com/cdn/hodl/2020/100/image.name.jpg', '200x200');
 */
export function resizeImageUrl(url, size) {
    if(!url || !size) return url;

    // Resize image
    switch(true) {
        // Resize image if it already contains dimensions
        case url.match(/(-\d+x\d+\.)/g) != null:
            url = url.replace(/(-\d+x\d+\.)/g,  `-${size}.`);
            break;
        // Resize image if full-size image provided
        case url.includes('cdn/hodl/') || url.includes('reu/ha/'): {
            /*
            // This doesn't work if there is a period in the filename
            let filename = url.split('/').pop().split('#')[0].split('?')[0];
            url = url.replace(filename, filename.replace('.', `-${size}.`));
            */

            // Replace the last period (before the extension) in the URL: https://stackoverflow.com/a/5497333/3799374
            var lastPeriodPosition = url.lastIndexOf('.');
            url = url.substring(0, lastPeriodPosition) + `-${size}.` + url.substring(lastPeriodPosition+1)
            break;
        }

        default: {
            return url;
        }
    }

    return url;
}

/**
 * If attachment type (PDF), return corresponding MIME type image, elseif image proceed to resize
 * @param {string} url - The fully qualified image URL
 * @param {string} size - The desired image dimensions. Example: 200x200
 * @param {bool} size - If true and if attachment, return the attachment URL rather than the MIME type image
 * @returns {string} Resized image URL
 * @example
 * // returns: /images/mime/pdf.png
 * getMimeOrResizedImage('https://media.townhall.com/cdn/hodl/2020/100/filename.pdf', '640x480');
 * // returns: https://media.townhall.com/cdn/hodl/2020/100/filename.pdf
 * getMimeOrResizedImage('https://media.townhall.com/cdn/hodl/2020/100/filename.pdf', '640x480', true);
 */
export function getMimeOrResizedImage(url, size, original = false) {
    // If PDF, return MIME image
    let _ext = getFilenameExtension(url);
    const attachmentTypes = getAttachmentMimeTypes('ext');
    if(attachmentTypes.includes(_ext)) {
        if(original) {
            return url;
        } else {
            return `/images/mime/${_ext}.png`;
        }
    }

    // If not attachment type, pass through to resizer
    return resizeImageUrl(url, size);

}

/**
 * Checks if provided description contains strings in parenthesis, returns last match if greater in length than 5
 * @param {string} desc - The image description to parse
 * @returns {string} Resized image URL
 * @source https://plainenglish.io/blog/how-to-use-regular-expression-to-get-strings-between-parentheses-with-javascript-baf65ebf8d66
 * @example
 * // returns: Credit Goes Here
 * getMimeOrResizedImage('This is (a) test image (Credit Goes Here)');
 * // returns: null
 * getMimeOrResizedImage('This is a test image');
 */
export function getCaptionFromDescription(desc) {
    if(!desc) return null;
    let result = null;
    let matches = [...desc.matchAll(/\(([^)]+)\)/g)].flat();
    if(matches.length) {
        result = matches.pop();
        if(result.length <= 5) return null;
    }
    return result;
}

export default {
    //getImagePrefixByLicenseId,
    getAttachmentMimeTypes,
    getCaptionFromDescription,
    getMimeOrResizedImage,
    getVipBadge,
    resizeImageUrl
};
