import axios from 'axios';

export default class MediaService {
    // Example: uploadMediaObject('podcasts/10', 'filename.mp3') => https://cdn.townhall.com/podcasts/10/YYYY-MM/filename.mp3
    // See: https://townhallmedia.visualstudio.com/th2/_git/th2?path=%2FSource%2FUI%2FWeb%2FMvc%2FSupport%2FResources%2Fjs%2Fpodcasts%2Fepisodes.js&version=GBmaster&_a=contents
    async uploadMediaObject(path, file) {
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/mediaobject/${path}/upload`;
        var formData = new FormData();
        formData.append(file.name, file);
        return await axios.post(endpoint, formData).then((res) => res.data);
    }

    // License ID: 2, UploadUniversal - Any Roles
    async uploadUniversalImage(file) { //transient deleted after 60 days
        // https://media.townhall.com/cdn/hodl/images/2022/213/7f396b3f-bfae-4824-85ef-bdda8d3691f6.png
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/thmassetsobject/universal`;
        var formData = new FormData();
        formData.append(file.name, file);
        return await axios.post(endpoint, formData).then((res) => res.data);
    }
    async deleteUniversalImage(path) {
        return await axios.delete('https://thm-web-media.azurewebsites.net/v1/thmassetsobject/universal', { params: { path: path } }).then((res) => res.data);
    }

    // License ID: 3, UploadUniversalPerm - "Image-Library-Trusted" Role
    async uploadUniversalImagePerm(file) {  //permanant never is deleted
        // https://media.townhall.com/cdn/hodl/images/up/2022/213/95cbd0c0-0624-4f65-8a0e-0472ded1d034.png
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/thmassetsobject/universalperm`;
        //var endpoint = `https://localhost:8950/v1/thmassetsobject/universalperm`;
        var formData = new FormData();
        formData.append(file.name, file);
        return await axios.post(endpoint, formData).then((res) => res.data);
    }
    async deleteUniversalImagePerm(path) {
        return await axios.delete('https://thm-web-media.azurewebsites.net/v1/thmassetsobject/universalperm', { params: { path: path } }).then((res) => res.data);
    }

    // License ID: 9, ApUpload - "Image-Library-Admin" Role
    async uploadApImage(file) { //permanant never is deleted
        // https://media.townhall.com/cdn/hodl/2022/213/146bc2fb-54ad-41f8-afa0-834adc820fe6.png
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/thmassetsobject/apimage`;
        var formData = new FormData();
        formData.append(file.name, file);
        return await axios.post(endpoint, formData).then((res) => res.data);
    }
    async deleteApImage(path) {
        return await axios.delete('https://thm-web-media.azurewebsites.net/v1/thmassetsobject/apimage', { params: { path: path } }).then((res) => res.data);
    }

    // License ID: 11, CmsGeneric - "Image-Library-Admin, Image-Library-Trusted" Roles
    async uploadCmsImage(file, licenseId) {
        // https://media.townhall.com/cdn/hodl/2022/213/146bc2fb-54ad-41f8-afa0-834adc820fe6.png
        var endpoint = `https://thm-web-media.azurewebsites.net/v2/thmassetsobject/image/${licenseId}`;
        //var endpoint = `https://localhost:8950/v2/thmassetsobject/image/${licenseId}`;
        var formData = new FormData();
        formData.append(file.name, file);
        return await axios.post(endpoint, formData).then((res) => res.data);
    }
    async deleteCmsImage(path, licenseId) {
        return await axios.delete(`https://thm-web-media.azurewebsites.net/v2/thmassetsobject/image/${licenseId}`, { params: { path: path } }).then((res) => res.data);
        //return await axios.delete(`https://localhost:8950/v2/thmassetsobject/image/${licenseId}`, { params: { path: path } }).then((res) => res.data);
    }

    async uploadImage(file, licenseId) {
        switch(licenseId) {
            case 2:
                return this.uploadUniversalImage(file);
            case 3:
                return this.uploadUniversalImagePerm(file);
            case 9:
                return this.uploadApImage(file);
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 17:
            case 18:
            case 19:
            case 20:
                return this.uploadCmsImage(file, licenseId);
            default:
                return 'Invalid license';
        }
    }

    async deleteImage(path, licenseId) {
        switch(licenseId) {
            case 2:
                return this.deleteUniversalImage(path);
            case 3:
                return this.deleteUniversalImagePerm(path);
            case 9:
                return this.deleteApImage(path);
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 17:
            case 18:
            case 19:
            case 20:
                return this.deleteCmsImage(path, licenseId);
            default:
                return 'Invalid license';
        }
    }

    // Example: deleteMediaObject('https://cdn.townhall.com/podcasts/10/YYYY-MM/filename.mp3')
    // See: https://townhallmedia.visualstudio.com/th2/_git/th2?path=%2FSource%2FUI%2FWeb%2FMvc%2FSupport%2FResources%2Fjs%2Fpodcasts%2Fepisodes.js&version=GBmaster&_a=contents
    async deleteMediaObject(bucket, url) {
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/mediaobject/${bucket}/delete?f=${url}`;
        return await axios.get(endpoint).then((res) => res.data);
    }
    async deleteMediaObjectById(path) {
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/imagesearch/delete?path=${path}`;
        return await axios.delete(endpoint).then((res) => res.data);
    }

    /**
     * Upload an author image
     * @param {object} file - The file to upload
     * @returns {string} https://media.townhall.com/cdn/hodl/authorimages/AAAAAAAA-BBBB-CCCC-DDDD-EEEEEEEEEEEE.jpg
     */
     async uploadAuthorImage(file) {
        var endpoint = 'https://thm-web-media.azurewebsites.net/v2/thmassetsobject/author';
        var formData = new FormData();
        formData.append(file.name, file);
        return await axios.post(endpoint, formData).then((res) => res.data);
    }

    /**
     * Delete an author image
     * @param {string} path - The path of the image to delete
     * @example
     * deleteAuthorImage('authorimages/AAAAAAAA-BBBB-CCCC-DDDD-EEEEEEEEEEEE.jpg');
     */
    async deleteAuthorImage(path) {
        var endpoint = 'https://thm-web-media.azurewebsites.net/v1/thmassetsobject/author';
        return await axios.delete(endpoint, { params: { path: path } }).then((res) => res.data);
    }

    // Add image to Elasticsearch 'image-library' index
    async saveImage(image) {
        return await axios.post('/api/image/elasticsearch', image).then((res) => res.data);
    }

    async imageSearchV1(params) {
        var defaultParams = {
            offset: 0,
            pageSize: 15,
            exactMatch: false,
            strictSortByDate: false,
            dateDecay: 0.9 // Valid values between 0 and 1
        };
        params = Object.assign(defaultParams, params);

        var endpoint = `https://thm-web-media.azurewebsites.net/v1/imagesearch/search?searchText=${params.keywords}&top=${params.pageSize}&skip=${params.offset}&count=true&exactMatch=${params.exactMatch.toString()}`;
        if (params.strictSortByDate) {
            endpoint += '&strictSortByDate=true';
        } else {
            endpoint += `&dateDecay=${params.dateDecay.toString()}`;
        }
        return await axios.get(endpoint).then((res) => res.data);
    }

}
