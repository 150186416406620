<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">VueUse</div>

                    <div class="card-body">
                        <!-- isDefined -->
                        <h4><a href="https://vueuse.org/shared/isDefined/">isDefined</a></h4>

                        <p>
                            Source object: <span class="font-monospace">{{ obj }}</span>
                        </p>

                        <ul>
                            <li>
                                <span class="font-monospace">obj.text</span> is defined and not null: <strong>{{ isDefined(obj.text) }}</strong>
                            </li>
                            <li>
                                <span class="font-monospace">obj.name</span> is defined and not null: <strong>{{ isDefined(obj.name) }}</strong>
                            </li>
                            <li>
                                <span class="font-monospace">obj.greeting</span> is defined and not null: <strong>{{ isDefined(obj.greeting) }}</strong>
                            </li>
                        </ul>

                        <hr />
                        <!-- useMagicKeys -->
                        <h4><a href="https://vueuse.org/core/useMagicKeys/">useMagicKeys</a></h4>

                        <p>useMagicKeys can be used for keyboard shortcuts. See also <a href="https://vueuse.org/core/useKeyModifier/">useKeyModifier</a>.</p>

                        <p>
                            Press <span class="font-monospace">Control+M</span> to toggle: <strong>{{ testState }}</strong>
                        </p>

                        <hr />
                        <!-- useDocumentVisibility -->
                        <h4><a href="https://vueuse.org/core/useDocumentVisibility/">useDocumentVisibility</a> &amp; <a href="https://vueuse.org/shared/watchOnce/">watchOnce</a></h4>

                        <p>useDocumentVisibility could be used to disable some process when the current window/tab is not active/visible. Try switching tabs to toggle the following:</p>
                        <ul>
                            <li>
                                Window state: <span class="fst-italic fw-bold" v-if="windowState">active</span><span v-else><span class="fst-italic fw-bold">disabled</span> (tab was switched at some point)</span>
                            </li>
                        </ul>
                        <p>
                            Reload the page to reset the state. This is an example only. Normally, you'd probably want to toggle on/off as the tab is in and out of focus, but I'm only toggling it once (via <a href="https://vueuse.org/shared/watchOnce/">watchOnce</a>) so you can see that it was
                            disabled when when you come back from switching tabs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue';
import { isDefined, useDocumentVisibility, useMagicKeys, watchOnce, whenever } from '@vueuse/core';

export default {
    name: 'AlertBar',
    setup() {
        const obj = { text: 'Hello world', name: null };
        const testState = ref(false);
        const keys = useMagicKeys();
        const windowState = ref(true);
        const visibility = useDocumentVisibility();

        whenever(keys['Ctrl+M'], () => {
            testState.value = !testState.value; // Toggle testState
        });

        watchOnce(visibility, () => {
            // Triggers only once when window is changed
            windowState.value = false;
        });

        return { isDefined, obj, testState, visibility, windowState };
    },
};
</script>

<style lang="scss" scoped></style>
