import { defineStore } from 'pinia';
import WidgetService from '@/service/WidgetService';
import AdminService from '@/service/AdminService';
import PostService from '@/service/PostService';
import { formatDateTimeISO, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { uuid } from 'vue-uuid';

const postService = new PostService();
const adminService = new AdminService();
const widgetService = new WidgetService();

export const useEditorsChoiceStore = defineStore('EditorsChoice', {
    state: () => ({
        isDirty: false,
        loading: false,
        posts: [],
        postTypes: [],
        settingsId: 2,
        userIdLock: null,
        widgetSettings: {
            postLockStatus: {
                userIdLock: null
            }
        }
    }),
    getters: {
        isLocked() {
            return this.widgetSettings.postLockStatus && this.widgetSettings.postLockStatus.userIdLock;
        }
    },
    actions: {
        async load(settingsId = null, force = false) {
            settingsId = settingsId || this.settingsId;
            // Fetch widget settings
            if(force || !this.widgetSettings) {
                this.loading = true;
                await widgetService.getSettings({ id: settingsId }).then((res) => {
                    this.widgetSettings = res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }

            // Get post types
            if(force || !this.postTypes.length) {
                postService.getPostTypes().then((res) => {
                    this.postTypes = res;
                }).catch((err) => {
                    console.error('Error fetching post types', err);
                });
            }

            // Fetch widget posts
            if(force || !this.posts.length) {
                widgetService.getWidgetPostItems(this.settingsId).then((res) => {
                    this.posts = res;
                    this.posts = this.posts.map(obj => ({ ...obj, uuid: uuid.v4() })); // Add UUID to each post
                    //console.log('this.posts', this.posts);
                }).catch((err) => {
                    console.error('[WidgetStore] Error fetching posts', err);
                });
            }
        },
        async setPostLockStatus(settingsId = 2, user = null) {
            //if(console.log.hide) console.log(settingsId);
            let postLockStatus = { userIdLock: null, connectionId: null }
            if(user) {
                postLockStatus.userIdLock = user.name;
                postLockStatus.connectionId = user.email;
            }

            return await widgetService.setPostLockStatus(settingsId, postLockStatus).then((res) => {
                this.widgetSettings.postLockStatus = res;
                return res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async save() {
            this.loading = true;

            // Abort save if duplicate post IDs found
            let beforeLength = this.posts.length;
            this.removeDuplocated();
            if(beforeLength != this.posts.length) throw( 'Duplicates detected and removed! Please verify result and click save again.');

            // Truncate posts to maximum (50)
            this.posts.length = this.posts.length > 50 ? 50 : this.posts.length;

            // Fix date formatting and remove UUID from log diff
            let editorsChoicePosts = [...this.posts]; // Clone
            for(let idx = 0; idx < editorsChoicePosts.length; idx++) {
                if(isEmptyDate(editorsChoicePosts[idx].dateLive)) editorsChoicePosts[idx].dateLive = formatDateTimeISO();
                //editorsChoicePosts[idx].dateExpires = isEmptyDate(editorsChoicePosts[idx].dateExpires) ? formatDateTimeISO('1/1/1770 12:00:00 AM') : formatDateTimeISO(layoutPosts[idx].dateExpires);
                editorsChoicePosts[idx].dateLive = formatDateTimeISO(editorsChoicePosts[idx].dateLive);
                //editorsChoicePosts[idx].postFields.datePublished = formatDateTimeISO(editorsChoicePosts[idx].postFields.datePublished);
                //delete editorsChoicePosts[idx].uuid;
                //delete editorsChoicePosts[idx].postFields.dateExpires;
            }

            // Save editor's choice
            console.log('📘 PUT Editor\'s Choice', editorsChoicePosts);

            // Hack: Remove categories because of type conversion error
            for(let idx = 0; idx < editorsChoicePosts.length; idx++) {
                delete editorsChoicePosts[idx].post.categories;
            }
            // End Hack

            return await widgetService.getWidgetPostItems(this.settingsId).then((original) => { // Fetch original
                return widgetService.saveWidgetPostItems(this.settingsId, editorsChoicePosts).then((res) => {
                    adminService.log({ logTypeId: 19, referenceId: this.settingsId, message: 'Updated', content: JSON.stringify(editorsChoicePosts, null, 2), original: JSON.stringify(original, null, 2), language: 'json' });
                    //this.posts = this.posts.map(obj => ({ ...obj, uuid: uuid.v4() })); // Re-add UUIDs since they were deleted above
                    return res;
                }).catch((error) => {
                    throw(error);
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        insert(post, status) {
            if(status == 'live') {
                post = {
                    ...post,
                    dateLive: formatDateTimeISO()
                }
            }

            // Set post type
            let postType = this.postTypes?.find(({ postTypeId }) => postTypeId == post.postTypeId);

            this.posts.unshift({
                uuid: uuid.v4(),
                id: 0,
                //postType: postType.name?.toLowerCase(),
                postType: postType.name,
                dateLive: '',
                postId: post.postId,
                post: post,
                settingsId: this.settingsId
            });
            //this.renumber(status);
            //this.getStats(status, true);
            //this.changedColumn[status] = true;
            this.isDirty = true;
        },
        remove(post) {
            this.posts = this.posts.filter(item => item.uuid !== post.uuid);
            //this.changedPosts.push(post.uuid); // TODO
            //this.renumber(status);
            //this.changedColumn[status] = true;
            this.isDirty = true;
        },
        movePosition(post, dir) {
            // Get current index of object in array
            var idx = this.posts.findIndex(item => item.uuid === post.uuid);
            //var idx = this.posts.findIndex(item => item.id === post.id);

            var newPosition = 0;
            if(!isNaN(dir)) {
                newPosition = dir-1;
            } else {
                // Move the item up or down
                newPosition = dir == 'up' ? idx-- : idx++;
            }

            if((dir == 'up' && idx < 0) || (dir = 'down' && idx >= this.posts.length)) return;
            this.posts = this.arrayMove(this.posts, idx, newPosition);

            // Highlight the item as changed
            //this.changedPosts.push(post.uuid); // TODO

            //this.renumber();
            this.isDirty = true;
        },
        arrayMove(arr, fromIndex, toIndex) {
            // https://www.codegrepper.com/code-examples/javascript/change+position+of+element+in+array+javascript
            var newArr = [...arr]; // Clone
            var element = newArr[fromIndex];
            newArr.splice(fromIndex, 1);
            newArr.splice(toIndex, 0, element);
            return newArr;
        },
        removeDuplocated() {
            // https://stackoverflow.com/a/36744732/3799374
            let result = [], postIds = [];
            for(let idx = 0; idx < this.posts.length; idx++) {
                if(!postIds.includes(this.posts[idx].postId)) {
                    result.push(this.posts[idx]);
                    if(this.posts[idx].postId > 0) postIds.push(this.posts[idx].postId);
                }
            }
            this.posts = result;
        }
    }
});
