<template>
    <div class="row mb-3">
       <div class="col-sm-12 col-md-12">
          <div class="card card-border-color card-border-color-primary">

               <form class="needs-validation" novalidate>
                  <div class="mb-3">
                        <div class="hstack gap-2  bg-white p-2">
                           <div class="me-2">
                            <Calendar id= "DateFrom" v-model="filter.fromDate" :class="dateFromRequired" :showButtonBar="true" :showIcon="true" placeholder="Date"  class="me-2" style="max-width: 155px" @date-select="dateCheck()" /><span class="required"/> &nbsp; to
                            <Calendar id= "DateTo"  v-model="filter.toDate" :class="dateToRequired" :showButtonBar="true" :showIcon="true" placeholder="Date" class="me-2 " style="max-width: 155px" @date-select="dateCheck()" /> <span class="required"/>&nbsp;&nbsp;
                            <Dropdown id="TimePeriod" v-model="filter.timePeriod" @change="onChange($event)" optionLabel="label" :options="timePeriods" optionValue="value" placeholder="Time Period" :showClear="true" class="me-2 " />&nbsp;
                            <Dropdown id="PartnersList" v-model="filter.partnerId" :class="partnerRequired" :options="coRegStore.partnersList" optionLabel="name"  optionValue="partnerId" :showClear="true" class="me-2" placeholder="Select a Partner"/><span class="required"/>&nbsp;
                            <Dropdown id="Offers" v-model="filter.offerId" :class="offerRequired" :options="coRegStore.offersList" optionLabel="offerName"  optionValue="offerId" :showClear="true" class="me-2" placeholder="Select a Offer"/><span class="required"/>

                            <Button label="Run" title="Run" icon="far fa-search" class="ms-2  p-button" @click="getReportsStats" />
                                 <Button label="Reset" title="Reset" icon="far fa-times"  class="p-button ms-2  p-button-secondary" @click="clearFilter"  />
                                 <Button label title="Download report..." icon="fa fa-download" class="p-button ms-2  p-button-secondary" @click="reportStatsExport" />

                            </div>
                           <div class="me-2">
                           </div>
                        </div>
                  </div>
               </form>
           </div>
      </div>
   </div>
   <div class="row mb-3">
        <div class="col-sm-12 col-md-12">
            <div class="card card-border-color card-border-color-primary report">
                <div class="card-header card-header-divider ">CoRegistrations</div>

                <div class="card-body">
                    <DataTable :value="coRegStore.reportsStats.coRegistratorData" ref="dt" stripedRows responsiveLayout="scroll">
                        <Column field="dateRegistered" header="Date Registered" exportHeader="Date Registered">
                            <template #body="slotProps">
                                <date-format format="mm/dd/yy" :datetime="slotProps.data.dateRegistered" />
                            </template>
                        </Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="partnerName" header="Partner" ></Column>
                        <Column field="offerName" header="Offer" ></Column>
                        <Column field="utmSource" header="utm_source" ></Column>
                        <Column field="utmCampaign" header="utm_campaign" ></Column>
                        <Column field="utmMedium" header="utm_medium" ></Column>
                        <Column field="utmTerm" header="utm_term" ></Column>

                    </DataTable>

                </div>


            </div>
        </div>
    </div>

</template>

<script>
 import { useCoRegStore } from '@/stores/CoRegStore';
//  import CoRegService from '@/service/CoRegService';
 import {  onBeforeMount, ref } from 'vue';
 import { useToast } from 'primevue/usetoast';
 import { formatDateTime } from '@/utils/ThmDateTimeUtils';
 import DateFormat from '@/components/DateFormat.vue';


export default {

    components: {
        DateFormat
    },
    setup(){
        //sevice setup
        // const coRegService = new CoRegService();

        const coRegStore = useCoRegStore();

        const filter = ref({

            siteId: 0,
            siteGroup: 128,
            title: null,
            timePeriod: 0,
            fromDate: null,
            toDate: null,
            pageIndex: 1,
            pageSize: 100,
            sortColumn: "DateRegistered",
            sortDirection: "DESC",
            dataView: null,
            isSearch: false,
            partnerId: 0,
            campaignId: 0,
            offerId: 0
        });
        const dt = ref();



        const exportCSV = () => {
            dt.value.exportCSV();
        };
        const timePeriods = [
            { label: 'Today', value: '1' },
            { label: 'Last 7 Days', value: '2' },
            { label: 'Month to Date', value: '3' },
            { label: 'Year to Date', value: '4' },
        ];
        const dateFromRequired = ref('');
        const dateToRequired = ref('');
        const partnerRequired = ref('');
        const offerRequired = ref('');

        const reportsStats = [];

        // const reportsCSV = "";

        const toast = useToast();

        onBeforeMount(() => {
            coRegStore.getPartnersList();
            coRegStore.getOffersList();
        });


        function onChange() {

            var today = new Date(new Date().setDate(new Date().getDate()));
            //    const timePeriod = filter.value.timePeriod;

            switch(true){

                case (filter.value.timePeriod == 4):   // YTD
                filter.value.fromDate = new Date(today.getFullYear(), 0,   1);
                filter.value.toDate = today;
                    break;

                case (filter.value.timePeriod == 3): // MTD
                filter.value.fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
                filter.value.toDate = today;
                    break;

                case (filter.value.timePeriod == 2): // Last 7 days
                filter.value.fromDate = new Date(new Date().setDate(new Date().getDate()-7));
                filter.value.toDate = today;
                    break;

                case (filter.value.timePeriod == 1): // Today
                filter.value.toDate = today;
                filter.value.fromDate = today;
                    break;

                default:
                    filter.value.fromDate = null;
                    filter.value.toDate = null;

            }


        }





        async function clearFilter() {
            filter.value.fromDate = null;
            filter.value.toDate = null;
            filter.value.timePeriod = 0;
            filter.value.partnerId = 0;
            filter.value.offerId = 0;
            coRegStore.reportsStats = [];

        }
        async function reportStatsExport(){

            let invalidFields = [];

            var requiredFields = [ filter.value.fromDate, filter.value.toDate, filter.value.partnerId, filter.value.offerId],
                isFormInvalid = false;
            if(filter.value.fromDate == null) {
                isFormInvalid = true;
                dateFromRequired.value = 'p-invalid';
                invalidFields.push('Date From');

            } else {
                dateFromRequired.value = '';
            }
            if(filter.value.toDate == null) {
                isFormInvalid = true;
                dateToRequired.value = 'p-invalid';
                invalidFields.push('Date To');

            } else {
                dateToRequired.value = '';
            }
            if(filter.value.partnerId == 0 ) {
                isFormInvalid = true;
                partnerRequired.value = 'p-invalid';
                invalidFields.push('Partner');

            } else {
                partnerRequired.value = '';
            }
            if(filter.value.offerId == 0){
                isFormInvalid = true;
                offerRequired.value = 'p-invalid';
                invalidFields.push('Offer');
            }



            requiredFields.forEach((elm) => {
                if (!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields: ' + invalidFields.join(', '), life: 5000 });
                return;
            }
            console.log(filter.value);

            await coRegStore.reportStatsExport( filter.value )
             .then((res) => {

                //some profound hackery
                window.open("data:text/csv;charset=utf-8," + encodeURI(res));

             }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Stats', detail: err.message || err });
             });


        }

        function dateCheck() {
            if ((filter.value.fromDate != null) & (filter.value.toDate != null)) {
                filter.value.timePeriod = null;
            }
        }

        async function getReportsStats() {
            // if(filter.value.timePeriod == 0) filter.value.timePeriod = 4;

            let invalidFields = [];

            var requiredFields = [ filter.value.fromDate, filter.value.toDate],
            isFormInvalid = false;
            if(filter.value.fromDate == null) {
            isFormInvalid = true;
            dateFromRequired.value = 'p-invalid';
            invalidFields.push('Date From');

            } else {
            dateFromRequired.value = '';
                                    }
            if(filter.value.toDate == null) {
            isFormInvalid = true;
            dateToRequired.value = 'p-invalid';
            invalidFields.push('Date To');

            } else {
            dateToRequired.value = '';
                                    }
            // if(filter.value.partnerId == 0 ) {
            // isFormInvalid = true;
            // partnerRequired.value = 'p-invalid';
            // invalidFields.push('Partner');

            // } else {
            // partnerRequired.value = '';
            //                         }
            // if(filter.value.offerId == 0){
            // isFormInvalid = true;
            // offerRequired.value = 'p-invalid';
            // invalidFields.push('Offer');
            // }
            requiredFields.forEach((elm) => {
                if (!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields: ' + invalidFields.join(', '), life: 5000 });
                return;
            }
            //filter.value.timePeriod = 4;


            await coRegStore.getReportsStats( filter.value )
             .then((res) => {
                if(res.coRegistratorData.length == 0){
                    toast.add({ severity: 'success', summary: 'No Results found', detail: 'No Results found', life: 4000 });

                    return;
                }
                coRegStore.reportsStats = res;

                console.log(res);

             }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Stats', detail: err.message || err });
             }).finally(() => {});
            //console.log(coRegStore.reportsStats);
            //console.log("dt:");
            //console.log(dt.value);
            return reportsStats;

        }


        return {  coRegStore, DateFormat, timePeriods, formatDateTime, dateCheck, getReportsStats,filter, clearFilter,reportStatsExport, exportCSV, dt, onChange, offerRequired, partnerRequired, dateFromRequired, dateToRequired};
    }


}
</script>
<style lang="scss" scoped>
.report{
 min-height:300px;
}
</style>
