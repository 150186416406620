<template>
    <ConfirmDialog />
    <div class="row mb-3" id="home_page_settings">
        <div class="col-xs-12" :class="gridSizeClass" v-if="appSettings.features.homePageManager.showSettings && appSettings.features.homePageManager.showQueue">
            <!--<Accordion :activeIndex="0">-->
            <Accordion>
                <AccordionTab header="Settings">
                    <Dropdown v-model="layoutStore.location.templateId" :options="templates" optionLabel="name" optionValue="templateId" class="w-100" />
                    <div class="mt-2">
                        <!--<p class="mb-0"><Checkbox name="ShowMoreArticles" value="1" class="vertical-align-middle" v-model="showMoreArticles" /> <span class="vertical-align-middle ps-1">Add 6 more articles</span></p>-->
                        <p class="mb-0">
                            <ToggleButton name="SortColumnistsByTrending" class="vertical-align-middle mb-1" v-model="layoutStore.template.sortColumnistsByTrending" onIcon="pi pi-check" offIcon="pi pi-times" onLabel="" offLabel="" /> <span class="vertical-align-middle ps-1">Sort Columnists by Chartbeat Trending</span>
                        </p>
                        <p class="mb-0">
                            <ToggleButton name="SortTipsheetByTrending" class="vertical-align-middle mb-1" v-model="layoutStore.template.sortTipsheetByTrending" onIcon="pi pi-check" offIcon="pi pi-times" onLabel="" offLabel="" :disabled="layoutStore.template.sortTipsheetByChronological" /> <span class="vertical-align-middle ps-1" :class="{ 'text-disabled': layoutStore.template.sortTipsheetByChronological }">Sort Tipsheet by Chartbeat Trending</span>
                        </p>
                        <p class="mb-0">
                            <ToggleButton name="SortTipsheetByTrending" class="vertical-align-middle mb-1" v-model="layoutStore.template.sortTipsheetByChronological" onIcon="pi pi-check" offIcon="pi pi-times" onLabel="" offLabel="" :disabled="layoutStore.template.sortTipsheetByTrending" /> <span class="vertical-align-middle ps-1" :class="{ 'text-disabled': layoutStore.template.sortTipsheetByTrending }">Sort Tipsheet by Chronological</span>
                        </p>
                        <div v-show="layoutStore.template.sortTipsheetByChronological">
                            <div class="row">
                                <div class="col">
                                    <label for="ChronologicalDateTimeStart" class="smallest pe-1">Start Date/Time:</label>
                                    <Calendar id="ChronologicalDateTimeStart" v-model="layoutStore.template.chronologicalDateTimeStart" :showTime="true" hourFormat="12" class="datetime-small" />
                                    <!--<CalendarThm id="ChronologicalDateTimeStart" v-model="layoutStore.template.chronologicalDateTimeStart" :showTime="true" hourFormat="12" class="datetime-small" />-->
                                </div>
                                <div class="col">
                                    <label for="ChronologicalDateTimeEnd" class="smallest pe-1">End Date/Time:</label>
                                    <Calendar id="ChronologicalDateTimeEnd" v-model="layoutStore.template.chronologicalDateTimeEnd" :showTime="true" hourFormat="12" class="datetime-small" />
                                    <!--<CalendarThm id="ChronologicalDateTimeEnd" v-model="layoutStore.template.chronologicalDateTimeEnd" :showTime="true" hourFormat="12" class="datetime-small" />-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button label="Save Layout" severity="secondary" class="p-button-sm mt-2" @click="saveLayout()" :disabled="!userHasAccessToRole('Admin')" />
                    <SplitButton label="Preview" icon="pi pi-chevron-down" :model="getPreviewActions()" severity="secondary" outlined class="hide-dropdown-button p-button-sm ms-2" />
                </AccordionTab>
            </Accordion>
            <!-- Preview links for non-admins: -->
            <!--
            <div class="d-block text-muted smallest" v-else>
                LayoutTemplate: {{ locationSettings.templateId }}<br />
                <a :href="'https://townhall.com/?hp=' + locationSettings.templateId" target="_blank">Preview</a> <span v-show="getTemplateProperty('previewImage')">| <a :href="getTemplateProperty('previewImage')" target="_blank">Image</a></span>
            </div>
            -->
        </div>
        <!--<div class="col-xs-12 col-md-4" v-else></div>-->
        <div class="col-xs-12" :class="gridSizeClass">
            <!--<Button label="Show Article Order by Template X" class="p-button-secondary p-button-sm" @click="showTemplate()" />-->
            <div v-show="layoutStore.isLocked" v-if="layoutStore.location.postLockStatus" class="smallest text-muted">
                <i class="pi pi-lock align-top me-1"></i> Currently editing: {{ layoutStore.location.postLockStatus.userIdLock }}
                <span v-if="layoutStore.isLocked && layoutStore.location.postLockStatus.userIdLock != user.name">
                    &ndash; <a href="#" @click="takeOver">Take Over</a>
                </span>
            </div>
            <div v-if="layoutStore.template.sortTipsheetByChronological" class="smallest text-muted">
                <i class="pi pi-lock align-top me-1"></i> Auto-Pilot: Chronological Sorting Enabled
            </div>
        </div>
        <div class="col-xs-12 col-md-4">
            <p class="smallest mb-0 text-muted">Last Updated: {{ formatDateTime(log.timestamp) }} &ndash; {{log.userName}}</p>
            <p class="smallest mb-0 text-muted">
                <LogViewerThm :filter="{ logTypeId: 13, referenceId: 1 }" label="Log" /> |
                <a :href="homePage" target="_blank">View Home Page <i class="far fa-arrow-up-right-from-square"></i></a>
            </p>
        </div>
    </div>

    <!-- Template layout image preview: -->
    <Dialog v-model:visible="showImageModal" @hide="showImageModal = false" :modal="true" :dismissableMask="true">
        <template #header>
            <h3>Template {{ layoutStore.location.templateId }}</h3>
        </template>

        <img :src="imagePreviewUrl" />
    </Dialog>
</template>

<script>
import { ref, computed, inject, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { formatDateTime, formatDateTimeISO, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useLayoutStore } from '@/stores/LayoutStore';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
//import { useSignalR } from '@quangdao/vue-signalr';
//import { useSignalR } from '@dreamonkey/vue-signalr';
import useSignalR from '@/use/SignalR';
//import CalendarThm from '@/components/CalendarThm.vue';
import SettingsService from '@/service/SettingsService';
import ConfirmDialog from 'primevue/confirmdialog';
import AdminService from '@/service/AdminService';
import LogViewerThm from '@/components/LogViewerThm.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'HomePageManagerSettings',
    components: {
        //CalendarThm,
        ConfirmDialog,
        LogViewerThm
    },
    setup() {
        const appSettings = inject('appSettings');
        const settingsService = new SettingsService();
        const adminService = new AdminService();
        const layoutStore = useLayoutStore();
        const confirm = useConfirm();
        const signalr = useSignalR();
        const toast = useToast();
        const { user } = useAuth0();
        const showImageModal = ref(false);
        const imagePreviewUrl = ref(null);
        const homePage = ref('https://townhall.com');

        //const locationSettings = ref({});
        const templates = ref([]);
        //const selectedTemplate = ref({ sortTipsheetByTrending: false, sortColumnistsByTrending: false, sortTipsheetByChronological: false });
        const log = ref({});
        const locationId = 1;

        // Signal-R
        signalr.on('LayoutReceiveLock', (message) => setLockMessageOnLocation(message));
        signalr.on('LayoutReceiveTakeOverNotify', (message) => takeOverNotify(message));

        onBeforeMount(() => {
            settingsService.getLayoutLocation(locationId).then((res) => {
                layoutStore.location = res;
                //locationSettings.value = res;

                settingsService.getLayoutTemplates(layoutStore.location.locationId).then((res) => {
                    for(let idx = 0; idx < res.length; idx ++) {
                        res[idx].chronologicalDateTimeEnd = formatDateTime(res[idx].chronologicalDateTimeEnd);
                        res[idx].chronologicalDateTimeStart = formatDateTime(res[idx].chronologicalDateTimeStart);
                    }

                    templates.value = res;
                    layoutStore.template = templates.value.find(({ templateId }) => templateId === layoutStore.location.templateId);
                }).catch((error) => {
                    throw error;
                });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading Location Settings', detail: err.message || err, life: 3000 });
            });
        });

        onMounted(() => {
            getLastUpdated();
            homePage.value = appSettings.webroot[process.env.NODE_ENV];
        });

        onBeforeUnmount(() => {
            signalr.off('LayoutReceiveLock');
            signalr.off('LayoutReceiveTakeOverNotify');
        });

        function getLastUpdated() {
            // Get most recent log entry
            adminService.getLogs({ logTypeId: 13, referenceId: locationId, pageSize: 1 }).then((res) => {
                if(res.length) {
                    log.value = res[0];
                }
            });
        }

        const gridSizeClass = computed(() => {
            return 'col-md-' + (appSettings.features.homePageManager.showQueue ? 4 : 6);
        });

        function getPreviewActions() {
            let previewActions = [
                {
                    label: 'Live Preview',
                    command: () => {
                        window.open(`https://townhall.com/?hp=${layoutStore.location.templateId}`, '_blank');
                    }
                }
            ];

            let template = templates.value.find(({ templateId }) => templateId === layoutStore.location.templateId);
            if(template && template.previewImage) {
                previewActions.push({
                    label: 'Show Image',
                    command: () => {
                        //window.open(template.previewImage, '_blank');
                        imagePreviewUrl.value = template.previewImage;
                        showImageModal.value = true;
                    }
                });
            }

            return previewActions;
        }

        function getTemplateProperty(prop) {
            let template = templates.value.find(({ templateId }) => templateId === layoutStore.location.templateId);
            return template && template[prop] ? template[prop] : null;
        }

        function saveLayout() {
            let tmpl = templates.value.find(({ templateId }) => templateId === layoutStore.location.templateId);
            let template = { ...tmpl };

            template.chronologicalDateTimeStart = isEmptyDate(template.chronologicalDateTimeStart) ? formatDateTimeISO() : formatDateTimeISO(template.chronologicalDateTimeStart);
            template.chronologicalDateTimeEnd = isEmptyDate(template.chronologicalDateTimeEnd) ? formatDateTimeISO() : formatDateTimeISO(template.chronologicalDateTimeEnd);

            settingsService.saveLayoutTemplate(template).then(() => {
                settingsService.saveLayoutLocation(layoutStore.location).then(() => {
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Template Settings Saved', life: 3000 });
                }).catch((error) => {
                    throw error;
                });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Template Settings', detail: err.message || err, life: 3000 });
            });
        }

        function takeOver() {
            confirm.require({
                message: `Are you sure you want to take over editing? If yes, ${layoutStore.location.postLockStatus.userIdLock} will be blocked from continuing to edit.`,
                header: 'Take Over Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    layoutStore.load(locationId, true);
                    let payload = { postId: locationId, userIdLock: user.value.name, connectionId: user.value.email };
                    //console.log('payload', payload);
                    signalr.invoke('LayoutManagerTakeOverNotify', payload);
                    layoutStore.location.postLockStatus = payload;
                    toast.add({ severity: 'info', summary: 'Location Taken Over', detail: 'You have successfully taken over this location', life: 4000 });
                }
            });
        }

        function setLockMessageOnLocation(message) {
            //let postIndex = postStore.posts.findIndex((n) => n.postId == postMessage.postId);
            //postStore.posts[postIndex] = { ...postStore.posts[postIndex], userIdLock: postMessage.userIdLock };
            //console.log('R:message', message);
            if(message) {
                layoutStore.location.postLockStatus = message;
                getLastUpdated();

                // Reload posts on unlock
                if(!message.isLocked) layoutStore.load(locationId, true);
            }
        }

        function takeOverNotify(message) {
            console.log('[Layout takeOverNotify] Location:', locationId);
            layoutStore.location.postLockStatus = message;
            getLastUpdated();
            layoutStore.load(locationId, true);
            toast.add({ severity: 'warn', summary: 'Location Taken Over', detail: `${message.userIdLock} took over editing this location! Your changes will be abandoned.` });
        }

        return {
            appSettings,
            formatDateTime,
            getPreviewActions,
            getTemplateProperty,
            gridSizeClass,
            homePage,
            imagePreviewUrl,
            layoutStore,
            log,
            //locationSettings,
            saveLayout,
            //selectedTemplate,
            showImageModal,
            takeOver,
            templates,
            user,
            userHasAccessToRole,
        };
    }
};
</script>

<style lang="scss" scoped>
#home_page_settings {
    .smaller { font-size: 85%; }
    .smallest { font-size: 75%; }
    .vertical-align-middle { vertical-align: middle; }
    .text-disabled { color: var(--gray-500); }
}
</style>

<style lang="scss">
#home_page_settings {
    .p-accordion-header-link {
        padding: 0.6rem 1rem;
    }
    .datetime-small {
        input {
            font-size: 0.8em;
            padding: 0.2em;
            width: 100%;
        }
    }
}
div.hide-dropdown-button {
    button.p-button-icon-only {
        border-radius: 3px !important;
    }
    button.p-splitbutton-defaultbutton {
        display: none;
    }
}
</style>