<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Access Denied</div>

                <div class="card-body">
                    <p>Sorry, your access has been refused due to lack of permissions.<br />Please go back to the previous page to continue browsing.</p>
                    <p>If you believe there has been a mistake and you need the access, contact your administrator to get it fixed.</p>
                    <a class="btn btn-danger" href="javascript:history.back()">Go Back</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: 'Unauthorized',
    data() {
        return {
            message: 'Protected!',
            user: ref({
                name: '',
                email: '',
                groups: '',
            }),
            userJson: '',
        };
    },
    created() {},
    methods: {},
};
</script>
