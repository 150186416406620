<template>
    <ConfirmDialog />
    <Dialog header="Post locked for editing" v-model:visible="showTakeOver" :modal="true" :closeOnEscape="false" :closable="false">
        <!--<p>This post is currently locked. If you take over, {{ postEditStore.post.userIdLock }} will be blocked from continuing to edit.</p>-->
        <p>This post is currently locked. If you take over, {{ userLock }} will be blocked from continuing to edit.</p>
        <template #footer>
            <Button label="Preview" icon="pi pi-times" @click="readOnlyPost()" text />
            <Button label="Take over" icon="pi pi-check" @click="takeOverPost()" autofocus />
        </template>
    </Dialog>

    <div class="card card-border-color card-border-color-primary">
        <div class="card-header card-header-divider">
            <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" @click="newPost()" title="Add New Author" />
            <!-- {{ route.params?.postId ? 'Edit' : 'Add' }} -->
            <span v-if="cptEditStore.isReadonly">Preview</span>
            <span v-else-if="route.params?.postId">Edit</span>
            <span v-else>Add </span>
            {{ cptStore.postType?.name }}<span v-if="cptEditStore.post.postId">:</span> {{ cptEditStore.post.postId }}
            <!-- <div>
                PostId: {{cptEditStore.post.postId}}<br />
                AuthorID: {{cptEditStore.currentAuthor.authorId}}<br />
                Type: {{cptStore.postType.postTypeId}}
            </div> -->
        </div>

        <div class="card-body" v-if="Object.keys(cptEditStore.post).length">

            <div class="mb-3">
                <label for="CptTitle" class="form-label required">Title</label>
                <InputText type="text" class="form-control" v-model="cptEditStore.post.title" id="CptTitle" />
            </div>

            <div v-if="cptEditStore.post.postId > 0" class="mb-4 mt-1">
                <label for="Permalink" class="form-label mb-1">
                    <a target="_blank" :href="getPermalink(cptEditStore.post.url)">Permalink <i class="far fa-arrow-up-right-from-square"></i></a>
                </label>
                <div class="p-inputgroup">
                    <Button @click="togglePermalink()" :label="modifyPermalink ? 'Edit' : 'Save'" :disabled="cptEditStore.isReadonly || !userHasAccessToRole('Admin')" />
                    <InputText :disabled="modifyPermalink" type="text" v-model="postPermalink" placeholder="" id="Permalink" :class="{ 'modify-disabled': modifyPermalink }" />
                    <span class="p-inputgroup-addon">-n{{ cptEditStore.post.postId }}</span>
                    <Button ref="copyPermalinkText" @click="copy(getPermalink(cptEditStore.post.url))">
                        <span v-if="!copied">Copy</span>
                        <span v-else>Copied!</span></Button>
                </div>
            </div>

            <!-- Headline -->
            <div class="panel mb-3" v-if="cptStore.postType.postTypeId == 4">
                <div class="panel-header">Source</div>
                <div class="panel-body">
                    <div class="mb-3">
                        <label for="SourceName" class="form-label required">Source Name</label>
                        <InputText type="text" class="form-control" v-model="cptEditStore.post.extendedProperties.customPost.source.name" id="SourceName" />
                    </div>

                    <div class="mb-3">
                        <label for="OriginalAuthor" class="form-label">Original Author</label>
                        <InputText type="text" class="form-control" v-model="cptEditStore.post.extendedProperties.customPost.source.author" id="OriginalAuthor" />
                    </div>

                    <div class="mb-2">
                        <label for="SourceUrl" class="form-label required">URL</label>
                        <IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-link" />
                            </InputIcon>
                            <InputText type="url" class="w-100" id="SourceUrl" placeholder="https://" :value="cptEditStore.post.extendedProperties?.customPost?.source?.url" @change="cptEditStore.post.extendedProperties.customPost.source.url = $event.target.value" />
                        </IconField>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label for="Body" class="form-label" :class="{ required: [4].includes(cptEditStore.postType.postTypeId) }">Content</label>
                <FroalaThm tag="textarea" v-model="cptEditStore.post.body" :config="froalaConfig" id="Body" />
            </div>

            <!-- Headline -->
            <div class="mb-3" v-if="cptStore.postType.postTypeId == 4">
                <label for="Commentary" class="form-label">Commentary</label>
                <FroalaThm tag="textarea" v-model="cptEditStore.post.extendedProperties.customPost.body" :config="froalaConfig" id="Commentary" />
            </div>
            <!-- /Headline -->

            <div class="row mb-3">
                <label for="Status" class="col-sm-3 col-form-label">Status</label>
                <SelectButton v-model="cptEditStore.post.status" :options="cptStore.postStatusList" optionLabel="label" optionValue="value" optionDisabled="disabled" :multiple="false" :unselectable="false" id="Status" />
            </div>


            <div class="mb-3">
                <label for="DatePublished" class="form-label d-block">Date Published</label>
                <Calendar id="DatePublished" v-model="postDatePublished" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :showButtonBar="true" @clear-click="postDatePublished = null" />
            </div>

            <p>
                <Button v-if="!cptEditStore.isReadonly" :label="(cptEditStore.post.postId ? 'Save ' : 'Add ') + cptStore.postType.name" @click="save()" />
                <Button v-else @click="showTakeOver = true;" label="Take Over Post" severity="warning" />
                <Button label="Delete" severity="danger" class="btn-delete ms-2" v-show="cptEditStore.post.postId" @click="deletePost()" />
                <LogViewerThm label="Change Log" :filter="{ logTypeId: 20, referenceId: cptEditStore.post.postId }" v-if="cptEditStore.post.postId" class="p-button-secondary p-button-outlined ms-2" tag="button" />
            </p>
        </div>

    </div>

</template>

<script>
import { ref, computed, onBeforeMount, onBeforeUnmount, onUnmounted, watch } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useCptEditStore } from '@/stores/CptEditStore';
//import { isEmptyOrNull } from '@/utils/ThmStringUtils';
import { getPermalink } from '@/utils/ThmUtils';
import { useClipboard } from '@vueuse/core';
import { useCptStore } from '@/stores/CptStore';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { seoUrl } from '@/utils/ThmUtils';
import ConfirmDialog from 'primevue/confirmdialog';
import LogViewerThm from '@/components/LogViewerThm.vue';
import PostService from '@/service/PostService';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import useSignalR from '@/use/SignalR';
import useAuth0 from '@/use/Auth0';
//import FroalaThm from '@/components/Froala/FroalaOld.vue';

export default {
    name: 'CustomPostTypeEditor',
    components: {
        ConfirmDialog,
        FroalaThm,
        LogViewerThm
    },
    setup() {
        const { copied, copy } = useClipboard();
        const { user } = useAuth0();
        const cptStore = useCptStore();
        const cptEditStore = useCptEditStore();
        const postService = new PostService();
        const router = useRouter();
        const confirm = useConfirm();
        const route = useRoute();
        const toast = useToast();
        const signalr = useSignalR();

        const modifyPermalink = ref(true);
        const showTakeOver = ref(false);
        const userLock = ref(null);

        //signalr.on('ReceiveTakeOverNotify', (message) => takeOverNotify(message));
        signalr.on('ReceivePostLock', (message) => setLockMessageOnPostList(message));

        const froalaConfig = {
            toolbarButtons: ['html', 'bold', 'italic', 'quote', 'formatOLSimple', 'formatUL', 'insertLink', 'embedCodeButton', 'undo', 'redo'],
            charCounterCount: false,
        };

        watch(() => route.params, async (params, fromParams) => {
            console.log('[watch] CustomPostTypeEditor');

            //if(!params.postId) cptEditStore.setDefaults();
            if(params.postTypeId != fromParams.postTypeId) {
                //cptStore.load(params.postTypeId, true);
                cptEditStore.load(params.postTypeId, true);
            }
            if(params.postId != fromParams.postId) {
                if(params.postId) {
                    // cptEditStore.getPost(params.postId).catch((err) => {
                    //     toast.add({ severity: 'error', summary: `Error Fetching ${cptStore.postType.name}`, detail: err.message || err, life: 3000 });
                    // });
                    await getPost(params.postId);
                }
            }
            if(params.postId && params.postId != fromParams.postId) {
                subscribe();
            } else {
                //cptEditStore.$reset();
                cptEditStore.setDefaults(route.params.postTypeId);
                //setCurrentAuthor();
            }
        });

        function subscribe() {
            if(!cptEditStore.post.postId) return;
            cptEditStore.$subscribe((mutation, state) => {
                if(console.log.hide) console.log(state);
                if(state && mutation.type == 'direct') {
                    //console.log('Mutation:', state);
                    if(state.isDirty == false && state.isReadonly == false && state.editMode == true) {
                        console.log('Mutation:', state);
                        let connectionId = user.value.email;
                        console.log('Set user ID lock and broadcast: ', connectionId);

                        postService.postLockStatus(state.post.postId).then((postLockStatus) => {
                            if (postLockStatus.isLocked && postLockStatus.connectionId !== user.value.email) {
                                showTakeOver.value = true;
                            } else {
                                lockPost(state.post.postId);
                            }
                        });
                    }

                    if(state.isReadonly == false) {
                        cptEditStore.$patch((state) => {
                            state.isDirty = true;
                            state.post.userIdLock = user.value.name;
                        });
                        console.log(`📙 Mutation: ${mutation.type}:`, state);
                    }
                }
            });
        }

        onBeforeMount(async () => {
            //await cptStore.load(route.postTypeId, true);
            await cptEditStore.load(route.postTypeId, true);
            if(route.params.postId) {
                await getPost(route.params.postId);
                // cptEditStore.$patch((state) => {
                //     state.editMode = true;
                // });
                subscribe();
                // } else {
                //     cptEditStore.$reset();
            } else {
                //cptEditStore.post = cptEditStore.newPost;
                cptEditStore.setDefaults(route.params.postTypeId);
            }
        });

        onUnmounted(() => {
            cptEditStore.$reset();
        });

        onBeforeUnmount(() => {
            ['ReceivePostLock'].forEach((methodName) => { // ReceiveTakeOverNotify
                signalr.off(methodName);
            });
        });

        onBeforeRouteUpdate((to, from, next) => {
            //console.log('currently locked by:', cptEditStore.post.userIdLock)
            if(cptEditStore.post.postId && cptEditStore.isDirty && !cptEditStore.isReadonly && cptEditStore.post.userIdLock == user.value.name) {
                confirm.require({
                    message: 'You have unsaved changes. Do you want to continue?',
                    header: 'Unsaved Changes',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        //toast.add({ severity: 'error', summary: 'Save cancelled', detail: 'Post Save cancelled', life: 3000 });
                        if(cptEditStore.post.userIdLock == user.value.name) {
                            unlockPost(cptEditStore.post.postId);
                        }
                        next();
                    },
                    reject: () => {
                        // Do nothing
                    },
                });
            } else if(cptEditStore.post.postId && cptEditStore.post.userIdLock == user.value.name) {
                //cptEditStore.$reset();
                //cptEditStore.post = cptEditStore.newPost;
                unlockPost(cptEditStore.post.postId);
                next();
            } else {
                //cptEditStore.$reset();
                next();
            }
        });

        onBeforeRouteLeave((to, from, next) => {
            if(cptEditStore.post.postId && cptEditStore.isDirty) {
                confirm.require({
                    message: 'You have unsaved changes. Do you want to continue?',
                    header: 'Unsaved Changes',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        if(cptEditStore.post.userIdLock == user.value.name) {
                            unlockPost(cptEditStore.post.postId);
                        }
                        next();
                    },
                    reject: () => {
                        // Do nothing
                    },
                });
            } else if(cptEditStore.post.postId && cptEditStore.post.userIdLock == user.value.name) {
                // cptEditStore.post = cptEditStore.newPost;
                // cptEditStore.$reset();
                unlockPost(cptEditStore.post.postId);
                next();
            } else {
                // cptEditStore.post = cptEditStore.newPost;
                // cptEditStore.$reset();
                next();
            }
        });

        const postDatePublished = computed({
            get() {
                return cptEditStore.post.datePublished == null ? null : new Date(cptEditStore.post.datePublished);
            },
            set(newValue) {
                cptEditStore.post.datePublished = newValue ? formatDateTime(newValue, 'yyyy-MM-dd HH:mm:ss') : null;
            },
        });

        const postPermalink = computed({
            get() {
                let urlTitle = cptEditStore.post.urlTitle;
                if(!urlTitle?.match(/-n\d+$/)) return urlTitle;

                let n = urlTitle.lastIndexOf('-n');
                if (n > 0) {
                    let url = urlTitle?.substring(0, n);
                    return url;
                }

                //let nId = url.substring(url.lastIndexOf("-n"))
                return urlTitle;
            },
            set(newValue) {
                cptEditStore.post.urlTitle = seoUrl(newValue);
            },
        });

        function togglePermalink() {
            if(modifyPermalink.value) {
                modifyPermalink.value = false;
            } else {
                cptEditStore.urlTitle = postPermalink.value;
                modifyPermalink.value = true;
                cptEditStore.post.url = cptEditStore.getPath();
            }
        }

        function newPost() {
            router.push({ path: `/cpt/${route.params.postTypeId}`, query: route.query });
        }

        async function save() {
            // Required fields for all post types
            var requiredFields = [cptEditStore.post.title], isFormInvalid = false;

            // Required fields by post type
            switch(route.params.postTypeId) {
                case 4:
                    requiredFields.push(
                        cptEditStore.post.extendedProperties.customPost.source.name,
                        cptEditStore.post.extendedProperties.customPost.source.url,
                        cptEditStore.post.body
                    );
            }

            //var requiredFields = [], isFormInvalid = false; // Temporarily disable field checking
            requiredFields.forEach((elm) => {
                if(!elm) isFormInvalid = true;
            });
            if(isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            // Save custom post
            let postType = cptStore.postType?.name || 'Custom Post';
            let isNewPost = !cptEditStore.post.postId;
            cptEditStore.save().then((res) => {
                //if(console.log.hide) console.log('res', res);
                cptStore.getPosts( { postTypeId: route.params.postTypeId, ...route.query });
                router.push({ path: `/cpt/${route.params.postTypeId}/${res.postId}`, query: route.query });
                toast.add({ severity: 'success', summary: 'Success', detail: `${postType} Saved`, life: 3000 });

                if(isNewPost) {
                    let msg = {
                        postId: res.postId,
                        postTypeId: 4
                    }
                    signalr.invoke('BroadcastNewPostNotification', msg);
                    cptStore.getPosts( { postTypeId: route.params.postTypeId, ...route.query });
                } else {
                    unlockPost();
                }

            }).catch((err) => {
                toast.add({ severity: 'error', summary: `Error Saving ${postType}`, detail: err.message || err, life: 3000 });
            });
        }

        function deletePost() {
            if(!cptEditStore.post.postId) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this post?',
                header: 'Delete Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    cptEditStore.delete().then(() => {
                        cptStore.getPosts( { postTypeId: route.params.postTypeId, ...route.query });
                        router.push({ path: `/cpt/${route.params.postTypeId}`, query: route.query });
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting Post', detail: err.message || err, life: 3000 });
                    });
                }
            });
        }

        async function getPost(postId) {
            if(!postId) return;
            let uid = user.value.name;
            cptEditStore.$patch((state) => {
                state.isReadonly = false;
            });

            await postService.getPost(postId).then((response) => {
                if(!response.postId) throw('Post ID not found');

                if(response.userIdLock != null && uid != response.userIdLock) {
                    userLock.value = response.userIdLock;
                    showTakeOver.value = true;
                }

                //original.value = { ...response }; // Clone to prevent reactivity
                cptEditStore.$patch((state) => {
                    if(console.log.hide) console.log(state);
                    state.post = { ...response };
                    //state.postId = postId;
                    state.editMode = true;
                    state.isDirty = false;
                    //state.post.body = addHyperlinkTitles(state.post.body);
                });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Post', detail: err.message || err, life: 5000 });
            });
        }

        async function lockPost(postId) {
            let postMessage = {
                postId: postId,
                userIdLock: user.value.name,
                connectionId: user.value.email,
            };
            //console.log('lock:', postMessage);
            await postService.toggleLockPost(postMessage);
            signalr.invoke('BroadcastPostLock', postMessage);
        }

        async function unlockPost() {
            let postMessage = {
                postId: cptEditStore.post.postId,
                userIdLock: null,
                connectionId: null,
            };
            await postService.toggleLockPost(postMessage);
            signalr.invoke('BroadcastPostLock', postMessage);
        }

        function readOnlyPost() {
            console.log('Read only');
            cptEditStore.$patch((state) => {
                state.isReadonly = true;
            });
            showTakeOver.value = false;
        }

        async function takeOverPost() {
            let uid = user.value.name;
            let connectionId = user.value.email;

            cptEditStore.$patch((state) => {
                state.isReadonly = false;
                state.editMode = true;
                state.isDirty = true;
                state.post.userIdLock = uid;
                state.post.connectionId = connectionId;
            });

            showTakeOver.value = false;
            console.log('take over my connectionId: ', connectionId);
            let postMessage = { postId: cptEditStore.post.postId, userIdLock: uid, connectionId: connectionId };
            //signalr.invoke('TakeOverNotify', postMessage);
            await postService.toggleLockPost(postMessage);
            signalr.invoke('BroadcastPostLock', postMessage);
            toast.add({ severity: 'warn', summary: 'Post taken over', detail: 'You have taken over the post and the previous editor will be notified.', life: 5000 });
        }

        // function takeOverNotify(message) {
        //     //console.log('Listen for user: ', message);
        //     console.log('[CPT] ReceiveTakeOverNotify: ', message);
        //     resetLockPost();
        //     //setLockMessageOnPostList(message)
        //     toast.add({ severity: 'warn', summary: 'Post taken over', detail: message });
        // }

        // function resetLockPost() {
        //     cptEditStore.$patch((state) => {
        //         state.isReadonly = true;
        //         state.editMode = true;
        //         state.isDirty = false;
        //         //postEditStore.post.userIdLock = user.value.name
        //     });
        // }

        function setLockMessageOnPostList(postMessage) {
            console.log('[CPT] ReceivePostLock', postMessage);
            let postIndex = cptStore.posts.findIndex((n) => n.postId == postMessage.postId);
            cptStore.posts[postIndex] = { ...cptStore.posts[postIndex], userIdLock: postMessage.userIdLock };

            // If more than one person is on the locked post, lock everyone else out
            if(postMessage.postId == cptEditStore.post.postId && postMessage.userIdLock && postMessage.userIdLock != user.value.name) {
                cptEditStore.$patch((state) => {
                    state.isReadonly = true;
                    state.editMode = true;
                    state.isDirty = false;
                    state.post.userIdLock = postMessage.userIdLock;
                });

                let duration = process.env.NODE_ENV == 'development' ? 5000 : null;
                toast.add({ severity: 'warn', summary: 'Post Taken Over', detail: `This post has been taken over by ${postMessage.userIdLock}.`, life: duration });
            }

            // if(postMessage.postId == cptEditStore.post.postId && postMessage.userIdLock) {
            //     /*
            //     postEditStore.$patch((state) => {
            //         state.isReadonly = false;
            //         state.editMode = true;
            //         state.isDirty = false;
            //         //postEditStore.post.userIdLock = user.value.name
            //     });
            //     */
            //     getPost(cptEditStore.post.postId);
            // }
        }

        return {
            copied,
            copy,
            cptStore,
            cptEditStore,
            deletePost,
            froalaConfig,
            getPermalink,
            modifyPermalink,
            newPost,
            postDatePublished,
            postPermalink,
            readOnlyPost,
            route,
            save,
            showTakeOver,
            takeOverPost,
            togglePermalink,
            userHasAccessToRole,
            unlockPost,
            userLock,
        }
    }
}
</script>

<style lang="scss" scoped>
div.panel {
    background-color: var(--surface-100);
}
</style>
