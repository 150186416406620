import { defineStore } from 'pinia';
import MenuService from '../service/MenuService';

const menuService = new MenuService('');
export const useMenuStore = defineStore('MenuDemoStore', {
    // other options...
    state: () => ({
        menu: null,
        count: 0,
    }),

    actions: {
        async getMenuById(id) {
            this.menu = await menuService.getMenuById(id);
        },
        add() {
            const m = {
                icon: null,
                label: 'Test 1',
                url: '/test1',
                target: null,
                classes: null,
                sortOrder: 0,
                menu: [],
            };

            this.menu.menu.push(m);
        },
        save() {
            console.log('SAVED: ', this.menu);
        },
        increment() {
            this.count++;
        },
        expand() {
            console.log('Expand');
        },
        deleteItem(item) {
            console.log('delete - ', item);

            this.menu.menu = recursiveRemove(this.menu.menu, item.label);
            // console.log(this.menu.menu);

            function recursiveRemove(list, id) {
                return list
                    .map((item) => {
                        return { ...item };
                    })
                    .filter((item) => {
                        if ('menu' in item) {
                            item.menu = recursiveRemove(item.menu, id);
                        }
                        return item.label !== id;
                    });
            }

            console.log('RESULT', this.menu.menu);
        },
        menuChange(obj) {
            console.log('[_] menuChange(obj)', obj);
        },
    },
    //computed properties
    //getters
});
