

<template>

    <div class="row" id="tha">
        <div class="col-sm-12 col-md-5">
            <ArticleSources :addExternal="true" :queueSupport="false" :store="afternoonStore" />
        </div>

        <CenterQueue />
    </div>
</template>

<script>
import { useAfternoonStore } from '@/stores/AfternoonStore';
//import ArticleSources from './ArticleSources.vue';
import ArticleSources from '@/pages/manage/home/ArticleSources.vue';
import CenterQueue from './CenterQueue.vue';

export default {
    name: 'TownhallAfternoon',
    components: {
        ArticleSources,
        CenterQueue
    },
    setup() {
        const afternoonStore = useAfternoonStore();

        return {
            afternoonStore
        }
    }
};
</script>

<style lang="scss" scoped>
#tha {
    font-size: 90%;
}
</style>
