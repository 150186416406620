<template>
    <div class="col-sm-12 col-md-8">
        <ConfirmDialog />

        <section class="card card-border-color card-border-color-primary">
            <div class="card-header card-header-divider">
                <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" v-tooltip.left="'Add New Episode'" @click="addNewEpisode()" />
                <span v-if="podcastStore.show.id">{{ podcastStore.show.title }} &ndash; </span><span v-if="podcastStore.episode.id">Edit</span><span v-else>Add</span> Episode
            </div>

            <div class="card-body" id="episode_editor">
                <div class="mb-3">
                    <label for="Title" class="form-label required">Episode Name</label>
                    <InputText type="text" class="form-control" v-model="podcastStore.episode.title" id="Title" />
                    <a :href="episodeUrl" class="permalink" target="_blank" v-show="podcastStore.episode.isPublished">{{ episodeUrl }}</a>
                </div>

                <div class="input-group mb-2">
                    <label for="AudioUrl" class="form-label required w-100">Audio File</label>
                    <InputText type="text" class="form-control" v-model="podcastStore.episode.audioUrl" id="AudioUrl" :disabled="true" />
                    <button v-if="podcastStore.episode.audioUrl" class="btn btn-outline-secondary delete" type="button" @click="deleteUpload"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="audio[]" mode="basic" :auto="true" :fileLimit="1" accept="audio/*" :disabled="isLoading" :customUpload="true" @uploader="uploadPodcast" />
                    <audio v-show="podcastStore.episode.audioUrl" id="episode-audio" controls="controls" preload="metadata" :src="podcastStore.episode.audioUrl" style="width: 100%"></audio>
                </div>
                <div class="mb-3" v-show="isUploading">
                    <ProgressBar mode="indeterminate" style="height: .5em" />
                </div>

                <div class="mb-3">
                    <InputSwitch v-model="podcastStore.episode.isExplicit" id="IsExplicit" />
                    <label for="IsExplicit" class="switch-label">Explicit</label>
                </div>

                <div class="mb-3 input-group">
                    <label for="EmbedCode" class="form-label w-100">Embed Code</label>
                    <InputText class="form-control" v-model="embedCode" readonly="readonly" id="EmbedCode" />
                    <button class="btn btn-outline-secondary" type="button" id="btn_copy_feed_url" @click="copy(embedCode)" v-if="embedCode"><span v-if="copied">Copied</span><span v-else>Copy</span></button>
                </div>

                <div class="mb-3">
                    <label for="Description" class="form-label">Description</label>
                    <!--
                    <Editor v-model="selectedEpisode.description" editorStyle="height: 150px;">
                        <template #toolbar>
                            <span class="ql-formats">
                                <button class="ql-bold"></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-blockquote"></button>
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-link"></button>
                            </span>
                        </template>
                    </Editor>
                    -->
                    <!--<froala-thm tag="textarea" v-model="podcastStore.episode.description" :config="{ toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'], charCounterCount: false, pastePlain: true }" />-->
                    <FroalaThm v-model="podcastStore.episode.description" :config="{ toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'], charCounterCount: false, pastePlain: true }" />
                </div>

                <div class="mb-3">
                    <label for="Season" class="form-label">Season</label>
                    <InputText type="number" class="form-control input__tiny" v-model="podcastStore.episode.season" id="Season" />
                </div>

                <div class="mb-3">
                    <label for="Episode" class="form-label">Episode</label>
                    <InputText type="number" class="form-control input__small" v-model="podcastStore.episode.episode" id="Episode" />
                </div>

                <div class="mb-3">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <InputSwitch v-model="podcastStore.episode.isPublished" id="isPublished" />
                                <label for="isPublished" class="switch-label">Published</label>
                            </div>

                            <p>
                                <Button :label="podcastStore.episode.id ? 'Save Episode' : 'Add Episode'" @click="saveEpisode()" x-title="Save (Ctrl+S or ⌘S)" />
                                <Button label="Delete" severity="danger" class="btn-delete" v-if="podcastStore.episode.id" @click="deleteEpisode()" />
                                <LogViewerThm label="Change Log" :filter="{ logTypeId: 6, referenceId: podcastStore.episode.id }" v-if="podcastStore.episode.id" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                            </p>
                        </div>
                        <div class="col">
                            <div class="mb-3" v-if="podcastStore.episode.isPublished">
                                <label for="PublishDate">Publish Date/Time ({{ timezoneAbbrev }})</label>
                                <div class="input-group">
                                    <Calendar id="PublishDate" v-model="podcastStore.episode.datePublished" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :manual-input="false" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="card card-border-color card-border-color-primary" id="episodes" v-show="podcastStore.episodes.length">
            <div class="card-header card-header-divider">Episodes</div>

            <div class="card-body">
                <div v-for="episode in podcastStore.episodes" :key="episode.id" class="row episode item" v-bind:class="{ active: podcastStore.episode == episode.id }">
                    <div class="col-sm-10">
                        <a :href="getEpisodeUrl(episode)" target="_blank">{{ episode.title }}</a>
                        <div>
                            <time :datetime="episode.datePublished">{{ formatDateTime(episode.datePublished) }}</time>
                            <span class="badge bg-success" v-if="episode.isPublished">Published</span>
                            <span class="badge bg-secondary" v-else>Draft</span>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <Button label="Edit" @click="editEpisode(episode)" severity="secondary" />
                    </div>
                    <audio controls="controls" preload="metadata" :src="episode.audioUrl" class="w-100"></audio>
                    <Divider type="dashed" />
                </div>

                <Paginator :totalRecords="podcastStore.totalEpisodes" :rows="pageSize" @page="podcastStore.getEpisodesPage({ showId: podcastStore.show.id, pageIndex: $event.page, pageSize: pageSize })" />
            </div>
        </section>
    </div>
</template>

<script>
//import { isDefined, useClipboard, useMagicKeys, whenever } from '@vueuse/core';
import { isDefined, useClipboard } from '@vueuse/core';
import { ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { usePodcastStore } from '@/stores/PodcastStore';
import { useSiteStore } from '@/stores/SiteStore';
import MediaService from '@/service/MediaService';
import ElasticsearchService from '@/service/ElasticsearchService';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import FileUpload from 'primevue/fileupload';
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressBar from 'primevue/progressbar';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
//import { formatDateTime, formatDateTimeISO, getTimezone } from '@/utils/ThmDateTimeUtils';
import { formatDateTime, getTimezone, secondsToDuration } from '@/utils/ThmDateTimeUtils';
import { stripHtml } from '@/utils/ThmStringUtils';

export default {
    name: 'PodcastEpisode',
    components: {
        ConfirmDialog,
        FileUpload,
        FroalaThm,
        //SiteGroupSelect,
        ProgressBar,
        LogViewerThm
    },
    setup() {
        const { copy, copied } = useClipboard();
        const siteStore = useSiteStore();
        const podcastStore = usePodcastStore();
        const mediaService = new MediaService();
        const elasticsearchService = new ElasticsearchService();
        //const keys = useMagicKeys();
        const confirm = useConfirm();
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();

        const isLoading = ref(false);
        const isUploading = ref(false);
        const pageSize = ref(5); // Number of episodes to display per page

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveEpisode());
        whenever(meta_s, () => saveEpisode());
        */

        // Watch for route changes
        watch(
            () => route.params, async (newParams, oldParams) => {
                // route.params = old params, params = new params // These stopped working - they are the same values now (?)
                if(console.log.hide) console.log(route.params, newParams);
                if(console.log.hide) console.log(route.params, oldParams);
                load(newParams.showId != oldParams.showId);
            }
        );

        // Before Mount
        onBeforeMount(() => {
            siteStore.load();
        });

        // Mounted
        onMounted(() => {
            load();
        });

        /*
        whenever(keys['Ctrl+S'], () => {
            saveEpisode();
        });
        */

        // Computed Timezone abbreviation
        const timezoneAbbrev = computed(() => {
            return getTimezone();
        });

        // Computed episode URL
        const episodeUrl = computed(() => {
            return getEpisodeUrl(podcastStore.episode);
        });

        // Computed embed code
        const embedCode = computed(() => {
            if (!podcastStore.episode || typeof podcastStore.episode.slug == 'undefined') return null;
            let domain = siteStore.site && siteStore.site.domain ? siteStore.site.domain : '';
            return `<figure><iframe width="375" height="175" src="https://${domain}/podcasts/${podcastStore.show.slug}/widget/${podcastStore.episode.id}" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></figure>`;
        });

        function load(showChanged = true) {
            // Load show, site and eposides
            if(showChanged) {
                if(route.params.showId) {
                    podcastStore.setShow(route.params.showId).then((res) => {
                        if (!podcastStore.show) {
                            toast.add({ severity: 'error', summary: 'Error Loading Show', detail: 'Podcast not found', life: 4000 });
                        } else {
                            siteStore.setSite(res.siteId);
                            podcastStore.getEpisodesPage({ showId: res.id, pageSize: pageSize.value });
                        }
                    });
                }
            }

            // Load podcast episode (if provided)
            if (route.params.episodeId) {
                podcastStore.setEpisode(route.params.episodeId).then((res) => {
                    if (!res) toast.add({ severity: 'error', summary: 'Error Loading Episode', detail: 'Episode not found', life: 4000 });
                    //siteStore.setSite(res.siteId);
                });
            } else {
                podcastStore.setEpisodeDefaults();
            }
        }

        function filterSlug(str) {
            return str
                .toLowerCase()
                .replaceAll(' ', '-')
                .replace(/[^0-9a-z-_]/gi, '');
        }

        // Calculates the time duration of an uploaded file
        // Notes: https://stackoverflow.com/a/71118143/3799374
        function setDuration(event, dest) {
            // https://ourcodeworld.com/articles/read/1036/how-to-retrieve-the-duration-of-a-mp3-wav-audio-file-in-the-browser-with-javascript
            var audio = document.createElement('audio');
            var file = event.files[0];
            var reader = new FileReader();

            if (event.files && file) {
                reader.onload = function (e) {
                    audio.src = e.target.result;
                    audio.addEventListener(
                        'loadedmetadata',
                        function () {
                            //console.log('[duration]', audio.duration);
                            //console.log("The duration of the song is of: " + audio.duration + " seconds");
                            //dest.duration = parseInt(audio.duration); // method 1

                            // Convert seconds to HH:MM:SS
                            //console.log("Converted to HH:MM:SS: ", new Date(audio.duration * 1000).toISOString());
                            //dest.duration = new Date(audio.duration * 1000).toISOString().substr(11, 8); // method 1

                            // Set duration
                            dest.duration = secondsToDuration(audio.duration, true) || '00:00:00';
                            console.log('Calculated duration:', dest.duration);

                            // If the above method fails, try this:
                            //if(!dest.duration) getAudioDuration(file);
                        },
                        false
                    );
                };

                reader.readAsDataURL(file);
            }
        }

        /*
        function getAudioDuration(file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = (e) => {
                const ctx = new AudioContext();
                const audioArrayBuffer = e.target.result;
                ctx.decodeAudioData(audioArrayBuffer, data => {
                    // this is the success callback
                    const duration = data.duration;
                    console.log('Audio file duration: ' + duration);

                }, error => {
                    // this is the error callback
                    console.error(error);
                });
            };
        }
        */

        function getEpisodeUrl(episode) {
            episode = episode || podcastStore.episode;
            //if(!(episode.id && podcastStore.show && podcastStore.show.slug && siteStore.site)) return '';
            if(!episode.id || !(podcastStore.show && podcastStore.show.slug && siteStore.site)) return '';

            //var episodeSlug = episode.title.toLowerCase().replaceAll(' ', '-').replace(/[^0-9a-z-_]/gi, '');
            //var datePath = moment(episode.datePublished).tz(AppSettings.timezone).format('YYYY/MM/DD');
            var datePath = episode.datePublished ? formatDateTime(episode.datePublished, 'yyyy/MM/dd') : null;
            //return `https://${siteStore.site.domain}/podcasts/${podcastStore.show.slug}/${datePath}/${episodeSlug}-n${episode.id}`;
            //return `https://${siteStore.site.domain}/podcasts/${podcastStore.show.slug}/${datePath}/${episodeSlug}`;
            return `https://${siteStore.site.domain}/podcasts/${podcastStore.show.slug}/${datePath}/${episode.slug}`;
        }

        function uploadPodcast(event) {
            //if (typeof event.files == 'undefined' || !event.files || typeof this.selectedShow == 'undefined' || !this.selectedShow.id) return;
            if(typeof event.files == 'undefined' || !event.files || !podcastStore.show.id) return;
            isLoading.value = true;
            isUploading.value = true;
            console.log('[uploadPodcast] files:', event.files);

            mediaService.uploadMediaObject(`podcasts/${podcastStore.show.id}`, event.files[0]).then((res) => {
                console.log('[uploadPodcast] response:', res);
                let uploadedFilePath = res[0].fullPath;
                var uploadedFileUrl = 'https://cdn.townhall.com/podcasts/' + uploadedFilePath;
                podcastStore.episode.audioUrl = uploadedFileUrl;
                setDuration(event, podcastStore.episode);
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Upload Error', detail: err.message || err });
            }).finally(() => {
                isLoading.value = false;
                isUploading.value = false;
            });
        }

        function deleteUpload() {
            //if(typeof this.selectedEpisode == 'undefined' || !this.selectedEpisode.audioUrl) return;
            if (!podcastStore.episode.audioUrl) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this upload?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    isLoading.value = true;
                    mediaService.deleteMediaObject('podcasts', podcastStore.episode.audioUrl).then((res) => {
                        console.log('🗑 Deleted:', res);
                        //toast.add({ severity: 'success', summary: 'Success', detail: 'File Deleted', life: 2000 });
                    }).catch((err) => {
                        if (console.log.hide) console.log(err);
                        //toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 3000 })
                    }).finally(() => {
                        podcastStore.episode.audioUrl = null;
                        isLoading.value = false;
                    });
                },
            });
        }

        function addNewEpisode() {
            router.push(`/manage/podcasts/${route.params.showId}/episodes`);
        }

        function editEpisode(episode) {
            router.push(`/manage/podcasts/${episode.showId}/episodes/${episode.id}`);
            window.scrollTo(0, 0);
        }

        function saveEpisode() {
            // Check required fields. Using this method because Vuelidate doesn't support object properties, only variables :/
            let requiredFields = [podcastStore.episode.title], isFormInvalid = false;
            requiredFields.forEach((elm) => {
                if(!elm) isFormInvalid = true;
            });
            if(isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 5000 });
                return;
            }

            // Set episode ID to zero for new, set undefined properties
            let episodeDefaults = { id: 0, showId: podcastStore.show.id, slug: filterSlug(podcastStore.episode.title), season: 1, episode: 0, images: {}, links: {} };
            podcastStore.episode = { ...episodeDefaults, ...podcastStore.episode };
            //podcastStore.episode.datePublished = getFormattedDateTime(podcastStore.episode.datePublished, 'yyyy-MM-ddThh:mm');
            //podcastStore.episode.datePublished = formatDateTimeISO(podcastStore.episode.datePublished);

            // Unpublish if audio file hasn't been uploaded
            if(!podcastStore.episode.audioUrl) {
                podcastStore.episode.isPublished = false;
                toast.add({ severity: 'warn', summary: 'Warning', detail: 'Episode unpublished due to missing audio file.', life: 5000 });
            }

            // Save episode
            podcastStore.saveEpisode().then((res) => {
                saveToElasticsearch(res);
                // Display notification
                toast.add({ severity: 'success', summary: 'Success', detail: 'Episode Saved', life: 3000 });
                if (res.isNew) router.push({ path: `/manage/podcasts/${res.showId}/episodes/${res.id}`, query: route.query });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Episode', detail: err.message || err, life: 3000 });
            });
        }

        function deleteEpisode() {
            if (!podcastStore.episode.id) return;

            confirm.require({
                message: 'Are you sure you want to delete this episode?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    podcastStore.deleteEpisode().then(() => {
                        elasticsearchService.siteSearchDeletePost(`podcast-${podcastStore.episode.showId}-${podcastStore.episode.id}`);
                        router.push(`/manage/podcasts/${route.params.showId}/episodes`);
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting Episode', detail: err.message || err, life: 3000 });
                    });
                },
            });
        }

        function saveToElasticsearch(episode) {
            let site = siteStore.sites?.find(({ siteId }) => siteId === podcastStore.show.siteId);
            if(podcastStore.show.includeInSearch && episode.isPublished && episode.audioUrl) { // Only index episode where show primary site is Townhall (1)
                let document = {};
                let showUrl = podcastStore.show.slug ? `https://${siteStore.site && typeof siteStore.site.domain != 'undefined' ? siteStore.site.domain : ''}/podcasts/${podcastStore.show.slug}` : null;
                if(podcastStore.show.siteId == 1) {
                    document = {
                        'post_id': episode.id,
                        'post_type': 'podcast',
                        'post_title': episode.title,
                        //'description': stripHtml(episode.description),
                        'post_content': episode.description,
                        'post_content_filtered': stripHtml(episode.description),
                        'thumbnail': {
                            'src': podcastStore.show.images.squareImage,
                            'alt': podcastStore.show.title
                        },
                        'attachment': episode.audioUrl,
                        'permalink': getEpisodeUrl(podcastStore.episode),
                        'post_date': episode.datePublished,
                        'post_modified': episode.dateModified,
                        'category': {
                            'id': podcastStore.show.id,
                            'name': podcastStore.show.title,
                            'slug': podcastStore.show.slug,
                            'url': showUrl
                        },
                        'post_author': {
                            'display_name': podcastStore.show.title,
                            'slug': podcastStore.show.slug,
                            'image': podcastStore.show.images.squareImage,
                            'url': showUrl
                        },
                        'subscription': podcastStore.show.subscription,
                        'is_published': episode.isPublished
                    };
                    elasticsearchService.siteSearchSavePost(`podcast-${podcastStore.show.id}-${episode.id}`, document);
                }

                document = {
                    post_id: episode.id,
                    post_type: 'podcast',
                    site: {
                        name: site.description,
                        domain: site.domain,
                        id: site.siteId,
                        group: site.siteGroup,
                        post_type_id: 3
                    },
                    date_published: episode.datePublished,
                    date_modified: episode.dateModified,
                    title: episode.title,
                    description: null,
                    url: getEpisodeUrl(podcastStore.episode),
                    content: episode.description,
                    content_raw: stripHtml(episode.description),
                    image: {
                        title: podcastStore.show.title,
                        url: podcastStore.show.images.squareImage
                    },
                    attachments: [
                        {
                            url: episode.audioUrl
                        }
                    ],
                    //tags: null,
                    categories: [
                        {
                            id: podcastStore.show.id,
                            name: podcastStore.show.title,
                            slug: podcastStore.show.slug,
                            url: showUrl
                        }
                    ],
                    author: {
                        name: podcastStore.show.title,
                        url: showUrl
                    },
                    subscription: podcastStore.show.subscription
                }
                let docId = `${podcastStore.show.siteId}-podcast-${episode.id}`;
                elasticsearchService.siteSearchSavePostNew(docId, document);
            } else {
                elasticsearchService.siteSearchDeletePost(`podcast-${episode.showId}-${episode.id}`);

                if(episode.id) {
                    let docId = `${podcastStore.show.siteId}-podcast-${episode.id}`;
                    console.log('DELETE:', docId);
                    elasticsearchService.siteSearchDeletePostNew(docId);
                }
            }
        }

        return {
            addNewEpisode,
            copy,
            copied,
            deleteEpisode,
            deleteUpload,
            editEpisode,
            embedCode,
            episodeUrl,
            formatDateTime,
            getEpisodeUrl,
            isDefined,
            isLoading,
            isUploading,
            pageSize,
            podcastStore,
            saveEpisode,
            siteStore,
            timezoneAbbrev,
            uploadPodcast
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .form-label {
        font-weight: 500;
    }
    .permalink {
        font-size: 90%;
    }
    .required::after {
        content: '*';
        color: rgb(255, 0, 0);
    }
    .switch-label {
        padding: 0.15em 0 0 0.5em;
        vertical-align: top;
    }
    .row.episode {
        padding-bottom: 0.35em;

        time {
            padding-right: 0.4em;
        }
    }
    audio {
        margin-top: 1em;
    }
    .btn-delete {
        margin-left: 0.4em;
    }
    button.delete.btn-outline-secondary {
        border-color: #ced4d9;
    }
    .input {
        &__tiny {
            width: 75px;
        }
        &__small {
            width: 125px;
        }
    }
}
section#episodes {
    margin-top: 0.75em;
}
label[for='PublishDate'] {
    padding-bottom: 0.5em;
}
</style>
