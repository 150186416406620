import BaseService from './BaseService';

export default class CoRegService extends BaseService {
    async getReport(params){
        return await this.apiClient.get('/api/coregistrator/json/report', { params }).then((res) => res.data);
    }
    async getOffersList() {
        return await this.apiClient.get('/api/coregistrator/json/offers').then((res) => res.data);
    }
    async getPartnersList(){
        return await this.apiClient.get(`/api/coregistrator/json/partners`).then((res) => res.data);
    }
    async getPartnersStats(report){
        return await this.apiClient.post('/api/coregistrator/json/partnersstats', report).then((res) => res.data);
    }
    async addPartner(name){
        return await this.apiClient.post('/api/coregistrator/offers/addpartner', null, {params: {name}}).then((res) => res.data);
    }
    async getReportsStats(report){
        return await this.apiClient.post('/api/coregistrator/report/getdata', report).then((res) => res.data);
    }
    async reportStatsExport(report){
        return await this.apiClient.post('/api/coregistrator/report/exportdata', report).then((res) => res.data);
    }
    async activateOffer(offer){
        return await this.apiClient.post('/api/coregistrator/offers/activate', offer).then((res) => res.data);
    }
    async getPartners(params) {
        return await this.apiClient.get('/api/coregistrator/offers/getpartners', { params }).then((res) => res.data);
    }
    async getOfferPartner(params) {
        return await this.apiClient.get('/api/coregistrator/offers/getofferpartner', { params }).then((res) => res.data);
    }
    async listItem(params) {
        return await this.apiClient.get('/api/coregistrator/offers/listitem', { params }).then((res) => res.data);
    }
    async listOffers(params) {
        return await this.apiClient.get('/api/coregistrator/offers/list', { params }).then((res) => res.data);
    }
    async editOffer(offerId){
        // return await this.apiClient.get(`/api/coregistrator/offers/edit/${offerId}`, { offerId }).then((res) => res.data);
        return await this.apiClient.get(`/api/coregistrator/offers/edit/${offerId}`).then((res) => res.data);
    }
    async deleteOffer(offerId){
        return await this.apiClient.delete(`/api/coregistrator/offers/delete/${offerId}`).then((res) => res.data);
    }
    async saveOffer(offer){
        return await this.apiClient.post('/api/coregistrator/offers/save', offer).then((res) => res.data);
    }
}
