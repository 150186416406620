import BaseService from './BaseService';

export default class SeoService extends BaseService {
    async getSeoItems(params) {
        return await this.apiClient.get('/api/seo', { params }).then((res) => res.data);
    }

    async getSeoItem(seoId) {
        //if(!seoId) return null;
        return await this.apiClient.get(`/api/seo/${seoId}`).then((res) => res.data);
    }

    async save(item) {
        return await this.apiClient.post('/api/seo', item).then((res) => res.data);
    }

    async deleteItem(seoId) {
        return await this.apiClient.delete(`/api/seo/${seoId}`).then((res) => res.data);
    }
}
