import BaseService from './BaseService';
import { seoUrl } from '@/utils/ThmUtils';

export default class TagsService extends BaseService {
    async getTags(params) {
        //let defaultParams = { pageSize: 15 };
        params = { pageSize: 15, ...params };
        return await this.apiClient.get(`/api/tag`, { params }).then((res) => res.data);
    }

    async getTagBySlug(slug) {
        return await this.apiClient.get(`/api/tag`, { params: { slug: slug } }).then((res) => {
            return res.data.length ? res.data[0] : {}; // Take the first one
        });
    }

    async getTagsAutoComplete(text) {
        return await this.apiClient
            .get(`/api/tag/AutoComplete`, {
                params: {
                    //querystring
                    text,
                },
            })
            .then((res) => res.data);
    }

    async saveTag(tag) {
        return await this.apiClient.post('/api/tag', tag).then((res) => res.data);
    }

    async deleteTag(tagId) {
        return await this.apiClient.delete(`/api/tag/${tagId}`).then((res) => res.data);
    }

    // Looks for next available slug given string. For example, if you pass "test-slug" and "test-slug" and "test-slug[2-3]" are taken,
    // it will return "test-slug4"
    async getTagSlug(str) {
        //str = str.toLowerCase();
        str = seoUrl(str);

        return await this.apiClient
            .get(`/api/tag`, { params: { slug: str, isFuzzy: true } })
            .then((res) => {
                let attempt = str,
                    idx = 1,
                    result = null;

                while (!result) {
                    let found = res.data.find((obj) => obj.slug == attempt);
                    if (!found) result = attempt;
                    idx++;
                    attempt = str + '-' + idx;
                    if (idx > 50) break; // Prevent infinite loop
                }
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }

    async reassign(oldTagId, newTagId) {
        return await this.apiClient.put(`/api/tag/reassign/${oldTagId}/${newTagId}`).then((res) => res.data);
    }
}
