<template>
    <div v-if="currentVideo.imageUrl" class="thmbrid-selected-image">
        <figure class="figure mb-2">
            <video class="embed-responsive-item mw-100" controls :poster="currentVideo.imageUrl" :key="currentVideo">
                <source :src="getVideoProperty(currentVideo, 'url')" type="video/mp4">
            </video>
            <figcaption class="figure-caption">{{ currentVideo.title }}</figcaption>
        </figure>
    </div>
    <div class="thmbrid-actions">
        <Button :label="props.buttonLabel" :disabled="props.disabled" @click="openModal" />
        <Button type="button" label="Remove Video" @click="clearVideo" severity="secondary" outlined class="ms-2" v-if="currentVideo.postId" />
    </div>

    <Dialog :header="props.buttonLabel" v-model:visible="showModal" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '60vw', '640px': '100vw' }" :style="{ width: '55vw' }" class="thmbrid-modal">
        <div id="thmbrid_top"></div>
        {{ getVideos(true) }}
        <div class="d-flex pb-3 mb-3 thmbrid-video" v-for="video in videos" :key="video.postId">
            <div class="flex-shrink-0">
                <!--<img :src="video.imageObject[0].url" :alt="video.title" v-if="video.imageObject" />-->
                <!--<a class="preview-image" :href="getAbsoluteUrl(video.url)" v-if="video.imageObject" v-bind:style="{ backgroundImage: 'url(' + video.imageObject[0].url + ')' }" :title="video.title" target="_blank"></a>-->
                <a class="preview-image" v-if="video.imageObject" v-bind:style="{ backgroundImage: 'url(' + resizeImageIfCdn(video.imageObject[0].url) + ')' }" :title="video.title" @click="chooseVideo(video)" role="button"></a>
            </div>
            <div class="flex-grow-1 ms-3">
                <!--<h5>Jhon Carter <small class="text-muted"><i>Posted on January 10, 2021</i></small></h5>-->
                <h5 class="mb-1" @click="chooseVideo(video)" x-title="video.title" role="button">{{ video.title }}</h5>
                <ul class="list-group list-group-flush">
                    <li class="list-item">Brid Video ID: <span class="brid-video-id" @click="copyToClipboard(video.extendedProperties.bridVideo.videoId)">{{ video.extendedProperties.bridVideo.videoId }}</span> <span class="copied-text text-success ps-1" v-show="copied == video.extendedProperties.bridVideo.videoId">Copied</span></li>
                    <li class="list-item">Published: {{ formatDateTime(video.datePublished, 'M/dd/yyyy hh:mm a') }}</li>
                    <li class="list-item"><a :href="getAbsoluteUrl(video.url)" target="_blank">Link <i class="fa-regular fa-arrow-up-right-from-square small"></i></a></li>
                </ul>
            </div>
        </div>

        <!-- <Button label="Load More" @click="getVideos()" severity="secondary" class="w-100" v-if="videos.length" /> -->
        <em class="d-block" v-if="Array.isArray(videos) && !videos.length">No videos found matching search criteria</em>
        <div ref="bottomSentinel" class="bottom-sentinel"></div>

        <template #header>
            <div class="input-group pe-3">
                <input type="search" class="form-control" placeholder="Keywords..." aria-label="Search" v-model="searchKeywords" @keyup.enter="submitSearch" @change="hasBeenFetched = false" />
                <button class="btn btn-primary thmim-button-primary" type="button" id="thmbrid_search_btn" @click="submitSearch">Search</button>
            </div>
        </template>
        <template #footer>
            <SpinnerThm class="spinner float-end" size="1.3em" v-show="loading" />
        </template>
    </Dialog>
</template>

<script>
import { ref, inject, onBeforeMount, watch } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { formatDateTimeISO } from '@/utils/ThmDateTimeUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { resizeImageUrl } from '@/utils/ThmImageUtils';
import { useClipboard } from '@vueuse/core';
import { promiseTimeout } from '@vueuse/core'
import { useToast } from 'primevue/usetoast';
import { subDays } from 'date-fns';
import PostService from '@/service/PostService';
import SpinnerThm from '@/components/SpinnerThm.vue';
import Dialog from 'primevue/dialog';

export default {
    name: 'VideoChooserThm',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        buttonLabel: {
            type: String,
            default: 'Choose Video'
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    components: {
        Dialog,
        SpinnerThm,
    },
    setup(props, { emit }) {
        const postService = new PostService();
        const { copy } = useClipboard();
        const appSettings = inject('appSettings');
        const toast = useToast();

        const videos = ref([]);
        const errorLoading = ref(false);
        const hasBeenFetched = ref(false);
        const loading = ref(false);
        const pageNumber = ref(0);
        const showModal = ref(false);
        const copied = ref(0);
        const searchKeywords = ref('');
        const bottomSentinel = ref(null);
        const finishedLoading = ref(false);
        const currentVideo = ref({});
        const pageSize = 15;
        const params = ref({
            postTypeId: 2,
            pageSize: 20
        });

        onBeforeMount(() => {
            // Set default query parameters
            params.value = {
                ...params.value,
                pageSize: props.pageSize,
                //datePublishedStart: formatDateTimeISO(subDays(new Date, process.env.NODE_ENV == 'development' ? 120 : 7)) // Speeds up SQL query
                datePublishedStart: formatDateTimeISO(subDays(new Date, 120)) // Speeds up SQL query by limiting to last 120 days
            }
        });

        watch(() => props.modelValue, (val) => {
            currentVideo.value = val;
        });

        // Infinite scroll trigger observer
        useIntersectionObserver(bottomSentinel, ([{ isIntersecting }]) => {
                handleInfiniteScroll(isIntersecting);
            },
            { threshold: 0 }
        );

        const handleInfiniteScroll = async (isIntersecting) => {
            if(finishedLoading.value || videos.value?.length < params.value.pageSize || loading.value) return;
            if(isIntersecting) {
                await getVideos();
            }
        };

        function getVideos(initialLoad = false) {
            if(errorLoading.value) return;
            if((initialLoad && videos.value && videos.value.length) || (hasBeenFetched.value && videos.value.length < 1)) return; // Prevent from continuously running

            loading.value = true;
            postService.getPosts({ ...params.value, pageSize: pageSize, pageIndex: pageNumber.value }).then((res) => {
                //console.log('res', res);
                if(pageNumber.value == 0) {
                    videos.value = res;
                } else {
                    videos.value.push(...res);
                }

                if(res.length < pageSize) {
                    finishedLoading.value = true;
                    console.log('Finished loading posts');
                }
            }).catch((err) => {
                errorLoading.value = true;
                toast.add({ severity: 'error', summary: err.name || 'Error Loading Videos', detail: err.message || err, life: 3000 });
            }).finally(() => {
                hasBeenFetched.value = true; // This prevents endless fetches when there are no results
                pageNumber.value = pageNumber.value ? pageNumber.value+1 : 1;
                loading.value = false;
            });
        }

        function getAbsoluteUrl(path) {
            if(appSettings.slug == 'townhall') {
                return appSettings.webroot + path;
            }
            return appSettings.webroot[process.env.NODE_ENV] + path;
        }

        function openModal() {
            showModal.value = true;
        }

        async function copyToClipboard(videoId) {
            copied.value = videoId;
            copy(videoId);
            await promiseTimeout(3000).then(() => {
                copied.value = 0;
            });
        }

        function submitSearch() {
            finishedLoading.value = false;
            params.value.title = searchKeywords.value.trim();
            pageNumber.value = 0;

            // Scroll to top
            const element = document.getElementById('thmbrid_top');
            if(element) element.scrollIntoView();

            getVideos();
        }

        function getVideoProperty(video, prop) {
            /*
            if(!video || !video.extendedProperties || !video.extendedProperties.bridVideo || !video.extendedProperties.bridVideo.videos || !video.extendedProperties.bridVideo.videos.length) return null;
            return video.extendedProperties.bridVideo.videos[0][prop];
            */
            if(!video || !video.videos || !video.videos.length) return null;
            return video.videos[0][prop];
        }

        function chooseVideo(video) {
            //console.log('video', video);

            let newVideo = {
                ...video.extendedProperties.bridVideo,
                postId: video.postId,
                datePublished: video.datePublished,
                dateModified: video.dateModified,
                imageUrl: video.imageObject[0].url,
                title: video.title,
                url: video.url
            };

            emit('update:modelValue', newVideo);
            showModal.value = false;
        }

        function clearVideo() {
            //currentVideo.value = {};
            emit('update:modelValue', {});
        }

        function resizeImageIfCdn(url) {
            //console.log()
            if(url.includes('/hodl/')) {
                return resizeImageUrl(url, '300x168');
            }
            return url;
        }

        return {
            bottomSentinel,
            chooseVideo,
            clearVideo,
            copied,
            copyToClipboard,
            currentVideo,
            formatDateTime,
            getAbsoluteUrl,
            getVideoProperty,
            getVideos,
            hasBeenFetched,
            loading,
            openModal,
            props,
            resizeImageIfCdn,
            searchKeywords,
            showModal,
            submitSearch,
            videos
        }
    }
}
</script>

<style lang="scss" scoped>
div.thmbrid-selected-image {
    max-width: 300px;

    img.figure-img {
        width: 100%;
        height: auto;
    }
}

div.thmbrid-video {
    border-bottom: 1px solid var(--bluegray-200);

    a.preview-image, span.preview-image {
        background: no-repeat center center / cover;
        display: inline-block;
        vertical-align: middle;
        width: 12.4em;
        height: 7em;
        aspect-ratio: auto 640/360;
    }

    ul.list-group {
        li.list-item {
            font-size: 0.85em;
            list-style-type: none;
            color: var(--gray-700);

            &.small {
                font-size: 0.8em;
            }
        }
    }

    .brid-video-id {
        //border-bottom: 1px dashed var(--bluegray-200);
        cursor: copy;

        &:hover {
            border-bottom: 1px solid var(--bluegray-200);
        }
    }

    &:last-of-type {
        border-bottom: unset;
    }
}
</style>
