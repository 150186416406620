
<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Redirect</div>

                <div class="card-body">
                    <div v-if="route.query.url">
                        Redirecting to: <a :href="route.query.url">{{ route.query.url }}</a>
                    </div>
                    <div v-else>
                        Error: Missing URL
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default {
    name: 'Redirect',
    setup() {
        const route = useRoute();

        onMounted(() => {
            if(route.query.url) window.location.replace(route.query.url);
        });

        return { route };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    min-height: 100px;
}
</style>