<template>
    <!-- Filter bar -->
    <div class="row mb-3">
        <div class="col">
            <section class="card">
                <div class="card-body container-fluid">
                    <div class="row filter-row g-2">

                        <div class="col-sm-10">
                            <!--<InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search... (Ctrl+K or ⌘K)" v-on:keyup.enter="updateFilter" autofocus />-->
                            <InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search..." v-on:keyup.enter="updateFilter" autofocus />
                        </div>
                        <!--
                        <div class="col-xs-12 col-sm-6 col-lg-3">
                            <SiteGroupSelect v-model="filter.siteGroup" />
                        </div>
                        -->
                        <div class="col-sm-2">
                            <Button label="" icon="far fa-search" iconPos="right" @click="updateFilter" />
                            <Button label="" icon="far fa-times" iconPos="right" severity="secondary" outlined class="ms-2" @click="clearFilter" />
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
//import { useMagicKeys, whenever } from '@vueuse/core';
//import SiteGroupSelect from '@/components/SiteGroupSelectClient.vue';

export default {
    name: 'BlockCopyFilterBar',
    components: {
        //SiteGroupSelect
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const keys = useMagicKeys();

        const filter = ref({});

        //whenever(keys['Ctrl+K'], () => document.getElementById('SearchText').select());
        //whenever(keys['Meta+K'], () => document.getElementById('SearchText').select());

        onMounted(() => {
            // Set filter bar based on query string values
            const query = Object.assign({}, route.query);
            filter.value = query;
        });

        /*
        whenever(keys['Meta+K'], () => {
            document.getElementById('SearchText').select();
        });
        */

        function updateFilter() {
            router.push({ query: filter.value });
        }

        function clearFilter() {
            filter.value = {};
            router.push({ query: filter.value });
        }

        return { clearFilter, updateFilter, filter };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    input.p-inputtext {
        height: 42px;
    }
    .btn-search {
        min-width: 125px;
    }
}
</style>
