import BaseService from './BaseService';

export default class CartoonService extends BaseService {
    async getCartoons(params) {
        return await this.apiClient.get(`/api/cartoons`, { params }).then((res) => res.data);
    }

    async getCartoon(cartoonId) {
        return await this.apiClient.get(`/api/cartoons/${cartoonId}`).then((res) => res.data);
    }

    async updateCartoon(cartoon) {
        return await this.apiClient.put('/api/cartoons', cartoon).then((res) => res.data);
    }

    async deleteCartoon(cartoonId) {
        return await this.apiClient.delete(`/api/cartoons/${cartoonId}`).then((res) => res.data);
    }
}
