<template>
    <div class="position-relative d-inline-block">
        <AutoComplete v-model="author" :class="cssClass" dropdownMode="current" :suggestions="filteredAuthor" :minLength="2" forceSelection :dropdown="false" @complete="searchAuthors($event)" field="name" optionValue="value" required placeholder="Author" :disabled="props.disabled" />
        <a href="#" v-show="!props.disabled" @click.prevent="getAuthorByUserName" class="mx-2 user-me"><i class="fa-regular fa-user"></i></a>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import AdminService from '@/service/AdminService';
import useAuth0 from '@/use/Auth0';
import { isDefined } from '@vueuse/core';

export default {
    name: 'AuthorsAutoComplete',
    emits: ['update:modelValue'],
    props: {
        modelValue: [String, Object],
        authorId: [String, Number],
        authorTypeId: {
            type: Number,
            default: null
        },
        class: String,
        disabled: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit }) {
        const adminService = new AdminService();
        const { user } = useAuth0();
        const filteredAuthor = ref([]);

        onMounted(() => {
            if(props.authorId) getAuthor(props.authorId);
        });

        watch(() => props.authorId, (authId, oldValue) => {
            if(authId && authId != oldValue) {
                console.log('[watch] authorID from autocomplete:', authId);
                getAuthor(authId);
            }
        });

        const cssClass = computed(() => {
            return props.class;
        });

        const author = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                emit('update:modelValue', value);
            }
        });

       async function getAuthor(authorId) {
            let item = await adminService.getAuthor(authorId);
            if(isDefined(item)) {
                //let selectItem = { name: item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : item.nickName, value: item.authorId, slug: item.slug };
                let _name = item.nickName;
                if(item.firstName || item.lastName) {
                    _name = (item.firstName || '') + ' ';
                    _name += item.lastName || '';
                }
                let selectItem = { name: _name.trim(), value: item.authorId, slug: item.slug };

                author.value = selectItem;
            } else {
                author.value = null;
            }
        }

        async function searchAuthors(event) {
            filteredAuthor.value = await adminService.getAuthorAutoComplete(event.query, props.authorTypeId);
        }

        async function getAuthorByUserName() {
            filteredAuthor.value = await adminService.getAuthorAutoComplete(user.value.name, props.authorTypeId);
            if(isDefined(filteredAuthor.value)) {
                author.value = filteredAuthor.value[0];
            } else {
                author.value = null;
            }
        }

        return {
            author,
            cssClass,
            filteredAuthor,
            getAuthorByUserName,
            props,
            searchAuthors
        }
    }
}
</script>

<style lang="scss" scoped>
.user-me {
    position: absolute;
    right: 10px;
    top: 8px;
}
</style>
