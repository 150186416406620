<template>
    <!-- Filter bar -->
    <div class="row mb-3">
        <div class="col">
            <section class="card">
                <div class="card-body container-fluid">
                    <div class="row filter-row g-2">

                        <!--<div class="col-xs-12 col-lg-8">-->
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <!--<InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search... (Ctrl+K or ⌘K)" v-on:keyup.enter="updateFilter" autofocus />-->
                            <InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search..." v-on:keyup.enter="updateFilter" autofocus />
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <InputText class="form-control" v-model="filter.userName" id="Name" placeholder="User Name..." v-on:keyup.enter="updateFilter" />
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <Dropdown id="LogTypeId" v-model="filter.logTypeId" :options="logStore.logTypes" optionLabel="logTypeName" optionValue="logTypeId" placeholder="Log Type" class="w-100" :showClear="true" />
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <Calendar
                                v-model="filter.dateStart"
                                date-format="mm/dd/yy"
                                :show-icon="true"
                                :manual-input="false"
                                :show-button-bar="true"
                                class="w-100"
                                placeholder="Date Start"
                            />

                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4">
                            <Calendar
                                v-model="filter.dateEnd"
                                date-format="mm/dd/yy"
                                :show-icon="true"
                                :manual-input="false"
                                :show-button-bar="true"
                                class="w-100"
                                placeholder="Date End"
                            />
                        </div>
                        <div class="col-md-6 col-lg-4 d-none d-md-block">
                            <Button label="" icon="far fa-search" iconPos="right" @click="updateFilter" />
                            <Button label="" icon="far fa-times" iconPos="right" severity="secondary" outlined class="ms-2" @click="clearFilter" />
                        </div>
                        <!-- Small Displays -->
                        <div class="col-6 d-block d-md-none">
                            <Button label="Search" class="w-100" @click="updateFilter" />
                        </div>
                        <div class="col-6 d-block d-md-none">
                            <Button label="Clear" outlined class="ms-2 w-100" @click="clearFilter" />
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, onBeforeMount, onMounted, watch } from 'vue';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
//import AdminService from '@/service/AdminService';
import { useLogStore } from '@/stores/LogStore';
import { subMonths } from 'date-fns';

export default {
    name: 'LogsFilterBar',
    setup() {
        //const adminService = new AdminService();
        const logStore = useLogStore();
        const router = useRouter();
        const route = useRoute();
        //const keys = useMagicKeys();

        const filter = ref({
            dateStart: new Date(formatDateTime(subMonths(new Date, 2))) // Default start date is 2 months ago
        });

        watch(
            () => route.query, (query, fromQuery) => {
                if(console.log.hide) console.log({ from: fromQuery, to: query });
                if(query.logTypeId) query.logTypeId = parseInt(query.logTypeId);
                if(query.dateStart) query.dateStart = formatDateTime(query.dateStart, 'MM/dd/yyyy');
                if(query.dateEnd) query.dateEnd = formatDateTime(query.dateEnd, 'MM/dd/yyyy');
                //filter.value = query;
            }
        );

        onBeforeMount(() => {
            logStore.load();
            /*
            adminService.getLogTypes().then((res) => {
                logTypes.value = res;
            });
            */
        });

        onMounted(() => {
            const query = { ...filter.value, ...route.query };
            if(query.logTypeId) query.logTypeId = parseInt(query.logTypeId);
            filter.value = query;
        });

        /*
        whenever(keys['Meta+K'], () => {
            document.getElementById('SearchText').select();
        });
        */
        //whenever(keys['Ctrl+K'], () => document.getElementById('SearchText').select());
        //whenever(keys['Meta+K'], () => document.getElementById('SearchText').select());

        function updateFilter() {
            if(filter.value.dateStart) filter.value.dateStart = formatDateTime(filter.value.dateStart, 'MM/dd/yyyy');
            if(filter.value.dateEnd) filter.value.dateEnd = formatDateTime(filter.value.dateEnd, 'MM/dd/yyyy');
            let query = { ...route.query, ...filter.value };
            delete query.pageIndex;
            //router.push({ query: { ...route.query, ...filter.value } });
            router.push({ query });
        }

        function clearFilter() {
            filter.value = {};
            router.push({ query: filter.value });
        }

        return { clearFilter, updateFilter, filter, logStore, route };
    }
};
</script>

<style lang="scss" scoped>
div.card-body {
    input.p-inputtext {
        height: 42px;
    }
    .btn-search {
        min-width: 125px;
    }
}
</style>
