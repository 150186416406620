import BaseService from './BaseService';
import { getAppSettings } from '@/utils/ThmUtils';

export default class ElasticsearchService extends BaseService {
    async siteSearchSavePost(documentId, post) { // postType = "article", "post"
        if(!documentId || !post) {
            console.error('Elasticsearch Error: Post and documentId may not be null');
            return;
        }
        return await this.apiClient.post(`/api/elasticsearch/sitesearch/post/${documentId}`, post).then((res) => res.data);
    }

    // Special, accepts Core.Models.Admin.Post type
    async siteSearchSaveArticle(post) {
        //console.log('Save to ElasticSearch:', post);
        if(!post || !post.postId) {
            console.error('Elasticsearch Error: Post is null or post ID is empty');
            return;
        }
        let endpoint = `/api/elasticsearch/sitesearch/article/post-${post.categoryId}-${post.postId}`;
        //console.log('ElasticSearch Endpoint POST:', endpoint);
        return await this.apiClient.post(endpoint, post).then((res) => res.data);
    }

    async siteSearchDeletePost(postId) {
        return await this.apiClient.delete(`/api/elasticsearch/sitesearch/${postId}`).then((res) => res.data);
    }

    /*****************************************************************************************
     * New index: Posts
     *****************************************************************************************/

    async siteSearchSavePostNew(documentId, post) { // postType = "article", "post"
        if(!post.post_id) return;
        if(!documentId || !post) {
            console.error('Elasticsearch Error: Post and documentId may not be null');
            return;
        }
        return await this.apiClient.post(`/api/elasticsearch/sitesearch/post/new/${documentId}`, post).then((res) => res.data);
    }

    async siteSearchSaveArticleNew(post) {
        //console.log('[ES] Save:', post);
        const appSettings = getAppSettings();
        if(!post || !post.postId) {
            console.error('Elasticsearch Error: Post is null or post ID is empty');
            return;
        }
        let endpoint = `/api/elasticsearch/sitesearch/article/new/${appSettings.siteId}-post-${post.postId}`;
        //console.log('ElasticSearch Endpoint POST:', endpoint);
        return await this.apiClient.post(endpoint, post).then((res) => res.data);
    }

    /*
    async siteSearchDeletePostNew(postId) {
        //console.log('[ES] Delete:', postId);
        const appSettings = getAppSettings();
        return await this.apiClient.delete(`/api/elasticsearch/sitesearch/new/${appSettings.siteId}-post-${postId}`).then((res) => res.data);
    }
    */
    async siteSearchDeletePostNew(docId) {
        //console.log('[ES] Delete:', docId);
        return await this.apiClient.delete(`/api/elasticsearch/sitesearch/new/${docId}`).then((res) => res.data);
    }
}
