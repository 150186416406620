<template>
    <div class="col-md-5 mb-3">
        <div class="card card-border-color card-border-color-primary">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="text-nowrap">
                            <span v-if="route.params.categoryId || postStore.filterPosts.categoryId">{{ getCategory(route.params.categoryId || postStore.filterPosts.categoryId, 'name') }}</span>
                            <span v-else>Posts</span>
                        </div>
                    </div>
                    <div class="col-md-auto">
                        <div class="hstack">
                            <SelectButton @change="postStore.filter()" class="p-button-sm select-button-status" v-model="postStore.filterPosts.status" :options="postStore.statusOptions" optionValue="value" dataKey="value">
                                <template #option="slotProps">
                                    <span class="circle" :class="slotProps.option.css">&nbsp;</span>&nbsp;
                                    <span class="small">{{ slotProps.option.label }}</span>
                                </template>
                            </SelectButton>
                            <!-- <Badge value="2" class="mr-2"></Badge> -->
                            <!-- <Button v-badge="2" @click="onRefresh()" icon="pi pi-refresh" title="Refresh list ...." iconPos="right" class="p-button-secondary p-button-text p-button-sm ms-2" /> -->
                            <button @click="onRefresh()" type="button" class="btn btn-light position-relative ms-2">
                                <i class="pi pi-refresh"></i>

                                <span v-if="postStore.newPosts > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {{ postStore.newPosts }}
                                    <span class="visually-hidden">Refresh list ...</span>
                                </span>
                            </button>
                            <div class="ms-auto d-none" v-show="!online">
                                <span class="badge rounded-pill bg-danger sm" v-tooltip="'You are offline, edits to your post will be autosaved locally.'">{{ isOnline }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex"></div>
            </div>

            <div v-if="!postStore.isLoading" class="card-body">
                <div v-for="p in postStore.posts" :key="p.postId" class="row g-2 border-bottom py-2" v-bind:class="{ 'active-row': postEditStore.postId == p.postId }">
                    <div class="col-md-auto d-none d-md-block">
                        <SplitButton label="Edit" :model="setEditButtons(p)" @click="onEdit(p)" class="p-button-sm" />
                    </div>
                    <div class="col-md-auto d-none d-md-block">
                        <img class="preview-image border" v-if="hasImg(p.imageObject)" :src="getImage(p.imageObject)" />
                        <svg v-else class="shadow-sm" width="75" height="58" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="A generic square placeholder image" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>A generic square placeholder image</title>
                            <rect width="100%" height="100%" fill="#868e96"></rect>
                            <text x="5%" y="50%" fill="#dee2e6" dy=".3em">No Image</text>
                        </svg>
                    </div>

                    <div class="col">
                        <!--<a href="#" @click.prevent="onEdit(p.postId)">{{ p.title }}</a>-->
                        <a href="#" @click.prevent="onEdit(p)">{{ getActiveHeadline(p) }}</a>
                        <i v-show="appSettings.features.postEditor?.featuredPostCheckbox && p.extendedProperties.isFeatured" class="pi pi-star-fill ps-1 color-restricted" v-tooltip.right="'Featured Post'"></i>
                        <br />
                        <div class="muted-info text-muted">
                            <dl>
                                <dd>
                                    <a class="text-muted" @click="postStore.onAuthor(p.author.authorId)" href="#">{{ p.author && p.author.name ? p.author.name : p.author.nickName }}</a>
                                    <span v-if="p.userIdLock" class="ms-3 me-1 far fa-lock text-danger"></span>
                                    <span v-if="p.userIdLock">{{ p.userIdLock + ' is currently editing' }}</span>
                                </dd>
                                <dd v-if="p.subscription > 1">
                                    <img class="thm-vip-sublevel" :src="getVipBadge(p.subscription)" alt="Premium" />
                                </dd>
                                <dd>
                                    <publish-label-status :datetime="p.datePublished" :status="p.status" :showTimezone="false" />
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div class="col-md-auto">
                        <div class="text-muted muted-info me-2 fs-5">
                            <dl>
                                <!-- <dd v-if="p.userIdLock">
                                    <span v-tooltip="p.userIdLock + ' is currently editing'" class="far fa-lock text-danger"></span>
                                </dd> -->
                                <dd>
                                    <!-- <i class="far fa-exclamation-circle text-danger" v-tooltip="'You have 12 characters until the 6th ad is placed.'"></i> -->
                                    <ad-stats-tooltip :postStats="p.extendedProperties.postStats" />
                                </dd>
                                <dd v-if="p.extendedProperties && p.extendedProperties.adsDisabled">
                                    <span v-tooltip="'Ads Disabled'" class="far fa-ad"></span>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

                <!--<Paginator v-model:first="postStore.filterPosts.pageIndex" :rows="parseInt(postStore.filterPosts.pageSize)" :totalRecords="parseInt(postStore.filterPosts.total)" @page="postStore.onPage($event)" alwaysShow="false"></Paginator>-->
                <PaginationThm v-model="postStore.filterPosts.pageIndex" :pageSize="parseInt(postStore.filterPosts.pageSize)" :totalRecords="parseInt(postStore.filterPosts.total)" @page="postStore.onPage($event)" class="mt-2" :alwaysShow="false" />
            </div>
            <div v-else class="card-body text-center">
                <SpinnerThm class="spinner" size="3em" />
            </div>
            <div v-if="postStore.posts.length == 0 && !postStore.isLoading" class="card-body text-center">
                <p>No posts found...</p>
            </div>
        </div>
    </div>
</template>

<script>
import PublishLabelStatus from '@/components/PublishLabelStatus.vue';
import AdStatsTooltip from '@/components/AdStatsTooltip.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';
import { hasImg, getImage, getActiveHeadline } from '@/utils/ThmUtils';
import { getVipBadge } from '@/utils/ThmImageUtils';
//import { getPermalink } from '@/utils/ThmUtils';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import PaginationThm from '@/components/PaginationThm.vue';
import { ref, computed, inject } from 'vue';
import { useClipboard, useOnline } from '@vueuse/core';
// import { useConfirm } from 'primevue/useconfirm';
// import { useToast } from 'primevue/usetoast';
import { usePostStore } from '@/stores/PostStore';
import { usePostEditStore } from '@/stores/PostEditStore';
import { useRoute, useRouter } from 'vue-router';

export default {
    name: 'PostList',
    emits: ['save-post', 'unlock-post'],
    components: {
        PublishLabelStatus,
        AdStatsTooltip,
        PaginationThm,
        SpinnerThm,
    },
    setup() {
        const appSettings = inject('appSettings');
        const { copy } = useClipboard();

        const online = useOnline();
        //const confirm = useConfirm();
        //const toast = useToast();
        const isOnline = computed(() => (online.value ? '' : 'Offline'));
        const postStore = usePostStore();
        const postEditStore = usePostEditStore();
        const router = useRouter();
        const route = useRoute();

        //const editMode = ref(false);
        const editButtons = ref([
            /*
            {
                label: 'Permalink',
                icon: 'pi pi-external-link',
                command: () => {
                    window.location.href = 'https://vuejs.org/';
                },
            }
            */
        ]);

        function setEditButtons(post) {
            let url = appSettings.webroot[process.env.NODE_ENV] + post.url;
            let buttons = [
                {
                    label: 'Copy Link',
                    icon: 'pi pi-copy',
                    command: () => {
                        copy(url);
                    },
                }
            ];

            if(post.status == 1) {
                buttons.push(
                    {
                        label: 'Open in New Tab',
                        icon: 'pi pi-external-link',
                        command: () => {
                            window.open(url, '_blank');
                        },
                    }
                );
            }

            return buttons;
        }

        function getCategory(categoryId, fld) {
            if(!postStore.categoryList || !postStore.categoryList.length) return '';

            let category = postStore.categoryList.find((obj) => obj.categoryId == categoryId);
            if(!category) return '';

            return fld ? category[fld] : category;
        }

        async function onEdit(post) {
            //let filterPosts = postStore.filterPosts;
            //handled in PostEdit
            //router.push({ name: 'Posts', params: { categoryId: post.categoryId, id: post.postId }, query: filterPosts });
            //router.push({ name: 'Posts', params: { id: post.postId }, query: filterPosts });
            let query = route.query;
            delete query.total;
            if(route.params.categoryId) {
                router.push({ name: 'PostsCategory', params: { categoryId: route.params.categoryId, id: post.postId }, query: query });
            } else {
                router.push({ name: 'Posts', params: { id: post.postId }, query: query });
            }

            // Scroll to top on mobile
            if(deviceIsMobile()) {
                const postEditorTop = document.getElementById('post_editor_top');
                postEditorTop.scrollIntoView({ behavior: 'smooth' });
            }
        }
        function onRefresh() {
            postStore.getPosts(postStore.filterPosts);
            postStore.getPostsCount(postStore.filterPosts);
        }
        /*
        function hasImg(images) {
            return ThmUtils.hasImg(images);
        }
        function getImage(images) {
            return ThmUtils.getImage(images);
        }
        */
        // function newPost() {
        //     console.log('newpost');
        //     //this.$router.push({ name: 'Posts', params: { categoryId: this.paging.categoryId }, query: this.$route.query })
        //     this.$emit('on-new-post');
        // }
        return {
            appSettings,
            editButtons,
            getActiveHeadline,
            getCategory,
            getImage,
            getVipBadge,
            hasImg,
            isOnline,
            onEdit,
            online,
            onRefresh,
            postEditStore,
            postStore,
            route,
            setEditButtons
        };
    },
};
</script>

<style lang="scss" scoped>
img.preview-image {
    max-width: 75px;
    height: auto;
}
.active-row {
    background-color: var(--blue-100);
}
.sm {
    font-size: 10px;
}
div.select-button-status {
    .p-button {
        padding: 0.3rem 0.7rem;
    }
}
</style>

<style lang="scss">
// Shrink size of post status buttons:
div.select-button-status {
    div.p-button {
        padding: 0.3rem 0.4rem !important;
    }
}
</style>
