<template>
    <textarea :id="uuid" v-html="modelValue" :ref="uuid" />
</template>

<script>
import FroalaEditor from 'froala-editor'
import { uuid } from 'vue-uuid';

// Froala plugins: https://froala.com/wysiwyg-editor/docs/plugins/
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/track_changes.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Third-party
//import 'froala-editor/js/third_party/font_awesome.min'; // Froala only support Font Awesome 4 :(

// Syntax highlighting requires CodeMirror: https://thiagoborg.es/blog/2018/08/30/syntax-highlight-on-froala-editor-using-webpacker/
import CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/lib/codemirror.css';

export default {
    name: "FroalaText",
    emits: ['update:modelValue', 'change', 'keyup'],
    data() {
        return {
            uuid: 'froala_' + uuid.v4().replaceAll('-', ''), // Remove hyphens, must begin with letter
            currentConfig: null,
            editorInitialized: false,
            defaultConfig: {
                //placeholderText: 'Type something here',
                htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'script', '.fa'],
                htmlRemoveTags: ['base', 'html', 'style'],
                charCounterCount: false,
                codeMirror: false,
                htmlExecuteScripts: false,
                htmlUntouched: false,
                imagePaste: false,
                imagePasteProcess: false,
                linkAlwaysBlank: true,
                heightMin: 200,
                toolbarSticky: false,
                toolbarStickyOffset: 50,
                videoMove: true,
                videoResize: false,
                wordPasteModal: true,
                iconsTemplate: 'font_awesome_5r'
            },
            presets: {
                standard: {
                    toolbarButtons: ['html', 'bold', 'italic', 'quote', 'undo', 'redo', 'paragraphFormat', 'formatOLSimple', 'formatUL', 'align', 'insertLink', 'insertImage', 'insertVideo', 'fontAwesome', 'clearFormatting', 'fullscreen', 'help'], // TODO: paragraphFormat, OL, UL, socialEmbed, insertImage, insertVideo, fullscreen
                    codeMirror: true, // Syntax highlighting enabled by default
                    toolbarSticky: true
                },
                basic: {
                    toolbarButtons: ['bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'],
                    charCounterCount: false
                }
            },
            editor: null
        };
    },
    props: {
        type: String, // Values: null (default), "basic", "standard"
        config: Object, // Option overrides for toolbar style
        modelValue: String,
        id: String
    },
    /*
    watch: {
        modelValue: (val) => {
            console.log('watch', val);
        }
    },
    watch: {
        modelValue: {
            //immediate: true,
            handler(newValue, oldValue) {
                //document.getElementById(this.uuid).value = newValue;
                if(this.editor) this.editor.html.set(newValue);
                console.log("CALLED", newValue, oldValue);
            }
        }
    },
    */
    methods: {
        updateContent(contentChanged = false) {
            if (!this.editorInitialized) return;

            // Update data model
            this.$emit('update:modelValue', this.editor.html.get());

            // Emit keyup and change events - I couldn't find a way to emit/forward all input $listeners in Vue.js 3, so we'll do them manually
            if(contentChanged) {
                this.$emit('change', {elementId: this.uuid, ...this.editor});
            } else {
                this.$emit('keyup', {elementId: this.uuid, ...this.editor})
            }
        }
    },
    mounted() {
        var self = this;

        // Set configuration object
        this.currentConfig = Object.assign(this.defaultConfig, this.presets[this.type]);
        this.currentConfig = Object.assign(this.currentConfig, this.config);

        // Convert "true" to CodeMirror object
        if(this.currentConfig.codeMirror === true) this.currentConfig.codeMirror = CodeMirror;

        // Add event handlers
        this.currentConfig.events = {
            'contentChanged': function() {
                self.updateContent(true);
            },
            'keyup': function() {
                self.updateContent();
            }
        }

        // Initialize editor
        this.editor = new FroalaEditor(
            `#${this.uuid}`,
            this.currentConfig
        );

        this.editorInitialized = true;
    },
    updated() {
        //console.log('updated');

        // This is a total hack. There doesn't seem to be a method to simply update the editor when the modelValue changes (for example, when you click a different record)
        //this.editor.html.set(document.getElementById(this.uuid).value);
        //console.log('this.editor.html.set', this.editor.html.get());
        //console.log('value', document.getElementById(this.uuid).value);

        //this.editor.html.set(this.modelValue);
        //this.editor.events.trigger('refresh', [], true);
        //this.updateContent();

        // Need to figure out how to "resync" editor contents with what in the underlying tag
        /*
        this.editor = new FroalaEditor(
            `#${this.uuid}`,
            this.currentConfig
        );
        this.editorInitialized = true;
        */
        //this.editor.html.set(this.modelValue || '');
    }
};
</script>

<!-- Hide Froala advertisement -->
<style lang="scss">
div.fr-view {
    a {
        text-decoration: underline;
    }
}
#fr-logo {
    display: none;
}
</style>