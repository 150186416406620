import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import VirtualPageService from '@/service/VirtualPageService';
import { formatDateTime, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { getAppSettings } from '@/utils/ThmUtils';

const adminService = new AdminService();
const virtualPageService = new VirtualPageService();

export const useVirtualPageStore = defineStore('VirtualPages', {
    state: () => ({
        loading: false,
        pages: [],
        page: {},
        totalPages: 0,
        /*
        templateViews: [
            { label: "Article", value: "Article" },
            { label: "Article - Most Popular", value: "Article-MostPopular" },
            { label: "Right Rail", value: "Right Rail" },
            { label: "No Right Rail", value: "No Right Rail" },
            { label: "Sponsored", value: "Sponsored" }
        ]
        */
        templateViews: []
    }),
    /*
    getters: {
        totalPages() {
            return this.pages.length && typeof this.pages[0].totalRows != 'undefined' ? this.pages[0].totalRows : 0;
        }
    },
    */
    actions: {
        load() {
            const appSettings = getAppSettings();
            if(appSettings.siteId == 1) {
                this.templateViews = [{ label: "Right Rail", value: "RightRail" }];
            }
        },
        async getVirtualPages(params, pageSize = 15) {
            this.loading = true;
            return await virtualPageService.getVirtualPages({ pageSize: pageSize, ...params }).then((res) => {
                this.pages = res.virtualPages;
                this.totalPages = res.count;
                return this.pages;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async getVirtualPage(virtualPageId, setStore = false) {
            let virtualPage = null;
            // See if virtual page exists in paged results (if any). This saves an API lookup.
            if(this.pages.length) {
                virtualPage = this.pages.find((obj) => obj.virtualPageId == virtualPageId);
            }

            // If not, fetch by virtual page ID
            if(virtualPage && virtualPage.virtualPageId) {
                if(setStore) {
                    this.page = virtualPage;
                    this.page.datePublished = isEmptyDate(this.page.datePublished) ? null : formatDateTime(this.page.datePublished);
                    this.page.dateExpires = isEmptyDate(this.page.dateExpires) ? null : formatDateTime(this.page.dateExpires);
                }
                return virtualPage;
            } else {
                this.loading = false;
                return virtualPageService.getVirtualPage(virtualPageId).then((res) => {
                    if(setStore) {
                        this.page = res;
                        this.page.datePublished = isEmptyDate(this.page.datePublished) ? null : formatDateTime(this.page.datePublished);
                        this.page.dateExpires = isEmptyDate(this.page.dateExpires) ? null : formatDateTime(this.page.dateExpires);
                    }
                    return res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async setVirtualPage(virtualPageId) {
            return this.getVirtualPage(virtualPageId, true);
        },
        async save() {
            this.loading = true;
            let isNew = !this.page.virtualPageId || this.page.virtualPageId < 1;
            var page = { ...this.page }; // Cloning to avoid date formatting from being reactive

            // Format dates for MS SQL
            //page.datePublished = page.datePublished ? formatDateTimeISO(page.datePublished) : null;
            //page.dateExpires = page.dateExpires ? formatDateTimeISO(page.dateExpires) : null;
            page.datePublished = page.datePublished ? formatDateTime(page.datePublished, 'yyyy-MM-dd HH:mm:ss') : null;
            page.dateExpires = page.dateExpires ? formatDateTime(page.dateExpires, 'yyyy-MM-dd HH:mm:ss') : null;

            return await virtualPageService.getVirtualPage(page.virtualPageId).then((original) => { // Fetch original
                return virtualPageService.saveVirtualPage(page).then((res) => { // Save virtual page
                    if(original) {
                        original.datePublished = original.datePublished ? formatDateTime(original.datePublished, 'yyyy-MM-dd HH:mm:ss') : null;
                        original.dateExpires = original.dateExpires ? formatDateTime(original.dateExpires, 'yyyy-MM-dd HH:mm:ss') : null;
                        delete original.dateModified;
                    }
                    delete page.dateModified;
                    adminService.log({ logTypeId: 11, referenceId: res.virtualPageId, message: (isNew ? 'Added: ' : 'Modified: ') + res.title, content: JSON.stringify(page, null, 2), original: isNew ? null : JSON.stringify(original, null, 2), language: 'json' });
                    res.isNew = isNew;
                    //this.page = res;
                    return res;
                }).catch((error) => {
                    console.error(error);
                    throw(error);
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        async delete() {
            if(!this.page) return;
            this.loading = true;

            return await virtualPageService.deleteVirtualPage(this.page.virtualPageId).then(() => {
                adminService.log({ logTypeId: 11, referenceId: this.page.virtualPageId, message: 'Delete: ' + this.page.title, content: JSON.stringify(this.page, null, 2), language: 'json' });
                this.page = {};
                return this.page;
            }).catch((error) => {
                throw(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        setDefaults() {
            const appSettings = getAppSettings();
            let templateViewDefault = this.templateViews.length ? this.templateViews[0].value : null;
            this.page = {
                datePublished: formatDateTime(),
                templateView: templateViewDefault,
                siteGroup: appSettings.siteGroup
            }
        }
    },
});
