<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-sm-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">Site Store Demo (options API)</div>
                    <div class="card-body">
                        <p>Site list:</p>

                        <ul>
                            <li v-for="site of siteStore.sites" :key="site.siteId">
                                <a :href="site.baseUrl">{{ site.domain }}</a>
                            </li>
                        </ul>

                        <hr />
                        <p>Enter a site ID (1-7):</p>

                        <div class="input-group mb-3">
                            <input type="number" class="form-control" v-model="selectedSiteId" />
                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="changeSite">Load</button>
                        </div>

                        <ul v-if="siteStore.site">
                            <li><strong>Domain:</strong> {{ siteStore.site.domain }}</li>
                            <li>
                                <strong>Slug:</strong> <span class="font-monospace">{{ siteStore.site.slug }}</span>
                            </li>
                            <li><strong>URL:</strong> {{ siteStore.site.baseUrl }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { useSiteStore } from '@/stores/SiteStore';

export default {
    name: 'AlertBar',
    data() {
        return {
            siteStore: useSiteStore(),
            selectedSiteId: 1,
        };
    },
    beforeMount() {
        this.siteStore.load();
    },
    mounted() {
        // Get site #3 asynchronously
        /*
        this.siteStore.getById(3).then((res) => {
            console.log('Site 3:', res);
        });
        */
    },
    methods: {
        changeSite() {
            console.log('Change site:', this.selectedSiteId);
            this.siteStore.setSite(this.selectedSiteId);
        },
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    input[type='number'] {
        max-width: 200px;
    }
}
</style>
