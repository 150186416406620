<template>
    <div class="card card-border-color card-border-color-primary" id="article_sources">
        <div class="card-header card-header-divider">
            <Button label="Add External" severity="secondary" outlined class="p-button-smaller float-end" @click="addExternalArticle()" title="Add External Article" :disabled="layoutStore.isLocked && layoutStore.location.postLockStatus.userIdLock != user.name" v-show="props.addExternal" />
            Post Sources
            <a href="#" class="far fa-arrow-rotate-right text-muted small" @click="refreshPosts" title="Refresh Posts"></a>
        </div>

        <div class="card-body site-posts">
            <Accordion lazy :activeIndex="activeTabIndex" @tab-open="onExpanded" @update:activeIndex="setActiveTab">
                <AccordionTab v-for="(source, index) in sources" :key="source.postTypeId" :header="source.label">
                    <div class="text-center" v-show="sources[index].posts == null">
                        <SpinnerThm class="spinner" size="3em" />
                    </div>

                    <div v-for="post in sources[index].posts" :key="post.postId" class="post" :class="{ exists: isActive(post) }">
                        <!-- <SplitButton label="Save" menuButtonIcon="pi pi-ellipsis-v" :model="getActions(element)" class="hide-dropdown-button" severity="secondary" text /> -->
                        <SplitButton :model="getActions(post)" :key="post.postId" :disabled="isLockedByOtherUser || postExists(post)" v-if="props.queueSupport && appSettings.features.homePageManager.showQueue" severity="secondary" outlined menuButtonIcon="pi pi-chevron-down" class="ms-1 mb-2 mt-1 hide-dropdown-button float-end" />
                        <Button label="" icon="pi pi-chevron-right" iconPos="right" @click="props.store.insert(post, 'live')" :disabled="isLockedByOtherUser || postExists(post)" v-show="!postExists(post)" severity="secondary" text class="p-button-lg ms-1 float-end" v-else />

                        <h6 :x-post-id="post.postId">
                            <a :href="getEditLink(post)" target="_blank" class="small">{{ getActiveHeadline(post)}}</a>
                        </h6>
                        <img :src="getVipBadge(post.subscription)" v-if="post.subscription > 1" class="vip-badge" />
                        <small class="d-block smaller">
                            {{ post.author && post.author.name ? post.author.name : post.author?.nickName }} &bull; {{ formatDateTime(post.datePublished) }} {{ getTimezone() }}
                            <a :href="getPermalink(post)" class="pi pi-link article-link" target="_blank" v-show="!props.queueSupport || (props.store.$id == 'Layout' && !appSettings.features.homePageManager.showQueue)"></a>
                        </small>
                    </div>
                    <Button label="Load More" @click="getPosts(index)" severity="secondary" class="mt-2 w-100" />
                </AccordionTab>
            </Accordion>
        </div>
    </div>

    <Dialog header="Header" v-model:visible="showAddExternalDialog" @hide="closeAddExternalDialog" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{width: '50vw'}">
        <template #header>
            <h5>Add External Article</h5>
        </template>

        <form>
            <div class="mb-3">
                <label for="Title" class="form-label">Headline</label>
                <FroalaThm v-model="layoutStore.post.customProperties.headline" id="Title" :config="froalaColorsConfig.colorOnly" />
            </div>

            <div class="mb-3">
                <InputText type="text" class="form-control" v-model="layoutStore.post.customProperties.contributor" id="Contributor" placeholder="Contributor" />
            </div>

            <div class="mb-3">
                <InputText type="text" class="form-control" v-model="layoutStore.post.customProperties.url" id="Url" placeholder="https://" />
            </div>

            <div class="mb-3 row">
                <div class="col-6">
                    <label for="DatePublished" class="form-label d-block">Published Date/Time</label>
                    <Calendar id="DatePublished" v-model="layoutStore.post.postFields.datePublished" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :manual-input="false" />
                    <label for="DateLive" class="form-label d-block mt-3">Live Date/Time (ET)</label>
                    <Calendar id="DateLive" v-model="layoutStore.post.dateLive" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :manual-input="false" />
                </div>
                <div class="col-6">
                    <ImageLibrary v-model="layoutStore.post.customProperties.image" />
                </div>
            </div>
        </form>

        <template #footer>
            <SplitButton label="Add Article" icon="pi pi-chevron-down" :model="saveMenuCommands" class="hide-dropdown-button dropdown-botton-save" v-if="props.queueSupport && appSettings.features.homePageManager.showQueue" />
            <Button icon="pi pi-plus" label="Add Article" @click="enqueueExternalArticle('live');" class="p-button-sm" iconPos="left" v-else />
        </template>
    </Dialog>
</template>

<script>
import { ref, computed, inject, onBeforeMount } from 'vue';
import { formatDateTime, formatDateTimeET, getTimezone } from '@/utils/ThmDateTimeUtils';
import { getActiveHeadline } from '@/utils/ThmUtils';
import { useLayoutStore } from '@/stores/LayoutStore';
import { useSiteStore } from '@/stores/SiteStore';
import { usePostStore } from '@/stores/PostStore';
import { getVipBadge } from '@/utils/ThmImageUtils';
import { isValidURL } from '@/utils/ThmStringUtils';
import { useToast } from 'primevue/usetoast';
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';
import PostService from '@/service/PostService';
import SpinnerThm from '@/components/SpinnerThm.vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'HomePageManagerArticleSourcesNew',
    components: {
        FroalaThm,
        ImageLibrary,
        SpinnerThm,
    },
    props: {
        addExternal: {
            type: Boolean,
            default: true
        },
        queueSupport: {
            type: Boolean,
            default: true
        },
        store: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup(props) {
        const postService = new PostService();
        const layoutStore = useLayoutStore();
        const postStore = usePostStore();
        const siteStore = useSiteStore();
        const appSettings = inject('appSettings');
        const { user } = useAuth0();
        const toast = useToast();

        const showAddExternalDialog = ref(false);
        const activeTabIndex = ref(0);
        const sources = ref([]);
        const pageSize = 20;
        const locationId = 1;

        const froalaColorsConfig = {
            colorOnly: {
                toolbarButtons: ['textColor', 'undo', 'redo'], // 'bold', 'italic'
                colorsText: ['#C90110', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8'],
                charCounterCount: false,
                enter: 2, //FroalaEditor.ENTER_BR
                pastePlain: true,
                height: 75,
            }
        };

        function getActions(post) {
            let actions = [
                {
                    label: 'Live',
                    command: () => {
                        if(postExists(post)) {
                            toast.add({ severity: 'error', summary: 'Unable to add', detail: 'The article is already assigned to a section', life: 3000 });
                        } else {
                            props.store.insert(post, 'live');
                        }
                    }
                },
                {
                    label: 'View Post ⧉',
                    //icon: 'pi pi-pencil',
                    command: () => {
                        window.open(getPermalink(post), '_blank');
                    }
                }
            ];

            if(props.queueSupport && (props.store.$id != 'Layout' || appSettings.features.homePageManager.showQueue)) {
                actions.splice(1, 0, {
                    label: 'Queue',
                    command: () => {
                        if(postExists(post)) {
                            toast.add({ severity: 'error', summary: 'Unable to add', detail: 'The article is already assigned to a section', life: 3000 });
                        } else {
                            props.store.insert(post, 'queue');
                        }
                    }
                });
            }

            return actions;
        }

        const saveMenuCommands = [
            {
                label: 'Live',
                command: () => {
                    if(!layoutStore.post.customProperties.headline || !layoutStore.post.customProperties.url || !isValidURL(layoutStore.post.customProperties.url)) {
                        toast.add({ severity: 'error', summary: 'Error adding article', detail: 'Headline and URL are required', life: 3000 });
                    } else {
                        enqueueExternalArticle('live');
                    }
                }
            },
            {
                label: 'Queue',
                command: () => {
                    if(!layoutStore.post.customProperties.headline || !layoutStore.post.customProperties.url || !isValidURL(layoutStore.post.customProperties.url)) {
                        toast.add({ severity: 'error', summary: 'Error adding article', detail: 'Headline and URL are required', life: 3000 });
                    } else {
                        enqueueExternalArticle('queue');
                    }
                }
            }
        ];

        onBeforeMount(async () => {
            // Load sites
            await siteStore.load();
            await props.store.load(locationId, true);

            buildSources();
        });

        const isLockedByOtherUser = computed(() => {
            if(props.store.$id == 'Layout') { // HP Manager
                return props.store.isLocked && props.store.location.postLockStatus.userIdLock != user.value.name;
            } else { // Everything else
                return props.store.isLocked && props.store.widgetSettings.postLockStatus.userIdLock != user.value.name;
            }
        });

        async function buildSources() {
            let sourceList = [];

            // Get categories
            let enabledCategories = appSettings.features.homePageManager.categories;
            if(enabledCategories) {
                await postStore.getCategories();
                let cats = postStore.categoryList.filter(category => enabledCategories.includes(category.categoryId));
                cats.sort((a, b) => b.categoryId - a.categoryId);

                for(let idx = 0; idx < cats.length; idx++) {
                    sourceList.push({
                        type: 'category',
                        label: cats[idx].name,
                        categoryId: cats[idx].categoryId,
                        posts: null,
                        pageIndex: 0
                    });
                }
            }

            // Get post types
            let enabledPostTypes = appSettings.features.homePageManager.postTypes;
            await postStore.getPostTypes();
            for(let idx = 0; idx < postStore.postTypes.length; idx++) {
                if(!enabledPostTypes || enabledPostTypes.includes(postStore.postTypes[idx].postTypeId)) {
                    sourceList.push({
                        type: 'postType',
                        label: postStore.postTypes[idx].name,
                        postTypeId: postStore.postTypes[idx].postTypeId,
                        posts: null,
                        pageIndex: 0
                    });
                }
            }

            // Add VIP
            sourceList.push({
                type: 'vip',
                label: 'VIP',
                posts: null,
                pageIndex: 0
            });

            sources.value = sourceList;
            await getPosts();
        }

        async function getPosts(index = 0) {
            let source = sources.value[index];
            let params = {
                pageSize: pageSize,
                pageIndex: source.pageIndex,
                status: 1,
                postTypeId: 1,
            };

            let posts = [];
            switch(source.type) {
                case 'category':
                    posts = await postService.getPosts({ ...params, categoryId: source.categoryId });
                    break;
                case 'postType':
                    posts = await postService.getPosts({ ...params, postTypeId: source.postTypeId });
                    break;
                case 'vip':
                    posts = await postService.getPosts({ ...params, subscriptionPackage: 12 }); // VIP, VIP Gold
                    break;
            }

            if(posts.length) {
                if(sources.value[index].posts == null) {
                    sources.value[index].posts = posts;
                } else {
                    sources.value[index].posts = sources.value[index].posts.concat(posts);
                }
                sources.value[index].pageIndex++;
            }
        }

        async function onExpanded(event) {
            // Initial posts load
            if(sources.value[event.index].posts == null) await getPosts(event.index);
        }

        function isActive(post) {
            if(post.postId <= 0) return false;
            let isInLive = false, isInQueue = false;
            if(props.store.$id == 'Layout') {
                isInLive = props.store.posts.live?.find(({ postId }) => postId == post.postId);
                isInQueue = props.store.posts.queue?.find(({ postId }) => postId == post.postId);
            } else if(props.store.$id == 'Afternoon') {
                isInLive = props.store.posts?.find(obj => obj.url == post.url);
            } else {
                isInLive = props.store.posts?.find(({ postId }) => postId == post.postId);
            }
            return (typeof isInLive != 'undefined' && isInLive) || (typeof isInQueue != 'undefined' && isInQueue);
        }

        function postExists(post) {
            if(post.postId < 1) return false;
            let livePost = null, queuePost = null;
            if(props.store.$id == 'Layout') {
                livePost = props.store.posts.live.find(obj => obj.postId == post.postId);
                queuePost = props.store.posts.queue.find(obj => obj.postId == post.postId);
            } else if(props.store.$id == 'Afternoon') {
                livePost = props.store.posts?.find(obj => obj.url == post.url);
            } else {
                livePost = props.store.posts.find(obj => obj.postId == post.postId);
            }
            return livePost || queuePost ? true : false;
        }

        function getPermalink(post) {
            var site = siteStore.sites?.find(({ siteGroup }) => siteGroup == appSettings.siteGroup);
            switch(post.postTypeId) {
                case 'video':
                    return site && site.domain ? appSettings.webroot[process.env.NODE_ENV] + post.customProperties.video.url : post.customProperties.video.url;
                default:
                    if(!post.url || post.url.includes('http')) return post.url;
                    //var site = siteStore.sites?.find(({ siteGroup }) => siteGroup == post.siteGroup);
                    return site && site.domain ? appSettings.webroot[process.env.NODE_ENV] + post.url : post.url;
            }
        }

        function getEditLink(post) {
            let categoryId = post.categories?.length > 0 ? post.categories[0] : null;
            switch(post.postTypeId) {
                case 2:
                    return appSettings.webroot[process.env.NODE_ENV] + post.url;
                default:
                    if(appSettings.siteId == 1 && categoryId) {
                        return `/post/${categoryId}/${post.postId}`;
                    } else {
                        return `/posts/${post.postId}`;
                    }
            }
        }

        function addExternalArticle() {
            layoutStore.post = {
                ...layoutStore.newPost,
                dateLive: formatDateTimeET(),
                postFields: { datePublished: formatDateTimeET(), imageObject: [] },
                customProperties: { image: '' }
            };
            showAddExternalDialog.value = true;
        }

        function closeAddExternalDialog() {
            layoutStore.post = {};
        }

        function enqueueExternalArticle(status) {
            layoutStore.post.isLive = status == 'live';
            let cloneObj = {...layoutStore.post};
            cloneObj.isExternal = true;
            cloneObj.postId = 0;
            cloneObj.postType = 'Post';
            layoutStore.posts[status].unshift(cloneObj);

            showAddExternalDialog.value = false;
            layoutStore.changedColumn[status] = true;
            layoutStore.renumber(true);
        }

        async function refreshPosts() {
            sources.value.forEach(item => {
                item.posts = null;
                item.pageIndex = 0;
            });
            console.log('activeTabIndex.value', activeTabIndex.value);
            await getPosts(activeTabIndex.value);
        }

        function setActiveTab(index) {
            activeTabIndex.value = index;
        }

        return {
            activeTabIndex,
            addExternalArticle,
            appSettings,
            closeAddExternalDialog,
            enqueueExternalArticle,
            formatDateTime,
            froalaColorsConfig,
            getActions,
            getActiveHeadline,
            getEditLink,
            getPermalink,
            getPosts,
            getTimezone,
            getVipBadge,
            isActive,
            isLockedByOtherUser,
            layoutStore,
            onExpanded,
            postExists,
            props,
            refreshPosts,
            saveMenuCommands,
            setActiveTab,
            showAddExternalDialog,
            siteStore,
            sources,
            user,
        }
    }
}
</script>

<style lang="scss" scoped>
div.site-posts {
    .post {
        min-height: 55px;
        border-bottom: 1px solid #ddd;
        padding: 0.2em 0.3em 0.2em 0.5em;

        h6 {
            margin: 0;

            &, a {
                color: var(--blue-700);
                padding-bottom: 0.1em;
                font-size: 0.95em;
            }
            a:hover {
                color: var(--blue-800);
                text-decoration: underline;
            }
        }

        a.article-link {
            vertical-align: text-bottom;
            color: #495057;
        }

        &.exists {
            border-left: 3px solid var(--teal-400);
        }
        &:nth-child(even) {
            background-color: #F9F9F9;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
}
.smaller {
    font-size: 80%;
}
</style>

<style lang="scss">
#article_sources {
	div.site-posts {
		div.p-accordion-content {
			overflow-y: scroll;
			max-height: 420px;
		}
	}
}
</style>
