<template>
    <div class="card-body" v-if="authorStore.author">
        <ProfileEditorComponent :store="authorStore" :isLoading="isLoading" />

        <p>
            <Button :label="authorStore.author.authorId ? 'Save' : 'Add User'" @click="saveUser()" x-title="Save (Ctrl+S or ⌘S)" />
            <Button label="Delete" class="btn-delete" severity="danger" v-show="authorStore.author.authorId" @click="deleteUser()" />
            <LogViewerThm label="Change Log" :filter="{ logTypeId: 3, referenceId: authorStore.author.authorId }" v-if="authorStore.author.authorId" class="p-button-secondary p-button-outlined ms-2" tag="button" />
        </p>
    </div>
    <!--
    <div v-else class="card-body fst-italic text-muted pb-3">Author not found</div>
    -->
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, onBeforeMount, watch } from 'vue';
import { useAuthorStore } from '@/stores/AuthorStore';
import ProfileEditorComponent from '@/components/ProfileEditor.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'AuthorsEditor',
    components: {
        LogViewerThm,
        ProfileEditorComponent,
    },
    setup() {
        const authorStore = useAuthorStore();
        const router = useRouter();
        const route = useRoute();
        //const adminService = new AdminService();
        const confirm = useConfirm();
        const toast = useToast();
        const { user } = useAuth0();
        const isLoading = ref(false);

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveUser());
        whenever(meta_s, () => saveUser());
        */

        watch(
            () => route.params,
            async (params, fromParams) => {
                //console.log('[watch] AuthorsEditor');
                if (params.authorId != fromParams.authorId) {
                    if (params.authorId) {
                        await authorStore.setAuthor(params.authorId).catch((err) => {
                            toast.add({ severity: 'error', summary: 'Error Loading Author', detail: err.message || err, life: 3000 });
                        });
                    } else {
                        authorStore.author = authorStore.newAuthor;
                    }
                }
            }
        );

        onBeforeMount(() => {
            if(!authorStore.author) authorStore.author = authorStore.newAuthor;
            authorStore.load().then(() => {
                if(route.params.authorId) {
                    authorStore.setAuthor(route.params.authorId).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Loading Author', detail: err.message || err, life: 3000 });
                    });
                }
            });

            authorStore.getCurrentAuthor();
        });

        function saveUser() {
            // Check required fields
            //var requiredFields = [ authorStore.author.firstName, authorStore.author.lastName ], isFormInvalid = false; // authorStore.author.authorTypeId
            /*
            var requiredFields = [ authorStore.author.nickName ],
            var requiredFields = [],
                isFormInvalid = false;
            requiredFields.forEach((elm) => {
                if (!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }
            */
            if(!authorStore.author.firstName && !authorStore.author.lastName && !authorStore.author.nickName) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Author First/Last Name or Display Name required', life: 4000 });
                return;
            }

            authorStore.author.siteGroup = 0;

            // Save user
            authorStore.save().then((res) => {
                if (console.log.hide) console.log('res', res);
                if (res.isNew) router.push({ name: 'AuthorManager', params: { authorId: res.authorId }, query: route.query});
                toast.add({ severity: 'success', summary: 'Success', detail: 'Author Saved', life: 3000 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Author', detail: err.message || err, life: 3000 });
            });
        }

        function deleteUser() {
            if (!authorStore.author.authorId) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this author?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    authorStore.delete().then(() => {
                        authorStore.author = authorStore.newAuthor;
                        router.push({ path: '/settings/authors', query: route.query });
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting Author', detail: err.message || err, life: 3000 });
                    });
                },
            });
        }

        return {
            authorStore,
            deleteUser,
            isLoading,
            route,
            saveUser,
            user
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .btn-delete {
        margin-left: 0.4em;
    }
}
</style>
