<template>
    <div class="card card-border-color card-border-color-primary mb-3">
        <div class="card-header card-header-divider">Froala New</div>

        <div class="card-body">
            <FroalaThm v-model="post.body" :config="config" />

            <Button label="Submit" class="mt-3" @click="submit()" />
        </div>
    </div>

    <div class="card card-border-color card-border-color-primary">
        <div class="card-header card-header-divider">vue-froala-wysiwyg</div>

        <div class="card-body">
            <froala v-model="post.body2" id="edit" :tag="'textarea'" :config="config" @keyup="keyupFn"></froala>
            <Button label="Submit" class="mt-3" @click="submit()" />
        </div>
    </div>
</template>

<script>
import { ref, inject } from 'vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';

export default {
    name: 'FroalaTest',
    components: {
        FroalaThm
    },
    setup() {
        const appSettings = inject('appSettings');

        const post = ref({ body: null });
        const config = {
            heightMin: 300,
            //toolbarButtons: ['html', 'bold', 'italic', 'quote', 'undo', 'redo', 'paragraphFormat', 'formatOLSimple', 'formatUL', 'align', 'insertLink', 'embedCodeButton', 'imageManagerButton', 'insertVideo', 'fontAwesome', 'clearFormatting', 'fullscreen', 'help'], // Others: paragraphFormat, OL, UL, socialEmbed, insertImage, insertVideo, fullscreen, 'embedly'
            key: appSettings.keys.froala,
            immediateVueModelUpdate: true,
            events: {
                initialized: function () {
                    console.log('Froala Editor Initialized');
                },
                /*
                'contentChanged': function () {
                    //console.log('contentChanged', this);
                    console.log(this.html.get());
                },
                'codeView.update': () => {
                    console.log(333, this);
                },
                //'keyup': (keyupEvent) => {
                'keyup': function (keyupEvent) {
                    if(console.log.hide) console.log('keyupEvent', keyupEvent);
                    console.log('this', this);
                    console.log('keyup', post.value.body);
                }
                */
            }
        };

        function keyupFn(e) {
            if(console.log.hide) console.log('e', e);
            //console.log('**', post.value.body);
        }

        function submit() {
            console.log('SAVE:', post.value);
        }

        return {
            config,
            keyupFn,
            post,
            submit
        }
    }
}
</script>
