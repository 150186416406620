<template>
    <CptFilterBar />

    <div class="row mb-3">
        <div class="col-sm-12 col-md-6">
            <CptList />
        </div>
        <div class="col-sm-12 col-md-6">
            <CptEditor />
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useCptStore } from '@/stores/CptStore';
import { useCptEditStore } from '@/stores/CptEditStore';
import CptFilterBar from './CptFilterBar.vue';
import CptList from './CptList.vue';
import CptEditor from './CptEditor.vue';

export default {
    name: 'CustomPostType',
    components: {
        CptFilterBar,
        CptList,
        CptEditor,
    },
    setup() {
        const cptEditStore = useCptEditStore();
        const cptStore = useCptStore();
        const route = useRoute();

        onMounted(() => {
            cptEditStore.load(route.params.postTypeId);
            cptStore.load(route.params.postTypeId).then((res) => {
                if(console.log.hide) console.log('res', res);
            });
        });

    }
};
</script>
