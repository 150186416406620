<template>
    <ConfirmDialog />

    <div class="col-sm-12 col-md-6 col-xl-7 editor-column" id="virtual_page_editor">
        <div class="card card-border-color card-border-color-primary">
            <div class="card-header card-header-divider">
                <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" @click="addVirtualPage()" title="Add New" />
                {{ route.params.virtualPageId ? 'Edit' : 'Add' }} Virtual Page
            </div>

            <div class="card-body">
                <div class="mb-3">
                    <label for="Title" class="form-label required">Title</label>
                    <InputText type="text" class="form-control" v-model="virtualPageStore.page.title" id="Title" />
                </div>

                <div class="mb-3 d-none">
                    <label for="SiteGroup" class="form-label required">Site(s)</label>
                    <SiteGroupSelect v-model="virtualPageStore.page.siteGroup" />
                </div>

                <div class="mb-3">
                    <label for="Slug" class="form-label required">Slug</label>
                    <InputText type="text" class="form-control" v-model="virtualPageStore.page.slug" id="FirstName" @keyup="filterSlug($event.target)" />
                    <a :href="getAbsoluteUrl(virtualPageStore.page.path,virtualPageStore.page.slug)" class="small" target="_blank" v-if="virtualPageStore.page.slug" v-html="getAbsoluteUrl(virtualPageStore.page.path,virtualPageStore.page.slug)"></a>
                </div>
                <div class="mb-3">
                    <label for="Path" class="form-label required">Path</label>
                    <InputText type="text" class="form-control" v-model="virtualPageStore.page.path" id="Path"/>
                </div>
                <div class="mb-4">
                    <label for="Author" class="form-label">Author</label>
                    <InputText type="text" class="form-control" v-model="virtualPageStore.page.author" id="Author" />
                </div>

                <div class="mb-3">
                    <label for="AuthorImageUrl" class="form-label">Author Image URL</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" v-model="virtualPageStore.page.authorImageUrl" id="AuthorImageUrl" placeholder="https://" />
                    </IconField>
                </div>
                <div class="mb-3" v-if="virtualPageStore.page.authorImageUrl">
                    <Image :src="virtualPageStore.page.authorImageUrl" alt="Author Image" class="preview-image" />
                </div>

                <div class="mb-3" v-if="virtualPageStore.templateViews?.length">
                    <label for="TemplateView" class="form-label">Template View</label>
                    <Dropdown id="TemplateView" v-model="virtualPageStore.page.templateView" :options="virtualPageStore.templateViews" showClear optionLabel="label" optionValue="value" class="d-flex" />
                </div>

                <div class="mb-3">
                    <label for="Html" class="form-label">Body</label>
                    <!--<froala-thm tag="textarea" v-model="virtualPageStore.page.html" :config="{ toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'], charCounterCount: false }" />-->
                    <FroalaThm v-model="virtualPageStore.page.html" :config="{ toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'], charCounterCount: false }" />
                </div>

                <div class="mb-3">
                    <label for="ImageUrl" class="form-label">Image URL</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" v-model="virtualPageStore.page.imageUrl" id="ImageUrl" placeholder="https://" />
                    </IconField>
                </div>
                <div class="mb-3" v-if="virtualPageStore.page.imageUrl">
                    <Image :src="virtualPageStore.page.imageUrl" alt="Page Image" class="preview-image" :preview="true" />
                </div>

                <div class="mb-2">
                    <InputSwitch v-model="virtualPageStore.page.isPublished" id="IsPublished" />
                    <label for="IsPublished" class="switch-label">Published</label>
                </div>

                <div class="mb-3" v-show="virtualPageStore.page.isPublished">
                    <label for="DatePublished" class="form-label d-block">Date Published</label>
                    <Calendar id="DatePublished" v-model="virtualPageDatePublished" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :showButtonBar="true" />
                    <!--<CalendarThm id="DatePublished" v-model="virtualPageStore.page.datePublished" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :showButtonBar="true" />-->
                </div>

                <div class="mb-3">
                    <label for="DateExpires" class="form-label d-block">Date Expires</label>
                    <Calendar id="DateExpires" v-model="virtualPageDateExpires" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :showButtonBar="true" @clear-click="virtualPageDateExpires = null" />
                    <!--<CalendarThm id="DateExpires" v-model="virtualPageStore.page.dateExpires" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :showButtonBar="true" />-->
                </div>

                <p>
                    <Button :label="virtualPageStore.page.virtualPageId ? 'Save Virtual Page' : 'Add Virtual Page'" @click="saveVirtualPage()" x-title="Save (Ctrl+S or ⌘S)" />
                    <Button label="Delete" severity="danger" class="btn-delete ms-2" v-show="virtualPageStore.page.virtualPageId && userHasAccessToRole('Admin')" @click="deleteVirtualPage()" />
                    <LogViewerThm label="Change Log" :filter="{ logTypeId: 11, referenceId: virtualPageStore.page.virtualPageId }" v-if="virtualPageStore.page.virtualPageId" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                </p>

            </div>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
//import { onBeforeMount, ref, watch } from 'vue';
import { computed, inject, onBeforeMount, watch } from 'vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useVirtualPageStore } from '@/stores/VirtualPageStore';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';
import SiteGroupSelect from '@/components/SiteGroupSelectClient.vue';
import ConfirmDialog from 'primevue/confirmdialog';
//import CalendarThm from '@/components/CalendarThm.vue';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
//import ThmUtils from '@/utils/ThmUtils';
import { seoUrl } from '@/utils/ThmUtils';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'VirtualPageEditor',
    components: {
        //CalendarThm,
        ConfirmDialog,
        SiteGroupSelect,
        FroalaThm,
        LogViewerThm
    },
    setup() {
        const virtualPageStore = useVirtualPageStore();
        const appSettings = inject('appSettings');
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const confirm = useConfirm();
        const { user } = useAuth0();

        const virtualPageDatePublished = computed({
            get() {
                return virtualPageStore.page.datePublished == null ? null : new Date(virtualPageStore.page.datePublished);
            },
            set(newValue) {
                //alertBarStore.alert.startDate = newValue.toLocaleString('en-US');
                virtualPageStore.page.datePublished = formatDateTime(newValue, 'yyyy-MM-dd HH:mm:ss');
            },
        });

        const virtualPageDateExpires = computed({
            get() {
                return virtualPageStore.page.dateExpires == null ? null : new Date(virtualPageStore.page.dateExpires);
            },
            set(newValue) {
                //alertBarStore.alert.startDate = newValue.toLocaleString('en-US');
                virtualPageStore.page.dateExpires = newValue ? formatDateTime(newValue, 'yyyy-MM-dd HH:mm:ss') : null;
            },
        });

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveVirtualPage());
        whenever(meta_s, () => saveVirtualPage());
        */

        // Watch for route changes
        watch(
            () => route.params, (params, fromParams) => {
                //console.log('[watch] VirtualPageEditor');
                if(params.virtualPageId != fromParams.virtualPageId) {
                    // Only if virtualPageId changed
                    if(params.virtualPageId) {
                        virtualPageStore.setVirtualPage(params.virtualPageId).catch((err) => {
                            toast.add({ severity: 'error', summary: 'Error Fetching Virtual Page', detail: err.message || err, life: 3000 });
                        });
                    } else {
                        //virtualPageStore.page = {};
                        virtualPageStore.setDefaults();
                    }
                }
            }
        );

        onBeforeMount(() => {
            if(route.params.virtualPageId) {
                virtualPageStore.setVirtualPage(route.params.virtualPageId).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Virtual Page', detail: err.message || err, life: 3000 });
                });
            } else {
                virtualPageStore.setDefaults();
            }
        });

        function saveVirtualPage() {
            // Check required fields
            var requiredFields = [virtualPageStore.page.title, virtualPageStore.page.siteGroup, virtualPageStore.page.slug], isFormInvalid = false;
            //var requiredFields = [], isFormInvalid = false; // Temporarily disable field checking
            requiredFields.forEach((elm) => {
                if(!elm) isFormInvalid = true;
            });
            if(isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            // Save virtual page
            virtualPageStore.save().then((res) => {
                if(console.log.hide) console.log('res', res);
                router.push({ path: `/manage/virtualpage/${res.virtualPageId}`, query: route.query });
                toast.add({ severity: 'success', summary: 'Success', detail: 'Virtual Page Saved', life: 3000 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Virtual Page', detail: err.message || err, life: 3000 });
            });
        }

        function filterSlug(el) {
            //el.value = el.value.toLowerCase().replaceAll(' ', '-').replace(/[^0-9a-z-_]/gi, '');
            el.value = seoUrl(el.value, true);
        }

        function addVirtualPage() {
            router.push({ path: `/manage/virtualpage`, query: route.query });
        }

        function deleteVirtualPage() {
            if(!virtualPageStore.page.virtualPageId) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this virtual page?',
                header: 'Delete Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    virtualPageStore.delete().then(() => {
                        router.push({ path: '/manage/virtualpage', query: route.query });
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting Virtual Page', detail: err.message || err, life: 3000 });
                    });
                }
            });
        }

        function getAbsoluteUrl(path, slug) {
            if(path == null)
                path = "";
            return appSettings.webroot[process.env.NODE_ENV] + '/' + path + slug ;
        }

        return {
            addVirtualPage,
            deleteVirtualPage,
            filterSlug,
            getAbsoluteUrl,
            route,
            saveVirtualPage,
            virtualPageStore,
            toast,
            user,
            userHasAccessToRole,
            virtualPageDatePublished,
            virtualPageDateExpires
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .form-label {
        margin-bottom: 0.2rem;
        font-weight: 500;
    }
    .required::after {
        content: '*';
        color: rgb(255, 0, 0);
    }
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
    .input {
        &__tiny {
            width: 75px;
        }
    }
}
</style>

<style lang="scss">
#virtual_page_editor {
	.preview-image {
		img {
			max-height: 150px;
			max-width: 100%;
		}
	}
	.p-image-preview-container {
		img {
			max-height: 150px;
			max-width: 100%;
		}
	}
}
</style>
