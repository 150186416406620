<template>
    <ImageLibrary v-model="image" mode="froala" :id="froalaConfig.containerId" :config="{ showPreview: false }" v-if="froalaConfig.toolbarButtons?.includes('imageManagerButton')" />
    <froala v-model:value="model" :id="froalaConfig.containerId + '_value'" :tag="props.tag" :config="froalaConfig"></froala>
</template>

<script>
import { ref, inject, watch } from 'vue';
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';

//Import third party plugins
// Froala plugins: https://froala.com/wysiwyg-editor/docs/plugins/
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/track_changes.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import '@/utils/froala/plugins/BridVideo.js';
import '@/utils/froala/plugins/EmbedCode.js';
import '@/utils/froala/plugins/ImageManager.js';

// Syntax highlighting requires CodeMirror: https://thiagoborg.es/blog/2018/08/30/syntax-highlight-on-froala-editor-using-webpacker/
import CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/lib/codemirror.css';

// Embed.ly: https://froala.com/wysiwyg-editor/examples/embedly/
//import '/node_modules/froala-editor/js/third_party/embedly.min.js';
//import '/node_modules/froala-editor/css/third_party/embedly.min.css';

export default {
    name: 'FroalaThm',
    components: {
        ImageLibrary
    },
    emits: ['update:modelValue', 'change', 'keyup'],
    props: {
        config: {
            type: Object,
            default() {
                return {}
            }
        },
        id: {
            type: String,
            default: 'froala_editor'
        },
        modelValue: {
            type: String,
            //default: '<p></p>'
            default: ''
        },
        tag: {
            type: String,
            default: 'textarea'
        },
    },
    setup(props, { emit, expose }) {
        if(console.log.hide) console.log(props, emit);

        //const model = ref(props.modelValue || '<p></p>');
        const appSettings = inject('appSettings');
        const model = ref(props.modelValue || '');
        const editorInstance = ref(null);
        const image = ref(null);
        const defaultConfig = ref({
            containerId: props.id,
            key: appSettings.keys?.froala,
            immediateVueModelUpdate: false,
            codeMirror: CodeMirror,
            htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'script', '.fa', 'brid'],
            htmlRemoveTags: ['base', 'html', 'style'],
            charCounterCount: false,
            htmlExecuteScripts: false,
            htmlUntouched: false,
            imagePaste: false,
            imagePasteProcess: false,
            linkAlwaysBlank: true,
            heightMin: 200,
            toolbarSticky: true,
            toolbarStickyOffset: 50,
            videoMove: true,
            videoResize: false,
            wordPasteModal: true,
            //pastePlain: true,
            pasteDeniedAttrs: ['style'],
            toolbarButtons: ['html', 'bold', 'italic', 'quote', 'undo', 'redo', 'paragraphFormat', 'formatOLSimple', 'formatUL', 'align', 'insertLink', 'embedCodeButton', 'imageManagerButton', 'insertVideo', 'embedBridVideoButton', 'fontAwesome', 'clearFormatting', 'fullscreen', 'help'], // Others: paragraphFormat, OL, UL, socialEmbed, insertImage, insertVideo, fullscreen, 'embedly'
            events: {
                'initialized': function () {
                    editorInstance.value = this;
                },
                'link.beforeInsert': function (link, text, attrs) {
                    if(console.log.hide) console.log(link, text, attrs);
                    if(!attrs.title) attrs.title = link;
                },
                'contentChanged': function () {
                    //console.log('contentChanged', this);
                    emit('update:modelValue', this.html.get());
                    emit('change', this);
                },
                'keyup': function (keyupEvent) {
                    if(console.log.hide) console.log('keyupEvent', keyupEvent);
                    if(props.config.immediateVueModelUpdate) {
                        //console.log('keyup', this);
                        emit('update:modelValue', this.html.get());
                        emit('change', this);
                        emit('keyup', this);
                    }
                }
            }

        });
        const froalaConfig = ref({ ...defaultConfig.value, ...props.config });

        watch(() => props.modelValue, (val) => {
            //model.value = val || '<p></p>'; // The <p></p> is necessary because the Froala component doesn't appear to be reactive then model is null
            model.value = val || '';
        });

        // Expose function to toggle code view
        const toggleCodeView = () => {
            if(editorInstance.value) {
                const isCodeViewActive = editorInstance.value.codeView.isActive();
                if(isCodeViewActive) {
                    editorInstance.value.codeView.toggle();
                }
            }
        };
        expose({ toggleCodeView });

        return {
            defaultConfig,
            froalaConfig,
            image,
            model,
            props
        }
    }
}
</script>
