<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">VueUse</div>

                    <div class="card-body">
                        <h4><a href="https://vueuse.org/core/usebrowserlocation/">useBrowserLocation</a> &amp; <a href="https://vueuse.org/core/useUrlSearchParams/">useUrlSearchParams</a></h4>

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Property</th>
                                    <th scope="col">First</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">hostname</th>
                                    <td>{{ location.hostname }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">host</th>
                                    <td>{{ location.host }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">href</th>
                                    <td>{{ location.href }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">origin</th>
                                    <td>{{ location.origin }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">pathname</th>
                                    <td>{{ location.pathname }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">port</th>
                                    <td>{{ location.port }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">protocol</th>
                                    <td>{{ location.protocol }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">state.back</th>
                                    <td>{{ location.state.back }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">state.current</th>
                                    <td>{{ location.state.current }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">state.forward</th>
                                    <td>{{ location.state.forward }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">state.scroll</th>
                                    <td>
                                        <div v-if="location.state.scroll">Left: {{ location.state.scroll.left }}, Top: {{ location.state.scroll.top }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">search</th>
                                    <td>{{ location.search }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">querystring params</th>
                                    <td>{{ querystring }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { useBrowserLocation, useUrlSearchParams } from '@vueuse/core';
//import { ref, onBeforeMount } from 'vue';

export default {
    name: 'UseBrowserLocationDemo',
    setup() {
        const location = useBrowserLocation();
        const querystring = useUrlSearchParams('history');

        return { location, querystring };
    },
};
</script>

<style lang="scss" scoped></style>
