<template>
    <ConfirmDialog />

    <div class="col-sm-12 col-md-5 col-xl-6 editor-column" id="seo_route_editor">
        <div class="card card-border-color card-border-color-primary">
            <div class="card-header card-header-divider">
                <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" @click="addNewRoute()" title="Add New" />
                {{ route.params.id ? 'Edit' : 'Add' }} Route
            </div>

            <div class="card-body container-fluid pb-3">
                <div class="mb-3">
                    <label for="Path" class="form-label required">Path</label>
                    <InputText type="text" class="form-control" v-model="page.path" id="Path" />
                </div>

                <div class="mb-3">
                    <label for="Title" class="form-label">Title</label>
                    <InputText type="text" class="form-control" v-model="page.title" id="Title" />
                </div>

                <div class="mb-3">
                    <label for="Description" class="form-label">Description</label>
                    <Textarea v-model="page.description" :autoResize="true" rows="3" cols="30" class="form-control" id="Description" />
                </div>

                <div class="mb-3">
                    <label for="Keywords" class="form-label">Keywords</label>
                    <Textarea v-model="page.keywords" :autoResize="true" rows="3" cols="30" class="form-control" id="Keywords" />
                </div>

                <div class="mb-3">
                    <label for="Canonical" class="form-label">Canonical URL</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" v-model="page.canonical" id="Canonical" placeholder="https://" />
                    </IconField>
                </div>

                <div class="mb-3 input-group">
                    <label for="DefaultImage" class="form-label w-100">Default Image</label>
                    <InputText type="text" class="form-control" id="DefaultImage" :value="page.defaultImage" placeholder="https://" @change="page.defaultImage = $event.target.value" />
                    <button v-if="page.defaultImage" class="btn btn-outline-secondary delete" type="button" @click="deleteImage('defaultImage')"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'defaultImage')" />
                    <!--<span class="help w-100">Recommended dimensions: 1400x1400</span>-->
                </div>

                <div class="mb-3" v-if="page.defaultImage">
                    <Image :src="page.defaultImage" alt="Default Image" class="preview-image" :preview="true" />
                </div>

                <div class="mb-3 input-group">
                    <label for="OgImage" class="form-label w-100">Open Graph/Facebook Image</label>
                    <InputText type="text" class="form-control" id="OgImage" :value="page.ogImage" placeholder="https://" @change="page.ogImage = $event.target.value" />
                    <button v-if="page.ogImage" class="btn btn-outline-secondary delete" type="button" @click="deleteImage('ogImage')"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'ogImage')" />
                    <span class="help w-100">Recommended dimensions: 1200x630</span>
                </div>

                <div class="mb-3" v-if="page.ogImage">
                    <Image :src="page.ogImage" alt="Default Image" class="preview-image" :preview="true" />
                </div>

                <div class="mb-3 input-group">
                    <label for="TwitterImage" class="form-label w-100">X/Twitter Image</label>
                    <InputText type="text" class="form-control" id="TwitterImage" :value="page.twitterImage" placeholder="https://" @change="page.twitterImage = $event.target.value" />
                    <button v-if="page.twitterImage" class="btn btn-outline-secondary delete" type="button" @click="deleteImage('twitterImage')"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'twitterImage')" />
                    <span class="help w-100">Recommended dimensions: 1600x900</span>
                </div>

                <div class="mb-3" v-if="page.twitterImage">
                    <Image :src="page.twitterImage" alt="Twitter Image" class="preview-image" :preview="true" />
                </div>

                <p>
                    <Button :label="page.id ? 'Save' : 'Add'" class="me-2" @click="save()" />
                    <Button label="Delete" severity="danger" class="btn-delete" v-show="page.id" @click="deleteItem()" />
                    <LogViewerThm label="Change Log" :filter="{ logTypeId: 17, referenceId: page.id }" v-if="page.id" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                </p>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, onBeforeMount, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { isDefined } from '@vueuse/core';
import ConfirmDialog from 'primevue/confirmdialog';
import LogViewerThm from '@/components/LogViewerThm.vue';
import FileUpload from 'primevue/fileupload';
//import TagInput from '@/components/TagInput.vue';
import AdminService from '@/service/AdminService';
import MediaService from '@/service/MediaService';
import SeoService from '@/service/SeoService';

export default {
    name: 'SeoEditor',
    components: {
        ConfirmDialog,
        FileUpload,
        LogViewerThm
    },
    setup() {
        const seoService = new SeoService();
        const mediaService = new MediaService();
        const adminService = new AdminService();
        const confirm = useConfirm();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const page = ref({});
        const isLoading = ref(false);

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => save());
        whenever(meta_s, () => save());
        */

        watch(
            () => route.params, (params, fromParams) => {
                //console.log('[watch] SeoEditor');
                if(console.log.hide) console.log({ from: fromParams, to: params });
                if(params.id != fromParams.id) {
                    if(params.id) {
                        seoService.getSeoItem(params.id).then((res) => {
                            page.value = res;
                        }).catch((err) => {
                            toast.add({ severity: 'error', summary: 'Error Fetching SEO Route', detail: err.message || err, life: 3000 });
                        });
                    } else {
                        page.value = {};
                    }
                }
            }
        );

        onBeforeMount(() => {
            if(route.params.id) {
                seoService.getSeoItem(route.params.id).then((res) => {
                    if(!Object.keys(res).length) {
                        toast.add({ severity: 'error', summary: '404 Not Found', detail: 'SEO Item Not Found', life: 3000 });
                    }
                    page.value = res;
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching SEO Item', detail: err.message || err, life: 3000 });
                });
            }
        });

        function addNewRoute() {
            router.push({ path: `/settings/seo`, query: route.query });
        }

        function deleteItem() {
            if(!page.value.id) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this SEO route?',
                header: 'Delete Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    seoService.deleteItem(page.value.id).then(() => {
                        adminService.log({ logTypeId: 17, referenceId: page.value.id, message: 'Deleted: ' + page.value.path, content: JSON.stringify(page.value, null, 2), original: '', language: 'json' });
                        router.push({ path: '/settings/seo', query: route.query });
                    })
                    .catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting SEO Route', detail: err.message || err, life: 3000 });
                    });
                },
            });
        }

        function save() {
            let isNew = !page.value.id;

            // Check required fields
            var requiredFields = [page.value.path], isFormInvalid = false;
            requiredFields.forEach((elm) => {
                if(!elm || !elm.trim()) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            seoService.getSeoItem(page.value.id || 0).then((original) => {
                seoService.save(page.value).then((res) => {
                    router.push(`/settings/seo/${res.id}`);
                    adminService.log({ logTypeId: 17, referenceId: res.id, message: (isNew ? 'Added: ' : 'Modified: ') + page.value.path, content: JSON.stringify(res, null, 2), original: isNew ? '' : JSON.stringify(original, null, 2), language: 'json' });
                    toast.add({ severity: 'success', summary: 'Success', detail: 'SEO Route Saved', life: 3000 });
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving SEO Route', detail: err.message || err, life: 3000 });
                });
            });
        }

        function uploadImage(event, elm) {
            if (!isDefined(event.files) || !event.files) return;
            isLoading.value = true;

            mediaService.uploadMediaObject(`media`, event.files[0]).then((res) => {
                console.log('[uploadImage] response:', res);
                let uploadedFileUrl = 'https://cdn.townhall.com/media/' + res[0].fullPath;
                page.value[elm] = uploadedFileUrl;
            }).catch((err) => {
                //podcastStore.show.images[elm] = null;
                if(console.log.hide) console.log(err);
                //toast.add({ severity: 'error', summary: 'Upload Error', detail: err.message || err, life: 2000 });
            }).finally(() => {
                isLoading.value = false;
            });
        }

        function deleteImage(elm) {
            if (!page.value[elm]) return;

            /*
            confirm.require({
                message: 'Are you sure you want to permanently delete this image?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    isLoading.value = true;
                    mediaService.deleteMediaObject('media', page.value[elm]).then(() => {
                        page.value[elm] = null;
                    }).catch((err) => {
                        if (console.log.hide) console.log(err);
                        //toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 2000 })
                    }).finally(() => {
                        page.value[elm] = null;
                        isLoading.value = false;
                    });
                },
            });
            */

           page.value[elm] = null;
        }

        return {
            addNewRoute,
            deleteImage,
            deleteItem,
            isLoading,
            page,
            route,
            save,
            uploadImage
        }
    }
};

</script>

<style lang="scss" scoped>
div.card-body {
    label {
        font-weight: 500;
    }
}
</style>

<style lang="scss">
#seo_route_editor {
    .p-image-preview-container img {
        max-height: 150px;
        max-width: 100%;
    }
}
</style>
