<template>

    <div class="row">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Pagination Demo</div>

                <div class="card-body">

                    <!-- PrimeVue -->
                    <h2>PrimeVue</h2>

                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="author in authors1" :key="author.authorId">
                                <th scope="row">{{ author.authorId }}</th>
                                <td>{{ author.fullName.trim() }}</td>
                                <td>{{ author.authorTypeName }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <Paginator :totalRecords="totalRows" :rows="parseInt(params.pageSize)" @page="onPrimeVuePage($event)" v-show="authors1.length" />
                    <hr />

                    <!-- Other -->
                    <h2>Other</h2>

                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="author in authors2" :key="author.authorId">
                                <th scope="row">{{ author.authorId }}</th>
                                <td>{{ author.fullName.trim() }}</td>
                                <td>{{ author.authorTypeName }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Notice the v-model parameter here, which holds the current page -->
                    <PaginationThm v-model="params.pageIndex" :totalRecords="totalRows" :pageSize="params.pageSize" @page="onOtherPage($event)" />

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, onBeforeMount, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AdminService from '@/service/AdminService';
import PaginationThm from '@/components/PaginationThm.vue';

export default {
    name: 'PaginationDemo',
    components: {
        PaginationThm
    },
    setup() {
        const adminService = new AdminService();
        const router = useRouter();
        const route = useRoute();

        const authors1 = ref([]);
        const authors2 = ref([]);
        const totalRows = ref(0);
        const params = ref({ pageSize: 5, pageIndex: 0 });
        //const page = ref(0);
        const pageSize = 5;
        const demoSearchText = ''; // 'iams' to reduce to 4 pages for demo

        onBeforeMount(() => {
            params.value = { ...params.value, ...route.query, pageSize: pageSize };
            //params.value.pageIndex = params.value.pageIndex > 0 ? params.value.pageIndex-1 : params.value.pageIndex;
            //let pageIndex = params.value.pageIndex > 0 ? params.value.pageIndex-1 : params.value.pageIndex;
            let pageIndex = params.value.pageIndex;
            //if(isNaN(pageIndex)) pageIndex = 0;
            adminService.getAuthorsFiltered({ ...params.value, pageIndex: parseInt(pageIndex), searchText: demoSearchText }).then((res) => {
                if(res.length) {
                    authors1.value = authors2.value = res;
                    totalRows.value = res[0].totalRows;
                }
            });
        });

        watch(
            // () => route.query, (params, fromParams) => {
            () => route.query, async (query) => {
                //if(console.log.hide) console.log({ query });
                //console.log('[watch]]', query.pageIndex);
                getAuthors(query.pageIndex).then((res) => {
                    authors1.value = authors2.value = res;
                    totalRows.value = res && res[0] ? res[0].totalRows : 0;
                });
            }
        );

        function onPrimeVuePage(e) {
            //params.value.pageIndex = e.page;
            router.push({ query: { ...route.query, pageIndex: e.page } });
        }

        function onOtherPage(e) {
            //params.value.pageIndex = e.page;
            router.push({ query: { ...route.query, pageIndex: e.page } });
        }

        async function getAuthors(pageIndex) {
            return adminService.getAuthorsFiltered({ ...params.value, pageIndex: pageIndex, searchText: demoSearchText }).then((res) => {
                return res;
            });
        }

        return {
            authors1,
            authors2,
            //page,
            params,
            onOtherPage,
            onPrimeVuePage,
            totalRows
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
