<template>
    <div class="lb-breadcrumbs">
        <Breadcrumb :home="home" :model="items" class="py-2 px-3">
            <template #item="{ item }">
                <router-link :to="item.route" v-if="item.icon">
                    <span :class="item.icon"></span>
                </router-link>
                <router-link :to="item.route" v-else-if="item.route">
                    {{ item.label }}
                </router-link>
                <span class="fw-500" v-else>
                    {{ item.label }}
                </span>
            </template>
        </Breadcrumb>
    </div>
</template>

<script>
//import { ref, computed, inject, onMounted } from 'vue';
import { ref, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import Breadcrumb from 'primevue/breadcrumb';

export default {
    name: 'LiveBlog_Breadcrumbs',
    props: {
        post: {
            type: Object,
            required: true
        },
        currentPage: {
            type: String
        }
    },
    components: {
        Breadcrumb
    },
    setup(props) {
        const route = useRoute();

        const home = ref({
            icon: 'pi pi-home',
            //label: 'Live Blog',
            route: '/liveblog'
        });
        const items = ref([]);

        onBeforeMount(async () => {
            if(route.params.postId) {
                items.value.push({
                    label: 'Post #' + route.params.postId,
                    route: `/liveblog/${route.params.postId}`
                });
            }
            if(props.currentPage) {
                items.value.push({
                    label: props.currentPage
                });
            }
        });

        return {
            home,
            items,
            props,
            route,
        };
    },
};
</script>
