<template>
    <ConfirmDialog />

    <div class="row mb-3">
        <div class="col-sm-12 col-md-12">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Purge Cache</div>

                <div class="card-body">
                    <div class="mb-3">
                        <label for="PurgeUrl" class="form-label">Purge an Article by its URL</label>
                        <IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-link" />
                            </InputIcon>
                            <InputText type="url" class="w-100" v-model="url" id="PurgeUrl" placeholder="https://" />
                        </IconField>
                    </div>
                    <Button :disabled="isLoading" label="Purge URL" @click="purge('url')" />
                </div>

                <hr />

                <div class="card-body">
                    <div class="mb-3">
                        <label for="PurgePage" class="form-label">Purge Specific Page/Section</label>
                        <Dropdown id="PurgePage" v-model="page" :options="pages" optionLabel="label" optionValue="value" placeholder="Select Page" class="w-100" />
                    </div>
                    <Button :disabled="isLoading" label="Purge Page" @click="purge('page')" />
                </div>

                <hr />

                <div class="card-body ">
                    <div class="mb-3">
                        <label for="PurgeImage" class="form-label">Purge Image by URL</label>
                        <p class="text-muted card-p small">Purge CacheFly image cache for <code>media.townhall.com</code> URL.</p>
                        <IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-link" />
                            </InputIcon>
                            <InputText type="url" class="w-100" v-model="image" id="PurgeImage" placeholder="https://media.townhall.com/ ..." />
                        </IconField>
                    </div>
                    <Button :disabled="isLoading" label="Purge Image" @click="purgeHw('image')" />
                    <!-- <InlineMessage severity="warn" class="p-inline-message p-component p-inline-message-warn d-inline-block mb-2">This feature is currently disabled. Please ask administrator to clear image cache.</InlineMessage> -->
                </div>

                <hr />

                <div class="card-body mb-2" v-show="userHasAccessToRole('Developer')">
                    <div class="mb-3">
                        <label for="PurgeDrafts" class="form-label">Purge Drafts</label>
                        <Dropdown id="PurgeDrafts" v-model="day" :options="days" optionLabel="label" optionValue="value" placeholder="Select Days to Purge Drafts" class="w-100" />
                    </div>
                    <Button :disabled="isLoading" label="Purge Drafts" @click="purgeDrafts('day')" />
                </div>
            </div>
        </div>
    </div>

    <ProgressBar mode="indeterminate" v-show="isLoading" />
</template>

<script>
import CacheService from '@/service/CacheService';
import ConfirmDialog from 'primevue/confirmdialog';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import ProgressBar from 'primevue/progressbar';
import { usePostStore } from '@/stores/PostStore';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { ref, computed, inject, onMounted } from 'vue';

export default {
    name: 'CachePurge',
    components: {
        ConfirmDialog,
        ProgressBar,
    },
    setup() {
        const appSettings = inject('appSettings');
        const cacheService = new CacheService();
        const postStore = usePostStore();
        const confirm = useConfirm();
        const toast = useToast();

        const isLoading = ref(false);
        const image = ref(),
            page = ref(),
            url = ref(),
            day = ref();
        //const pages = getPages();
        const days = [

            { label: '30 days', value: 30 },
            { label: '60 days', value: 60 },
            { label: '90 days', value: 90 },


            ]

        onMounted(async () => {
            await postStore.load();
        })

        const pages = computed(() => {
            // Add Home Page
            var paths = [
                { label: 'Home Page', value: appSettings.webroot.production },
            ];

            // Add Categories
            for(var idx = 0; idx < postStore.categoryList.length; idx++) {
                paths.push({
                    label: 'Category: ' + postStore.categoryList[idx].name,
                    value: appSettings.webroot.production + '/category/' + postStore.categoryList[idx].slug,
                })
            }

            // Add miscellaneous and Purge Everything
            paths = [
                ...paths,
                { label: 'Podcasts', value: appSettings.webroot.production + '/podcasts' },
                { label: 'Videos', value: appSettings.webroot.production + '/videos' },
                { label: '🗑 Purge Everything', value: 'purge_everything' },
            ];

            return paths;
        });

        async function purgeService(params) {
            isLoading.value = true;
            return await cacheService
                .purge(params)
                .then((res) => {
                    toast.add({ severity: 'success', summary: 'Success', detail: res.message, life: 4000 });
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Purging Cache', detail: err.message || err, life: 4000 });
                })
                .finally(() => {
                    isLoading.value = false;
                });
        }
        async function purgeDraftService(params) {
            isLoading.value = true;
            return await cacheService
                .purgeDrafts(params)
                .then((res) => {
                    toast.add({ severity: 'success', summary: 'Success', detail: res.message, life: 3000 });
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Purging Cache', detail: err.message || err, life: 3000 });
                })
                .finally(() => {
                    isLoading.value = false;
                });

        }

        /*
        function getPages() {

            var pages = [];
            switch( appSettings.name) {

                case 'Twitchy':
                    pages = [
                        { label: 'Home Page', value: appSettings.webroot.production },
                        { label: 'Politics Section', value: appSettings.webroot.production + '/category/politics' },
                        { label: 'Media Section', value: appSettings.webroot.production + '/category/media' },
                        { label: 'Culture Section', value: appSettings.webroot.production + '/category/culture' },
                        { label: 'Videos Section', value: appSettings.webroot.production + '/videos' },
                        { label: 'Podcast Section', value: appSettings.webroot.production + '/podcasts' },
                        { label: 'Purge Everything', value: 'purge_everything' },
                    ];
                    break;
                case 'RedState':
                pages = [
                        { label: 'Home Page', value: appSettings.webroot.production },
                        { label: 'Columns Section', value: appSettings.webroot.production + '/category/columns' },
                        { label: 'Authors Section', value: appSettings.webroot.production + '/authors' },
                        { label: 'Videos Section', value: appSettings.webroot.production + '/videos' },
                        { label: 'Podcast Section', value: appSettings.webroot.production + '/podcasts' },
                        { label: 'Purge Everything', value: 'purge_everything' },
                    ];
                    break;
                default:
                    pages = [
                        { label: 'Home Page', value: appSettings.webroot.production },
                    ];
            }

            return pages;
        }
        */

        function purge(purgeType = 'url') {
            // Set the target URL to purge
            let targetUrl = eval(purgeType + '.value'); // url.value, page.value or image.value
            if (!targetUrl) {
                toast.add({ severity: 'warn', summary: 'Invalid Value', detail: 'Target URL or page cannot be empty', life: 3000 });
                return;
            }

            // Purge cache
            if (targetUrl == 'purge_everything') {
                confirm.require({
                    message: 'Are you sure you want to purge the entire site?',
                    header: 'Purge Confirmation',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        purgeService({ [purgeType]: targetUrl });
                    },
                });
            } else {
                purgeService({ [purgeType]: targetUrl });
            }
        }
        function purgeHw(purgeType = 'url') {
            // Set the target URL to purge
            let targetUrl = eval(purgeType + '.value'); // url.value, page.value or image.value
            if (!targetUrl) {
                toast.add({ severity: 'warn', summary: 'Invalid Value', detail: 'Target URL or page cannot be empty', life: 3000 });
                return;
            }

            if(extension(targetUrl)){

                purgeService({ [purgeType]: targetUrl });


            }else{
                toast.add({ severity: 'warn', summary: 'Invalid Value', detail: 'Target URL must be of a specified image', life: 3000 });
                return;
            }

            // Purge cache



        }
        function purgeDrafts(purgeType = 'days') {
        let targetUrl = eval(purgeType + '.value'); // url.value, page.value or image.value or day.value
            if (!targetUrl) {
                toast.add({ severity: 'warn', summary: 'Invalid Value', detail: 'The number of days cannot be empty', life: 3000 });
                return;
            }

                confirm.require({
                    message: 'Are you sure you want to purge drafts for the site',
                    header: 'Purge Confirmation',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        purgeDraftService({ [purgeType]: targetUrl });

                    },
                });

        }

        function extension(url) {
            // const file = url;
            //  if (!file) return; // No file selected

            const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];


            const fileExtension = url.split('.').pop().toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                // File has an invalid image extension
                toast.add({ severity: 'warn', summary: 'Invalid Value', detail: 'Please select a valid image file (JPG, JPEG, PNG, GIF, WEBP, or SVG).', life: 3000 });
                // alert('Please select a valid image file (JPG, JPEG, PNG, GIF, or SVG).');
                // Optionally, you can reset the input to allow the user to select a new file.
                // event.target.value = '';
                return false;
            }
            else{
                return true;
            }
        }

        return {
            image,
            isLoading,
            page,
            pages,
            day,
            days,
            purge,
            purgeHw,
            purgeDrafts,
            url,
            userHasAccessToRole
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    label {
        font-weight: 500;
    }
}
</style>
