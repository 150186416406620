<template>
    <div class="row g-0">
        <div class="col">
            <form v-on:submit.prevent>
                <dd>
                    <dl><AutoComplete v-model="autoCompleteTag" class="" dropdownMode="current" placeholder="Search for tag..." :suggestions="filtered" :minLength="2" :dropdown="false" @complete="search($event)" field="name" @item-select="addTag()" /></dl>
                    <!--<dl><Button type="submit" label="Add Tag" @click="addTag()" class="p-button-sm" :disabled="enableAddTag" /></dl>-->
                </dd>
            </form>
        </div>
        <div class="col">
            <div v-if="modelValue.length > 0" class="card border border-success rounded mb-2">
                <div class="card-body">
                    <div class="muted-info text-muted">Assigned Tags</div>
                    <span v-for="t in modelValue" :key="t.TagId" @click="deleteTag(t)" role="button" class="badge bg-success me-2">{{ t.name }} <i class="far fa-times-circle"></i></span>
                </div>
            </div>
            <div v-else class="card border border-warning rounded">
                <div class="card-body">
                    <div class="muted-info text-muted">No tags assigned</div>
                </div>
            </div>
            <!-- <div class="card border border-warning rounded">
                <div class="card-body">
                    <div class="muted-info text-muted">New Tags</div>

                    <span v-for="t in newTags" :key="t.TagId" class="badge bg-warning me-2">{{ t.name }}&nbsp;&nbsp;<i class="far fa-times-circle"></i></span>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import TagsService from '../service/TagsService';

import { ref, computed, onMounted } from 'vue';

import { isObject } from '@vueuse/core';

export default {
    name: 'TagsAutoComplete',
    props: {
        modelValue: [String, Object],
    },

    //setup does not have access to this!!
    setup(props, context) {
        onMounted(async () => {
            //tags.value = props.modelValue;
        });
        // const isObject = (obj) => {
        //     return Object.prototype.toString.call(obj) === '[object Object]';
        // };
        const tagsService = new TagsService();
        const filtered = ref([]);
        //const newTags = ref([]);
        //const tags = ref([]);
        const autoCompleteTag = ref();
        // watch(autoCompleteTag, (val) => {
        //     if (isObject(val)) {
        //         context.emit('update:modelValue', val);
        //     }
        // });
        const tags = computed({
            get() {
                return props.modelValue;
            },
            set(val) {
                context.emit('update:modelValue', val);
            },
        });

        const enableAddTag = computed(() => {
            if (isObject(autoCompleteTag.value)) {
                return false;
            } else {
                return true;
            }
        });
        function deleteTag(t) {
            tags.value = tags.value.filter((n) => n.tagId != t.tagId);
            //context.emit('update:modelValue', tags.value);
        }
        function addTag() {
            if (isObject(autoCompleteTag.value) && tags.value.some((n) => n.tagId == autoCompleteTag.value.tagId) == false) {
                tags.value.push(autoCompleteTag.value);
                //context.emit('update:modelValue', tags.value);
                autoCompleteTag.value = null;
            }
        }
        async function search(event) {
            filtered.value = await tagsService.getTagsAutoComplete(event.query);
        }
        //returning them for the template to use
        return {
            tags,
            filtered,
            search,
            addTag,
            deleteTag,
            enableAddTag,
            autoCompleteTag,
        };
    },
};
</script>
<style lang="scss" scoped>
.far fa-times-circle {
    cursor: pointer;
}
</style>
