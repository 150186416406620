<template>
    <div class="card-body badge-list container-fluid pb-3">
        <PaginationThm v-model="route.query.pageIndex" :totalRecords="totalRows" :pageSize="pageSize" @page="onPage" />

        <div class="row row__header pb-2 mb-1">
            <div class="col-2"></div>
            <div class="col-10 fw-bold">Label</div>
        </div>

        <p class="fst-italic text-muted ps-1 pt-4" v-show="Object.keys(route.query).length && !badges.length">No tbadgesags found matching criteria.</p>

        <div v-for="badge in badges" :key="badge.badgeId" class="row badge-item item" v-bind:class="{ active: route.params.id == badge.badgeId }">
            <div class="col-2">
                <Button label="Edit" class="btn-edit" @click="editBadge(badge.badgeId)" />
            </div>

            <div class="col-10">
                <span class="badge-tag" :style="{ color: badge.textColor, backgroundColor: badge.defaultColor }">
                    {{ badge.label }}
                </span>
            </div>
        </div>

    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import PaginationThm from '@/components/PaginationThm.vue';
import BadgeService from '@/service/BadgeService';

export default {
    name: 'BadgeList',
    components: {
        PaginationThm
    },
    setup() {
        const router = useRouter();
        const badgeService = new BadgeService();
        const route = useRoute();

        const filter = ref({});
        const badges = ref([]);
        const pageSize = ref(15);
        const totalRows = ref(0);

        // Watch for route changes
        watch(
            () => route.query, (params, fromParams) => {
                if(console.log.hide) console.log({ from: fromParams, to: params });
                filter.value = params;
                badgeService.getBadges(params).then((res) => {
                    badges.value = res;
                    if(res.length && res[0].totalRows) totalRows.value = res[0].totalRows;
                });
            }
        );

        // Mounted
        onMounted(() => {
            if(route.query.pageSize) pageSize.value = parseInt(route.query.pageSize);
            badgeService.getBadges(route.query).then((res) => {
                badges.value = res;
                if(res.length && res[0].totalRows) totalRows.value = res[0].totalRows;
            });
        });

        function onPage(e) {
            router.push({ query: { ...route.query, pageSize: e.rows, pageIndex: e.page } });
            badgeService.getBadges({ ...route.query, pageIndex: e.page }).then((res) => {
                badges.value = res;
            });
        }

        function editBadge(badgeId) {
            router.push({ path: `/settings/badges/${badgeId}`, query: route.query });
        }

        return {
            badges,
            editBadge,
            filter,
            onPage,
            pageSize,
            route,
            totalRows
        };
    },
};
</script>

<style lang="scss" scoped>
.badge-list {
    .row.badge-item {
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }

        span.badge-tag {
            display: inline-block;
            margin-top: 0.2em;
            padding: 0.3em 0.5em;
            font-weight: 500;
            border-radius: 0.3em;
        }

        @media (max-width: 767px) {
            min-height: 30px;
            padding: 0.75em;
        }
    }
}
</style>
