import { defineStore } from 'pinia';
import { getAppSettings } from '@/utils/ThmUtils';
import ImageService from '@/service/ImageService';
import PostService from '@/service/PostService';
import AdminService from '@/service/AdminService';

const postService = new PostService();
const adminService = new AdminService();
const imageService = new ImageService();

export const usePostStore = defineStore('PostStore', {
    state: () => ({
        posts: [],
        newPosts:0,
        isLoading:true,
        filterPosts: {
            title: null,
            categoryId: null,
            subscription: null,
            datePublished: null,
            includeBody: getAppSettings().features.postEditor.search.includeBody,
            status: null,
            authorId: null,
            postId: null,
            routeId:null,
            pageIndex: 0,
            pageSize: 30,
            postTypeId: 1,
            total: null,
        },
        autoCompleteAuthor: null,
        categoryList: [],
        imageLicenses: [],
        postTypes: [],
        totalItemsCount: 0,
        statusOptions: [
            { label: 'All', value: '-1' },
            { label: 'Future', value: '3', css: 'bg-warning' },
            { label: 'Published', value: '1', css: 'bg-success' },
            //{ label: 'UnPublished', value: '0', css: 'bg-danger' },
            { label: 'Draft', value: '2', css: 'bg-secondary' },
            { label: 'Pending', value: '4', css: 'bg-primary' },
        ],
        categories: [],
        /*
        categories: [
            {
                label: 'Tipsheet',
                id: 5,
                icon: 'far fa-sticky-note',
                to: '/posts/5',
            },
            {
                label: 'Columnists',
                id: 1,
                icon: 'far fa-sticky-note',
                to: '/posts/1',
            }
        ],
        */
    }),
    getters: {
        category(state) {
            //toplevel
            if(!state.categories || !state.categories.length) return null;
            const name = state.categories.find((n) => n.id == state.filterPosts.categoryId)?.label ?? state.categories.find((n) => n.items).items.find((z) => z.id == state.filterPosts.categoryId)?.label;
            console.log('Category Name: ', name);
            return name;
        },
    },
    actions: {
        async load() {
            await this.getCategories();
            await this.getPostTypes();
            await this.getImageLicenses();
        },
        async getCategories() {
            if(this.categoryList.length) return this.categoryList;
            return adminService.getCategories().then((res) => {
                res = res.filter(item => item.isHidden != true); // Remove hidden categories
                res = res.sort((a, b) => (a.name > b.name) ? 1 : -1); // Sort categories by name
                this.categoryList = res;
                return res;
            }).catch((err) => {
                console.error('Error fetching categories', err);
            });
        },
        async getPostTypes() {
            if(this.postTypes.length) return this.postTypes;
            return postService.getPostTypes().then((res) => {
                this.postTypes = res;
                return res;
            }).catch((err) => {
                console.error('Error fetching post types', err);
            });
        },
        onPage($event) {
            //from emit on-page
            console.log('PAGE', $event.page);
            this.filterPosts.pageIndex = $event.page;
            //this event is fired from emit event on PostList
            this.getPosts(this.filterPosts);
        },

        onAuthor(id) {
            //from emit on-author
            //filter by author link
            this.filterPosts.authorId = id;
            this.router.push({ name: 'Posts', params: { categoryId: this.filterPosts.categoryId }, query: this.filterPosts, hash: this.router.hash });
        },
        filter() {
            let route = this.router.currentRoute.value;
            if(route.query.status != this.filterPosts.status) this.filterPosts.pageIndex = 0; // Set page index to 0 (first page) if filter status changes
            //console.log('filterPosts:', this.filterPosts)
            //this.this.router.push(`/posts/${this.filterPosts.categoryId}/`)
            //https://next.router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
            //this.router.push({ name: 'Posts', params: { categoryId: this.filterPosts.categoryId, id: route.params.id }, query: this.filterPosts, hash: this.router.hash });
            if(route.params.categoryId) {
                this.router.push({ name: 'PostsCategory', params: { id: route.params.id, categoryId: route.params.categoryId }, query: this.filterPosts, hash: this.router.hash });
            } else {
                this.router.push({ name: 'Posts', params: { id: route.params.id }, query: this.filterPosts, hash: this.router.hash });
            }

            //this.getPosts(this.filterPosts)
            //this.getPostsCount(this.filterPosts)
        },
        clearFilter() {
            let appSettings = getAppSettings();

            Object.keys(this.filterPosts).forEach((key) => {
                this.filterPosts[key] = null;
            });

            let route = this.router.currentRoute.value;
            this.filterPosts.categoryId = route.params.categoryId;
            this.filterPosts.pageIndex = 0;
            this.filterPosts.pageSize = 30;
            this.autoCompleteAuthor = null;
            if(appSettings.siteId == 1) {
                this.router.push({ name: 'PostsCategory', params: { categoryId: this.filterPosts.categoryId, id: route.params.id }, query: this.filterPosts, hash: route.hash });
            } else {
                this.router.push({ name: 'Posts', params: { id: route.params.id }, query: this.filterPosts, hash: route.hash });
            }
            //this.router.push({ name: 'Posts', params: { categoryId: this.filterPosts.categoryId, id: route.params.id }, hash: route.hash });
            // this.getPosts(this.filterPosts)
            // this.getPostsCount(this.filterPosts)
        },

        async getPosts(p) {
            //console.log('params', params);
            let appSettings = getAppSettings();
            let params = { ...p, includeBody: appSettings.features.postEditor.search.includeBody, postTypeId: 1 };
            await postService.getPosts(params).then((response) => {
                this.posts = response;
                this.newPosts = 0;
            }).catch((err) => {
                throw err;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        async getPostsCount(params) {
            await postService
                .getPostsCount(params)
                .then((response) => {
                    this.filterPosts.total = response;
                })
                .catch((err) => {
                    //$toast.add({ severity: 'error', summary: 'Error Fetching Counts', detail: err.message || err });
                    throw err;
                });
        },
        async getImageLicenses() {
            if(!this.imageLicenses?.length) this.imageLicenses = await imageService.getImageLicenses();
        },
        getLicenseColor(licenseId) {
            if(!licenseId) return null;
            let lic = this.imageLicenses?.find(({ id }) => id == licenseId);
            return lic?.color ?? '#6C757D';
        }

    },
});
