<template>
    <span class="dot-thm bg-primary"></span>
</template>

<script>


export default {
    name: 'DotThm',
    setup() {}
}
</script>

<style lang="scss" scoped>
span.dot-thm {
    display: inline-block;
    background-color: #017BFE;
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
}
</style>
