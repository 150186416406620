<template>
    <!-- https://media.townhall.com/townhall/reu/hv//2019/35/triggereds.png -->
    <div class="mb-3">
        <Toolbar>
            <template #left>
                <subscriptions-dropdown v-model="selectedSubscription" class="me-2" />
                <Button label="New" icon="pi pi-plus" class="me-2 p-button-sm" />
                <Button label="Upload" icon="pi pi-upload" class="me-2 p-button-success p-button-sm" />
                <Button icon="pi pi-arrow-left" @click="visibleRight = true" class="me-2 p-button-sm" />
                <i class="pi pi-bars p-toolbar-separator p-mr-2" />
            </template>

            <template #right>
                <Button icon="pi pi-search" class="me-2 p-button-sm" />
                <Button icon="pi pi-calendar" class="p-button-success me-2 p-button-sm" />
                <Button icon="pi pi-times" class="p-button-danger p-button-sm" />
            </template>
        </Toolbar>
    </div>
    <div class="row">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Standards Table</div>

                <div class="card-body">
                    <p class="text-muted card-p"><small>Please use responsive tables tabular data</small></p>
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th style="width: 125px" scope="col"></th>
                                    <th scope="col">Show</th>
                                    <th scope="col">Site</th>
                                    <th scope="col" style="width: 100px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="s in sites" :key="s.siteId">
                                    <th scope="row">
                                        <SplitButton label="Edit" :model="editButtons" class="p-button-sm" @click="editShow(s)"></SplitButton>
                                    </th>
                                    <td>
                                        <a href="#" :title="`${s.title} string interpolation`">{{ s.description }}</a>
                                    </td>
                                    <td>Townhall</td>

                                    <td>
                                        <!-- <img
                                        :src="'https://media.townhall.com/townhall/reu/hv'+s.showSettings.squareImage"
                                        class="w-100px"
                                    > -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <Paginator :rows="10" :totalRecords="totalItemsCount"></Paginator>
                    <h3>Rules</h3>
                    <ul>
                        <li>
                            Split Buttons
                            <ul>
                                <li>utilize split buttons for addtional commands</li>
                            </ul>
                        </li>
                        <li>
                            Status colors:
                            <ul>
                                <li><span class="badge bg-success"> Published </span> <code>bg-success</code></li>
                                <li><span class="badge bg-warning"> Scheduled </span> <code>bg-warning</code></li>
                                <li><span class="badge bg-danger"> Unpublished </span> <code>bg-danger</code></li>
                                <li><span class="badge bg-secondary"> Draft </span> <code>bg-secondary</code></li>
                            </ul>
                        </li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">
                    Standards Form
                    <span v-show="show.showId > 0">- {{ show.title }}</span>
                </div>

                <div class="card-body">
                    <form class="row g-3 needs-validation" novalidate>
                        <div class="col-md-4">
                            <label for="validationCustom01" class="form-label">First name</label>
                            <input type="text" class="form-control" id="validationCustom01" value="Mark" required />
                            <div id="emailHelp" class="form-text">extra information if neededed</div>
                            <div class="valid-feedback">Looks good!</div>
                        </div>
                        <div class="col-md-4">
                            <label for="validationCustom02" class="form-label">Last name</label>
                            <input type="text" class="form-control" id="validationCustom02" value="Otto" required />
                            <div class="valid-feedback">Looks good!</div>
                        </div>
                        <div class="col-md-4">
                            <label for="validationCustomUsername" class="form-label">Username</label>
                            <div class="input-group has-validation">
                                <span class="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                                <div class="invalid-feedback">Please choose a username.</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="validationCustom03" class="form-label">Text 1</label>
                            <input type="text" class="form-control" id="validationCustom03" required />
                            <div class="invalid-feedback">Please provide a valid city.</div>
                        </div>
                        <div class="col-md-3">
                            <label for="validationCustom04" class="form-label">Calendar Date</label>
                            <Calendar id="time24" v-model="date1" :show-time="true" :showIcon="true" :show-seconds="false" hour-format="12" />
                            <div class="invalid-feedback">Please select a valid state.</div>
                        </div>
                        <div class="col-md-3">
                            <label for="validationCustom05" class="form-label">Zip</label>
                            <input type="text" class="form-control" id="validationCustom05" required />
                            <div class="invalid-feedback">Please provide a valid zip.</div>
                        </div>
                        <div class="col-12">
                            <InputSwitch v-model="checked" id="isPublished" />
                            <label for="isPublished" class="switch-label"> Publish</label>
                        </div>
                        <div class="col-12"></div>
                        <div class="col-12">
                            <Button label="Save" class="p-button me-2" />
                            <Button label="Delete" class="p-button-danger btn-delete me-2" @click="deleteItem()" />
                        </div>
                        <div class="col-12">
                            <h3>Rules</h3>
                            <ul>
                                <li>
                                    Delete buttons
                                    <ul>
                                        <li><code>p-button-danger</code></li>
                                        <li>Delete buttons should confirm before performing actions <code> ConfirmDialog</code></li>
                                        <li></li>
                                    </ul>
                                </li>
                                <li>
                                    Save and other commands
                                    <ul>
                                        <li>need to respond with a toast message</li>
                                        <li><code>this.$toast.add({ severity: 'error', summary: 'Delete Failed', detail: "err.message", life: 3000 })</code></li>
                                        <li><code>this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Episode Saved', life: 3000 });</code></li>
                                    </ul>
                                </li>
                                <li></li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Test Component</div>

                <div class="card-body">
                    <dl>
                        
                        <dd>
                            <button type="button" class="btn btn-light position-relative">
                                <i class="pi pi-refresh"></i>
                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    2
                                    <span class="visually-hidden">Refresh list ...</span>
                                </span>
                            </button>
                        </dd>
                    </dl>
                    <!-- <Button v-badge="2" label="click here" icon="pi pi-refresh" title="Refresh list ...." iconPos="right" class="p-button-secondary p-button-text p-button-sm ms-2" /> -->
                </div>
            </div>
        </div>
    </div>
    <ConfirmDialog />
</template>

<script>
import SubscriptionsDropdown from '@/components/SubscriptionsDropdown.vue';
import AdminService from '@/service/AdminService';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
    name: 'TableDemo',
    adminService: null,
    components: {
        ConfirmDialog,
        SubscriptionsDropdown,
    },
    created() {
        this.adminService = new AdminService();
    },
    data() {
        return {
            selectedSubscription: null,
            checked: true,
            date1: null,
            editButtons: [
                {
                    label: 'New Show',
                    icon: 'pi pi-plus',
                    class: 'p-button-sm',
                    command: () => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Updated',
                            detail: 'Data Updated',
                            life: 3000,
                        });
                    },
                },
            ],
            totalItemsCount: 122,
            sites: 1,
            show: {
                showId: 0,
                title: String,
            },
        };
    },
    methods: {
        edit(show) {
            console.log('show-', show);
            this.show = { ...show };
        },
        deleteItem() {
            this.$confirm.require({
                message: 'Are you sure you want to permanently delete this upload?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.isLoading = true;
                    this.$toast.add({ severity: 'error', summary: 'Delete Failed', detail: 'err.message', life: 3000 });
                },
            });
        },
    },
    mounted() {
        this.adminService
            .getSites()
            .then((response) => {
                console.log('response', response);
                this.sites = { ...response };
                //const { res } = response
                const user = {
                    id: 42,
                    is_verified: true,
                };

                const { id, is_verified } = user;
                console.log(id, is_verified);
                //console.log(res)
            })
            .catch(function (error) {
                console.log(error);
            });
        //console.log(s);
        //this.podcastService.getShows().then(data=>this.shows = data);
    },
};
</script>
