import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import CartoonService from '@/service/CartoonService';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';

const adminService = new AdminService();
const cartoonService = new CartoonService();

export const useCartoonStore = defineStore('Cartoons', {
    state: () => ({
        loading: false,
        cartoons: null,
        cartoon: {},
        totalRows: 0,
    }),
    actions: {
        async getCartoons(params) {
            this.loading = true;
            await cartoonService.getCartoons(params).then((cartoons) => {
                console.log('📗 GET Cartoons', cartoons);
                for (var idx = 0; idx < cartoons.length; idx++) {
                    //console.log('cartoons', cartoons);
                    // Format dates for display
                    cartoons[idx].datePublished = formatDateTime(cartoons[idx].datePublished);
                }
                this.cartoons = cartoons;
                if (cartoons.length && typeof cartoons[0].totalRows != 'undefined') this.totalRows = cartoons[0].totalRows;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async setCartoon(cartoonId) {
            return this.load().then(() => {
                this.cartoon = this.cartoons.find((obj) => obj.cartoonId == cartoonId);
                return this.cartoon;
            });
        },
        async save() {
            this.loading = true;

            return await cartoonService.getCartoon(this.cartoon.cartoonId).then((original) => {
                // Fetch original
                return cartoonService
                    .updateCartoon(this.cartoon)
                    .then((res) => {
                        // Save new
                        //if(process.env.NODE_ENV !== 'production') console.log('📘 PUT Save cartoon', res);
                        adminService.log({ logTypeId: 4, referenceId: res.cartoonId, message: 'Modified', content: JSON.stringify(res, null, 2), original: JSON.stringify(original, null, 2), language: 'json' });
                        this.cartoon = res;
                        return res;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        async delete() {
            if (!this.cartoon) return;
            this.loading = true;

            return await cartoonService
                .deleteCartoon(this.cartoon.cartoonId)
                .then(() => {
                    //if(process.env.NODE_ENV !== 'production') console.log('📕 DELETE Cartoon', this.cartoon);
                    adminService.log({ logTypeId: 4, referenceId: this.cartoon.cartoonId, message: 'Delete', content: null, original: JSON.stringify(this.cartoon, null, 2), language: 'json' });
                    this.cartoon = {};
                    return this.cartoon;
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
});
