<template>
    <div class="card card-border-color card-border-color-primary posts">
        <div class="card-header">
            <div class="hstack">
                <div>
                    {{ cptStore.postType?.name ? cptStore.postType.name + 's' : null }}
                </div>
                <button @click="onRefresh" type="button" class="btn btn-light position-relative ms-2" v-if="cptStore.postType?.name">
                    <i class="pi pi-refresh"></i>

                    <span v-if="newPosts > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {{ newPosts }}
                        <span class="visually-hidden">Refresh list ...</span>
                    </span>
                </button>
                <SpinnerThm class="spinner ps-2" size="1em" v-if="cptStore.isLoading" />
            </div>
            <!-- <button @click="testFunc">Test</button> -->
        </div>
        <div class="card-body container-fluid pb-3">
            <PaginationThm v-model="route.query.pageIndex" :totalRecords="cptStore.totalPosts" :pageSize="cptStore.pageSize" :showTotalRecords="true" @page="onPage" />

            <div v-for="post in cptStore.posts" :key="post.postId" class="row g-2 border-bottom py-2">
                <div class="col-md-auto d-none d-md-block">
                    <Button label="Edit" class="btn-edit" @click="editPost(post.postId)" />
                </div>
                <div class="col-md-6 col-xl-7"><!-- Large screens -->
                    <a :href="getPermalink(post.url)" target="_blank" v-if="post.url && post.status == 1" class="d-none d-md-block">{{ post.title }}</a>
                    <router-link :to="{ name: 'CustomPostTypeManager', params: { postTypeId: cptStore.postType.postTypeId, postId: post.postId } }" v-if="post.url && post.status == 1" class="d-md-none">{{ post.title }}</router-link><!-- Mobile -->
                    <span v-else>{{ post.title }}</span>

                    <!-- Headlines -->
                    <div v-show="cptStore.postType.postTypeId == 4">
                        <small class="d-block text-muted">
                            {{ post.extendedProperties?.customPost?.source?.name }}
                        </small>
                    </div>
                    <!-- End Headlines -->

                    <div v-if="post.userIdLock" class="small">
                        <span class="me-1 far fa-lock text-danger"></span>
                        <span class="text-surface-700">{{ post.userIdLock + ' is currently editing' }}</span>
                    </div>
                </div>
                <div class="col">
                    <PublishLabelStatus :datetime="post.datePublished" :status="post.status" :showTimezone="false" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, onBeforeUnmount, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCptStore } from '@/stores/CptStore';
import { getPermalink } from '@/utils/ThmUtils';
import PublishLabelStatus from '@/components/PublishLabelStatus.vue';
import PaginationThm from '@/components/PaginationThm.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';
import useSignalR from '@/use/SignalR';

export default {
    name: 'CustomPostTypeList',
    components: {
        PaginationThm,
        PublishLabelStatus,
        SpinnerThm,
    },
    setup() {
        const cptStore = useCptStore();
        const route = useRoute();
        const router = useRouter();
        const signalr = useSignalR();

        const newPosts = ref(0);

        // Signal-R
        signalr.on('ReceiveNewPostNotification', (message) => setNewPosts(message));
        signalr.on('ReceivePostLock', (message) => setLockMessageOnPostList(message));

        // Watch for route changes
        watch(
            // () => route.query, (params, fromParams) => {
            () => route.query,
            async (params) => {
                cptStore.getPosts( { postTypeId: route.params.postTypeId, ...params });
            }
        );

        onMounted(() => {
            cptStore.getPosts( { postTypeId: route.params.postTypeId, ...route.query });
        });

        onBeforeUnmount(() => {
            ['ReceivePostLock', 'ReceiveNewPostNotification'].forEach((methodName) => {
                signalr.off(methodName);
            });
        });

        function onPage(e) {
            let query = { ...route.query, pageIndex: e.page };
            if(!query.pageIndex) delete query.pageIndex;
            router.push({ query: { ...query } });
        }

        function editPost(postId) {
            router.push({ path: `/cpt/${route.params.postTypeId}/${postId}`, query: route.query });
        }

        function onRefresh() {
            cptStore.getPosts( { postTypeId: route.params.postTypeId, ...route.query }).then(() => {
                newPosts.value = 0;
            });
        }

        function setNewPosts(msg) {
            if(console.log.hide) console.log({msg});
            if(msg.postTypeId == route.params.postTypeId) {
                newPosts.value++;
                console.log('[CPT] New post: ', msg);
            }
        }

        function setLockMessageOnPostList(postMessage) {
            console.log('[CPT] ReceivePostLock', postMessage);
            let postIndex = cptStore.posts.findIndex((n) => n.postId == postMessage.postId);
            cptStore.posts[postIndex] = { ...cptStore.posts[postIndex], userIdLock: postMessage.userIdLock };
        }

        // function testFunc() {
        //     let msg = {
        //         postId: 1,
        //         postTypeId: 4
        //     }
        //     console.log('Signal-R: Send', msg);
        //     signalr.invoke('BroadcastNewPostNotification', msg);
        //     //signalr.invoke('BroadcastNewPost', 1);
        // }

        return {
            cptStore,
            editPost,
            getPermalink,
            newPosts,
            onPage,
            onRefresh,
            route,
            //testFunc,
        }
    }
}
</script>

<style lang="scss" scoped>
.text-surface-700 {
    color: var(--surface-700);
}
</style>
