<template>
    <ConfirmDialog />

    <div class="col-sm-12 col-md-5 col-xl-6 editor-column" id="block_copy_editor">
        <div class="card card-border-color card-border-color-primary">
            <div class="card-header card-header-divider">
                <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" @click="addBlock()" title="Add New" />
                {{ route.params.blockCopyId ? 'Edit' : 'Add' }} Block Copy
            </div>

            <div class="card-body">
                <div class="mb-1" v-if="blockCopyStore.block.blockCopyId">
                    <span class="form-label">ID:</span> {{ blockCopyStore.block.blockCopyId }}
                </div>

                <div class="mb-3">
                    <label for="Title" class="form-label required">Description</label>
                    <InputText type="text" class="form-control" v-model="blockCopyStore.block.title" id="Title" />
                </div>

                <div class="mb-3 d-none">
                    <label for="SiteGroup" class="form-label required">Site(s)</label>
                    <SiteGroupSelect v-model="blockCopyStore.block.siteGroup" />
                </div>

                <div class="mb-3">
                    <label for="Body" class="form-label">Body</label>
                    <Textarea v-model="blockCopyStore.block.body" :autoResize="true" rows="6" class="form-control" id="Body" />
                </div>

                <div class="mb-3">
                    <InputSwitch v-model="blockCopyStore.block.isPublished" id="IsPublished" />
                    <label for="IsPublished" class="switch-label">Published</label>
                </div>

                <div class="mb-4" v-show="blockCopyStore.block.isPublished">
                    <label for="DatePublished" class="form-label d-block">Date Published</label>
                    <Calendar id="DatePublished" v-model="blockCopyStore.block.datePublished" :show-icon="true" :manual-input="false" :showButtonBar="true"  dateFormat="mm/dd/yy" />
                </div>

                <p>
                    <Button :label="blockCopyStore.block.blockCopyId ? 'Save Block Copy' : 'Add Block Copy'" @click="saveBlock()" x-title="Save (Ctrl+S or ⌘S)" />
                    <Button label="Delete" class="p-button-danger btn-delete ms-2" v-show="blockCopyStore.block.blockCopyId && userHasAccessToRole('Admin')" @click="deleteBlock()" />
                    <LogViewerThm label="Change Log" :filter="{ logTypeId: 12, referenceId: blockCopyStore.block.blockCopyId }" v-if="blockCopyStore.block.blockCopyId" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                </p>

            </div>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, watch } from 'vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useBlockCopyStore } from '@/stores/BlockCopyStore';
//import { useMagicKeys, whenever } from '@vueuse/core';
import SiteGroupSelect from '@/components/SiteGroupSelectClient.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import LogViewerThm from '@/components/LogViewerThm.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'BlockCopyEditor',
    components: {
        ConfirmDialog,
        SiteGroupSelect,
        LogViewerThm
    },
    setup() {
        const blockCopyStore = useBlockCopyStore();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const confirm = useConfirm();
        const { user } = useAuth0();

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveBlock());
        whenever(meta_s, () => saveBlock());
        */

        // Watch for route changes
        watch(
            () => route.params, (params, fromParams) => {
                //console.log('[watch] BlockCopyEditor');
                if(params.blockCopyId != fromParams.blockCopyId) {
                    // Only if blockCopyId changed
                    if(params.blockCopyId) {
                        blockCopyStore.setBlockCopy(params.blockCopyId).catch((err) => {
                            toast.add({ severity: 'error', summary: 'Error Fetching Block Copy', detail: err.message || err, life: 3000 });
                        });
                    } else {
                        blockCopyStore.setDefaults();
                    }
                }
            }
        );

        onBeforeMount(() => {
            if(route.params.blockCopyId) {
                blockCopyStore.setBlockCopy(route.params.blockCopyId).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Block Copy', detail: err.message || err, life: 3000 });
                });
            } else {
                blockCopyStore.setDefaults();
            }
        });

        function saveBlock() {
            // Check required fields
            var requiredFields = [blockCopyStore.block.title, blockCopyStore.block.siteGroup], isFormInvalid = false;
            //var requiredFields = [], isFormInvalid = false; // Temporarily disable field checking
            requiredFields.forEach((elm) => {
                if(!elm) isFormInvalid = true;
            });
            if(isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            // Save block
            blockCopyStore.save().then((res) => {
                if(console.log.hide) console.log('res', res);
                router.push({ path: `/manage/blockcopy/${res.blockCopyId}`, query: route.query });
                toast.add({ severity: 'success', summary: 'Success', detail: 'Block Copy Saved', life: 3000 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Block Copy', detail: err.message || err, life: 3000 });
            });
        }

        function addBlock() {
            router.push({ path: `/manage/blockcopy`, query: route.query });
        }

        function deleteBlock() {
            if(!blockCopyStore.block.blockCopyId) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this block copy?',
                header: 'Delete Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    blockCopyStore.delete().then(() => {
                        router.push({ path: '/manage/blockcopy', query: route.query });
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting Block Copy', detail: err.message || err, life: 3000 });
                    });
                }
            });
        }

        return {
            addBlock,
            blockCopyStore,
            deleteBlock,
            route,
            saveBlock,
            toast,
            user,
            userHasAccessToRole,
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .form-label {
        margin-bottom: 0.2rem;
        font-weight: 500;
    }
    .required::after {
        content: '*';
        color: rgb(255, 0, 0);
    }
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
    .input {
        &__tiny {
            width: 75px;
        }
    }
}
</style>
