/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';
import { inject } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

//core pages
import LoginCallback from '@/components/LoginCallback.vue';
import Home from '@/pages/Home.vue';
import Environment from '@/pages/Environment.vue';
import Profile from '@/pages/Profile.vue';
import Redirect from '@/pages/Redirect.vue';
import Loggedout from '@/pages/Loggedout.vue';
import Logoff from '@/pages/Logoff.vue';
import Unauthorized from '@/pages/Unauthorized.vue';
import NotFound from '@/pages/NotFound.vue';
//Posts
import Posts from '@/pages/posts/Posts.vue';

// Tools
import CachePurge from '@/pages/tools/CachePurge.vue';
import LogSearch from '@/pages/tools/logs/Logs.vue';
import LogEntry from '@/pages/tools/logs/LogEntry.vue';
import ImageManager from '@/pages/tools/ImageManager/ImageManager.vue';
import GetData from '@/pages/tools/GetData.vue';
import THAfternoon from '@/pages/newsletters/TownhallAfternoon.vue';
import CoRegReports from '@/pages/tools/CoReg/Reports.vue';
import CoRegPartnersReports from '@/pages/tools/CoReg/PartnersReports.vue';
import CoRegOffers from '@/pages/tools/CoReg/Offers.vue';

// Reports
import StatsReport from '@/pages/reports/StatsReport.vue';
import AuthorPostsReport from '@/pages/reports/AuthorPostsReport.vue';
import VipRevenueReport from '@/pages/reports/VipRevenue.vue';

// Sections
import HomePageManager from '@/pages/manage/home/HomePageManager.vue';
import Cartoons from '@/pages/manage/cartoons/Cartoons.vue';
import Podcasts from '@/pages/manage/podcasts/Podcasts.vue';
import EditorsChoice from '@/pages/editors-choice/EditorsChoice.vue';
import LiveBlog from '@/pages/liveblog/LiveBlog.vue';
import LiveBlogThread from '@/pages/liveblog/LbThread.vue';
import LiveBlogPost from '@/pages/liveblog/LbPost.vue';
//import Podcasts2 from '@/pages/manage/podcasts2/Podcasts.vue';

// Settings
import AlertBar from '@/pages/settings/AlertBar.vue';
import AuthorManager from '@/pages/settings/authors/Authors.vue';
import BlockCopy from '@/pages/manage/blockcopy/BlockCopy.vue';
import Cpt from '@/pages/manage/cpt/Cpt.vue';
import MenuEditor from '@/pages/menus/MenuEditor.vue';
import TagManager from '@/pages/settings/tags/Tags.vue';
import BadgeManager from '@/pages/settings/badges/Badges.vue';
import VirtualPage from '@/pages/manage/virtualpage/VirtualPage.vue';
import SeoManager from '@/pages/settings/seo/Seo.vue';
import ColumnistsWidget from '@/pages/settings/widgets/Columnists.vue';

// Demos
import TableDemo from '@/pages/demos/TableDemo.vue';
import MultiDemo from '@/pages/demos/MultiDemo.vue';
import EditorDemo from '@/pages/demos/EditorDemo.vue';
import FroalaImageManagerSandbox from '@/pages/demos/sandbox/FroalaImageMgrSandbox.vue';
import NotificationDemo from '@/pages/demos/NotificationDemo.vue';
import FileUploadDemo from '@/pages/demos/FileUploadDemo.vue';
import FormComponentDemo from '@/pages/demos/FormComponentDemo.vue';
import ImageManagerSandbox from '@/pages/demos/sandbox/ImageManagerSandbox.vue';
import FroalaDemo from '@/pages/demos/FroalaDemo.vue';
import FroalaSandbox from '@/pages/demos/FroalaSandbox.vue';
import FroalaTest from '@/pages/demos/FroalaTest.vue';
import DraggableDemo from '@/pages/demos/draggable/DraggableDemo.vue';
import PaginationDemo from '@/pages/demos/PaginationDemo.vue';
import PromiseDemo from '@/pages/demos/PromiseDemo.vue';
import SignalrSendReceiveDemo from '@/pages/demos/signalr/SendReceiveDemo.vue';
import SignalrPostLockDemo from '@/pages/demos/signalr/PostLockDemo.vue';
import VideoChooserDemo from '@/pages/demos/VideoChooserDemo.vue';

import TestMenuDemo from '@/pages/demos/TestMenuDemo.vue';
import SiteStoreOptionsApiDemo from '@/pages/demos/archived/SiteStoreOptionsApiDemo.vue';
import ThmLogViewerDemo from '@/pages/demos/ThmLogViewerDemo.vue';

import UseBrowserLocationDemo from '@/pages/demos/vueuse/UseBrowserLocationDemo.vue';
import UseGeneralDemo from '@/pages/demos/vueuse/UseGeneralDemo.vue';
import useInfiniteScrollDemo from '@/pages/demos/vueuse/useInfiniteScrollDemo.vue';

const defaultRoles = ['Contributor', 'Author', 'Editor', 'Admin', 'Developer'],
    adminOnly = ['Admin', 'Developer'],
    editorAndHigher = ['Editor', 'Admin', 'Developer'],
    everyone = ['Everyone']; //default everyone role for okta

const routes = [
    {
        path: '/login/callback',
        component: LoginCallback,
    },
    {
        path: '/callback',
        component: LoginCallback,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        //beforeEnter: routeGuard,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized,
    },
    {
        path: '/env',
        name: 'Environment',
        component: Environment,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/Profile',
        name: 'Profile',
        component: Profile,
        //beforeEnter: routeGuard,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/redirect',
        name: 'Redirect',
        component: Redirect,
    },
    {
        //path: '/posts/:categoryId?/:id?',
        //path: '/posts/:id?',
        path: '/posts/:id?',
        name: 'Posts',
        component: Posts,
        props: true,
        //query: { authorId: null },
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
            siteIds: [2, 3, 4, 5, 6],
        },
    },
    {
        path: '/post/:categoryId/:id?',
        name: 'PostsCategory',
        component: Posts,
        props: true,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
            //siteIds: [1],
        },
    },
    {
        path: '/cpt/:postTypeId/:postId?',
        name: 'CustomPostTypeManager',
        component: Cpt,
        //props: true,
        meta: {
            roles: ['Admin'],
            requiresAuth: true,
            siteIds: [5],
        },
    },
    {
        path: '/settings/alertbar',
        name: 'AlertBar',
        component: AlertBar,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/settings/widgets/columnists',
        name: 'ColumnistsWidget',
        component: ColumnistsWidget,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/tools/cache',
        name: 'CachePurge',
        component: CachePurge,
        meta: {
            roles: ['Developer'],
            requiresAuth: true,
        },
    },
    {
        path: '/newsletters/TownhallAfternoon/',
        name: 'THAfternoon',
        component: THAfternoon,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
            siteIds: [1],
        },
    },
    {
        path: '/tools/get/:type',
        name: 'GetData',
        component: GetData,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/imagelib',
        name: 'ImageManager',
        component: ImageManager,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/tools/logs',
        name: 'LogSearch',
        component: LogSearch,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/log/:logId',
        name: 'LogEntry',
        component: LogEntry,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/tools/coreg/reports/',
        name: 'CoRegReports',
        component: CoRegReports,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/tools/coreg/partnersreports/',
        name: 'CoRegPartnersReports',
        component: CoRegPartnersReports,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/tools/coreg/offers/:offerId?',
        name: 'CoRegOffers',
        component: CoRegOffers,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/settings/badges/:id?',
        name: 'BadgeManager',
        component: BadgeManager,
        meta: {
            roles: ['Developer'],
            requiresAuth: true,
        },
    },
    {
        path: '/tags/:tag?',
        name: 'TagManager',
        component: TagManager,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/reports/statsreport',
        name: 'StatsReport',
        component: StatsReport,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/reports/postcounts',
        name: 'AuthorPostsReport',
        component: AuthorPostsReport,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/reports/viprevenue',
        name: 'VipRevenueReport',
        component: VipRevenueReport,
        meta: {
            roles: ['Reports - VIP Revenue', 'Developer'],
            requiresAuth: true,
        },
    },
    {
        path: '/settings/authors/:authorId?',
        name: 'AuthorManager',
        component: AuthorManager,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/manage/home',
        //alias: ['/tags'], // Temporary to get rid of the console warning
        name: 'HomePageManager',
        component: HomePageManager,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    {
        path: '/manage/cartoons',
        name: 'Cartoons',
        component: Cartoons,
        meta: {
            roles: editorAndHigher,
            requiresAuth: true,
            siteIds: [1],
        },
    },
    {
        path: '/section/editors-choice',
        name: 'EditorsChoice',
        component: EditorsChoice,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
            siteIds: [2, 4],
        },
    },
    {
        path: '/liveblog',
        name: 'LiveBlog',
        component: LiveBlog,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/liveblog/:postId',
        name: 'LiveBlogThread',
        component: LiveBlogThread,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/liveblog/settings/:postId?',
        name: 'LiveBlogPost',
        component: LiveBlogPost,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
    },
    // {
    //     path: '/manage/podcasts/:showId?',
    //     name: 'Podcasts',
    //     component: Podcasts,
    //     meta: {
    //         roles: adminOnly,
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: '/manage/podcasts/:showId/episodes/:episodeId?',
    //     name: 'PodcastEpisode',
    //     component: Podcasts,
    //     meta: {
    //         roles: adminOnly,
    //         requiresAuth: true,
    //     },
    // },
    {
        path: '/manage/podcasts/:showId?',
        component: Podcasts,
        meta: {
            roles: adminOnly,
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'Podcasts',
                component: Podcasts,
            },
            {
                path: 'episodes/:episodeId?',
                name: 'PodcastEpisode',
                component: Podcasts,
            },
        ],
    },
    // {
    //     path: '/manage/podcasts2/:showId?/:episodeId?',
    //     name: 'Podcasts2',
    //     component: Podcasts2,
    //     meta: {
    //         roles: adminOnly,
    //         requiresAuth: true,
    //     },
    // },
    {
        path: '/settings/menu/:id?',
        name: 'MenuEditor',
        component: MenuEditor,
        meta: {
            roles: ['Developer'],
            requiresAuth: true,
        },
    },
    {
        path: '/settings/seo/:id?',
        name: 'SeoManager',
        component: SeoManager,
        meta: {
            roles: ['Developer'],
            requiresAuth: true,
        },
    },
    {
        path: '/manage/blockcopy/:blockCopyId?',
        name: 'BlockCopy',
        component: BlockCopy,
        meta: {
            roles: ['Developer'],
            requiresAuth: true,
        },
    },
    {
        path: '/manage/virtualpage/:virtualPageId?',
        name: 'VirtualPage',
        component: VirtualPage,
        meta: {
            roles: ['Developer'],
            requiresAuth: true,
        },
    },
    //Demos
    {
        path: '/demos/TableDemo',
        name: 'TableDemo',
        component: TableDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/FroalaDemo',
        name: 'FroalaDemo',
        component: FroalaDemo,
    },
    {
        path: '/demos/FroalaSandbox',
        name: 'FroalaSandbox',
        component: FroalaSandbox,
    },
    {
        path: '/demos/FroalaTest',
        name: 'FroalaTest',
        component: FroalaTest,
    },
    {
        path: '/demos/draggable',
        name: 'DraggableDemo',
        component: DraggableDemo,
    },
    {
        path: '/demos/pagination',
        name: 'PaginationDemo',
        component: PaginationDemo,
    },
    {
        path: '/demos/promises',
        name: 'PromiseDemo',
        component: PromiseDemo,
    },
    {
        path: '/demos/videochooser',
        name: 'VideoChooserDemo',
        component: VideoChooserDemo,
    },
    {
        path: '/demos/signalr/sendreceive',
        name: 'SignalrSendReceiveDemo',
        component: SignalrSendReceiveDemo,
    },
    {
        path: '/demos/signalr/postlock',
        name: 'SignalrPostLockDemo',
        component: SignalrPostLockDemo,
    },
    {
        path: '/demos/menutest',
        name: 'TestMenuDemo',
        component: TestMenuDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/EditorDemo',
        name: 'EditorDemo',
        component: EditorDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/MultiDemo',
        name: 'MultiDemo',
        component: MultiDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/NotificationDemo',
        name: 'NotificationDemo',
        component: NotificationDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/archived/sitestoreoptionsapi',
        name: 'SiteStoreOptionsApiDemo',
        component: SiteStoreOptionsApiDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/vueuse/UseGeneralDemo',
        name: 'UseGeneralDemo',
        component: UseGeneralDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/vueuse/UseBrowserLocation',
        name: 'UseBrowserLocationDemo',
        component: UseBrowserLocationDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/vueuse/useInfiniteScroll',
        name: 'useInfiniteScrollDemo',
        component: useInfiniteScrollDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/ThmLogViewer',
        name: 'ThmLogViewerDemo',
        component: ThmLogViewerDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/fileuploaddemo',
        name: 'FileUploadDemo',
        component: FileUploadDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/demos/FormComponentDemo',
        name: 'FormComponentDemo',
        component: FormComponentDemo,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/sandbox/imagemanager',
        name: 'ImageManagerSandbox',
        component: ImageManagerSandbox,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/sandbox/froalaimagemgr',
        name: 'FroalaImageManagerSandbox',
        component: FroalaImageManagerSandbox,
        meta: {
            roles: defaultRoles,
            requiresAuth: true,
        },
    },
    {
        path: '/logoff',
        name: 'Logoff',
        component: Logoff,
    },
    {
        path: '/Loggedout',
        name: 'Loggedout',
        component: Loggedout,
    },
    // will match everything and put it under `$route.params.pathMatch`
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

//https://github.com/okta/okta-vue/blob/a3599f6666924f0e329b87d97baff49bc0431c34/src/okta-vue.ts
//https://developer.okta.com/blog/2020/05/15/vue-login
//https://next.router.vuejs.org/api/#children
//router.beforeEach(navigationGuard)

/*
import { navigationGuard } from '@/mixins/ThmOktaAuth';
router.beforeEach(navigationGuard);
*/

router.beforeEach(authGuard);

router.beforeEach(async (to, from) => {
    const appSettings = inject('appSettings');

    //console.log('VUE_APP_ROLE', process.env.VUE_APP_ROLE);
    //console.log('VUE_APP_PROFILE', process.env);
    //const { user } = useAuth0();
    //console.log('getAccessTokenSilently', user);

    /*
    // @auth0/auth0-vue v1:
    const authState = getAuthState();
    let userRoles = authState?.body?.decodedToken.user['https://cms.townhall.com/api/roles'];
    let canUserAccess = !to.meta.requiresAuth || !to.meta.roles || userRoles.includes(process.env.VUE_APP_ROLE);
    if(!canUserAccess) return { name: 'Unauthorized', component: Unauthorized }
    */

    // const { user } = useAuth0();
    // let userRoles = user.value['https://cms.townhall.com/api/roles'];
    // let canUserAccess = !to.meta.requiresAuth || !to.meta.roles || userRoles.includes(appSettings.auth0.appRole) || userRoles.includes('Developer');
    // if(!canUserAccess) return { name: 'Unauthorized', component: Unauthorized }

    const { user } = useAuth0();
    let userRoles = user.value[`${appSettings.auth0.audience}/roles`] || [];
    let canUserAccess = !to.meta.requiresAuth || !to.meta.roles || userRoles.length > 0;
    if(!canUserAccess) return { name: 'Unauthorized', component: Unauthorized }

    // Check if site has access to route
    if(to.meta.siteIds?.length) {
        if(!to.meta.siteIds.includes(appSettings.siteId)) {
            return { name: 'NotFound', component: NotFound }
        }
    }

    /* 2:
    const authState = getAuthState();
    let userRoles = authState?.body?.decodedToken.user['https://cms.townhall.com/api/roles'];
    let canUserAccess = !to.meta.requiresAuth || !to.meta.roles || userHasAccessToRoute(to.meta.roles, userRoles);
    if(!canUserAccess) return { name: 'Unauthorized', component: Unauthorized }
    */
    /* 1:
    let token = JSON.parse(localStorage.getItem('@@auth0spajs@@::qGYWCdOvHgtFBIAEk11D2g6Dz6SFHwc7::https://cms.townhall.com/api::openid profile email'));
    let userRoles = token?.body?.decodedToken.user['https://cms.townhall.com/api/roles'];
    let canUserAccess = !to.meta.requiresAuth || !to.meta.roles || userHasAccessToRoute(to.meta.roles, userRoles);
    if(!canUserAccess) return { name: 'Unauthorized', component: Unauthorized }
    */
    //console.log({to});
    //canUserAccess() returns `true` or `false`
    //let s = await authGuard(to);
    //console.log(s);
});

// router.beforeEach(async (to, from) => {
//     // canUserAccess() returns `true` or `false`
//     let s = await navigationGuard(to);
//     console.log(s);

// })

export default router;
