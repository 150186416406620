<template>
    <div class="row mb-3">
        <div class="col-sm-8">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Image Manager</div>

                <div class="card-body">
                    <p>This component will return the same variable type that is passed to it. For example, if you pass it just a URL string, it will return a URL string. If you pass it an image object, it will return an image object.</p>

                    <h5>(1) Image field with Preview:</h5>
                    <ul>
                        <li>This example passes an <strong>object</strong> to the <code>v-model</code>, and will thus return an object:</li>
                    </ul>
                    <code>
                        <pre>
    {
        url: "https://media.townhall.com/cdn/hodl/2022/129/3921b469-d275-47c3-86ee-a4bdea2b9012.jpg",
        credit: "AP Photo/Manuel Balce Ceneta, File"
    }</pre>
                    </code>
                    <ImageLibrary v-model="image1" />

                    <p>
                        <strong>ID:</strong> <em>{{ image1.id || 'null' }}</em
                        ><br />
                        <strong>Caption:</strong> <em>{{ image1.credit || 'null' }}</em>
                    </p>

                    <hr />
                    <h5>(2) Simple image field:</h5>
                    <ul>
                        <li>This example passes a URL <strong>string</strong> to the <code>v-model</code>, and will thus return a URL string.</li>
                    </ul>

                    <p class="help">This field just has the URL in a text field.</p>
                    <ImageLibrary v-model="image2" :config="{ showPreview: false }" />

                    <hr />
                    <p>Open browser console to see results.</p>
                    <Button label="Submit" @click="submitForm" />
                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Side Bar</div>

                <div class="card-body">
                    <!-- Nothing -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';

export default {
    name: 'ImageManagerSandbox',
    data() {
        return {
            image1: {
                // Example of passing an object as a v-model
                url: 'https://media.townhall.com/townhall/reu/ha/2022/129/3921b469-d275-47c3-86ee-a4bdea2b9012.jpg',
                credit: 'AP Photo/Manuel Balce Ceneta, File',
            },
            image2: 'https://media.townhall.com/townhall/reu/d/2021/256/a4702bec-d15c-494e-8249-dfeaf65bacc1.jpg', // Example of passing only a URL as v-model
        };
    },
    components: {
        ImageLibrary,
    },
    methods: {
        submitForm() {
            console.log('(1) Selected image:', this.image1);
            console.log('(2) Selected image:', this.image2);
        },
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
}
</style>
