import { defineStore } from 'pinia';
import { getAppSettings } from '@/utils/ThmUtils';
import AdminService from '@/service/AdminService';
//import SettingsService from '@/service/SettingsService';
import WidgetService from '@/service/WidgetService';

//import { uuid } from 'vue-uuid';
//import { formatDateTime, formatDateTimeISO, isEmptyDate } from '@/utils/ThmDateTimeUtils';
//import { uppercaseFirst } from '@/utils/ThmStringUtils';

const adminService = new AdminService();
const widgetService = new WidgetService();

//const settingsService = new SettingsService();

export const useStore = defineStore('ColumnistsWidgetStore', {
    state: () => ({
        appSettings: getAppSettings(),
        settings: {
            id: 0,
            name: null,
            description: null,
            templateId: 0,
            datePublished: new Date(),
            isPublished: false,
            duration: 30,
            limit: 11,
            expires: null,
        },
        loading: true,
        //widgetLimit: 9,
        original: {},
        // dateModified: new Date(),
        // startDate: new Date(),
        // hours: 30,
        //isEnabled: false,
        list: {
            live: [],
            future: [],
        },
        changedColumn: {
            live: false,
            future: false,
        },

        item: {
            ordinalPosition: 0,
            id: 0,
            title: null,
            author: null,
            image: null,
            url: null,
            datePublished: null,
            rank: 0,
            subscription: 0,
        },
    }),
    getters: {
        isDirty() {
            return this.changedColumn['live'] || this.changedColumn['future'] || this.changedPosts > 0;
        },
        disableSave(state) {
            return !state.settings.isPublished;
        }
    },
    actions: {
        async getSettings(id) {
            //this.list['live']
            let params = {
                id: id,
            };
            await widgetService
                .getSettings(params)
                .then((res) => {
                    this.settings = res;
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {});
            this.loading = false;
        },
        async save() {
            console.log('Save Widget items: ', this.settings.id, this.list['live']);
            await widgetService.saveColumnists(this.settings, this.list['live']);

            adminService.log({ logTypeId: 14, referenceId: this.settings.id, message: 'Saved ', content: JSON.stringify(this.list['live'], null, 2), original: JSON.stringify(this.original, null, 2), language: 'json' });
        },
        async getColumnists(column) {
            let isFuture = column == 'live' ? false : true;
            let params = {
                future: isFuture,
                duration: this.settings.duration,
                siteGroup: this.appSettings.siteGroup,
                categoryId: this.appSettings.features.widgets?.columnists?.categoryId
            };
            this.list[column] = await widgetService.getColumnists(params);
            this.renumber();
            //this.original = this.list[column];
            //this.loading = false;

            //this.list['future'] = await widgetService.getColumnists(true);
            //this.list['live'].map(obj => ({ ...obj, uuid: uuid.v4() })); // Add UUID to each post

            // await widgetService.getColumnists(params).then((res) => {
            //     this.list[column] = res;
            // }).finally(() => {
            //     this.loading = false;
            // });
        },
        arrayMove(arr, fromIndex, toIndex) {
            // https://www.codegrepper.com/code-examples/javascript/change+position+of+element+in+array+javascript
            var newArr = [...arr]; // Clone
            var element = newArr[fromIndex];
            newArr.splice(fromIndex, 1);
            newArr.splice(toIndex, 0, element);
            return newArr;
        },
        renumber(status = true) {
            // status = "live", "future" or true (both live and future)
            if (status === true) {
                this.renumber('live');
                this.renumber('future');
            } else {
                for (let idx = 1; idx <= this.list[status].length; idx++) {
                    this.list[status][idx - 1].ordinalPosition = idx;
                    this.list[status][idx - 1].isLive = status == 'live';
                }
            }
        },
        movePosition(post, status, dir) {
            if (console.log.hide) console.log(status);

            // Get current index of object in array
            var idx = this.list[status].findIndex((item) => item.id === post.id);

            var newPosition = 0;
            if (!isNaN(dir)) {
                newPosition = dir - 1;
            } else {
                // Move the item up or down
                newPosition = dir == 'up' ? idx-- : idx++;
            }

            if ((dir == 'up' && idx < 0) || (dir = 'down' && idx >= this.list[status].length)) return;
            this.list[status] = this.arrayMove(this.list[status], idx, newPosition);

            // Highlight the item as changed
            //this.changedPosts.push(post.uuid); // TODO

            this.renumber(status);
            this.changedColumn[status] = true;
        },
        moveColumn(post, sourceColumn, targetColumn) {
            post.isLive = targetColumn == 'live';

            // Set datePublished when made live
            if (targetColumn == 'live') {
                //post.dateLive = formatDateTimeISO(); // now
            }

            // Remove from source column
            this.list[sourceColumn] = this.list[sourceColumn].filter(function (obj) {
                return obj.uuid !== post.uuid;
            });
            this.list[targetColumn].unshift(post);

            this.renumber(true);
            this.changedColumn.live = true;
            this.changedColumn.future = true;
        },
        remove(post, status) {
            this.list[status] = this.list[status].filter((item) => item.uuid !== post.uuid);
            //this.changedPosts.push(post.uuid); // TODO
            this.renumber(status);
            this.changedColumn[status] = true;
        },
    },
});
