<template>
    <form class="needs-validation" novalidate>
        <!-- Filter bar -->
        <div class="row mb-3">
            <div class="col">
                <TagsFilterBar />
            </div>
        </div>

        <!-- Main Area -->
        <div class="row mb-3">
            <div class="col-sm-12 col-md-6 col-xl-5">
                <div class="card card-border-color card-border-color-primary shows">
                    <TagsList />
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-xl-7 editor-column">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">
                        <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny float-end" iconPos="right" @click="addNewTag()" title="Add New Author" />
                        {{ route.params.tag ? 'Edit' : 'Add' }} Tag
                    </div>
                    <TagsEditor />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
//import { onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TagsFilterBar from './TagsFilterBar.vue';
import TagsList from './TagsList.vue';
import TagsEditor from './TagsEditor.vue';

export default {
    name: 'Tags',
    components: {
        TagsEditor,
        TagsFilterBar,
        TagsList,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const tagService = new TagsService();

        function addNewTag() {
            router.push({ path: `/tags`, query: route.query });
        }

        return { addNewTag, route };
    },
};
</script>

<style lang="scss" scoped>
div.editor-column {
    @media (max-width: 767px) {
        margin-top: 1.25em;
    }
}
</style>
