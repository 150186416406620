
<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Log Off</div>

                <div class="card-body">
                    <p>You are being logged off...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    name: 'Logoff',
    inject: ['appSettings'],
    mounted() {
        Cookies.remove('_wpadmin', { domain: process.env.NODE_ENV == 'development' ? null : '.' + this.appSettings.domain, path: '/' });
        this.$auth.signOut();
    },
    methods: {},
};
</script>
