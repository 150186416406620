import { resizeImageUrl } from './ThmImageUtils';

export function hasImg(images) {
    let img = images != null && images.length > 0 && images[0].url;
    return img;
}

export function getImage(images, size = '75x58') {
    let hasImg = images != null && images.length > 0;
    let i = images[0]?.url;
    /*
    let ext = i?.substring(i.lastIndexOf('.')) ?? null;
    let img = i?.substring(0, i.lastIndexOf('.')) ?? null;
    //l.slice(0, l.lastIndexOf('.'))
    if(hasImg && img != null) {
        return `${img}-${size}${ext}`;
    } else {
        return '';
    }
    */

    if(hasImg) {
        return resizeImageUrl(i, size);
    } else {
        return '';
    }
}

/**
 * Sorts the properties of an object alphabetically
 * @param {object} obj
 * @returns {object}
 * @example
 * // returns: { apple: 'pie', foo: 'bar', hello: 'world' }
 * let obj = { hello: 'world', apple: 'pie', foo: 'bar' }
 * sortObjectProps(obj);
 */
export function sortObjectProps(obj) {
    // Source: https://bobbyhadz.com/blog/javascript-sort-object-keys
    let sorted = Object.keys(obj).sort().reduce((accumulator, key) => {
        accumulator[key] = obj[key];
        return accumulator;
    }, {});
    return sorted;
}

export function seoUrl(text = '', asYouType = false) {
    if(!text) return '';
    if(asYouType) {
        // If asYouType is true, do not strip trailing and leading dashes
        return text
            .toString() // Convert to string
            .normalize('NFD') // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-') // Change whitespace to dashes
            .toLowerCase() // Change to lowercase
            .replace(/&/g, '-and-') // Replace ampersand
            .replace(/[^a-z0-9-]/g, '') // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-'); // Remove duplicate dashes
    } else {
        return text
            .toString() // Convert to string
            .normalize('NFD') // Change diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
            .replace(/\s+/g, '-') // Change whitespace to dashes
            .toLowerCase() // Change to lowercase
            .replace(/&/g, '-and-') // Replace ampersand
            .replace(/[^a-z0-9-]/g, '') // Remove anything that is not a letter, number or dash
            .replace(/-+/g, '-') // Remove duplicate dashes
            .replace(/^-*/, '') // Remove starting dashes
            .replace(/-*$/, ''); // Remove trailing dashes
    }

    // Original:
    //return text
    //    .toString() // Convert to string
    //    .normalize('NFD') // Change diacritics
    //    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    //    .replace(/\s+/g, '-') // Change whitespace to dashes
    //    .toLowerCase() // Change to lowercase
    //    .replace(/&/g, '-and-') // Replace ampersand
    //    .replace(/[^a-z0-9-]/g, '') // Remove anything that is not a letter, number or dash
    //    .replace(/-+/g, '-') // Remove duplicate dashes
    //    .replace(/^-*/, '') // Remove starting dashes
    //    .replace(/-*$/, ''); // Remove trailing dashes
}

/**
 * Returns the active headline for a post
 * @returns {string}
 */
export function getActiveHeadline(post) {
    let props = post.extendedProperties;

    if(props && props.headlines && props.headlines.length) {
        for(let idx = 0; idx < props.headlines.length; idx++) {
            if(props.headlines[idx].active) return props.headlines[idx].headline.trim();
        }
    }
    // Default
    return post.title.trim();
}

/**
 * Returns the merged app settings and site settings
 * @returns {string}
 */
export function getAppSettings() {
    let appSettings = require('/appsettings.json');
    const siteSettings = require(`/appsettings.${process.env.VUE_APP_PROFILE}.json`);

    try {
        appSettings = { ...appSettings, ...siteSettings };
    } catch (error) {
        return appSettings;
    }
    return appSettings;
}

/**
 * Fetches the user's personalized settings from local storage, if present
 * @returns {object}
 */
export function getUserSettings() {
    let appSettings = getAppSettings();
    var settings = JSON.parse(window.localStorage.getItem(`${appSettings.slug}-user-config`)) || {};
    return settings;
}

/**
 * Sabes the user's personalized settings to local storage
 * @returns {object}
 */
export function saveUserSettings(settings = {}) {
    let appSettings = getAppSettings();
    localStorage.setItem(`${appSettings.slug}-user-config`, JSON.stringify(settings));
    return settings;
}

/**
 * Get the full URL of a path for the current site
 * @returns {string}
 */
export function getPermalink(path) {
    let appSettings = getAppSettings();
    return appSettings.webroot[process.env.NODE_ENV] + path;
}

/**
 * Get the edit link for a given post
 * @returns {Object} Post
 */
export function getEditLink(post) {
    if(!post || post.isExternal) return null;
    let appSettings = getAppSettings();
    let categoryId = post.postFields?.categories?.length > 0 ? post.postFields?.categories[0] : null;

    switch(post.postType?.toLowerCase()) {
        case 'post':
            if(appSettings.siteId == 1 && categoryId) {
                return `/post/${categoryId}/${post.postId}`;
            } else {
                return `/posts/${post.postId}`;
            }
        case 'video':
        default:
            return null;
    }
}

/**
 * Check if an object is iterable
 * @returns {Boolean}
 */
export function isIterable(obj) {
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

export default {
    getActiveHeadline,
    getAppSettings,
    getEditLink,
    getImage,
    getPermalink,
    getUserSettings,
    hasImg,
    isIterable,
    saveUserSettings,
    seoUrl,
    sortObjectProps
};
