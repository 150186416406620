import BaseService from './BaseService';

export default class PodcastService extends BaseService {
    async getShows(params) {
        return await this.apiClient.get('/api/podcasts', { params: params }).then((res) => res.data);
    }

    async getShow(id) {
        return await this.apiClient.get(`/api/podcasts/${id}`).then((res) => res.data);
    }

    async saveShow(show) {
        return await this.apiClient.post('/api/podcasts', show).then((res) => res.data);
    }

    async deleteShow(id) {
        return await this.apiClient.delete(`/api/podcasts/${id}`).then((res) => res.data);
    }

    async saveShowLayout(siteId, shows) {
        return await this.apiClient.post(`/api/podcasts/${siteId}/sortorder`, shows).then((res) => res.data);
    }

    async getEpisodes(showId) {
        return await this.apiClient.get(`/api/podcasts/${showId}/episodes`).then((res) => res.data);
    }

    async getEpisodesPaged(params) {
        return await this.apiClient.post(`/api/podcasts/episodes`, params).then((res) => res.data);
    }

    async getEpisode(episodeId) {
        return await this.apiClient.get(`/api/podcasts/episode/${episodeId}`).then((res) => res.data);
    }

    async saveEpisode(episode) {
        return await this.apiClient.post('/api/podcasts/episode', episode).then((res) => res.data);
    }

    async deleteEpisode(episodeId) {
        return await this.apiClient.delete(`/api/podcasts/episode/${episodeId}`).then((res) => res.data);
    }

    async uploadPodcast(podcastId, file) {
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/mediaobject/podcasts/${podcastId}/upload`;
        var formData = new FormData();
        formData.append(file.name, file);
        return await this.apiClient.post(endpoint, formData).then((res) => res.data);
    }

    async deletePodcast(url) {
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/mediaobject/podcasts/delete?f=${url}`;
        return await this.apiClient.get(endpoint).then((res) => res.data);
    }
}
