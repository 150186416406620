<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">ThmLogViewer Demo</div>

                    <div class="card-body">
                        <h5>Button Style, by Reference ID</h5>

                        <div class="mb-3">
                            <label for="ReferenceId" class="form-label required">Reference ID:</label>
                            <InputText type="number" class="form-control" v-model="referenceId" id="ReferenceId" />
                            <p class="help">Enter a Reference ID from the <span class="font-monospace">[Cms].[Logs]</span> table.</p>
                        </div>

                        <p>
                            <LogViewerThm :filter="{ logTypeId: 1, referenceId: referenceId }" tag="button" class="p-button-secondary p-button-outlined" />
                        </p>

                        <hr />
                        <h5>Link Style, by User Name</h5>

                        <div class="mb-3">
                            <label for="FullName" class="form-label required">User Name:</label>
                            <InputText type="text" class="form-control" v-model="userName" id="FullName" />
                            <p class="help">Enter a UserName from the <span class="font-monospace">[Cms].[Logs]</span> table.</p>
                        </div>

                        <p>Click here: <LogViewerThm :filter="{ userName: userName, pageSize: 15 }" /></p>

                        <hr style="border-top: 7px solid #000" />
                        <h5>Add Log Entry</h5>

                        <div class="mb-3">
                            <label for="LogTypeId" class="form-label">Log Type ID</label>
                            <InputText type="number" class="form-control" v-model="newLogEntry.logTypeId" id="LogTypeId" :disabled="true" />
                        </div>
                        <div class="mb-3">
                            <label for="ReferenceId" class="form-label">Reference ID</label>
                            <InputText type="number" class="form-control" v-model="newLogEntry.referenceId" id="ReferenceId" />
                        </div>
                        <div class="mb-3">
                            <label for="Message" class="form-label">Message</label>
                            <InputText class="form-control" v-model="newLogEntry.message" id="Message" />
                        </div>
                        <div class="mb-3">
                            <label for="Content" class="form-label">Content</label>
                            <Textarea v-model="newLogEntry.content" rows="3" id="Content" class="d-block w-100" />
                        </div>
                        <div class="mb-3">
                            <label for="Original" class="form-label">Original</label>
                            <Textarea v-model="newLogEntry.original" rows="3" id="Original" class="d-block w-100" />
                        </div>

                        <Button label="Save" class="btn-save" @click="save" />
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import AdminService from '@/service/AdminService';
import { useToast } from 'primevue/usetoast';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'ThmLogViewerDemo',
    components: {
        LogViewerThm,
    },
    setup() {
        const adminService = new AdminService();
        const toast = useToast();
        const referenceId = ref(2604715);
        const { user } = useAuth0();
        const userName = ref(user.value.name);
        const newLogEntry = ref({
            logTypeId: 1,
            referenceId: 1234,
            message: 'Test log entry',
            content: '{\n\t"message": "Hello world"\n}',
            original: '{\n\t"message": "Hello darkness"\n}',
        });

        function save() {
            // If you just want to log an item, no need for then/catch (which is just here for demo). You could just use: adminService.log({ ... });
            adminService
                .log(newLogEntry.value)
                .then(() => {
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Log Entry Added', life: 3000 });
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving Log Entry', detail: err.message || err, life: 3000 });
                });
        }

        return { newLogEntry, referenceId, save, userName };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
    input[type='text'] {
        max-width: 350px;
    }
    input[type='number'] {
        max-width: 150px;
    }
}
</style>
