<template>
    <h3>BlockCopy</h3>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
                <div class="card card-border-color card-border-color-primary shows">
                    <div>
                        <button class="btn-primary" type="button" @click="consolelog()">log</button>
                    </div>
                    <div class="card-header card-header-divider">Search</div>

                    <div class="card-body">
                        <div class="card-header-divider" id="show_filter">
                            <!-- <Button label="" icon="far fa-times" iconPos="right" class="p-button-outlined p-button-secondary p-button-border-none float-end" title="Clear Filter" @click="clearFilter" />
                                <Dropdown id="SiteFilter" v-model="searchParams.siteId" :options="sites" optionLabel="description" optionValue="siteId" class="d-flex" @change="filterSite" />-->
                            <InputText id="searchBox" class="mx-auto search-box" type="text" @keyup.enter="callSearch()" v-model="searchParams.search" />
                            <button class="p-button p-component p-button-icon-only me-2 p-button-sm search-button" type="button" @click="callSearch()"><span class="pi pi-search p-button-icon"></span></button>
                        </div>
                    </div>
                    <!-- <div class="card-header card-header-divider">
                            Search
                        </div> -->

                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Description</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">Published</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="b in blockcopies" :key="b.blockCopyId">
                                    <tr>
                                        <td>
                                            <button class="btn btn-primary" type="button" @click="editBlockCopy(b)">Edit</button>
                                        </td>
                                        <td>
                                            {{ b.title }}
                                        </td>
                                        <td>
                                            {{ b.blockCopyId }}
                                        </td>
                                        <td>
                                            <div id="published" v-if="b.isPublished == true" class="bg-success publish-status"></div>
                                            <div id="unpublished" v-else class="bg-danger publish-status"></div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <Paginator :rows="10" :totalRecords="totalRecordCount" @page="onPage($event)"></Paginator>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="card card-border-color card-border-color-primary shows">
                    <div v-if="blockcopy.blockCopyId == 0" class="card-header card-header-divider">Create New Block Copy</div>
                    <div v-else class="card-header card-header-divider">Edit Block Copy-{{ blockcopy.blockCopyId }}</div>

                    <div class="card-body">
                        <div class="mb-3">
                            <label for="BlockCopySite" class="form-label">Site</label><br />

                            <SiteGroupSelectServer v-model="blockcopy.siteGroups" />
                            <SiteGroupSelectClient v-model="blockcopy.siteGroup" />
                        </div>

                        <div class="mb-3">
                            <label for="Description" class="form-label">Description</label><br />
                            <Textarea v-model="blockcopy.title" :autoResize="true" rows="1" class="w-100" />
                        </div>

                        <div class="mb-3">
                            <label for="Body" class="form-label">Body</label><br />
                            <Textarea v-model="blockcopy.body" :autoResize="true" rows="5" class="w-100" />
                        </div>

                        <div class="mb-3">
                            <label for="time24">Published Date </label><br />
                            <Calendar id="time24" v-model="blockcopy.modifiedDate" :show-time="true" :show-seconds="false" hour-format="12" />
                        </div>

                        <div class="mb-3">
                            <InputSwitch v-model="blockcopy.isPublished" class="me-2" id="isPublished" />
                            <label for="isPublished" class="switch-label">Published&nbsp;&nbsp;</label>
                        </div>

                        <Button class="me-2" label="Save" @click="saveBlockCopy()" />
                        <Button class="me-2" label="Delete" @click="deleteBlockCopy()" />
                        <Button class="me-2" label="Clear" @click="resetBlockCopy()" />
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import AdminService from '@/service/AdminService';
import BlockCopyService from '@/service/BlockCopyService';
import SiteGroupSelectServer from '@/components/SiteGroupSelectServer.vue';
import SiteGroupSelectClient from '@/components/SiteGroupSelectClient.vue';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
// import BlockCopyEditComponent from "../BlockCopy/BlockCopyEditComponent.vue";
// import BlockCopyListComponent from "../BlockCopy/BlockCopyListComponent.vue";
export default {
    components: {
        SiteGroupSelectServer,
        SiteGroupSelectClient,
    },
    name: 'BlockCopy',
    created() {
        this.blockcopyService = new BlockCopyService(this.$auth.getAccessToken());
    },
    data() {
        return {
            adminService: new AdminService(this.$auth.getAccessToken()),
            selectedBlockCopy: null,
            selectedSite: null,
            filteredBlockCopies: null,
            totalRecordCount: 0,
            blockcopies: [],
            sites: [],
            selectedSightGroups: [],

            blockcopy: {
                siteGroup: 0,
                siteGroups: this.selectedSiteGroup,

                blockCopyId: 0,
                body: null,
                createdDate: null,
                isPublished: false,
                modifiedDate: null,
                publishedDate: null,
                publishedDateDate: null,
                publishedDateTime: null,
                siteId: 1,
                title: null,
            },
            searchParams: {
                PageIndex: 1,
                PageSize: 10,
                search: null,
            },
        };
    },
    methods: {
        saveBlockCopy() {
            if (this.blockcopy.publishedDate == null) {
                this.blockcopy.publishedDate = this.blockcopy.modifiedDate;
            }
            if (this.blockcopy.publishedDateDate == null) {
                this.blockcopy.publishedDateDate = this.blockcopy.modifiedDate;
            }
            if (this.blockcopy.publishedDateTime == null) {
                this.blockcopy.publishedDateTime = this.blockcopy.modifiedDate;
            }
            //this.blockcopy.siteGroup = 0;

            this.blockcopyService
                .saveBlockCopy(this.blockcopy)
                .then((response) => {
                    console.log(response);
                    this.getBlockCopies();
                    this.resetBlockCopy();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        deleteBlockCopy() {
            var deleteBc = this.blockcopy.blockCopyId;

            this.blockcopyService
                .deleteBlockCopy(deleteBc)
                .then((response) => {
                    console.log(response);
                    var deletedBlockCopy = this.blockcopies.find((x) => x.blockCopyId === deleteBc);
                    if (deletedBlockCopy != null) {
                        this.blockcopies.splice(this.blockcopies.indexOf(deletedBlockCopy), 1);
                    }
                    this.resetBlockCopy();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getBlockCopies() {
            this.blockcopyService
                .getBlockCopies(this.searchParams)
                .then((response) => {
                    console.log(response);
                    this.blockcopies = response.blockCopies;

                    this.totalRecordCount = response.count;
                    // const {res} = response;
                    console.log(response.count);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        editBlockCopy(b) {
            this.blockcopy = b;
        },
        resetBlockCopy() {
            Object.keys(this.blockcopy).forEach((i) => (this.blockcopy[i] = null));
            this.blockcopy.siteId = 1;
            this.blockcopy.blockCopyId = 0;
            this.blockcopy.isPublished = false;
            this.blockcopy.siteGroup = 0;
            //this.blockcopy.siteGroups= [{siteGroup: 0, description: "string"}];
            this.getBlockCopies();
        },
        getSites() {
            this.adminService
                .getSites()
                .then((response) => {
                    this.sites = response;
                    // const { res } = response;
                    console.log(this.sites);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onPage(event) {
            this.searchParams.PageIndex = event.page + 1;
            this.searchParams.PageSize = event.rows;
            this.callSearch();
        },
        callSearch() {
            this.blockcopyService
                .getBlockCopies(this.searchParams)
                .then((response) => {
                    this.blockcopies = response.blockCopies;
                    this.totalRecordCount = response.count;
                    const { res } = response;
                    console.log(res);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getFormattedDateTime(dateString = null, format = 'MM/dd/yyyy hh:mm a') {
            return formatDateTime(dateString, format);
        },
        formatDate(d) {
            return formatDateTime(d, 'MMM  Do yyyy, h:mm:ss a');
        },
        consolelog() {
            console.log(this.blockcopy);
        },
    },

    mounted() {
        /* change this */
        this.getBlockCopies();
        this.getSites();
    },
};
</script>
<style scoped>
.publish-status {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.search-button {
    float: right;
    padding: 0.75rem;
}
.search-box {
    width: 80%;
}
</style>
