<template>
    <div class="row mb-3">
        <div class="col-sm-12 col-md-12">
            <form class="needs-validation" novalidate>
                <div class="mb-3">
                    <div class="hstack gap-2 shadow-sm bg-white p-2">
                        <div class="me-2">
                            <Calendar v-model="filter.fromDate" :showButtonBar="true" :showIcon="true" placeholder="Date" dateFormat="mm/dd/yy" hourFormat="12" class="me-2" style="max-width: 155px" @date-select="dateCheck()" />to
                            <Calendar v-model="filter.toDate" :showButtonBar="true" :showIcon="true" placeholder="Date" dateFormat="mm/dd/yy" hourFormat="12" class="me-2" style="max-width: 155px" @date-select="dateCheck()" />&nbsp;&nbsp;
                            <Dropdown v-model="filter.timePeriod" optionLabel="label" :options="timePeriod" optionValue="value" placeholder="Time Period" :showClear="true" class="me-2" @change="periodCheck()" />
                            <AuthorsAutoComplete v-model="autoCompleteAuthor" :authorId="filter.authorId" class="me-2 mb-2" placeholder="Author" :authorTypeId="1" />
                            Ad Density <InputText type="text" v-model="filter.adDensity" class="p-inputtext me-2 mb-2" placeholder="7" style="max-width: 45px" />Characters
                            <InputText type="text" v-model="filter.nextAd" class="p-inputtext me-2" placeholder="7" style="max-width: 75px" />
                            <Button label title="Search" icon="far fa-search" class="ms-2 mb-2 p-button" @click="getPostsData()" />
                            <Button label title="Reset search..." icon="far fa-times" class="p-button ms-2 mb-2 p-button-secondary" @click="clearFilter()" />
                            <Button label title="Download report..." icon="fa fa-download" class="p-button ms-2 mb-2 p-button-secondary" @click="exportCSV()" />
                        </div>
                    </div>
                </div>
            </form>
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">
                    Post Statistics Reports
                    <SpinnerThm class="spinner" size="1em" v-if="loading" />
                </div>

                <div class="card-body">
                    <DataTable :value="posts" ref="dt" stripedRows responsiveLayout="scroll">
                        <Column field="datePublished" header="Published Date" exportHeader="Published Date">
                            <template #body="slotProps">
                                <date-format :datetime="slotProps.data.datePublished" format="MM/dd/yyyy" /><br />
                                <date-format :datetime="slotProps.data.datePublished" format="hh:mm a" />
                                <DotThm class="ms-1 bg-warning" v-show="isDateInFuture(slotProps.data.datePublished)" v-tooltip="'Scheduled'" />
                            </template>
                        </Column>
                        <Column field="url" header="Article" exportHeader="Article" :exportable="true">
                            <template #body="slotProps">
                                <!--<a :href="getPermalink(slotProps.data.url)" class="permalink" target="_blank">{{ slotProps.data.title }}</a>-->
                                <router-link :to="{ name: 'Posts', params: { id: slotProps.data.postId } }">{{ slotProps.data.title }}</router-link>
                                <img :src="getVipBadge(slotProps.data.subscription)" v-if="slotProps.data.subscription > 1" class="vip-badge ps-1" />
                                <br />
                                {{ getCategoryLabel(slotProps.data.categories) }} | {{ slotProps.data.author.name || slotProps.data.author.nickName || 'No Author' }}
                                <a :href="getPermalink(slotProps.data.url)" class="pi pi-link article-edit-link text-muted align-text-bottom" target="_blank" v-show="!isDateInFuture(slotProps.data.datePublished)"></a>
                            </template>
                        </Column>
                        <Column field="title" header="Title" :hidden="true"></Column>
                        <Column field="author.name" header="Author" :hidden="true"></Column>
                        <Column field="extendedProperties.postStats.adDensity" class="text-center" header="Ad Density"></Column>
                        <Column field="extendedProperties.postStats.characters" header="Characters"></Column>
                        <Column field="extendedProperties.postStats.nextAd" header="Next Ad (Characters)"></Column>
                        <Column field="extendedProperties.postStats.words" header="Words"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AuthorsAutoComplete from '@/components/AuthorsAutoComplete.vue';
import DateFormat from '@/components/DateFormat.vue';
import DotThm from '@/components/DotThm.vue';
import ReportService from '@/service/ReportService';
import SpinnerThm from '@/components/SpinnerThm.vue';

import { ref, inject, onBeforeMount, watch } from 'vue';
import { usePostStore } from '@/stores/PostStore';
import { isDateInFuture } from '@/utils/ThmDateTimeUtils';
import { getVipBadge } from '@/utils/ThmImageUtils';
import { useToast } from 'primevue/usetoast';

export default {
    name: 'PostStatsReport',
    components: {
        AuthorsAutoComplete,
        DateFormat,
        DotThm,
        SpinnerThm,
    },
    setup() {

        const appSettings = inject('appSettings');
        const postStore = usePostStore();
        const autoCompleteAuthor = ref();

        const filter = ref({
            fromDate: null,
            toDate: null,
            timePeriod: 2,
            nextAd: 150,
            adDensity: 7,
            authorId: null,
        });
        const timePeriod = [
            { label: 'Today', value: '1' },
            { label: 'Last 7 Days', value: '2' },
            { label: 'Month to Date', value: '3' },
            { label: 'Year to Date', value: '4' },
        ];

        const dt = ref();
        const toast = useToast();
        const posts = ref([]);
        const loading = ref(false);

        const exportCSV = () => {
            dt.value.exportCSV();
        };

        //sevice setup
        const reportService = new ReportService();

        //lifecycle hooks
        onBeforeMount(async () => {
            await getPostsData();
            postStore.load();
        });
        watch(autoCompleteAuthor, (newVal) => {
                console.log("Watching ! author")
                filter.value.authorId = newVal?.value ?? null;
            }
        );

        //Methods
        function periodCheck() {
            console.log(filter.value.timePeriod);
            if (filter.value.timePeriod != null) {
                filter.value.toDate = null;
                filter.value.fromDate = null;
            }
        }

        function dateCheck() {
            if ((filter.value.fromDate != null) & (filter.value.toDate != null)) {
                filter.value.timePeriod = null;
            }
        }

        async function clearFilter() {
            filter.value.fromDate = null;
            filter.value.toDate = null;
            filter.value.timePeriod = null;
            filter.value.nextAd = 150;
            filter.value.adDensity = 7;
            filter.value.authorId = null;
            autoCompleteAuthor.value = null;
            await getPostsData();

        }
        async function getPostsData() {
            loading.value = true;
            await reportService
                .getPostStats(filter.value)
                .then((res) => {
                    posts.value = res;
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Stats', detail: err.message || err, life: 4000 });
                })
                .finally(() => {
                    loading.value = false;
                });
        }

        function getCategoryLabel(categories) {
            if(!categories || !categories.length) return 'No Category';
            let categoryNames = [];
            for(let idx = 0; idx < categories.length; idx++) {
                var category = postStore.categoryList.find((obj) => obj.categoryId == categories[idx]);
                if(category) categoryNames.push(category?.name);
            }
            return categoryNames.join(', ');
        }

        function getPermalink(path) {
            return appSettings.webroot[process.env.NODE_ENV] + path;
        }

        return {
            autoCompleteAuthor,
            clearFilter,
            dateCheck,
            dt,
            exportCSV,
            filter,
            getCategoryLabel,
            getPermalink,
            getPostsData,
            getVipBadge,
            isDateInFuture,
            loading,
            periodCheck,
            posts,
            timePeriod,
        };
    },
};
</script>

<style lang="scss" scoped></style>
