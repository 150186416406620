<template>
    <div class="col-sm-12 col-md-8">
        <div class="card card-border-color card-border-color-primary shows">
            <div class="card-header card-header-divider">Edit Form</div>

            <div class="card-body">
                <div class="mb-3">
                    <label for="Title" class="form-label">Title</label>
                    <InputText type="text" class="form-control" v-model="selectedItem.title" id="Title" />
                    <!-- <InputText type="text" class="form-control" id="Title" :value="item.title" @input="updateValue('title', $event.target.value)" /> -->
                </div>

                <div class="mb-3">
                    <label for="Description" class="form-label">Description</label>
                    <Textarea type="text" class="form-control" v-model="selectedItem.description" id="Description" rows="2" />
                    <!-- <Textarea type="text" class="form-control" id="Description" :value="item.description" rows="2" @input="updateValue('description', $event.target.value)" /> -->
                </div>

                <div class="mb-3">
                    <label for="Link" class="form-label">Link</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="form-control" v-model="selectedItem.link" id="Link" placeholder="https://" />
                    </IconField>
                </div>

                <Button label="Save Item" @click="saveItem()" :disabled="!Object.keys(item).length" />
                <div class="help">See browser console for output when clicked.</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormDemo',
    data() {
        return {
            selectedItem: {},
        };
    },
    props: {
        item: Object,
    },
    methods: {
        saveItem() {
            console.log('[save]', this.selectedItem);
        },
        /*
        // This is an experiment for updated the commented out input fields with bound :value
        updateValue(key, value) {
            // Allows v-model binding of object properties: https://www.drewtown.dev/post/using-vues-v-model-with-objects/#template
            this.selectedItem = { ...this.item, [key]: value };
        }
        */
    },
    updated() {
        this.selectedItem = this.item;
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    div.help {
        font-style: italic;
        color: #979797;
        font-size: 90%;
    }
}
</style>
