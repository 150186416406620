import { defineStore } from 'pinia';
import NewsletterService from '@/service/NewsletterService';
import AdminService from '@/service/AdminService';
import { sortObjectProps } from '@/utils/ThmUtils';
import { deviceIsOS } from '@/utils/ThmDeviceUtils';
import { uuid } from 'vue-uuid';

const newsletterService = new NewsletterService();
const adminService = new AdminService();

export const useAfternoonStore = defineStore('Afternoon', {
    state: () => ({
        loading: false,
        isPublished:false,
        customNl:null,
        execpectedCount:0,
        posts: [],
        changedColumn: {
            'live': false
        },
        changedPosts: [],
        post: {},
        newPost: {
            uuid: null,
            sectionId: 1,
            ordinalId: 1,
            title: "",
            teaser: "",
            mainImage: "",
            url: null,
            subscriptionLevel: 1,
            byLine:"",
            datePublished:""
        },
        nlContentPost :{
            "sectionId": 1,
            "ordinalId": 1,
            "title": "",
            "teaser": "",
            "mainImage": "",
            "url": "",
            "subscriptionLevel": 0,
            "byLine":"",
            "datePublished":""
        }
    }),
    actions: {
        async load() {

            // Live posts
            //if(!this.posts.live.length) {

                this.loading = true;
                await newsletterService.getCustomNl(4).then((res) => {
                    this.customNl =res;
                    this.isPublished = this.customNl.isPublished;
                    this.posts =  this.customNl.nlContent;
                    this.execpectedCount = this.customNl.configuration.section1.limit + this.customNl.configuration.section2.limit + this.customNl.configuration.section3.limit;
                    this.posts = this.posts.map(obj => ({ ...obj, uuid: uuid.v4() })); // Add UUID to each post
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            //}
        },
        async togglePublish() {

            var parms = { "id":this.customNl.newsletterId, "isPublished": ! this.customNl.isPublished}

            await newsletterService.publishCustomNl(parms).then((res) => {
                if(res!=true) {
                    throw "Error Saving";
                }
                this.customNl.isPublished = !this.customNl.isPublished;

            }).catch((error) => {
                throw error;
            }).finally(() => {

            });

        },
        async save() {
           // if(this.customNl.nlContent.length >= 1) {
                return await newsletterService.getCustomNl(this.customNl.newsletterId).then((original) => { // Fetch original
                    return newsletterService.updateCustomNl(this.customNl).then(() => {
                        adminService.log({ logTypeId: 15, referenceId: this.customNl.newsletterId, message: 'Modified', content: JSON.stringify(this.sortArrayObjectProps(this.customNl.nlContent), null, 2), original: JSON.stringify(this.sortArrayObjectProps(original.nlContent), null, 2), language: 'json' });
                    }).catch((error) => {
                        throw error;
                    });
                });
            //}
        },
        setPost(post) {
            if(!deviceIsOS('macOS')) console.log('setPost(post)', post);
            this.post = post;
        },
        arrayMove(arr, fromIndex, toIndex) {
            // https://www.codegrepper.com/code-examples/javascript/change+position+of+element+in+array+javascript
            var newArr = [...arr]; // Clone
            var element = newArr[fromIndex];
            newArr.splice(fromIndex, 1);
            newArr.splice(toIndex, 0, element);
            return newArr;
        },
        renumber() {
            for(let idx = 1; idx <= this.posts.length; idx++) {
                this.posts[idx-1].ordinalId = idx;
            }
        },
        movePosition(post, dir) {
            // Get current index of object in array
            var idx = this.posts.findIndex(item => item.uuid === post.uuid);

            var newPosition = 0;
            if(!isNaN(dir)) {
                newPosition = dir-1;
            } else {
                // Move the item up or down
                newPosition = dir == 'up' ? idx-- : idx++;
            }

            if((dir == 'up' && idx < 0) || (dir = 'down' && idx >= this.posts.length)) return;
            this.posts = this.arrayMove(this.posts, idx, newPosition);
            this.changedColumn.live = true;
            this.renumber();

        },
        // move(post, sourceColumn, targetColumn) {
        //     // Remove from source column
        //     this.posts = this.posts.filter(function( obj ) {
        //         return obj.uuid !== post.uuid;
        //     });
        //     this.posts.unshift(post);

        //     this.renumber();


        // },
        remove(post) {
            this.posts = this.posts.filter(item => item.uuid !== post.uuid);
            this.changedColumn.live = true;
            this.renumber();

        },
        insert(postFields) {
            let post = { ...this.newPost};

            if(postFields.imageObject.length >0) {
                post.mainImage= postFields.imageObject[0].url;
            }

            post.title = postFields.title;
            post.subscriptionLevel =  postFields.subscription;
            post.url = postFields.url;
            post.byLine = postFields.author.name;
            post.datePublished = postFields.datePublished;
            post.uuid = uuid.v4();
            this.changedColumn.live = true;

            this.posts.unshift(post);
            this.renumber();

        },
        /**
         * Added this method to sort the object properties in an array of objects because the input
         * we're getting from the form has the properties in a different order than the original
         * array of objects from the database, which makes the logging messy.
         */
        sortArrayObjectProps(arr) {
            for(let idx=0; idx < arr.length; idx++) {
                arr[idx] = sortObjectProps(arr[idx]);
            }
            return arr;
        }
    }
});
