<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Get {{ route.params.type }}</div>

                <div class="card-body">
                    <DataTable :value="data" tableStyle="min-width: 50rem">
                        <Column v-for="col of columns" :key="col" :field="col" :header="col"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AdminService from '@/service/AdminService';

export default {
    name: 'GetData',
    setup() {
        const route = useRoute();
        const adminService = new AdminService();
        const data = ref(null);
        const columns = ref([]);

        onMounted(async () => {
            switch(route.params.type) {
                case 'categories':
                    adminService.getCategories().then((res) => {
                        res = res.sort((a, b) => (a.categoryId > b.categoryId) ? 1 : -1); // Sort by CategoryId
                        data.value = res;
                        if(res.length) columns.value = Object.getOwnPropertyNames(res[0]);
                    });
                    break;
            }
        });

        return {
            columns,
            data,
            route
        }
    }
}
</script>
