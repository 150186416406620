import 'bootstrap/dist/css/bootstrap.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '/node_modules/@fortawesome/fontawesome-pro/css/fontawesome.css';
import '/node_modules/@fortawesome/fontawesome-pro/css/brands.css';
import '/node_modules/@fortawesome/fontawesome-pro/css/regular.css';

// Froala
/*
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins/word_paste.min';
*/

// Experiment for appsettings.json
//import AppSettings from '/appsettings.json';

//https://pinia.vuejs.org/
import { createPinia } from 'pinia';

//PRIMEVUE Components
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
//import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
//import Card from 'primevue/card';
//import Carousel from 'primevue/carousel';
//import Skeleton from 'primevue/skeleton';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
//import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
//import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
// import DataView from 'primevue/dataview';
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
//import Fieldset from 'primevue/fieldset';
import Avatar from 'primevue/avatar';
//import InlineMessage from 'primevue/inlinemessage';
//import Inplace from 'primevue/inplace';
//import InputMask from 'primevue/inputmask';
//import InputNumber from 'primevue/inputnumber';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Image from 'primevue/image';
//import Listbox from 'primevue/listbox';
//import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';

//import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';

import PickList from 'primevue/picklist';
//import ProgressBar from 'primevue/progressbar';
//import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
//import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
//import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
// import Tree from 'primevue/tree';
// import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
//end components

import VueDiff from 'vue-diff';
import 'vue-diff/dist/index.css';

//base setup
import { createApp, markRaw } from 'vue';
import App from './App.vue';
import VueFroala from 'vue-froala-wysiwyg';
import PrimeVue from 'primevue/config';
import router from './router';

const app = createApp(App);

// Load appsettings.json
let appSettings = require('/appsettings.json');
//https://v3.cli.vuejs.org/guide/mode-and-env.html#modes

// Merge site-specific appsettings
try {
    let siteSettings = require(`/appsettings.${process.env.VUE_APP_PROFILE}.json`);
    appSettings = { ...appSettings, ...siteSettings };
} catch (error) {
    if (console.log.hide) console.log('Site appsettings.json not found');
}
// Merge environment appsettings
/*
try {
    let envSettings = require(`/appsettings.${process.env.VUE_APP_PROFILE}.${process.env.NODE_ENV}.json`);
    appSettings = { ...appSettings, ...envSettings };
} catch (error) {
    if(console.log.hide) console.log('Environment appsettings.json not found');
}
*/

app.provide('appSettings', appSettings); // https://stackoverflow.com/a/63101214/3799374
app.config.globalProperties.$appSettings = appSettings;

app.use(PrimeVue, {
    ripple: true,
});

app.use(router);
//thm guard
/*
import { OktaAuth } from '@okta/okta-auth-js';
import ThmOktaAuth from '@/mixins/ThmOktaAuth';

const oktaAuth = new OktaAuth({
    issuer: appSettings.okta.issuer,
    clientId: appSettings.okta.clientId,
    redirectUri: window.location.origin + '/login/callback',
    //postLogoutRedirectUri: window.location.origin + '/loggedout',
    scopes: ['openid', 'profile', 'email'],
    // restoreOriginalUri: async () => {
    //   // redirect with custom router
    //   router.replace({
    //     path: '/',
    //   });
    // }
});
//import {guard} from "thmauth";
app.use(ThmOktaAuth, {
    oktaAuth,
});
*/

import { createAuth0 } from '@auth0/auth0-vue';
export const auth0 = createAuth0({
    domain: appSettings.auth0.domain,
    clientId: appSettings.auth0.clientId,
    authorizationParams: {
        //redirect_uri: window.location.origin + '/callback',
        redirect_uri: window.location.origin,
        audience: appSettings.auth0.audience,
        scope: 'openid profile email',
    },
    // useRefreshTokens: true,
    // useRefreshTokensFallback: true,
    cacheLocation: 'localstorage',
});
app.use(auth0);

//https://pinia.vuejs.org/getting-started.html#installation
// vuex 5 = pinia
const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
    //store.auth = markRaw(app.config.globalProperties.$auth); // Experimental - Pass $auth to Pinia
    //store.auth.user = markRaw(store.auth.authStateManager.getAuthState().idToken.claims); // Experimental - Pass user to Pinia as this.auth.user
});

//https://github.com/quangdaon/vue-signalr

// app.use(VueSignalR, {
//     automaticReconnect: true,
//     automaticUnsubscribe: true,
//     accessTokenFactory: async () => await oktaAuth.getAccessToken(),
//     prebuild(builder) {
//         builder.configureLogging(LogLevel.Debug);
//         //builder.transport= HttpTransportType.WebSockets
//         //builder.withUrl(process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub',HttpTransportType.WebSockets);
//         builder.withUrl(process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub', { transport: HttpTransportType.WebSockets, skipNegotiation: false });
//     },
//     url: process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub',
// });

/*
//import { getAuthToken } from '@/utils/ThmAuthUtils';
import { HttpTransportType, LogLevel } from '@microsoft/signalr';
import { VueSignalR } from '@quangdao/vue-signalr';
auth0.getAccessTokenSilently().then((token) => {
    app.use(VueSignalR, {
        automaticReconnect: true,
        automaticUnsubscribe: true,
        //accessTokenFactory: () => getAuthToken(),
        accessTokenFactory: () => token,
        prebuild(builder) {
            builder.configureLogging(process.env.NODE_ENV == 'production' ? LogLevel.Information : LogLevel.Debug);
            //builder.transport= HttpTransportType.WebSockets
            //builder.withUrl(process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub',HttpTransportType.WebSockets);
            builder.withUrl(process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub', { transport: HttpTransportType.WebSockets, skipNegotiation: true });
        },
        url: process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub',
    });
});
*/

import { LogLevel } from '@microsoft/signalr';
import { VueSignalR } from '@dreamonkey/vue-signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
/*
const connection = new HubConnectionBuilder()
    .withUrl(process.env.NODE_ENV === 'production' ? '/api/hub/posthub' : 'https://localhost:5001/api/hub/posthub',
    {
        accessTokenFactory: async () => await auth0.getAccessTokenSilently(),
    })
    .configureLogging(process.env.NODE_ENV == 'production' ? LogLevel.Information : LogLevel.Debug)
    //.withAutomaticReconnect([0, 2000, 10000, 30000])
    .withAutomaticReconnect()
    .build();



app.use(
    VueSignalR, {
        connection
    }
);
*/
//https://learn.microsoft.com/en-us/aspnet/core/release-notes/aspnetcore-8.0?view=aspnetcore-8.0#signalr
const connection = new HubConnectionBuilder()
    .withUrl(process.env.NODE_ENV === 'development' ? 'https://localhost:5001/api/hub/posthub' : '/api/hub/posthub', {
        accessTokenFactory: async () => await auth0.getAccessTokenSilently(),
    })
    .configureLogging(process.env.NODE_ENV == 'development' ? LogLevel.Debug : LogLevel.Information)
    //.withAutomaticReconnect([0, 2000, 10000, 30000])
    .withServerTimeout(60000)
    .withAutomaticReconnect()
    .build();

connection.serverTimeoutInMilliseconds = 300000; // 5 mins
connection.keepAliveIntervalInMilliseconds = 5000; // 5 seconds

// async function start() {
//     try {
//         await auth0.getAccessTokenSilently(); // Update token cache
//         await connection.start();
//         console.log('⚡ SignalR connected');
//     } catch (err) {
//         if(console.log.hide) console.error(err);
//         console.log('⚡ SignalR closed');
//         setTimeout(start, 5000);
//     }
// }
// connection.onclose(async () => {
//     await start();
// });

if (process.env.NODE_ENV == 'development') {
    connection.onreconnecting((error) => {
        console.log(`⚡ Connection lost due to error "${error}". Reconnecting...`);
    });
}

app.use(VueSignalR, {
    connection,
});

app.use(pinia);
//primevue services
app.use(ConfirmationService);
app.use(ToastService);
app.use(VueDiff);
app.use(VueFroala);

// Add Auth0 mixin
/*
import ThmAuth0 from '@/mixins/ThmAuth0';
app.use(ThmAuth0, {
    auth0
});
*/

app.config.productionTip = false;
//app.config.globalProperties.$appSettings = AppSettings; // Deprecated

app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

//PRIMEVUE Components
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
//app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
//app.component('Card', Card);
//app.component('Carousel', Carousel);

app.component('Checkbox', Checkbox);
app.component('Chips', Chips);
//app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
//app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
// app.component('DataView', DataView);
//app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
//app.component('Fieldset', Fieldset);
app.component('Avatar', Avatar);
//app.component('InlineMessage', InlineMessage);
//app.component('Inplace', Inplace);
//app.component('InputMask', InputMask);
//app.component('InputNumber', InputNumber);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Image', Image);
//app.component('Listbox', Listbox);
//app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);

//app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);

app.component('PickList', PickList);
//app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
//app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
//app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('SplitButton', SplitButton);
//app.component('Steps', Steps);
//app.component('Skeleton', Skeleton);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
// app.component('Tree', Tree);
// app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Badge', Badge);

//please note global properties should before mount
//app.config.globalProperties.groups = "this is a group global property";

app.mount('#app');
