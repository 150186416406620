<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">SignalR Demo</div>

                    <div class="card-body">
                        <p>Send a message to SignalR:</p>
                        <p>
                            <InputText type="text" class="form-control" v-model="message" @keyup="changed()" />
                        </p>
                        <p>
                            <Button label="Send" @click="send" />
                        </p>

                        <div v-show="sent">
                            <p>Sent! If SignalR is working, you should receive a message in console:</p>

                            <div id="sent_example" class="px-3 py-2 font-monospace">
                                ✅ Received: {{ message }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';
//import { useSignalR } from '@quangdao/vue-signalr';
//import { useSignalR } from '@dreamonkey/vue-signalr';
import useSignalR from '@/use/SignalR';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'SignalrSendReceiveDemo',
    setup() {
        //const signalr = useSignalR();
        const { user } = useAuth0();
        const message = ref('Test');
        const sent = ref(false);
        const signalr = useSignalR();

        signalr.on('ReceiveSend', (message) => console.info('\u2705 Received: ', message));

        onBeforeUnmount(() => {
            signalr.off('ReceiveSend');
        });

        function changed() {
            sent.value = false;
        }

        function send() {
            console.log('Sending:', message.value);
            signalr.invoke('Send', message.value);
            sent.value = true;
        }

        return {
            changed,
            message,
            send,
            sent,
            user
        };
    },
};
</script>

<style lang="scss" scoped>
#sent_example {
    background-color: #000;
    color: #e3e3e3;
    font-size: 0.8em;
    max-width: 500px;
}
</style>
