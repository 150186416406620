import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import LayoutService from '@/service/LayoutService';
import SettingsService from '@/service/SettingsService';
import PostService from '@/service/PostService';
import { uuid } from 'vue-uuid';
import { formatDateTime, formatDateTimeET, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { uppercaseFirst } from '@/utils/ThmStringUtils';
import { getAppSettings } from '@/utils/ThmUtils';

const postService = new PostService();
const adminService = new AdminService();
const layoutService = new LayoutService();
const settingsService = new SettingsService();

export const useLayoutStore = defineStore('Layout', {
    state: () => ({
        loading: false,
        location: {},
        template: {
            sortTipsheetByTrending: false,
            sortColumnistsByTrending: false,
            sortTipsheetByChronological: false
        },
        posts: {
            'live': [],
            'queue': []
        },
        changedColumn: {
            'live': false,
            'queue': false
        },
        changedPosts: [],
        postTypes: [],
        post: {},
        stats: [],
        newPost: {
            uuid: null,
            layoutPostId: 0,
            postId: null,
            locationId: 1,
            postFields: { datePublished: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'), imageObject: [] },
            customProperties: { image: '' },
            dateLive: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'),
            isLive: false,
            isExternal: false,
            ordinalPosition: 0
        }
    }),
    getters: {
        isLocked() {
            return this.location.postLockStatus && this.location.postLockStatus.userIdLock;
        },
        isDirty() {
            return this.changedColumn['live'] || this.changedColumn['queue'] || this.changedPosts > 0;
        }
    },
    actions: {
        async load(locationId = 1, force = false) {
            // Fetch location
            //if(!Object.keys(this.location).length) {
                this.loading = true;
                await settingsService.getLayoutLocation(locationId).then((res) => {
                    this.location = res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            //}

            if(force) {
                this.changedColumn['live'] = false;
                this.changedColumn['queue'] = false;
                this.changedPosts = [];
            }

            // Get post types
            if(force || !this.postTypes.length) {
                postService.getPostTypes().then((res) => {
                    this.postTypes = res;
                }).catch((err) => {
                    console.error('Error fetching post types', err);
                });
            }

            // Queued posts
            if(force || !this.posts.queue.length) {
                this.loading = true;
                await layoutService.getPosts({ locationId: locationId, isLive: false }).then((res) => {
                    //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Posts', res);

                    for(let idx = 0; idx < res.length; idx++) {
                        res[idx].postFields.datePublished = formatDateTime(res[idx].postFields.datePublished);
                        res[idx].dateLive = formatDateTime(res[idx].dateLive);
                    }

                    this.posts.queue = res;
                    this.posts.queue = this.posts.queue.map(obj => ({ ...obj, uuid: uuid.v4() })); // Add UUID to each post
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }

            // Live posts
            if(force || !this.posts.live.length) {
                this.loading = true;
                await layoutService.getPosts({ locationId: locationId, isLive: true }).then((res) => {
                    //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Posts', res);

                    for(let idx = 0; idx < res.length; idx++) {
                        res[idx].postFields.datePublished = formatDateTime(res[idx].postFields.datePublished);
                        res[idx].dateLive = formatDateTime(res[idx].dateLive);
                    }

                    this.posts.live = res;
                    this.posts.live = this.posts.live.map(obj => ({ ...obj, uuid: uuid.v4() })); // Add UUID to each post

                    //this.setStats(this.posts['live']);
                    //this.setStats(this.posts['queue']);
                    this.getStats('live');
                    this.getStats('queue');
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async setPostLockStatus(locationId = 1, user = null) {
            if(console.log.hide) console.log(locationId);
            let postLockStatus = { userIdLock: null, connectionId: null }
            if(user) {
                postLockStatus.userIdLock = user.name;
                postLockStatus.connectionId = user.email;
            }

            return await settingsService.setPostLockStatus(locationId, postLockStatus).then((res) => {
                this.location.postLockStatus = res;
                return res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        setPost(post) {
            //console.log('setPost(post)', post);
            this.post = post;
        },
        arrayMove(arr, fromIndex, toIndex) {
            // https://www.codegrepper.com/code-examples/javascript/change+position+of+element+in+array+javascript
            var newArr = [...arr]; // Clone
            var element = newArr[fromIndex];
            newArr.splice(fromIndex, 1);
            newArr.splice(toIndex, 0, element);
            return newArr;
        },
        renumber(status = true) { // status = "live", "queue" or true (both live and queue)
            if(status === true) {
                this.renumber('live');
                this.renumber('queue');
            } else {
                for(let idx = 1; idx <= this.posts[status].length; idx++) {
                    this.posts[status][idx-1].ordinalPosition = idx;
                    this.posts[status][idx-1].isLive = (status == 'live');
                }
            }
        },
        movePosition(post, status, dir) {
            if(console.log.hide) console.log(status);

            // Get current index of object in array
            var idx = this.posts[status].findIndex(item => item.uuid === post.uuid);

            var newPosition = 0;
            if(!isNaN(dir)) {
                newPosition = dir-1;
            } else {
                // Move the item up or down
                newPosition = dir == 'up' ? idx-- : idx++;
            }

            if((dir == 'up' && idx < 0) || (dir = 'down' && idx >= this.posts[status].length)) return;
            this.posts[status] = this.arrayMove(this.posts[status], idx, newPosition);

            // Highlight the item as changed
            //this.changedPosts.push(post.uuid); // TODO

            this.renumber(status);
            this.changedColumn[status] = true;
        },
        moveColumn(post, sourceColumn, targetColumn) {
            post.isLive = targetColumn == 'live';

            // Set datePublished when made live
            if(targetColumn == 'live') {
                //post.dateLive = formatDateTimeISO(); // now
                //post.dateLive = formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss'); // now, timezone offset stripped
                post.dateLive = formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'); // now, timezone offset stripped
            }

            // Remove from source column
            this.posts[sourceColumn] = this.posts[sourceColumn].filter(function( obj ) {
                return obj.uuid !== post.uuid;
            });
            this.posts[targetColumn].unshift(post);

            this.renumber(true);
            this.changedColumn.live = true;
            this.changedColumn.queue = true;
        },
        remove(post, status) {
            this.posts[status] = this.posts[status].filter(item => item.uuid !== post.uuid);
            //this.changedPosts.push(post.uuid); // TODO
            this.renumber(status);
            this.changedColumn[status] = true;
        },
        insert(postFields, status) {
            let post = { ...this.newPost, postFields: postFields };

            post.uuid = uuid.v4();
            post.postId = postFields.postId;
            if(status == 'live') {
                post = {
                    ...post,
                    //dateLive: formatDateTimeISO(),
                    dateLive: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'),
                    isLive: true
                }
            }

            // Set post type
            let postType = this.postTypes?.find(({ postTypeId }) => postTypeId == postFields.postTypeId);
            post.postType = postType.name;

            this.posts[status].unshift(post);
            this.renumber(status);
            this.getStats(status, true);
            this.changedColumn[status] = true;
        },
        async save(status) {
            const appSettings = getAppSettings();
            this.loading = true;

            // Abort save if duplicate post IDs found
            //if(status == 'live' && this.hasDuplicates(status)) throw( 'Duplicate post IDs found in ' + uppercaseFirst(status) + ' column. Save aborted.');
            if(status == 'live') {
                let beforeLength = this.posts[status].length;
                this.removeDuplocated(status);
                if(beforeLength != this.posts[status].length) throw( 'Duplicates detected in Live column and removed! Please verify result and click save again.');
            }

            // Truncate posts to maximum (50)
            //this.posts[status].length = this.posts[status].length > 50 ? 50 : this.posts[status].length;
            let maxItems = appSettings.features.homePageManager.maxItems || 0;
            if(maxItems > 0) this.posts[status].length = this.posts[status].length > maxItems ? maxItems : this.posts[status].length;

            // Fix date formatting and remove UUID from log diff
            let layoutPosts = [...this.posts[status]]; // Clone
            for(let idx = 0; idx < layoutPosts.length; idx++) {
                layoutPosts[idx].dateExpires = isEmptyDate(layoutPosts[idx].dateExpires) ? formatDateTime('1/1/1770 12:00:00 AM', 'yyyy-MM-dd HH:mm:ss') : formatDateTimeET(layoutPosts[idx].dateExpires, 'yyyy-MM-dd HH:mm:ss');
                layoutPosts[idx].dateLive = isEmptyDate(layoutPosts[idx].dateLive) ? formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss') : formatDateTime(layoutPosts[idx].dateLive, 'yyyy-MM-dd HH:mm:ss');
                layoutPosts[idx].postFields.datePublished = formatDateTime(layoutPosts[idx].postFields.datePublished, 'yyyy-MM-dd HH:mm:ss');
                delete layoutPosts[idx].uuid;
                delete layoutPosts[idx].postFields.dateExpires;
            }

            // Save posts
            //if(status == 'live') console.log('SAVE LIVE:', layoutPosts);
            let locationId = typeof layoutPosts[0]?.locationId != 'undefined' ? layoutPosts[0].locationId : 1; // Default locationId: 1
            return await layoutService.getPosts({ locationId: locationId, isLive: status == 'live' }).then((original) => { // Fetch original
                return layoutService.save(layoutPosts, status).then((res) => {
                    if(status == 'live') adminService.log({ logTypeId: 13, referenceId: locationId, message: 'Saved ' + uppercaseFirst(status), content: JSON.stringify(layoutPosts, null, 2), original: JSON.stringify(original, null, 2), language: 'json' });
                    this.posts[status] = this.posts[status].map(obj => ({ ...obj, uuid: uuid.v4() })); // Re-add UUIDs since they were deleted above
                    return res;
                }).catch((error) => {
                    throw(error);
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        /*
        async setStatsOld(posts) {
            return await layoutService.getPostStats2(posts).then((res) => {
                res.forEach((post) => {
                    let status = post.isLive ? 'live' : 'queue';
                    this.posts[status] = this.posts[status].map(item => item.uuid == post.uuid ? {...item, stats: post.stats} : item);
                });
            });
        },
        */
        async getStats(status, force = false) {
            // Load post stats
            if(force || !this.stats.length) {
                layoutService.getPostStats().then((res) => {
                    this.stats = res;
                    //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Stats', this.stats);
                    this.setStats(status);
                });
            } else {
                this.setStats(status);
            }
        },
        setStats(status) {
            for(let idx = 0; idx < this.posts[status].length; idx++) {
                let stats = this.stats?.find(({ postId }) => postId == this.posts[status][idx].postId);
                if(stats) {
                    this.posts[status][idx] = { ...this.posts[status][idx], stats: stats };
                }
            }
        },
        hasDuplicates(status) {
            // Filter out the external articles (post ID == 0)
            let posts = this.posts[status].filter(function( obj ) {
                return obj.postId > 0;
            });

            // Check for duplicate post IDs https://stackoverflow.com/a/30735838/3799374
            var tempArray = posts.map(function(item) { return item.postId });
            var hasDuplicate = tempArray.some(function(item, idx) {
                if(tempArray.indexOf(item) != idx) console.log('DUPE:', item);
                return tempArray.indexOf(item) != idx;
            });
            return hasDuplicate;
        },
        removeDuplocated(status) {
            // https://stackoverflow.com/a/36744732/3799374
            /*
            this.posts[status] = this.posts[status].filter((value, index, self) =>
                index === self.findIndex((t) => ( t.postId === value.postId ))
            )
            */
            let result = [], postIds = [];
            for(let idx = 0; idx < this.posts[status].length; idx++) {
                if(!postIds.includes(this.posts[status][idx].postId)) {
                    result.push(this.posts[status][idx]);
                    if(this.posts[status][idx].postId > 0) postIds.push(this.posts[status][idx].postId);
                }
            }
            this.posts[status] = result;
        }
    }
});
