<template>

    <div id="sidebar_help">
        <h4>Search Specific Fields</h4>

        <ul>
            <li>Search any field by typing the field name, then a colon (<span class="tt">:</span>), then the search term.</li>
            <li>For example, to search for a image with a title that contains "Civil Rights" and contains the phrase "racial discrimination" in the description, you might use the following query:</li>
        </ul>

        <div class="search-example mb-3">
            title:"Civil Rights" AND description:"racial discrimination"
        </div>

        <p>Available fields include: <span class="small font-monospace">title</span>, <span class="small font-monospace">description</span>, <span class="small font-monospace">license.name</span>, <span class="small font-monospace">path</span>, <span class="small font-monospace">source.user</span></p>

        <p>To search for images uploaded by a particular user:</p>

        <div class="search-example mb-3">
            source.user:"{{ userName }}"
        </div>

        <!--
        <p>To search for keywords in the credit/attribution:</p>

        <div class="search-example mb-3">
            credit:"U.S. Army"
        </div>
        -->

        <h4>Boolean Operators</h4>

        <p>Boolean operators allow terms to be combined through logic operators. Boolean operators <strong>OR</strong>, <strong>AND</strong>, <strong>+</strong>, <strong>NOT</strong>, and <strong>-</strong> are supported. <span class="help-critical">Boolean operators <strong><em>must</em></strong> be in all capital letters.</span></p>

        <h5>OR</h5>

        <p>
            The <span class="tt">OR</span> operator links two terms and finds a matching image if either of the terms exist within the image. For example,
            to search for images containing either the phrase "right to vote" or the word suffrage use:
        </p>

        <div class="search-example mb-3">
            "right to vote" OR suffrage
        </div>

        <h5>AND</h5>

        <p>
            The <span class="tt">AND</span> operator matches images in which both terms exist anywhere in the text or metadata fields of an image. For example, to search for images
            with the title field containing the phrase "Los Angeles" and the credit field containing "Associated Press" use the query:
        </p>

        <div class="search-example mb-3">
            "los angeles" AND credit:"Associated Press"
        </div>

        <h5>+</h5>

        <p>
            The <span class="tt">+</span> (or required) operator dictates that the term after the + symbol MUST exist somewhere in matched images. For example, to
            search for images that <em>may</em> contain "primary" and <em>must</em> contain "election" use the query:
        </p>

        <div class="search-example mb-3">
            primary +election
        </div>

        <h5>NOT</h5>

        <p>
            The <span class="tt">NOT</span> operator excludes images that contain the term after NOT. The symbol <span class="tt">!</span> can be used in place of the word NOT.
            For example, to search for images that contain the phrase "Capitol Hill" but <em>not</em> "Washington", use the query:
        </p>

        <div class="search-example mb-3">
            "capitol hill" NOT washington
        </div>

        <h5>-</h5>

        <p>
            The <span class="tt">-</span> (or prohibit) operator excludes images that contain the term after the <span class="tt">-</span> symbol.
            <!--NOTE: If using special characters in a search, the <span class="tt">-</span> must have a space before the special character, but not after.-->
            For example, to search for images that contain "former president" but <em>not</em> "action summit", use the query:
        </p>

        <div class="search-example mb-3">
            "former president" -"action summit"
        </div>

        <h4>Wildcard Modifiers</h4>

        <p>Single and multiple-character wildcard searches are supported:</p>
        <ul>
            <li>Use the <span class="tt">?</span> symbol to perform a single-character wildcard search</li>
            <li>Use the <span class="tt">*</span> symbol to perform a multiple-character wildcard search</li>
        </ul>

        <p>For example, to search for images that contain "text" or "test", use the search:</p>
        <div class="search-example mb-3">
            te?t
        </div>

        <p>The asterisk (<span class="tt">*</span>) is used to match partial keywords. For example:</p>
        <div class="search-example mb-3">
            elect*
        </div>
        <p>The above query would match keywords such as: <em>elect</em>, <em>elects</em>, <em>election</em>, <em>elected</em>, <em>selected</em>, <em>thermoelectric</em>, etc.</p>

        <h4>Grouping</h4>

        <p>
            Use parentheses to group clauses to form sub-queries. This can be useful in controlling Boolean logic for a search query. For example, to search
            for images that contain either "rigged" or "election fraud" AND "president":
        </p>

        <div class="search-example mb-3">
            (rigged OR "election fraud") AND president
        </div>

        <p>Field grouping is also supported:</p>

        <div class="search-example mb-3">
            title:(+King +"civil rights")
        </div>

        <h4>Fuzzy Searches</h4>

        <p>
            Use fuzzy searches, which are based on an "edit distance" algorithm, to search for terms similar in spelling to another term.
            Like proximity searches, fuzzy searches use the tilde symbol (<span class="tt">~</span>) as an operator.
        </p>

        <p>For example, to search for words like <em>elected</em> and <em>election</em>, you can use:</p>
        <div class="search-example mb-3">
            elected~
        </div>

        <!--
        <p>
            A similarity parameter can also be specified. The parameter value is between 0 and 1, and the closer the value is to 1,
            the higher the similarity will be. The default similarity parameter if not otherwise specified is 0.5.
        </p>

        <p>For example, to increase the similarity of results, specify a value greater than 0.5:</p>
        <div class="search-example mb-3">
            elected~0.8
        </div>
        -->
    </div>

    <!-- Source: https://heinonline.org/HeinDocs/AdvancedSearchSyntax.pdf -->

</template>

<script>
import { computed } from 'vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'ImageLibrarySearchHelp',
    setup() {
        const { user } = useAuth0();

        const userName = computed(() => {
            return user.value?.name ? user.value.name : 'John Doe';
        });

        return { userName };
    },
};
</script>

<style lang="scss" scoped>
#sidebar_help {
    h4 {
        border-bottom: 1px dotted var(--gray-600);
    }
    span.tt {
        background-color: #F0F0F0;
        background-color: var(--gray-200);
    }
    div.search-example {
        background-color: var(--bluegray-100);
        padding: 0.5em 1em;
    }
    .help-critical {
        color:var(--red-700);
    }
}
</style>
