<template>
    <p>Froala Sandbox</p>
    <ImageLibrary v-model="froalaImage" mode="froala" :id="'fro-im'" :config="{ showPreview: false }" />
    <FroalaOld :id="'fro-im'" :tag="'textarea'" :options="config" v-model="model" />
</template>

<script>
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';
import FroalaOld from '@/components/Froala/FroalaOld.vue';

export default {
    name: 'EditorDemo',
    data() {
        return {
            // config: {
            //     events: {
            //         initialized: function () {
            //             console.log('initialized')
            //         },
            //     },
            // },
            config: {
                //toolbarButtons: ['bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'],
                heightMin: 300,
            },
            model: 'Edit Your Content Here!',
            froalaImage: null,
        };
    },
    components: {
        FroalaOld,
        ImageLibrary,
    },
    methods: {},
};
</script>
