<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">Post Lock Demo</div>

                    <div class="card-body">
                        <p>Lock/unlock post ID:</p>
                        <p>
                            <InputText type="text" class="form-control" v-model="lockPostId" />
                        </p>
                        <p>
                            <Button label="Lock" @click="btnLockPost" class="me-2" />
                            <Button label="Unlock" @click="btnUnlockPost" />
                        </p>
                        <hr />
                        <p>New post notification:</p>
                        <p>
                            <Dropdown v-model="postType" :options="postTypes" optionLabel="label" optionValue="postTypeId" />
                        </p>
                        <p>
                            <Button label="Send New Post Notification" @click="btnSendNewPost" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
//import { ref, onBeforeUnmount, onMounted, watch } from 'vue';
import { ref, onBeforeUnmount } from 'vue';
import useSignalR from '@/use/SignalR';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'SignalrPostLockDemo',
    setup() {
        const signalr = useSignalR();
        const { user } = useAuth0();

        const lockPostId = ref(3786736);
        const postType = ref(1);

        const postTypes = [
            {
                label: 'Post',
                postTypeId: 1
            },
            {
                label: 'Headline',
                postTypeId: 4
            }
        ];

        signalr.on('ReceivePostLock', (message) => console.log('ReceivePostLock:', message));
        signalr.on('ReceiveNewPost', (message) => console.log('ReceiveNewPost:', message));
        signalr.on('ReceiveNewPostNotification', (message) => console.log('ReceiveNewPostNotification:', message));

        onBeforeUnmount(() => {
            ['ReceivePostLock', 'ReceiveNewPost', 'ReceiveNewPostNotification'].forEach((methodName) => {
                signalr.off(methodName);
            });
        });

        function btnLockPost() {
            let postMessage = {
                postId: parseInt(lockPostId.value),
                userIdLock: user.value.name,
                connectionId: user.value.email,
            };
            console.log('BroadcastPostLock:', postMessage);
            signalr.invoke('BroadcastPostLock', postMessage);
        }

        function btnUnlockPost() {
            let postMessage = {
                postId: parseInt(lockPostId.value),
                userIdLock: null,
                connectionId: null,
            };
            console.log('BroadcastPostLock:', postMessage);
            signalr.invoke('BroadcastPostLock', postMessage);
        }

        function btnSendNewPost() {
            let payload = {
                postId: 1,
                postTypeId: postType.value
            }
            console.log('New post:', payload);
            if(payload.postTypeId == 1) {
                signalr.invoke('BroadcastNewPost', payload.postId);
            } else {
                signalr.invoke('BroadcastNewPostNotification', payload);
            }
        }

        // function send() {
        //     console.log('Sending:', message.value);
        //     signalr.invoke('Send', message.value);
        //     sent.value = true;
        // }

        return {
            btnLockPost,
            btnSendNewPost,
            btnUnlockPost,
            lockPostId,
            postType,
            postTypes,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
