<template>
    <div class="col-sm-12 col-md-4">
        <div class="card card-border-color card-border-color-primary podcast-shows-list">
            <div class="card-header card-header-divider">
                <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" @click="addNewShow()" v-tooltip.top="'Add New Show'" />
                <Button icon="pi pi-check" severity="secondary" class="btn-add p-button-tiny float-end me-1" iconPos="right" @click="saveSortOrder()" title="Save Sort Order" :disabled="!selectedSite" />
                Shows
                <SpinnerThm class="spinner" size="1.1em" v-show="podcastStore.loading" />
            </div>

            <div class="card-body">
                <div class="card-header-divider pb-2 mb-2" id="show_filter">
                    <Button label="" icon="far fa-times" iconPos="right" severity="secondary" text class="float-end" title="Clear Filter" @click="clearSiteFilter" />
                    <Dropdown id="SiteFilter" v-model="selectedSiteId" :options="sites" optionLabel="description" optionValue="siteId" class="d-flex" @change="filterSite" />
                </div>

                <div v-for="show in podcastStore.shows" :key="show.id" class="podcast item" v-bind:class="{ active: route.params.showId == show.id, primarySite: show.siteId == selectedSiteId }">
                    <div class="float-end advanced-icons">
                        <div v-if="selectedSiteId">
                            <Button icon="far fa-arrow-up" severity="secondary" outlined class="p-button-tiny show-on-hover" iconPos="right" @click="movePosition(show, 'up')" title="Move Up" />
                            <Button icon="far fa-arrow-down" severity="secondary" outlined class="p-button-tiny show-on-hover" iconPos="right" @click="movePosition(show, 'down')" title="Move Down" /><br />
                        </div>
                        <Button icon="far fa-cog" severity="secondary" outlined class="p-button-tiny show-on-hover" iconPos="right" @click="editShow(show)" title="Edit Show" />
                        <Button v-if="show.isPublished" icon="far fa-home" severity="secondary" outlined class="p-button-tiny xd-block show-on-hover" iconPos="right" @click="openShowLandingPage(show)" title="Edit Show" />
                    </div>

                    <div class="d-flex">
                        <a href="#" class="d-flex" @click="editEpisodes(show)">
                            <div class="flex-shrink-0">
                                <div class="corner-vip" v-if="show.subscription > 1">
                                    <i class="corner-vip__badge"></i>
                                </div>
                                <div class="cover-thumbnail" :style="'background-image: url(\'' + (typeof show.images != 'undefined' && show.images.squareImage ? show.images.squareImage : 'https://placehold.co/75x75?text=THM') + '\');'"></div>
                                <!--<img :src="show.images.squareImage" :alt="show.title" width="75" height="75" />-->
                            </div>
                        </a>
                        <div class="flex-grow-1 ms-3">
                            <a href="#" class="d-flex show-title" @click="editEpisodes(show)">{{ show.title }}</a>
                            <div v-if="show.siteId">
                                <!-- {{ getSiteById(show.siteId, 'description') }} -->
                                <img v-if="getSiteImage(show.siteId, 'icon')" :src="getSiteImage(show.siteId, 'icon')" width="18" height="18" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount, watch } from 'vue';
import { usePodcastStore } from '@/stores/PodcastStore';
import { useSiteStore } from '@/stores/SiteStore';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import PodcastService from '@/service/PodcastService';
import SpinnerThm from '@/components/SpinnerThm.vue';

export default {
    name: 'PodcastList',
    components: {
        SpinnerThm
    },
    setup() {
        const podcastService = new PodcastService();
        const siteStore = useSiteStore();
        const podcastStore = usePodcastStore();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const selectedSiteId = ref(null);
        //const shows = ref([]);
        const sites = ref([]);
        const selectedSite = ref(null);

        // Watch for route changes
        watch(
            () => route.params,
            async (newParams) => {
                //if(console.log.hide) console.log(route.params, newParams); // Ignore 'variable never used' error
                if (newParams.showId) {
                    podcastStore.setShow(newParams.showId).then((res) => {
                        siteStore.setSite(res.siteId);
                    });
                } else {
                    podcastStore.show = {};
                }
            }
        );

        // Before Mount
        onBeforeMount(() => {
            podcastStore.load().then(() => {
                siteStore.load().then(() => {
                    buildNonEmptySiteList();
                });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading Podcasts', detail: err.message || err, life: 3000 });
            });
        });

        function buildNonEmptySiteList() {
            // Filter sites to those that have at least one show
            siteStore.sites?.forEach((site) => {
                podcastStore.shows?.forEach((show) => {
                    // If the show's primary siteId is equal to the site, or it's siteGroup belongs to the shows SiteGroup, add it to the array
                    if ((show.siteId == site.siteId || site.siteGroup & show.siteGroup) && sites.value.findIndex((item) => item.siteId == site.siteId) < 0) {
                        sites.value.push(site);
                    }
                });
            });
        }

        function addNewShow() {
            //router.push(`/manage/podcasts`);
            router.push({ name: 'Podcasts' });
        }

        function saveSortOrder() {
            if (!Number.isInteger(selectedSiteId.value)) return;

            // Create layout payload
            let sortOrderLayout = [],
                idx = 0;
            podcastStore.shows.forEach((show) => {
                sortOrderLayout.push({ showId: show.id, siteId: show.siteId, sortOrder: idx });
                idx++;
            });

            // Save the layout to database
            //console.log('SORT ORDER:', sortOrderLayout);
            podcastService.saveShowLayout(selectedSiteId.value, sortOrderLayout)
            .then(() => {
                toast.add({ severity: 'success', summary: 'Save', detail: 'Podcast Layout Saved', life: 2500 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Podcast Layout', detail: err.message || err, life: 4000 });
            });
        }

        function movePosition(show, direction) {
            // Get current index of show in array
            var idx = podcastStore.shows.findIndex((item) => item.id === show.id);
            // Do not move if at the top or bottom of list
            if ((direction == 'up' && idx == 0) || (direction == 'down' && idx == podcastStore.shows.length - 1)) return;
            // Move the item up or down
            var newPosition = direction == 'up' ? idx-- : idx++;
            podcastStore.shows = arrayMove(podcastStore.shows, idx, newPosition);
        }

        function arrayMove(arr, fromIndex, toIndex) {
            var newArr = [...arr]; // Clone
            var element = newArr[fromIndex];
            newArr.splice(fromIndex, 1);
            newArr.splice(toIndex, 0, element);
            return newArr;
        }

        function editShow(show) {
            //router.push(`/manage/podcasts/${show.id}`);
            router.push({ name: 'Podcasts', params: { showId: show.id } });
        }

        function editEpisodes(show) {
            //router.push(`/manage/podcasts/${show.id}/episodes`);
            router.push({ name: 'PodcastEpisode', params: { showId: show.id } });
        }

        function filterSite() {
            //siteStore.setSite(selectedSiteId.value); // Had to move the selectedSite to it's own variable, else it messed with editing
            selectedSite.value = siteStore.getSite(selectedSiteId.value);

            // Get shows (sorted, per site)
            podcastService.getShows({ siteGroup: selectedSite.value.siteGroup }).then((res) => {
                if (res && typeof res === 'object') {
                    podcastStore.shows = res;
                }
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Filtering Shows', detail: err.message || err, life: 4000 });
            });
        }

        function openShowLandingPage(show) {
            let site = siteStore.sites?.find(({ siteId }) => siteId === show.siteId);
            var url = `https://${site.domain}/podcasts/${show.slug}`;
            window.open(url);
        }

        function getSiteImage(selectedSiteId, fld) {
            // This is kind of awkward, but I'm not sure of a better way to do it
            if (!selectedSiteId || !fld || !siteStore.sites) return null;
            let site = siteStore.sites?.find(({ siteId }) => siteId == selectedSiteId);
            return !site || typeof site.images == 'undefined' || typeof site.images[fld] == 'undefined' ? null : site.images[fld];
        }

        function clearSiteFilter() {
            //selectedSiteId.value = siteStore.site = null;
            selectedSiteId.value = selectedSite.value = null;
            //podcastStore.showsList = podcastStore.shows;
            podcastStore.load(true);
        }

        return { addNewShow, clearSiteFilter, editEpisodes, editShow, filterSite, getSiteImage, movePosition, openShowLandingPage, podcastStore, route, saveSortOrder, selectedSite, selectedSiteId, sites, siteStore };
    },
};
</script>
