<template>
    <section class="card" id="filter_bar">
        <div class="card-body container-fluid">
            <div class="row filter-row g-2">
                <div class="col-sm-9 col-md-10">
                    <!--<InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search... (⌘K)" v-on:keyup.enter="updateFilter" autofocus />-->
                    <InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search..." v-on:keyup.enter="updateFilter" autofocus />
                </div>
                <div class="col-sm-3 col-md-2">
                    <Button label="" icon="far fa-search" iconPos="right" class="ms-2" @click="updateFilter" />
                    <Button label="" icon="far fa-times" iconPos="right" severity="secondary" outlined class="ms-2" @click="clearFilter" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted, watch } from 'vue';
//import { useMagicKeys, whenever } from '@vueuse/core';

export default {
    name: 'TagsFilterBar',
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const keys = useMagicKeys();

        const filter = ref({});
        const defaultPageSize = 15;

        // Watch for route changes
        watch(
            () => route.query,
            (params, fromParams) => {
                if (console.log.hide) console.log({ from: fromParams, to: params });
                filter.value.pageSize = filter.value.pageSize ? parseInt(filter.value.pageSize) : defaultPageSize;
            }
        );

        // Mounted
        onMounted(() => {
            // Set filter bar based on query string values
            const query = Object.assign({}, route.query);
            filter.value = query;
            //filter.value.pageSize = filter.value.pageSize ? parseInt(filter.value.pageSize) : defaultPageSize; // Convert to number
        });

        /*
        whenever(keys['Meta+K'], () => {
            document.getElementById('SearchText').select();
        });
        */

        function updateFilter() {
            router.push({ query: { ...route.query, ...filter.value } });
        }

        function clearFilter() {
            filter.value = { pageSize: route.query.pageSize };
            //filter.value = {};
            router.push({ query: filter.value });
        }

        return { clearFilter, filter, updateFilter };
    },
};
</script>
