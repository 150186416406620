<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">File Upload Demo</div>
                <ConfirmDialog></ConfirmDialog>

                <div class="card-body">
                    <Message severity="warn" :closable="false">This uploads to production. <strong>Please delete all test files</strong> after you upload them by clicking the trash icon!</Message>
                    <Message severity="warn" :closable="false"
                        >This is currently configured to upload to <code>podcasts/10</code> because uploading to other containers/paths doesn't work on production, and I haven't figured out how to run multiple .NET projects on the same port at the same time.</Message
                    >

                    <div class="mb-3 input-group">
                        <label for="ImageUpload" class="form-label w-100">Image Upload (Auto)</label>
                        <InputText type="text" class="form-control" id="ImageUpload" :value="uploadedImage" placeholder="https://" />
                        <button v-if="uploadedImage" class="btn btn-outline-secondary delete" type="button" @click="deleteFile"><i class="far fa-trash-alt"></i></button>
                        <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadFile" />
                    </div>

                    <p v-if="uploadedImage">
                        <img :src="uploadedImage" class="preview-image" />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
import FileUpload from 'primevue/fileupload';
import MediaService from '../../service/MediaService';

export default {
    name: 'FileUploadDemo',
    data() {
        return {
            mediaService: new MediaService(),
            isLoading: false,
            uploadedImage: null,
        };
    },
    components: {
        FileUpload,
        ConfirmDialog,
    },
    methods: {
        uploadFile(event) {
            this.isLoading = true;
            console.log('[uploadFile] file:', event.files[0]);
            this.mediaService
                .uploadMediaObject('media', event.files[0])
                .then((res) => {
                    //console.log('[uploadFile] response:', res);
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: 'File Uploaded', life: 2000 });
                    this.uploadedImage = `https://cdn.townhall.com/${res[0].container}/${res[0].fullPath}`;
                    console.log('this.uploadedImage', this.uploadedImage);
                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Upload Error', detail: err.message || err, life: 3000 });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        deleteFile() {
            if (typeof this.uploadedImage == 'undefined' || !this.uploadedImage) return;

            this.$confirm.require({
                message: 'Are you sure you want to permanently delete this image?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.isLoading = true;
                    this.mediaService
                        .deleteMediaObject('media', this.uploadedImage)
                        .then(() => {
                            this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Deleted', life: 2000 });
                        })
                        .catch((err) => {
                            this.$toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 2000 });
                        })
                        .finally(() => {
                            this.uploadedImage = null;
                            this.isLoading = false;
                        });
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    label {
        font-weight: bold;
        padding-bottom: 0.3em;
    }
    .podcast-embed {
        margin-top: 1em;
    }
    .preview-image {
        max-height: 300px;
    }
}
</style>
