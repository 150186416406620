import BaseService from './BaseService';

export default class AlertBarService extends BaseService {
    async getAlert() {
        return await this.apiClient.get('/api/components/alertbar').then((res) => res.data);
    }

    async updateAlert(alert) {
        return await this.apiClient.put('/api/components/alertbar', alert).then((res) => res.data);
    }
}
