//import axios from 'axios';
import BaseService from './BaseService';
import { formatDateTimeET } from '@/utils/ThmDateTimeUtils';
export default class AdminService extends BaseService {
    async getSites() {
        return await this.apiClient.get('/api/shared/sites').then((res) => res.data);
    }
    getSubscriptions() {
        let subscriptions = [
            { label: 'None', value: '1' },
            { label: 'VIP', value: '4' },
            { label: 'VIP Gold', value: '8' },
        ];
        return subscriptions;
    }

    async getAuthor(authorId) {
        if (authorId != null) {
            return await this.apiClient.get(`/api/authors/${authorId}`).then((res) => res.data);
        } else {
            return null;
        }
    }

    async getAuthorById(authorId) {
        return await this.apiClient.get(`/api/authors/${authorId}`).then((res) => res.data);
    }

    async getAuthorTypes(includeNone = false) {
        return await this.apiClient.get('/api/authors/types').then((res) => {
            if (includeNone) {
                return res.data;
            } else {
                // Remove the 'NotSet' one
                return res.data.filter((authorType) => authorType.authorTypeId > 0);
            }
        });
    }
    async getAuthorAutoComplete(text, authorTypeId = null) {
        return await this.apiClient
            .get(`/api/authors/authorsautocomplete`, {
                params: {
                    text,
                    authorTypeId,
                },
            })
            .then((res) => res.data);
    }

    async getAuthorsFiltered(params) {
        return await this.apiClient.get('/api/authors/filtered', { params }).then((res) => res.data);
    }

    async getAuthorsPostCounts() {
        return await this.apiClient.get('/api/authors/postcounts').then((res) => res.data);
    }

    // Looks for next available slug given string. For example, if you pass "JohnDoe" and "JohnDoe" and "JohnDoe[2-3]" are taken,
    // it will return "JohnDoe4"
    async getAuthorsSlug(str) {
        return await this.apiClient
            .get(`/api/authors/filtered`, { params: { slug: str } })
            .then((res) => {
                str = str.toLowerCase();
                let attempt = str,
                    idx = 1,
                    result = null;

                while (!result) {
                    let found = res.data.find((obj) => obj.slug == attempt);
                    if (!found) result = attempt;
                    idx++;
                    attempt = str + idx;
                    if (idx > 50) break; // Prevent infinite loop
                }
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }

    async saveAuthor(author) {
        return await this.apiClient.post('/api/authors', author).then((res) => res.data);
    }

    async deleteAuthor(authorId) {
        return await this.apiClient.delete(`/api/authors/${authorId}`).then((res) => res.data);
    }

    /*
    async getAuthorsFiltered(id, authorTypeId = null, pageIndex = null, pageSize = null, orderBy = null) {
        id = id || null

        if (Number.isInteger(id)) {
            // Retrieve a single author
            return await axios.get(`../api/authors/${id}`).then(res => res.data)
        } else {
            // Query authors
            var _uri = '../api/authors/filtered?'
            if (authorTypeId) _uri += '&authorTypeId=' + authorTypeId
            if (pageIndex) _uri += '&pageIndex=' + pageIndex
            if (pageSize) _uri += '&pageSize=' + pageSize
            if (orderBy) _uri += '&orderBy=' + orderBy

            return await axios.get(_uri).then(res => res.data)
        }
    }
    */
    async getVersion() {
        //let config = this.setConfig();
        //return await axios.get(`/api/shared/version`, {headers: { Authorization: `Bearer ${config}` }}).then((res) => res.data);
        return await this.apiClient.get(`/api/shared/version`).then((res) => res.data);
    }
    async getCategories() {
        return await this.apiClient.get(`/api/shared/categories`).then((res) => res.data);
    }

    /* LOGGING */
    async getLog(logId) {
        return await this.apiClient.get(`/api/log/${logId}`).then((res) => res.data);
    }
    async getLogs(params) {
        return await this.apiClient.get('/api/log', { params }).then((res) => res.data);
    }
    async getLogsByRefId(logTypeId, referenceId) {
        return await this.apiClient.get(`/api/log/${logTypeId}/${referenceId}`).then((res) => res.data);
    }
    async getLogTypes() {
        return await this.apiClient.get('/api/log/types').then((res) => res.data);
    }
    async log(log) {
        //let now = new Date();

        // Set log entry default properties
        var defaults = {
            logTypeId: 2,
            referenceId: 0,
            //timestamp: now.toISOString(), // This causes timezone offset issues, depending on browser
            //timestamp: formatDateTimeET(now, 'yyyy-MM-dd HH:mm:ss'),
            timestamp: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'),
            language: 'plaintext',
        };
        let logEntry = { ...defaults, ...log };

        // Set userName to current user if not provided
        if (!logEntry.userName) {
            var authUser = JSON.parse(window.localStorage.getItem('thm-auth-user'));
            if(authUser?.name) logEntry.userName = authUser.name;
        }

        return await this.apiClient.post('/api/log', logEntry).then((res) => res.data);
    }
}
