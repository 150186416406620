<template>
    <section class="card" id="filter_bar">
        <div class="card-body container-fluid">
            <div class="row filter-row g-2">
                <div class="col-xs-12 col-sm-6 col-lg-2">
                    <Dropdown id="AuthorFilter" v-model="filter.authorId" :options="authorStore.authors" optionLabel="sortName" optionValue="authorId" :filter="true" placeholder="Author" class="d-flex" @change="updateFilter" :showClear="true" />
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-2 filter-row__padding-top"><TriStateCheckbox v-model="filter.isPublished" @change="updateFilter" /> Published</div>
                <div class="col-xs-12 col-sm-6 col-lg-2 filter-row__padding-top filter-row__padding-bottom"><TriStateCheckbox v-model="filter.isDefault" @change="updateFilter" /> Default</div>
                <div class="col-xs-12 col-sm-6 col-lg-2 filter-row__padding-bottom">
                    <Calendar id="DatePublished" v-model="filter.datePublished" :show-time="false" :show-icon="true" :manual-input="false" placeholder="Published" @date-select="updateFilter" />
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-2">
                    <Calendar id="DateCreated" v-model="filter.dateCreated" :show-time="false" :show-icon="true" :manual-input="false" placeholder="Created" @date-select="updateFilter" />
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-2">
                    <Button label="" icon="far fa-times" x-iconPos="right" class="p-button-outlined p-button-border-none x-float-end" @click="clearFilter" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, onBeforeMount, onMounted } from 'vue';
import { useAuthorStore } from '@/stores/AuthorStore';
import { useRoute, useRouter } from 'vue-router';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';

export default {
    name: 'CartoonsFilterBar',
    setup() {
        const authorStore = useAuthorStore();
        const router = useRouter();
        const route = useRoute();

        const filterDefaults = { isPublished: false, isDefault: null };
        const filter = ref({ ...filterDefaults });

        // Before Mount
        onBeforeMount(() => {
            //authorStore.getAuthorsPage({ authorTypeId: 4, orderBy: 'SortName' }, 0).then((res) => {
            // 2022-08-18: Added SiteGroup to limit the authors list:
            authorStore.getAuthorsPage({ authorTypeId: 4, siteGroup: 1, orderBy: 'SortName' }, 0).then((res) => {
                if(console.log.hide) console.log('res', res);
            });
        });

        // Mounted
        onMounted(() => {
            let query = Object.assign({}, route.query);

            // Convert property data types
            if (query.isPublished) query.isPublished = JSON.parse(query.isPublished); // Convert string to boolean
            if (query.isDefault) query.isDefault = JSON.parse(query.isDefault); // Convert string to boolean
            if (!isNaN(query.authorId)) query.authorId = parseInt(query.authorId);

            // Set filter to querystring values
            filter.value = { ...filterDefaults, ...query };
        });

        function updateFilter() {
            let filterFormatted = Object.assign({}, filter.value);
            if (filterFormatted.datePublished) filterFormatted.datePublished = formatDateTime(filterFormatted.datePublished, 'MM/dd/yyyy');
            if (filterFormatted.dateCreated) filterFormatted.dateCreated = formatDateTime(filterFormatted.dateCreated, 'MM/dd/yyyy');
            filterFormatted.pageIndex = 0;
            router.push({ query: filterFormatted });
        }

        function clearFilter() {
            filter.value = { ...filterDefaults };
            router.push({ query: null });
        }

        return { authorStore, clearFilter, filter, updateFilter };
    },
};
</script>

<style lang="scss" scoped>
#filter_bar {
    .p-checkbox {
        vertical-align: text-top;
    }
    .p-checkbox,
    .p-calendar,
    .p-button {
        margin-left: 1em;
    }
    .top-padding {
        padding-top: 0.6em;
    }

    /* Media queries to make filter bar flow nicely on smaller devices */
    @media (min-width: 1132px) {
        .col-lg-2:not(:first-of-type) {
            // The dropdown resizes itself and causes jumpiness w/o this :not
            width: auto;
        }
    }

    div.filter-row {
        &__padding-top {
            padding-top: 0.6em;
        }

        @media (max-width: 991px) {
            &__padding-bottom {
                padding-bottom: 0.6em;
            }
        }
        @media (max-width: 575px) {
            .col-lg-2:last-of-type {
                padding-top: 0.6em;
            }
        }
    }
}
</style>
