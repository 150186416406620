<template>
    <LogsFilterBar />

    <section class="card card-border-color card-border-color-primary" id="cartoons">
        <div class="card-body container-fluid">

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Reference ID</th>
                        <th scope="col">Timestamp</th>
                        <th scope="col">Type</th>
                        <th scope="col">Name</th>
                        <th scope="col">Message</th>
                        <th class="th-col-view" />
                    </tr>
                </thead>
            <tbody>
                <tr v-for="log in logStore.logs" :key="log.logId">
                    <th scope="row">
                        <a :href="`/log/${log.logId}`">{{ log.logId }}</a>
                    </th>
                    <td><a :href="getLink({ logTypeId: log.logTypeId, referenceId: log.referenceId })" @click.prevent="updateFilter({ logTypeId: log.logTypeId, referenceId: log.referenceId });">{{ log.referenceId }}</a></td>
                    <td><a :href="getLink({ dateStart: formatDateTime(log.timestamp, 'MM/dd/yyyy'), dateEnd: formatDateTime(log.timestamp, 'MM/dd/yyyy') })" @click.prevent="updateFilter({ dateStart: formatDateTime(log.timestamp, 'MM/dd/yyyy'), dateEnd: formatDateTime(log.timestamp, 'MM/dd/yyyy') });">{{ formatDateTime(log.timestamp) }}</a></td>
                    <td><a :href="getLink({ logTypeId: log.logTypeId })" @click.prevent="updateFilter({ logTypeId: log.logTypeId });">{{ log.logTypeName }}</a></td>
                    <td><a :href="getLink({ userName: log.userName })" @click.prevent="updateFilter({ userName: log.userName });">{{ log.userName }}</a></td>
                    <td>{{ log.message }}</td>
                    <td class="th-col-view">
                        <!--<Button icon="pi pi-search" class="p-button-rounded" @click="log.original ? displayDiff(log) : displayContent(log)" :disabled="!log.content" />-->
                        <Button icon="pi pi-search" severity="info" rounded @click="log.original ? displayDiff(log) : displayContent(log)" :class="{ invisible: !log.content }"  />
                    </td>
                </tr>
            </tbody>
            </table>

            <!--<Paginator :totalRecords="totalRows" :rows="pageSize" @page="podcastStore.getEpisodesPage({ showId: podcastStore.show.id, pageIndex: $event.page, pageSize: pageSize })" />-->
            <!--<Paginator :totalRecords="logStore.totalRows" :rows="pageSize" @page="onPage($event)" />-->
            <PaginationThm v-model="route.query.pageIndex" :totalRecords="logStore.totalRows" :pageSize="pageSize" @page="onPage" class="mt-3" />

        </div>
    </section>

    <!-- Modal: View -->
    <Dialog v-model:visible="showContentModal" :dismissableMask="true" :style="{width: '80vw'}">
        <template #header>
            <h4>Log ID: {{ activeLog.logId }}, {{ activeLog.userName }}</h4>
        </template>

        <Diff
            mode="unified"
            theme="light"
            :language="activeLog.language || 'plaintext'"
            :prev="activeLog.content"
            :current="activeLog.content"
        />

        <Diff
            :mode="splitView ? 'split' : 'unified'"
            theme="light"
            language="html"
            :prev="activeLog.htmlBefore || activeLog.htmlAfter"
            :current="activeLog.htmlAfter"
            v-if="activeLog.htmlBefore || activeLog.htmlAfter"
        />
    </Dialog>

    <!-- Modal: Diff -->
    <Dialog v-model:visible="showDiffModal" :dismissableMask="true" :maximizable="true" :style="{width: '95vw'}">
        <template #header>
            <h4>Diff &ndash; Log ID: {{ activeLog.logId }}, {{ activeLog.userName }}</h4>
        </template>

        <Diff
            :mode="splitView ? 'split' : 'unified'"
            theme="light"
            :language="activeLog.language || 'plaintext'"
            :prev="activeLog.original"
            :current="activeLog.content"
        />

        <Diff
            :mode="splitView ? 'split' : 'unified'"
            theme="light"
            language="html"
            :prev="activeLog.htmlBefore || activeLog.htmlAfter"
            :current="activeLog.htmlAfter"
            v-if="activeLog.htmlBefore || activeLog.htmlAfter"
        />

        <template #footer>
            <InputSwitch v-model="splitView" /> <div class="ms-1 split-view-label">Split View</div>
        </template>
    </Dialog>
</template>

<script>
import LogsFilterBar from './LogsFilterBar.vue';
import { ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useLogStore } from '@/stores/LogStore';
import { useToast } from 'primevue/usetoast';
import { subMonths } from 'date-fns';
import PaginationThm from '@/components/PaginationThm.vue';

export default {
    name: 'Logs',
    components: {
        LogsFilterBar,
        PaginationThm
    },
    setup() {
        //const adminService = new AdminService();
        const logStore = useLogStore();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const filter = ref({
            dateStart: new Date(formatDateTime(subMonths(new Date, 2))) // Default start date is 2 months ago
        });
        const pageSize = 10;
        const showLogId = ref(0);
        const activeLog = ref({});
        const showDiffModal = ref(false);
        const showContentModal = ref(false);
        const splitView = ref(false);

        watch(
            () => route.query, (query, fromQuery) => {
                //console.log('[watch] Logs');
                if(console.log.hide) console.log({ from: fromQuery, to: query });
                //logStore.getLogs({ pageSize: pageSize.value, pageIndex: pageIndex.value, ...query }).catch((err) => {
                logStore.getLogs({ ...query, pageSize: pageSize.value }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Loading Author', detail: err.message || err, life: 3000 });
                });
            }
        );

        onBeforeMount(() => {
            logStore.load();
        });

        onMounted(() => {
            /*
            adminService.getLogs({ pageSize: pageSize.value, ...route.query }).then((res) => {
                logs.value = res;
            });
            */
            filter.value = { ...filter.value, ...route.query };
            logStore.getLogs({ ...filter.value, pageSize: pageSize }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading Author', detail: err.message || err, life: 3000 });
            });
        });

        const totalRows = computed(() => {
            return logStore.logs.value.length ? logStore.logs.value[0].totalRows : 0;
        });

        function updateFilter(params) {
            filter.value = { ...route.query, ...params };
            delete filter.value.pageIndex;
            router.push({ query: filter.value });
            return false;
        }

        function getLink(params) {
            var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            return `/tools/logs?${queryString}`;
        }

        function onPage(event) {
            //filter.value = { ...route.query, pageIndex: pageIndex.value };
            //if(event.page > 0) logStore.getLogs({ ...route.query, pageIndex: event.page, pageSize: pageSize.value });
            //router.push({ query: { ...route.query } });
            router.push({ query: { ...route.query, pageIndex: event.page } });
        }

        // Show Content
        function displayContent(log) {
            activeLog.value = log;
            showContentModal.value = true;
        }

        // Show Diff
        function displayDiff(log) {
            log.content = log.content || '';
            log.original = log.original || '';
            activeLog.value = log;
            showDiffModal.value = true;
        }

        return {
            activeLog,
            displayContent,
            displayDiff,
            filter,
            formatDateTime,
            getLink,
            logStore,
            onPage,
            //pageIndex,
            pageSize,
            route,
            router,
            showContentModal,
            showDiffModal,
            showLogId,
            splitView,
            totalRows,
            updateFilter
        };
    }
};
</script>

<style lang="scss" scoped>
div.card-body {
    .th-col-view {
        width: 80px;
    }
}
</style>
