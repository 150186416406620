import { defineStore } from 'pinia';
import { seoUrl } from '@/utils/ThmUtils';
import { getUser } from '@/utils/ThmAuthUtils';
import { getAppSettings } from '@/utils/ThmUtils';
import { formatDateTimeET, formatDateTime, formatDateTimeISO } from '@/utils/ThmDateTimeUtils';
import AdminService from '@/service/AdminService';
import PostService from '@/service/PostService';

const adminService = new AdminService();
const postService = new PostService();

export const useCptEditStore = defineStore('CptEditStore', {
    state: () => ({
        isDirty: false,
        editMode: false,
        isReadonly: false,
        currentAuthor: { authorId: 0 },
        post: {
            userIdLock: null,
            extendedProperties: {
                customPost: {
                    body: '',
                    source: {
                        name: '',
                        author: '',
                        url: ''
                    }
                }
            }
        },
        postType: {
            postTypeId: 0,
            name: null
        },
        newPost: {
            //postId: 0,
            datePublished: formatDateTimeET(),
            postTypeId: 1,
            status: 2,
            siteGroup: getAppSettings().siteGroup,
            userIdLock: null,
            userId: null,
            extendedProperties: {
                customPost: {
                    body: '',
                    source: {
                        name: '',
                        author: '',
                        url: ''
                    }
                }
            }
        }
    }),
    actions: {
        async load(postTypeId, force = true) {
            if(force || !this.postType.postTypeId) {
                const appSettings = getAppSettings();

                // Get current author
                await this.getCurrentAuthor();

                // Set post type based on route
                await this.setPostType(postTypeId);

                // Set post defaults
                this.newPost.postTypeId = this.postType.postTypeId;
                if(!Object.keys(this.post).length) this.setDefaults();
                let user = getUser();
                this.newPost = {
                    ...this.newPost,
                    userId: user.name,
                    authorId: this.currentAuthor.authorId || appSettings.defaultAuthorId || 1
                };
                if(!this.post.authorId) this.setDefaults(); // TODO?
            }
        },
        async getPost(postId) {
            await postService.getPost(postId).then((res) => {
                //this.post = res;
                this.$patch((state) => {
                    state.post = res;
                });
            }).catch((err) => {
                throw err;
            });
        },
        async setPostType(postTypeId) {
            return await postService.getPostTypes().then((res) => {
                let postType = res.find((obj) => obj.postTypeId == postTypeId);
                if(postType) this.postType = postType;
                return postType;
            }).catch((err) => {
                throw err;
            });
        },
        async save() {
            this.loading = true;
            let isNew = !this.post.postId || this.post.postId < 1;
            let post = { ...this.post }; // Cloning to avoid date formatting from being reactive

            //post.datePublished = post.datePublished ? formatDateTimeISO(post.datePublished) : null;
            post.datePublished = post.datePublished ? formatDateTime(post.datePublished, 'yyyy-MM-dd HH:mm:ss') : null;

            if(isNew) {
                post.urlTitle = this.post.urlTitle = seoUrl(this.post.title);
                post.url = this.getPath(false);
            }

            return await postService.getPost(post.postId).then((original) => { // Fetch original
                return postService.savePost(post).then((res) => { // Save virtual page
                    if(original) {
                        original.datePublished = formatDateTimeISO(original.datePublished);
                        delete original.dateModified;
                    }
                    delete post.dateModified;
                    adminService.log({ logTypeId: 20, referenceId: res.postId, message: (isNew ? 'Added: ' : 'Modified: ') + res.title, content: JSON.stringify(post, null, 2), original: isNew ? null : JSON.stringify(original, null, 2), language: 'json' });
                    res.isNew = isNew;
                    return res;
                }).catch((error) => {
                    console.error(error);
                    throw(error);
                }).finally(() => {
                    this.loading = false;
                });

            });
        },
        async delete() {
            if(!this.post || !this.post.postId) return;
            this.loading = true;

            let postTypeId = this.postType.postTypeId;
            return await postService.deletePost(this.post.postId).then(() => {
                adminService.log({ logTypeId: 20, referenceId: this.post.postId, message: 'Delete: ' + this.post.title, content: JSON.stringify(this.post, null, 2), language: 'json' });
                this.post = { ...this.newPost };
                this.setDefaults(postTypeId);
                return this.post;
            }).catch((error) => {
                throw(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        async getCurrentAuthor() {
            let user = getUser();
            if(typeof this.currentAuthor == 'object' && this.currentAuthor.email == user.email) return this.currentAuthor;

            return await adminService.getAuthorsFiltered({ email: user.email, pageSize: 1 }).then((res) => {
                if(res.length > 0) {
                    this.currentAuthor = res[0];
                } else {
                    this.currentAuthor = { authorId: 0 };
                }
                return this.currentAuthor;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        getPath(withPath = true) {
            let datePath = formatDateTime(this.post.datePublished, 'yyyy/MM/dd');
            if(withPath) {
                return `/headlines/${datePath}/${this.post.urlTitle}-n${this.post.postId}`;
            } else {
                return `/headlines/${datePath}/${this.post.urlTitle}`;
            }
        },
        setDefaults(postTypeId) {
            // this.$patch((state) => {
            //     state.post = { ...this.newPost };
            //     state.post.extendedProperties.customPost.body = null;
            //     state.post.extendedProperties.customPost.source = { name: null, author: null, url: null };
            // });

            this.post = { ...this.newPost };
            this.post.extendedProperties.customPost.body = null;
            this.post.extendedProperties.customPost.source = { name: null, author: null, url: null };

            if(postTypeId) {
                this.post.postTypeId = postTypeId;
            }
        }
    }
})