/**
 * Parse a JWT token and return object
 * @param {string} token
 * @see https://stackoverflow.com/a/38552302/3799374
 */
export function parseJwt(token) {
    var base64Url = token?.split('.')[1];
    if(!base64Url) return null;
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

/**
 * Get the domain name from a URL
 * @param {string} link - The URL from which to fetch the domain
 * @example
 * // returns: redstate.com
 * getDomainFromUrl('https://redstate.com/path/to/article');
 */
 export function getDomainFromUrl(link) {
    if(!link || !link.includes('.')) return link;
    let url = new URL(link);
    return url.hostname || link;
}

/**
 * Strip HTML tags from a string
 * @param {string} html - The input string
 * @example
 * // returns: Example text
 * uppercaseFirst('<p>Example text</p>');
 */
 export function stripHtml(html) {
    if(!html) return html;
    var tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
}

/**
 * Capitalize the first letter of a string.
 * @param {string} str - The input string
 * @example
 * // returns: Hello world
 * uppercaseFirst('hello world');
 */
export function uppercaseFirst(str) {
    if(!str || !str.trim()) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}
/**
 * Checks if the value is empty or null
 * @param {string} value
 */
export function isEmptyOrNull(value) {
    //has a value
    if(value && value.trim()) {
        return false;
    }
    return true;
}

/**
 * Get the filename from a URL or path
 * @param {String} path - The input path or URL
 * @param {Boolean} stripExtension - If true, removes the file extension
 * @example
 * // returns: AAAAAAAA-BBBB-CCCC-DDDD-EEEEEEEEEEEE
 * getFilenameFromPath('https://media.townhall.com/cdn/hodl/2022/1/AAAAAAAA-BBBB-CCCC-DDDD-EEEEEEEEEEEE.png', true);
 */
 export function getFilenameFromPath(path, stripExtension = false) {
    if(!path || !path.trim()) return path;
    let arr = path.split('/');
    let filename = arr.pop();
    if(!stripExtension) return filename;

    // Remove extension
    var lastPeriodPosition = filename.lastIndexOf('.');
    filename = filename.substring(0, lastPeriodPosition);
    return filename;
}

/**
 * Returns the file extension from a filename or URI string
 * @param {string} filename Filename or URI
 * @returns {string}
 * @example
 * // returns: jpg
 * getFileExtension('https://example.com/fold.er/fil.e.jpg?param.eter#hash=12.345');
 */
export function getFilenameExtension(filename) {
    // Source:https://stackoverflow.com/a/47767860/3799374
    if(!filename || !filename.includes('.')) return null;
    return filename.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
}

/**
 * Check if string contains valid JSON
 * @param {string} str
 * @example
 * // returns true
 * isValidJSON( '{ "name": "Homelander" }' );
 */
 export function isValidJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (_) {
        return false;
    }
}

/**
 * Check if string is a valid URL
 * @param {string} str
 * @example
 * // returns true
 * isValidURL( 'https://townhall.com/path' );
 */
 export function isValidURL(str) {
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
}

/**
 * Adds ordinal suffix to number
 * @param {string} number
 * @example
 * // returns "23rd"
 * abbreviateNumber( 23 );
 */
export function abbreviateNumber(number) {
    let abbrev = '';
    switch (number) {
        default:
        case 0:
        case 1:
            abbrev = 'st';
            break;
        case 2:
            abbrev = 'nd';
            break;
        case 3:
            abbrev = 'rd';
            break;
        case 4:
        case 5:
        case 6:
        case 7:
            abbrev = 'th';
            break;
    }
    return abbrev;
}

/**
 * Shorten a long number
 * @param {string} number
 * @example
 * // returns "2.5K"
 * shortenNumber( 2500 );
 */
export function shortenNumber(number) {
    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1
      }).format(number);
}

export function addHyperlinkTitles(html) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, 'text/html');
    doc.querySelectorAll('a:not([title])')
        .forEach(A=>{ A.title = A.href });
    return doc.body.innerHTML;
}

export function stripHyperlinkTitles(html) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, 'text/html');
    doc.querySelectorAll('a[title^="http"], a[title^="mailto"]')
        .forEach(element => {
            //console.log('element', element);
            element.removeAttribute('title');
        });
    return doc.body.innerHTML;
}

/**
 * Formats numbers/strings as currency
 * @param {string} str
 * @example
 * // returns "$2,551.60"
 * formatCurrency( 2551.6 );
 */
export function formatCurrency(str) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return formatter.format(Number(str));
}

/**
 * Checks whether a given string is a valid HTML color hex
 * @param {string} hex
 * @example
 * isValidHexColor( '#FFF000' ); // returns true
 * isValidHexColor( 'FFFFFF' ); // returns false
 */
export function isValidHexColor(hex) {
    // Regular expression to match hex color code
    const hexRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
    return hexRegex.test(hex);
}

/**
 * Generate random string of given length
 * @param {Number} length
 * @param {Boolean} includeSymbols
 * @example
 * generateRandomString( 5 ); // returns 'Ah7kJ'
 * isValidHexColor( 7, true ); // returns 'fG_JwT$'
 */
export function generateRandomString(length = 8, includeSymbols = false) {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    if(includeSymbols) characters += '!@#$%^&*()_+[]{}|;:,.<>';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

/**
 * Get the initials from a name
 * @param {String} name
 * @example
 * getInitials( 'John Doe' ); // returns "JD"
 */
export function getInitials(displayName) {
    if(!displayName) return '';

    // Split the name into words
    let words = displayName.trim().split(/\s+/);

    // Handle the case for names with more than 2 words
    if(words.length > 1) {
        let firstInitial = words[0][0].toUpperCase();
        let lastInitial = words[words.length - 1][0].toUpperCase();
        return firstInitial + lastInitial;
    }

    // Handle the case for names with only one word
    let firstTwoLetters = displayName.slice(0, 2).toUpperCase();
    return firstTwoLetters;
}

/**
 * Strips empty paragraphs from a string that are added by Froala
 * @param {String} html
 * @example
 * removeEmptyParagraphs( '<p>Test</p><p><br></p>' ); // returns "<p>Test</p>"
 */
export function removeEmptyParagraphs(html, trailingOnly = false) {
    if(!html) return '';
    // Create a DOM parser to parse the HTML string
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, 'text/html');

    // Get all the <p> elements
    let paragraphs = doc.querySelectorAll('p');

    if (trailingOnly) {
        // Remove trailing empty paragraphs only
        for (let i = paragraphs.length - 1; i >= 0; i--) {
            let p = paragraphs[i];
            if (p.innerHTML.trim() === '<br>') {
                p.remove();
            } else {
                break;
            }
        }
    } else {
        // Remove all empty paragraphs
        paragraphs.forEach(p => {
            if (p.innerHTML.trim() === '<br>') {
                p.remove();
            }
        });
    }

    // Return the modified HTML string
    return doc.body.innerHTML?.trim();
}

export default {
    abbreviateNumber,
    addHyperlinkTitles,
    formatCurrency,
    generateRandomString,
    getDomainFromUrl,
    getFilenameExtension,
    getFilenameFromPath,
    getInitials,
    isValidHexColor,
    isValidJSON,
    isValidURL,
    parseJwt,
    removeEmptyParagraphs,
    shortenNumber,
    stripHtml,
    stripHyperlinkTitles,
    uppercaseFirst,
    isEmptyOrNull
};
