import BaseService from './BaseService';

export default class NewsletterService extends BaseService {
    async getAds(parameters) {
        return await this.apiClient
            .post('../api/newsletter/adsearch', parameters, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.data);
    }

    async saveAd(ad) {
        return await this.apiClient
            .post('../api/newsletter/save', ad, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.data);
    }

    async getAd(id) {
        return await this.apiClient
            .get('../api/newsletter/ad', {
                params: {
                    ID: id,
                },
            })
            .then((res) => res.data);
    }
    async deleteAd(id) {
        return await this.apiClient
            .get('../api/newsletter/delete', {
                params: {
                    ID: id,
                },
            })
            .then((res) => res.data);
    }
    async getAdTemplates() {
        return await this.apiClient.get('../api/newsletter/templates').then((res) => res.data);
    }

    async getActiveInAdTemplates() {
        return await this.apiClient.get('../api/newsletter/activeinadtemplates').then((res) => res.data);
    }

    async getAdPositions() {
        return await this.apiClient.get('../api/newsletter/adpositions').then((res) => res.data);
    }

    async getAdvertiserCategories() {
        return await this.apiClient.get('../api/newsletter/advertisercategories').then((res) => res.data);
    }
//
    async getCustomNl(id) {
        return await this.apiClient
        .get('/api/newsletter/customnl', {
            params: {
                Id: id,
            },
        })
        .then((res) => res.data);
    }

    async publishCustomNl(params) {
        return await this.apiClient
        .post('/api/newsletter/setCustomNlPublished', params, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => res.data);
    }


    async updateCustomNl(customNl) {
        return await this.apiClient
            .post('/api/newsletter/updatecustomnl', customNl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.data);
    }

}
