import BaseService from './BaseService';

export default class ReportService extends BaseService {
    async getPostStats(params) {
        //console.log('getPostStats:', params);
        return await this.apiClient.get('/api/reports/poststats', { params: params }).then((res) => res.data);
    }
    async getPostCounts(params) {
        return await this.apiClient.get('/api/reports/PostCounts', { params: params }).then((res) => res.data);
    }
    async getPostCountsByAuthorId(authorId, params) {
        return await this.apiClient.get(`/api/reports/PostCounts/${authorId}`, { params: params }).then((res) => res.data);
    }
    async getDailyTransactions(params) {
        return await this.apiClient.get('/api/reports/DailyTransactions', { params: params }).then((res) => res.data);
    }
    async getWeeklySiteReport() {
        return await this.apiClient.get('/api/reports/WeeklySiteReport').then((res) => res.data);
    }
    async getMonthlySiteReport() {
        return await this.apiClient.get('/api/reports/MonthlySiteReport').then((res) => res.data);
    }
}
