<template>
    <div class="card-body tag-list container-fluid pb-3">
        <!--<Paginator :totalRecords="totalRows" :rows="pageSize" @page="onPage($event)" v-show="tags.length" :rowsPerPageOptions="[10, 15, 25, 50, 100]" />-->
        <PaginationThm v-model="route.query.pageIndex" :totalRecords="totalRows" :pageSize="pageSize" @page="onPage" />

        <div class="row row__header pb-2 mb-1">
            <div class="col-md-3 col-lg-2 d-none d-md-block"></div>
            <div class="col-sm-5 col-lg-6 fw-bold">Name</div>
            <div class="col-sm-6 col-md-3 d-none d-sm-block fw-bold text-center">Slug</div>
            <div class="col-md-1 col-lg-1 d-none d-md-block text-center" title="Post Count" alt="Post Count">#</div>
            <!--<div class="col-md-1 col-lg-1 d-none d-md-block"></div>-->
        </div>

        <p class="fst-italic text-muted ps-1 pt-4" v-show="Object.keys(route.query).length && !tags.length">No tags found matching criteria.</p>

        <div v-for="tag in tags" :key="tag.tagId" class="row tag-item item" v-bind:class="{ active: route.params.tag == tag.slug }">
            <div class="col-md-3 col-lg-2 d-none d-md-block">
                <Button label="Edit" class="btn-edit" @click="editTag(tag.slug)" />
            </div>

            <div class="col-sm-5 col-lg-6 ps-1">
                <span class="d-none d-md-block">{{ tag.name }}</span>
                <a href="#" class="d-block d-md-none" @click="editTag(tag.slug)">{{ tag.name }}</a>
            </div>

            <div class="col-sm-6 col-md-3 d-none d-sm-block text-center" x-style="line-height: 42px">
                <a :href="getPermalink(`/tags/${encodeURIComponent(tag.slug)}`)" target="_blank" v-if="tag.isPublished">{{ tag.slug }}</a>
                <span v-else>{{ tag.slug }}</span>
            </div>

            <div class="col-sm-1 ps-1 d-none d-md-block text-center">
                {{ tag.postCount }}
            </div>
            <!--
            <div class="col-sm-1 ps-1 d-none d-md-block">
                <i class="far fa-check-circle fa-lg align-bottom text-success" v-if="tag.isPublished" alt="Enabled" title="Enabled"></i>
            </div>
            -->
        </div>

        <!--<Paginator :totalRecords="totalRows" :rows="pageSize" @page="onPage($event)" v-show="tags.length" :rowsPerPageOptions="[15,25,50,100]" />-->
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, inject, onMounted, watch } from 'vue';
import PaginationThm from '@/components/PaginationThm.vue';
import TagsService from '@/service/TagsService';

export default {
    name: 'TagsList',
    components: {
        PaginationThm
    },
    setup() {
        const router = useRouter();
        const tagsService = new TagsService();
        const appSettings = inject('appSettings');
        const route = useRoute();

        const filter = ref({});
        const tags = ref([]);
        const pageSize = ref(15);
        const totalRows = ref(0);

        // Watch for route changes
        watch(
            () => route.query, (params, fromParams) => {
                if(console.log.hide) console.log({ from: fromParams, to: params });
                filter.value = params;
                tagsService.getTags(params).then((res) => {
                    tags.value = res;
                    if(res.length && res[0].totalRows) totalRows.value = res[0].totalRows;
                });
            }
        );

        // Mounted
        onMounted(() => {
            if(route.query.pageSize) pageSize.value = parseInt(route.query.pageSize);
            tagsService.getTags(route.query).then((res) => {
                tags.value = res;
                if(res.length && res[0].totalRows) totalRows.value = res[0].totalRows;
            });
        });

        function onPage(e) {
            router.push({ query: { ...route.query, pageSize: e.rows, pageIndex: e.page } });
            tagsService.getTags({ ...route.query, pageIndex: e.page }).then((res) => {
                if(console.log.hide) console.log('res', res);
                tags.value = res;
            });
        }

        function editTag(tag) {
            router.push({ path: `/tags/${encodeURIComponent(tag)}`, query: route.query });
        }

        function getPermalink(path) {
            return appSettings.webroot[process.env.NODE_ENV] + path;
        }

        return {
            editTag,
            filter,
            getPermalink,
            onPage,
            pageSize,
            route,
            tags,
            totalRows
        };
    },
};
</script>

<style lang="scss" scoped>
.tag-list {
    .row.tag-item {
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }

        @media (max-width: 767px) {
            min-height: 30px;
            padding: 0.75em;
        }
    }
}
</style>
