import { useSignalR } from '@dreamonkey/vue-signalr';
//import { useSignalR } from '@quangdao/vue-signalr';

export default function thmSignalR() {
    //const { on, off, once, invoke } = useSignalR();
    const signalr = useSignalR();

    /*
    function on(methodName, ...params) {
        signalr.off(methodName);
        signalr.on(methodName, ...params);
    }
    */

    return {
        invoke: signalr.invoke,
        off: signalr.off,
        on: signalr.on,
        once: signalr.once,
        send: signalr.invoke,
    };

}

/*
import { useSignalR } from '@dreamonkey/vue-signalr';
//const signalr = useSignalR();

export function on(methodName, ...params) {
    const signalr = useSignalR();
    off(methodName);
    signalr.on(methodName, ...params);
}

export function once(methodName, ...params) {
    const signalr = useSignalR();
    off(methodName);
    signalr.once(methodName, ...params);
}

export function invoke(methodName, ...params) {
    const signalr = useSignalR();
    signalr.invoke(methodName, ...params);
}

export function off(methodName, ...params) {
    const signalr = useSignalR();
    signalr.off(methodName, ...params);
}

export default {
    invoke,
    off,
    on,
    once,
};
*/
