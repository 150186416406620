<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Chips hello</div>
                <div class="card-body"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    props: {
        msg: String,
    },
    data() {
        return {
            checked: false,
            chip1: null,
            chip2: null,
            chip3: null,
            text: null,
        };
    },
    methods: {
        greet() {
            this.$toast.add({
                severity: 'info',
                summary: 'hello',
                detail: this.text,
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
