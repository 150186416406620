import axios from 'axios';
//import AppSettings from '/appsettings.json';
//import { useAuth0 } from '@auth0/auth0-vue';
import { getAppSettings } from '@/utils/ThmUtils';
//import { useSiteStore } from '@/stores/SiteStore';
import { auth0 } from '@/main.js';

export default class BaseService {
    constructor(accessToken) {
        this.token = accessToken;
        this.apiClient = axios.create({
            withCredentials: true,
            //headers: { Authorization: 'Bearer ' + this.getToken() },
        });

        // Set bearer token with each request: https://stackoverflow.com/a/43052288/3799374
        /*
        this.apiClient.interceptors.request.use(function (config) {
            const appSettings = getAppSettings();
            let token = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${appSettings.auth0.clientId}::${appSettings.auth0.audience}::openid profile email`));
            if(token) {
                //config.headers.Authorization = oktaToken.accessToken?.accessToken ? `Bearer ${oktaToken.accessToken?.accessToken}` : 'null token';
                config.headers.Authorization = token.body?.access_token ? `Bearer ${token.body.access_token}` : 'null token';
            } else {
                console.log('🤬 [Auth0] null token');
            }
            return config;
        });
        */

        this.apiClient.interceptors.request.use(
            async (config) => {
                /*
                const siteStore = useSiteStore();
                siteStore.accessToken = await auth0.getAccessTokenSilently();
                config.headers['Authorization'] = `Bearer ${siteStore.accessToken}`
                */
                let token = await auth0.getAccessTokenSilently();
                config.headers['Authorization'] = `Bearer ${token}`
                return config;
            },
            (error) => {
                // Do something with request error
                return Promise.reject(error)
            }
        );

        // Login redirect when Auth0 token expired
        /*
        this.apiClient.interceptors.response.use(
            response => response,
            async error => {
                //if (error.response && error.response.status === 401 && error.response.data.error === 'login_required') {
                if (error.toString().includes('Login required')) {
                    await auth0.loginWithRedirect();
                }
                return Promise.reject(error);
            }
        );
        */
    }
    getToken() {
        const appSettings = getAppSettings();
        let token = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${appSettings.auth0.clientId}::${appSettings.auth0.audience}::openid profile email`));
        //console.log('token.body.access_token', token.body.access_token);
        return token?.body?.access_token ?? null;
    }
}
