<template>
    <small :title="message" class="badge" :class="badgeStyle">
        {{ prefix }}
        {{ formatIt(datetime, 'MM/dd/yyyy hh:mm a') }}
        {{ displayTimezone() }}
        {{ suffix }}
    </small>
</template>

<script>
import { formatDateTime, formatDateTimeET, getTimezone } from '@/utils/ThmDateTimeUtils';
import { computed } from 'vue';
export default {
    name: 'PublishLabelStatus',
    props: {
        status: {
            type: [Number, Boolean],
            default: 0,
            required: true,
        },
        datetime: {
            type: String,
            default: new Date().toString(),
            required: true,
        },
        prefix: String,
        suffix: String,
        showTimezone: {
            type: Boolean,
            default: false
        }
    },
    //options: props, context
    setup(_props) {

        const message = computed(() => {
            let _nowEasternTime = new Date(formatDateTimeET());
            let _dateTime = new Date(_props.datetime);

            switch (_props.status) {
                case 4:
                    return 'Pending';
                case 2:
                    return 'Draft';
                case 1:
                    //if (Date.parse(this.datetime) > new Date()) {
                    if(_dateTime.getTime() > _nowEasternTime.getTime()) {
                        return 'Scheduled';
                    } else {
                        return 'Published';
                    }
                case 0:
                    return 'Unpublished';
                default:
                    return '';
            }
        });

        const badgeStyle = computed(() => {
            let _nowEasternTime = new Date(formatDateTimeET());
            let _dateTime = new Date(_props.datetime);

            switch (_props.status) {
                case 2:
                    return 'text-bg-secondary';
                case 1:
                    //if (Date.parse(this.datetime) > new Date()) {
                    if(_dateTime.getTime() > _nowEasternTime.getTime()) {
                        return 'text-bg-warning text-dark';
                    } else {
                        return 'text-bg-success';
                    }
                case 4:
                    return 'text-bg-primary';
                case 0:
                    return 'text-bg-danger';
                default:
                    return '';
            }
        });

        function formatIt(datetime, format) {
            if (datetime != null) {
                return formatDateTime(datetime, format);
            }
        }

        function displayTimezone() {
            return _props.showTimezone ? getTimezone() : '';
        }

        return {
            message,
            badgeStyle,
            formatIt,
            displayTimezone
        };
    }
};
</script>
