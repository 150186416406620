<template>
    <div class="thm-pagination" v-show="props.alwaysShow || props.totalRecords > 0">

        <nav>
            <ul class="pagination justify-content-center mb-0">
                <li class="page-item" :class="{ disabled: props.modelValue == 0 }" :x-first-page="0">
                    <a class="page-link" aria-label="Start" @click="changePage(0)">
                        <span class="p-paginator-icon pi pi-angle-double-left" aria-hidden="true"></span>
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: props.modelValue == 0 }">
                    <a class="page-link" aria-label="Previous" @click="changePage(page-2)">
                        <span class="p-paginator-icon pi pi-angle-left" aria-hidden="true"></span>
                    </a>
                </li>
                <li class="page-item" v-for="p in currentPages" :class="{ active: page == p }" :key="p">
                    <a class="page-link" @click="changePage(p-1)" v-html="p"></a>
                </li>
                <li class="page-item" :class="{ disabled: parseInt(props.modelValue)+1 >= totalPages }">
                    <a class="page-link" aria-label="Next" @click="changePage(page)">
                        <span class="p-paginator-icon pi pi-angle-right" aria-hidden="true"></span>
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: parseInt(props.modelValue)+1 >= totalPages }" :x-last-page="totalPages-1" v-tooltip="showTotalRecords ? 'Total records: ' + totalRecords : null">
                    <a class="page-link" aria-label="End" @click="changePage(totalPages-1)">
                        <span class="p-paginator-icon pi pi-angle-double-right" aria-hidden="true"></span>
                    </a>
                </li>
            </ul>
        </nav>

    </div>
</template>

<script>
//import { ref, onBeforeMount, onMounted, watch } from 'vue';
import { computed } from 'vue';

export default {
    name: 'PaginationThm',
    emits: ['update:modelValue', 'page'],
    props: {
        modelValue: {
            type: [Number, String],
            default: 0
        },
        totalRecords: {
            type: Number,
            default: 0,
            required: true
        },
        pageSize: {
            type: Number,
            default: 0,
            required: true
        },
        pageLinkSize: {
            type: Number,
            default: 5
        },
        alwaysShow: {
            type: Boolean,
            default: true
        },
        showTotalRecords: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const totalPages = computed(() => {
            //console.log('totalPages', props.totalRecords / props.pageSize);
            return Math.ceil(props.totalRecords / props.pageSize);
        });

        const currentPages = computed(() => {
            let arr = [];
            let maxPages = Math.ceil(props.totalRecords/props.pageSize);

            let pageNumber = parseInt(page.value)+1;
            if(pageNumber < 1) pageNumber = 1;
            if(pageNumber > maxPages) pageNumber = maxPages;

            // Source: https://stackoverflow.com/a/33352604/3799374
            switch(true) {
                case props.totalRecords < 1:
                    return [];
                case pageNumber < props.pageLinkSize:
                    //console.log(1);
                    arr = Array.from({length: props.pageLinkSize}, (_, i) => i + 1).filter(function (val) {
                        //return (val-1) * props.pageSize <= props.totalRecords;
                        return val <= maxPages;
                    });
                    return arr;
                case pageNumber > totalPages.value - parseInt(props.pageLinkSize)+1:
                    //console.log(2);
                    arr = Array.from({length: props.pageLinkSize}, (_, i) => i + parseInt(totalPages.value-props.pageLinkSize+1));
                    return arr;
                default:
                    //console.log(3);
                    return Array.from({length: props.pageLinkSize}, (_, i) => i + parseInt(pageNumber-(props.pageLinkSize/2)));
            }
        });

        const page = computed({
            get() {
                let val = parseInt(props.modelValue);
                if(val < 1) val = 1;

                //let maxPages = (Math.ceil(val/props.pageLinkSize)*props.pageLinkSize)-1;
                let maxPages = Math.ceil(props.totalRecords/props.pageSize);
                if(val > maxPages) val = maxPages;

                return parseInt(props.modelValue)+1;
            },
            set(value) {
                emit('page', { page: parseInt(value) });
                emit('update:modelValue', parseInt(value));
            }
        });

        function changePage(p) {
            page.value = p;
            //emit('update:modelValue', p);
            //emit('page', { page: p });
        }

        return {
            changePage,
            currentPages,
            page,
            props,
            totalPages
        }
    }
}

/**
 * To change this component to have 1-based pages rather than 0-based, undo this:
 * https://townhallmedia.visualstudio.com/thm/_git/thm/commit/5798b87fc42b3641151e7691a68fbc1a9ea6e9bd?refName=refs%2Fheads%2Fmaster
 */
</script>

<style lang="scss" scoped>
div.thm-pagination {
    li.page-item {
        margin-left: 0.1em;
        margin-right: 0.1em;

        &.active {
            & > .page-link {
                &, &:hover {
                    background-color: #E3F2FD;
                }
            }
        }

        &.disabled {
            & > .page-link {
                color: var(--gray-400);
            }

        }

        a.page-link {
            color: var(--gray-600);
            font-size: 1.1em;
            border: none;
            cursor: pointer;
            background-color: unset;

            span.p-paginator-icon {
                padding-top: 5px;
            }

            &:hover {
                background-color: var(--gray-200);
            }
        }
    }
}
</style>
