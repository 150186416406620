<template>
    <form class="needs-validation" novalidate>
        <!-- Filter bar -->
        <div class="row mb-3">
            <div class="col">
                <AuthorsFilterBar />
            </div>
        </div>

        <!-- Main Area -->
        <div class="row mb-3">
            <div class="col-sm-12 col-md-7 col-xl-6">
                <div class="card card-border-color card-border-color-primary shows">
                    <AuthorsList />
                </div>
            </div>

            <div class="col-sm-12 col-md-5 col-xl-6 editor-column">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">
                        <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny float-end" iconPos="right" @click="addNewUser()" title="Add New Author" />
                        {{ route.params.authorId ? 'Edit' : 'Add' }} Author
                    </div>
                    <AuthorsEditor />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { useRoute } from 'vue-router';
import { useAuthorStore } from '@/stores/AuthorStore';
import { useRouter } from 'vue-router';
import AuthorsFilterBar from './AuthorsFilterBar.vue';
import AuthorsList from './AuthorsList.vue';
import AuthorsEditor from './AuthorsEditor.vue';

export default {
    name: 'Authors',
    components: {
        AuthorsFilterBar,
        AuthorsList,
        AuthorsEditor,
    },
    setup() {
        const route = useRoute(), router = useRouter();
        const authorStore = useAuthorStore();

        function addNewUser() {
            authorStore.author = authorStore.newAuthor;
            router.push({ path: `/settings/authors`, query: route.query });
        }

        return {
            addNewUser,
            authorStore,
            route,
        };
    },
};
</script>

<style lang="scss" scoped>
div.editor-column {
    @media (max-width: 767px) {
        margin-top: 1.25em;
    }
}
</style>
