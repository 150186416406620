import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import AlertBarService from '@/service/AlertBarService';
//import { formatDateTimeISO, formatDateTime } from '@/utils/ThmDateTimeUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';

const alertBarService = new AlertBarService();
const adminService = new AdminService();

export const useAlertBarStore = defineStore('AlertBar', {
    state: () => ({
        loading: false,
        alert: {},
        original: {}
    }),
    actions: {
        async load() {
            this.loading = true;
            await alertBarService.getAlert().then((res) => {
                //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Alert Bar', res);
                //this.alert = this.original = res;
                this.alert = res;
                this.original = { ...res, startDate: formatDateTime(res.startDate) };
                //this.alert.startDate = formatDateTimeET(this.alert.startDate);
                this.alert.startDate = formatDateTime(this.alert.startDate);
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async save() {
            this.loading = true;
            //let alert = { ...this.alert, startDate: formatDateTimeISO(this.alert.startDate) };
            let alert = { ...this.alert, startDate: formatDateTime(this.alert.startDate, 'yyyy-MM-dd HH:mm:ss') };
            await alertBarService.updateAlert(alert).then((res) => {
                //if(process.env.NODE_ENV !== 'production') console.log('📘 PUT Alert Bar', res);
                let savedResult = { ...res, startDate: formatDateTime(res.startDate) };
                adminService.log({ logTypeId: 7, referenceId: res.id, message: 'Saved', content: JSON.stringify(savedResult, null, 2), original: JSON.stringify(this.original, null, 2), language: 'json' });

                //this.alert = this.original = res;
                this.alert = res;
                this.original = { ...res, startDate: formatDateTime(res.startDate) };
                //this.alert.startDate = formatDateTimeET(this.alert.startDate);
                this.alert.startDate = formatDateTime(this.alert.startDate);
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        }
    }
});
