<template>
    <div class="card card-border-color card-border-color-primary">
        <div class="card-header card-header-divider">
            VIP Revenue
            <SpinnerThm class="spinner" size="1em" v-if="loading" />
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-auto mb-3">
                    <DataTable :value="dailyTransations" ref="dt" tableStyle="max-width: 25rem" tableClass="daily-transactions-report">
                        <Column field="day" header="Day">
                            <template #body="props">
                                {{ formatDateTime(props.data.date, 'MM/dd/yyyy') }}
                            </template>
                        </Column>
                        <Column field="newAmount" header="New Amount" class="text-center">
                            <template #body="props">
                                {{ formatCurrency(props.data.newAmount) }}
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <div class="col-auto">
                    <DataTable :value="weeklySiteReport" ref="wt" x-tableStyle="max-width: 50rem" tableClass="weekly-site-report">
                        <Column header="Week">
                            <template #body="props">
                                {{ formatDateTime(props.data.weekStartDate, 'MM/dd/yyyy') }}
                                &ndash;
                                {{ formatDateTime(props.data.weekEndDate, 'MM/dd/yyyy') }}
                            </template>
                        </Column>
                        <Column field="totalNewAmount" header="Total New Amount" class="text-center">
                            <template #body="props">
                                {{ formatCurrency(props.data.totalNewAmount) }}
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <div class="col-auto">
                    <DataTable :value="monthlySiteReport" ref="mt" tableClass="monthly-site-report">
                        <Column header="Month">
                            <template #body="props">
                                {{ formatDateTime(props.data.monthStartDate, 'MM/dd/yyyy') }}
                                &ndash;
                                {{ formatDateTime(props.data.monthEndDate, 'MM/dd/yyyy') }}
                            </template>
                        </Column>
                        <Column field="totalNewAmount" header="Total New Amount" class="text-center">
                            <template #body="props">
                                {{ formatCurrency(props.data.totalNewAmount) }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
//import { ref, computed, inject, onMounted, reactive, watch } from 'vue';
import { ref, onMounted } from 'vue';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { formatCurrency } from '@/utils/ThmStringUtils';
import ReportService from '@/service/ReportService';
import SpinnerThm from '@/components/SpinnerThm.vue';
// import ColumnGroup from 'primevue/columngroup';
// import Row from 'primevue/row';


export default {
    name: 'VipRevenueReport',
    components: {
        SpinnerThm,
    },
    setup() {
        const reportService = new ReportService();

        const dailyTransations = ref([]);
        const weeklySiteReport = ref([]);
        const monthlySiteReport = ref([]);
        const loading = ref(false);

        onMounted(async () => {
            dailyTransations.value = await reportService.getDailyTransactions();
            weeklySiteReport.value = await reportService.getWeeklySiteReport();
            monthlySiteReport.value = await reportService.getMonthlySiteReport();
        });

        return {
            dailyTransations,
            formatCurrency,
            formatDateTime,
            loading,
            monthlySiteReport,
            weeklySiteReport,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
table.daily-transactions-report, table.weekly-site-report, table.monthly-site-report {
    th.text-center {
        .p-column-title {
            text-align: center;
            width: 100%;
        }
    }
}
</style>
