<template>
    <draggable class="drag-area" tag="ul" :list="activeMenu" :group="{ name: 'g1' }" item-key="url" v-if="activeMenu">
        <template #item="{ element }">
            <li>
                <div class="item-container">
                    <i
                        class="far fa-chevron-down float-end expand-item"
                        @click="
                            expand;
                            element.expanded = !element.expanded;
                        "
                    ></i>
                    <i class="far fa-bars pe-2"></i> {{ element.label }}

                    <form class="item-fields form-inline" role="form" v-show="element.expanded">
                        <div class="row my-1">
                            <label for="Label" class="col-sm-2 col-form-label text-end">Label</label>
                            <div class="col-sm-10">
                                <InputText type="text" class="form-control" v-model="element.label" id="Label" />
                            </div>
                        </div>

                        <div class="row my-1">
                            <label for="Url" class="col-sm-2 col-form-label text-end">URL / Path</label>
                            <div class="col-sm-10">
                                <InputText type="text" class="form-control" v-model="element.url" id="Url" />
                            </div>
                        </div>

                        <div class="row my-1">
                            <label for="Classes" class="col-sm-2 col-form-label text-end">CSS Classes</label>
                            <div class="col-sm-10">
                                <InputText type="text" class="form-control" v-model="element.classes" id="Classes" />
                            </div>
                        </div>

                        <div class="row my-1">
                            <label for="Icon" class="col-sm-2 col-form-label text-end">Icon</label>
                            <div class="col-sm-10">
                                <InputText type="text" class="form-control" v-model="element.icon" id="Icon" />
                            </div>
                        </div>

                        <div class="row mt-1">
                            <label for="Classes" class="col-sm-2 col-form-label switch-label text-end">Open in New Tab</label>
                            <div class="col-sm-10">
                                <a class="px-2 pt-2 float-end delete-icon" @click="deleteItem(element)"><i class="far fa-trash"></i></a>
                                <InputSwitch v-model="element.target" id="Target" />
                            </div>
                        </div>
                    </form>
                </div>
                <!--<menu-draggable :menu="element.menu" />-->
                <!--<menu-draggable :menu="element.menu" @menu-changed="menuChanged" />-->
                <!--<menu-draggable :menu="element.menu" @menu-changed="menuChanged" @delete-item="deleteItem(element)" />-->
                <!--<menu-draggable :menu="element.menu" @change="removed" />-->
                <menu-draggable :menu="element.menu" @menu-change="menuChange" />
            </li>
        </template>
    </draggable>
</template>
<script>
import draggable from 'vuedraggable';

// Source: https://github.com/SortableJS/vue.draggable.next/blob/master/example/components/nested/nested-test.vue
export default {
    name: 'menu-draggable',
    data() {
        return {
            activeMenu: this.menu,
        };
    },
    props: {
        menu: { type: Array },
    },
    components: {
        draggable,
    },
    mounted() {},
    methods: {
        expand() {
            console.log('Expand');
        },
        deleteItem(item) {
            this.activeMenu = this.activeMenu.filter((obj) => {
                return obj.label !== item.label && obj.url !== item.url;
            });
            this.$emit('menu-change', this.activeMenu);
        },
        menuChange(obj) {
            console.log('[MC] menu:', obj);
            //this.activeMenu.menu = obj;
            this.$emit('menu-change', this.activeMenu);
        },
    },
    watch: {
        menu: function () {
            this.activeMenu = this.menu;
        },
        activeMenu: function () {
            this.$emit('menu-change', this.activeMenu);
        },
    },
};
</script>

<style lang="scss" scoped>
.drag-area {
    padding-left: 0;
    background-color: #fff;
    border-top: 1px solid var(--gray-400);
    //max-width: 700px;

    div.item-container {
        margin-bottom: 0;
        padding: 0.5em;
        cursor: pointer;
        border: 1px solid var(--gray-400);

        .fa-bars {
            cursor: move;
        }
    }

    ul {
        padding-left: 2rem;
        border-top: none;
        //max-width: unset;
    }

    li {
        background-color: var(--gray-200);
        list-style: none;

        div.item-container {
            border-top: none;
        }
    }

    .expand-item {
        width: 24px;
        height: 26px;
        padding: 5px;
    }
    .delete-icon {
        color: unset;
    }
}
</style>
