<template>
    <div class="col-xs-12 col-sm-6 col-lg-3">
        <div class="card lbcard lbcard__large mb-3">
            <router-link :to="{ name: 'LiveBlogThread', params: { postId: props.post.postId } }">
                <router-link :to="{ name: 'LiveBlogPost', params: { postId: props.post.postId } }" class="lb-icon-settings pi pi-cog" v-show="userHasAccessToRole('Admin')"></router-link>
                <span class="badge bg-danger d-inline-block position-absolute ms-2 my-2" v-if="isLiveblogPostLive(post)">Live</span>
                <img :src="resizeImageUrl(mainImage, '526x310')" class="card-img-top" alt="...">
            </router-link>
            <div class="card-body mb-2">
                <h5 class="card-title lbcard__title mb-1">
                    <router-link :to="{ name: 'LiveBlogThread', params: { postId: props.post.postId } }">
                        {{ props.post.title }}
                    </router-link>
                </h5>
                <div class="card-text lbcard__date">
                    {{ formatDateTime(props.post.datePublished, "MMMM do, y 'at' h:mm aaa ") }} {{ getTimezone() }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import { ref, computed, inject, onMounted } from 'vue';
import { computed } from 'vue';
import { formatDateTime, getTimezone } from '@/utils/ThmDateTimeUtils';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { isLiveblogPostLive } from '@/utils/ThmLiveBlogUtils';
import { resizeImageUrl } from '@/utils/ThmImageUtils';

export default {
    name: 'LiveBlog_Post',
    props: {
        post: {
            type: Object,
            required: true
        },
        index: Number,
    },
    components: {
    },
    setup(props) {

        const mainImage = computed(() => {
            if(!props.post?.imageObject) return 'https://fakeimg.pl/526x310/cccccc/909090?text=No+Image&font=bebas';
            //return props.post?.imageObject?.includes('https://') ? props.post.imageObject : `https://media.townhall.com/cdn/hodl/${props.post.imageObject}`
            return props.post.imageObject;
        });

        return {
            formatDateTime,
            getTimezone,
            isLiveblogPostLive,
            mainImage,
            props,
            resizeImageUrl,
            userHasAccessToRole,
        };
    },
};
</script>
