import BaseService from './BaseService';

export default class MenuService extends BaseService {
    async getMenus() {
        return await this.apiClient.get('/api/menu').then((res) => res.data);
    }

    async getMenuById(menuId) {
        return await this.apiClient.get(`/api/menu/${menuId}`).then((res) => res.data);
    }

    async saveMenu(menu) {
        return await this.apiClient.post('/api/menu', menu).then((res) => res.data);
    }

    async deleteMenu(menuId) {
        return await this.apiClient.delete(`/api/menu/${menuId}`).then((res) => res.data);
    }
}
