<template>
    <div></div>
</template>

<script>
//Import third party plugins
// Froala plugins: https://froala.com/wysiwyg-editor/docs/plugins/
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
//import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/track_changes.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import '@/utils/froala/plugins/BridVideo.js';
import '@/utils/froala/plugins/EmbedCode.js';
import '@/utils/froala/plugins/ImageManager.js';

// Syntax highlighting requires CodeMirror: https://thiagoborg.es/blog/2018/08/30/syntax-highlight-on-froala-editor-using-webpacker/
import CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/lib/codemirror.css';

// Embed.ly: https://froala.com/wysiwyg-editor/examples/embedly/
/*
import '/node_modules/froala-editor/js/third_party/embedly.min.js';
import '/node_modules/froala-editor/css/third_party/embedly.min.css';
*/

import FroalaEditor from 'froala-editor';
export default {
    name: 'FroalaOld',
    props: [
        'tag',
        'modelValue',
        'config', // Use :config to REPLACE defaultConfig
        'options', // Use :options to ADD TO/CHANGE defaultConfig
        'onManualControllerReady',
        'id'
    ],
    emits: ['update:modelValue'],
    inject: ['appSettings'],
    components: {
    },
    data() {
        return {
            initEvents: [],

            // Tag on which the editor is initialized.
            currentTag: 'div',

            // Editor element.
            editor: null,

            // Current config.
            currentConfig: null,

            // Editor options config
            defaultConfig: {

                containerId: this.id,
                immediateVueModelUpdate: false,
                vueIgnoreAttrs: null,
                codeMirror: CodeMirror,
                htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'script', '.fa', 'brid'],
                htmlRemoveTags: ['base', 'html', 'style'],
                charCounterCount: false,
                htmlExecuteScripts: false,
                htmlUntouched: false,
                imagePaste: false,
                imagePasteProcess: false,
                linkAlwaysBlank: true,
                heightMin: 200,
                toolbarSticky: true,
                toolbarStickyOffset: 50,
                videoMove: true,
                videoResize: false,
                wordPasteModal: true,
                //pastePlain: true,
                pasteDeniedAttrs: ['style'],
                toolbarButtons: ['html', 'bold', 'italic', 'quote', 'undo', 'redo', 'paragraphFormat', 'formatOLSimple', 'formatUL', 'align', 'insertLink', 'embedCodeButton', 'imageManagerButton', 'insertVideo', 'embedBridVideoButton', 'fontAwesome', 'clearFormatting', 'fullscreen', 'help'], // Others: paragraphFormat, OL, UL, socialEmbed, insertImage, insertVideo, fullscreen, 'embedly'
                //pluginsEnabled: ['embedCodePlugin', 'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI', 'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'] // List: https://froala.com/wysiwyg-editor/docs/options/#pluginsEnabled
                //iconsTemplate: 'font_awesome_5r'
                events: {
                    'link.beforeInsert': function (link, text, attrs) {
                        if(console.log.hide) console.log(link, text, attrs);
                        if(!attrs.title) attrs.title = link;
                    }
                }
            },

            editorInitialized: false,

            SPECIAL_TAGS: ['img', 'button', 'input', 'a'],
            INNER_HTML_ATTR: 'innerHTML',
            hasSpecialTag: false,

            model: null,
            oldModel: null,
        };
    },
    watch: {
        modelValue() {
            this.model = this.modelValue;
            this.updateValue();
        },
    },

    render(createElement) {
        return createElement(this.currentTag, [this.$slots().default]);
    },

    created() {
        this.currentTag = this.tag || this.currentTag;
        this.model = this.modelValue;
        //console.log('value', this.modelValue)
    },

    // After first time render.
    mounted() {
        if (this.SPECIAL_TAGS.indexOf(this.currentTag) != -1) {
            this.hasSpecialTag = true;
        }

        if (this.onManualControllerReady) {
            this.generateManualController();
        } else {
            this.createEditor();
        }
    },
    beforeMount() {
        this.destroyEditor();
    },

    methods: {
        updateValue() {
            if (JSON.stringify(this.oldModel) == JSON.stringify(this.model)) {
                return;
            }

            this.setContent();
        },

        toggleCodeView() {
            if(this.editor) {
                const isCodeViewActive = this.editor.codeView.isActive();
                if(isCodeViewActive) {
                    console.log(3333);
                    this.editor.codeView.toggle();
                }
            }
        },

        createEditor() {
            if (this.editorInitialized) {
                return;
            }

            this.currentConfig = this.clone(this.config || this.defaultConfig);
            if(this.options) this.currentConfig = { ...this.currentConfig, ...this.options };
            this.currentConfig.key = this.appSettings.keys.froala;
            this.setContent(true);

            // Bind editor events.
            this.registerEvents();
            this.initListeners();

            this.editor = new FroalaEditor(this.$el, this.currentConfig);

            this.editorInitialized = true;
        },

        // Return clone object
        clone(item) {
            const me = this;
            if (!item) {
                return item;
            } // null, undefined values check

            let types = [Number, String, Boolean],
                result;

            // normalizing primitives if someone did new String('aaa'), or new Number('444');
            types.forEach(function (type) {
                if (item instanceof type) {
                    result = type(item);
                }
            });

            if (typeof result == 'undefined') {
                if (Object.prototype.toString.call(item) === '[object Array]') {
                    result = [];
                    item.forEach(function (child, index) {
                        result[index] = me.clone(child);
                    });
                } else if (typeof item == 'object') {
                    // testing that this is DOM
                    if (item.nodeType && typeof item.cloneNode == 'function') {
                        result = item.cloneNode(true);
                    } else if (!item.prototype) {
                        // check that this is a literal
                        if (item instanceof Date) {
                            result = new Date(item);
                        } else {
                            // it is an object literal
                            result = {};
                            for (var i in item) {
                                result[i] = me.clone(item[i]);
                            }
                        }
                    } else {
                        //if (false && item.constructor) {
                        if (item.constructor) {
                            result = new item.constructor();
                        } else {
                            result = item;
                        }
                    }
                } else {
                    result = item;
                }
            }
            return result;
        },

        setContent(firstTime) {
            if (!this.editorInitialized && !firstTime) {
                return;
            }
            //pointless if statement removed for null model
            // if (this.model || this.model == '') {
            //     this.oldModel = this.model

            //     if (this.hasSpecialTag) {
            //         this.setSpecialTagContent()
            //     } else {
            //         this.setNormalTagContent(firstTime)
            //     }
            // }

            this.oldModel = this.model;

            if (this.hasSpecialTag) {
                this.setSpecialTagContent();
            } else {
                this.setNormalTagContent(firstTime);
            }
        },

        setNormalTagContent(firstTime) {
            var self = this;

            function htmlSet() {
                // Check if editor not null
                if (self.editor == null) {
                    return;
                }

                if (self.editor.html != undefined) {
                    self.editor.html.set(self.model || '');
                }

                //This will reset the undo stack everytime the model changes externally. Can we fix this?

                if (self.editor.undo != undefined) {
                    self.editor.undo.saveStep();
                    self.editor.undo.reset();
                }
            }

            if (firstTime) {
                this.registerEvent('initialized', function () {
                    htmlSet();
                });
            } else {
                htmlSet();
            }
        },

        setSpecialTagContent() {
            var tags = this.model;

            // add tags on element
            if (tags) {
                for (var attr in tags) {
                    //tags.hasOwnProperty(attr) &&
                    if (Object.prototype.hasOwnProperty.call(tags, attr) && attr != this.INNER_HTML_ATTR) {
                        this.$el.setAttribute(attr, tags[attr]);
                    }
                }
                //tags.hasOwnProperty(this.INNER_HTML_ATTR)
                if (Object.prototype.hasOwnProperty.call(tags, this.INNER_HTML_ATTR)) {
                    this.$el.innerHTML = tags[this.INNER_HTML_ATTR];
                }
            }
        },

        destroyEditor() {
            this.initEvents = [];

            if (this.editor) {
                this.editor.destroy();
                this.editorInitialized = false;
                this.editor = null;
            }
        },

        getEditor() {
            return this.editor;
        },

        generateManualController() {
            var controls = {
                initialize: this.createEditor,
                destroy: this.destroyEditor,
                getEditor: this.getEditor,
            };

            this.onManualControllerReady(controls);
        },

        updateModel() {
            var modelContent = '';

            if (this.hasSpecialTag) {
                var attributeNodes = this.$el[0].attributes;
                var attrs = {};

                for (var i = 0; i < attributeNodes.length; i++) {
                    var attrName = attributeNodes[i].name;
                    if (this.currentConfig.vueIgnoreAttrs && this.currentConfig.vueIgnoreAttrs.indexOf(attrName) != -1) {
                        continue;
                    }
                    attrs[attrName] = attributeNodes[i].value;
                }

                if (this.$el[0].innerHTML) {
                    attrs[this.INNER_HTML_ATTR] = this.$el[0].innerHTML;
                }

                modelContent = attrs;
            } else {
                var returnedHtml = this.editor.html.get();
                if (typeof returnedHtml === 'string') {
                    modelContent = returnedHtml;
                }
            }

            this.oldModel = modelContent;
            this.$emit('update:modelValue', modelContent);
        },

        initListeners() {
            var self = this;

            this.registerEvent('initialized', function () {
                // Editor initialized
                self.editorInitialized = true;

                // Check if editor not null and editor has events
                if (self.editor != null && self.editor.events) {
                    // bind contentChange and keyup event to froalaModel
                    self.editor.events.on('contentChanged', function () {
                        self.updateModel();
                    });

                    if (self.currentConfig.immediateVueModelUpdate) {
                        self.editor.events.on('keyup', function () {
                            self.updateModel();
                        });
                    }
                }
            });
        },

        // register event on editor element
        registerEvent(eventName, callback) {
            if (!eventName || !callback) {
                return;
            }

            // Initialized event.
            if (eventName == 'initialized') {
                this.initEvents.push(callback);
            } else {
                if (!this.currentConfig.events) {
                    this.currentConfig.events = {};
                }

                this.currentConfig.events[eventName] = callback;
            }
        },

        registerEvents() {
            // Handle initialized on its own.
            this.registerInitialized();

            // Get current events.
            var events = this.currentConfig.events;

            if (!events) {
                return;
            }

            for (var event in events) {
                //events.hasOwnProperty(event) &&
                //events.hasOwnProperty(event)
                if (Object.prototype.hasOwnProperty.call(events, event) && event != 'initialized') {
                    this.registerEvent(event, events[event]);
                }
            }
        },

        registerInitialized() {
            // Bind initialized.
            if (!this.currentConfig.events) {
                this.currentConfig.events = {};
            }

            // Set original initialized event.
            if (this.currentConfig.events.initialized) {
                this.registerEvent('initialized', this.currentConfig.events.initialized);
            }

            // Bind initialized event.
            this.currentConfig.events.initialized = () => {
                for (var i = 0; i < this.initEvents.length; i++) {
                    this.initEvents[i].call(this.editor);
                }
            };
        },
    },
};
</script>
