<template>

    <div class="row" id="tha">
        <div class="col-sm-12 col-md-6">
            <ArticleSources :addExternal="false" :queueSupport="false" :store="editorsChoiceStore" />
        </div>

        <LiveQueue :queueSupport="false" />
    </div>
</template>

<script>

import { useEditorsChoiceStore } from '@/stores/EditorsChoiceStore';
import LiveQueue from './LiveQueue.vue';
import ArticleSources from '@/pages/manage/home/ArticleSources.vue';

export default {
    name: 'EditorsChoice',
    components: {
        ArticleSources,
        LiveQueue
    },
    setup() {
        const editorsChoiceStore = useEditorsChoiceStore();

        return {
            editorsChoiceStore
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
