import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
//import { useAuth0 } from '@auth0/auth0-vue';
import { auth0 } from '@/main.js';

export const useSiteStore = defineStore('Sites', {
    state: () => ({
        build: null,
        loading: false,
        sites: [],
        site: {},
        accessToken: null
    }),
    actions: {
        async load(reload = false) {
            const adminService = new AdminService();

            // Get site list
            if (reload || !this.sites.length) {
                this.loading = true;
                adminService
                    .getSites()
                    .then((res) => {
                        //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Sites', res);
                        this.sites = res;
                    })
                    .catch((error) => {
                        if(console.log.hide) console.log({error});
                        //console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

            // Get build/version
            return this.getBuild(reload).then((res) => {
                this.build = res;
                return this.build;
            });
        },
        setSite(siteId) {
            this.site = this.sites.find((obj) => obj.siteId == siteId);
        },
        getSite(siteId) {
            return this.sites.find((obj) => obj.siteId == siteId);
        },
        async getAccessToken() {
            /*
            const { getAccessTokenSilently } = useAuth0();
            let token = await getAccessTokenSilently();
            */
            let token = await auth0.getAccessTokenSilently();
            this.accessToken = token;
            return this.accessToken;
        },
        async getBuild(reload = false) {
            const adminService = new AdminService();
            if (reload || !this.build) {
                return adminService.getVersion().then((res) => {
                    this.build = res;
                    return res;
                }).catch((err) => {
                    console.error('Error loading build:', err.message);
                    //if(err.message.includes('Login required')) auth0.logout({ logoutParams: { returnTo: window.location.origin } });
                });
            }
            return this.build;
        }
    },
});
