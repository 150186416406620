<template>
    <div class="card-body">
        <ConfirmDialog />

        <div class="mb-3">
            <label for="Label" class="form-label required">Label</label>
            <InputText type="text" class="form-control" v-model="badge.label" id="Label" />
        </div>

        <div class="mb-3">
            <label for="TextColor" class="form-label required">Foreground Text Color</label>
            <InputText type="text" class="form-control" v-model="badge.textColor" id="TextColor" />
            <div class="help">The foregroun color of the badge label.</div>
        </div>

        <div class="mb-3">
            <label for="Colors" class="form-label d-block required">Colors</label>
            <ColorRepeater v-model="badgeColors" id="Colors" />
        </div>

        <div class="mb-3">
            <label for="DefaultColor" class="form-label d-block required">Default Background Color</label>
            <Dropdown v-model="badge.defaultColor" :options="badgeColors" optionLabel="name" optionValue="hexCode" placeholder="Select a Color" id="DefaultColor" />
        </div>

        <div class="mb-3">
            <label for="ExpireAfterHours" class="form-label">Default Expire After Hours</label>
            <InputText type="text" class="form-control" v-model="badge.expireAfterHours" id="TextColor" />
            <div class="help">The foregroun color of the badge label.</div>
        </div>

        <div class="mb-3">
            <InputSwitch v-model="badge.isActive" id="isPublished" />
            <label for="isPublished" class="switch-label">Enabled</label>
            <div class="help d-none">If disabled, the badge won't appear as an option in the post editor.</div>
        </div>

        <p>
            <Button :label="badge.badgeId ? 'Save' : 'Add Badge'" class="me-2" @click="save()" />
            <Button label="Delete" severity="danger" class="btn-delete" v-show="badge.badgeId" @click="deleteBadge()" />
            <LogViewerThm label="Change Log" :filter="{ logTypeId: 9, referenceId: badge.badgeId }" v-if="badge.badgeId" class="p-button-secondary p-button-outlined ms-2" tag="button" />
        </p>
    </div>

</template>

<script>
import { ref, onBeforeMount, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import ConfirmDialog from 'primevue/confirmdialog';
import ColorRepeater from './ColorRepeater.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import AdminService from '@/service/AdminService';
import BadgeService from '@/service/BadgeService';
import useAuth0 from '@/use/Auth0';
import { uuid } from 'vue-uuid';

export default {
    name: 'BadgeEditor',
    components: {
        ColorRepeater,
        ConfirmDialog,
        LogViewerThm,
    },
    setup() {
        const badgeService = new BadgeService();
        const adminService = new AdminService();
        const confirm = useConfirm();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const { user } = useAuth0();

        const badge = ref({});
        const newBadge = {
            colors: [],
            //textColor: '#FFFFFF',
            isActive: true,
            expireHours: 0
        };

        const badgeColors = computed({
            get() {
                if(!badge.value.colors || !badge.value.colors.length) return [];
                return badge.value.colors.map(function(color){
                    return { uuid: uuid.v4(), ...color, name: `${color.label} (${color.hexCode})` };
                });
            },
            set(value) {
                badge.value.colors = value;
            }
        });

        // Watch for route changes
        watch(
            () => route.params, (params, fromParams) => {
                //console.log('[watch] BadgeEditor');
                if(console.log.hide) console.log({ from: fromParams, to: params });
                if(params.id != fromParams.id) {
                    if(params.id) {
                        badgeService.getBadge(params.id).then((res) => {
                            badge.value = res;
                        }).catch((err) => {
                            toast.add({ severity: 'error', summary: 'Error Fetching Badge', detail: err.message || err, life: 3000 });
                        });
                    } else {
                        badge.value = newBadge;
                    }
                }
            }
        );

        onBeforeMount(() => {
            if(route.params.id) {
                badgeService.getBadge(route.params.id).then((res) => {
                    if(!Object.keys(res).length) {
                        toast.add({ severity: 'error', summary: '404 Not Found', detail: 'Badge not found', life: 3000 });
                    }
                    badge.value = res;
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Badge', detail: err.message || err, life: 3000 });
                });
            } else {
                badge.value = newBadge;
            }
        });

        function deleteBadge() {
            if(!badge.value.badgeId) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this badge?',
                header: 'Delete Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    badgeService.deleteBadge(badge.value.badgeId).then(() => {
                        adminService.log({ logTypeId: 9, referenceId: badge.value.badgeId, message: 'Deleted: ' + badge.value.label, content: JSON.stringify(badge.value, null, 2), original: '', language: 'json' });
                        router.push({ path: '/settings/badges', query: route.query });
                    })
                    .catch((err) => {
                        toast.add({ severity: 'error', summary: 'Error Deleting Badge', detail: err.message || err, life: 3000 });
                    });
                },
            });
        }

        function save() {
            // Save user; if slug already exists, increment it
            let isNew = !badge.value.badgeId;

            var requiredFields = [badge.value.label, badge.value.textColor, badge.value.colors, badge.value.defaultColor], isFormInvalid = false;
            requiredFields.forEach((elm) => {
                if (!elm) isFormInvalid = true;
            });
            if(isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            badgeService.getBadge(badge.value.badgeId).then((original) => {
                // get original
                badgeService.saveBadge(badge.value).then((res) => {
                    router.push(`/settings/badges/${res.badgeId}`);
                    adminService.log({ logTypeId: 18, referenceId: res.badgeId, message: (isNew ? 'Added: ' : 'Modified: ') + badge.value.name, content: JSON.stringify(res, null, 2), original: isNew ? '' : JSON.stringify(original, null, 2), language: 'json' });
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Badge Saved', life: 3000 });
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving Badge', detail: err.message || err, life: 3000 });
                });
            });
        }

        return {
            badgeColors,
            deleteBadge,
            save,
            badge,
            user
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    label {
        font-weight: 500;
    }
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
}
</style>
