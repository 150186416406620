<template>
    <div class="thm-poststats-component">
        <div v-if="props.modelValue && props.modelValue.words > 0">
            <p>
                You have <strong>{{ props.modelValue.nextAd }}</strong> characters until the <strong>{{ props.modelValue.adDensity + 1 }}{{ abbreviateNumber(props.modelValue.adDensity + 1) }} ad</strong> is placed.
            </p>
            <ul class="list-inline m-0 mb-2">
                <li class="list-inline-item"><strong>Ad Density:</strong> {{ props.modelValue.adDensity }}</li>
                <li class="list-inline-item">&nbsp;&nbsp;<strong>Words:</strong> {{ props.modelValue.words }}</li>
                <li class="list-inline-item">&nbsp;&nbsp;<strong>Characters:</strong> {{ props.modelValue.characters }}</li>
            </ul>
        </div>
        <div v-else>
            <p>No post stats</p>
        </div>
    </div>
</template>

<script>
import { abbreviateNumber } from '@/utils/ThmStringUtils';

export default {
    name: 'PostStats',
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        return {
            abbreviateNumber,
            props
        };
    }
}
</script>
