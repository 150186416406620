import AppSettings from '/appsettings.json';
//import { inject } from 'vue';
import { differenceInDays, format, formatISO, intervalToDuration, isBefore, formatDistance, parseISO, startOfToday, sub, subHours } from 'date-fns';
import { formatInTimeZone, fromZonedTime, toZonedTime } from 'date-fns-tz';
import { useTimeAgo } from '@vueuse/core';

export function formatDateTime(dateString = null, fmt = 'MM/dd/yyyy hh:mm a') {
    // Local time
    try {
        dateString = dateString ? Date.parse(dateString) : new Date();
        return format(dateString, fmt);
    } catch (error) {
        console.log('formatDateTime() error: ' + dateString, error);
        return null;
    }
}

export function formatDateTimeET(dateString = null, fmt = 'MM/dd/yyyy hh:mm a') {
    // Eastern time
    try {
        dateString = dateString ? Date.parse(dateString) : new Date();
        //return formatInTimeZone(dateString, inject('appSettings').timezone, fmt);
        return formatInTimeZone(dateString, AppSettings.timezone, fmt);
    } catch (error) {
        console.log('formatDateTimeET() error: ' + dateString, error);
        return null;
    }
}

// See the following for valid 'formatType' values ('date', 'time', 'complete' = default): https://date-fns.org/v2.28.0/docs/formatISO
export function formatDateTimeISO(dateString, formatType = null) {
    try {
        // Format date as ISO 8601
        dateString = dateString ? Date.parse(dateString) : new Date();
        return formatISO(dateString, { representation: formatType })
    } catch (error) {
        console.log('formatDateTimeISO() error: ' + dateString, error);
        return null;
    }
}

// Format date/time for Microsoft SQL Server, cutting off time offset
export function formatDateTimeSQL(dateString = null, fmt = 'yyyy-MM-dd HH:mm:ss') {
    return formatDateTime(dateString, fmt);
}

export function getDateTime(dateString = null) {
    return formatDateTime(dateString);
}

export function getTimezone(dateTimeString = null) {
    // Returns: "EDT" or "EST"
    try {
        dateTimeString = dateTimeString || new Date();
        //return formatInTimeZone(dateTimeString, inject('appSettings').timezone, 'zzz');
        return formatInTimeZone(dateTimeString, AppSettings.timezone, 'zzz');
    } catch (error) {
        console.log('getTimezone() error: ' + dateTimeString, error);
        return null;
    }
}

export function isEmptyDate(dateString) {
    // Returns true if date is empty or before 01/01/1900, such as "0001-01-01T00:00:00"
    try {
        if(!dateString) return true;
        dateString = dateString ? Date.parse(dateString) : new Date();
        return isBefore(dateString, new Date(1900, 1, 1));
    } catch (error) {
        console.log('isEmptyDate() error: ' + dateString, error);
        return null;
    }
}

export function timeAgo(dateTimeString, fmt = 'MM/dd/yyyy hh:mm a', daysAgo = false) {

    if(console.log.hide) console.log(fmt, daysAgo,startOfToday,differenceInDays);
    // Just return the formatted date if more than X days ago
    if(daysAgo !== true && !isNaN(daysAgo)) {
        let today = startOfToday();
        let diff = differenceInDays(today, Date.parse(dateTimeString));
        if(diff >= daysAgo) return formatDateTime(dateTimeString, fmt);
    }

    let distance = formatDistance(
        new Date(dateTimeString),
        new Date(),
        { addSuffix: true }
    );

    return distance.replace('about ', ''); // Remove "about" from beginning of string
}

/**
 * Converts seconds (float) to duration object or string
 * @param {Number} duration The duration to convert, represented as a float
 * @param {bool} asHMS If false, returns duration object. If true, returns string in HH:MM:SS format
 * @example
 * // returns 01:08:09
 * secondsToDuration( 4089.057143, true );
 */
export function secondsToDuration(seconds, asHMS = false) {
    let duration = intervalToDuration({ start: 0, end: seconds * 1000 });

    if(asHMS) { // Convert to HH:MM:SS string
        let hours = duration.hours.toString().padStart(2, '0');
        let minutes = duration.minutes.toString().padStart(2, '0');
        let seconds = duration.seconds.toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }
    return duration;
}

/**
 * Determines if specified date is in the future
 * @param {Date} date The date to check
 * @param {Bool} easternTime If true, uses local time zone rather than Eastern
 * @example
 * // returns true
 * isDateInFuture( '08/16/2030 01:15 PM' );
 */
export function isDateInFuture(date, localTime = true) {
    let _now = localTime ? parseISO(formatDateTime(null, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")) : parseISO(formatDateTimeET(null, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
    return isBefore(_now, parseISO(date));
}

/**
 * Get a date/time string from specified hours ago
 * @param {Number} hours Number of hours to go back
 * @example
 * // Returns date/stime string of 28 hours ago
 * let date48HoursAgo = getDateTimeHoursAgo(48);
 */
export function getDateTimeHoursAgo(hours) {
    const now = new Date();
    const pastDate = sub(now, { hours });
    return pastDate;
}

/**
 * Get a date/time string from specified days ago
 * @param {Number} days Number of days to go back
 * @example
 * // Returns date/stime string of 7days ago
 * let date7daysAgo = getDateTimeDaysAgo(7);
 */
export function getDateTimeDaysAgo(days) {
    const now = new Date();
    const pastDate = sub(now, { days });
    return pastDate;
}

/**
 * Check if a date is within the last 24 hours
 * @param {Date} dt The input date/time
 * @example
 * // Returns true
 * let isWithinLast24Hours = isWithinLast24Hours(new Date());
 */
export function isWithinLast24Hours(dt) {
    let givenDate = parseISO(dt);
    let now = new Date();

    // Calculate the date 24 hours ago from now
    let twentyFourHoursAgo = subHours(now, 24);

    // Check if the given date is within the interval
    //return isWithinInterval(givenDate, { start: twentyFourHoursAgo, end: now });
    return givenDate >= twentyFourHoursAgo;
}

/**
 * Convert an Eastern Time (from the database) to local time
 * @param {String} easternTimeString The eastern date/time string to convert
 * @example
 * // Returns '2024-08-05 10:25:00.000' if the user's time zone is Central, for example
 * let localDateTime = easternDateTimeToLocal('2024-08-05 11:25:00.000');
 */
export function easternDateTimeToLocal(easternTimeString) {
    if(console.log.hide) console.log(fromZonedTime, toZonedTime)
    let easternTimeZone = 'America/New_York';
    let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let easternDate = fromZonedTime(easternTimeString, easternTimeZone);
    let localDate = toZonedTime(easternDate, localTimeZone);
    return localDate;
}

/**
 * Calculate moment (ie, time ago) based on provided eastern date/time, adjusted for locale
 * @param {Date} dt The input date/time
 * @param {String} fmt The default date/time string to return if not within last 24 hours
 * @example
 * // Returns "Just now"
 * let momentInTime = dateTimeMoment(new Date());
 */
export function dateTimeMoment(dt, fmt = 'MMMM d, y h:mm a') {
    if(isWithinLast24Hours(dt)) {
        let moment = useTimeAgo(easternDateTimeToLocal(dt));
        return moment.value;
    }
    return formatDateTime(dt, fmt);
}

export default {
    formatDateTimeISO,
    formatDateTime,
    formatDateTimeET,
    getDateTime,
    getDateTimeHoursAgo,
    getDateTimeDaysAgo,
    getTimezone,
    isDateInFuture,
    isEmptyDate,
    secondsToDuration,
    timeAgo
};
