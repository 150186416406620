<template>
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
    name: 'SpinnerThm',
    props: {
        size: {
            type: String,
            default: '1.4em'
        }

    }
};
</script>

<style lang="scss" scoped>
.spinner-border {
    color: var(--primary-color);
    width: v-bind(size);
    height: v-bind(size);
}
</style>
