<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Debug Information</div>

                <div class="card-body" v-if="user">
                    <ul class="list-unstyled">
                        <li><strong>Name:</strong> <span v-if="authorStore.hasCurrentAuthor"><a href="/profile">{{ user.name }}</a></span><span v-else>{{ user.name }}</span></li>
                        <li><strong>Email:</strong> {{ user.email }}</li>
                        <li>
                            <strong>Security Roles:</strong>
                            <span v-for="role in userRoles" class="badge text-bg-secondary ms-1" :key="role">{{ role }}</span>
                        </li>
                        <li v-if="siteStore.build?.dt">
                            <strong>Server Time:</strong> {{ formatDateTime(siteStore.build?.dt, 'M/dd/yyyy h:mm a') }}
                        </li>
                        <li><strong>Node Env:</strong> {{ environment }}</li>
                    </ul>

                    <LogViewerThm :filter="{ userName: user.name, ...filter }" tag="button" class="p-button-secondary p-button-outlined" />
                </div>
            </div>

            <div class="card card-border-color card-border-color-primary mt-3">
                <div class="card-header card-header-divider">User Object</div>
                <div class="card-body">
                    <div class="protected">
                        <!--<pre class="userinfo">{{ userObject }}</pre>-->
                        <Diff
                            v-if="userObject"
                            mode="unified"
                            theme="light"
                            language="json"
                            :prev="JSON.stringify(userObject, null, 3)"
                            :current="JSON.stringify(userObject, null, 3)"
                            class="diff-hide-row-numbers"
                        />
                    </div>
                </div>
            </div>


            <div class="card card-border-color card-border-color-primary mt-3" v-if="userRoles?.includes('Developer')">
                <div class="card-header card-header-divider">Environment <i class="far fa-crown color-restricted" v-tooltip="'Visible to Developers'"></i></div>
                <div class="card-body">
                    <div class="protected">
                        <!--<pre class="userinfo mb-0">{{ siteStore.build }}</pre>-->
                        <Diff
                            v-if="siteStore.build"
                            mode="unified"
                            theme="light"
                            language="json"
                            :prev="JSON.stringify(siteStore.build, null, 3)"
                            :current="JSON.stringify(siteStore.build, null, 3)"
                            class="diff-hide-row-numbers"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LogViewerThm from '@/components/LogViewerThm.vue';
import { useSiteStore } from '@/stores/SiteStore';
import { useAuthorStore } from '@/stores/AuthorStore';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { ref, inject, onMounted } from 'vue';
import { subMonths } from 'date-fns';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'Profile',
    components: {
        LogViewerThm
    },
    setup() {
        const { user, userRoles } = useAuth0();
        const environment = process.env.NODE_ENV;
        const siteStore = useSiteStore();
        const authorStore = useAuthorStore();
        const userObject = ref({});
        const appSettings = inject('appSettings');

        const filter = {
            dateStart: new Date(formatDateTime(subMonths(new Date, 6))) // Default start date is 6 months ago
        };

        onMounted(() => {
            siteStore.load();
            authorStore.getCurrentAuthor();
            getUser();
        });

        function getUser() {
            const { user } = useAuth0();
            //let _user = { ...user };
            let _user = JSON.parse(JSON.stringify(user));

            _user['_value'].roles = _user['_value'][`${appSettings.auth0.audience}/roles`];
            delete _user['_value'][`${appSettings.auth0.audience}/roles`];
            _user['_value'].user_metadata = _user['_value'][`${appSettings.auth0.audience}/user_metadata`];
            delete _user['_value'][`${appSettings.auth0.audience}/user_metadata`];
            _user['_value'].app_metadata = _user['_value'][`${appSettings.auth0.audience}/app_metadata`];
            delete _user['_value'][`${appSettings.auth0.audience}/app_metadata`];

            userObject.value = _user['_value'], null, 4;
        }

        return {
            appSettings,
            authorStore,
            environment,
            filter,
            formatDateTime,
            siteStore,
            user,
            userObject,
            userRoles,
        }
    }
}
</script>
