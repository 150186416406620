import { defineStore } from 'pinia';
import PostService from '@/service/PostService';

const postService = new PostService();

export const useCptStore = defineStore('CustomPostType', {
    state: () => ({
        isLoading: false,
        currentAuthor: { authorId: 0 },
        posts: [],
        postType: {
            postTypeId: 0,
            name: null
        },
        pageSize: 20
    }),
    getters: {
        postStatusList() {
            return [
                { label: 'Draft', value: 2 },
                { label: 'Unpublished', value: 0 },
                //{ label: 'Pending', value: 4 },
                { label: 'Published', value: 1 }
            ];
        },
    },
    actions: {
        async load(postTypeId, force = false) {
            if(!postTypeId) return;
            if(force || !this.postType.postTypeId) {
                this.isLoading = true;

                // Get post type based on route
                await postService.getPostTypes().then((res) => {
                    let postType = res.find((obj) => obj.postTypeId == postTypeId);
                    if(postType) this.postType = postType;
                }).catch((err) => {
                    console.error('Error fetching post types', err);
                });

                // Get total post count
                await postService.getPostsCount({ postTypeId: postTypeId }).then((res) => {
                    this.totalPosts = res;
                }).catch((err) => {
                    throw err;
                });
            }
        },
        async getPosts(params) {
            this.isLoading = true;
            if(!params.pageSize) params.pageSize = this.pageSize;
            return await postService.getPosts(params).then((res) => {
                this.posts = res;
                this.newPosts = 0;
                //return res;
            }).then((res) => {
                postService.getPostsCount(params).then((count) => {
                    this.totalPosts = count;
                    return res;
                });
            }).catch((err) => {
                throw err;
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
});