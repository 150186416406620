<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Test</div>
                <div class="card-body">
                    <Button label="Add" class="mb-3" @click="menuStore.add()" />
                    <Button label="Save" class="mb-3" @click="menuStore.save()" />
                    <menu-draggable :menu="menuStore.menu.menu" v-if="menuStore.menu" />
                </div>

                <ul>
                    <li v-for="item of siteStore.sites" :key="item.siteId">
                        {{ item.domain }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import MenuDraggable from './TestMenuComponent.vue';
import { useMenuStore } from '@/stores/MenuDemoStore';
import { useSiteStore } from '../../stores/SiteStore';
//import {useTestMenuFeature } from '@/use/TestMenuFeature';

import { ref, onBeforeMount, onMounted, onUnmounted } from 'vue';

export default {
    name: 'TestMenuDemo',
    components: { MenuDraggable },
    //setup does not have access to this!!
    setup() {
        const menuStore = useMenuStore();
        const selectedMenu = ref([]);
        // lifecycle hooks
        onBeforeMount(() => {
            console.log('Before Mount');
        });
        onMounted(() => {
            menuStore.getMenuById(1);
            console.log('Mounted');
        });
        onUnmounted(() => {
            console.log('Un Mounted');
        });
        const siteStore = useSiteStore();
        //const { selectedMenu } = useTestMenuFeature();

        //returning them for the template to use
        return { menuStore, siteStore, selectedMenu };
    },
};
</script>
