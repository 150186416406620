import BaseService from './BaseService';

export default class SettingsService extends BaseService {
    async getLayoutLocations() {
        return await this.apiClient.get('/api/settings/layout/location').then((res) => res.data);
    }
    async getLayoutLocation(locationId) {
        return await this.apiClient.get(`/api/settings/layout/location/${locationId}`).then((res) => res.data);
    }
    async saveLayoutLocation(location) {
        return await this.apiClient.put('/api/settings/layout/location', location).then((res) => res.data);
    }
    async getLayoutTemplates(locationId) {
        return await this.apiClient.get(`/api/settings/layout/template/${locationId}`).then((res) => res.data);
    }
    async setPostLockStatus(locationId, postLockStatus) {
        return await this.apiClient.put(`/api/settings/layout/location/lock/${locationId}`, postLockStatus).then((res) => res.data);
    }
    async saveLayoutTemplate(template) {
        return await this.apiClient.put('/api/settings/layout/template', template).then((res) => res.data);
    }
}
