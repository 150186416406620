<template>

    <Dialog header="Edit Image" v-model:visible="showEditModal" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{width: '50vw'}" @hide="closeModal">
        <div class="container-fluid" id="edit_container">
            <div class="row">

                <div class="mb-4">
                    <div class="preview-image text-center" v-if="image.path">
                        <a :href="'https://media.townhall.com/cdn/hodl/' + image.path" target="_blank"><img :src="resizeImageUrl('https://media.townhall.com/cdn/hodl/' + image.path, '300x0')" :alt="image.title" /></a>
                    </div>
                </div>

                <div class="p-float-label">
                    <InputText type="text" class="form-control" v-model="image.title" id="Title" @keyup="validateFields" />
                    <label for="Title" class="form-label required">Title</label>
                </div>

                <div class="p-float-label">
                    <Textarea v-model="image.description" rows="3" id="Description" class="d-block w-100" @keyup="validateFields" />
                    <label for="Description" class="form-label required">Description</label>
                </div>

                <div class="p-float-label">
                    <InputText type="text" class="form-control" v-model="image.credit" id="Credit" @keyup="validateFields" />
                    <label for="Credit" class="form-label required">Attribution/Credit</label>
                </div>

                <div class="p-float-label" v-if="image.type && image.type.name">
                    <Dropdown v-model="image.type.id" :options="imageTypes" optionValue="value" optionLabel="name" placeholder="Select an Image Type" id="ImageType" @change="validateFields" />
                    <label for="ImageType" class="form-label required w-100">Type</label>
                </div>

                <div class="p-float-label">
                    <Chips v-model="image.tags" separator="," :allowDuplicate="false" style="w-100" />
                    <label for="Tags" class="form-label w-100 d-block">Tags</label>
                </div>

                <div class="p-float-label mb-3">
                    <InputText type="text" class="form-control" v-model="image.caption" id="Caption" />
                    <label for="Caption" class="form-label w-100">Caption</label>
                </div>

                <div class="mb-2">
                    <InputSwitch v-model="image.isPublished" id="isPublished" />
                    <label for="isPublished" class="switch-label">Published</label>
                    <div class="help" v-show="!image.isPublished">Note: Unpublished the image hides it from the search results, but it the image continues to be available at it's URL. If you no longer want the image to be accessible, delete it instead.</div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Save" class="me-2" @click="saveImage" :disabled="!isFormValid" />
            <Button label="Cancel" severity="secondary" outlined @click="closeModal()" />
        </template>
    </Dialog>

</template>

<script>
//import { ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { ref, onBeforeMount, watch } from 'vue';
import { isDefined } from '@vueuse/core';
import ImageService from '@/service/ImageService';
import { resizeImageUrl } from '@/utils/ThmImageUtils';
import { useToast } from 'primevue/usetoast';
//import { ref, computed, onBeforeMount } from 'vue';

export default {
    name: 'ImageLibraryUpload',
    emits: ['close'],
    props: {
        imageId: {
            type: String
        }
    },
    setup(_props, { emit }) {
        const imageService = new ImageService();
        const toast = useToast();

        const imageTypes = ref([]);
        const isFormValid = ref(false);
        const showEditModal = ref(false);
        const image = ref({});

        onBeforeMount(() => {
            imageTypes.value = imageService.getImageTypes();
        });

        watch(() => _props.imageId, (imgId, oldValue) => {
            if(imgId && imgId != oldValue) {
                setImage(imgId);
            } else {
                showEditModal.value = false;
            }
        });

        function setImage(imageId) {
            if(!imageId) return;
            imageService.getImage(_props.imageId).then((res) => {
                if(isDefined(res['_source'])) {
                    image.value = { id: res['_id'], ...res['_source'] };
                    if(!isDefined(image.value.type)) {
                        image.value.type = { id: 0, name: null }
                    }
                    showEditModal.value = isDefined(image.value.id);
                    validateFields();
                }
            });
        }

        function closeModal() {
            showEditModal.value = false;
            emit('close', false);
        }

        function validateFields() {
            isFormValid.value = true;
            //var requiredFields = [image.value.title, image.value.description, image.value.credit, image.value.license.id, image.value.type.id];
            var requiredFields = [image.value.title, image.value.description, image.value.credit, image.value.license.id];
            requiredFields.forEach((elm) => {
                if(!elm || (typeof elm == 'string' && !elm.trim())) isFormValid.value = false;
            });
        }

        function saveImage() {
            if(isFormValid.value) {
                imageService.saveImage(_props.imageId, image.value).then((res) => {
                    let result = res.result || 'saved';
                    toast.add({ severity: 'success', summary: 'Success', detail: `Image ${result} successfully`, life: 2000 });
                    emit('close', image.value);
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Save Error', detail: err.message || err, life: 3000 });
                });
            }
        }

        return {
            closeModal,
            image,
            imageTypes,
            isFormValid,
            resizeImageUrl,
            saveImage,
            showEditModal,
            validateFields
        };
    },
};
</script>

<style lang="scss" scoped>
#edit_container {
    div.preview-image {
        max-height: 200px;
        background-position: top;
        overflow-y: scroll;
    }
}
</style>

<style lang="scss">
#edit_container {
    .p-chips {
        &, .p-chips-multiple-container {
            width: 100%;
        }
    }
    .p-float-label {
        margin-bottom: 1.7em;
    }
    .p-float-label label {
        left: 1.4em;
    }
}
</style>