<template>
    <div class="row mb-3">
        <div class="col-sm-12 col-md-12">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">
                    <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny float-end" iconPos="right" @click="addNew()" title="Add New Live Blog" v-show="userHasAccessToRole('Admin')" />
                    Live Blog
                    <SpinnerThm class="spinner" size="1em" v-show="loading" />
                </div>

                <div class="card-body">
                    <div id="liveblog_filter_bar" class="mb-3 px-3 py-2">
                        <div class="row">
                            <div class="col-auto">
                                <InputText @keyup.enter="search()" type="search" v-model="filter.searchText" class="me-1" placeholder="Search Title..." />
                                <Button label="" icon="far fa-search" @click="search()" />
                                <Button label="" severity="secondary" icon="far fa-times" class="ms-1" @click="clear" />
                            </div>
                            <div class="col-xs-12 col-sm-auto p-2">
                                <!-- <Checkbox v-model="filter.isLive" :binary="true" id="IsLive" class="me-1" /> -->
                                <input type="checkbox" class="form-check-input fw-500 me-2" v-model="filter.isLive" id="IsLive" @change="search" />
                                <label for="IsLive" class="form-label fw-500 d-inline">Live</label>
                            </div>
                            <div class="col-xs-12 col-sm-auto p-2">
                                <!-- <Checkbox v-model="filter.isPublished" :binary="true" id="IsPublished" class="me-1" /> -->
                                <input type="checkbox" class="form-check-input fw-500 me-2" v-model="filter.status" value="1" id="IsPublished" @change="search" />
                                <label for="IsPublished" class="form-label fw-500 d-inline">Published</label>
                            </div>
                            <div class="col text-end">
                                <Button label="" icon="far fa-refresh" severity="secondary" outlined @click="search(true)" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- {{ posts }} -->
                        <template v-for="(post, idx) in posts" :key="post.postId">
                            <PostCard :post="post" :index="idx" />
                        </template>
                    </div>
                    <div ref="bottomSentinel" class="bottom-sentinel"></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
//import { ref, computed, inject, onMounted } from 'vue';
//import { inject, onMounted } from 'vue';
import { ref, onMounted } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useRouter } from 'vue-router';
import LiveBlogService from '@/service/LiveBlogService';
import SpinnerThm from '@/components/SpinnerThm.vue';
import PostCard from './_Post.vue';

export default {
    name: 'LiveBlog',
    components: {
        PostCard,
        SpinnerThm,
    },
    setup() {
        const liveBlogService = new LiveBlogService();
        const router = useRouter();

        const defaultFilters = {
            isLive: null,
            isPublished: null,
            searchText: null,
            pageSize: 16,
            pageIndex: 0
        }

        const posts = ref([]);
        const loading = ref(true);
        const filter = ref({ ...defaultFilters });
        const bottomSentinel = ref(null);
        const finishedLoading = ref(false);

        onMounted(async () => {
            await getPosts();
            checkSentinelVisibility()
        });

        // Infinite scroll trigger observer
        useIntersectionObserver(bottomSentinel, ([{ isIntersecting }]) => {
                handleInfiniteScroll(isIntersecting);
            },
            { threshold: 0 }
        );

        const checkSentinelVisibility = () => {
            if(bottomSentinel.value) {
                const rect = bottomSentinel.value.getBoundingClientRect();
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    handleInfiniteScroll(true);
                }
            }
        };

        const handleInfiniteScroll = async (isIntersecting) => {
            if(finishedLoading.value || posts.value.length < filter.value.pageSize || loading.value) return;
            if(isIntersecting) {
                await getPosts();
            }
        };

        async function getPosts(params) {
            params = params || filter.value;
            loading.value = true;
            let results = await liveBlogService.getPosts(params);
            params.pageIndex++;
            if(results.length < params.pageSize) {
                finishedLoading.value = true;
                console.log('Finished loading posts');
            }
            posts.value = posts.value.concat(results);
            loading.value = false;
        }

        function addNew() {
            router.push({ name: 'LiveBlogPost' });
        }

        async function search(refresh = false) {
            if(console.log.hide) console.log(refresh);
            filter.value.pageIndex = 0;

            let params = { ...filter.value };
            params.isLive = params.isLive || null;
            params.status = params.status ? 1 : null;
            //posts.value = await liveBlogService.getPosts(params);
            posts.value = [];
            await getPosts(params);
        }

        function clear() {
            filter.value = { ...defaultFilters };
            search();
        }

        return {
            addNew,
            bottomSentinel,
            clear,
            filter,
            loading,
            posts,
            search,
            userHasAccessToRole,
        };
    },
};
</script>
