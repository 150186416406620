/**
 * Image Manager plugin for Froala
 * @see https://froala.com/wysiwyg-editor/examples/custom-popup/
 * @see https://froala.com/wysiwyg-editor/docs/concepts/custom/plugin/
 */
import FroalaEditor from 'froala-editor/js/froala_editor.min';

// Define popup template
FroalaEditor.POPUP_TEMPLATES['imageManagerPlugin.popup'] = '[_BUTTONS_][_CUSTOM_LAYER_]';

// Define popup buttons
Object.assign(FroalaEditor.DEFAULTS, {
    popupButtons: ['popupClose'],
    containerId: 'fim_0',
});

// Create plugin
FroalaEditor.PLUGINS.imageManagerPlugin = function (editor) {
    var imageManager = null;

    function showImageManager() {
        //console.log('Show:', editor.opts.containerId);
        if (!imageManager) imageManager = initListener();

        //var _im = GetElementInsideContainer('editor.opts.containerId', )
        //var _imbtn = GetFirstTagInsideContainer('editor.opts.containerId', 'button');
        var _imbtn = document.getElementById(editor.opts.containerId + '_button');
        _imbtn.click();

        //var _imtxt = document.getElementById(editor.opts.containerId).getElementsByTagName('input')[0]; // Get first input field
        //console.log('_imtxt', _imtxt);
    }

    function initListener() {
        var _imval = document.getElementById(editor.opts.containerId + '_value');

        // Add listener to hidden input field, watch for value change
        _imval.addEventListener('change', () => {
            setTimeout(() => {
                var _val = document.getElementById(editor.opts.containerId + '_value').value;
                //console.log('insert image:', _val);

                // Old way: Insert URL only
                //editor.html.insert(`<img src="${_val}" class="fr-fic fr-dib" />`);

                // New way: Insert with dimensions: https://stackoverflow.com/a/11442850
                const getImageWidthHeight = async (url) => {
                    const img = new Image();
                    img.src = url;
                    await img.decode();
                    return img;
                };
                getImageWidthHeight(_val).then(img => {
                    if(img.naturalWidth && img.naturalHeight) {
                        editor.html.insert(`<img src="${_val}" data-width="${img.naturalWidth}" data-height="${img.naturalHeight}" class="fr-fic fr-dib" />`);
                    } else {
                        editor.html.insert(`<img src="${_val}" class="fr-fic fr-dib" />`);
                    }
                });
            }, 100); // Pause for a bit so that value can be set
        });

        return _imval;
    }

    // Create popup
    function initPopup() {
        // Create the list of buttons. Note: This plugin doesn't have any at the top of the popup/overlay
        var popup_buttons = '';
        /*
        if (editor.opts.popupButtons.length > 1) {
            popup_buttons += '<div class="fr-buttons">';
            popup_buttons += editor.button.buildList(editor.opts.popupButtons);
            popup_buttons += '</div>';
        }
        */

        // Build popup template
        var template = {
            buttons: popup_buttons,
            custom_layer:
                '<div class="fr-imagemanager-embed-layer fr-layer fr-active" id="fr-imagemanager-embed-layer-' +
                editor.id +
                '"><div class="fr-input-line"><textarea id="fr-imagemanager-embed-layer-text' +
                editor.id +
                '" type="text" placeholder="' +
                editor.language.translate('Image Manager') +
                '" tabIndex="1" aria-required="true" rows="5"></textarea></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="imageManagerInsert" tabIndex="2" role="button">' +
                editor.language.translate('Insert') +
                '</button></div></div>',
        };

        // Create popup
        var $popup = editor.popups.create('imageManagerPlugin.popup', template);

        return $popup;
    }

    // Show popup
    function showPopup() {
        // Get the popup object defined above
        var $popup = editor.popups.get('imageManagerPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container
        editor.popups.setContainer('imageManagerPlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup
        // editor.popups.refresh('imageManagerPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="imageManagerButton"]');

        // Set the popup's position
        var left = $btn.offset().left + $btn.outerWidth() / 2;
        var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        // Show the custom popup. The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show('imageManagerPlugin.popup', left, top, $btn.outerHeight());
    }

    // Hide the custom popup
    function hidePopup() {
        editor.popups.hide('imageManagerPlugin.popup');
    }

    // Methods visible outside the plugin
    return {
        showImageManager: showImageManager,
        showPopup: showPopup,
        hidePopup: hidePopup,
    };
};

// Define an icon and command for the button that opens the custom popup.
FroalaEditor.DefineIcon('imageManagerIcon', { NAME: 'imageManager', SVG_KEY: 'imageManager' });
FroalaEditor.RegisterCommand('imageManagerButton', {
    title: 'Image Manager',
    icon: 'imageManagerIcon',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    plugin: 'imageManagerPlugin',
    callback: function () {
        this.imageManagerPlugin.showImageManager();
    },
});

// Define custom popup close button icon and command.
//FroalaEditor.DefineIcon('popupClose', { NAME: 'times', SVG_KEY: 'times'});
FroalaEditor.RegisterCommand('popupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function () {
        this.imageManagerPlugin.hidePopup();
    },
});

// Define custom popup 1.
//$.FroalaEditor.DefineIcon('popupButton1', { NAME: 'bell-o' });
FroalaEditor.RegisterCommand('imageManagerInsert', {
    title: 'Insert Text',
    undo: true,
    focus: true,
    callback: function () {
        var textArea = this.$box.find('.fr-imagemanager-embed-layer textarea');
        //$('#Body').froalaEditor('html.insert', textArea.val(), false);
        this.html.insert(textArea.val());
        textArea.val('');
        this.imageManagerPlugin.hidePopup();
        this.events.focus();
    },
});
