import BaseService from './BaseService';

export default class WidgetService extends BaseService {
    async getColumnists(params) {
        return await this.apiClient.get('/api/widget/columnists', { params }).then((res) => res.data);
    }
    async saveColumnists(settings, items) {
        //console.log('Saving Items:', settings, items);
        var params = {
            settings: settings,
            items: items
        }
        return await this.apiClient.post('/api/widget/columnists/save',params).then((res) => res.data);
    }
    async getWidgetPostItems(settingsId) {
        return await this.apiClient.get('/api/widget/posts', { params: { settingsId } }).then((res) => res.data);
    }
    async saveWidgetPostItems(settingsId = 0, items) {
        return await this.apiClient.put('/api/widget/posts', items, { params: { settingsId: settingsId } }).then((res) => res.data);
    }
    async getSettings(params) {
        return await this.apiClient.get('/api/widget/settings', { params }).then((res) => res.data);
    }
    async getWidgetLockStatus(settingsId) {
        return await this.apiClient.get(`/api/widget/lock/${settingsId}`).then((res) => res.data);
    }
    async setWidgetLockStatus(settingsId, postLockStatus) {
        return await this.apiClient.put(`/api/widget/lock/${settingsId}`, postLockStatus).then((res) => res.data);
    }
}
