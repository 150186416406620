
import { defineStore } from 'pinia';
import CoRegService from '@/service/CoRegService';
import { formatDateTime, isEmptyDate } from '@/utils/ThmDateTimeUtils';

const coRegService= new CoRegService();

export const useCoRegStore = defineStore('CoRegStore', {
    state: () => ({
        loading: false,
        offers: [],
        offer: {},
        partner: {
            name : ""
        },
        offersList : [],
        partnersList: [],
        partnersStats: [],
        reportsStats: [],
        reportsStatsExport: [],
        totalPages: 0,
        timePeriod: {},
        timePeriods: []

    }),
    actions: {
        async getPartnersStats(report){
            return await coRegService.getPartnersStats(report).then((res) => {
                this.partnersStats = res;
                console.log(this.partnersStats);
                return res;
            })
        },
        async getReportsStats(report){
            return await coRegService.getReportsStats(report).then((res) => {
                this.reportsStats = res;
                console.log(this.reportsStats);
                return res;

            })
        },
        async reportStatsExport(report){
            return await coRegService.reportStatsExport(report).then((res) => {
                this.reportsStatsExport = res;
                //console.log(this.reportsStats);
                return res;
            })
        },
        async listOffers() {
            this.loading = true;
            //var test = await CoRegService.getOffer()

            return await coRegService.listOffers().then((res) => {
                this.offers = res.offerList;

                return this.offers;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async getOffersList(){

            return coRegService.getOffersList().then((res) => {
                this.offersList = res;
                console.log('Offers:', this.offersList);
                return res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                //this.loading = false;
            });

        },
        async getPartnersList(){

            return coRegService.getPartnersList().then((res) => {

                this.partnersList = res;
                console.log('Partner\'s List:', this.partnersList );
                return res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                //this.loading = false;
            });

        },
        async addPartner(partner){
            return coRegService.addPartner(partner).then((res) => {
                //{ PartnerId = newId.FirstOrDefault(), Name = name };
                console.log('Partner added:', res);
                return res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.getPartnersList();
                //this.loading = false;
            });
        },
        async getTimePeriods(){
            return this.timePeriod;

        },
        async setOffer(offerId){
            return this.getOffer(offerId, true);
        },
        async activateOffer(offer){

            return await coRegService.activateOffer(offer).then((res) => {

                //console.log(res);

                return res;
            }).catch((error) => {

                throw error;
            }).finally(() => {
                //this.loading = false;
            });

        },

        async getOffer(offerId, setStore = false) {
            console.log(this.partnersList)
            let offer= null;
            // See if block exists in paged results (if any). This saves an API lookup.
            if(this.offer.length) {
                offer= this.offer.find((obj) => obj.offerId == offerId);
            }

            // If not, fetch by offer ID
            if(offer&& offer.offerId) {
                if(setStore) {
                    this.offer = offer;

                    this.offer.datePublished = isEmptyDate(this.offer.datePublished) ? null : formatDateTime(this.offer.datePublished, 'MM/dd/yyyy');
                    //this.block.dateExpires = isEmptyDate(this.block.dateExpires) ? null : formatDateTime(this.block.dateExpires);
                    //this.block.dateCreated = isEmptyDate(this.block.dateCreated) ? null : formatDateTime(this.block.dateCreated);
                }
                return offer;
            } else {
                this.loading = false;
                return coRegService.editOffer(offerId).then((res) => {
                    //console.log(res);
                    if(setStore) {
                        this.offer = res;
                        this.offer.datePublished = isEmptyDate(this.offer.datePublished) ? null : formatDateTime(this.offer.datePublished, 'MM/dd/yyyy');
                        //this.block.dateExpires = isEmptyDate(this.block.dateExpires) ? null : formatDateTime(this.block.dateExpires);
                        //this.block.dateCreated = isEmptyDate(this.block.dateCreated) ? null : formatDateTime(this.block.dateCreated);
                    }
                    return res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async logOffers(){
            //console.log("This is a tEST");
            console.log(this.offers);
        },
        async saveOffer() {
            return await coRegService.saveOffer(this.offer);
        },
        async deleteOffer(){
            return await coRegService.deleteOffer(this.offer.offerId);
        },
        setDefaults() {
            this.offer = {
                datePublished: formatDateTime(new Date(), 'MM/dd/yyyy'),
                offerId : 0
                //siteGroup: 1
            },
            this.timePeriods = [
                { label: 'Today', value: '1' },
                { label: 'Yesterday', value: '2' },
                { label: 'Month to Date', value: '3' },
                { label: 'Year to Date', value: '4' },
            ]
        }
    }
});
