<template>
    <section class="card card-border-color card-border-color-primary" id="cartoons">
        <div class="card-header card-header-divider">
            <span class="float-end d-inline-block" v-if="log.original">
                <Checkbox v-model="splitView" :binary="true" @change="toggleView" /> <span class="ms-1 fw-normal split-view-label">Split View</span>
            </span>

            <span v-if="log.logId">Log ID: {{ log.logId }}</span>
            <span v-else>404 Not Found</span>
        </div>
        <div class="card-body container-fluid">
            <!--
            <span class="float-end d-inline-block">
                <InputSwitch v-model="splitView" /> <span class="ms-1 split-view-label">Split View</span>
            </span>
            -->
            <table class="table" v-if="log.logId">
                <!--
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col" class="w-100">First</th>
                    </tr>
                </thead>
                -->
                <tbody>
                    <tr>
                        <th scope="row" class="text-nowrap">Object Type</th>
                        <td class="w-100">{{ log.logTypeName }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-nowrap">Reference ID</th>
                        <td>{{ log.referenceId }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-nowrap">User Name</th>
                        <td>{{ log.userName }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-nowrap">Date/Time</th>
                        <td>{{ formatDateTime(log.timestamp, 'MM/dd/yyyy hh:mm a') }} {{ getTimezone(log.timestamp) }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="text-nowrap">Message</th>
                        <td>{{ log.message }}</td>
                    </tr>
                </tbody>
            </table>

            <p class="text-muted fst-italic" v-if="!log.logId">
                Log entry not found
            </p>

            <Diff
                :mode="splitView ? 'split' : 'unified'"
                theme="light"
                :language="log.language || 'plaintext'"
                :prev="log.original || log.content"
                :current="log.content"
            />

            <div v-if="log.htmlBefore || log.htmlAfter">
                <h5 class="mb-0">Body</h5>

                <Diff
                    :mode="splitView ? 'split' : 'unified'"
                    theme="light"
                    language="html"
                    :prev="log.htmlBefore || log.htmlAfter"
                    :current="log.htmlAfter"
                    v-if="log.htmlBefore || log.htmlAfter"
                />
            </div>

        </div>
    </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import AdminService from '@/service/AdminService';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { formatDateTime, getTimezone } from '@/utils/ThmDateTimeUtils';

export default {
    name: 'Logs',
    components: {
    },
    setup() {
        const adminService = new AdminService();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const log = ref({});
        const splitView = ref(false);

        onMounted(() => {
            if(route.query.splitView) splitView.value = true;
            if(route.params.logId) {
                adminService.getLog(route.params.logId).then((res) => {
                    log.value = res;
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Loading Log Entry', detail: err.message || err, life: 5000 });
                });
            }
        });

        function toggleView() {
            let params = splitView.value ? { splitView: true } : null;
            router.push({ query: params });
        }

        return { log, getTimezone, formatDateTime, splitView, toggleView };
    }
};
</script>

<style lang="scss" scoped>
div.card-body {
    .single-line {
        height: 1.1em;
        overflow: hidden;
    }
}
span.split-view-label {
    vertical-align: top;
    display: inline-block;
    margin-top: 0.3em;
    font-size: 0.85em;
}
</style>
