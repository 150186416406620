<template>
    <div class="col-sm-12 col-md-6 col-xl-5">
        <div class="card card-border-color card-border-color-primary shows">
            <div class="card-body page-list container-fluid pb-3">
                <!--<Paginator :totalRecords="virtualPageStore.totalPages" :rows="pageSize" @page="onPage($event)" v-show="virtualPageStore.pages.length" />-->
                <PaginationThm v-model="route.query.pageIndex" :totalRecords="virtualPageStore.totalPages" :pageSize="pageSize" @page="onPage" />

                <div class="row row__header pb-2 mb-1">
                    <div class="col-md-3 col-lg-2 d-none d-md-block"></div>
                    <div class="col-sm-6 col-md-4 col-lg-5 fw-bold">Title</div>
                    <div class="col-sm-6 col-md-5 d-none d-sm-block fw-bold text-center">Status</div>
                </div>

                <p class="fst-italic text-muted ps-1 pt-4" v-show="Object.keys(route.query).length && !virtualPageStore.pages.length">No virtual pages found matching criteria.</p>

                <div v-for="page in virtualPageStore.pages" :key="page.virtualPageId" class="row vitual-page item pt-2" v-bind:class="{ active: route.params.virtualPageId == page.virtualPageId }">
                    <div class="col-md-3 col-lg-2 d-none d-md-block">
                        <Button label="Edit" class="btn-edit" @click="editVirtualPage(page.virtualPageId)" />
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-5 ps-1">
                        <a :href="getAbsoluteUrl(page.path, page.slug)" target="_blank" v-if="page.isPublished">{{ page.title }}</a>
                        <span v-else>{{ page.title }}</span>
                    </div>
                    <div class="col-sm-6 col-md-5 d-none d-sm-block text-center">
                        <small title="Published Date" class="badge bg-success" v-if="page.isPublished">{{ formatDateTime(page.datePublished) }}</small>
                        <small title="Not Published" class="badge bg-secondary" v-else>Not Published</small>
                        <div v-show="!isEmptyDate(page.dateExpires) && page.isPublished">
                            <small title="Expiration Date" class="badge bg-danger">{{ formatDateTime(page.dateExpires) }}</small>
                        </div>
                        <!--
                        <publish-label-status :datetime="page.datePublished" :status="page.isPublished ? 1 : 0" v-if="page.isPublished" x-class="bg-success" />
                        <publish-label-status :datetime="page.datePublished" :status="page.isPublished ? 1 : 0" v-else class="bg-secondary" />
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { inject, onMounted, watch } from 'vue';
import { useVirtualPageStore } from '@/stores/VirtualPageStore';
import { isEmptyDate, formatDateTime } from '@/utils/ThmDateTimeUtils';
import PaginationThm from '@/components/PaginationThm.vue';

export default {
    name: 'VirtualPageList',
    components: {
        PaginationThm
    },
    setup() {
        const virtualPageStore = useVirtualPageStore();
        const appSettings = inject('appSettings');
        const router = useRouter();
        const route = useRoute();

        const pageSize = 15;

        // Watch for route changes
        watch(
            // () => route.query, (params, fromParams) => {
            () => route.query,
            async (params) => {
                if (console.log.hide) console.log({ params });
                virtualPageStore.getVirtualPages(params).then((res) => {
                    if (console.log.hide) console.log('res', res);
                });
            }
        );

        // Mounted
        onMounted(() => {
            virtualPageStore.load();
            virtualPageStore.getVirtualPages(route.query).then((res) => {
                if (console.log.hide) console.log('res', res);
            });
        });

        function editVirtualPage(virtualPageId) {
            router.push({ path: `/manage/virtualpage/${virtualPageId}`, query: route.query });
        }

        function onPage(e) {
            router.push({ query: { ...route.query, pageIndex: e.page } });
            virtualPageStore.getVirtualPage({ ...route.query, pageIndex: e.page });
        }

        function getAbsoluteUrl(path, slug) {
            path = (path || '').replace(/^\/|\/$/g, ''); // Trim slashes
            slug = (slug || '').replace(/^\/|\/$/g, '');
            let pathSlug = (path + '/' + slug).replace(/^\/|\/$/g, '');
            if(appSettings.siteId == 1) {
                return appSettings.webroot[process.env.NODE_ENV] + '/' + pathSlug;
            } else {
                return appSettings.webroot[process.env.NODE_ENV] + '/page/' + pathSlug;
            }
        }

        return {
            editVirtualPage,
            formatDateTime,
            getAbsoluteUrl,
            isEmptyDate,
            onPage,
            pageSize,
            route,
            virtualPageStore
        };
    },
};
</script>

<style lang="scss" scoped>
div.page-list {
    .row.vitual-page {
        padding-bottom: 0.5em;

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }
    }
    div.row__header {
        border-bottom: 1px solid #d9d9d9;
    }
}
</style>
