<template>
    <!-- <div class="border-bottom py-2 liveblog-thread" v-for="post in liveBlogStore.widgetPosts" :key="post.id">
        <a :href="getPermalink(post.url)" target="_blank">{{ post.title }}</a>
    </div> -->

    <div class="card card-border-color card-border-color-primary liveblog-featured mb-3">

        <div class="card-header card-header-divider">
            <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny float-end" iconPos="right" @click="addNew()" title="Add New" v-show="userHasAccessToRole('Editor')" />
            Highlighted Stories
            <SpinnerThm class="spinner me-2 float-end" size="1.3em" v-show="loading" />
        </div>

        <BlockUI class="card-body" :blocked="props.isBlocked">
            <PostChooserThm v-model="currentPost" ref="postChooserThm" @choose="onPostAdd" />
            <template v-if="liveBlogStore.widgetPosts?.length">

                <Draggable class="drag-area" :list="liveBlogStore.widgetPosts" :group="{ name: 'g1' }" item-key="id" @change="itemDragged" :disabled="!userHasAccessToRole('Editor')">
                    <template #item="{ element }">
                        <div :key="element.id" class="border-bottom py-2 liveblog-featured-item">
                            <div class="row g-0">
                                <div class="col-1 text-center align-self-center">
                                    <i class="fa-solid fa-bars"></i>
                                </div>
                                <div class="col pe-2 align-self-center">
                                    <a :href="getPermalink(element.url)" target="_blank">{{ element.title }}</a>
                                </div>
                                <div class="col-auto" v-if="userHasAccessToRole('Editor')">
                                    <Button icon="pi pi-trash" severity="secondary" outlined @click="deleteWidget(element.widgetId)" />
                                </div>
                            </div>
                        </div>
                    </template>
                </Draggable>

            </template>
            <template  v-else-if="liveBlogStore.widgetPosts != null">
                No posts yet...
            </template>
        </BlockUI>

    </div>
</template>

<script>
//import { ref, computed, inject, onMounted } from 'vue';
import { ref, inject } from 'vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { formatDateTimeET } from '@/utils/ThmDateTimeUtils';
import { useLiveBlogStore } from '@/stores/LiveBlogStore';
import { useConfirm } from 'primevue/useconfirm';
//import { useToast } from 'primevue/usetoast';
import PostChooserThm from '@/components/PostChooserThm.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';
import Draggable from 'vuedraggable';
import BlockUI from 'primevue/blockui';

export default {
    name: 'LiveBlog_Featured',
    emits: [ 'change' ],
    props: {
        isBlocked: {
            type: Boolean,
            default: false
        },
        post: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    components: {
        BlockUI,
        Draggable,
        PostChooserThm,
        SpinnerThm,
    },
    setup(props, { emit }) {
        const liveBlogStore = useLiveBlogStore();
        const appSettings = inject('appSettings');
        const confirm = useConfirm();
        //const toast = useToast();

        const currentPost = ref({});
        const loading = ref(false);
        const postChooserThm = ref(null);

        function getPermalink(path) {
            return appSettings.webroot[process.env.NODE_ENV] + path;
        }

        async function itemDragged(ev) {
            if(console.log.hide) console.log(ev);
            loading.value = true;
            await liveBlogStore.saveWidgets();
            // toast.add({ severity: 'success', summary: 'Success', detail: 'Highlighted Stories Saved', life: 3000 });
            emit('change', true);
            loading.value = false;
        }

        async function deleteWidget(widgetId) {
            confirm.require({
                message: 'Are you sure you want to delete this post?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    await liveBlogStore.deleteWidget(widgetId);
                    emit('change', true);
                },
                reject: () => {
                    // Do nothing
                },
            });
        }

        function addNew() {
            postChooserThm.value.openModal();
        }

        async function onPostAdd(post) {
            let newPost = {
                liveBlogPostId: props.post.postId,
                postId: post.postId,
                dateCreated: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'),
                //datePublished: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'),
                isPublished: true,
                ordinalPosition: 0,
                //url: getPermalink(post.url),
                url: post.url,
                title: post.title,
                authorId: post.author?.authorId,
                image: post.imageObject[0]?.url,
            };

            await liveBlogStore.addWidget(newPost);
            emit('change', true);
        }

        return {
            addNew,
            currentPost,
            deleteWidget,
            getPermalink,
            itemDragged,
            liveBlogStore,
            loading,
            onPostAdd,
            postChooserThm,
            props,
            userHasAccessToRole,
        };
    },
};
</script>
