<template>
    <VirtualPageFilterBar />

    <div class="row mb-3">
        <VirtualPageList />
        <VirtualPageEditor />
    </div>
</template>

<script>
import VirtualPageFilterBar from './VirtualPageFilterBar.vue';
import VirtualPageList from './VirtualPageList.vue';
import VirtualPageEditor from './VirtualPageEditor.vue';

export default {
    name: 'VirtualPage',
    components: {
        VirtualPageFilterBar,
        VirtualPageList,
        VirtualPageEditor,
    },
};
</script>
