<template>
    <div class="card card-border-color card-border-color-primary">
        <div class="card-header card-header-divider">Video Chooser Demo</div>
        <div class="card-body">
            <VideoChooserThm v-model="video" />

            <template v-if="video.videoId">
                <h5 class="pt-3">Video Object:</h5>
                <p>{{ video }}</p>
            </template>
        </div>
    </div>
</template>

<script>
//import { ref, onBeforeUnmount, onMounted, watch } from 'vue';
import { ref } from 'vue';
import VideoChooserThm from '@/components/VideoChooserThm.vue';

export default {
    name: 'VideoChooserDemo',
    components: {
        VideoChooserThm,
    },
    setup() {
        const video = ref({});

        return {
            video
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
