import BaseService from './BaseService';

export default class VirtualpageService extends BaseService {
    async getVirtualPages(params) {
        return await this.apiClient.get('/api/virtualpage', { params }).then((res) => res.data);
    }

    async getVirtualPage(virtualPageId) {
        if(!virtualPageId) return null;
        return await this.apiClient.get(`/api/virtualpage/${virtualPageId}`).then((res) => res.data);
    }

    async deleteVirtualPage(id) {
        return await this.apiClient.delete(`/api/virtualpage/${id}`).then((res) => res.data);
    }
    async saveVirtualPage(virtualpage) {
        return await this.apiClient.post('/api/virtualpage', virtualpage).then((res) => res.data);
    }
}
