<template>
    <ConfirmDialog />
    <div class="col-sm-12 col-md-6">
        <div class="card card-border-color card-border-color-primary" id="live_queue">
            <div class="card-header card-header-divider">
                <SpinnerThm class="spinner float-end" v-if="saving" />
                <div v-else>
                    <Button label="Save" class="p-button-smaller float-end" @click="savePosts()" :disabled="isLockedByOtherUser || !editorsChoiceStore.isDirty" v-if="!isLockedByOtherUser" />
                    <Button label="Take Over" class="p-button-smaller float-end" @click="takeOver()" v-else />
                    <LogViewerThm :filter="{ logTypeId: 19, referenceId: 2 }" label="" icon="pi pi-list" tag="button" class="p-button-smaller p-button-secondary p-button-outlined btn-log align-middle me-1 float-end" />
                </div>
                <!--Editor's Choice ({{ editorsChoiceStore.posts.length }})-->
                Editor's Choice (<span class="item-count" :class="{ exceeded: editorsChoiceStore.posts.length > appSettings.features.editorsChoice.maxItems }">{{ editorsChoiceStore.posts.length }}</span> of {{ appSettings.features.editorsChoice.maxItems }})
                <span class="icon-dot-modified" v-show="editorsChoiceStore.isDirty" v-tooltip.top="'Unsaved changes'"></span>
                <span class="fa-regular fa-lock widget-locked" v-show="editorsChoiceStore.isLocked" v-tooltip.right="lockedMessage"></span>
            </div>

            <div class="card-body live">
                <draggable class="drag-area" :list="editorsChoiceStore.posts" :group="{ name: 'g1' }" item-key="id" @change="itemDragged($event)" :disabled="isLockedByOtherUser || deviceIsMobile()">
                    <template #item="{ element }">
                        <div :key="element.id" class="post-item">
                            <div class="border">
                                <div class="post-item__toolbar">
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-up" @click="movePosition(element, 'up')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-up"></i></button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-down" @click="movePosition(element, 'down')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-down"></i></button>

                                    <div class="float-end text-end">
                                        <i class="pi pi-times btn-toolbar-close" @click="removePost(element, 'live')"></i>
                                    </div>
                                    <!--<button class="btn-toolbar-button d-inline-block btn-toolbar-edit float-end" @click="edit(element, 'live')" :disabled="isLockedByOtherUser">Edit</button>-->
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-right float-end" @click="moveColumn(element, 'live', 'queue')" :disabled="isLockedByOtherUser" v-if="props.queueSupport"><i class="far fa-arrow-right"></i></button>
                                </div>

                                <div class="post-item__body d-flex">
                                    <div class="w-100">
                                        <div class="preview-image-container float-end d-inline-block">
                                            <a :href="getAbsoluteUrl(element)" class="position-absolute play-button" target="_blank" v-if="element.postType?.toLowerCase() == 'video' && getPreviewImage(element)"><img src="/images/play-button.svg" /></a>
                                            <a class="preview-image" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="element.post.imageObject && element.post.imageObject[0] ? element.post.imageObject[0].credit : ''" target="_blank"></a>
                                        </div>

                                        <h6 class="mb-0" :x-post-id="element.postId">
                                            <a :href="getEditLink(element)" target="_blank" v-html="getActiveHeadline(element)" v-if="getEditLink(element)"></a>
                                            <span v-else v-html="getActiveHeadline(element)"></span>
                                            <span class="text-muted ps-1" v-if="element.postType && element.postType?.toLowerCase() != 'post'">({{ element.postType }})</span>
                                        </h6>

                                        <ul class="list-group list-group-flush">
                                            <li class="list-item">{{ formatDateTime(element.post.datePublished, 'M/dd/yyyy hh:mm a') }} - {{ getAuthorName(element) }}</li>
                                            <li class="list-item">Live: {{ formatDateTime(element.dateLive, 'M/dd/yyyy hh:mm a') }}</li>
                                            <li class="list-item" v-if="!isEmptyDate(element.dateExpires)">Expires: {{ formatDateTime(element.dateExpires, 'M/dd/yyyy hh:mm a') }}</li>
                                            <li class="list-item d-none2" v-if="element.stats && Object.keys(element.stats).length">
                                                <abbr class="text-decoration-none" title="Chartbeat Concurrents">CB:</abbr> {{element.stats.concurrents || 0}} <i class="fa-regular fa-pipe"></i>
                                                5m: <span class="min5" v-html="element.stats['5min'] || 0" /> <i class="fa-regular fa-pipe"></i>
                                                30m: <span class="min30" v-html="element.stats['30min'] || 0" /> <i class="fa-regular fa-pipe"></i>
                                                60m: <span class="min60" v-html="element.stats['60min'] || 0" />
                                            </li>
                                        </ul>

                                        <span v-if="element.post.subscription > 1">
                                            <img :src="getVipBadge(element.post.subscription)" alt="VIP" class="vip-badge" />
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
//import { ref, computed, inject, onBeforeMount, onMounted } from 'vue';
import { ref, inject, computed, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { getVipBadge, resizeImageUrl } from '@/utils/ThmImageUtils';
import { getEditLink } from '@/utils/ThmUtils';
//import { formatDateTime, formatDateTimeET, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { formatDateTime, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { useEditorsChoiceStore } from '@/stores/EditorsChoiceStore';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import { useConfirm } from 'primevue/useconfirm';
import LogViewerThm from '@/components/LogViewerThm.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';
//import { useSignalR } from '@quangdao/vue-signalr';
//import { useSignalR } from '@dreamonkey/vue-signalr';
import useSignalR from '@/use/SignalR';
import { useToast } from 'primevue/usetoast';
import ConfirmDialog from 'primevue/confirmdialog';
import Draggable from 'vuedraggable';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'EditorsChoiceLiveQueue',
    components: {
        ConfirmDialog,
        Draggable,
        LogViewerThm,
        SpinnerThm,
    },
    props: {
        queueSupport: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const editorsChoiceStore = useEditorsChoiceStore();
        const appSettings = inject('appSettings');
        const confirm = useConfirm();
        const signalr = useSignalR();
        const { user } = useAuth0();
        const toast = useToast();

        const saving = ref(false);
        const settingsId = 2;

        signalr.on('ReceiveSend', () => null);
        signalr.on('ReceivePostLock', () => null);
        signalr.on('WidgetReceiveLock', (message) => setLockMessageOnWidgetType(message));
        signalr.on('WidgetReceiveTakeOverNotify', (message) => takeOverNotify(message));

        const isLockedByOtherUser = computed(() => {
            return editorsChoiceStore.isLocked && editorsChoiceStore.widgetSettings.postLockStatus.userIdLock != user.value.name;
        });

        const lockedMessage = computed(() => {
            switch(true) {
                case isLockedByOtherUser.value:
                    return 'Locked by ' + editorsChoiceStore.widgetSettings.postLockStatus.userIdLock;
                case !!editorsChoiceStore.isLocked:
                    return 'Locked by you';
                default:
                    return 'Locked by unknown';
            }
        });

        watch(() => editorsChoiceStore.isDirty, async (isDirty) => {
            if(isDirty === true) setEditLock(true);
        });

        onBeforeMount(() => {
            //subscriptionTypes.value = adminService.getSubscriptions();
            editorsChoiceStore.load();
        });

        onBeforeUnmount(() => {
            ['ReceiveSend', 'ReceivePostLock', 'WidgetReceiveLock', 'WidgetReceiveTakeOverNotify'].forEach((methodName) => {
                signalr.off(methodName);
            });
        });

        function savePosts() {
            saving.value = true;
            if(editorsChoiceStore.posts.length > appSettings.features.editorsChoice.maxItems) {
                toast.add({ severity: 'warn', summary: 'Unable to Save', detail: `Too many items added. You may only have up to ${appSettings.features.editorsChoice.maxItems}.`, life: 4000 });
                saving.value = false;
            } else {
                editorsChoiceStore.save(status).then((res) => {
                    if(console.log.hide) console.log({res});
                    editorsChoiceStore.isDirty = false;
                    setEditLock(false);
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Editor\'s Choice Saved', life: 3000 });
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving Editor\'s Choice', detail: err.message || err });
                    editorsChoiceStore.isDirty = true;
                }).finally(() => {
                    saving.value = false;
                });
            }
        }

        function itemDragged(event) {
            // Set datePublished when made live
            if(event.added) {
                editorsChoiceStore.posts[event.added.newIndex].datePublished = formatDateTime();
            }

            editorsChoiceStore.isDirty = true;
            setEditLock();
        }

        function setEditLock(lock = true) {
            let userData = lock ? user.value : { name: null, email: null };
            if(!lock || !editorsChoiceStore.isLocked) {
                let payload = { postId: settingsId, userIdLock: userData.name, connectionId: userData.email };
                signalr.invoke('WidgetBroadcastLock', payload);
                editorsChoiceStore.widgetSettings.postLockStatus = payload;
            }
        }

        function movePosition(element, dir) {
            if(takeoverCheck()) {
                setEditLock();
                editorsChoiceStore.movePosition(element, dir);
            }
        }

        function removePost(post) {
            if(takeoverCheck()) {
                editorsChoiceStore.remove(post);
            }
        }

        function takeoverCheck() {
            if(isLockedByOtherUser.value) {
                toast.add({ severity: 'error', summary: 'Location Locked', detail: 'Location is currently on auto-pilot. Disabled chronological sorting if you want to make changes.', life: 4000 });
                return false;
            }
            return true;
        }

        function setLockMessageOnWidgetType(message) {
            //console.log('R:message', message);
            if(message) {
                editorsChoiceStore.widgetSettings.postLockStatus = message;

                // Reload posts on unlock
                if(!message.isLocked) editorsChoiceStore.load(settingsId, true);
            }
        }

        function takeOverNotify(message) {
            console.log('[Widget takeOverNotify] Widget ID:', settingsId);
            editorsChoiceStore.widgetSettings.postLockStatus = message;
            editorsChoiceStore.load(settingsId, true);
            toast.add({ severity: 'warn', summary: 'Editor\'s Choice Taken Over', detail: `${message.userIdLock} took over editing! Your changes will be abandoned.` });
        }

        function takeOver() {
            confirm.require({
                message: `Are you sure you want to take over editing? If yes, ${editorsChoiceStore.widgetSettings.postLockStatus.userIdLock} will be blocked from continuing to edit.`,
                header: 'Take Over Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    editorsChoiceStore.load(settingsId, true);
                    let payload = { postId: settingsId, userIdLock: user.value.name, connectionId: user.value.email };
                    signalr.invoke('WidgetManagerTakeOverNotify', payload);
                    editorsChoiceStore.widgetSettings.postLockStatus = payload;
                    toast.add({ severity: 'info', summary: 'Editor\'s Choice Taken Over', detail: 'You have successfully taken over editing', life: 4000 });
                }
            });
        }

        function getAbsoluteUrl(post) {
            return appSettings.webroot[process.env.NODE_ENV] + post.post.url;
        }

        function getPreviewImage(post, size = '180x180') {
            let imageObject = post.post.imageObject;
            if(!imageObject || !imageObject.length || !imageObject[0].url) return null;
            let imageUrl = imageObject[0].url;

            // If image is path only, make fully qualified URL
            if(!imageUrl.includes('http')) {
                imageUrl = 'https://media.townhall.com/cdn/hodl/' + imageUrl.replace(/^\/+/, '').replace(/\/+$/, ''); // Trim slashes
            }

            return resizeImageUrl(imageUrl, size);
        }

        function getActiveHeadline(post) {
            let _props = post.post.extendedProperties;
            // Headlines
            let headline = '';
            if(_props && _props.headlines && _props.headlines.length) {
                for(let idx = 0; idx < _props.headlines.length; idx++) {
                    if(_props.headlines[idx].active) headline = _props.headlines[idx].headline;
                }
            }
            // Default
            return headline.trim() || post.post.title;
        }

        function getAuthorName(item) {
            return item.post?.author?.name || item.post?.author?.nickName || 'No Author';
        }

        return {
            appSettings,
            deviceIsMobile,
            editorsChoiceStore,
            formatDateTime,
            getAbsoluteUrl,
            getActiveHeadline,
            getAuthorName,
            getEditLink,
            getPreviewImage,
            getVipBadge,
            isEmptyDate,
            isLockedByOtherUser,
            itemDragged,
            lockedMessage,
            movePosition,
            props,
            removePost,
            savePosts,
            saving,
            takeOver
        }
    }
}
</script>

<style lang="scss" scoped>
div.card-header {
    .icon-dot-modified {
        height: 1em;
        width: 1em;
        background-color: var(--pink-500);
        border-radius: 50%;
        display: inline-block;
        vertical-align: text-bottom;
    }
    span.widget-locked {
        color: var(--indigo-400);
        margin-left: 0.3em;
        vertical-align: middle;
    }
    .item-count.exceeded {
        color: var(--red-600);
    }
}
div.card-body {
    .post-item {
        //cursor: grab;
        margin-bottom: 0.75em;

        /*
        .d-flex.border {
            padding: 0 !important;
        }
        */

        &__toolbar {
            background-color: var(--teal-400);
            color: #fff;
            padding: 0.2em 0.4em;
            height: 2.1em;
            cursor: all-scroll;

            .btn-toolbar-button {
                border: none;
                margin-top: 0.1em;
                margin-right: 0.5em;
                //background-color: transparent;
                background-color: var(--teal-50);
                color: var(--teal-900);
                //color: #fff;
                padding: 0 0.3em;
                border-radius: 0.2em;
                font-size: 0.95em;
                height: 22px;

                &[disabled] {
                    color: #999 !important;
                }
            }
            .btn-toolbar-up,
            .btn-toolbar-down,
            .btn-toolbar-left,
            .btn-toolbar-right {
                font-weight: bold;
                width: 22px;
            }
            .btn-toolbar-up {
                margin-right: 0.2em;
            }
            .btn-toolbar-close {
                vertical-align: middle;
                //margin-top: -0.15em !important;
                cursor: pointer;
            }
        }
        &__body {
            padding: 0.5em 0.5em 0.4em 0.5em;

            div.preview-image-container {
                a.play-button {
                    &, img {
                        width: 90px;
                        height: 90px;
                    }
                }
                a.preview-image, span.preview-image {
                    background: no-repeat center center / cover;
                    display: inline-block;
                    vertical-align: middle;
                    width: 90px;
                    height: 90px;
                    margin-left: 0.2em;
                }
            }
            h6 {
                a {
                    color: var(--blue-700);

                    &:hover {
                        color: var(--blue-800);
                        text-decoration: underline;
                    }
                }
                &, a {
                    font-size: 1em;
                    padding-bottom: 0.3em;
                }
            }
            ul.list-group {
                li.list-item {
                    font-size: 0.85em;
                    list-style-type: none;
                    color: var(--gray-700);
                }
            }
        }
    }
}
</style>
