import { validate as validateUuid } from 'uuid';
//import useAuth0 from '@/use/Auth0';
import axios from 'axios';

export default class MediaService {

    /**
     * V1 API
     */
    async getDocument(imageId) {
        if(!imageId || !validateUuid(imageId)) return { error: 'Invalid UUID' };
        var endpoint = `https://thm-web-media.azurewebsites.net/v1/imagesearch/getdocument/${imageId}`;
        return await axios.get(endpoint).then((res) => res.data);
    }

    /**
     * V2 API
     */
    async search(params) {
        var defaultParams = {
            offset: 0,
            pageSize: 15,
            //dateDecay: 0.9, // Valid values between 0 and 1
            //decay: 0.9
            sort: 'date'
        };
        params = Object.assign(defaultParams, params);

        //return await axios.get('https://thm-web-media.azurewebsites.net/v2/imagesearch', { params }).then((res) => res.data);
        return await axios.get('https://thm-web-media.azurewebsites.net/v3/imagesearch', { params }).then((res) => res.data);
        //return await axios.get('https://localhost:8950/v3/imagesearch', { params }).then((res) => res.data);
    }

    async getImage(imageId) {
        //if(!imageId || !validateUuid(imageId)) return { error: 'Invalid UUID' };
        if(!imageId) return { error: 'Invalid UUID' };
        var endpoint = `https://thm-web-media.azurewebsites.net/v2/imagesearch/${imageId}`;
        return await axios.get(endpoint).then((res) => res.data);
    }

    async saveImage(imageId, image) {
        //if(!imageId || !validateUuid(imageId)) return { error: 'Invalid UUID' };
        if(!imageId) return { error: 'Invalid UUID' };
        var endpoint = `https://thm-web-media.azurewebsites.net/v2/imagesearch/${imageId}`;
        //var endpoint = `https://localhost:8950/v3/imagesearch/${imageId}`;
        return await axios.post(endpoint, image).then((res) => res.data);
    }

    async deleteImage(imageId) {
        //if(!imageId || !validateUuid(imageId)) return { error: 'Invalid UUID' };
        if(!imageId) return { error: 'Image ID cannot be null' };
        var endpoint = `https://thm-web-media.azurewebsites.net/v2/imagesearch/${imageId}`;
        //var endpoint = `https://localhost:8950/v2/imagesearch/${imageId}`;
        return await axios.delete(endpoint).then((res) => res.data);
    }

    getImageTypes() {
        return [
            {
                value: 5,
                name: 'Attachment/PDF'
            },
            {
                value: 3,
                name: 'Meme'
            },
            {
                value: 4,
                name: 'Photograph'
            },
            {   value: 1,
                name: 'Screen Capture'
            },
            {
                value: 2,
                name: 'Tweet'
            },
        ];
    }

    /*
    getImageLicenses(limitToRoles = false) {
        let licenses = [
            {
                value: 2,
                name: 'Upload - Universal',
                roles: {
                    upload: true // all roles
                }
            },
            {
                value: 3,
                name: 'Upload - Universal (Permanent)',
                roles: {
                    upload: ['Image-Library-Trusted', 'Image-Library-Admin']
                }
            },
            {
                value: 8,
                name: 'AP Feed',
                roles: {
                    upload: false // no roles
                }
            },
            {
                value: 9,
                name: 'AP Manual Upload',
                roles: {
                    upload: ['Image-Library-Admin']
                }
            }
        ];

        if(!limitToRoles) {
            // Return all roles
            return licenses;
        } else {
            // Return the license that the user has access to
            const { user } = useAuth0();
            let result = [];
            for(let idx = 0; idx < licenses.length; idx++) {
                if(Array.isArray(licenses[idx].roles.upload)) {
                    for(let k = 0; k < licenses[idx].roles.upload.length; k++) {
                        // If the user has one of the required roles, add it to the list of licenses
                        if(user.value && user.value['https://cms.townhall.com/api/roles']?.includes(licenses[idx].roles.upload[k])) result.push(licenses[idx]);
                    }
                } else if(licenses[idx].roles.upload === true) {
                    result.push(licenses[idx]);
                }
            }

            return result;
        }
    }
    */

    async getImageLicenses(siteId = null, limitToRoles = false) {
        var endpoint = `https://thm-web-media.azurewebsites.net/v2/thmassetsobject/imagelicenses`;
        //var endpoint = `https://localhost:8950/v2/thmassetsobject/imagelicenses`;

        return await axios.get(endpoint).then((res) => {
            let licenses = [];
            if(!res.data || !res.data.length) return licenses;

            for(let idx = 0; idx < res.data.length; idx++) {
                if(siteId == null || res.data[idx].siteIds.includes(siteId)) {
                    licenses.push(res.data[idx]);
                }
            }

            // Removed licenses that the current user doesn't have access to
            if(!limitToRoles) {
                // Return all roles
                return licenses;
            } else {
                // Return the license that the user has access to
                let result = [];
                for(let idx = 0; idx < licenses.length; idx++) {
                    if(!licenses[idx].roles.upload.length) {
                        result.push(licenses[idx]);
                    } else if(Array.isArray(licenses[idx].roles.upload)) {
                        for(let k = 0; k < licenses[idx].roles.upload.length; k++) {
                            // If the user has one of the required roles, add it to the list of licenses
                            if(limitToRoles.includes(licenses[idx].roles.upload[k])) result.push(licenses[idx]);
                        }
                    } else if(licenses[idx].roles.upload === true) {
                        result.push(licenses[idx]);
                    }
                }

                return result;
            }
        });

    }

}
