<template>
    <ConfirmDialog />

    <div class="row mb-3">
        <div class="col-sm-12">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-body">
                    <Button label="" severity="secondary" text icon="pi pi-info-circle" class="float-end btn-help" @click="toggleHelp" />

                    <!--<TabView :activeIndex="1">-->
                    <TabView :activeIndex="activeTab" @tab-change="changeTab">
                        <TabPanel header="Search">

                            <div class="input-group mb-1">
                                <input type="search" class="form-control" v-model="searchKeywords" placeholder="Keywords..." aria-label="Search" id="SearchText" autofocus @keyup.enter="submitSearch" />
                                <select class="form-select" name="sort" style="max-width: 8rem;" title="Rank By" v-model="searchSort">
                                    <option value="date">Date</option>
                                    <option value="relevance" selected>Relevance</option>
                                </select>
                                <button class="btn btn-primary thmim-button-primary" type="button" id="thmim_search_btn" @click="submitSearch">Search</button>
                            </div>

                            <!--
                            <div class="input-group mb-1">
                                <input type="search" class="form-control" v-model="searchKeywords" placeholder="Keywords..." aria-label="Search" id="SearchText" autofocus @keyup.enter="submitSearch" />
                                <button class="btn btn-primary thmim-button-primary" type="button" id="thmim_search_btn" @click="submitSearch">Search</button>
                            </div>
                            -->
                            <p class="mb-1 fst-italic" v-if="lsImageLibray.searchHistory && lsImageLibray.searchHistory.length > 0">
                                <span v-for="(history, index) in lsImageLibray.searchHistory" :key="history" class="me-1 badge bg-secondary search-history-item" :class="{ 'd-none d-md-inline': index > 2 }">
                                    <a @click="setSearchKeyword(history)">{{ history }}</a>
                                </span>
                            </p>

                            <!--<Paginator :totalRecords="totalRows" :rows="resultsPerPage" @page="fetchImages($event.page)" v-show="images.length > 0" />-->
                            <PaginationThm v-model="currentPage" :totalRecords="totalRows" :pageSize="resultsPerPage" @page="fetchImages($event.page)" v-show="images.length > 0" :showTotalRecords="true" class="my-2" />

                            <div class="container-fluid">
                                <div class="row" v-if="images.length">

                                    <div v-for="image in images" :key="image['_id']" class="thmim-result col-xs-12 col-sm-6 col-md-3 py-0">
                                        <div class="card mb-4 me-2">
                                            <!-- Result: Collapsed -->
                                            <div class="thmim-result__card-body thmim-result__short" v-show="!image.showDetail">
                                                <img :src="getMimeOrResizedImage('https://media.townhall.com/cdn/hodl/' +  image['_source'].path, '400x230')" class="card-img-top" :alt="image['_source'].title" @click="chooseImage(image)" />
                                                <a class="far fa-caret-circle-down float-end mt-2 me-1" @click="toggleDetail(image)"></a>
                                                <div class="card-text text-muted p-2"><date-format :datetime="image['_source'].datePublished" format="MMM d, yyyy" :days-ago="3" /> - <span :style="{ color: getLicenseColor(image['_source'].license.id) }">{{ image['_source'].license.name }}</span></div>
                                            </div>
                                            <!-- Result: Expanded -->
                                            <div class="thmim-result__card-body thmim-result__detail position-absolute" v-show="image.showDetail">
                                                <img :src="getMimeOrResizedImage('https://media.townhall.com/cdn/hodl/' +  image['_source'].path, '400x230')" class="card-img-top" :alt="image['_source'].title" @click="chooseImage(image)" />
                                                <a class="far fa-caret-circle-up float-end mt-2 me-1" @click="toggleDetail(image)"></a>
                                                <div class="card-text text-muted p-2">
                                                    <date-format :datetime="image['_source'].datePublished" format="MMM d, yyyy" :days-ago="3" /> - <span :style="{ color: getLicenseColor(image['_source'].license.id) }">{{ image['_source'].license.name }}</span>
                                                    <div v-if="image['_source'].title">
                                                        <div class="card-text__label">Title</div>
                                                        {{ ellipsize(image['_source'].title, 200) }}
                                                    </div>
                                                    <div v-if="image['_source'].description">
                                                        <div class="card-text__label">Description</div>
                                                        {{ ellipsize(image['_source'].description, 300) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div v-else>
                                    <p class="py-3 mt-2 fst-italic">No results found matching search keywords.</p>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel header="Upload" v-if="appSettings.features.imageLibrary.minimumUploadRole && userHasAccessToRole(appSettings.features.imageLibrary.minimumUploadRole)">
                            <ImageLibraryUpload @upload="onUpload" />
                        </TabPanel>
                    </TabView>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Image Details -->
    <Dialog v-model:visible="showDetailModal" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{width: '50vw'}">
        <template #header>
            <h5 :title="selectedImage['_source'].title">{{ ellipsize(selectedImage['_source'].title, 50) }}</h5>
        </template>

        <div id="image_modal">

            <div class="mb-2">
                <!--
                <a :href="'https://media.townhall.com/cdn/hodl/' + selectedImage.document.location" target="_blank"><img :src="resizeImageUrl('https://media.townhall.com/cdn/hodl/' + selectedImage.document.location, '600x0')" class="w-100" :alt="selectedImage.document.title" /></a>
                -->
                <div class="preview-image">
                    <a :href="'https://media.townhall.com/cdn/hodl/' + selectedImage['_source'].path" target="_blank"><img :src="getMimeOrResizedImage('https://media.townhall.com/cdn/hodl/' + selectedImage['_source'].path, '600x0')" class="w-100" :alt="selectedImage['_source'].title" /></a>
                </div>
            </div>

            <div class="mb-2 input-group">
                <label class="form-label w-100">Image URL <i class="far fa-arrow-up-from-square small cursor" @click="copy('https://cms.townhall.com/imagelib?s=path:&quot;' + getFilenameFromPath(selectedImage['_source'].path, true) + '*&quot;')"></i></label>
                <InputText type="url" class="form-control" :value="'https://media.townhall.com/cdn/hodl/' + selectedImage['_source'].path" readonly="readonly" id="ImageUrl" aria-describedby="btnCopyImageUrl" />
                <button class="btn btn-outline-secondary" type="button" id="btnCopyImageUrl" @click="copy('https://media.townhall.com/cdn/hodl/' + selectedImage['_source'].path)">
                    <!--<span v-if="copied">Copied</span><span v-else>Copy</span>-->
                    <span>Copy</span>
                </button>
            </div>

            <div class="">

                <table class="table mb-2">
                    <tbody>
                        <tr>
                            <th scope="row" class="table-row-label">Uploaded By</th>
                            <td class="w-100"><a href="#" @click="setSearchKeyword(`source.user:&quot;${selectedImage['_source'].source.user}&quot;`, true);">{{ selectedImage['_source'].source.user }}</a></td>
                        </tr>
                        <tr>
                            <th scope="row" class="table-row-label">Date</th>
                            <td class="w-100">{{formatDateTime(selectedImage['_source'].datePublished) }}</td>
                        </tr>
                        <tr>
                            <th scope="row" class="table-row-label">License</th>
                            <td class="w-100"><a href="#" @click="setSearchKeyword(`license.name:&quot;${selectedImage['_source'].license.name}&quot;`, true);">{{ selectedImage['_source'].license.name }}</a> <span v-if="selectedImage['_source'].license.id">(<a href="#" @click="setSearchKeyword(`license.id:${selectedImage['_source'].license.id}`, true);">{{ selectedImage['_source'].license.id }}</a>)</span></td>
                        </tr>
                        <tr v-if="selectedImage['_source'].type?.name">
                            <th scope="row" class="table-row-label">Type</th>
                            <td class="w-100"><a href="#" @click="setSearchKeyword(`type.name:&quot;${selectedImage['_source'].type?.name}&quot;`, true);">{{ selectedImage['_source'].type?.name }}</a> <span v-if="selectedImage['_source'].type?.id">(<a href="#" @click="setSearchKeyword(`type.id:${selectedImage['_source'].type?.id}`, true);">{{ selectedImage['_source'].type?.id }}</a>)</span></td>
                        </tr>
                        <tr>
                            <th scope="row" class="table-row-label">Credit</th>
                            <td class="w-100">{{ selectedImage['_source'].credit }}</td>
                        </tr>
                        <tr v-show="selectedImage['_source'].tags && selectedImage['_source'].tags.length">
                            <th scope="row" class="table-row-label">Tags</th>
                            <td class="w-100 tags">
                                <a href="#" v-for="tag in selectedImage['_source'].tags" :key="tag" class="badge bg-primary me-1" @click="setSearchKeyword(`tags:&quot;${tag}&quot;`, true);">{{ tag }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!--
                <label class="form-label">Uploaded By</label>
                <span>{{ selectedImage.document.sourceUser }}</span>
                -->
            </div>

            <div class="pb-2 mb-1 field-bottom-border">
                <label class="form-label w-100">Title <i class="ps-1 far fa-copy small cursor" @click="copy(selectedImage['_source'].title)"></i></label>
                <div>{{ selectedImage['_source'].title }}</div>
            </div>

            <div class="pb-2 mb-1">
                <label class="form-label">Description <i class="ps-1 far fa-copy small cursor" @click="copy(selectedImage['_source'].description)"></i></label>
                <!--<Textarea v-model="selectedImage.document.description" :disabled="true" :autoResize="true" rows="5" class="w-100" style="color: black" />-->
                <div>{{ selectedImage['_source'].description }}</div>
            </div>
        </div>

        <template #footer>
            <div class="pt-2" id="imgmgr_modal_footer">
                <div class="text-success float-start copied-text pt-2 pe-3" v-show="copied">Copied to Clipboard</div>
                <Button label="Delete" severity="danger" outlined class="me-2" @click="deleteImage(selectedImage)" v-show="userHasAccessToRole('Image-Library-Admin')" :disabled="!selectedImage['_source'].license.id" />
                <!--<Button label="Copy JSON" class="p-button-secondary p-button-outlined" @click="copy(selectedImage.document)" />-->
                <SplitButton label="Copy" class="p-button-custom me-2" severity="secondary" outlined @click="copy('https://media.townhall.com/cdn/hodl/' + image['_source'].path)" :model="getCopyJsonActions(selectedImage)" :key="selectedImage['_id']" />
                <Button label="Edit" severity="secondary" @click="editImage(selectedImage)" v-show="userHasAccessToRole('Image-Library-Admin')" />
            </div>
        </template>
    </Dialog>

    <!-- Modal: Edit Image -->
    <ImageLibraryEdit :imageId="editImageId" @close="closeEditModal" />

    <Sidebar v-model:visible="showHelp" position="right" class="p-sidebar-md">
        <ImageLibrarySearchHelp />
    </Sidebar>

</template>

<script>
//import { ref, onBeforeMount, onMounted, watch } from 'vue';
import { ref, inject, onBeforeMount, onMounted } from 'vue';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { getFilenameFromPath } from '@/utils/ThmStringUtils';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useRoute, useRouter } from 'vue-router';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { getMimeOrResizedImage, resizeImageUrl } from '@/utils/ThmImageUtils';
import { useClipboard } from '@vueuse/core';
import { useConfirm } from 'primevue/useconfirm';
import { isDefined } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import ConfirmDialog from 'primevue/confirmdialog';
import MediaService from '@/service/MediaService';
import ImageService from '@/service/ImageService';
import AdminService from '@/service/AdminService';
import PaginationThm from '@/components/PaginationThm.vue';
import DateFormat from '@/components/DateFormat.vue';
import ImageLibrarySearchHelp from '@/components/ImageLibrary/SearchHelp.vue';
import ImageLibraryEdit from './Edit.vue';
import ImageLibraryUpload from './Upload.vue';
import ellipsize from 'ellipsize';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'ImageLibrary',
    components: {
        ConfirmDialog,
        DateFormat,
        ImageLibraryEdit,
        ImageLibrarySearchHelp,
        ImageLibraryUpload,
        PaginationThm
    },
    setup() {
        const mediaService = new MediaService();
        const imageService = new ImageService();
        const adminService = new AdminService();
        const { copy, copied } = useClipboard();
        const appSettings = inject('appSettings');
        const toast = useToast();
        //const keys = useMagicKeys();
        const confirm = useConfirm();
        const { user } = useAuth0();
        const router = useRouter();
        const route = useRoute();

        const searchKeywords = ref(null);
        const searchSort = ref('date');
        const images = ref([]);
        const selectedImage = ref({});
        const totalRows = ref(0);
        const showDetailModal = ref(false);
        //const showEditModal = ref(true);
        const editImageId = ref(null);
        const showHelp = ref(false);
        const resultsPerPage = 12;
        const lsImageLibray = ref({});
        const activeTab = ref(0);
        const currentPage = ref(0);
        const licenses = ref([]);

        onBeforeMount(() => {
            lsImageLibray.value = JSON.parse(localStorage.getItem('adminImageLibray')) || {};

            imageService.getImageLicenses().then((res) => {
                if(res && res.length) licenses.value = res;
            });
        });

        onMounted(() => {
            if(route.query) {
                if(route.query.s) {
                    searchKeywords.value = route.query.s;
                    searchSort.value = route.query.sort || searchSort.value;
                }
                // Set active tab
                if(!isNaN(route.query.tabIndex)) {
                    activeTab.value = parseInt(route.query.tabIndex);
                }
            }
            fetchImages(0);
        });

        /*
        whenever(keys['Meta+K'], () => {
            document.getElementById('SearchText').select();
        });
        */

        function getCopyJsonActions(image) {
            return [
                {
                    label: 'JSON',
                    command: () => {
                        // Copy the raw JSON from ElasticSearch:
                        let source = { ...image['_source'] };
                        source = {
                            '_id': image['_id'],
                            '_url': 'https://media.townhall.com/cdn/hodl/' + source.path,
                            //'_url': getImagePrefixByLicenseId(selectedImage.value['_source'].license.id, source.path),
                            ...source
                        };
                        copy(JSON.stringify(source, null, '\t'));
                    }
                },
                {
                    label: 'Image ID',
                    command: () => {
                        copy(image['_id']);
                    }
                }
            ];
        }

        function submitSearch() {
            let qs = searchKeywords.value && searchKeywords.value.trim() ? { s: searchKeywords.value, sort: searchSort.value } : null;
            console.log('qs', qs);
            router.push({ query: qs });
            fetchImages(0);
        }

        function fetchImages(page, updateHistory = true) {
            currentPage.value = page;
            if(updateHistory) updateSearchHistory(searchKeywords.value);

            // Sorting strictSortByDate: true reduces relevance - better to search with date decay
            //mediaService.imageSearch({ keywords: searchKeywords.value, pageSize: resultsPerPage, offset: resultsPerPage * page, strictSortByDate: true }).then((res) => {
            //imageService.search({ searchText: searchKeywords.value, pageSize: resultsPerPage, pageIndex: page, decay: 0.9, logicalOperator: "AND" }).then((res) => {
            imageService.search({ searchText: searchKeywords.value, domain: appSettings.domain, pageSize: resultsPerPage, pageIndex: page, sort: searchSort.value, logicalOperator: "AND" }).then((res) => {
                images.value = res.hits?.hits;
                //totalRows.value = res.hits.total.value
                // Basically make the max page size one less else Elasticsearch complains:
                //totalRows.value = res.hits.total.value/resultsPerPage > Math.floor(10000/resultsPerPage) ? Math.floor(10000/resultsPerPage)*resultsPerPage : res.hits.total.value;
                let maxQuerySize = 50000;
                totalRows.value = res.hits.total.value/resultsPerPage > Math.floor(maxQuerySize/resultsPerPage) ? Math.floor(maxQuerySize/resultsPerPage)*resultsPerPage : res.hits.total.value;
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Searching Images', detail: err.message || err, life: 5000 });
            });
        }

        function updateSearchHistory(keywords) {
            if(!keywords || !keywords.trim() || !keywords.replace(/[^a-z0-9]/gi, '') || keywords.includes('path:')) return;
            //lsImageLibray.value = { ...lsImageLibray.value };
            //localStorage.setItem('adminImageLibray', JSON.stringify(lsImageLibray.value));

            if(!lsImageLibray.value.searchHistory) lsImageLibray.value.searchHistory = [];

            if(lsImageLibray.value.searchHistory.includes(keywords.trim())) {
                // Move existing item to beginning: https://stackoverflow.com/a/23921775/3799374
                lsImageLibray.value.searchHistory.sort(function(x,y){ return x == keywords.trim() ? -1 : y == keywords.trim() ? 1 : 0; });
            } else {
                // Add to beginning:
                lsImageLibray.value.searchHistory.unshift(keywords.trim());
            }
            lsImageLibray.value.searchHistory = lsImageLibray.value.searchHistory.slice(0, 5);
            localStorage.setItem('adminImageLibray', JSON.stringify(lsImageLibray.value));
        }

        function deleteImage(image) {
            confirm.require({
                message: 'Are you sure you want to permanently delete this image?',
                header: 'Delete Image',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    imageService.deleteImage(image['_id']).then((res) => {
                        console.log({res});
                        //if(res.result && res.result == 'deleted') {
                        if(!res.error) {
                            showDetailModal.value = false;
                            images.value = images.value.filter(( obj ) => {
                                return obj['_id'] != image['_id'];
                            });

                            // Delete blob
                            if(image['_source'].path && image['_source'].license.id) {
                                mediaService.deleteImage(image['_source'].path, image['_source'].license.id).then((res) => {
                                    //console.log('image', image);
                                    if(image['_id']) {
                                        let imgTemp = { ...image, id: image['_id'] };
                                        adminService.log({ logTypeId: 16, referenceId: image['_id'], message: 'Image Deleted', content: JSON.stringify({}, null, 2), original: JSON.stringify(imgTemp, null, 2), language: 'json' });
                                    }
                                    console.log('🗑 Deleted:', res);
                                });
                            }

                            toast.add({ severity: 'success', summary: 'Delete Image', detail: 'Image deleted successfully', life: 3000 });
                        } else {
                            toast.add({ severity: 'error', summary: 'Error', detail: res.error, life: 4000 });
                        }
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Failed to delete image', detail: err.message || err, life: 4000 });
                    });

                }
            });
        }

        function setSearchKeyword(keywords, closeModal = false) {
            searchKeywords.value = keywords;
            fetchImages(0, false);
            let qs = searchKeywords.value && searchKeywords.value.trim() ? { s: searchKeywords.value } : null;
            router.push({ query: qs });
            if(closeModal) {
                updateSearchHistory(searchKeywords.value);
                showDetailModal.value = false;
            }
        }

        function toggleDetail(image) {
            image.showDetail = !image.showDetail;
        }

        function toggleHelp() {
            showHelp.value = !showHelp.value;
        }

        function chooseImage(image) {
            selectedImage.value = image;
            showDetailModal.value = true;
        }

        function changeTab(tab) {
            let qs = route.query;
            qs = { ...qs, tabIndex: tab.index };
            if(tab.index) {
                qs = { ...qs, tabIndex: tab.index };
            } else {
                delete qs.tabIndex;
            }
            router.push({ query: qs });
        }

        function onPage(e) {
            fetchImages(e.page);
        }

        function onUpload(image) {
            if(console.log.hide) console.log({image});
            fetchImages();
        }

        function copyJSON(image) {
            // Copy the image structure from the search endpoint
            // copy(JSON.stringify(image, null, '\t'));

            // Copy the raw JSON from ElasticSearch:
            imageService.getImage(image.id).then((res) => {
                if(isDefined(res['_source'])) {
                    let source = res['_source'];
                    source = {
                        '_id': res['_id'],
                        '_url': 'https://media.townhall.com/cdn/hodl/' + source.path,
                        //'_url': getImagePrefixByLicenseId(selectedImage.value['_source'].license.id, source.path),
                        ...source
                    };
                    copy(JSON.stringify(source, null, '\t'));
                } else {
                    toast.add({ severity: 'error', summary: 'Error', detail: 'Error fetching JSON' });
                }
            });
        }

        function editImage(image) {
            editImageId.value = image['_id'];
        }

        function closeEditModal(updatedImage) {
            if(updatedImage && updatedImage.id) {
                selectedImage.value['_source'] = updatedImage;

                // Update image in results
                for(let idx = 0; idx < images.value.length; idx++) {
                    if(images.value[idx]['_id'] == updatedImage.id) {
                        //console.log('images.value[idx].document', images.value[idx]['_source']);
                        //console.log('selectedImage.value', selectedImage.value);
                        images.value[idx]['_source'] = selectedImage.value['_source'];
                    }
                }
            }
            editImageId.value = null;
        }

        function getLicenseColor(licenseId) {
            let lic = licenses.value?.find(({ id }) => id == licenseId);
            return lic?.color ?? null;
        }

        return {
            activeTab,
            appSettings,
            changeTab,
            chooseImage,
            closeEditModal,
            copied,
            copy,
            copyJSON,
            currentPage,
            deleteImage,
            editImage,
            editImageId,
            ellipsize,
            fetchImages,
            formatDateTime,
            getCopyJsonActions,
            getFilenameFromPath,
            getLicenseColor,
            getMimeOrResizedImage,
            //getImagePrefixByLicenseId,
            images,
            lsImageLibray,
            onPage,
            onUpload,
            resizeImageUrl,
            resultsPerPage,
            searchKeywords,
            searchSort,
            selectedImage,
            setSearchKeyword,
            showDetailModal,
            //showEditModal,
            showHelp,
            submitSearch,
            toggleDetail,
            toggleHelp,
            totalRows,
            user,
            userHasAccessToRole
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    /*
    .image-item {
        color: #000;

        &:hover {
            text-decoration: underline;
        }

        &__photo {
            min-height: 50px;
        }
    }
    */
    .btn-help {
        position: absolute;
        right: 0.5em;
        /* top: 100px; */
        z-index: 1;
    }

    span.search-history-item a {
        color: #fff;
        font-weight: 500;
        cursor: pointer;
    }

    .thmim-button-primary {
        background-color: #2196f3;
        border: #2196f3;

        &:hover {
            background-color: darken(#2196f3, 6%);
            border: darken(#2196f3, 6%);
        }
    }

    .thmim-result {
        padding: 1em;
        //width: 24%;
        min-height: 195px;

        img {
            cursor: pointer;
            min-height: 135px;
            border-bottom: 1px solid #dfe2e6;
        }
        a.fa-caret-circle-up,
        a.fa-caret-circle-down {
            color: var(--primary-color);
            padding-top: 0.65em;
            cursor: pointer;
            padding: 0.1em;
        }
        &__card-body {
            .card-text {
                font-size: 0.8em;
                //min-height: 56px;
            }
        }
        &__detail {
            background-color: var(--bluegray-100);
            z-index: 10;

            .card-text {
                &__label {
                    color: var(--bluegray-700);
                    font-weight: bold;
                }
            }
        }
    }
}

#image_modal {
    img {
        border: 1px solid #dfe2e6;
    }
    .form-label {
        margin-bottom: 0.2rem;
        font-weight: 600;
    }
    .field-bottom-border {
        border-bottom: 1px solid #dfe2e6;
    }
    .cursor {
        cursor: copy;
    }
    table {
        .table-row-label {
            white-space: nowrap;
            padding-left: 0px;
            font-weight: 600;
        }
        td.tags {
            a:hover {
                color: #fff;
            }
        }
    }
    div.preview-image {
        max-height: 400px;
        background-position: top;
        overflow-y: scroll;
    }
}
</style>

<style lang="scss">
#imgmgr_modal_footer {
    /*
    div.hide-dropdown-button {
        .p-button-icon-only {
            padding-right: 0.6em;
            padding-left: 0.6em;
        }
    }
    */
    div.p-button-custom {
        .p-button-icon-only {
            padding-right: 0.6em;
            padding-left: 0.6em;
        }
        .p-splitbutton-defaultbutton {
                margin: 0;
        }
    }
}
</style>
