<template>
    <ConfirmDialog />

    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-3 actions">
                <div class="card card-border-color card-border-color-primary shows">
                    <div class="card-header card-header-divider">Add Item</div>

                    <div class="card-body">
                        <div class="mb-3">
                            <label for="Label" class="form-label required">Label</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.label" id="Label" />
                        </div>

                        <div class="mb-3">
                            <label for="Url" class="form-label required">URL / Path</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.url" id="Url" />
                        </div>

                        <div class="mb-3">
                            <InputSwitch v-model="addMenuItem.target" id="Target" />
                            <label for="Target" class="switch-label">Open in new tab</label>
                        </div>

                        <div class="mb-3">
                            <label for="Classes" class="form-label">CSS Classes</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.classes" id="Classes" />
                            <span class="help">Optional CSS classes, separated by spaces.</span>
                        </div>

                        <div class="mb-3">
                            <label for="Icon" class="form-label">Icon</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.icon" id="Icon" />
                        </div>

                        <div class="mb-3">
                            <Button label="Add to Menu" icon="pi pi-angle-double-right" iconPos="right" class="float-end" @click="addItem()" v-if="!addMenuItem.id && addMenuItem.id !== 0" />
                            <Button label="Clear" class="p-button-secondary p-button-outlined float-end" @click="item = { item: {} }" v-else />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-9">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">
                        <div v-show="userHasAccessToRoleFn('Admin')" class="float-end">
                            <Button icon="pi pi-trash" class="btn-add p-button-secondary p-button-tiny me-1" iconPos="right" @click="deleteMenu()" title="Delete Menu (admin only)" />
                            <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny" iconPos="right" @click="showModal = true" title="Add New Menu" />
                        </div>
                        Menu Structure
                    </div>
                    <div class="card-body">
                        <Dropdown id="ActiveMenu" v-model="selectedMenu" :options="menus" optionLabel="label" optionValue="websiteMenuId" class="d-flex" @change="chooseMenu" />

                        <form class="form-floating mt-3" v-if="selectedMenu">
                            <input type="text" class="form-control" id="Description" v-model="menu.label" />
                            <label for="Description">Menu Description</label>
                        </form>

                        <h5 class="my-3">Menu Items</h5>
                        <!--<menu-draggable :menu="menu.menu" v-if="menu.menu" @menu-change="menuChange" @remove="removed" />-->
                        <menu-draggable :menu="menu.menu" v-if="menu.menu" @menu-change="menuChange" />

                        <Button label="Save Menu" class="float-end" @click="saveMenu()" />
                    </div>
                </div>

                <Dialog v-model:visible="showModal">
                    <template #header>
                        <h3>Add New Menu</h3>
                    </template>

                    <div class="mb-1">
                        <label for="addLabel" class="form-label required">Label</label>
                        <InputText type="text" class="form-control" v-model="newMenu.label" id="addLabel" placeholder="New Menu" />
                    </div>

                    <div class="mb-1">
                        <label for="addSlug" class="form-label required">Slug</label>
                        <InputText type="text" class="form-control" v-model="newMenu.slug" id="addSlug" placeholder="new-menu" />
                    </div>

                    <div class="mb-1">
                        <label for="addView" class="form-label required">View Name</label>
                        <InputText type="text" class="form-control" v-model="newMenu.view" id="addView" placeholder="Default" />
                    </div>

                    <template #footer>
                        <Button label="Add Menu" @click="addNewMenu()" />
                    </template>
                </Dialog>
            </div>
        </div>
    </form>
</template>

<script>
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import MenuService from '@/service/MenuService';
import menuDraggable from './MenuComponent.vue';
import ConfirmDialog from 'primevue/confirmdialog';

// Source: https://github.com/SortableJS/vue.draggable.next/blob/master/example/components/nested-example.vue
export default {
    name: 'MenuEditor',
    data() {
        return {
            menuService: new MenuService(this.$auth.getAccessToken()),
            menus: [
                {
                    websiteMenuId: 1,
                    websiteMenuType: 0,
                    label: 'Primary Menu',
                    slug: 'primary',
                    dateUpdated: '0001-01-01T00:00:00',
                    dateCreated: '0001-01-01T00:00:00',
                    view: 'Default',
                    menu: [
                        {
                            icon: null,
                            label: 'Columnists',
                            url: '/columnists',
                            target: null,
                            classes: null,
                            sortOrder: 0,
                            menu: [],
                        },
                        {
                            icon: null,
                            label: 'Tipsheet',
                            url: '/tipsheet',
                            target: null,
                            classes: null,
                            sortOrder: 0,
                            menu: [],
                        },
                        {
                            icon: null,
                            label: 'Cartoons',
                            url: '/political-cartoons',
                            target: null,
                            classes: null,
                            sortOrder: 0,
                            menu: [],
                        },
                        {
                            icon: null,
                            label: 'Sections',
                            url: null,
                            target: null,
                            classes: null,
                            sortOrder: 0,
                            menu: [
                                {
                                    icon: null,
                                    label: 'Townhall TV',
                                    url: '/tv',
                                    target: null,
                                    classes: null,
                                    sortOrder: 0,
                                    menu: [
                                        {
                                            icon: null,
                                            label: 'Testing',
                                            url: '/testing',
                                            target: null,
                                            classes: null,
                                            sortOrder: 0,
                                            menu: [],
                                        },
                                    ],
                                },
                                {
                                    icon: null,
                                    label: 'Election',
                                    url: '/election',
                                    target: null,
                                    classes: null,
                                    sortOrder: 0,
                                    menu: [],
                                },
                            ],
                        },
                        {
                            icon: null,
                            label: 'Podcasts',
                            url: '/podcasts',
                            target: null,
                            classes: null,
                            sortOrder: 0,
                            menu: [],
                        },
                    ],
                },
            ],
            selectedMenu: null,
            menu: {},
            user: null,
            addMenuItem: {},
            showModal: false,
            newMenu: { websiteMenuId: 0, view: 'Default', menu: [] },
        };
    },
    components: {
        ConfirmDialog,
        menuDraggable,
    },
    mounted() {
        // Get menus
        /*
        this.menuService.getMenus().then((res) => {
            if (res && typeof res === 'object') {
                this.menus = res;
                //console.log('this.menus', this.menus);
                if(this.menus.length) {
                    this.selectedMenu = this.menus[0].websiteMenuId;
                    this.menu = this.menus[0];
                }
            }
        }).catch((err) => {
            this.$toast.add({ severity: 'error', summary: 'Error Fetching Menus', detail: err.message || err });
        });
        */
        this.selectedMenu = this.menus[0].websiteMenuId;
        this.menu = this.menus[0];

        // Get current user
        this.$auth.getUser().then((user) => (this.user = user));
    },
    methods: {
        addItem() {
            //console.log('ADD:', this.addMenuItem);
            if (typeof this.addMenuItem.label == 'undefined' || !this.addMenuItem.label.trim() || this.addMenuItem.url == 'undefined' || !this.addMenuItem.url.trim()) {
                this.$toast.add({ severity: 'error', summary: 'Error Adding Menu Item', detail: 'Label and URL fields may not be empty', life: 5000 });
                return;
            }

            this.menu.menu = [...this.menu.menu, this.addMenuItem];
            this.addMenuItem = {};
        },
        chooseMenu() {
            this.menu = this.menus.find((obj) => obj.websiteMenuId == this.selectedMenu);
        },
        saveMenu() {
            this.$toast.add({ severity: 'info', summary: 'Disabled', detail: 'See browser console for resulting menu.', life: 3000 });
            console.log('SAVE:', this.menu);

            //console.log('SAVE', this.menu);
            // Save menu (disable in demo)
            /*
            this.menuService.saveMenu(this.menu).then((res) => {
                console.log('Menu saved:', res);
                // Display notification
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Saved', life: 3000 });
            }).catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'Error Saving Episode', detail: err.message || err, life: 3000 });
            });
            */
        },
        menuChange(obj) {
            //console.log('ROOT: menuChange(obj)', obj);
            this.menu.menu = obj;
        },
        addNewMenu() {
            if (typeof this.newMenu.label == 'undefined' || !this.newMenu.label.trim() || this.newMenu.slug == 'undefined' || !this.newMenu.slug.trim()) {
                this.$toast.add({ severity: 'error', summary: 'Error Saving Menu', detail: 'Label and Slug fields may not be empty', life: 5000 });
                return;
            }

            // Save new menu (disabled in demo)
            /*
            this.menuService.saveMenu(this.newMenu).then((res) => {
                // Add menu to dropdown
                this.menu = res;
                this.menus = [...this.menus, res];
                this.selectedMenu = res.websiteMenuId;

                // Display notification
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Saved', life: 3000 });
                this.newMenu = { view: 'Default', menu: [] };
                this.showModal = false;
            }).catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'Error Saving Menu', detail: err.message || err, life: 3000 });

            });
            */

            // Demo:
            this.menus = [...this.menus, this.newMenu];
            this.selectedMenu = 0;
            // Display notification
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Saved', life: 3000 });
            this.newMenu = { view: 'Default', menu: [] };
            this.showModal = false;
        },
        userHasAccessToRoleFn(role = null) {
            return userHasAccessToRole(role);
        },
        deleteMenu() {
            if (typeof this.selectedMenu == 'undefined' || !this.selectedMenu) return;
            this.$toast.add({ severity: 'info', summary: 'Disabled', detail: 'Disabled in Demo', life: 3000 });

            /*
            this.$confirm.require({
                message: 'Are you sure you want to permanently delete this menu? This is dangerous and irreversible!',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.menuService.deleteMenu(this.selectedMenu).then(() => {
                        // Remove menu from menu list
                        this.menus = this.menus.filter(( menu ) => {
                            return menu.websiteMenuId !== this.selectedMenu;
                        });

                        // Select first menu if one exists
                        if(this.menus.length) {
                            this.selectedMenu = this.menus[0].websiteMenuId;
                            this.menu = this.menus[0];
                        } else {
                            this.selectedMenu = null;
                            this.menu = {};
                        }

                        // Display notification
                        this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Deleted', life: 3000 });
                    }).catch((err) => {
                        this.$toast.add({ severity: 'error', summary: 'Error Deleting Menu', detail: err.message || err, life: 3000 });
                    });
                }
            });
            */
        },
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
}
label.required::after {
    content: '*';
    color: rgb(255, 0, 0);
}
</style>
