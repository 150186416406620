<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'LoginCallback',
    data() {
        return {
            error: null,
        };
    },
    render() {
        return this.error;
    },
});
</script>
