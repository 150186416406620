<template>
    <AutoComplete v-model="autoCompleteTag" class="" dropdownMode="current" :placeholder="props.placeholder" :suggestions="filtered" :minLength="2" :dropdown="false" @complete="search($event)" field="name" />
</template>

<script>
import TagsService from '@/service/TagsService';
import { isObject } from '@vueuse/core';
import { ref, watch } from 'vue';

export default {
    name: 'TagInput',
    props: {
        modelValue: [String, Object],
        placeholder: {
            type: String,
            default: 'Search for tag...'
        }

    },
    setup(props, context) {
        const tagsService = new TagsService();
        const filtered = ref([]);
        const autoCompleteTag = ref(null);

        watch(autoCompleteTag, (val) => {
            if(isObject(val)) {
                context.emit('update:modelValue', val);
            }
        });

        async function search(event) {
            filtered.value = await tagsService.getTagsAutoComplete(event.query);
        }

        return { filtered, props, search, autoCompleteTag };
    },
};
</script>
