<template>
    <MultiSelect id="MultiSites" v-model="model" :options="sites" optionLabel="description" optionValue="siteGroup" class="d-flex" placeholder="Select Sites" display="chip" />
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { isDefined } from '@vueuse/core';
//import { deviceIsOS } from '@/utils/ThmDeviceUtils';
import AdminService from '@/service/AdminService';

export default {
    name: 'SiteGroupSelectClient',
    props: ['modelValue'],
    emits: ['update:modelValue'],
    setup(_props, { emit }) {
        const adminService = new AdminService();
        const sites = ref([]);

        onMounted(() => {
            adminService.getSites().then((response) => {
                sites.value = response;
                //if(!deviceIsOS('macOS')) console.log('sites.value', sites.value);
            }).catch(function (error) {
                console.error(error);
            });
        });

        const model = computed({
            get() {
                if(isDefined(sites.value)){
                    //if(!deviceIsOS('macOS')) console.log('sites.value', sites.value);
                    return getSitesFromSiteGroup(_props.modelValue);
                }
                return _props.modelValue;
            },
            set(value) {
                 if(isDefined(sites.value)){
                    emit('update:modelValue', computeSiteGroup(value));
                 } else {
                    emit('update:modelValue', value);
                 }

            }
        });

        function getSitesFromSiteGroup(sg) {
            //if(!deviceIsOS('macOS')) console.log('sites.value', sites.value);
            let items = [];
            if(sg != null) {
                sites.value.forEach((item) => {
                    if((item.siteGroup & sg) > 0) {
                        items.push(item.siteGroup);
                    }
                });
            }

            return items
        }

        function computeSiteGroup(siteGroups) {
            let returnValue = 0
            if (siteGroups != null) {
                siteGroups.forEach((s) => {
                    returnValue += s
                })
                return returnValue
            }
        }

        return {
            model,
            sites
        }
    }
}
</script>
