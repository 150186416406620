<template>
    <BlockCopyFilterBar />

    <div class="row mb-3">
        <BlockCopyList />
        <BlockCopyEditor />
    </div>
</template>

<script>
import BlockCopyFilterBar from './BlockCopyFilterBar.vue';
import BlockCopyList from './BlockCopyList.vue';
import BlockCopyEditor from './BlockCopyEditor.vue';

export default {
    name: 'BlockCopy',
    components: {
        BlockCopyFilterBar,
        BlockCopyList,
        BlockCopyEditor
    },
};
</script>
