import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';

const adminService = new AdminService();

export const useLogStore = defineStore('Logs', {
    state: () => ({
        loading: false,
        logTypes: [],
        logs: []
    }),
   getters: {
        totalRows() {
            return this.logs.length && typeof this.logs[0].totalRows != 'undefined' ? this.logs[0].totalRows : 0;
        }
    },
    actions: {
        async load() {
            if(!this.logTypes.length) {
                this.loading = true;
                await adminService.getLogTypes().then((res) => {
                    //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Log Types', res);
                    this.logTypes = res;
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        async getLogs(params) {
            this.loading = true;
            return await adminService.getLogs(params).then((res) => {
                this.logs = res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        }
    }
});
