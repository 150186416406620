<template>
    <div class="col-sm-12 col-md-7 col-xl-6">
        <div class="card card-border-color card-border-color-primary shows">
            <div class="card-body page-list container-fluid pb-3">
                <!--<Paginator :totalRecords="blockCopyStore.totalPages" :rows="pageSize" @page="onPage($event)" v-show="blockCopyStore.blocks.length" />-->
                <PaginationThm v-model="route.query.pageIndex" :totalRecords="blockCopyStore.totalPages" :pageSize="pageSize" @page="onPage($event)" :alwaysShow="false" />

                <div class="row row__header pb-2 mb-1">
                    <div class="col-md-3 col-lg-2 d-none d-md-block"></div>
                    <div class="col-sm-1 fw-bold"><a @click="onSort('BlockCopyId')" class="sort-label pointer">ID</a></div>
                    <div class="col-sm-5 col-md-3 col-lg-4 fw-bold"><a @click="onSort('Title')" class="sort-label pointer">Description</a></div>
                    <div class="col-sm-6 col-md-5 d-none d-sm-block fw-bold text-center"><a @click="onSort('DatePublished')" class="sort-label pointer">Status</a></div>
                </div>

                <p class="fst-italic text-muted ps-1 pt-4" v-show="Object.keys(route.query).length && !blockCopyStore.blocks.length">No blocks found matching criteria.</p>

                <div v-for="block in blockCopyStore.blocks" :key="block.blockCopyId" class="row vitual-page item pt-2" v-bind:class="{ active: route.params.blockCopyId == block.blockCopyId }">
                    <div class="col-md-3 col-lg-2 d-none d-md-block">
                        <Button label="Edit" class="btn-edit" @click="editBlock(block.blockCopyId)" />
                    </div>
                    <div class="col-sm-1 ps-1 d-none d-lg-block">
                        {{ block.blockCopyId }}
                    </div>
                    <div class="col-sm-5 col-md-3 col-lg-4 ps-1">
                        <a class="d-block d-md-none" @click="editBlock(block.blockCopyId)">{{ block.title }}</a>
                        <span class="d-none d-md-block">{{ block.title }}</span>
                    </div>
                    <div class="col-sm-6 col-md-5 d-none d-sm-block text-center">
                        <publish-label-status :datetime="block.datePublished" :status="block.isPublished ? 1 : 0" :title="block.isPublished ? 'Published' : 'Not Published'" />
                    </div>
                </div>

                <p class="fst-italic ps-4 pt-4 d-block" v-if="blockCopyStore.totalPages < 1">Nothing here yet</p>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, watch } from 'vue';
import { useBlockCopyStore } from '@/stores/BlockCopyStore';
import { isEmptyDate, formatDateTime } from '@/utils/ThmDateTimeUtils';
import PublishLabelStatus from '@/components/PublishLabelStatus.vue';
import PaginationThm from '@/components/PaginationThm.vue';

export default {
    name: 'BlockCopyList',
    components: {
        PublishLabelStatus,
        PaginationThm
    },
    setup() {
        const blockCopyStore = useBlockCopyStore();
        const router = useRouter();
        const route = useRoute();

        const pageSize = 15;

        // Watch for route changes
        watch(
            //() => route.query, async (params) => {
            () => route.query, (params, fromParams) => {
                if(console.log.hide) console.log(params, fromParams);
                //if(console.log.hide) console.log({ params });
                blockCopyStore.getBlockCopies(params).then((res) => {
                    if(console.log.hide) console.log('res', res);
                });
            }
        );

        onMounted(() => {
            blockCopyStore.getBlockCopies(route.query).then((res) => {
                if(console.log.hide) console.log('res', res);
            });
        });

        function editBlock(blockCopyId) {
            router.push({ path: `/manage/blockcopy/${blockCopyId}`, query: route.query });
        }

        function onPage(e) {
            router.push({ query: { ...route.query, pageIndex: e.page } });
            //blockCopyStore.getBlockCopies({ ...route.query, pageIndex: e.page });
        }

        function onSort(orderBy = null) {
            if(!orderBy) return;
            let orderDir = route.query.orderDir == 'DESC' ? 'ASC' : 'DESC';
            router.push({ query: { ...route.query, orderBy: orderBy, orderDir: orderDir } });
        }

        return {
            blockCopyStore,
            editBlock,
            formatDateTime,
            isEmptyDate,
            onPage,
            onSort,
            pageSize,
            route
        };
    },
};
</script>

<style lang="scss" scoped>
div.page-list {
    .row.vitual-page {
        padding-bottom: 0.5em;

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }
    }
    div.row__header {
        border-bottom: 1px solid #d9d9d9;
    }
    .sort-label {
        color: #000;
        &.pointer {
            cursor: pointer;
        }
    }
}
</style>
