import BaseService from './BaseService';

export default class AppleNewsService extends BaseService {

    // Special, accepts Core.Models.Admin.Post type
    async addToQueue(post) {
        console.log('Save to AppleNews:', post);
        if(!post) {
            console.error('AppleNews Error: Post is null');
            return;
        }
        let endpoint = `/api/applenews/${post.siteGroup}/${post.postId}`;
        //console.log('AppleNews Endpoint POST:', endpoint);
        console.log('Save AppleNews Post:', post);
        return await this.apiClient.post(endpoint, post).then((res) => res.data);
    }
    async deletePost(siteGroup, postId) {
        // console.log('Delete from AppleNews:', post);
        // if(!post) {
        //     console.error('AppleNews Error: Post is null');
        //     return;
        // }
        //console.log('AppleNews Endpoint POST:', endpoint);
        console.log('Delete AppleNews Post:', postId);
        //return await this.apiClient.post(endpoint, post).then((res) => res.data);
        return await this.apiClient.delete(`/api/applenews/${siteGroup}/${postId}`).then((res) => res.data);
    }

}
