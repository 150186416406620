/**
 * Brid video embed plugin for Froala
 */

import FroalaEditor from 'froala-editor/js/froala_editor.min';

// Define popup template
FroalaEditor.POPUP_TEMPLATES['embedBridVideoPlugin.popup'] = '[_BUTTONS_][_CUSTOM_LAYER_]';

// Allow the insertion of empty <brid /> tags
FroalaEditor.DEFAULTS.htmlAllowedTags.push('brid');

// Define <brid /> tags as a "block" tag to prevent it from being wrapped in paragraph <p> tags
FroalaEditor.BLOCK_TAGS.push('brid');

// Define popup buttons
Object.assign(FroalaEditor.DEFAULTS, { popupButtons: ['popupClose'] });

// Create plugin
FroalaEditor.PLUGINS.embedBridVideoPlugin = function (editor) {
    // Create embed code popup
    function initPopup() {
        // Create the list of buttons. Note: This plugin doesn't have any at the top of the popup/overlay
        var popup_buttons = '';
        /*
        if (editor.opts.popupButtons.length > 1) {
            popup_buttons += '<div class="fr-buttons">';
            popup_buttons += editor.button.buildList(editor.opts.popupButtons);
            popup_buttons += '</div>';
        }
        */

        // Build popup template
        var template = {
            buttons: popup_buttons,
            custom_layer:
                '<div class="fr-embedcode-embed-layer fr-layer fr-active" id="fr-embedcode-embed-layer-' +
                editor.id +
                '"><div class="fr-input-line"><input id="fr-embedcode-embed-layer-text' +
                editor.id +
                '" type="text" placeholder="' +
                editor.language.translate('Brid Video ID') +
                '" tabIndex="1" aria-required="true" rows="5"></input></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="embedBridVideoInsert" tabIndex="2" role="button">' +
                editor.language.translate('Insert') +
                '</button></div></div>',
        };

        // Create popup
        var $popup = editor.popups.create('embedBridVideoPlugin.popup', template);

        return $popup;
    }

    // Show embed code popup
    function showPopup() {
        // Get the popup object defined above
        var $popup = editor.popups.get('embedBridVideoPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container
        editor.popups.setContainer('embedBridVideoPlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup
        // editor.popups.refresh('embedBridVideoPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="embedBridVideoButton"]');

        // Set the popup's position
        var left = $btn.offset().left + $btn.outerWidth() / 2;
        var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        // Show the custom popup. The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show('embedBridVideoPlugin.popup', left, top, $btn.outerHeight());
    }

    // Hide the custom popup
    function hidePopup() {
        editor.popups.hide('embedBridVideoPlugin.popup');
    }

    // Methods visible outside the plugin
    return {
        showPopup: showPopup,
        hidePopup: hidePopup,
    };
};

// Define an icon and command for the button that opens the custom popup
// Non-visual list of available icons: https://github.com/froala/wysiwyg-editor/issues/3478#issuecomment-539497338
//FroalaEditor.DefineIcon('embedBridVideoIcon', { NAME: 'insertEmbed', SVG_KEY: 'video' });
FroalaEditor.DefineIcon('embedBridVideoIcon', {SRC: '/images/froala-brid-icon.svg', ALT: 'Image button', template: 'image'});
FroalaEditor.RegisterCommand('embedBridVideoButton', {
    title: 'Insert Brid Video',
    icon: 'embedBridVideoIcon',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    plugin: 'embedBridVideoPlugin',
    callback: function () {
        this.embedBridVideoPlugin.showPopup();
    },
});

// Define custom popup close button icon and command.
//FroalaEditor.DefineIcon('popupClose', { NAME: 'times', SVG_KEY: 'times'});
FroalaEditor.RegisterCommand('popupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function () {
        this.embedBridVideoPlugin.hidePopup();
    },
});

// Define custom popup 1.
//$.FroalaEditor.DefineIcon('popupButton1', { NAME: 'bell-o' });
FroalaEditor.RegisterCommand('embedBridVideoInsert', {
    title: 'Insert Brid Video',
    undo: true,
    focus: true,
    callback: function () {
        var input = this.$box.find('.fr-embedcode-embed-layer input');
        if(!input.val().trim()) {
            this.embedBridVideoPlugin.hidePopup();
            return;
        }

        // Transform: Strip non-alphanumeric characters and non-hyphen/underscores
        var _content = input.val().replace(/[^0-9a-z_-]/gi, '');
        input.val(_content);

        // Wrap video id in <brid /> tag
        // Froala will not insert empty tags, so we have to add a space to the content: https://github.com/froala/wysiwyg-editor/issues/248#issuecomment-59929511
        //input.val(`<brid id="${input.val()}" contenteditable="false" >&nbsp;</brid>`);
        input.val(`<brid id="${input.val()}" contenteditable="false" class="fr-deletable">&nbsp;</brid>`);

        //this.html.insert('<p><brid id="1234"></brid></p>');
        this.html.insert(input.val());
        input.val('');
        this.embedBridVideoPlugin.hidePopup();
        this.events.focus();
    },
});
