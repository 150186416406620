<template>
    <ConfirmDialog />
    <Breadcrumbs :post="liveBlogStore.post" class="mb-2" />
    <div class="row mb-4 liveblog-threads-container" v-if="route.params.postId && liveBlogStore.post.postId">
        <div class="col-sm-12 col-md-6">
            <div class="card card-border-color card-border-color-primary mb-3">
                <div class="card-body">
                    <span class="badge bg-danger d-inline-block mb-1 me-1" v-if="isLiveblogPostLive(liveBlogStore.post)">Live</span>
                    <span class="badge d-inline-block mb-1" :class="connectionStateCssClass" v-if="connectionState" v-html="connectionState"></span>
                    <h4 class="mb-1">
                        {{ liveBlogStore.post.title }}
                    </h4>
                    <small class="d-block text-muted mb-2">
                        <span v-if="!deviceIsMobile()">
                            Published:
                            {{ formatDateTime(liveBlogStore.post.datePublished, "MMMM do, y 'at' h:mm aaa ") }}
                        </span>
                        <span v-if="liveBlogStore.threads?.length && !deviceIsMobile()">&bull;</span>
                        <span v-if="liveBlogStore.threads?.length">
                            Updated:
                            {{ formatDateTime(liveBlogStore.post.latestDate, "MMMM do, y 'at' h:mm aaa ") }}
                        </span>
                    </small>
                    <div class="lb-toolbar">
                        <SpinnerThm class="spinner float-end" size="1.8em" v-show="liveBlogStore.loading" />
                        <router-link :to="{ name: 'LiveBlogPost', params: { postId: liveBlogStore.post.postId } }" type="button" class="btn btn-outline-secondary me-1 pi pi-cog" role="button" aria-label="Settings" title="Live Blog Settings" v-show="userHasAccessToRole('Admin')"></router-link>
                        <a href="#" @click="refresh" class="btn btn-outline-secondary me-1 pi pi-refresh" role="button" aria-label="Reload" title="Reload"></a>
                        <a :href="getPermalink(liveBlogStore.post, liveBlogStore.post.status != 1)" type="button" class="btn btn-outline-secondary me-1 pi pi-external-link" role="button" aria-label="Open in New Tab" title="Open in New Tab" target="_blank"></a>
                        <a href="#" @click="copyPermalink(liveBlogStore.post)" type="button" class="btn btn-outline-secondary me-1 pi pi-paperclip" role="button" aria-label="Link" title="Copy Link"></a>
                        <span class="ms-1 text-success" v-show="copied">Copied Link</span>
                    </div>
                </div>
            </div>

            <div id="liveblog_edit_thread" style="position: relative; top: -70px;"></div>
            <div class="card card-border-color card-border-color-primary mb-3">
                <div class="card-header card-header-divider" :x-author-id="liveBlogStore.thread.authorId">
                    <span v-if="liveBlogStore.thread.id">Edit</span>
                    <span v-else>Add</span>
                    Thread
                </div>
                <div class="card-body">
                    <!--
                    <div class="mb-3">
                        <label for="Title" class="form-label fw-500">Title</label>
                        <InputText type="text" class="form-control" v-model="liveBlogStore.thread.title" id="Title" />
                    </div>
                    -->
                    <div class="mb-3">
                        <label for="Html" class="form-label fw-500 required">Body</label>
                        <FroalaThm v-model="liveBlogStore.thread.body" :config="froalaConfig" ref="liveBlogThreadEditor" />
                    </div>

                    <div v-if="liveBlogStore.thread?.childId">
                        <h5>Quoted Thread:</h5>
                        <PostThread :thread="liveBlogStore.thread?.childThread" :index="liveBlogStore.thread.id" class="mb-3" :key="liveBlogStore.thread?.childId" :isPreview="true" @clear="liveBlogStore.thread.childId = 0;" />
                    </div>

                    <p>
                        <Button :label="liveBlogStore.thread.id ? 'Save Changes' : 'Add Thread'" @click="saveThread()" :severity="liveBlogStore.thread.id ? 'warning' : 'primary'" :disabled="liveBlogStore.authorNotFound" />
                        <Button label="Clear" severity="secondary" class="ms-2" outlined @click="liveBlogStore.newThread()" v-if="liveBlogStore.thread.id || liveBlogStore.thread.childId" />
                    </p>
                </div>
            </div>

            <PostFeatured :isBlocked="widgetsBlocked" :post="liveBlogStore.post" @change="widgetsChanged" v-if="liveBlogStore.post.showHighlightedStories" />
            <PostTimeline v-if="liveBlogStore.post.showTimeline" :isBlocked="timelineBlocked" @change="timelineChanged" ref="postTimeline" />
        </div>

        <div class="col-sm-12 col-md-6">
            <div class="card card-border-color-primary mb-3 px-3 py-2">
                <PostGroup />
            </div>

            <div class="card card-border-color card-border-color-primary mb-3">
                <div class="card-body">
                    <Message severity="warn" :closable="false" v-show="liveBlogStore.authorNotFound"><strong>Warning:</strong> Current user not found in Authors table. You will not be able to add threads.</Message>
                    <template v-if="liveBlogStore.threads?.length">
                        <PostThread :thread="thread" :index="idx" class="mb-3" v-for="(thread, idx) in liveBlogStore.threads" :key="thread.id" @edit="editThread" @delete="deleteThread" @quote="quoteThread" @timeline="addThreadToTimeline" />
                        <div ref="bottomTrigger" class="bottom-sentinel"></div>
                    </template>
                    <template v-else-if="liveBlogStore.threads != null">
                        No posts yet...
                    </template>
                </div>
            </div>
        </div>

    </div>

    <div class="card card-border-color card-border-color-primary" v-else-if="typeof liveBlogStore.post != 'object'">
        <div class="card-body">Live Blog not found</div>
    </div>

</template>

<script>
import { ref, computed, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { getPermalink, isLiveblogPostLive, stopConnection } from '@/utils/ThmLiveBlogUtils';
import { formatDateTime, getTimezone } from '@/utils/ThmDateTimeUtils';
import { removeEmptyParagraphs } from '@/utils/ThmStringUtils';
import { useIntersectionObserver } from '@vueuse/core';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useLiveBlogStore } from '@/stores/LiveBlogStore';
import { isDateInFuture } from '@/utils/ThmDateTimeUtils';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import { useClipboard } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import ConfirmDialog from 'primevue/confirmdialog';
import Breadcrumbs from './_Breadcrumbs.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import PostFeatured from './_Featured.vue';
import PostTimeline from './_Timeline.vue';
import PostGroup from './_Group.vue';
import PostThread from './_Thread.vue';
import useAuth0 from '@/use/Auth0';
import * as signalR from '@microsoft/signalr';

export default {
    name: 'LiveBlogPost',
    components: {
        Breadcrumbs,
        ConfirmDialog,
        FroalaThm,
        PostFeatured,
        PostGroup,
        PostThread,
        PostTimeline,
        SpinnerThm,
    },
    setup() {
        const { user, getAccessTokenSilently } = useAuth0();
        const { copy, copied } = useClipboard();
        const liveBlogStore = useLiveBlogStore();
        const liveBlogThreadEditor = ref(null);
        const bottomTrigger = ref(null);
        const route = useRoute();
        const toast = useToast();

        //const post = ref({});
        //const thread = ref({});
        const froalaConfig = {
            toolbarButtons: ['html', 'bold', 'italic', 'quote', 'undo', 'redo', 'paragraphFormat', 'formatOLSimple', 'formatUL', 'align', 'insertLink', 'embedCodeButton', 'imageManagerButton', 'insertVideo', 'clearFormatting'],
            charCounterCount: false,
            toolbarSticky: false
        }
        const postTimeline = ref();
        const connection = ref(null);
        const connectionState = ref(null);
        const timelineBlocked = ref(false);
        const widgetsLoading = ref(false);
        const widgetsBlocked = ref(false);
        const isDisconnecting = ref(false);

        watch(() => connection.value?.state, async (state) => {
            connectionState.value = state;
        });

        useIntersectionObserver(bottomTrigger, async ([{ isIntersecting }]) => {
            if (isIntersecting) {
                await liveBlogStore.getPage();
            }
        });

        onBeforeMount(async () => {
            if(route.params.postId) {
                liveBlogStore.threads = null;
                liveBlogStore.groupMembers = null;
                await liveBlogStore.load(route.params.postId, true);
            }
            await startConnection();

            //await connection.value.invoke('TestSocket', '[TestSocket] Success');
        });

        onBeforeUnmount(async () => {
            isDisconnecting.value = true;
            await leaveGroup();
            await stopConnection(connection.value);
        });

        const connectionStateCssClass = computed(() => {
            switch(connectionState.value) {
                case 'Connected':
                    return 'text-bg-light';
                case 'Disconnected':
                    return 'text-bg-warning';
                default:
                    return 'text-bg-secondary';
            }
        });

        const startConnection = async () => {
            connection.value = new signalR.HubConnectionBuilder()
                //.withUrl(process.env.NODE_ENV === 'production' ? '/api/hub/liveblog' : 'https://localhost:5001/api/hub/liveblog',
                .withUrl(process.env.NODE_ENV === 'development' ? 'https://localhost:5001/api/hub/liveblog' : '/api/hub/liveblog',
                {
                    accessTokenFactory: async () => await getAccessTokenSilently(),
                })
                .configureLogging(signalR.LogLevel.Warning)
                //.withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => 2000 }) // Always wait 2 seconds before reconnecting
                .withAutomaticReconnect()
                .build();

            // Increase timeout
            connection.value.serverTimeoutInMilliseconds = 300000; // 5 mins
            connection.value.keepAliveIntervalInMilliseconds = 5000; // 5 seconds

            connection.value.on('TestSocket', (message) => {
                const msg = `${message}`;
                console.log('msg', msg);
                //messages.value.push(msg);
            });

            connection.value.on('ReceiveWidgetUpdate', async (widgets) => {
                widgetsBlocked.value = true;
                //await liveBlogStore.getWidgets();
                if(Array.isArray(widgets)) {
                    liveBlogStore.widgetPosts = widgets;
                }
                widgetsBlocked.value = false;
            });

            connection.value.on('ReceiveTimelineUpdate', async (timeline) => {
                timelineBlocked.value = true;
                //await liveBlogStore.getTimeline();
                if(Array.isArray(timeline)) {
                    liveBlogStore.timeline = timeline;
                }
                timelineBlocked.value = false;
            });

            connection.value.on('GroupJoin', (groupUser) => {
                console.log('[GroupJoin]', groupUser);
                liveBlogStore.groupAdd(groupUser);
            });

            connection.value.on('GroupLeave', (authorId) => {
                if(authorId) {
                    console.log('[GroupLeave]', authorId);
                    liveBlogStore.groupRemove(authorId);
                }
            });

            connection.value.on('OnPostThreadUpdated', (thread) => {
                console.log('[OnPostThreadUpdated]', thread);
                if(window.twttr) window.twttr.widgets.load();
                liveBlogStore.updateThread(thread);
            });

            connection.value.on('Notification', (message) => {
                console.log('[Notification]', message);
            });

            try {
                await connection.value.start();
                console.log('%cSignalR Connected: /api/hub/liveblog', 'color: #00FFFF');
                await joinGroup();
            } catch (err) {
                console.error('Error while starting connection: ' + err);
                //setTimeout(() => startConnection(), 5000);
            }

            connection.value.onreconnected(async function (e) {
                if(console.log.hide) console.log(e);
                connectionState.value = 'Connected';
                await joinGroup();
                //console.log("OnReconnected:", e);
            });

            connection.value.onclose(async () => {
                connectionState.value = 'Disconnected';
                if(!isDisconnecting.value) {
                    setTimeout(async () => {
                        await startConnection();
                    }, 2000); // Wait a second before attempting to reconnect
                }
            });
        };

        function copyPermalink(post) {
            let url = getPermalink(post, liveBlogStore.post.status != 1);
            copy(url);
        }
        async function refresh() {
            if(connectionState.value != 'Connected') await startConnection();
            liveBlogStore.threads = null;
            await liveBlogStore.load(route.params.postId, true);
        }
        async function saveThread() {
            let isNew = !liveBlogStore.thread.id;

            // Switch to WYSIWYG view before save
            if(liveBlogThreadEditor.value) {
                liveBlogThreadEditor.value.toggleCodeView();
            }

            // Strip empty paragraphs
            liveBlogStore.thread.body = removeEmptyParagraphs(liveBlogStore.thread.body, false);

            // Check required fields
            if(!liveBlogStore.thread.body?.trim()) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Content cannot be empty', life: 5000 });
                return;
            }

            let result = await liveBlogStore.saveThread();
            liveBlogStore.post.latestDate = result.datePublished;
            if(window.twttr) window.twttr.widgets.load();
            toast.add({ severity: 'success', summary: 'Success', detail: isNew ? 'Post saved' : 'Post updated', life: 2700 });

            // Broadcast new thread
            await connection.value.invoke('SendPostThread', route.params.postId, { ...result, status: isNew ? 0 : 1}); // 0 = Add, 1 = Update
        }

        function editThread(thread) {
            liveBlogStore.thread = { ...thread };
            scrollToEditForm();
        }

        async function deleteThread(thread) {
            await connection.value.invoke('SendPostThread', route.params.postId, { ...thread, status: 2 }); // 2 = Delete

            // Delete from Timeline items, if present
            if(liveBlogStore.post.showTimeline) {
                let rowsAffected = await liveBlogStore.deleteTimelineItemByThreadId(thread.id);
                //if(rowsAffected) await connection.value.invoke('BroadcastTimelineUpdate', thread.postId.toString());
                if(rowsAffected) await connection.value.invoke('BroadcastTimelineUpdate', thread.postId.toString(), liveBlogStore.timeline);
            }
        }

        async function joinGroup() {
            if(!liveBlogStore.hasCurrentAuthor) await liveBlogStore.getCurrentAuthor(user.value);
            let group = {
                groupId: liveBlogStore.post.postId,
                authorId: liveBlogStore.currentAuthor.authorId,
                isActive: true,
                timestamp: new Date().getTime(),
                name: liveBlogStore.currentAuthor.fullName,
                nickName: liveBlogStore.currentAuthor.nickName,
                email: liveBlogStore.currentAuthor.email,
                ImageObject: liveBlogStore.currentAuthor.imageObject
            };
            await connection.value.invoke('AddToGroup', group);
        }

        async function leaveGroup() {
            await connection.value.invoke('RemoveFromGroup', liveBlogStore.post.postId, liveBlogStore.currentAuthor.authorId);
        }

        async function widgetsChanged(ev) {
            if(console.log.hide) console.log('Widgets updated:', ev);
            // Fix date/time strings for SignalR
            for(let idx = 0; idx < liveBlogStore.widgetPosts?.length; idx++) {
                liveBlogStore.widgetPosts[idx].dateCreated = formatDateTime(liveBlogStore.widgetPosts[idx].dateCreated, "yyyy-MM-dd'T'HH:mm:ss");
            }
            await connection.value.invoke('BroadcastWidgetUpdate', liveBlogStore.post.postId.toString(), liveBlogStore.widgetPosts);
        }

        async function timelineChanged(ev) {
            if(console.log.hide) console.log('Timeline updated:', ev);
            //await connection.value.invoke('BroadcastTimelineUpdate', liveBlogStore.post.postId.toString());
            await connection.value.invoke('BroadcastTimelineUpdate', liveBlogStore.post.postId.toString(), liveBlogStore.timeline);
        }

        function quoteThread(thread) {
            liveBlogStore.thread.childId = thread.id;
            liveBlogStore.thread.childThread = thread;
            scrollToEditForm();
        }

        function addThreadToTimeline(thread) {
            postTimeline.value.addLinkedItem(thread);
        }

        function scrollToEditForm() {
            let edit_form = document.getElementById('liveblog_edit_thread');
            edit_form.scrollIntoView({ behavior: 'instant' });
        }

        return {
            addThreadToTimeline,
            bottomTrigger,
            connection,
            connectionState,
            connectionStateCssClass,
            copied,
            copy,
            copyPermalink,
            deleteThread,
            deviceIsMobile,
            editThread,
            formatDateTime,
            froalaConfig,
            getPermalink,
            getTimezone,
            isDateInFuture,
            isLiveblogPostLive,
            liveBlogStore,
            liveBlogThreadEditor,
            postTimeline,
            quoteThread,
            refresh,
            route,
            saveThread,
            timelineBlocked,
            timelineChanged,
            userHasAccessToRole,
            widgetsBlocked,
            widgetsChanged,
            widgetsLoading,
        };
    },
};
</script>
