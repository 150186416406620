import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import MenuService from '@/service/MenuService';
import { uuid } from 'vue-uuid';

const adminService = new AdminService();
const menuService = new MenuService();

export const useMenuStore = defineStore('Menus', {
    state: () => ({
        loading: false,
        menus: [],
        menu: null,
    }),
    actions: {
        async load() {
            this.loading = true;
            return await menuService.getMenus().then((res) => {
                if(process.env.NODE_ENV !== 'production') console.log('📗 GET Menus', res);
                this.menus = res;

                // Recursively add UUID to each menu item
                for(let idx = 0; idx < this.menus.length; idx++) {
                    this.menus[idx] =  { ...this.menus[idx], menu: this.setMenuItemIds(this.menus[idx]['menu']) }
                }
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async save() {
            this.loading = true;

            await menuService.getMenuById(this.menu.websiteMenuId).then((original) => {
                // Fetch original
                menuService
                    .saveMenu(this.menu)
                    .then((res) => {
                        // Save new
                        if (process.env.NODE_ENV !== 'production') console.log('📙 POST Update menu', res);
                        this.menu = this.original = res;
                        adminService.log({ logTypeId: 8, referenceId: res.websiteMenuId, message: 'Saved: ' + this.menu.label, content: JSON.stringify(res, null, 2), original: JSON.stringify(original, null, 2), language: 'json' });
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        async delete(menuId) {
            if (!menuId) throw 'Menu ID cannot be null';

            this.loading = true;
            return await menuService
                .deleteMenu(menuId)
                .then(() => {
                    if (process.env.NODE_ENV !== 'production') console.log('📕 DELETE menu', this.menu);
                    // Remove menu from menu list
                    this.menus = this.menus.filter((menu) => {
                        return menu.websiteMenuId !== menuId;
                    });

                    // Log event
                    adminService.log({ logTypeId: 8, referenceId: this.menu.websiteMenuId, message: 'Deleted: ' + menuId, content: JSON.stringify(this.menu, null, 2), language: 'json' }).then(() => {
                        // Set current menu to null if selected
                        if (this.menu.websiteMenuId == menuId) {
                            if (this.menus.length) this.menu = this.menus[0];
                        }
                    });
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async add(menu) {
            this.loading = true;
            await menuService
                .saveMenu(menu)
                .then((res) => {
                    if (process.env.NODE_ENV !== 'production') console.log('📙 POST Insert menu', res);
                    this.menu = res;
                    this.menus = [...this.menus, res];

                    // Log event
                    adminService.log({ logTypeId: 8, referenceId: res.websiteMenuId, message: 'Added: ' + res.label, content: JSON.stringify(res, null, 2), language: 'json' });
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        removeItem(item) {
            this.menu.menu = recursiveRemove(this.menu.menu, item.label);

            function recursiveRemove(list, id) {
                return list
                    .map((item) => {
                        return { ...item };
                    })
                    .filter((item) => {
                        if ('menu' in item) {
                            item.menu = recursiveRemove(item.menu, id);
                        }
                        return item.label !== id;
                    });
            }
        },
        setMenu(menuId) {
            this.menu = this.menus.find((obj) => obj.websiteMenuId == menuId);
        },
        getMenu(menuId) {
            return this.menus.find((obj) => obj.menuId == menuId);
        },
        setMenuItemIds(menu) {
            // Recursively add UUID to each menu item
            for(let idx = 0; idx < menu.length; idx++) {
                menu[idx]['uuid'] = uuid.v4();
                if(menu[idx]['menu'].length) menu[idx]['menu'] = this.setMenuItemIds(menu[idx]['menu']);
            }
            return menu;
        }
    },
});
