import BaseService from './BaseService';

export default class VirtualpageService extends BaseService {
    async getBadges(params) {
        return await this.apiClient.get('/api/badge', { params }).then((res) => res.data);
    }

    async getBadge(badgeId) {
        if(!badgeId) return {};
        return await this.apiClient.get(`/api/badge/${badgeId}`).then((res) => res.data);
    }

    async deleteBadge(badgeId) {
        return await this.apiClient.delete(`/api/badge/${badgeId}`).then((res) => res.data);
    }
    async saveBadge(badge) {
        return await this.apiClient.post('/api/badge', badge).then((res) => res.data);
    }
}
