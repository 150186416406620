<template>
    <div class="row mb-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6 mb-2" v-if="drafts">

                    <div class="mb-2" v-show="vipStats && vipStats.length">
                        <h6 class="ms-1 d-inline-block">VIP Stats</h6>
                        <div class="list-group vip-stats shadow-sm">
                            <div v-for="item in vipStats" :key="item.days" class="row">
                                <div class="col-3 vip-stats__item text-nowrap" style="text-align: right;"><strong>{{ item.days == 1 ? 'Today:' : item.days + 'd:' }}</strong></div>
                                <div class="col-4 vip-stats__item text-nowrap">{{ item.vipPostCount }}<i class="fa-sharp fa-regular fa-slash-forward slash"></i>{{ item.totalPostCount }}</div>
                                <div class="col-5 vip-stats__item">
                                    <span class="badge d-block text-right" :class="getVipStatsCssClass(item.vipPercent)" v-tooltip.right="getVipStatsCssClass(item.vipPercent, true)">
                                        {{ item.vipPercent.toFixed(1) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 class="ms-1 d-inline-block" v-tooltip="'Sorted by modified date'">Drafts</h6>
                    <ul class="list-group shadow-sm">
                        <li v-for="t in drafts" :key="t.postId" class="list-group-item">
                            <dl>
                                <dd>
                                    <!--
                                    <router-link :to="{ name: 'Posts', params: { id: t.postId } }">
                                        {{ t.title || '(No Headline)' }}
                                        <img :src="getVipBadge(t.subscription)" v-if="t.subscription > 1" class="vip-badge" />
                                    </router-link>
                                    -->
                                    <router-link :to="getEditUrl(t)">
                                        {{ t.title || '(No Headline)' }}
                                        <img :src="getVipBadge(t.subscription)" v-if="t.subscription > 1" class="vip-badge" />
                                    </router-link>
                                </dd>
                                <dd class="story__text">{{ t.author.name || t.author.nickName }}</dd>
                                <dd>
                                    <span class="story__text"><publish-label-status :datetime="t.dateModified" :status="t.status" /></span>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 mb-2" v-if="publishedPosts">
                    <h6 class="ms-1 d-inline-block" v-tooltip="'Sorted by publish date'">Published</h6>
                    <ul class="list-group shadow-sm">
                        <li v-for="t in publishedPosts" :key="t.postId" class="list-group-item">
                            <dl>
                                <dd>
                                    <!--
                                    <router-link :to="{ name: 'Posts', params: { id: t.postId } }">
                                        {{ t.title || '(No Headline)' }}
                                        <img :src="getVipBadge(t.subscription)" v-if="t.subscription > 1" class="vip-badge" />
                                    </router-link>
                                    -->
                                    <router-link :to="getEditUrl(t)">
                                        {{ t.title || '(No Headline)' }}
                                        <img :src="getVipBadge(t.subscription)" v-if="t.subscription > 1" class="vip-badge" />
                                    </router-link>
                                </dd>
                                <dd class="story__text">{{ t.author.name || t.author.nickName }}</dd>
                                <dd>
                                    <span class="story__text"><publish-label-status :datetime="t.datePublished" :status="t.status" /></span>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6 mb-2" v-if="chartbeatPostsTownhall && chartbeatPostsTownhall.length">
                    <h6 class="ms-1">Trending on {{ appSettings.name }}</h6>
                    <ul class="list-group shadow-sm">
                        <li v-for="c in chartbeatPostsTownhall" :key="c.postId" class="list-group-item d-flex justify-content-between align-items-start">
                            <a :href="c.url" v-html="stripHtml(c.title)"></a>
                            <span class="ms-2 text-muted">{{ c.people }}</span>
                        </li>
                    </ul>
                </div>

                <div class="col-md-6 mb-2" v-if="chartbeatPostsAll && chartbeatPostsAll.length">
                    <h6 class="ms-1">Trending on All Sites</h6>
                    <ul class="list-group shadow-sm">
                        <li v-for="c in chartbeatPostsAll" :key="c.postId" class="list-group-item d-flex justify-content-between align-items-start">
                            <!--<a :href="c.url">{{ c.title }} <span class="list-item-domain">{{ getDomainFromUrl(c.url) }}</span></a>-->
                            <a :href="c.url" v-html="getTrendingHeadline(c)"></a>
                            <span class="ms-2 text-muted">{{ c.people }}</span>
                        </li>
                    </ul>
                </div>

                <!--
                <div class="col-md-6 mb-2" v-show="false">
                    <h6 class="ms-1">Newsletter Test Links</h6>
                    <ol class="list-group shadow-sm list-group-numbered">
                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Jon Blankenship Finish me!</div>
                                I am supposed to be a list of newsletters from a DB
                            </div>
                            <span class="badge bg-primary rounded-pill">14</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Subheading</div>
                                Cras justo odio
                            </div>
                            <span class="badge bg-primary rounded-pill">14</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Subheading</div>
                                Cras justo odio
                            </div>
                            <span class="badge bg-primary rounded-pill">14</span>
                        </li>
                    </ol>
                </div>
                -->
            </div>
        </div>
    </div>


    <Panel v-if="userRoles?.includes('Developer')" header="Developer" :toggleable="true" class="mb-3">
        <dl>
            <dd>
                <strong>Roles for {{ user.name }}</strong>
            </dd>
            <dd>
                <!--<span>{{ userRoles?.join(', ') }}</span>-->
                <span>
                    <span v-for="role in userRoles" class="badge text-bg-secondary me-1" :key="role">{{ role }}</span>
                </span>
            </dd>
            <!--
            <dd class="pt-2"><strong>API Access Token</strong></dd>
            <dd>
                <div class="p-inputgroup">
                    <InputText id="accessTokenInput" v-model="authToken" type="text" class="form-control" ref="accessTokenInput" />
                    <button class="btn btn-outline-secondary" type="button" @click="copy('Bearer ' + authToken)">
                        <span v-if="!copied">Copy</span>
                        <span v-else>Copied!</span>
                    </button>
                </div>
            </dd>
            -->
            <dd class="pt-2"><strong>API Access Token</strong></dd>
            <dd>
                <div class="input-group">
                    <InputText id="accessTokenInput" v-model="authToken" type="text" class="form-control" ref="accessTokenInput" />
                    <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard('Bearer ' + authToken)">
                        <span v-if="copied && copied.includes('Bearer')">Copied!</span>
                        <span v-else>Swagger</span>
                    </button>
                    <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard(authToken)">
                        <span v-if="copied && !copied.includes('Bearer')">Copied!</span>
                        <span v-else>Copy</span>
                    </button>
                </div>
            </dd>
            <dd class="pt-2"><strong>Environment</strong></dd>
            <dd v-if="siteStore.build?.environment">
                <span>{{ siteStore.build?.environment }}</span>
            </dd>
        </dl>
    </Panel>
</template>
<script>
import PublishLabelStatus from '@/components/PublishLabelStatus.vue';
import { getDomainFromUrl, stripHtml } from '@/utils/ThmStringUtils';
import { hasImg, getImage, isIterable } from '@/utils/ThmUtils';
import { formatDateTimeISO } from '@/utils/ThmDateTimeUtils';
import { getVipBadge } from '@/utils/ThmImageUtils';
import { useSiteStore } from '@/stores/SiteStore';
import { promiseTimeout } from '@vueuse/core'
import { useToast } from 'primevue/usetoast';
import { useClipboard } from '@vueuse/core';
import { useRouter } from 'vue-router'
import { subDays } from 'date-fns';
import { ref, inject } from 'vue';
import ChartbeatService from '../service/ChartbeatService';
import PostService from '../service/PostService';
import useAuth0 from '@/use/Auth0';

//import useAuth from '@/use/Auth';
import { onMounted } from 'vue';
export default {
    name: 'Home',
    components: { PublishLabelStatus },
    setup() {
        const defaultParams = ref({
            postTypeId: 1,
            pageSize: 10,
            datePublishedStart: formatDateTimeISO(subDays(new Date, process.env.NODE_ENV == 'development' ? 120 : 30))
        });
        const { user, userRoles, getAccessTokenSilently } = useAuth0();
        const toast = useToast();
        const router = useRouter();

        const chartbeatLimit = 12;
        const publishedPosts = ref([]);
        const drafts = ref([]);
        const chartbeatPostsTownhall = ref([]);
        const chartbeatPostsAll = ref([]);
        const vipStats = ref({});
        const platform = ref();
        const appSettings = inject('appSettings');
        const copied = ref(null);
        const { text, copy, isSupported } = useClipboard();
        const authToken = ref('');
        const hadError = ref(false);

        const postService = new PostService();
        const chartbeatService = new ChartbeatService();
        const siteStore = useSiteStore();

        onMounted(async () => {
            if(appSettings.siteId == 1) defaultParams.value.categoryId = 5;
            authToken.value = await getAccessTokenSilently();
            siteStore.load();
            vipStats.value = await getVipPostStats();
            drafts.value = await getPosts({ ...defaultParams.value, orderBy: 'DateModified', orderDir: 'DESC' }, 2);
            publishedPosts.value = await getPosts(defaultParams.value, 10);
            chartbeatPostsTownhall.value = await getChartbeat(appSettings.domain);
            chartbeatPostsAll.value = await getChartbeat('bearingarms.com,hotair.com,pjmedia.com,redstate.com,townhall.com,twitchy.com');
            //await getColumnists(columnistsParams);
        });

        async function getPosts(params, status) {
            return await postService
                .getPosts({ ...params, status: status })
                .then((response) => {
                    //tipsheets.value = response;
                    return response;
                })
                .catch((err) => {
                    if(!hadError.value) {
                        toast.add({ severity: 'error', summary: 'Error Fetching Posts', detail: err.message || err, life: 4000 });
                        hadError.value = true;
                    }
                });
        }

        async function getChartbeat(domains = null) {
            return await chartbeatService
                .getChartbeat(domains)
                .then((response) => {
                    //chartbeatPosts.value = response?.[0].pages.slice(0, 12); // 12 = page size
                    //return response?.[0].pages.slice(0, chartbeatLimit);

                    if(!isIterable(response?.[0].pages)) return [];

                    // Merge arrays
                    if(response && response.length) {
                        let results = [];
                        for(let idx = 0; idx < response.length; idx++) {
                            results = [ ...results, ...response[idx].pages ];
                        }
                        // Sory array by concurrents
                        results.sort((a, b) => (a.people < b.people) ? 1 : -1);
                        // Return result
                        return results?.slice(0, chartbeatLimit);
                    }

                    return response?.[0].pages?.slice(0, chartbeatLimit);
                })
                .catch((err) => {
                    if(!hadError.value) {
                        toast.add({ severity: 'error', summary: 'Error Fetching Chartbeat posts', detail: err.message || err, life: 6000 });
                        hadError.value = true;
                    }
                });
        }
        async function getVipPostStats() {
            return await postService
                .getVipPostStats()
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    if(console.log.hide) console.error({err});
                    //toast.add({ severity: 'error', summary: 'Error Fetching VIP Post Stats', detail: err.message || err, life: 4000 });
                });
        }

        function getTrendingHeadline(post) {
            // Necessary for v-html raw HTML
            let headline = stripHtml(post.title).trim();
            return `${headline} <span class="hp-list-item-domain">${getDomainFromUrl(post.url)}</span>`;
        }

        function getVipStatsCssClass(percent, returnLabel = false) {
            switch(true) {
                case percent.toFixed(1) < appSettings.features.frontPage.vipStats.thresholds.danger:
                    return returnLabel ? `Below ${appSettings.features.frontPage.vipStats.thresholds.danger}%` : 'bg-custom-danger';
                case percent < appSettings.features.frontPage.vipStats.thresholds.warning:
                    return returnLabel ? `Below ${appSettings.features.frontPage.vipStats.thresholds.warning}%` : 'bg-custom-warning';
                default:
                    return returnLabel ? null : 'bg-success';
            }
        }

        function getEditUrl(p) {
            let routeUrl = null;
            if(appSettings.siteId == 1) {
                let categoryId = p.categories.length > 0 ? p.categories[0] : null;
                routeUrl = router.resolve({name: 'PostsCategory', params: { id: p.postId, categoryId: categoryId } });
            } else {
                routeUrl = router.resolve({name: 'Posts', params: { id: p.postId } });

            }
            return routeUrl?.href;
        }

        async function copyToClipboard(txt) {
            copied.value = txt;
            copy(txt);
            await promiseTimeout(3000).then(() => {
                copied.value = null;
            });
        }

        function log(a) {
            console.log(a);
        }

        return {
            appSettings,
            authToken,
            chartbeatPostsAll,
            chartbeatPostsTownhall,
            copy,
            copyToClipboard,
            copied,
            drafts,
            getDomainFromUrl,
            getEditUrl,
            getImage,
            getTrendingHeadline,
            getVipBadge,
            getVipStatsCssClass,
            hasImg,
            isSupported,
            log,
            platform,
            publishedPosts,
            siteStore,
            stripHtml,
            text,
            user,
            userRoles,
            vipStats,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.cb-card {
    &__img {
        background-color: #f3f3f3;
        padding: 5px;
        margin: 5px;
    }
    span {
        font-size: 1.8rem;
        font-weight: 600;
        vertical-align: middle;
        color: #a0a0a0;
    }
}
dd {
    margin: 0;
}
dl {
    margin: 0;
}
// VIP Stats:
div.vip-stats {
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);

    &__item {
        max-width: 80px;

        .badge {
            margin-top: 0.1rem;
        }
        .slash {
            color: var(--bluegray-400);
            font-size: 1.7em;
            padding-left: 0.1em;
            padding-right: 0.1em;
            vertical-align: middle;
        }
        .bg-custom-warning {
            //background-color: rgba(var(--bs-warning-rgb));
            background-color: var(--orange-400);
        }
        .bg-custom-danger {
            background-color: var(--red-600);
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1000px) {
        font-size: 0.9em;
    }
}
</style>

<style lang="scss">
.hp-list-item-domain {
    color: var(--gray-700);
    font-size: 85%;
}
</style>