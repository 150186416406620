import { defineStore } from 'pinia';
//import AdminService from '@/service/AdminService';
import LiveBlogService from '@/service/LiveBlogService';
import AdminService from '@/service/AdminService';
//import { formatDateTimeISO, formatDateTime } from '@/utils/ThmDateTimeUtils';
//import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { getUser } from '@/utils/ThmAuthUtils';

const adminService = new AdminService();
const liveBlogService = new LiveBlogService();

export const useLiveBlogStore = defineStore('LiveBlog', {
    state: () => ({
        loading: false,
        authorNotFound: false,
        currentAuthor: { authorId: 0 },
        post: {},
        groupMembers: null,
        thread: {},
        threads: null,
        timeline: null,
        widgetPosts: null,
        pageSize: 20,
        finishedLoading: false,
    }),
    getters: {
        hasCurrentAuthor() {
            return typeof this.currentAuthor == 'object' && this.currentAuthor.authorId && this.currentAuthor.authorId > 0;
        }
    },
    actions: {
        async load(postId, isThreadPage = false) {
            this.finishedLoading = false;
            if(!postId) return;
            this.loading = true;

            // Get current author
            this.getCurrentAuthor();

            // Get post
            await liveBlogService.getPost(postId).then((res) => {
                this.post = res;
            }).catch((error) => {
                throw error;
            });

            if(isThreadPage) {
                // Get threads
                this.threads = await liveBlogService.getThreads({ postId: postId, pageSize: this.pageSize });

                // Get widget posts
                if(this.post.showHighlightedStories) {
                    this.widgetPosts = await liveBlogService.getWidgetPosts(postId);
                }

                // Get timeline
                if(this.post.showTimeline) {
                    this.timeline = await liveBlogService.getTimeline(postId);
                }

                // Get group members
                let group = await liveBlogService.getGroup(postId);
                for(let idx=0; idx < group.length; idx++) {
                    this.groupAdd(group[idx]);
                }

                this.newThread();
            }

            // await alertBarService.getAlert().then((res) => {
            //     //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Alert Bar', res);
            //     //this.alert = this.original = res;
            //     this.alert = res;
            //     this.original = { ...res, startDate: formatDateTime(res.startDate) };
            //     //this.alert.startDate = formatDateTimeET(this.alert.startDate);
            //     this.alert.startDate = formatDateTime(this.alert.startDate);
            // }).catch((error) => {
            //     throw error;
            // }).finally(() => {
            //     this.loading = false;
            // });
            this.loading = false;
        },
        async getCurrentAuthor(currentUser = null) {
            if(!currentUser) currentUser = getUser();
            if(this.hasCurrentAuthor) return this.currentAuthor;

            return await adminService.getAuthorsFiltered({ email: currentUser.email, pageSize: 1 }).then((res) => {
                if(res.length > 0) {
                    this.currentAuthor = res[0];
                } else {
                    this.authorNotFound = true;
                    this.currentAuthor = { authorId: 0 };
                }
                return this.currentAuthor;
            }).catch((error) => {
                throw error;
            });
        },
        async getPage() {
            if(this.finishedLoading || this.threads.length < this.pageSize || this.loading) return;
            this.loading = true;
            let lastItem = this.threads[this.threads.length - 1];
            if(!lastItem) return;

            let results = await liveBlogService.getThreads({ postId: this.post.postId, threadId: lastItem.id });
            if(results.length < this.pageSize) {
                this.finishedLoading = true;
                console.log('Finished loading threads');
            }
            this.threads = this.threads.concat(results);
            this.loading = false;
        },
        async getWidgets() {
            this.widgetPosts = await liveBlogService.getWidgetPosts(this.post.postId);
        },
        async saveWidgets() {
            await liveBlogService.saveWidgetPosts(this.post.postId, this.widgetPosts);
            //let results = await liveBlogService.saveWidgetPosts(this.post.postId, this.widgetPosts);
            //this.widgetPosts = results;
        },
        async deleteWidget(widgetId) {
            this.widgetPosts = this.widgetPosts.filter(obj => obj.widgetId !== widgetId);
            await liveBlogService.saveWidgetPosts(this.post.postId, this.widgetPosts);
        },
        async addWidget(post) {
            this.widgetPosts.unshift(post);
            await liveBlogService.saveWidgetPosts(this.post.postId, this.widgetPosts);
        },
        async getTimeline() {
            this.timeline = await liveBlogService.getTimeline(this.post.postId);
        },
        async saveTimelineItem(item) {
            if(item.id) {
                // Update
                this.timeline = this.timeline.map(obj => (obj.id === item.id ? item : obj));
            } else {
                // Save
                this.timeline.unshift(item);
            }
            this.timeline = await liveBlogService.saveTimeline(this.post.postId, this.timeline);
        },
        async saveTimeline() {
            await liveBlogService.saveTimeline(this.post.postId, this.timeline);
        },
        async deleteTimelineItem(id) {
            this.timeline = this.timeline.filter(obj => obj.id !== id);
            this.timeline = await liveBlogService.saveTimeline(this.post.postId, this.timeline);
        },
        async deleteTimelineItemByThreadId(threadId) {
            let rowsAffected = this.timeline.filter(item => item.threadId == threadId).length;
            if(rowsAffected) {
                this.timeline = this.timeline.filter(obj => obj.threadId !== threadId);
                this.timeline = await liveBlogService.saveTimeline(this.post.postId, this.timeline);
            }
            return rowsAffected;
        },
        newThread() {
            this.thread = {
                id: 0,
                authorId: this.currentAuthor.authorId,
                postId: this.post.postId,
                childId: 0,
            }
        },
        async saveThread() {
            let isNew = !this.thread.id;
            let result = await liveBlogService.saveThread(this.thread);
            if(isNew) {
                // Add to top
                this.threads.unshift(result);
            } else {
                // Update modified thread
                this.threads = this.threads.map(obj => obj.id === this.thread.id ? result : obj);
            }
            this.newThread();
            return result;
        },
        async deleteThread(threadId) {
            return await liveBlogService.deleteThread(threadId).then(() => {
                this.threads = this.threads.filter(obj => obj.id !== threadId);
                return true;
            }).catch((error) => {
                if(console.log.hide) console.error(error);
                return false
            });
        },
        updateThread(thread) {
            switch(thread.status) {
                case 0: // Add
                    this.threads.unshift(thread);
                    break;
                case 1: // Update
                    this.threads = this.threads.map(obj => obj.id === thread.id ? thread : obj);
                    break;
                case 2: // Delete
                    this.deleteThread(thread.id);
                    break;
            }
        },
        async getThreads() {
            this.threads = await liveBlogService.getThreads({ postId: this.post.postId });
            return this.threads;
        },
        groupAdd(groupUser) {
            let existsInGroup = this.groupMembers?.some(item => item.authorId === groupUser.authorId);
            if(!existsInGroup) {
                if(!Array.isArray(this.groupMembers)) {
                    this.groupMembers = [ groupUser ];
                } else {
                    this.groupMembers.push(groupUser);
                }
            }
        },
        groupRemove(authorId) {
            if(this.groupMembers) {
                this.groupMembers = this.groupMembers.filter(user => user.authorId !== authorId);
            }
        }
    }
});
