<template>
    <p>Editor demo</p>
    <FroalaThm id="edit" :config="config" v-model="model" />
</template>

<script>
import FroalaThm from '@/components/Froala/FroalaThm.vue';

export default {
    name: 'EditorDemo',
    data() {
        return {
            // config: {
            //     events: {
            //         initialized: function () {
            //             console.log('initialized')
            //         },
            //     },
            // },
            config: {},
            model: 'Edit Your Content Here!',
        };
    },
    components: {
        FroalaThm,
    },
    methods: {},
};
</script>
