import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import BlockCopyService from '@/service/BlockCopyService';
import { formatDateTime, formatDateTimeISO, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { getAppSettings } from '@/utils/ThmUtils';

const adminService = new AdminService();
const blockCopyService = new BlockCopyService();

export const useBlockCopyStore = defineStore('BlockCopy', {
    state: () => ({
        loading: false,
        blocks: [],
        block: {},
        totalPages: 0
    }),
    actions: {
        async getBlockCopies(params, pageSize = 15) {
            this.loading = true;
            return await blockCopyService.getBlockCopies({ pageSize: pageSize, ...params }).then((res) => {
                this.blocks = res.blockCopies;
                this.totalPages = res.count;
                return this.pages;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async getBlockCopy(blockCopyId, setStore = false) {
            let blockCopy = null;
            // See if block exists in paged results (if any). This saves an API lookup.
            if(this.blocks.length) {
                blockCopy = this.blocks.find((obj) => obj.blockCopyId == blockCopyId);
            }

            // If not, fetch by block ID
            if(blockCopy && blockCopy.blockCopyId) {
                if(setStore) {
                    this.block = blockCopy;
                    this.block.datePublished = isEmptyDate(this.block.datePublished) ? null : formatDateTime(this.block.datePublished, 'MM/dd/yyyy');
                    //this.block.dateExpires = isEmptyDate(this.block.dateExpires) ? null : formatDateTime(this.block.dateExpires);
                    //this.block.dateCreated = isEmptyDate(this.block.dateCreated) ? null : formatDateTime(this.block.dateCreated);
                }
                return blockCopy;
            } else {
                this.loading = false;
                return blockCopyService.getBlockCopy(blockCopyId).then((res) => {
                    if(setStore) {
                        this.block = res;
                        this.block.datePublished = isEmptyDate(this.block.datePublished) ? null : formatDateTime(this.block.datePublished, 'MM/dd/yyyy');
                        //this.block.dateExpires = isEmptyDate(this.block.dateExpires) ? null : formatDateTime(this.block.dateExpires);
                        //this.block.dateCreated = isEmptyDate(this.block.dateCreated) ? null : formatDateTime(this.block.dateCreated);
                    }
                    return res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async setBlockCopy(blockCopyId) {
            return this.getBlockCopy(blockCopyId, true);
        },
        async save() {
            this.loading = true;
            let isNew = !this.block.blockCopyId || this.block.blockCopyId < 1;
            var block = { ...this.block }; // Cloning to avoid date formatting from being reactive

            // Format dates for MS SQL
            block.datePublished = formatDateTimeISO(block.datePublished);
            block.dateModified = formatDateTimeISO();
            if(isNew) block.blockCopyId = 0;
            if(!block.body) block.body = '';

            //console.log({block});
            return await blockCopyService.getBlockCopy(block.blockCopyId).then((original) => { // Fetch original
                return blockCopyService.saveBlockCopy(block).then((res) => { // Save block
                    if(original) original.datePublished = formatDateTimeISO(original.datePublished);
                    delete block.dateModified;
                    delete original.dateModified;
                    adminService.log({ logTypeId: 12, referenceId: res.blockCopyId, message: (isNew ? 'Added: ' : 'Modified: ') + res.title, content: JSON.stringify(block, null, 2), original: isNew ? null : JSON.stringify(original, null, 2), language: 'json' });
                    res.isNew = isNew;
                    //this.page = res;
                    return res;
                }).catch((error) => {
                    throw(error);
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        async delete() {
            if(!this.block) return;
            this.loading = true;

            return await blockCopyService.deleteBlockCopy(this.block.blockCopyId).then(() => {
                adminService.log({ logTypeId: 12, referenceId: this.block.blockCopyId, message: 'Delete: ' + this.block.title, content: JSON.stringify(this.block, null, 2), language: 'json' });
                this.page = {};
                return this.page;
            }).catch((error) => {
                throw(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        setDefaults() {
            const appSettings = getAppSettings();
            this.block = {
                datePublished: formatDateTime(new Date(), 'MM/dd/yyyy'),
                siteGroup: appSettings.siteGroup
            }
        }
    }
});
