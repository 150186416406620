<template>
    <form class="needs-validation" novalidate>
        <!-- Main Area -->
        <div class="row mb-3">
            <div class="col-sm-12 col-md-6 col-xl-5">
                <div class="card card-border-color card-border-color-primary shows">
                    <BadgeList />
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-xl-7 editor-column">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">
                        <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny float-end" iconPos="right" @click="addNewTag()" title="Add New Author" />
                        {{ route.params.badgeId ? 'Edit' : 'Add' }} Badge
                    </div>
                    <BadgeEditor />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
//import { onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BadgeList from './BadgeList.vue';
import BadgeEditor from './BadgeEditor.vue';

export default {
    name: 'Badges',
    components: {
        BadgeList,
        BadgeEditor
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const tagService = new TagsService();

        function addNewTag() {
            router.push({ path: `/settings/badges`, query: route.query });
        }

        return { addNewTag, route };
    },
};
</script>

<style lang="scss" scoped>
/*
div.editor-column {
    @media (max-width: 767px) {
        margin-top: 1.25em;
    }
}
*/
</style>
