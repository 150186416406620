<template>
    <div class="col-xs-12" :class="gridSizeClass">
        <div class="card card-border-color card-border-color-primary" id="article_sources">
            <div class="card-header card-header-divider">
                <!--<Button label="Save" class="p-button-smaller float-end" @click="savePosts('queue', false); savePosts('live')" title="Save Live (Ctrl+S or ⌘S)" :disabled="isLockedByOtherUser || (!layoutStore.changedColumn.live && !layoutStore.changedColumn.queue)" />-->
                <SpinnerThm class="spinner float-end" v-if="saving" />
                <Button label="Save" class="p-button-smaller float-end" @click="savePosts('queue', false); savePosts('live')" x-title="Save Live (Ctrl+S or ⌘S)" :disabled="isLockedByOtherUser || !layoutStore.isDirty" v-else />
                <span v-if="appSettings.features?.homePageManager?.maxItems" v-tooltip="'Maximum posts: ' + appSettings.features.homePageManager.maxItems">Live ({{ layoutStore.posts.live.length }})</span>
                <span v-else>Live ({{ layoutStore.posts.live.length }})</span>
                <!--<i class="fa-regular fa-triangle-exclamation icon-column-modified" v-show="layoutStore.changedColumn.live" title="Column has changed"></i>-->
                <span class="icon-dot-modified ms-1" v-show="layoutStore.changedColumn.live" v-tooltip.top="'Unsaved changes'"></span>
            </div>

            <div class="card-body live">
                <draggable class="drag-area" :list="layoutStore.posts.live" :group="{ name: 'g1' }" item-key="layoutPostId" @change="itemDragged($event, 'live')" :disabled="isLockedByOtherUser || deviceIsMobile()">
                    <template #item="{ element }">
                        <div :key="element.layoutPostId" class="post-item" :x-post-type="element.postType">
                            <div class="border">
                                <div class="post-item__toolbar" v-bind:class="{ modified: element.postId > 0 && layoutStore.changedPosts.includes(element.postId), isExternal: element.isExternal }">
                                    <!--<div class="post-item__toolbar">-->
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-up" @click="movePosition(element, 'live', 'up')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-up"></i></button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-down" @click="movePosition(element, 'live', 'down')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-down"></i></button>
                                    <select class="btn-toolbar-button" v-model="element.pinnedDuration" @change="changedDuration(element)">
                                        <option v-for="duration in durations" :key="duration.value" v-bind:value="duration.value">{{duration.label}}</option>
                                    </select>
                                    <select class="btn-toolbar-button" v-model="element.ordinalPosition" :disabled="isLockedByOtherUser" @change="changePosition($event, 'live', element)">
                                        <option v-for="(item, index) in layoutStore.posts.live" :key="item.layoutPostId" v-bind:value="index + 1">{{ index + 1 }}</option>
                                    </select>
                                    <i class="far fa-up-right-from-square fw-bold" v-show="element.isExternal" v-tooltip.top="'External ' + uppercaseFirst(element.postType)"></i>

                                    <div class="float-end text-end" x-style="width: 200px;">
                                        <i class="pi pi-times btn-toolbar-close" @click="removePost(element, 'live')"></i>
                                    </div>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-edit float-end" @click="edit(element, 'live')" :disabled="isLockedByOtherUser">Edit</button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-right float-end" @click="moveColumn(element, 'live', 'queue')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-right"></i></button>
                                </div>

                                <div class="post-item__body d-flex">
                                    <div class="w-100">
                                        <!--<a class="preview-image float-end d-inline-block" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="element.postFields.imageObject && element.postFields.imageObject[0] ? element.postFields.imageObject[0].credit : ''" target="_blank"></a>-->
                                        <div class="preview-image-container float-end d-inline-block">
                                            <a :href="getAbsoluteUrl(element)" class="position-absolute play-button" target="_blank" v-if="element.postFields.postTypeId == 2 && getPreviewImage(element)"><img src="/images/play-button.svg" /></a>
                                            <a class="preview-image" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="element.postFields.imageObject && element.postFields.imageObject[0] ? element.postFields.imageObject[0].credit : ''" target="_blank"></a>
                                        </div>
                                        <!--<a class="preview-image float-end d-inline-block" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="getPreviewImageCredit(element)" target="_blank"></a>-->
                                        <h6 class="mb-0" :x-post-id="element.postId">
                                            <!--<a :href="getAbsoluteUrl(element)" target="_blank" v-html="element.postFields.title"></a>-->
                                            <a :href="getEditLink(element)" target="_blank" v-html="getActiveHeadline(element)" v-if="getEditLink(element)"></a>
                                            <!--<span v-else>{{ getActiveHeadline(element) }}</span>-->
                                            <span v-else v-html="getActiveHeadline(element)"></span>
                                            <i class="far fa-asterisk fw-bold text-warning ps-1" v-if="!element.isExternal && element.customProperties?.headline" :title="getActiveHeadline(element, true)"></i> <!--<span class="text-muted" v-if="element.postType && element.postType != 'Post'">({{ element.postType }})</span>-->
                                        </h6>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-item" v-show="element.isExternal">
                                                <!--<i class="far fa-arrow-up-right-from-square align-text-bottom text-muted" v-show="element.isExternal"></i>-->
                                                {{ getDomainFromUrl(element.customProperties.url) }}
                                            </li>
                                            <!--<li class="list-item">{{ formatDateTime(element.postFields.datePublished, 'M/dd/yyyy hh:mm a') }} - {{ element.postFields.userId || element.customProperties.contributor || 'No Author' }}</li>-->
                                            <li class="list-item">{{ formatDateTime(element.postFields.datePublished, 'M/dd/yyyy hh:mm a') }} - {{ getAuthorName(element) }}</li>
                                            <li class="list-item">Live: {{ formatDateTime(element.dateLive, 'M/dd/yyyy hh:mm a') }}</li>
                                            <li class="list-item" v-if="!isEmptyDate(element.dateExpires)">Expires: {{ formatDateTime(element.dateExpires, 'M/dd/yyyy hh:mm a') }}</li>
                                            <!--<li class="list-item d-none">Stats: 5m: <span class="min5">25</span><span> | </span> 30m: <span class="min30">115</span><span> | </span> 60m: <span class="min60">179</span></li>-->
                                            <li class="list-item d-none2" v-if="element.stats && Object.keys(element.stats).length">
                                                <abbr class="text-decoration-none" title="Chartbeat Concurrents">CB:</abbr> {{element.stats.concurrents || 0}} <i class="fa-regular fa-pipe"></i>
                                                5m: <span class="min5" v-html="element.stats['5min'] || 0" /> <i class="fa-regular fa-pipe"></i>
                                                30m: <span class="min30" v-html="element.stats['30min'] || 0" /> <i class="fa-regular fa-pipe"></i>
                                                60m: <span class="min60" v-html="element.stats['60min'] || 0" />
                                            </li>
                                        </ul>
                                        <span v-if="element.postFields.subscription > 1">
                                            <img :src="getVipBadge(element.postFields.subscription)" alt="VIP" class="vip-badge" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>

    <div class="col-xs-12" :class="gridSizeClass" v-show="appSettings.features.homePageManager.showQueue">
        <div class="card card-border-color card-border-color-primary" id="article_sources">
            <div class="card-header card-header-divider">
                <SpinnerThm class="spinner float-end" v-if="saving" />
                <Button label="Save" class="p-button-smaller float-end" @click="savePosts('live', false); savePosts('queue')" x-title="Save Queue (Ctrl+S or ⌘S)" :disabled="isLockedByOtherUser || !layoutStore.isDirty" v-else />
                Queue ({{ layoutStore.posts.queue.length }})
                <!--<i class="fa-regular fa-triangle-exclamation icon-column-modified" v-show="layoutStore.changedColumn.queue" title="Column has changed"></i>-->
                <span class="icon-dot-modified ms-1" v-show="layoutStore.changedColumn.queue" v-tooltip.top="'Unsaved changes'"></span>
            </div>

            <div class="card-body queue">
                <draggable class="drag-area" x-tag="ul" :list="layoutStore.posts.queue" :group="{ name: 'g1' }" item-key="layoutPostId" @change="itemDragged($event, 'queue')" :disabled="isLockedByOtherUser || deviceIsMobile()">
                    <template #item="{ element }">
                        <div :key="element.layoutPostId" class="post-item" :x-post-type="element.postType">
                            <div class="border">
                                <div class="post-item__toolbar" v-bind:class="{ modified: element.postId > 0 && layoutStore.changedPosts.includes(element.postId), isExternal: element.isExternal }">
                                    <!--<div class="post-item__toolbar">-->
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-up" @click="movePosition(element, 'queue', 'up')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-up"></i></button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-down" @click="movePosition(element, 'queue', 'down')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-down"></i></button>
                                    <select class="btn-toolbar-button" v-model="element.ordinalPosition" :disabled="isLockedByOtherUser" @change="changePosition($event, 'queue', element)">
                                        <option v-for="(item, index) in layoutStore.posts.queue" :key="item.layoutPostId" v-bind:value="index + 1">{{ index + 1 }}</option>
                                    </select>
                                    <i class="far fa-up-right-from-square fw-bold" v-show="element.isExternal" v-tooltip.top="'External ' + uppercaseFirst(element.postType)"></i>

                                    <div class="float-end text-end" x-style="width: 200px;">
                                        <i class="pi pi-times btn-toolbar-close" @click="removePost(element, 'queue')"></i>
                                    </div>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-edit float-end" @click="edit(element, 'queue')" :disabled="isLockedByOtherUser">Edit</button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-left float-end" @click="moveColumn(element, 'queue', 'live')" :disabled="isLockedByOtherUser"><i class="far fa-arrow-left"></i></button>
                                </div>

                                <div class="post-item__body d-flex">
                                    <div class="w-100">
                                        <!--<a class="preview-image float-end d-inline-block" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="element.postFields.imageObject && element.postFields.imageObject[0] ? element.postFields.imageObject[0].credit : ''" target="_blank"></a>-->
                                        <div class="preview-image-container float-end d-inline-block">
                                            <a :href="getAbsoluteUrl(element)" class="position-absolute play-button" target="_blank" v-if="element.postFields.postTypeId == 2 && getPreviewImage(element)"><img src="/images/play-button.svg" /></a>
                                            <a class="preview-image" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="element.postFields.imageObject && element.postFields.imageObject[0] ? element.postFields.imageObject[0].credit : ''" target="_blank"></a>
                                        </div>
                                        <!--<a class="preview-image float-end d-inline-block" :href="getAbsoluteUrl(element)" v-if="getPreviewImage(element)" v-bind:style="{ backgroundImage: 'url(' + getPreviewImage(element) + ')' }" :title="getPreviewImageCredit(element)" target="_blank"></a>-->
                                        <h6 class="mb-0" :x-post-id="element.postId">
                                            <!--<a :href="getAbsoluteUrl(element)" target="_blank" v-html="element.postFields.title"></a>-->
                                            <a :href="getEditLink(element)" target="_blank" v-html="getActiveHeadline(element)" v-if="getEditLink(element)"></a>
                                            <!--<span v-else>{{ getActiveHeadline(element) }}</span>-->
                                            <span v-else v-html="getActiveHeadline(element)"></span>
                                            <i class="far fa-asterisk fw-bold text-warning ps-1" v-if="!element.isExternal && element.customProperties?.headline" :title="getActiveHeadline(element, true)"></i> <!--<span class="text-muted" v-if="element.postType && element.postType != 'Post'">({{ element.postType }})</span>-->
                                        </h6>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-item" v-show="element.isExternal">
                                                <!--<i class="far fa-arrow-up-right-from-square align-text-bottom text-muted" v-show="element.isExternal"></i>-->
                                                {{ getDomainFromUrl(element.customProperties.url) }}
                                            </li>
                                            <!--<li class="list-item">{{ formatDateTime(element.postFields.datePublished, 'M/dd/yyyy hh:mm a') }} - {{ element.postFields.userId || element.customProperties.contributor || 'No Author' }}</li>-->
                                            <li class="list-item">{{ formatDateTime(element.postFields.datePublished, 'M/dd/yyyy hh:mm a') }} - {{ getAuthorName(element) }}</li>
                                            <!--<li class="list-item">Live: {{ formatDateTime(element.datePublished, 'M/dd/yyyy hh:mm a') }}</li>-->
                                            <!--<li class="list-item d-none2">Stats: 5m: <span class="min5">25</span><span> | </span> 30m: <span class="min30">115</span><span> | </span> 60m: <span class="min60">179</span></li>-->
                                            <li class="list-item d-none2" v-if="element.stats && Object.keys(element.stats).length">
                                                <abbr class="text-decoration-none" title="Chartbeat Concurrents">CB:</abbr> {{element.stats.concurrents || 0}} <i class="fa-regular fa-pipe"></i>
                                                5m: <span class="min5" v-html="element.stats['5min'] || 0" /> <i class="fa-regular fa-pipe"></i>
                                                30m: <span class="min30" v-html="element.stats['30min'] || 0" /> <i class="fa-regular fa-pipe"></i>
                                                60m: <span class="min60" v-html="element.stats['60min'] || 0" />
                                            </li>
                                        </ul>
                                        <span v-if="element.postFields.subscription > 1">
                                            <img :src="getVipBadge(element.postFields.subscription)" alt="VIP" class="vip-badge" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>

    <Dialog header="Header" v-model:visible="showEditModal" @hide="closeEditModal" :modal="true" :dismissableMask="false" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{ width: '50vw' }">
        <template #header>
            <h5>Edit Post</h5>
        </template>

        <form id="edit_post_form">
            <div class="mb-3">
                <a class="far fa-down-to-dotted-line float-end pt-2 pe-2" title="Copy Original Headline" @click="layoutStore.post.customProperties.headline = getActiveHeadline(layoutStore.post)" href="#" v-show="!layoutStore.post.isExternal"></a>
                <label for="Title" class="form-label">Headline</label>
                <!--<InputText type="text" class="form-control" v-model="post.title" id="editTitle" @change="changedPosts.push(post.rowId)" />-->
                <!--<froala-thm tag="textarea" v-model="layoutStore.post.customProperties.headline" id="Title" :config="froalaColorsConfig.colorOnly" @change="postChanged" />-->
                <FroalaThm v-model="layoutStore.post.customProperties.headline" id="Title" :config="froalaColorsConfig.colorOnly" @change="postChanged" />
                <small class="help d-block">Leave blank to use default headline</small>
            </div>

            <div class="mb-3" v-show="layoutStore.post.isExternal">
                <!--<InputText type="text" class="form-control" v-model="layoutStore.post.postFields.userId" id="UserId" placeholder="Contributor" @change="postChanged" />-->
                <InputText type="text" class="form-control" v-model="layoutStore.post.customProperties.contributor" id="UserId" placeholder="Contributor" @change="postChanged" />
            </div>

            <div class="mb-3" v-show="layoutStore.post.isExternal">
                <!--<InputText type="text" class="form-control" v-model="layoutStore.post.postFields.url" :value="getPermalink(layoutStore.post.postFields)" id="Url" placeholder="https://" @change="postChanged" />-->
                <!--<InputText type="text" class="form-control" v-model="layoutStore.post.postFields.url" :value="layoutStore.post.customProperties.url" id="Url" placeholder="https://" @change="postChanged" />-->
                <InputText type="text" class="form-control" v-model="externalPostUrl" id="Url" placeholder="https://" @change="postChanged" />
            </div>

            <div class="mb-3 row">
                <div class="col-6">
                    <div v-show="layoutStore.post.isExternal">
                        <label for="DatePublished" class="form-label d-block">Published Date/Time</label>
                        <Calendar id="DatePublished" v-model="layoutStore.post.postFields.datePublished" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :manual-input="false" @blue="postChanged" @input="postChanged" @date-select="postChanged" />
                    </div>
                    <label for="DateLive" class="form-label d-block mt-3">Live Date/Time (ET)</label>
                    <Calendar id="DateLive" v-model="layoutStore.post.dateLive" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" :manual-input="false" @blur="postChanged" />
                </div>
                <div class="col-6">
                    <!--
                    <ImageLibrary v-model="layoutStore.post.customProperties.image" v-if="layoutStore.post.isExternal" />
                    <ImageLibrary v-model="layoutStore.post.postFields.imageObject[0]" v-else />
                    -->
                    <ImageLibrary v-model="externalPostImage" />
                </div>
            </div>
        </form>

        <template #footer>
            <!--<Button label="Close" icon="pi pi-times" autofocus @click="postChanged(); showEditModal = false" />-->
            <Button label="Close" icon="pi pi-times" autofocus @click="showEditModal = false" />
        </template>
    </Dialog>
    <div>{{ console1 }}</div>
</template>

<script>
import { ref, computed, inject, onBeforeMount, onBeforeUnmount, onMounted, watch } from 'vue';
//import { useRoute, useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import { getEditLink } from '@/utils/ThmUtils';
import { getVipBadge, resizeImageUrl } from '@/utils/ThmImageUtils';
import { getDomainFromUrl, uppercaseFirst } from '@/utils/ThmStringUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { onBeforeRouteLeave } from 'vue-router';
import { formatDateTime, formatDateTimeET, isEmptyDate } from '@/utils/ThmDateTimeUtils';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import { useLayoutStore } from '@/stores/LayoutStore';
import { useSiteStore } from '@/stores/SiteStore';
//import { useSignalR } from '@quangdao/vue-signalr';
//import { useSignalR } from '@dreamonkey/vue-signalr';
import useSignalR from '@/use/SignalR';
import { useToast } from 'primevue/usetoast';
import AdminService from '@/service/AdminService';
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';
import SpinnerThm from '@/components/SpinnerThm.vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import Draggable from 'vuedraggable';
import { add } from 'date-fns';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'HomePageManagerLiveQueue',
    components: {
        Draggable,
        FroalaThm,
        ImageLibrary,
        SpinnerThm,
    },
    setup() {
        const adminService = new AdminService();
        const siteStore = useSiteStore();
        const layoutStore = useLayoutStore();
        const appSettings = inject('appSettings');
        //const keys = useMagicKeys();
        const toast = useToast();

        const subscriptionTypes = ref([]);
        const showEditModal = ref(false);
        const signalr = useSignalR();
        const { user } = useAuth0();
        //const locationId = 1;
        const durations = ref([]);
        const console1 = ref(null); // for debugging
        const saving = ref(false);

        // Signal-R: Invoke "SendMessage" and wait for a response
        //signalr.invoke('Send', 'hello word!!@#'); //{ message: 'Hello world!' }//.then((m) => console.log("my response from ws: ", m));
        //signalr.on('ReceiveSend', (message) => console.log('message from signalr: ', message));
        //signalr.on('LayoutReceiveLock', (message) => setLockMessageOnLocation(message));
        //signalr.on('ReceiveSend', () => null);
        signalr.on('ReceivePostLock', () => null);
        signalr.on('ReceiveSend', (message) => console.log('SignalR:', message));
        //signalr.invoke('Send', 'Message received');

        const froalaColorsConfig = {
            colorOnly: {
                //toolbarButtons: ['textColor', 'backgroundColor', 'undo', 'redo'], // 'bold', 'italic'
                //colorsText: ['#61BD6D', '#1ABC9C', '#54ACD2', 'REMOVE'],
                toolbarButtons: ['textColor', 'undo', 'redo'], // 'bold', 'italic'
                colorsText: ['#C90110', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8'],
                charCounterCount: false,
                enter: 2, //FroalaEditor.ENTER_BR
                pastePlain: true,
                immediateVueModelUpdate: true,
                height: 75,
            },
        };

        watch(() => layoutStore.isDirty, async (isDirty) => {
            if(isDirty === true) setEditLock(true);
        });

        onBeforeMount(() => {
            subscriptionTypes.value = adminService.getSubscriptions();
            siteStore.load();
            //layoutStore.load(locationId, true);

            // Populate expiration dirations
            if(!durations.value.length) {
                for(let i = 0; i <= 12; i++) {
                    durations.value.push({ value: i, label: i + (i !== 1 ? ' hrs' : ' hr') });
                }
            }
        });

        onBeforeUnmount(() => {
            signalr.off('ReceivePostLock');
            signalr.off('ReceiveSend');
        });

        onMounted(() => {
            // Unlock layout when leaving page
            window.onbeforeunload = function() {
                if(layoutStore.location.postLockStatus.userIdLock == user.value.name) {
                    setEditLock(false);
                }
            }
        });

        onBeforeRouteLeave((to, from) => {
            if(console.log.hide) console.log(to, from);
            if(layoutStore.location.postLockStatus.userIdLock == user.value.name) {
                setEditLock(false);
            }
        });

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveAll());
        whenever(meta_s, () => saveAll());
        //whenever(keys['Ctrl+S'], () => saveAll());
        */

        const isLockedByOtherUser = computed(() => {
            return isAutoPilot.value || (layoutStore.isLocked && layoutStore.location.postLockStatus?.userIdLock != user.value.name);
        });

        const isAutoPilot = computed(() => {
            return layoutStore.template.sortTipsheetByChronological;
        });

        const externalPostUrl = computed({
            get() {
                if(layoutStore.post.postFields.url) return layoutStore.post.postFields.url;
                return layoutStore.post.customProperties.url;
            },
            // setter
            set(newValue) {
                layoutStore.post.customProperties.url = newValue;
            },
        });

        const externalPostImage = computed({
            get() {
                if(layoutStore.post.isExternal || layoutStore.post.customProperties.image) {
                    return layoutStore.post.customProperties.image;
                } else {
                    return layoutStore.post.postFields.imageObject[0];
                }
            },
            // setter
            set(newValue) {
                if(layoutStore.post.isExternal) {
                    layoutStore.post.customProperties.image = newValue;
                } else {
                    layoutStore.post.postFields.imageObject[0] = newValue;
                    layoutStore.post.customProperties.image = newValue.url;
                }
            },
        });

        const gridSizeClass = computed(() => {
            return 'col-md-' + (appSettings.features.homePageManager.showQueue ? 4 : 6);
        });

        function getPermalink(post) {
            if(post.url.includes('http')) return post.url;
            let site = siteStore.sites?.find(({ siteGroup }) => siteGroup == post.siteGroup);
            return `https://${site.domain}${post.url}`;
        }

        function closeEditModal() {
            layoutStore.post = {};
        }

        function savePosts(status, showNotice = true) {
            if(status == 'live') saving.value = true;
            layoutStore.save(status).then((res) => {
                if(console.log.hide) console.log({res});
                //console1.value = res;
                layoutStore.changedColumn[status] = false;
                layoutStore.changedPosts = [];
                //layoutStore.setPostLockStatus(1, null);
                if(!layoutStore.changedColumn['live'] && !layoutStore.changedColumn['queue']) {
                    setEditLock(false);
                }
                //if(showNotice) toast.add({ severity: 'success', summary: 'Success', detail: uppercaseFirst(status) + ' Posts Saved', life: 3000 });
                if(showNotice) toast.add({ severity: 'success', summary: 'Success', detail: 'Posts layout saved', life: 3000 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Posts', detail: err.message || err });
                layoutStore.changedColumn[status] = true;
            }).finally(() => {
                if(status == 'live') saving.value = false;
            });
        }

        /*
        function saveAll() {
            let showNotice = (layoutStore.changedColumn['live'] && !layoutStore.changedColumn['queue']) || (layoutStore.changedColumn['queue'] && !layoutStore.changedColumn['live']);
            // Show notice if both live and queue columns changed
            if(layoutStore.changedColumn['live'] && layoutStore.changedColumn['queue']) {
                 toast.add({ severity: 'success', summary: 'Success', detail: 'Posts Saved', life: 3000 });
            }
            // Save posts in both columns
            if(layoutStore.changedColumn['live']) savePosts('live', showNotice);
            if(layoutStore.changedColumn['queue']) savePosts('queue', showNotice);
        }
        */

        function getAbsoluteUrl(post) {
            if(post.customProperties.url) return post.customProperties.url;
            //if(!post.postFields || !post.postFields.url || post.postFields.url.includes('://')) return post.postFields.url;
            if(!post.postFields || !post.postFields.url) return post.postFields.url;
            //var site = layoutStore.getSiteBySiteGroup(post.postFields.siteGroup);
            /*
            var site = siteStore.sites.find((obj) => {
                return obj.siteGroup == post.postFields.siteGroup;
            });
            if(!site) return post.postFields.url;
            */
            //return `https://${site.domain}` + post.postFields.url;
            return appSettings.webroot[process.env.NODE_ENV] + post.postFields.url;
        }

        function getAuthorName(post) {
            if(post.authorName) {
                return post.authorName;
            } else if(post.customProperties.contributor) {
                return post.customProperties.contributor;
            }
            return post.postFields?.author?.name || post.postFields?.author?.nickName || 'No Author';
        }

        function takeoverCheck() {
            if(isLockedByOtherUser.value) {
                toast.add({ severity: 'error', summary: 'Location Locked', detail: 'Location is currently locked. You must take over in order to make changes.', life: 4000 });
                return false;
            } else if(isAutoPilot.value) {
                toast.add({ severity: 'error', summary: 'Location Locked', detail: 'Location is currently on auto-pilot. Disabled chronological sorting if you want to make changes.', life: 4000 });
                return false;
            }
            return true;
        }

        function changePosition(event, status, post) {
            layoutStore.movePosition(post, status, event.target.value);
        }

        function getPreviewImage(post, size = '180x180') {
            if(post.customProperties.image && post.customProperties.image.url) {
                return resizeImageUrl(post.customProperties.image.url, size);
            } else if(post.customProperties.image) {
                if(post.customProperties.image.includes('media.townhall.com')) {
                    return resizeImageUrl(post.customProperties.image, size);
                } else {
                    //return resizeImageUrl(post.customProperties.image, size);
                    return post.customProperties.image;
                }
            }

            let imageObject = post.postFields.imageObject;
            if(!imageObject || !imageObject.length || !imageObject[0].url) return null;
            let imageUrl = imageObject[0].url;

            // If image is path only, make fully qualified URL
            if(!imageUrl.includes('http')) {
                imageUrl = 'https://media.townhall.com/cdn/hodl/' + imageUrl.replace(/^\/+/, '').replace(/\/+$/, ''); // Trim slashes
            }

            return resizeImageUrl(imageUrl, size);
        }

        /*
        function getPreviewImageCredit(post) {
            if(post.isExternal) {
                if(post.customProperties.image && post.customProperties.image.credit) {
                    return post.customProperties.image.credit
                }
            } else {
                if(post.postFields.imageObject && post.postFields.imageObject[0]) {
                    return post.postFields.imageObject[0].credit;
                }
            }
            return null;
        }
        */

        function moveColumn(post, sourceColumn, targetColumn) {
            if(takeoverCheck()) {
                setEditLock();
                layoutStore.moveColumn(post, sourceColumn, targetColumn);
            }
        }

        function movePosition(element, status, dir) {
            if(takeoverCheck()) {
                setEditLock();
                layoutStore.movePosition(element, status, dir);
            }
        }

        function removePost(post, status) {
            if(takeoverCheck()) {
                layoutStore.remove(post, status);
            }
        }

        function edit(post, status) {
            if(takeoverCheck()) {
                layoutStore.setPost(post);
                layoutStore.post.status = status;
                showEditModal.value = true;
                setEditLock();
                layoutStore.changedColumn[layoutStore.post.status] = true; // Enables save buttons
            }
        }

        function postChanged() {
            layoutStore.changedColumn[layoutStore.post.status] = true;
            layoutStore.changedPosts.push(layoutStore.post.postId);
            //layoutStore.setPostLockStatus(4, user.value);
            setEditLock();
        }

        function itemDragged(event, status) {
            // Set datePublished when made live
            if(status == 'live' && event.added) {
                //let idx = event.added.newIndex;
                layoutStore.posts[status][event.added.newIndex].datePublished = formatDateTime();
            }

            layoutStore.changedColumn[status] = true;
            layoutStore.renumber(status);
            //layoutStore.setPostLockStatus(4, user.value);
            setEditLock();
        }

        function setEditLock(lock = true, locationId = 1) {
            let userData = lock ? user.value : { name: null, email: null };
            if(!lock || !layoutStore.isLocked) {
                let payload = { postId: locationId, userIdLock: userData.name, connectionId: userData.email };
                signalr.invoke('LayoutBroadcastLock', payload);
                layoutStore.location.postLockStatus = payload;
            }
        }

        function getActiveHeadline(post, returnOriginal = false) {
            let props = post.postFields.extendedProperties;
            // Home Page title
            if(!returnOriginal && post.customProperties.headline && post.customProperties.headline.trim()) {
                return post.customProperties.headline;
            }
            // Headlines
            let headline = '';
            if(props && props.headlines && props.headlines.length) {
                for(let idx = 0; idx < props.headlines.length; idx++) {
                    if(props.headlines[idx].active) headline = props.headlines[idx].headline;
                }
            }
            // Default
            return headline.trim() || post.postFields.title;
        }

        function changedDuration(item) {
            //var dateExpires = moment().add(item.pinnedDuration, 'hours');
            if(!item.pinnedDuration) {
                item.dateExpires = '0001-01-01T00:00:00';
            } else {
                var dateExpires = add(new Date, { hours: item.pinnedDuration });
                item.dateExpires = formatDateTimeET(dateExpires);
            }
            setEditLock();
            layoutStore.changedColumn['live'] = true;
        }

        return {
            appSettings,
            console1,
            changedDuration,
            changePosition,
            closeEditModal,
            deviceIsMobile,
            durations,
            edit,
            externalPostImage,
            externalPostUrl,
            formatDateTime,
            froalaColorsConfig,
            getAbsoluteUrl,
            getActiveHeadline,
            getAuthorName,
            getDomainFromUrl,
            getEditLink,
            getPermalink,
            getPreviewImage,
            //getPreviewImageCredit,
            getVipBadge,
            gridSizeClass,
            isAutoPilot,
            isEmptyDate,
            isLockedByOtherUser,
            itemDragged,
            layoutStore,
            saving,
            moveColumn,
            movePosition,
            postChanged,
            removePost,
            savePosts,
            showEditModal,
            uppercaseFirst,
            user
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-header {
    /*
    .icon-column-modified {
        color: var(--red-500);
        vertical-align: middle;
    }
    */
    .icon-dot-modified {
        height: 1em;
        width: 1em;
        background-color: var(--pink-500);
        border-radius: 50%;
        display: inline-block;
        vertical-align: text-bottom;
    }
}
div.card-body {
    .post-item {
        //cursor: grab;
        margin-bottom: 0.75em;

        .d-flex.border {
            padding: 0 !important;
        }

        &__toolbar {
            //background-color: var(--teal-500);
            color: #fff;
            padding: 0.2em 0.4em;
            height: 2.1em;
            cursor: all-scroll;

            .btn-toolbar-button {
                border: none;
                margin-top: 0.1em;
                margin-right: 0.5em;
                //background-color: transparent;
                //background-color: var(--teal-50);
                //color: var(--teal-900);
                //color: #fff;
                padding: 0 0.3em;
                border-radius: 0.2em;
                font-size: 0.95em;
                height: 22px;

                &[disabled] {
                    color: #999 !important;
                }
            }
            .btn-toolbar-up,
            .btn-toolbar-down,
            .btn-toolbar-left,
            .btn-toolbar-right {
                font-weight: bold;
                width: 22px;
            }
            .btn-toolbar-up {
                margin-right: 0.2em;
            }
            .btn-toolbar-close {
                vertical-align: middle;
                //margin-top: -0.15em !important;
                cursor: pointer;
            }
            &.expiresSoon {
                background-color: var(--yellow-600);
            }
            &.isExternal {
                // Darken toolbar with gradient when article is external: https://stackoverflow.com/a/72300152/3799374
                background-image: repeating-linear-gradient(-45deg, rgba(0,0,0, 0.3), rgba(0,0,0, 0.3) 2px, transparent 2px, transparent 5px) !important;
                background-size: 2px 2px;
            }
        }
        &__body {
            padding: 0.5em 0.5em 0.4em 0.5em;

            div.preview-image-container {
                a.play-button {
                    &, img {
                        width: 90px;
                        height: 90px;
                    }
                }
                a.preview-image, span.preview-image {
                    background: no-repeat center center / cover;
                    display: inline-block;
                    vertical-align: middle;
                    width: 90px;
                    height: 90px;
                    margin-left: 0.2em;
                }
            }
            h6 {
                a {
                    color: var(--blue-700);

                    &:hover {
                        color: var(--blue-800);
                        text-decoration: underline;
                    }
                }
                &, a {
                    font-size: 1em;
                    padding-bottom: 0.3em;
                }
            }
            ul.list-group {
                li.list-item {
                    font-size: 0.85em;
                    list-style-type: none;
                    color: var(--gray-700);
                }
            }
        }
    }
}
div.card-body.live {
    .post-item {
        &__toolbar {
            background-color: var(--teal-400);

            .btn-toolbar-button {
                background-color: var(--teal-50);
                color: var(--teal-900);
            }
        }
        &__toolbar.modified {
            background-color: var(--pink-500);
        }
    }
}
div.card-body.queue {
    .post-item {
        &__toolbar {
            background-color: var(--gray-500);

            .btn-toolbar-button {
                background-color: var(--gray-50);
                color: var(--teal-900);
            }
        }
        &__toolbar.modified {
            background-color: var(--pink-500);
        }
    }
}
#edit_post_form {
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
}
label.form-label {
    font-weight: 500;
}
</style>
