<template>

    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Promises</div>

                <div class="card-body">

                    <table class="table">
                        <tbody>
                            <tr v-for="line in data" :key="line">
                                <th scope="row">{{ line.label }}</th>
                                <td>{{ line.value }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <hr />
                    <h6>Promise chain:</h6>

                    <ol>
                        <li>postService.getPosts() is run to fetch the most recent post, then</li>
                        <li>adminService.getLogs() is run to fetch the username of the person who last modified it, then</li>
                        <li>postService.postLockStatus() is run to get the current lock status of the post</li>
                    </ol>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, onMounted } from 'vue';
import AdminService from '@/service/AdminService';
import PostService from '@/service/PostService';

export default {
    name: 'PromiseDemo',
    components: {
    },
    setup() {
        const adminService = new AdminService();
        const postService = new PostService();

        const data = ref([]);

        onMounted(() => {

            postService.getPosts({ subscriptionPackage: 1, pageSize: 1 }).then((res) => {
                data.value.push({ label: 'Post ID', value: res[0].postId });
                return adminService.getLogs({ logTypeId: 1, referenceId: res[0].postId, pageSize: 1 });
            }).then((log) => {
                data.value.push({ label: 'Last Modified By', value: log[0].userName });
                return postService.postLockStatus(log[0].referenceId);
            }).then((status) => {
                data.value.push({ label: 'Current Lock Status', value: status.isLocked });
            });

        });

        return {
            data
        };
    },
};
</script>

<style lang="scss" scoped>
</style>

