<template>
    <HomePageManagerSettings />

    <div class="row" id="home_page_mgr">
        <div class="col-xs-12" :class="gridSizeClass">
            <ArticleSources :store="layoutStore" />
        </div>

        <LiveQueue />
    </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useLayoutStore } from '@/stores/LayoutStore';
import HomePageManagerSettings from './Settings.vue';
import ArticleSources from './ArticleSources.vue';
import LiveQueue from './LiveQueue.vue';

export default {
    name: 'HomePageManager',
    components: {
        ArticleSources,
        HomePageManagerSettings,
        LiveQueue
    },
    setup() {
        const appSettings = inject('appSettings');
        const layoutStore = useLayoutStore();

        const gridSizeClass = computed(() => {
            return 'col-md-' + (appSettings.features.homePageManager.showQueue ? 4 : 6);
        });

        return {
            gridSizeClass,
            layoutStore
        }
    }
};
</script>

<style lang="scss" scoped>
#home_page_mgr {
    font-size: 90%;
}
</style>
