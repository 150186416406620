<template>
    <!-- Filter bar -->
    <div class="row mb-3">
        <div class="col">
            <section class="card">
                <div class="card-body container-fluid">
                    <div class="row filter-row g-2">

                        <div class="col-xs-12 col-lg-5">
                            <!--<InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search... (Ctrl+K or ⌘K)" v-on:keyup.enter="updateFilter" autofocus />-->
                            <InputText class="form-control" v-model="filter.title" id="SearchText" placeholder="Search..." v-on:keyup.enter="updateFilter" autofocus />
                        </div>
                        <div class="col-xs-12 col-sm-6 col-lg-2">
                            <Calendar v-model="filterDatePublished" :showButtonBar="true" :showIcon="true" placeholder="Date" dateFormat="mm/dd/yy" hourFormat="12" class="mb-2" x-style="max-width: 155px" />
                        </div>
                        <!--<div class="col-xs-12 col-lg-3">-->
                        <div class="col-md-auto">
                            <AuthorsAutoComplete @keyup.enter="updateFilter" v-model="autoCompleteAuthor" :authorId="filter.authorId" class="me-2 mb-2" placeholder="Author" />
                        </div>
                        <div class="col-xs-12 col-sm-6 col-lg-2">
                            <Button label="" icon="far fa-search" iconPos="right" @click="updateFilter" />
                            <Button label="" icon="far fa-times" iconPos="right" severity="secondary" outlined class="ms-2" @click="clearFilter" />
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, onMounted, watch } from 'vue';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';
import AuthorsAutoComplete from '@/components/AuthorsAutoComplete.vue';

export default {
    name: 'VirtualPageFilterBar',
    components: {
        AuthorsAutoComplete
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const keys = useMagicKeys();

        const filter = ref({});
        const autoCompleteAuthor = ref(null);

        //whenever(keys['Ctrl+K'], () => document.getElementById('SearchText').select());
        //whenever(keys['Meta+K'], () => document.getElementById('SearchText').select());

        const filterDatePublished = computed({
            get() {
                return filter.value.datePublished == null ? null : formatDateTime(filter.value.datePublished, 'MM/dd/yyyy');
            },
            set(newValue) {
                filter.value.datePublished = newValue ? formatDateTime(newValue, 'yyyy-MM-dd HH:mm:ss') : null;
            },
        });

        watch(() => autoCompleteAuthor.value, (newVal) => {
                filter.value.authorId = newVal?.value ?? null;
            }
        );

        // Mounted
        onMounted(() => {
            // Set filter bar based on query string values
            const query = Object.assign({}, route.query);
            filter.value = query;
        });

        function updateFilter() {
            router.push({ query: filter.value });
        }

        function clearFilter() {
            filter.value = {};
            autoCompleteAuthor.value = null;
            router.push({ query: filter.value });
        }

        return {
            autoCompleteAuthor,
            clearFilter,
            filter,
            filterDatePublished,
            updateFilter,
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    input.p-inputtext {
        height: 42px;
    }
}
</style>
