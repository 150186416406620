<template>

        <div class="col-sm-12 col-md-7 col-xl-6">
            <div class="card card-border-color card-border-color-primary routes">
                <div class="card-body route-list container-fluid pb-3">
                    <PaginationThm v-model="route.query.pageIndex" :totalRecords="totalRows" :pageSize="pageSize" @page="onPage" class="mb-1" />

                    <div class="row row__header pb-2 mb-1">
                        <div class="col-2"></div>
                        <div class="col-10 fw-bold ps-3">Route</div>
                        <!--<div class="col-sm-6 col-md-3 d-none d-sm-block fw-bold text-center">Slug</div>-->
                        <!--<div class="col-2 text-center" title="Post Count" alt="Post Count">#</div>-->
                        <!--<div class="col-md-1 col-lg-1 d-none d-md-block"></div>-->
                    </div>

                    <p class="fst-italic text-muted ps-1 pt-4" v-show="Object.keys(route.query).length && !pages.length">No items found matching criteria.</p>

                    <div v-for="page in pages" :key="page.id" class="row seo-item item" v-bind:class="{ active: route.params.id == page.id }">
                        <div class="col-auto d-none d-md-block text-end">
                            <Button label="Edit" class="btn-edit" @click="editItem(page.id)" />
                        </div>

                        <div class="col-sm-9 col-md-10 ps-3">
                            <span class="d-none d-md-block">{{ ellipsize(page.title, 100) }}</span>
                            <a href="#" class="d-block d-md-none" @click="editItem(page.id)">{{ ellipsize(page.title, 100) }}</a>
                            <div class="seo-item__path font-monospace">{{ page.path }}</div>
                        </div>
                    </div>

                    <PaginationThm v-model="route.query.pageIndex" :totalRecords="totalRows" :pageSize="pageSize" @page="onPage" class="mt-3" />
                </div>
            </div>
        </div>

</template>

<script>
//import { onMounted, ref, watch } from 'vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, watch } from 'vue';
import PaginationThm from '@/components/PaginationThm.vue';
import SeoService from '@/service/SeoService';
import ellipsize from 'ellipsize';

export default {
    name: 'SeoList',
    components: {
        PaginationThm
    },
    setup() {
        const router = useRouter();
        const seoService = new SeoService();
        const route = useRoute();

        const filter = ref({});
        const pages = ref([]);
        const pageSize = ref(25);
        const totalRows = ref(0);

        // Watch for route changes
        watch(
            () => route.query, (params, fromParams) => {
                if(console.log.hide) console.log({ from: fromParams, to: params });
                filter.value = params;
                seoService.getSeoItems({ ...params, pageSize: pageSize.value }).then((res) => {
                    pages.value = res;
                    if(res.length && res[0].totalRows) totalRows.value = res[0].totalRows;
                });
            }
        );

        onMounted(() => {
            seoService.getSeoItems({ ...route.query, pageSize: pageSize.value }).then((res) => {
                pages.value = res;
                if(res.length && res[0].totalRows) totalRows.value = res[0].totalRows;
            });
        });

        function editItem(seoId) {
            router.push({ path: `/settings/seo/${seoId}`, query: route.query });
        }

        function onPage(e) {
            router.push({ query: { ...route.query, pageSize: e.rows, pageIndex: e.page } });
            seoService.getSeoItems({ ...route.query, pageSize: pageSize.value, pageIndex: e.page }).then((res) => {
                if(console.log.hide) console.log('res', res);
                pages.value = res;
            });
        }

        return {
            editItem,
            ellipsize,
            onPage,
            pages,
            pageSize,
            route,
            router,
            totalRows
        };
    }
}
</script>

<style lang="scss" scoped>
.route-list {
    .seo-item {
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        a {
            color: var(--bs-card-color);
        }

        &__path {
            color: var(--bs-code-color);
            //color: red;
        }

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }
    }
}
</style>
