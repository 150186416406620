<template>
    <div class="col-sm-12 col-md-7">
        <ConfirmDialog />
        <div class="card card-border-color card-border-color-primary" id="article_sources">
            <div class="card-header card-header-divider" >
                <span class="align-middle">{{ afternoonStore.posts.length }} of {{afternoonStore.execpectedCount}} Posts(s) selected.</span>

                <Button label="Save" class="p-button-smaller align-middle mt-1 ms-1 float-end" @click="saveLive()" x-title="Save (Ctrl+S or ⌘S)" />
                <Button label="Clear" class="p-button-smaller p-button-outlined align-middle mt-1 ms-1 float-end" @click="clear()" title="Clear" />&nbsp;
                <LogViewerThm :filter="{ logTypeId: 15, referenceId: 4 }" label="" icon="pi pi-list" tag="button" class="p-button-smaller p-button-secondary p-button-outlined btn-log align-middle mt-1 float-end" />
                <span class="float-end published me-3">
                    <label for="IsPublished" class="switch-label">Published</label> <InputSwitch v-model="afternoonStore.isPublished" :disabled="(afternoonStore.posts.length!=afternoonStore.execpectedCount)" id="IsPublished"/>
                </span>
                <span class="icon-dot-modified" v-show="afternoonStore.changedColumn.live" v-tooltip.top="'Unsaved changes'"></span>
            </div>

            <div class="card-body live">
                <Draggable class="drag-area" :list="afternoonStore.posts" :group="{ name: 'g1' }" item-key="layoutPostId" @change="itemDragged('live')" :disabled="deviceIsMobile()">
                    <template #item="{ element }">
                        <div :key="element.layoutPostId" class="post-item">
                            <div class="border">
                                <div class="post-item__toolbar" v-bind:class="{ modified: afternoonStore.changedPosts.includes(element.postId) }" :style="{'background-color':this.sectionColor(element.ordinalId)}">
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-up" @click="afternoonStore.movePosition(element, 'up')"><i class="far fa-arrow-up"></i></button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-down" @click="afternoonStore.movePosition(element, 'down')"><i class="far fa-arrow-down"></i></button>
                                    <select class="btn-toolbar-button" v-model="element.ordinalId" @change="changePosition($event, 'live', element)">
                                        <option v-for="(item, index) in afternoonStore.posts" :key="item.layoutPostId" v-bind:value="index + 1">{{ index + 1 }}</option>
                                    </select>

                                    <div class="float-end text-end" x-style="width: 200px;">
                                        <i class="pi pi-times btn-toolbar-close" @click="afternoonStore.remove(element, 'live')"></i>
                                    </div>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-edit float-end" @click="edit(element, 'live')">Edit</button>
                                </div>

                                <div class="post-item__body">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="mb-0">

                                                <a :href="getPermalink(element)" target="_blank" v-html="element.title" :value="getActiveHeadline(element)"></a>
                                                <i class="far fa-asterisk fw-bold text-warning ps-1" v-if="element.isTitleOverride" :title="getActiveHeadline(element, true)"></i>
                                            </h6>
                                            <div>
                                                <small class="d-block smaller">{{ element.byLine }} | {{ formatDateTime(element.datePublished) }} {{ getTimezone() }} </small>
                                            </div>
                                            <span v-if="element.subscriptionLevel > 1">
                                                <img :src="getVipBadge(element.subscriptionLevel)" alt="VIP" class="vip-badge" />
                                            </span>
                                        </div>
                                        <div class="col-md-auto">
                                            <img v-bind:src="resizeImage(element.mainImage)" alt="" style="max-height: 120px" />
                                        </div>
                                        <!-- <div>
                                            <small v-if="element.isTitleOverride" style="color:red; font-size:1.1em"> *Title Override </small>
                                            <small v-if="element.isImageOverride" style="color:red; font-size:1.1em"> *Image Override </small>

                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Draggable>
            </div>
        </div>
    </div>

    <Dialog header="Header" v-model:visible="showEditModal" @hide="closeEditModal" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{ width: '50vw' }">
        <template #header>
            <h5>Edit Post</h5>
        </template>

        <form>


            <div class="mb-3">

                <label for="Title" class="form-label">Title</label>
                <i class="far fa-asterisk fw-bold text-warning ps-1" v-if="afternoonStore.post.isTitleOverride" :title="getActiveHeadline(afternoonStore.post, true)"></i>
                <!--<InputText type="text" class="form-control" v-model="post.title" id="editTitle" @change="changedPosts.push(post.rowId)" />-->
                 <InputText type="text" class="form-control" v-model="afternoonStore.post.title" :value="getActiveHeadline(afternoonStore.post)" id="Title" placeholder="Title" />
                 <!-- <small v-if="afternoonStore.post.isTitleOverride" style="color:red; font-size:1.1em"> Title Override </small> -->
            </div>

            <div class="mb-3">
                <InputText type="text" class="form-control" v-model="afternoonStore.post.byLine" id="ByLine" placeholder="By Line" />
            </div>

            <div class="mb-3">
                <InputText type="text" class="form-control" v-model="afternoonStore.post.url" :value="getPermalink(afternoonStore.post)" id="Url" placeholder="https://" @change="postChanged" />
            </div>

            <div class="mb-3 row">
                <ImageLibrary v-model="afternoonStore.post.mainImage" />
            </div>


        </form>

        <template #footer>
            <Button v-if="!afternoonStore.post.isTitleOverride" label="Override Title" icon="pi pi-plus" class="p-button-accept" @click="afternoonStore.post.isTitleOverride = true" />
            <Button v-else label="Override Title" icon="pi pi-times" class="p-button-danger" @click="afternoonStore.post.isTitleOverride = false" />
            <Button v-if="!afternoonStore.post.isImageOverride" label="Override Image" icon="pi pi-plus" class="p-button-accept" @click="afternoonStore.post.isImageOverride = true" />
            <Button v-else label="Override Image" icon="pi pi-times" class="p-button-danger" @click="afternoonStore.post.isImageOverride = false" />
            <Button label="Close" icon="pi pi-times" autofocus @click="showEditModal = false" />

        </template>
    </Dialog>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import { formatDateTime, getTimezone } from '@/utils/ThmDateTimeUtils';
import { getVipBadge, resizeImageUrl } from '@/utils/ThmImageUtils';
import { getActiveHeadline } from '@/utils/ThmUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useAfternoonStore } from '@/stores/AfternoonStore';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import ConfirmDialog from 'primevue/confirmdialog';
import AdminService from '@/service/AdminService';
import LogViewerThm from '@/components/LogViewerThm.vue';
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';
import Draggable from 'vuedraggable';

export default {
    name: 'TownhallAfternoonCenterQueue',
    components: {
        Draggable,
        ConfirmDialog,
        ImageLibrary,
        LogViewerThm
    },
    setup() {
        const adminService = new AdminService();
        const toast = useToast();
        const afternoonStore = useAfternoonStore();
        const confirm = useConfirm();
        const subscriptionTypes = ref([]);
        const showEditModal = ref(false);

        onBeforeMount(() => {
            subscriptionTypes.value = adminService.getSubscriptions();
            //siteStore.load();
            afternoonStore.load();

            //console.log('AfternoonStore Nlcontent: ', afternoonStore.customNl.nlContent);
        });

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveLive());
        whenever(meta_s, () => saveLive());
        */

        function getPermalink(post) {
            if(post.url.startsWith('http')) return post.url;
            return `https://townhall.com${post.url}`;
        }

        function closeEditModal() {
            afternoonStore.post = {};
        }

        function clear() {
            confirm.require({
                message: 'Are you sure you want to clear the list?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    afternoonStore.posts = [];
                    afternoonStore.changedColumn.live = false;
                },
            });
        }

        function saveLive() {
            afternoonStore.customNl.nlContent = [];
            afternoonStore.customNl.isPublished = afternoonStore.isPublished;
            var ordinalCounter = 1;
            var isValid = true;

            afternoonStore.posts.forEach((element) => {
                if(isValid) {
                    var nlContentPost = {
                        sectionId: 0,
                        ordinalId: ordinalCounter,
                        title: getActiveHeadline(element),
                        teaser: element.teaser,
                        mainImage: element.mainImage,
                        byLine: element.byLine,
                        url: element.url,
                        datePublished: element.datePublished,
                        subscriptionLevel: element.subscriptionLevel,
                        isTitleOverride: element.isTitleOverride,
                        isImageOverride: element.isImageOverride,
                    };

                    if(element.title == null || element.title == '') {
                        toast.add({ severity: 'error', summary: 'Error saving post', detail: 'Article missing title', life: 3000 });
                        isValid = false;
                    }

                    if(element.url == null || element.url == '') {
                        toast.add({ severity: 'error', summary: 'Error saving post', detail: 'Article missing url', life: 3000 });
                        isValid = false;
                    }

                    if(element.mainImage == null || element.mainImage == '') {
                        toast.add({ severity: 'error', summary: 'Error saving post', detail: 'Article missing image', life: 3000 });
                        isValid = false;
                    }

                    afternoonStore.customNl.nlContent.push(nlContentPost);
                    ordinalCounter++;

                    //console.log(element.title);
                }
            });

            if (isValid) {
                var totalExpected = afternoonStore.customNl.configuration.section1.limit + afternoonStore.customNl.configuration.section2.limit + afternoonStore.customNl.configuration.section3.limit;
                if (totalExpected != afternoonStore.customNl.nlContent.length) {
                    afternoonStore.isPublished = false;
                }

                if (isValid) {
                    try {
                        afternoonStore
                            .save()
                            .then(() => {
                                afternoonStore.changedColumn.live = false;

                                if (totalExpected != afternoonStore.customNl.nlContent.length) {
                                    var infoMessage = {
                                        summary: null,
                                        detail: null,
                                    };

                                    if (afternoonStore.customNl.nlContent.length > totalExpected) {
                                        infoMessage.summary = 'Saved but not published. You have ' + (afternoonStore.customNl.nlContent.length - totalExpected) + ' too many post(s)';
                                        infoMessage.detail = 'Remove ' + (afternoonStore.customNl.nlContent.length - totalExpected) + '  post(s)';
                                    } else {
                                        infoMessage.summary = 'Saved but not published.  You have ' + (totalExpected - afternoonStore.customNl.nlContent.length) + ' too few post(s)';
                                        infoMessage.detail = 'Add  ' + (totalExpected - afternoonStore.customNl.nlContent.length) + '  post(s)';
                                    }
                                    toast.add({ severity: 'info', summary: infoMessage.summary, detail: infoMessage.detail, life: 2000 });
                                } else {
                                    toast.add({ severity: 'success', summary: 'Success', detail: 'Saved', life: 2000 });
                                }
                            })
                            .catch((err) => {
                                toast.add({ severity: 'error', summary: 'Error saving post', detail: err, life: 3000 });
                            });
                    } catch (error) {
                        toast.add({ severity: 'error', summary: 'Error saving post', detail: error, life: 3000 });
                    }
                }

                console.log('Result:', afternoonStore.customNl.nlContent);
            }
        }

        function sectionColor(count)
        {
               let config = afternoonStore.customNl.configuration;

               if(count <= config.section1.limit){
                    return config.section1.color;
               }

               if(count > config.section1.limit && count <= config.section1.limit + config.section2.limit){
                    return config.section2.color;
               }

              if(count > (config.section1.limit + config.section2.limit) && count <= config.section2.limit + config.section2.limit + config.section3.limit){
                     return config.section3.color;
              }
              return "";

        }

        function resizeImage(url, size='230x120') {
            if (!url.includes('https://media.townhall.com/cdn/hodl/')) {
                return url;
            }
          return resizeImageUrl(url, size);

        }

        function changePosition(event, status, post) {
            afternoonStore.changedColumn.live = true;
            afternoonStore.movePosition(post, event.target.value);
        }

        function edit(post, status) {
            afternoonStore.setPost(post);
            afternoonStore.post.status = status;
            showEditModal.value = true;
            afternoonStore.changedColumn.live = true;
            console.log(afternoonStore.post);
        }

        function postChanged() {
            afternoonStore.changedColumn[afternoonStore.post.status] = true;
            afternoonStore.changedColumn.live = true;
            afternoonStore.changedPosts.push(afternoonStore.post.postId);
        }

        function itemDragged(status) {
            afternoonStore.changedColumn[status] = true;
            afternoonStore.renumber(status);
        }

        return {
            afternoonStore,
            changePosition,
            clear,
            closeEditModal,
            deviceIsMobile,
            edit,
            formatDateTime,
            getActiveHeadline,
            getPermalink,
            getTimezone,
            getVipBadge,
            itemDragged,
            postChanged,
            resizeImage,
            saveLive,
            sectionColor,
            showEditModal
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-header {
    /*
    .icon-column-modified {
        color: var(--red-500);
        vertical-align: middle;
    }
    */
    .icon-dot-modified {
        height: 1em;
        width: 1em;
        background-color: var(--pink-500);
        border-radius: 50%;
        display: inline-block;
        vertical-align: text-bottom;
    }
}
div.card-body {
    .post-item {
        //cursor: grab;
        margin-bottom: 0.75em;

        .d-flex.border {
            padding: 0 !important;
        }

        &__toolbar {
            //background-color: var(--teal-500);
            color: #fff;
            padding: 0.2em 0.4em;
            height: 2.1em;

            .btn-toolbar-button {
                border: none;
                margin-top: 0.1em;
                margin-right: 0.5em;
                //background-color: transparent;
                //background-color: var(--teal-50);
                //color: var(--teal-900);
                //color: #fff;
                padding: 0 0.3em;
                border-radius: 0.2em;
                font-size: 0.95em;
                height: 22px;
            }
            .btn-toolbar-up,
            .btn-toolbar-down,
            .btn-toolbar-left,
            .btn-toolbar-right {
                font-weight: bold;
                width: 22px;
            }
            .btn-toolbar-up {
                margin-right: 0.2em;
            }
            .btn-toolbar-close {
                vertical-align: middle;
                //margin-top: -0.15em !important;
                cursor: pointer;
            }

            &.expiresSoon {
                background-color: var(--yellow-600);
            }
        }
        &__body {
            padding: 0.5em 0.5em 0.4em 0.5em;

            h6 {
                &,
                a {
                    font-size: 1em;
                    color: var(--blue-700); // --red-700
                    padding-bottom: 0.3em;
                }
                a:hover {
                    color: var(--blue-800); // --red-900
                    text-decoration: underline;
                }
            }
            ul.list-group {
                li.list-item {
                    font-size: 0.85em;
                    list-style-type: none;
                    color: var(--gray-700);
                }
            }
        }
    }
}
div.card-body.live {
    .post-item {
        &__toolbar {
            background-color: var(--teal-400);

            .btn-toolbar-button {
                background-color: var(--teal-50);
                color: var(--teal-900);
            }
        }
        &__toolbar.modified {
            background-color: var(--pink-500);
        }
    }
}
div.card-body.queue {
    .post-item {
        &__toolbar {
            background-color: var(--gray-500);

            .btn-toolbar-button {
                background-color: var(--gray-50);
                color: var(--teal-900);
            }
        }
        &__toolbar.modified {
            background-color: var(--pink-500);
        }
    }
}

label.form-label {
    font-weight: 500;
}
img.vip-badge {
    height: 1em;
}
/*
.btn-clear {
    padding: 0em 0.5em;
    margin-left: 3px;
    margin-top: 2px;
}
.btn-save {
    margin-top: 2px;
}
*/

.switch-label {
    padding: 0.15em 0 0 0.5em;
    vertical-align: top;
}
.published{
    height: 35px;
    padding-top: 4px;
}
</style>

<style lang="scss">
button.btn-log {
    padding: 0em 0.5em !important;
}
</style>
