<template>
    <ConfirmDialog />

    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-4 actions">
                <div class="card card-border-color card-border-color-primary shows">
                    <div class="card-header card-header-divider">Add Menu Item</div>

                    <div class="card-body">
                        <div class="mb-3">
                            <label for="Label" class="form-label required">Label</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.label" id="Label" />
                        </div>

                        <div class="mb-3">
                            <label for="Url" class="form-label required">URL / Path</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.url" id="Url" />
                        </div>

                        <div class="mb-3">
                            <InputSwitch v-model="addMenuItem.target" id="Target" />
                            <label for="Target" class="switch-label">Open in new tab</label>
                        </div>

                        <div class="mb-3">
                            <label for="Classes" class="form-label">CSS Classes</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.classes" id="Classes" />
                            <span class="help">Optional CSS classes, separated by spaces.</span>
                        </div>

                        <div class="mb-3">
                            <label for="Icon" class="form-label">Icon</label>
                            <InputText type="text" class="form-control" v-model="addMenuItem.icon" id="Icon" />
                        </div>

                        <div class="mb-3">
                            <Button label="Add to Menu" icon="pi pi-angle-double-right" iconPos="right" class="float-end" @click="addItem()" v-if="!addMenuItem.id && addMenuItem.id !== 0" />
                            <Button label="Clear" severity="secondary" outlined class="float-end" @click="item = { item: {} }" v-else />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-8">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">
                        <div v-show="userHasAccessToRole('Admin')" class="float-end">
                            <Button icon="pi pi-trash" severity="secondary" outlined class="btn-add p-button-tiny me-1" iconPos="right" @click="deleteMenu()" title="Delete Menu (admin only)" />
                            <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny" iconPos="right" @click="showModal = true" title="Add New Menu" />
                        </div>
                        Menu Structure
                    </div>
                    <div class="card-body">
                        <Dropdown id="ActiveMenu" v-model="selectedMenu" :options="menuStore.menus" optionLabel="label" optionValue="websiteMenuId" class="d-flex" @change="changeSelectedMenu" />

                        <form class="form-floating mt-3" v-if="selectedMenu">
                            <input type="text" class="form-control" id="Description" v-model="menuStore.menu.label" />
                            <label for="Description">Menu Description</label>
                        </form>

                        <h5 class="my-3">Menu Items</h5>
                        <menu-draggable :menu="menuStore.menu.menu" v-if="menuStore.menu" />

                        <LogViewerThm label="Change Log" :filter="{ logTypeId: 8, referenceId: menuStore.menu.websiteMenuId }" v-if="menuStore.menu" class="p-button-secondary p-button-outlined" tag="button" />
                        <Button label="Save Menu" class="float-end" @click="saveMenu()" x-title="Save (Ctrl+S or ⌘S)" />
                    </div>
                </div>

                <Dialog v-model:visible="showModal">
                    <template #header>
                        <h3>Add New Menu</h3>
                    </template>

                    <div class="mb-1">
                        <label for="addLabel" class="form-label required">Label</label>
                        <InputText type="text" class="form-control" v-model="newMenu.label" id="addLabel" placeholder="New Menu" />
                    </div>

                    <div class="mb-1">
                        <label for="addSlug" class="form-label required">Slug</label>
                        <InputText type="text" class="form-control" v-model="newMenu.slug" id="addSlug" placeholder="new-menu" />
                    </div>

                    <div class="mb-1">
                        <label for="addView" class="form-label required">View Name</label>
                        <InputText type="text" class="form-control" v-model="newMenu.view" id="addView" placeholder="Default" />
                    </div>

                    <template #footer>
                        <Button label="Add Menu" @click="addNewMenu()" />
                    </template>
                </Dialog>
            </div>
        </div>
    </form>
</template>

<script>
import { ref, onBeforeMount, getCurrentInstance } from 'vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useMenuStore } from '@/stores/MenuStore';
import ConfirmDialog from 'primevue/confirmdialog';
import LogViewerThm from '../../components/LogViewerThm.vue';
import menuDraggable from './MenuComponent.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'TestMenuDemo',
    components: {
        ConfirmDialog,
        menuDraggable,
        LogViewerThm,
    },
    setup() {
        const app = getCurrentInstance();
        const { $confirm, $route, $router, $toast } = app.appContext.config.globalProperties;
        const { user } = useAuth0();
        const menuStore = useMenuStore();
        const selectedMenu = ref(null);
        //const addMenuItem = ref({ label: 'xyz', url: '/tipsheet' });
        const addMenuItem = ref({});
        const newMenu = ref({ view: 'Default', menu: [] });
        const showModal = ref(false);

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => saveMenu());
        whenever(meta_s, () => saveMenu());
        */

        onBeforeMount(() => {
            // Get all menus, then set the selected menus to the first one in the array
            menuStore
                .load()
                .then(() => {
                    if ($route.params.id) {
                        // Select menu from URL
                        selectedMenu.value = $route.params.id;
                    } else if (menuStore.menus.length) {
                        // Select first menu
                        selectedMenu.value = menuStore.menus[0].websiteMenuId;
                    }
                    if (selectedMenu.value) {
                        // Load selected menu
                        menuStore.setMenu(selectedMenu.value);
                        selectedMenu.value = menuStore.menu.websiteMenuId;
                    }
                })
                .catch((err) => {
                    $toast.add({ severity: 'error', summary: 'Error Loading Menus', detail: err.message || err, life: 3000 });
                });
        });

        function changeSelectedMenu(menuId) {
            menuId = menuId.value || selectedMenu.value;
            menuStore.setMenu(menuId);
            $router.push(`/settings/menu/${menuId}`);
        }

        function addItem() {
            if (!addMenuItem.value.label || !addMenuItem.value.label.trim() || !addMenuItem.value.url || !addMenuItem.value.url.trim()) {
                $toast.add({ severity: 'error', summary: 'Error Adding Menu Item', detail: 'Label and URL fields may not be empty', life: 5000 });
                return;
            }

            menuStore.menu.menu = [...menuStore.menu.menu, addMenuItem.value];
            addMenuItem.value = {};
        }

        function deleteMenu() {
            $confirm.require({
                message: 'Are you sure you want to permanently delete this menu? This is dangerous and irreversible!',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    menuStore
                        .delete(selectedMenu.value)
                        .then(() => {
                            $toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Deleted', life: 3000 });
                            if (menuStore.menus.length) selectedMenu.value = menuStore.menus[0].websiteMenuId; // Select first menu
                            $router.push(`/settings/menu`);
                        })
                        .catch((err) => {
                            $toast.add({ severity: 'error', summary: 'Error Deleting Menu', detail: err.message || err, life: 3000 });
                        });
                },
            });
        }

        function addNewMenu() {
            if (typeof newMenu.value.label == 'undefined' || !newMenu.value.label.trim() || newMenu.value.slug == 'undefined' || !newMenu.value.slug.trim()) {
                this.$toast.add({ severity: 'error', summary: 'Error Saving Menu', detail: 'Label and Slug fields may not be empty', life: 5000 });
                return;
            }

            // Add new menu
            menuStore
                .add(newMenu.value)
                .then(() => {
                    // Add menu to dropdown
                    selectedMenu.value = menuStore.menu.websiteMenuId;
                    newMenu.value = { view: 'Default', menu: [] };
                    showModal.value = false;
                    $router.push(`/settings/menu/${selectedMenu.value}`);

                    // Display notification
                    $toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Deleted', life: 3000 });
                })
                .catch((err) => {
                    $toast.add({ severity: 'error', summary: 'Error Saving Menu', detail: err.message || err, life: 3000 });
                });
        }

        function saveMenu() {
            menuStore
                .save()
                .then(() => {
                    $toast.add({ severity: 'success', summary: 'Success', detail: 'Menu Saved', life: 3000 });
                })
                .catch((err) => {
                    $toast.add({ severity: 'error', summary: 'Error Saving Episode', detail: err.message || err, life: 3000 });
                });
        }

        return {
            addItem,
            addMenuItem,
            addNewMenu,
            changeSelectedMenu,
            deleteMenu,
            menuStore,
            newMenu,
            saveMenu,
            showModal,
            selectedMenu,
            user,
            userHasAccessToRole,
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
    ul.drag-area {
        max-width: 1200px;
    }
}
label.required::after {
    content: '*';
    color: rgb(255, 0, 0);
}
</style>
