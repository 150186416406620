<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <!-- Left Column -->
            <PodcastList />

            <!-- Episode editor -->
            <PodcastEpisode v-if="route.path.includes('episodes')" />

            <!-- Show editor -->
            <PodcastShow v-else />
        </div>
    </form>
</template>

<script>
import { useRoute } from 'vue-router';
import PodcastList from './PodcastList.vue';
import PodcastShow from './PodcastShow.vue';
import PodcastEpisode from './PodcastEpisode.vue';

export default {
    name: 'Podcasts',
    components: {
        PodcastList,
        PodcastShow,
        PodcastEpisode,
    },
    setup() {
        const route = useRoute();

        return { route };
    },
};
</script>
