//import useAuth0 from '@/use/Auth0';
//import AppSettings from '/appsettings.json';
import { getAppSettings } from '@/utils/ThmUtils';
import { parseJwt } from '@/utils/ThmStringUtils';
//import { getCurrentInstance } from 'vue';

export function getAuthState() {
    let appSettings = getAppSettings();
    let localStorageVariable = `@@auth0spajs@@::${appSettings.auth0.clientId}::${appSettings.auth0.audience}::openid profile email`;
    let result = null;
    try {
        result = JSON.parse(localStorage.getItem(localStorageVariable));
    } catch (error) {
        return null;
    }
    return result;
}

/**
 * Get roles from auth token
 * @example
 * // returns: [ 'Admin', 'Image-Library-Admin' ]
 * getRoles()
 */
export function getRolesSilently() {
    let appSettings = getAppSettings();
    /*
    const app = getCurrentInstance();
    const $auth0 = app.appContext.config.globalProperties.$auth0;
    return $auth0.user.value['https://cms.townhall.com/api/roles'];
    */

    let authState = getAuthState();
    let user = parseJwt(authState?.body?.access_token);
    let roles = user ? user[`${appSettings.auth0.audience}/roles`] : []
    return roles;
}

/**
 * Check if a user has access given an array of groups
 * @param {Array} roles Array of role/group names
 * @example
 * // returns true if the user is in the Editor group
 * userHasAccessToRoles( ['Editor'] );
 * // returns true if the user is in Admin or Developer groups
 * userHasAccessToRoles( ['Admin', 'Developer'] );
 * // returns false (nobody has access)
 * userHasAccessToRoles( [] );
 * // returns true (everyone has access)
 * userHasAccessToRoles( null );
 */
export function userHasAccessToRoles(roles = null) {
    // Return true if roles is null or not an array
    if(!Array.isArray(roles)) return true;

    /*
    const { user } = useAuth0();
    let userGroups = user.value['https://cms.townhall.com/api/roles'] || []; // Array of the user's groups
    */

    /*
    let authState = getAuthState();
    let user = parseJwt(authState?.body?.access_token);
    let userGroups = user['https://cms.townhall.com/api/roles'] || []; // Array of the user's groups
    */
    let userGroups = getRolesSilently();

    // Loop through the array of roles/groups passed to see if the user is in at least one of them
    for(let idx = 0; idx < roles.length; idx++) {
        if(userGroups.includes(roles[idx])) return true;
    }

    // Return false if the user is in none of the groups/roles
    return false;
}

/**
 * Check if a user has access given role (or higher role)
 * @param {string} role The name of the role to check
 * @example
 * // returns true if the user has the Contributor role OR a higher role
 * userHasAccessToRole( 'Contributor' );
 */
export function userHasAccessToRole(role = null) {
    // Return true if role is not specified
    if(!role) return true;

    /*
    const { user } = useAuth0();
    let userGroups = user.value['https://cms.townhall.com/api/roles'] || []; // Array of the user's groups
    */

    /*
    let authState = getAuthState();
    let user = parseJwt(authState?.body?.access_token);
    let userGroups = user['https://cms.townhall.com/api/roles'] || []; // Array of the user's groups
    */
    let userGroups = getRolesSilently();

    // Return true if the user explicitely has the role
    if(userGroups.includes(role)) return true;

    // Get the role level of the specified role
    let appSettings = getAppSettings();
    if(!appSettings.roles) return false;
    let roles = appSettings.roles;
    let roleNeeded = roles.find((obj) => obj.name == role);

    if(!roleNeeded) return false; // Role not found
    if(roleNeeded.value < 1) return true; // Everyone

    // Loop through roles and return true if user has access to a higher role
    if(!appSettings.roles) return false;
    let usersHighestRoleLevel = 0;
    for(let idx = 0; idx < roles.length; idx++) {
        if(userGroups.includes(roles[idx].name)) usersHighestRoleLevel = roles[idx].value;
    }
    if(usersHighestRoleLevel >= roleNeeded.value) return true;

    // Otherwise, return false
    return false;
}

export function getAuthToken() {
    let authState = getAuthState();
    return authState?.body?.access_token ?? null;
}


export function getUser() {
    //let authState = getAuthState();
    let user = JSON.parse(window.localStorage.getItem('thm-auth-user'));
    return user;
}

export default {
    getAuthState,
    getAuthToken,
    getRolesSilently,
    getUser,
    userHasAccessToRole,
    userHasAccessToRoles
};
