//import { getCurrentInstance } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { getAuthState } from '@/utils/ThmAuthUtils';
import { getAppSettings } from '@/utils/ThmUtils';

//modularizing for setup - reusable code in composition api
export default function useThmAuth() {
    const appSettings = getAppSettings();
    const { user, idTokenClaims, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    //https://github.com/auth0/auth0-vue/blob/main/FAQ.md#2-user-is-not-logged-in-after-successful-sign-in-with-redirect
    // const accessToken = ref();
    //const app = getCurrentInstance();
    //pull from mixin global auth
    //let $auth = app.appContext.config.globalProperties.$auth;
    // accessToken.value = await getAccessTokenSilently();
    //get current authState
    //let authMan = $auth.authStateManager.getAuthState();
    //change to properties that matter
    //let auth = authMan.idToken.claims;
    //user.value = auth;
    //anything that needs to be exposed to a template above goes here!!
    //const accessToken = localStorage.getItem("@@auth0spajs@@::qGYWCdOvHgtFBIAEk11D2g6Dz6SFHwc7::https://cms.townhall.com/api::openid profile email")
    const authState = getAuthState();

    // User roles
    let userRoles = [];
    if(user.value && user.value[`${appSettings.auth0.audience}/roles`]) {
        userRoles = user.value[`${appSettings.auth0.audience}/roles`];
    }

    // App meta
    let appMeta = {};
    if(user.value && user.value[`${appSettings.auth0.audience}/app_metadata`]) {
        appMeta = user.value[`${appSettings.auth0.audience}/app_metadata`];
    }

    // User meta
    let userMeta = {};
    if(user.value && user.value[`${appSettings.auth0.audience}/user_metadata`]) {
        userMeta = user.value[`${appSettings.auth0.audience}/user_metadata`];
    }

    return {
        // accessToken: async () => {
        //     return await getAccessTokenSilently()
        // },
        appMeta,
        authState,
        getAccessTokenSilently,
        idTokenClaims,
        isAuthenticated,
        logout,
        user,
        userMeta,
        userRoles,
    };
}
