<template>
    <ConfirmDialog />
    <Dialog header="Post locked for editing" v-model:visible="showTakeOver" :modal="true" :closeOnEscape="false" :closable="false">
        <!--<p>This post is currently locked. If you take over, {{ postEditStore.post.userIdLock }} will be blocked from continuing to edit.</p>-->
        <p>This post is currently locked. If you take over, {{ userLock }} will be blocked from continuing to edit.</p>
        <template #footer>
            <Button label="Preview" icon="pi pi-times" @click="readonlyPost()" text />
            <Button label="Take over" icon="pi pi-check" @click="takeOverPost()" autofocus :disabled="!isSaveAllowed" />
        </template>
    </Dialog>
    <div class="col-md-7">
        <div class="card card-border-color card-border-color-primary" id="post_editor_top">
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <div v-if="postEditStore.post.postId > 0">
                        <span v-if="postEditStore.isDirty" v-tooltip="postEditStore.post.userIdLock + ' is currently editing'" class="far fa-lock text-danger">&nbsp;</span>
                        <span v-if="postEditStore.isReadonly">Preview </span>
                        <span v-else>Edit </span>
                        Post: {{ postEditStore.post.postId }}
                    </div>
                    <div v-else>New Post</div>
                    <div class="ms-auto">
                        <Button icon="pi pi-plus" label="New Post" @click="onNewPost()" size="small" outlined severity="secondary" iconPos="left" title="Add New Post" />
                    </div>
                </div>
            </div>
            <div class="card-body">
                <form class="row" novalidate>
                    <div v-if="postEditStore.post.postId > 0" class="mb-2">
                        <RevisionComponent :post="postEditStore.post" :hidden="postEditStore.post.hideRevision" @restore="restoreRevision" />
                        <div class="muted-info">
                            <span v-if="postEditStore.post.dateCreated">Created: <date-format :datetime="postEditStore.post.dateCreated" /></span>
                            <span v-if="postEditStore.post.datePublished"> &#8226; Published: <date-format :datetime="postEditStore.post.datePublished" /> </span>
                            <span v-if="postEditStore.post.dateModified">
                                &#8226; Last Updated: <date-format :datetime="postEditStore.post.dateModified" /> by
                                <span v-if="postEditStore.post.userId">{{ postEditStore.post.userId }}</span>
                                <span v-else>Scheduled Import</span>
                            </span>
                            <!--<span style="color: red;" class="ps-2">{{ postEditStore.post.userIdLock }}</span>-->
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="Headline" class="form-label required">Title</label>
                            <div class="input-group mb-3">
                                <input v-model="postEditStore.post.title" type="text" class="form-control" :class="titleRequired" aria-label="Title" id="Headline" required />
                                <span class="input-group-text thm-char-count" :class="getThresholdCssClass(postEditStore.post.title)">{{ postEditStore.post.title ? postEditStore.post.title.length : 0 }}/{{ headlineConfig.maxLength }}</span>
                                <div class="input-group-text">
                                    <input @click="postEditStore.setDefaultHeadline()" :checked="postEditStore.activeHeadline == false" name="SelectedHeadline" class="form-check-input mt-0" type="radio" aria-label="Radio button Active Headline" />
                                </div>
                            </div>
                            <div v-for="(h, index) in postEditStore.post.extendedProperties?.headlines" :key="h.id" class="input-group mb-3">
                                <input v-model="h.headline" type="text" class="form-control" aria-label="Radio button Active Headline" :placeholder="'Headline ' + (index + 1)" :id="'Headline' + index" />
                                <span class="input-group-text thm-char-count" :class="getThresholdCssClass(h.headline)" v-if="h.headline">{{ h.headline.length }}/{{ headlineConfig.maxLength }}</span>
                                <div class="input-group-text">
                                    <input @click="postEditStore.setHeadline(index)" :checked="h.active" name="SelectedHeadline" class="form-check-input mt-0" type="radio" aria-label="Radio button Active Headline" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="userHasAccessToRole('Admin')" class="mb-2">
                        <label for="ShareTitle" class="form-label">Share Title</label>
                        <input v-model="postEditStore.post.extendedProperties.shareTitle" type="text" class="form-control" placeholder="Social media title for post" id="ShareTitle" />
                    </div>

                    <div v-if="userHasAccessToRole('Developer')" class="mb-2">
                        <label for="RedirectUrl" class="form-label">Redirect URL <i class="far fa-crown color-restricted" x-title="Developer role required to modify" v-tooltip="'Developer only'"></i></label>
                        <input v-model="postEditStore.post.extendedProperties.redirectUrl" type="text" class="form-control" placeholder="https://... " id="RedirectUrl" />
                    </div>

                    <div v-if="postEditStore.post.postId > 0" class="mb-2 mt-1">
                        <label for="Permalink" class="form-label mb-1">
                            <a target="_blank" :href="getPermalink(postEditStore.post.url)">Permalink <i class="far fa-arrow-up-right-from-square"></i></a>
                        </label>
                        <div class="p-inputgroup">
                            <Button @click="togglePermalink()" :label="modifyPermalink ? 'Edit' : 'Save'" :disabled="!userHasAccessToRole('Admin')" />
                            <InputText :disabled="modifyPermalink" type="text" v-model="postPermalink" placeholder="" id="Permalink" :class="{ 'modify-disabled': modifyPermalink }" style="border-radius: 0;" />
                            <span class="p-inputgroup-addon">-n{{ postEditStore.post.postId }}</span>
                            <Button ref="copyPermalinkText" @click="copy(getPermalink(postEditStore.post.url))">
                                <span v-if="!copied">Copy</span>
                                <span v-else>Copied!</span></Button>
                        </div>
                    </div>

                    <div class="mb-2">
                        <label for="validationCustom02" class="form-label required">Author</label>
                        <div class="inputgroup">
                            <AuthorsAutoComplete v-model="autoCompleteAuthor" :authorId="postEditStore.post.authorId" x-authorTypeId="0" :setCurrent="true" :class="authorIdRequired" placeholder="Type here...." class="me-2" :disabled="!!postEditStore.post.authorId && !userHasAccessToRole('Editor')" required />
                        </div>
                    </div>

                    <!-- <div class="mb-2 fw-bold">
                        Category: {{ postEditStore.post.categories }}
                    </div> -->

                    <!-- [S: {{ postEditStore.post.categories }}] - [C: {{ postEditStore.post.categoryId }}] -->
                    <div class="mb-2" v-if="!route.params.categoryId">
                        <label for="validationCustom06" class="form-label required">Category</label>
                        <div class="inputgroup">
                            <!--<MultiSelect v-model="postEditStore.post.categories" :options="postStore.categoryList.filter(item => item.isHidden != true)" placeholder="Select Categories" optionLabel="name" optionValue="categoryId" :class="categoryRequired" class="w-full md:w-20rem" :showClear="true" />-->
                            <MultiSelect v-model="postEditStore.post.categories" :options="postStore.categoryList" placeholder="Select Categories" optionLabel="name" optionValue="categoryId" :class="categoryRequired" class="w-full md:w-20rem" :showClear="true" />
                        </div>
                    </div>

                    <Panel class="my-2" header="Publish" :toggleable="true">
                        <template #icons>
                            <!-- <button class="p-panel-header-icon p-link p-mr-2" @click="toggle">
                            <span class="pi pi-cog"></span>
                        </button>
                        <Menu id="config_menu" ref="menu" :model="items" :popup="true" /> -->
                        </template>
                        <div class="form-group"></div>
                        <div class="row mb-2">
                            <label for="validationCustom05" class="col-lg-3 col-form-label">Publish Date</label>

                            <div class="col-lg-9">
                                <Calendar v-model="postDatePublished" :showButtonBar="true" :showIcon="true" :showTime="true" dateFormat="mm/dd/yy" hourFormat="12" placeholder="Date/Time" @today-click="todayClick" />
                                <!--<CalendarThm v-model="postDatePublished" :showButtonBar="true" :showIcon="true" :showTime="true" dateFormat="mm/dd/yy" hourFormat="12" placeholder="Date" @today-click="todayClick" />-->
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="validationCustom05" class="col-sm-3 col-form-label">Status</label>
                            <div class="col-lg-9">
                                <SelectButton v-model="postEditStore.post.status" :options="postStatus" optionLabel="label" optionValue="value" optionDisabled="disabled" :multiple="false" :unselectable="false" :disabled="!isSaveAllowed" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-9 offset-sm-3">
                                <!--<Button v-if="!postEditStore.isReadonly" @click="savePost()" label="Save" x-tooltip="'Ctrl+S'" class="me-2" :disabled="isLoading || (!userHasAccessToRole('Editor') && postEditStore.post.authorId != authorStore.currentAuthor.authorId)" />-->
                                <Button v-if="!postEditStore.isReadonly" @click="savePost()" label="Save" x-tooltip="'Ctrl+S'" class="me-2" :disabled="!isSaveAllowed" />
                                <Button v-else @click="showDialogTakeOverPost(true)" label="Take Over Post" class="me-2" severity="warning" :disabled="!isSaveAllowed" />
                                <div class="d-inline-flex" v-if="!postEditStore.newPost">
                                    <Button type="button" label="Preview" @click="togglePreviewOverlay" aria-haspopup="true" severity="secondary" outlined class="me-2" aria-controls="overlay_menu" />
                                    <!--<Menu id="overlay_menu" ref="previewMenu" :model="previewLinks" :popup="true" />-->
                                    <Menu id="overlay_menu" ref="previewMenu" :model="previewLinks" :popup="true">
                                        <template #item="{ item, label, props }">
                                            <a class="flex" v-bind="props.action">
                                                <span v-bind="props.icon" />
                                                <span v-bind="props.label">{{ label }}</span>
                                                <Badge v-if="item.badge" class="ml-auto" :value="item.badge" />
                                            </a>
                                        </template>
                                    </Menu>

                                    <Button v-if="!postEditStore.isReadonly" label="Delete" @click="deletePost(postEditStore.post.postId)" severity="danger" outlined class="me-2" :disabled="!isSaveAllowed" />
                                    <LogViewerThm v-if="userHasAccessToRole('Admin') && postEditStore.post.postId > 0" label="Logs" :filter="{ logTypeId: 1, referenceId: postEditStore.postId }" tag="button" />
                                </div>
                                <div v-if="!postEditStore.newPost">
                                    <span class="muted-info">
                                        Last Updated: <date-format :datetime="postEditStore.post.dateModified" /> by
                                        <span v-if="postEditStore.post.userId">{{ postEditStore.post.userId }}</span>
                                        <span v-else>Scheduled Import</span>
                                    </span>
                                </div>
                            </div>

                            <InlineMessage class="p-inline-message p-component p-inline-message-warn mt-2" severity="warn" v-if="!userHasAccessToRole('Editor') && !authorStore.currentAuthor?.authorId">
                                <strong>Warning:</strong> Current user not found in Authors table. Saving may be disabled.
                            </InlineMessage>
                        </div>
                    </Panel>

                    <Panel
                        class="my-2"
                        :class="{ 'thm-panel-poststats__error': postStats.characters > 0 && postStats.adDensity < 2, 'thm-panel-poststats__warning': postStats.characters > 0 && postStats.nextAd <= 500 }"
                        header="Post Stats"
                        :toggleable="true"
                        :collapsed="false">
                            <template #icons> </template>
                            <PostStats v-model="postStats" />
                            <Button label="Refresh" class="p-button-secondary p-button-raised p-button-text pe-btn-white-thm p-button-smaller" size="small" @click="refreshPostStats()" />
                    </Panel>

                    <div class="mb-2">
                        <label for="Body" class="form-label">Body</label>
                        <ImageLibrary v-model="froalaImage" mode="froala" :id="'fro-im'" :config="{ showPreview: false }" />
                        <!--<FroalaThm :id="'fro-im'" :tag="'textarea'" rows="6" v-model="postEditStore.post.body" :options="{ heightMin: 150, charCounterCount: true }" ref="froalaEditorRef"></FroalaThm>-->
                        <FroalaThm v-model="postEditStore.post.body" :config="{ heightMin: 150, charCounterCount: true }" ref="froalaEditorRef" />
                    </div>

                    <Panel class="my-2" header="Subscription" :toggleable="true">
                        <template #icons> </template>

                        <div class="row g-0 mb-2">
                            <label for="validationCustom05" class="col-sm-3 col-form-label">Level</label>

                            <div class="col-sm-9">
                                <SelectButton v-model="postEditStore.post.subscription" :options="subscription" optionLabel="label" optionValue="value" :multiple="false" :unselectable="false" />
                            </div>
                        </div>
                        <div class="row g-0 mb-2 d-none">
                            <label for="validationCustom05" class="col-sm-3 col-form-label">Content Type</label>

                            <div class="col-sm-9">
                                <SelectButton v-model="postEditStore.post.extendedProperties.subscriptionContent" :options="subscriptionContent" optionLabel="label" optionValue="value" :multiple="false" />
                                <!-- <span class="post-info">Only letters, numbers and hyphens allowed. Example: </span> -->
                            </div>
                        </div>
                        <div class="row g-0 mb-2">
                            <label for="validationCustom05" class="col-lg-3 col-form-label">Expiration Date</label>

                            <div class="col-lg-9">
                                <Calendar v-model="postDateExpires" :showButtonBar="true" :showIcon="true" :showTime="true" dateFormat="mm/dd/yy" hourFormat="12" placeholder="Date" />
                                <!--<CalendarThm v-model="postDateExpires" :showButtonBar="true" :showIcon="true" :showTime="true" dateFormat="mm/dd/yy" hourFormat="12" placeholder="Date" />-->
                            </div>
                        </div>
                    </Panel>

                    <Panel class="my-2" header="Miscellaneous" :toggleable="true" v-if="showMiscPanel">
                        <div v-show="appSettings.features.postEditor?.featuredPostCheckbox" class="row g-0 x-mb-3">
                            <label for="FeaturedPostCheckbox" class="col-sm-3 form-label">Featured Post</label>
                            <div class="col-sm-9">
                                <InputSwitch v-model="postEditStore.post.extendedProperties.isFeatured" id="FeaturedPostCheckbox" />
                            </div>
                        </div>
                    </Panel>

                    <Panel class="my-2" header="Main Image" :toggleable="true" :class="imageRequired">
                        <template #icons> </template>
                        <!--
                        <ImageLibrary v-model="postEditStore.post.imageObject[0]" />
                        <span v-if="postEditStore.post.imageObject && postEditStore.post.imageObject[0]?.credit" class="muted-info text-muted">{{ postEditStore.post.imageObject[0]?.credit }}</span>
                        -->
                        <ImageLibrary v-model="mainImage" />
                        <!--<span v-if="mainImage.credit" class="muted-info text-muted">{{ mainImage.credit }}</span>-->
                        <a :href="`/imagelib?s=_id:${mainImage.id}`" target="_blank" class="row">
                            <div class="col-sm-12 col-md-7 muted-info text-muted">
                                <span v-if="mainImage.credit">{{ mainImage.credit }}</span>
                            </div>
                            <div class="col-sm-12 col-md-5 text-end muted-info">
                                <!--<a :href="`/imagelib?s=_id:${mainImage.id}`" target="_blank" :style="{ color: postStore.getLicenseColor(mainImage.license?.id) }">{{ mainImage.license?.name }}</a>-->
                                <span :style="{ color: postStore.getLicenseColor(mainImage.license?.id) }">{{ mainImage.license?.name }}</span>
                            </div>
                        </a>
                    </Panel>

                    <Panel class="my-2" header="Tags &amp; Badge" :toggleable="true">
                        <template #icons>
                            <!-- <button class="p-panel-header-icon p-link p-mr-2" @click="toggle">
                            <span class="pi pi-cog"></span>
                        </button>
                        <Menu id="config_menu" ref="menu" :model="items" :popup="true" /> -->
                        </template>
                        <div class="mb-2">
                            <TagsAutoComplete v-model="postEditStore.post.tags" />
                        </div>
                        <div v-if="appSettings.features.badgeSupport">
                            <div class="row">
                                <div class="col-6">
                                    <label for="BadgeLabel" class="form-label d-block">Badge</label>
                                    <Dropdown v-model="postEditStore.post.extendedProperties.badge.label" :options="badges" optionLabel="label" optionValue="label" :showClear="true" placeholder="Select a Badge" id="BadgeLabel" v-if="postEditStore.post.extendedProperties.badge" />
                                </div>
                                <div class="col-6" v-if="postEditStore.post.extendedProperties.badge && postEditStore.post.extendedProperties.badge.label">
                                    <label for="BadgeColor" class="form-label d-block">Background Color</label>
                                    <!--<Dropdown v-model="postEditStore.post.extendedProperties.badge.backgroundColor" :options="badgeColors" optionLabel="label" optionValue="hexCode" placeholder="Select a Color" id="BadgeColor" v-if="postEditStore.post.extendedProperties.badge" />-->

                                    <Dropdown v-model="badgeBackgroundColor" :options="badgeColors" optionLabel="label" optionValue="hexCode" placeholder="Select a Color" id="BadgeColor" v-if="postEditStore.post.extendedProperties.badge" class="badge-dropdown-color">
                                        <template #value="slotProps">
                                            <div class="badge-dropdown-color__item" v-if="slotProps.value">
                                                <span class="badge-dropdown-color__value" :style="{ backgroundColor: slotProps.value }"></span>
                                            </div>
                                            <span v-else>
                                                {{slotProps.placeholder}}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="badge-dropdown-color__item">
                                                <span class="badge-dropdown-color__value" :style="{ backgroundColor: slotProps.option.hexCode }"></span> <span class="badge-dropdown-color__label">{{ slotProps.option.label }}</span>
                                            </div>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>

                            <div class="mt-3" v-if="postEditStore.post.extendedProperties.badge && postEditStore.post.extendedProperties.badge.label">
                                <label for="ExpireAfterHours" class="mb-2 d-block">Expire After Hours</label>
                                <InputText type="text" v-model="badgeExpireAfterHours" id="ExpireAfterHours" class="input-small" />
                            </div>
                        </div>
                    </Panel>

                    <!--<Panel class="my-2" header="Additional Media" :toggleable="true" :collapsed="isEmptyOrNull(postEditStore.post.extendedProperties.embedCode) && isEmptyOrNull(postEditStore.post.extendedProperties.embedCodeVip)">-->
                    <Panel class="my-2" header="Additional Media" :toggleable="true" :collapsed="true">
                        <template #icons></template>
                        <h6>Featured Video</h6>
                        <p><VideoChooserThm v-model="postEditStore.post.extendedProperties.bridVideo" /></p>
                        <div class="mb-2">
                            <label for="EmbedCode" class="form-label">Embed Code</label>
                            <Textarea v-model="postEditStore.post.extendedProperties.embedCode" :autoResize="true" rows="3" cols="30" class="form-control" id="EmbedCode" />
                        </div>
                        <div class="mb-2">
                            <label for="EmbedCodeVip" class="form-label">Embed Code (VIP)</label>
                            <Textarea v-model="postEditStore.post.extendedProperties.embedCodeVip" :autoResize="true" rows="3" cols="30" class="form-control" id="EmbedCodeVip" />
                        </div>
                    </Panel>

                    <Panel class="my-2" header="Advanced" :toggleable="true"  :collapsed="true" v-show="userHasAccessToRole('Developer')">
                        <div class="row g-0 mb-3">
                            <label for="validationCustom01" class="col-sm-3 form-label">Disable Ads <i class="far fa-crown color-restricted" x-title="Developer role required to modify" v-tooltip="'Developer only'"></i></label>
                            <div class="col-sm-9">
                                <InputSwitch v-model="postEditStore.post.extendedProperties.adsDisabled" />
                            </div>
                        </div>
                        <div class="row g-0 mb-2">
                            <label for="validationCustom05" class="col-sm-3 col-form-label">Platform Visibility <i class="far fa-crown color-restricted" x-title="Developer role required to modify" v-tooltip="'Developer only'"></i></label>

                            <div class="col-sm-9">
                                <SelectButton v-model="postEditStore.post.platformVisibility" :options="platformVisibility" optionLabel="label" optionValue="value" :multiple="false" />
                            </div>
                        </div>
                    </Panel>

                    <Panel class="my-2" header="Override SEO" :toggleable="true" :collapsed="true" v-if="userHasAccessToRole('Admin')" v-show="false">
                        <template #icons></template>
                        <p class="m-0">Not yet implemented</p>
                    </Panel>
                    <div class="row my-2">
                        <div class="mb-2">
                            <!--<Button v-if="!postEditStore.isReadonly" @click="savePost()" label="Save" x-tooltip="'Ctrl+S'" class="me-2" :disabled="isLoading || (!userHasAccessToRole('Editor') && postEditStore.post.authorId != authorStore.currentAuthor.authorId)" />-->
                            <Button v-if="!postEditStore.isReadonly" @click="savePost()" label="Save" x-tooltip="'Ctrl+S'" class="me-2" :disabled="!isSaveAllowed" />
                            <Button v-else @click="showDialogTakeOverPost(true)" label="Take Over Post" severity="warning" class="me-2" :disabled="!isSaveAllowed" />
                            <div class="d-inline-flex" v-if="!postEditStore.newPost">
                                <Button type="button" label="Preview" @click="togglePreviewOverlay" aria-haspopup="true" severity="secondary" outlined class="me-2" aria-controls="overlay_menu" />
                                <!--<Menu id="overlay_menu" ref="previewMenu" :model="previewLinks" :popup="true" />-->
                                <Menu id="overlay_menu" ref="previewMenu" :model="previewLinks" :popup="true">
                                    <template #item="{ item, label, props }">
                                        <a class="flex" v-bind="props.action">
                                            <span v-bind="props.icon" />
                                            <span v-bind="props.label">{{ label }}</span>
                                            <Badge v-if="item.badge" class="ml-auto" :value="item.badge" />
                                        </a>
                                    </template>
                                </Menu>

                                <Button v-if="!postEditStore.isReadonly" label="Delete" @click="deletePost(postEditStore.post.postId)" class="p-button-outlined p-button-danger me-2" :disabled="!isSaveAllowed" />
                                <LogViewerThm v-if="userHasAccessToRole('Admin') && postEditStore.post.postId > 0" label="Logs" :filter="{ logTypeId: 1, referenceId: postEditStore.postId }" class="p-button-secondary p-button-outlined" tag="button" />
                            </div>
                            <div v-if="!postEditStore.newPost">
                                <span class="muted-info"
                                    >Last Updated: <date-format :datetime="postEditStore.post.dateModified" /> by

                                    <span v-if="postEditStore.post.userId">{{ postEditStore.post.userId }}</span>
                                    <span v-else>Scheduled Import</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
//start components
import AuthorsAutoComplete from '@/components/AuthorsAutoComplete.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import InlineMessage from 'primevue/inlinemessage';
import DateFormat from '@/components/DateFormat.vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
//import FroalaThm from '@/components/Froala/FroalaOld.vue';
import ImageLibrary from '@/components/ImageLibrary/ImageLibrary.vue';
import VideoChooserThm from '@/components/VideoChooserThm.vue';
//import CalendarThm from '@/components/CalendarThm.vue';
import PostStats from './_PostStats.vue';
import TagsAutoComplete from '@/components/TagsAutoComplete.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import RevisionComponent from './_Revision.vue';
//End components
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { addMinutes, subMinutes, subDays } from 'date-fns';
import { useAuthorStore } from '@/stores/AuthorStore';

import { useRoute, useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
//Services:
import AdminService from '@/service/AdminService';
import PostService from '@/service/PostService';
import ElasticsearchService from '@/service/ElasticsearchService';
import AppleNewsService from '@/service/AppleNewsService';
import BadgeService from '@/service/BadgeService';

//import ThmUtils from '@/utils/ThmUtils';
import { seoUrl } from '@/utils/ThmUtils';
import { addHyperlinkTitles, isEmptyOrNull, removeEmptyParagraphs, stripHyperlinkTitles } from '@/utils/ThmStringUtils';
import { formatDateTimeISO, formatDateTime, formatDateTimeET } from '@/utils/ThmDateTimeUtils';
import { userHasAccessToRole, userHasAccessToRoles } from '@/utils/ThmAuthUtils';
import { getCaptionFromDescription } from '@/utils/ThmImageUtils';
import { ref, computed, inject, watch, onBeforeMount, onMounted, onUnmounted, onBeforeUnmount, defineComponent } from 'vue';
import { isObject } from '@vueuse/core';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useClipboard } from '@vueuse/core';
import { usePostEditStore } from '@/stores/PostEditStore';
import { usePostStore } from '@/stores/PostStore';
//import { useSignalR } from '@quangdao/vue-signalr';
//import { useSignalR } from '@dreamonkey/vue-signalr';
import useSignalR from '@/use/SignalR';
import useAuth0 from '@/use/Auth0';
import pretty from '@/../node_modules/pretty/index.js';

export default defineComponent({
    name: 'PostEdit',
    components: {
        AuthorsAutoComplete,
        //CalendarThm,
        ConfirmDialog,
        DateFormat,
        FroalaThm,
        ImageLibrary,
        InlineMessage,
        LogViewerThm,
        PostStats,
        RevisionComponent,
        TagsAutoComplete,
        VideoChooserThm,
    },
    props: {
        categoryId: {
            type: [Number, String],
            default: null,
        },
    },
    setup(props) {
        //signalr setup
        const signalr = useSignalR();
        // Listen to the "ReceivePostLock" event
        signalr.on('ReceivePostLock', (message) => setLockMessageOnPostList(message));
        // Listen for post take over
        signalr.on('ReceiveTakeOverNotify', (message) => takeOverNotify(message));
        // Invoke "SendMessage" and wait for a response
        signalr.invoke('Send', 'Connected to SignalR'); //{ message: 'Hello world!' }//.then((m) => console.log("my response from ws: ", m));
        signalr.on('ReceiveSend', (message) => console.log('SignalR:', message));
        signalr.on('ReceiveNewPost', () => setNewPosts());
        onBeforeUnmount(() => {
            //pinia reset is keeping the subscribe
            console.log('😊 onBeforeUnmount PostEdit');

            ['ReceivePostLock', 'ReceiveTakeOverNotify', 'ReceiveSend', 'ReceiveNewPost'].forEach((methodName) => {
                signalr.off(methodName);
            });
        });
        onUnmounted(() => {
            console.log('🤯 unmounted PostEdit - reset PostEditStore');
            //localStorage.setItem('autoSave', JSON.stringify(postEditStore.post));
            postEditStore.$reset();
        });
        onBeforeRouteUpdate((to, from, next) => {
            //route updates with in the page
            /*
            if (postEditStore.isDirty && !postEditStore.newPost) {
                confirm.require({
                    message: 'Changes have been made to this post do you want to save before leaving?',
                    header: 'Do you want to SAVE? 😕 😥',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        console.log('onBeforeRouteUpdate - Do you want to SAVE? 😕 SAVE POST');
                        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Saved post!', life: 3000 });
                        await savePost(false);
                        next();
                    },
                    reject: () => {
                        //toast.add({ severity: 'error', summary: 'Save cancelled', detail: 'Post Save cancelled', life: 3000 });
                        let postMessage = {
                            postId: postEditStore.post.postId,
                            userIdLock: null,
                            connectionId: null,
                        };
                        postService.toggleLockPost(postMessage);
                        signalr.invoke('BroadcastPostLock', postMessage);
                        console.log('onBeforeRouteUpdate -Do you want to SAVE? 😕 Reject confirm');
                        next();
                    },
                });
            } else {
                next();
            }
            */

            if(postEditStore.isDirty && !postEditStore.newPost) {
                confirm.require({
                    message: 'You have unsaved changes. Do you want to continue?',
                    header: 'Unsaved Changes',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        //toast.add({ severity: 'error', summary: 'Save cancelled', detail: 'Post Save cancelled', life: 3000 });
                        let postMessage = {
                            postId: postEditStore.post.postId,
                            userIdLock: null,
                            connectionId: null,
                        };
                        postService.postLockStatus(postMessage.postId).then((postLockStatus) => {
                            if(postLockStatus.isLocked && postLockStatus.connectionId === user.value.email) {
                                postService.toggleLockPost(postMessage);
                                signalr.invoke('BroadcastPostLock', postMessage);
                                console.log('onBeforeRouteUpdate - Do you want to SAVE? 😕 Reject confirm');
                            }
                        });
                        next();
                    },
                    reject: () => {
                        // Do nothing
                    },
                });
            } else {
                next();
            }
        });
        onBeforeRouteLeave((to, from, next) => {
            //leaving page
            /*
            if (postEditStore.isDirty) {
                confirm.require({
                    message: 'Changes have been made to this post do you want to save before leaving?',
                    header: 'Do you want to SAVE? 🤔',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        console.log('onBeforeRouteLeave - Do you want to SAVE? 😕 SAVE POST');
                        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Saved post!', life: 3000 });
                        await savePost(false);
                        next();
                    },
                    reject: () => {
                        //toast.add({ severity: 'error', summary: 'Save cancelled', detail: 'Post Save cancelled', life: 3000 });
                        console.log('onBeforeRouteLeave -Do you want to SAVE? 😕 Reject confirm');
                        let postMessage = {
                            postId: postEditStore.post.postId,
                            userIdLock: null,
                            connectionId: null,
                        };

                        postService.toggleLockPost(postMessage);
                        //unlock
                        signalr.invoke('BroadcastPostLock', postMessage);
                        next();
                    },
                });
            } else {
                next();
            }
            */

            if(postEditStore.isDirty) {
                confirm.require({
                    message: 'You have unsaved changes. Do you want to continue?',
                    header: 'Unsaved Changes',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        //toast.add({ severity: 'error', summary: 'Save cancelled', detail: 'Post Save cancelled', life: 3000 });
                        let postMessage = {
                            postId: postEditStore.post.postId,
                            userIdLock: null,
                            connectionId: null,
                        };
                        postService.toggleLockPost(postMessage);
                        signalr.invoke('BroadcastPostLock', postMessage);
                        next();
                    },
                    reject: () => {
                        // Do nothing
                    },
                });
            } else {
                next();
            }
        });
        const { text, copy, copied, isSupported } = useClipboard();
        const appSettings = inject('appSettings');
        const authorStore = useAuthorStore();
        const adminService = new AdminService();
        const badgeService = new BadgeService();
        const elasticsearchService = new ElasticsearchService();
        const appleNewsService = new AppleNewsService();
        const route = useRoute();
        const router = useRouter();
        const postService = new PostService();
        const { user } = useAuth0();
        const confirm = useConfirm();
        const toast = useToast();
        const postEditStore = usePostEditStore();
        const postStore = usePostStore();
        const postLogsKey = ref(0);
        const showTakeOver = ref(false);
        const froalaEditorRef = ref(null);
        const postStats = ref({});
        const headlineConfig = {
            warnLength: 95,
            maxLength: 105
        };

        const autoCompleteAuthor = ref(null);
        const modifyPermalink = ref(true);
        const previewMenu = ref();

        const togglePreviewOverlay = (event) => {
            previewMenu.value.toggle(event);
        };
        const original = ref({});
        const userLock = ref(null);
        const titleRequired = ref('');
        const authorIdRequired = ref('');
        const categoryRequired = ref('');
        const imageRequired = ref('');
        const referenceId = ref(null);
        const isLoading = ref(false);
        //const userId = ref(null);
        const froalaImId = 'fro-im';
        const froalaImage = ref(null);
        const badges = ref([]);
        const hasAutosavedSinceLastEdit = ref(false);
        const previewLinks = ref([
            {
                label: 'Desktop',
                items: [
                    {
                        label: 'With Paywall',
                        icon: 'pi pi-refresh',
                        //url: 'https://townhall.com/',
                        command: () => {
                            window.open(getPreviewLink(true), '_preview');
                        },
                    },
                    {
                        label: 'Normal',
                        icon: 'pi pi-times',
                        command: () => {
                            window.open(getPreviewLink(false), '_preview');
                        },
                    },
                ],
            },
            {
                label: 'Mobile',
                items: [
                    {
                        label: 'With Paywall',
                        icon: 'pi pi-external-link',
                        command: () => {
                            window.open(getPreviewLink(true), '_preview', 'width=375,height=700');
                        },
                    },
                    {
                        label: 'Normal',
                        icon: 'pi pi-upload',
                        command: () => {
                            window.open(getPreviewLink(false), '_preview', 'width=375,height=700');
                        },
                    },
                ],
            },
        ]);

        //get from db
        const subscription = [
            { label: 'None', value: 0 },
            { label: 'VIP', value: 4 },
            { label: 'VIP Gold', value: 8 },
        ];
        const subscriptionContent = [
            { label: 'None', value: 0 },
            { label: 'Video', value: 4 },
            { label: 'Podcast', value: 8 },
        ];
        const postStatusOptions = [
            { label: 'Draft', value: 2 },
            { label: 'Unpublished', value: 0, roles: ['Developer'] },
            { label: 'Pending', value: 4 },
            { label: 'Published', value: 1, disabled: !userHasAccessToRole('Author') },
        ];
        const platformVisibility = [
            { label: 'All', value: 0 },
            { label: 'Web Only', value: 1 },
            { label: 'Mobile Only', value: 2 },
        ];

        const postDatePublished = computed({
            // getter
            get() {
                //.toLocaleString('en-US')
                return postEditStore.post.datePublished == null ? null : new Date(postEditStore.post.datePublished);
            },
            // setter
            set(newValue) {
                postEditStore.post.datePublished = newValue ? newValue.toLocaleString('en-US') : newValue;
            },
        });
        const postDateExpires = computed({
            // getter .toLocaleString('en-US')
            get() {
                return postEditStore.post.dateExpires == null ? null : new Date(postEditStore.post.dateExpires);
            },
            // setter
            set(newValue) {
                postEditStore.post.dateExpires = newValue.toLocaleString('en-US');
            },
        });
        const postPermalink = computed({
            // getter
            get() {
                let urlTitle = postEditStore.post.urlTitle;
                if(!urlTitle?.match(/-n\d+$/)) return urlTitle;

                let n = urlTitle.lastIndexOf('-n');
                if (n > 0) {
                    let url = urlTitle?.substring(0, n);
                    return url;
                }

                //let nId = url.substring(url.lastIndexOf("-n"))
                return urlTitle;
            },
            // setter
            set(newValue) {
                postEditStore.post.urlTitle = seoUrl(newValue);
            },
        });
        const postStatus = computed(() => {
            let options = [];
            for(let idx = 0; idx < postStatusOptions.length; idx++) {
                if(!postStatusOptions[idx].roles || userHasAccessToRoles(postStatusOptions[idx].roles)) {
                    options.push(postStatusOptions[idx]);
                }
            }
            return options;
        });
        const mainImage = computed({
            get() {
                if(Array.isArray(postEditStore.post.imageObject) && postEditStore.post.imageObject.length) {
                    return postEditStore.post.imageObject[0];
                } else {
                    return [ { 'url': null, 'id': "", 'credit': null, 'type': 1, 'typeName': 'Main Image' } ];
                }
            },
            set(newValue) {
                postEditStore.post.imageObject = [newValue];
            },
        });
        const badgeExpireAfterHours = computed({
            get() {
                switch(true) {
                    case !postEditStore.post.extendedProperties.badge || !postEditStore.post.extendedProperties.badge.label:
                        return 0;
                    case !isNaN(postEditStore.post.extendedProperties.badge.expireAfterHours):
                        return postEditStore.post.extendedProperties.badge.expireAfterHours;
                    default:
                        var badge = badges.value.find((obj) => obj.label == postEditStore.post.extendedProperties.badge.label);
                        return badge ? badge.expireAfterHours : 0;
                }
            },
            set(newValue) {
                postEditStore.post.extendedProperties.badge.expireAfterHours = newValue;
            }
        });
        const badgeBackgroundColor = computed({
            get() {
                switch(true) {
                    case !postEditStore.post.extendedProperties.badge || !postEditStore.post.extendedProperties.badge.label:
                        return null;
                    case !isEmptyOrNull(postEditStore.post.extendedProperties.badge.backgroundColor):
                        return postEditStore.post.extendedProperties.badge.backgroundColor;
                    default:
                        if(!postEditStore.post.extendedProperties.badge || !postEditStore.post.extendedProperties.badge.label) return [];
                        var badge = badges.value.find((obj) => obj.label == postEditStore.post.extendedProperties.badge.label);
                        return badge ? badge.defaultColor : null;
                }
            },
            set(newValue) {
                postEditStore.post.extendedProperties.badge.backgroundColor = newValue;
            }
        });
        const badgeColors = computed(() => {
            if(!postEditStore.post.extendedProperties.badge || !postEditStore.post.extendedProperties.badge.label) return [];
            let badge = badges.value.find((obj) => obj.label == postEditStore.post.extendedProperties.badge.label);
            return badge ? badge.colors : [];
        });
        const showMiscPanel = computed(() => {
            return appSettings.features.postEditor?.featuredPostCheckbox;
        });
        /*
        const isSaveDisabled = computed(() => {
            switch(true) {
                case isLoading:
                case postEditStore.post.postTypeId > 1: // Only allow editing of PostType="post"
                case !userHasAccessToRole('Author') && postEditStore.post?.authorId != authorStore.currentAuthor?.authorId: // Prevent contributors from editing other users' posts
                    return true;
                default:
                    return false;
            }
        });
        */
        const isSaveAllowed = computed(() => {
            switch(true) {
                case isLoading:
                case postEditStore.post.postTypeId > 1: // Only allow editing of PostType="post"
                case !userHasAccessToRole('Author') && postEditStore.post.status == 1: // Disallow contributor changes once published
                case !userHasAccessToRole('Editor') && !authorStore.currentAuthor?.authorId: // Disallow saving if author doesn't exist in Authors table
                case !userHasAccessToRole('Editor') && authorStore.currentAuthor?.authorId > 0 && postEditStore.post?.authorId != authorStore.currentAuthor?.authorId: // Prevent contributors & authors from publishing other users' posts
                    return false;
                default:
                    return true;
            }
        });

        const editButtons = [
            {
                label: 'Permalink',
                class: 'p-button-sm',
            },
            {
                separator: true,
            },
            {
                label: 'Preview Desktop',
                class: 'p-button-sm',
            },
            {
                label: 'Preview Desktop with Paywall',
                class: 'p-button-sm',
            },
            {
                label: 'Preview Mobile',
                class: 'p-button-sm',
            },
            {
                label: 'Preview Mobile with Paywall',
                class: 'p-button-sm',
            },
        ];
        /*
        //const { ctrl_s, meta_s } = useMagicKeys({
        const { ctrl_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                //if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
                if(e.ctrlKey && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => {
            console.log('Ctrl+S SavePost');
            savePost();
        });
        */
        //whenever(meta_s, () => savePost());

        watch(
            () => route.params.id,
            async (newId) => {
                if (newId != null && newId.length > 0) {
                    await getPost(newId);
                    postEditStore.$subscribe((mutation, state) => {
                        // import { MutationType } from 'pinia'
                        // mutation.type; // 'direct' | 'patch object' | 'patch function'
                        // // same as cartStore.$id
                        // mutation.storeId; // 'cart'
                        // // only available with mutation.type === 'patch object'
                        // mutation.payload; // patch object passed to cartStore.$patch()
                        // persist the whole state to the local storage whenever it changes
                        if (mutation.type == 'direct') {
                            if (state.isDirty == false && state.isReadonly == false && state.editMode == true) {
                                let connectionId = user.value.email;
                                console.log('Set user ID lock and broadcast: ', connectionId);
                                let postMessage = {
                                    postId: state.post.postId,
                                    userIdLock: user.value.name,
                                    connectionId: connectionId,
                                };
                                // const postLockStatus = postService.postLockStatus(postMessage.postId);
                                // //on page refresh or returning from link don't show dialog if it is your locked post
                                // if (postLockStatus.isLocked && postLockStatus.connectionId !== user.value.email) {
                                //     showDialogTakeOverPost(true);
                                // } else {
                                //     postService.toggleLockPost(postMessage);
                                //     signalr.invoke('BroadcastPostLock', postMessage);
                                // }

                                postService.postLockStatus(postMessage.postId).then((postLockStatus) => {
                                    if (postLockStatus.isLocked && postLockStatus.connectionId !== user.value.email) {
                                        showDialogTakeOverPost(true);
                                    } else {
                                        postService.toggleLockPost(postMessage).then(() => {
                                            console.log('sending mutation BroadcastPostLock', postMessage);
                                            signalr.invoke('BroadcastPostLock', postMessage);
                                        });
                                    }
                                });
                                //on page refresh or returning from link don't show dialog if it is your locked post
                            }
                            if (state.isReadonly == false) {
                                //using patch because it will cause direct mutation
                                postEditStore.$patch((state) => {
                                    state.isDirty = true;
                                    state.post.userIdLock = user.value.name;
                                });
                                //state.isDirty = true;
                                // state.post.userIdLock = user.value.name;
                                localStorage.setItem('postEdit', JSON.stringify(state.post));
                                console.log(`📙 Mutation: ${mutation.type}:`, state);
                                //console.log("mutation post: ", mutation.post)
                                //console.log('mutation type: ', mutation.type);
                                //console.log('mutation payload', mutation.payload);

                                hasAutosavedSinceLastEdit.value = false;
                            }

                        }
                    });
                } else {
                    postEditStore.$reset();
                    setCurrentAuthor();
                    setCurrentCategory();
                }

                postEditStore.setDefaultCategory(user.value);
            },
            { immediate: true }
        );
        watch(autoCompleteAuthor, (newVal) => {
            postEditStore.$patch((state) => {
                //if(console.log.hide) console.log(newVal, state);
                //if(state.post && !state.post.authorId) {
                if(state.post) {
                    state.post.authorId = newVal?.value ?? null;
                    /*
                    let datePath = formatDateTime(postEditStore.post.datePublished, 'yyyy/MM/dd');
                    postEditStore.post.url = `/${autoCompleteAuthor.value?.slug}/${datePath}/${postPermalink.value}-n${postEditStore.post.postId}`;
                    */
                    postEditStore.post.url = getPath();
                }
            });
        });
        watch(() => route.params.categoryId, async (toCategoryId, fromCategoryId) => {
                if(toCategoryId && toCategoryId != fromCategoryId) {
                    setCurrentCategory();
                }
            }
        );

        onBeforeMount(() => {
            badgeService.getBadges({ isActive: true }).then((res) => {
                badges.value = res;
            }).catch((err) => {
                console.error('Error fetching badges', err);
            });

            setCurrentAuthor();
            setCurrentCategory();
        });

        onMounted(() => {
            setInterval(autoSave, 20000); // Autosave every 20 seconds
            setCurrentCategory();
        });

        function autoSave() {
            if(postEditStore.isDirty && !hasAutosavedSinceLastEdit.value) {
                let revision = {
                    postId: postEditStore.post.postId,
                    userName: postEditStore.post.userName,
                    content: JSON.stringify(postEditStore.post)
                };
                postService.saveRevision(revision).then(() => {
                    console.log('\u23F1 Auto-save:', postEditStore.post);
                });
                hasAutosavedSinceLastEdit.value = true;
            }
        }

        function getPath(title = null) {
            let datePath = formatDateTime(postEditStore.post.datePublished, 'yyyy/MM/dd');
            let _title = seoUrl(title || postPermalink.value);
            //postEditStore.post.url = `/${autoCompleteAuthor.value?.slug}/${datePath}/${_title}-n${postEditStore.post.postId}`;
            if(appSettings.siteId == 1) {
                //let category = postStore.categoryList?.find((n) => n.categoryId == props.categoryId) ?? new { slug: 'tipsheet' }();
                let category = postStore.categoryList?.find((n) => n.categoryId == props.categoryId) ?? { slug: 'tipsheet' };
                return `/${category.slug}/${autoCompleteAuthor.value?.slug}/${datePath}/${_title}-n${postEditStore.post.postId}`;
            } else {
                return `/${autoCompleteAuthor.value?.slug}/${datePath}/${_title}-n${postEditStore.post.postId}`;
            }
        }

        function setLockMessageOnPostList(postMessage) {
            console.log('ReceivePostLock', postMessage);
            let postIndex = postStore.posts.findIndex((n) => n.postId == postMessage.postId);
            postStore.posts[postIndex] = { ...postStore.posts[postIndex], userIdLock: postMessage.userIdLock };

            //postMessage.postId == postEditStore.post.postId && !isEmptyOrNull(postMessage.userIdLock) && postMessage.userIdLock != user.value.name
            //if more than one person is on the locked post, lock everyone else out
            if (postMessage.postId == postEditStore.post.postId && !isEmptyOrNull(postMessage.userIdLock) && postMessage.userIdLock != user.value.name) {
                postEditStore.$patch((state) => {
                    state.isReadonly = true;
                    state.editMode = true;
                    state.isDirty = false;
                    //postEditStore.post.userIdLock = user.value.name
                });

                let duration = process.env.NODE_ENV == 'development' ? 5000 : null;
                toast.add({ severity: 'warn', summary: 'Post Taken Over', detail: `This post has been taken over by ${postMessage.userIdLock}.`, life: duration });
            }
            //another user on the post in preview mode and the user editing the post saves
            //we need to unlock the user in preview mode
            if (postMessage.postId == postEditStore.post.postId && isEmptyOrNull(postMessage.userIdLock)) {
                /*
                postEditStore.$patch((state) => {
                    state.isReadonly = false;
                    state.editMode = true;
                    state.isDirty = false;
                    //postEditStore.post.userIdLock = user.value.name
                });
                */
                getPost(postEditStore.post.postId);
            }
        }
        async function unlockPost() {
            let postMessage = {
                postId: postEditStore.post.postId,
                userIdLock: null,
                connectionId: null,
            };
            await postService.toggleLockPost(postMessage);
            //unlock
            signalr.invoke('BroadcastPostLock', postMessage);
        }
        function showDialogTakeOverPost(b) {
            showTakeOver.value = b;
        }
        function resetLockPost() {
            postEditStore.$patch((state) => {
                state.isReadonly = true;
                state.editMode = true;
                state.isDirty = false;
                //postEditStore.post.userIdLock = user.value.name
            });
        }
        function takeOverNotify(message) {
            console.log('Listen for user: ', message);
            resetLockPost();
            //setLockMessageOnPostList(message)
            toast.add({ severity: 'warn', summary: 'Post taken over', detail: message });
        }
        function setNewPosts() {
            postStore.newPosts++;
            console.log('Received a new post from signalr: ', postStore.newPosts);
        }
        async function takeOverPost() {
            let uid = user.value.name;
            let connectionId = user.value.email;

            postEditStore.$patch((state) => {
                state.isReadonly = false;
                state.editMode = true;
                state.isDirty = true;
                state.post.userIdLock = uid;
                state.post.connectionId = connectionId;
            });
            //hide show dialog
            showTakeOver.value = false;
            //lock backed on current user
            console.log('take over my connectionId: ', connectionId);
            let postMessage = { postId: postEditStore.post.postId, userIdLock: uid, connectionId: connectionId };
            signalr.send('TakeOverNotify', postMessage);
            toast.add({ severity: 'warn', summary: 'Post taken over', detail: 'You have taken over the post and the previous editor will be notified.', life: 5000 });
        }
        function readonlyPost() {
            console.log('Readonly');
            postEditStore.$patch((state) => {
                state.isReadonly = true;
            });
            showTakeOver.value = false;
        }
        function setCurrentAuthor() {
            //if(!route.params.id) {
                if(!postEditStore.post.authorId) {
                    authorStore.getCurrentAuthor(user.value).then(() => {
                        //postEditStore.post.authorId = authorStore.currentAuthor.authorId;
                        if(!route.params.id) {
                            postEditStore.$patch((state) => {
                                state.post.authorId = authorStore.currentAuthor?.authorId ?? null;
                            });
                        }
                    });
                }
            //}
        }
        function setCurrentCategory() {
            if(route.params.categoryId) {
                postEditStore.$patch((state) => {
                    state.post.categories = [ parseInt(route.params.categoryId) ];
                });
            }
        }

        async function deletePost(id) {
            confirm.require({
                message: 'Do you want to delete this Post?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Post deleted', life: 3000 });
                    let logFields = formatLogObject({ ...postEditStore.post });
                    //adminService.log({ logTypeId: 1, referenceId: postEditStore.post.postId, message: 'Deleted', content: '', original: JSON.stringify(postEditStore.post, null, 2), language: 'json' });
                    adminService.log({ logTypeId: 1, referenceId: postEditStore.post.postId, message: 'Deleted', content: '', original: JSON.stringify(logFields, null, 2), htmlBefore: pretty(postEditStore.post.body || ''), htmlAfter: '', language: 'json' });
                    await postService.deletePost(id);
                    elasticsearchService.siteSearchDeletePost(`post-${id}`);
                    onNewPost(false);
                },
                reject: () => {
                    toast.add({ severity: 'warn', summary: 'Rejected', detail: 'Delete cancelled', life: 3000 });
                },
            });
        }
        function onNewPost(confitm = true) {
            //had to repeat the code with my if, accept and reject because code will execution is not paused during confirm 😒
            const postStore = usePostStore();
            let filterPosts = postStore.filterPosts;
            if (confitm && postEditStore.isDirty) {
                confirm.require({
                    message: 'Changes have been made to this post. Do you want to save before leaving?',
                    header: 'Do you want to SAVE? 🤔 🤩',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Saved and starting a new post', life: 3000 });
                        await savePost();
                        autoCompleteAuthor.value = null;
                        postEditStore.$reset();
                        //router.push({ name: 'Posts', params: { categoryId: filterPosts.categoryId, id: null }, query: filterPosts });
                        goToNewPost();
                    },
                    reject: async () => {
                        toast.add({ severity: 'warn', summary: 'Save cancelled', detail: 'Post Save cancelled', life: 3000 });
                        autoCompleteAuthor.value = null;
                        //unlock post after abandoning it
                        if (postEditStore.post.postId != null && postEditStore.post.postId > 0) {
                            let postMessage = {
                                postId: postEditStore.post.postId,
                                userIdLock: null,
                                connectionId: null,
                            };
                            await postService.toggleLockPost(postMessage);
                            //unlock
                            signalr.invoke('BroadcastPostLock', postMessage);
                        }

                        postEditStore.$reset();
                        if(route.params.categoryId) {
                            router.push({ name: 'PostsCategory', params: { categoryId: route.params.categoryId, id: null }, query: filterPosts });
                        } else {
                            router.push({ name: 'Posts', params: { id: null }, query: filterPosts });
                        }
                    },
                });
            } else {
                autoCompleteAuthor.value = null;
                // postEditStore.$patch((state) => {
                //     state.isDirty = false;
                // });
                postEditStore.$reset();
                setCurrentAuthor();
                //router.push({ name: 'Posts', params: { id: null }, query: query });
                goToNewPost();
            }

            postStats.value = {};
            console.log('on-new-post 🔠');
        }
        function goToNewPost() {
            let query = { ...postStore.filterPosts };
            delete query.total;
            if(route.params.categoryId) {
                router.push({ name: 'PostsCategory', params: { categoryId: route.params.categoryId, id: null }, query: query });
            } else {
                router.push({ name: 'Posts', params: { id: null }, query: query });
            }
        }
        function headlinePlaceholder() {
            let i = 0;
            i += 1;
            return `Headline ${i}`;
        }
        function togglePermalink() {
            if (modifyPermalink.value) {
                //edit
                modifyPermalink.value = false;
            } else {
                //save
                postEditStore.urlTitle = postPermalink.value;
                modifyPermalink.value = true;

                // Modify URL
                /*
                let datePath = formatDateTime(postEditStore.post.datePublished, 'yyyy/MM/dd');
                postEditStore.post.url = `/${autoCompleteAuthor.value.slug}/${datePath}/${postPermalink.value}-n${postEditStore.post.postId}`;
                */
                postEditStore.post.url = getPath();
            }
        }
        async function getPost(id) {
            console.log('getpost-postEdit🤪', id);
            //reset PostLogs Component
            //postEditStore.postLogsKey += 1;
            let uid = user.value.name; //this.authState.idToken.claims.name;

            postEditStore.$patch((state) => {
                state.isReadonly = false;
            });
            await postService
                .getPost(id)
                .then((response) => {
                    //check if the post is locked -
                    //lock conditions - auto save, save, on post
                    //present user a dialog box to take over or view only
                    //console.log(`getPost(${id})`, response);
                    if(!response.postId) throw('Post ID not found');

                    if (response.userIdLock != null && uid != response.userIdLock) {
                        //userLock.value = postEditStore.post.userIdLock;
                        userLock.value = response.userIdLock;
                        showDialogTakeOverPost(true);
                    }

                    original.value = { ...response }; // Clone to prevent reactivity
                    postEditStore.$patch((state) => {
                        state.post = { ...response };
                        state.postId = id;
                        //state.original = response;
                        state.editMode = true;
                        state.isDirty = false;
                        state.post.body = addHyperlinkTitles(state.post.body);
                        postStats.value = { id: id, ...response.extendedProperties?.postStats };
                    });
                })
                .catch((err) => {
                    toast.add({
                        severity: 'error',
                        summary: 'Error Fetching Post',
                        detail: err.message || err,
                        life: 5000,
                    });
                });
        }

        function isValidForm() {
            //? isValid.value = false : isValid.value = true;
            let isFormValid = true;
            let title = postEditStore.post.title ? postEditStore.post.title.trim() : postEditStore.post.title;
            let authorId = postEditStore.post.authorId;
            let invalidFields = [];
            if (isEmptyOrNull(title)) {
                isFormValid = false;
                titleRequired.value = 'is-invalid';
                invalidFields.push('Title');
            } else {
                titleRequired.value = '';
            }
            if (!authorId || isNaN(authorId)) {
                isFormValid = false;
                authorIdRequired.value = 'p-invalid';
                invalidFields.push('Author');
            } else {
                authorIdRequired.value = '';
            }
            if (postEditStore.post.status == 1 && postEditStore.post.imageObject != null && postEditStore.post.imageObject.length > 0 && isEmptyOrNull(postEditStore.post.imageObject[0].url)) {
                isFormValid = false;
                imageRequired.value = 'input-border-danger';
                invalidFields.push('Main Image');
            } else {
                imageRequired.value = '';
            }
            if(!route.params.categoryId && (!postEditStore.post.categories || postEditStore.post.categories.length < 1)) {
                isFormValid = false;
                categoryRequired.value = 'input-border-danger';
                invalidFields.push('Category');
            }
            if(!postEditStore.getActiveHeadline) {
                // If active headline is empty, make Title active
                postEditStore.setDefaultHeadline();
            }
            if(isFormValid == false) toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields: ' + invalidFields.join(', '), life: 5000 });
            return isFormValid;
        }
        function hasValidHeadline() {
            let isHeadlineValid = true;
            let launchDate = new Date(appSettings.launchDateTime), dateCreated = new Date(postEditStore.post.dateCreated);
            let activeHeadline = '';

            if(postEditStore.activeHeadline) {
                activeHeadline = postEditStore.post.extendedProperties.headlines?.filter((n) => n.active == true)[0].headline;
            } else {
                activeHeadline = postEditStore.post.title.trim();
            }

            if(dateCreated > launchDate && activeHeadline.length > headlineConfig.maxLength) {
                isHeadlineValid = false;
                toast.add({ severity: 'error', summary: 'Invalid field', detail: `Active headline exceeds maximum length (${headlineConfig.maxLength} chars)`, life: 4000 });
            }
            return isHeadlineValid;
        }

        async function savePost(refreshList = true) {
            if(froalaEditorRef.value) froalaEditorRef.value.toggleCodeView();
            if(!isValidForm() || !hasValidHeadline() || isLoading.value) return;

            // Check for published post within 15 minutes
            let _date = Date.parse(postEditStore.post.datePublished);
            let dateRangeStart = subMinutes(_date, 14); // 14 minutes before
            let dateRangeEnd = addMinutes(_date, 14); // 14 minutes after
            let _warnings = [];
            isLoading.value = true;

            // Set category for Townhall if not set
            if(route.params.categoryId) {
                if(!postEditStore.post.categories?.length) {
                    postEditStore.$patch((state) => {
                        state.post.categories.push(route.params.categoryId);
                    });
                    //postEditStore.post.categories.push(route.params.categoryId);
                }
                if(!postEditStore.post.categoryId) {
                    postEditStore.$patch((state) => {
                        state.post.categoryId = route.params.categoryId;
                    });
                }
            }

            await postService.getPosts({ status: 1, datePublishedStart: formatDateTime(dateRangeStart, 'MM/dd/yyyy HH:mm'), datePublishedEnd: formatDateTime(dateRangeEnd, 'MM/dd/yyyy HH:mm') }).then((result) => {
                // Check for posts within 15 minutes of publish
                result = result.filter(item => item.postId != postEditStore.post.postId);
                if(postEditStore.post.status == 1 && postEditStore.post.categoryId != 1 && result && result.length) {
                    _warnings.push('Post scheduled within 15 minutes of another published post');
                }
                return postService.getPosts({ datePublishedStart: formatDateTimeISO(subDays(new Date, 5)), title: postEditStore.post.title, exactMatch: true }); // 5 = 5 days back
            }).then((result) => {
                // Check for posts with identical title within the past 5 days
                result = result.filter(item => item.postId != postEditStore.post.postId);
                if(result && result.length) {
                    _warnings.push('Recent post with duplicate title found');
                }
                //return postService.savePost(postClone);

                if(_warnings.length) {
                    confirm.require({
                        message: _warnings.join('; ') + '. Save anyway?',
                        header: 'Warnings',
                        icon: 'pi pi-exclamation-triangle',
                        //acceptClass: 'p-button-warning',
                        accept: async () => {
                            return savePostAfterChecks(refreshList);
                        },
                        reject: () => {
                            toast.add({ severity: 'warn', summary: 'Cancelled', detail: 'Post not saved', life: 3000 });
                        },
                    });

                } else {
                    return savePostAfterChecks(refreshList);
                }

            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Validation Error Saving Post', detail: err.message || err, life: 5000 });
            }).finally(() => {
                isLoading.value = false;
            });
        }

        async function savePostAfterChecks(refreshList) {
            //toast.add({ severity: 'success', summary: 'Success', detail: 'Post Saved! - ' + response.postId, life: 2000 });

            let wasNewPost = false;
            if (postEditStore.isReadonly) return;

            // Insert new post
            let isNewPost = postEditStore.newPost;
            if (postEditStore.newPost) {
                wasNewPost = true;
                // Create url and url title for new post only
                let urlTitle = seoUrl(postEditStore.post.title);
                //let category = postStore.categoryList.find((n) => n.categoryId == props.categoryId) ?? new { slug: 'tipsheet' }();
                //let date = new Date(postEditStore.post.datePublished);
                //let url = `/${category.slug}/${autoCompleteAuthor.value.slug}/${date.toISOString().slice(0, 10).replaceAll('-', '/')}/${urlTitle}`;
                let datePath = formatDateTime(postEditStore.post.datePublished, 'yyyy/MM/dd');
                let categorySlug = '';
                if(appSettings.siteId == 1) {
                    let cat = postEditStore.post.categories?.length > 0 ? postEditStore.post.categories[0] : null;
                    //let category = postStore.categoryList?.find((n) => n.categoryId == props.categoryId) ?? { slug: 'tipsheet' };
                    let category = postStore.categoryList?.find((n) => n.categoryId == cat) ?? { slug: 'tipsheet' };
                    categorySlug = `/${category.slug}`;
                }
                let path = `${categorySlug}/${autoCompleteAuthor.value.slug}/${datePath}/${urlTitle}`;

                postEditStore.$patch((state) => {
                    state.post.url = path;
                    state.post.urlTitle = urlTitle;
                    state.post.categoryId = props.categoryId || 0;
                });
            } else {
                let path = getPath();
                // Update the date in the URL only: https://stackoverflow.com/a/17321430/3799374
                /*
                let _date = formatDateTime(postEditStore.post.datePublished, 'yyyy/MM/dd');
                let url = postEditStore.post.url.replace(/\/\d{4}\/\d{2}\/\d{2}\//, `/${_date}/`);
                */
                //let url = `/${category.slug}/${autoCompleteAuthor.value.slug}/${date.toISOString().slice(0, 10).replaceAll('-', '/')}/${postEditStore.post.urlTitle}`;
                postEditStore.$patch((state) => {
                    state.post.url = path;
                });
            }

            // Get credit if null based on description
            let _credit = null;
            if(postEditStore.post?.imageObject?.length && postEditStore.post?.imageObject[0]?.description && !postEditStore.post?.imageObject[0]?.credit) {
                _credit = getCaptionFromDescription(postEditStore.post.imageObject[0].description);
            }

            // Updated properties before saving
            postEditStore.$patch((state) => {
                //state.original = postEditStore.post;
                state.post.userId = user.value.name;
                //tell the database the post is unlocked
                state.post.userIdLock = null;
                state.post.connectionId = null;
                state.post.datePublished = formatDateTimeISO(state.post.datePublished);
                state.post.body = stripHyperlinkTitles(state.post.body);
                state.post.body = removeEmptyParagraphs(state.post.body, true);
                if(_credit) state.post.imageObject[0].credit = _credit;
                //state.post.datePublished = formatDateTime(state.post.datePublished, 'yyyy-MM-dd HH:mm:ss');
                //state.post.url = url;
            });

            let postClone = { ...postEditStore.post }
            postClone.datePublished = formatDateTime(postEditStore.post.datePublished, 'yyyy-MM-dd HH:mm:ss'); // Strip the time zone
            postClone.dateModified = formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'); // Strip the time zone

            isLoading.value = true;
            postService.getPost(postClone.postId || 0).then((res) => {
                return res;
            }).then(async (orig) => {
                await postService
                    .savePost(postClone)
                    .then(async (response) => {
                        //patch so it doesn't see the mutations
                        postEditStore.$patch((state) => {
                            //state.original = response;
                            state.post = { ...response };
                            state.isDirty = false;
                            state.postId = response.postId;
                            state.post.hideRevision = true; // Hide revision message
                            state.editMode = true;
                            //state.post.userIdLock = null;
                        });
                        if (!postEditStore.newPost) {
                            let postMessage = {
                                postId: response.postId,
                                userIdLock: null,
                                connectionId: null,
                            };
                            //unlock post after save
                            console.log('sending unlock BroadcastPostLock', postMessage);
                            signalr.invoke('BroadcastPostLock', postMessage);
                        }
                        //adminService.log({ logTypeId: 1, referenceId: response.postId, message: 'Saved', content: postEditStore.post.body, original: original.value, language: 'html' });

                        // START: Log
                        let logFieldsBefore = isObject(orig) ? formatLogObject({ ...orig }) : {};
                        let logFieldsAfter = isObject(postEditStore.post) ? formatLogObject({ ...postClone }) : {};
                        if(isNewPost) {
                            adminService.log({ logTypeId: 1, referenceId: response.postId, message: 'New Post', content: JSON.stringify(logFieldsAfter, null, 2), original: '', htmlBefore: '', htmlAfter: pretty(postClone.body || ''), language: 'json' });
                        } else {
                            adminService.log({ logTypeId: 1, referenceId: response.postId, message: 'Updated', content: JSON.stringify(logFieldsAfter, null, 2), original: JSON.stringify(logFieldsBefore, null, 2), htmlBefore: pretty(orig ? orig.body : ''), htmlAfter: pretty(postClone.body || ''), language: 'json' });
                        }
                        // END: Log

                        // Add to Elasticsearch index: townhall-{environment}-1
                        //if(postEditStore.post.status == 1 && new Date(postEditStore.post.datePublished) < new Date() && postEditStore.post.body && postEditStore.post.body.trim()) {
                        if(process.env.NODE_ENV != 'development') {
                            if(postEditStore.post.status == 1 && postEditStore.post.body && postEditStore.post.body.trim()) {
                                let payload = { ...postEditStore.post, datePublished: formatDateTimeISO(postEditStore.post.datePublished) }; // Reformat DatePublished to ISO
                                if(!payload.url.includes('://')) payload.url = appSettings.webroot[process.env.NODE_ENV] + payload.url;
                                elasticsearchService.siteSearchSaveArticleNew(payload);
                            } else {
                                //elasticsearchService.siteSearchDeletePostNew(postEditStore.post.postId);
                                elasticsearchService.siteSearchDeletePostNew(`${appSettings.siteId}-post-${postEditStore.post.postId}`);
                            }
                        }
                        if(process.env.NODE_ENV == 'production') {
                            if(appSettings.domain != 'pjmedia.com'){
                                if(postEditStore.post.status == 1 && postEditStore.post.body && postEditStore.post.body.trim()) {
                                let payload = { ...postEditStore.post, datePublished: formatDateTimeISO(postEditStore.post.datePublished) }; // Reformat DatePublished to ISO
                                if(!payload.url.includes('://')) payload.url = appSettings.webroot[process.env.NODE_ENV] + payload.url;
                                console.log(appSettings);

                                appleNewsService.addToQueue(payload);
                                }else {
                                    appleNewsService.deletePost(postEditStore.post.siteGroup, postEditStore.post.postId);
                                }
                            }
                        }

                        if(wasNewPost){
                            console.log('sending BroadcastNewPost', 1);
                            signalr.invoke('BroadcastNewPost', 1);
                        }

                        // Start: Refresh post list and update stats in PostList.
                        //postStore.posts = []; // This does a really hard refresh; not ideal
                        postStore.getPosts(postStore.filterPosts).then(() => {
                            postStore.posts.map((obj) => {
                                if(obj.postId == response.postId) {
                                    //obj = response;
                                    obj.extendedProperties.postStats = response.extendedProperties.postStats;
                                }
                            });
                        });
                        // End: Refresh post list and update stats

                        // Update post stats for *current* post
                        postService.getPost(response.postId).then((res) => {
                            postStats.value.id = response.postId;
                            postEditStore.$patch((state) => {
                                //state.original = response;
                                state.post.extendedProperties.postStats = res.extendedProperties.postStats;
                            });
                        }).catch((err) => {
                            console.error('Error fetching post stats:', err);
                        });

                        // Delete auto-saves from Post.Revisions table
                        postService.deleteRevisions(response.postId);

                        toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Post Saved! - ' + response.postId,
                            life: 2000,
                        });

                        //refreshing List
                        if (refreshList) {
                            if(route.params.categoryId) {
                                router.push({ name: 'PostsCategory', params: { categoryId: route.params.categoryId, id: response.postId }, query: postStore.filterPosts });
                            } else {
                                router.push({ name: 'Posts', params: { id: response.postId }, query: postStore.filterPosts });
                            }
                        }
                    }).catch((err) => {
                        toast.add({
                            severity: 'error',
                            summary: 'Error Saving Post',
                            detail: err.message || err,
                        });
                    }).finally(() => {
                        isLoading.value = false;
                    });

            });
        }

        function formatLogObject(postFields) {
            delete postFields.body;
            delete postFields.extendedProperties.postStats;
            delete postFields.dateModified;
            delete postFields.userIdLock;
            delete postFields.connectionId;
            delete postFields.hideRevision;
            if(postFields.extendedProperties && postFields.extendedProperties.postStats) {
                delete postFields.extendedProperties.postStats.lastModified;
            }
            //postFields.dateModified = formatDateTimeET(postFields.dateModified) + ' ' + getTimezone();
            postFields.dateCreated = formatDateTimeET(postFields.dateCreated);
            postFields.datePublished = formatDateTimeET(postFields.datePublished);
            return postFields;
        }

        function getPreviewLink(hasPaywall = true) {
            let _ts = Math.round((new Date()).getTime() / 1000);

            // Get section slug
            let _section = postStore.categoryList?.find(({ categoryId }) => categoryId == postEditStore.post.categoryId);
            let sectionSlug = _section && _section.slug ? '/' + _section.slug : '';

            // Build preview link
            let previewLink = `${appSettings.webroot[process.env.NODE_ENV]}${sectionSlug}/preview/${postEditStore.post.postId}?p=${_ts}`;
            if(hasPaywall) previewLink += '&utm_content=preview';

            return previewLink;
        }

        function getPermalink(path) {
            return appSettings.webroot[process.env.NODE_ENV] + path;
        }

        function getThresholdCssClass(str) {
            if(!str || str.trim().length < headlineConfig.warnLength) return '';
            if(str.length <= headlineConfig.maxLength) return 'warning';
            return 'danger';
        }

        function todayClick(d) {
            if(console.log.hide) console.log(d);
            postDatePublished.value = formatDateTime(null, 'MM/dd/yyyy hh:mm a');
        }

        function refreshPostStats() {
            postService.getPostStats({ body: postEditStore.post.body }).then((stats) => {
                postEditStore.post.extendedProperties.postStats = postStats.value = { id: postEditStore.post.postId, ...stats };
            });
        }

        function restoreRevision(revision) {
            console.log('Restore revision:', revision);
            //postEditStore.post = revision;
            postEditStore.$patch((state) => {
                state.original = state.post;
                state.post = revision;
                state.post.hideRevision = true;
                state.isDirty = true;
            });
        }

        return {
            appSettings,
            authorIdRequired,
            authorStore,
            autoCompleteAuthor,
            badges,
            badgeColors,
            badgeBackgroundColor,
            badgeExpireAfterHours,
            categoryRequired,
            copied,
            copy,
            deletePost,
            platformVisibility,
            editButtons,
            //froalaConfig,
            froalaEditorRef,
            froalaImage,
            froalaImId,
            getPermalink,
            getPost,
            getThresholdCssClass,
            headlineConfig,
            headlinePlaceholder,
            imageRequired,
            //isEmptyOrNull,
            isLoading,
            isSaveAllowed,
            //isSaveDisabled,
            isSupported,
            mainImage,
            modifyPermalink,
            onNewPost,
            postDateExpires,
            postDatePublished,
            postEditStore,
            postStore,
            postLogsKey,
            postPermalink,
            postStats,
            postStatus,
            previewLinks,
            previewMenu,
            readonlyPost,
            referenceId,
            refreshPostStats,
            restoreRevision,
            route,
            savePost,
            showDialogTakeOverPost,
            showMiscPanel,
            showTakeOver,
            subscription,
            subscriptionContent,
            takeOverPost,
            text,
            titleRequired,
            todayClick,
            togglePermalink,
            togglePreviewOverlay,
            unlockPost,
            user,
            userLock,
            userHasAccessToRole
        };
    },
});
</script>

<style lang="scss" scoped>
div.card-body {
    label {
        font-weight: 500;
    }
    .input-small {
        max-width: 70px;
    }
    span.thm-char-count {
        color: var(--gray-800);
        font-size: 85%;

        &.warning {
            background-color: var(--yellow-300);
            border-color: var(--yellow-300);
        }
        &.danger {
            background-color: var(--red-400);
            border-color: var(--red-400);
            color: #fff;
        }
    }
    .p-inputgroup {
        .modify-disabled {
            background: #e9ecef;
            color: #6c757d;
            opacity: unset;
        }
    }
}

.badge-dropdown-color {
    &__value {
        display: inline-block;
        height: 1em;
        width: 1em;
    }
    &__label {
        vertical-align: text-bottom;
        padding-left: 0.2em;
    }
}
</style>

<style lang="scss">
// Post Stats panel background colors:
.thm-panel-poststats {
    &__warning {
        div.p-panel-content {
            color: var(--yellow-900);
            background-color: var(--yellow-300);
        }
    }
    &__error {
        div.p-panel-content {
            color: var(--red-50);
            background-color: #D32F2F;
        }
    }
}
.pe-btn-white-thm {
    background-color: #fff !important;
    .p-button-label {
        color: #313943 !important;
    }
}
</style>
