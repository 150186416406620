<template>
    <i v-show="show" class="far fa-triangle-exclamation" :class="colorClass" v-tooltip="message"></i>
</template>

<script>
//import { ref, computed, onMounted } from 'vue';
import { computed } from 'vue';
import { abbreviateNumber } from '@/utils/ThmStringUtils';

export default {
    name: 'AdStatsTooltip',
    props: {
        postStats: {
            type: Object,
            default: () => ({
                id: 0,
                adDensity: 0,
                nextAd: 0,
                words: 0,
                characters: 0,
                lastModified: null,
                hasExternalImage: null,
                validAmp: null,
                ampErrors: [],
                validHttps: null,
                httpsErrors: [],
                validHtml: null,
                validFeeds: null,
                validationPassed: null,
            })
        }
    },
    setup(_props) {
        /*
        const colorClass = ref('text-success');
        const show = ref(false);

        onMounted(() => {
            if(_props.postStats == null) return false;

            let nextAd = _props.postStats.nextAd - _props.postStats.characters;
            if(nextAd > 0 && nextAd <= 75 && _props.postStats.adDensity < 7) {
                show.value = true;
                colorClass.value = 'text-warning';
            }
            if(nextAd > 0 && _props.postStats.adDensity < 2) {
                show.value = true;
                colorClass.value = 'text-danger';
            }
        });
        */

        // Converted these to computed values to make them reactive. Ideally, "postStats" should be modelValue.
        const show = computed(() => {
            let nextAd = _props.postStats.nextAd - _props.postStats.characters;
            return nextAd > 0 && ((nextAd <= 75 && _props.postStats.adDensity < 7) || _props.postStats.adDensity < 2);
        });

        const colorClass = computed(() => {
            let nextAd = _props.postStats.nextAd - _props.postStats.characters;
            switch(true) {
                case nextAd > 0 && nextAd <= 75 && _props.postStats.adDensity < 7:
                    return 'text-warning';
                case nextAd > 0 && _props.postStats.adDensity < 2:
                    return 'text-danger';
                default:
                    return '';
            }
        });

        const message = computed(() => {
            //let m = _props.postStats != null ? `Only ${_props.postStats.nextAd} characters for a ${_props.postStats.adDensity + 1}<sup>${abbreviateNumber(_props.postStats.adDensity + 1)}</sup> ad.` : '';
            let m = _props.postStats != null ? `Only ${_props.postStats.nextAd} characters for a ${_props.postStats.adDensity + 1}${abbreviateNumber(_props.postStats.adDensity + 1)} ad.` : '';
            return m;
        });

        return {
            colorClass,
            message,
            show
        }
    }
}
</script>
