import { defineStore } from 'pinia';
import { getAppSettings } from '@/utils/ThmUtils';
import { formatDateTimeET } from '@/utils/ThmDateTimeUtils';
import { getUserSettings } from '@/utils/ThmUtils';

export const usePostEditStore = defineStore('PostEditStore', {
    state: () => ({
        appSettings: getAppSettings(),
        postId: 0,
        postEditKey: 0,
        isDirty: false,
        isReadonly: false,
        //original: {},
        post: {
            postId: null,
            categoryId: 0,
            categories: [],
            title: null,
            description: null,
            body: '',
            dateCreated: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'), //new Date(),
            dateModified: null,
            datePublished: formatDateTimeET(null, 'yyyy-MM-dd HH:mm:ss'), //new Date(),
            dateExpires: null,
            status: 2,
            userId: null,
            userIdLock: null,
            authorId: null,
            url: null,
            urlTitle: null,
            tags: [],
            siteGroup: getAppSettings().siteGroup,
            subscription: 0,
            subscriptionContent: 0,
            imageObject: [
                {
                    url: null,
                    id: '',
                    credit: null,
                    type: 1,
                    typeName: 'Main Image',
                },
            ],
            extendedProperties: {
                headlines: [
                    {
                        id: 1,
                        headline: null,
                        active: false,
                    },
                    {
                        id: 2,
                        headline: null,
                        active: false,
                    },
                    {
                        id: 3,
                        headline: null,
                        active: false,
                    },
                ],
                badge: {},
                bridVideo: {},
                deviceVisibility: 0,
                embedCode: null,
                embedCodeVip: null,
                shareTitle: null,
                adsDisabled: false,
                subscriptionContent: 0,
                postStats: {
                    id: 0,
                    adDensity: 0,
                    nextAd: 0,
                    words: 0,
                    characters: 0,
                    lastModified: null,
                    hasExternalImage: false,
                    validAmp: true,
                    ampErrors: null,
                    validHttps: true,
                    httpsErrors: null,
                    validHtml: true,
                    validFeeds: true,
                    validationPassed: true,
                }
            },
        },
        editMode: false,
    }),
    actions: {
        setDefaultHeadline() {
            this.post.extendedProperties.headlines.forEach((n) => (n.active = false));
        },
        setHeadline(id) {
            //this.post.extendedProperties.headlines.forEach(n=>n.active = false);

            console.log("headline id: ",id);
            let list = this.post.extendedProperties.headlines;
            for (let index = 0; index < list.length; index++) {
                if (index == id) list[index].active = true;
                else list[index].active = false;
            }
        },
        setDefaultCategory(user) {
            if(!user || this.post.categories.length > 0) return;
            try {
                let defaultCategory = user[`${this.appSettings.auth0.audience}/user_metadata`][this.appSettings.slug].defaultCategory;
                if(defaultCategory) this.post.categories.push(defaultCategory);
                return defaultCategory;
            } catch {
                let userConfig = getUserSettings();
                if(userConfig?.defaultCategory) {
                    this.post.categories.push(userConfig.defaultCategory);
                    return userConfig.defaultCategory;
                }
                if(this.appSettings.features.postEditor.defaultCategory) {
                    this.post.categories.push(this.appSettings.features.postEditor.defaultCategory);
                    return this.appSettings.features.postEditor.defaultCategory;
                }
                return null;
            }
        }
    },
    getters: {
        newPost(state) {
            return state.postId == null || state.postId == '';
        },
        activeHeadline(state) {
            let headlines = state.post.extendedProperties?.headlines;
            let hasActive = headlines?.filter((n) => n.active == true).length > 0;
            return hasActive;
        },
        getActiveHeadline(state) {
            let props = state.post.extendedProperties;

            if(props.headlines && props.headlines.length) {
                for(let idx = 0; idx < props.headlines.length; idx++) {
                    if(props.headlines[idx].active) return props.headlines[idx].headline.trim();
                }
            }
            // Default
            return state.post.title ? state.post.title.trim() : state.post.title;
        }
    },
});
