<template>
        <ConfirmDialog />
   <form class="needs-validation" novalidate>
       <!-- Main Area -->
       <div class="row mb-3">
           <div class="col-sm-12 col-md-6 col-xl-6">
               <div class="card card-border-color card-border-color-primary shows">
                    <div class="card-body user-list container-fluid pb-3">
                        <div class="card-header card-header-divider">Offers</div>
                        <div class="row border-bottom pb-2 mb-1">
                              <div class="col-md-1 d-none d-md-block form-label"></div>
                              <div class="col ps-1 form-label" >Partner</div>
                              <div class="col ps-1 form-label" >Offer</div>
                              <div class="col ps-1 form-label" >Logo</div>
                              <div class="col-md-auto d-none d-md-block form-label" >Active</div>
                        </div>
                        <div v-for="offer in coRegStore.offers" :key="offer.offerId" class="row offer item pt-2" v-bind:class="{ active: coRegStore.offer.offerId == offer.offerId }">
                              <div class="col-md-auto d-none d-md-block">
                                    <Button label="Edit"  @click="editOffer(offer.offerId)"  />
                              </div>
                              <div class="col ps-1" @click="editOffer(offer.offerId)">
                                 {{ offer.partnerName }}
                              </div>
                              <div class="col ps-1" @click="editOffer(offer.offerId)">
                                 {{ offer.offerName}}
                              </div>
                              <div class="col ps-1" @click="editOffer(offer.offerId)">
                                 <Image :src="offer.logo" />
                              </div>
                              <div class="col-md-auto d-none d-md-block" @click="editOffer(offer.offerId)">
                                 <div class="">
                                    <InputSwitch v-model="offer.isActive"  @change="activateOffer(offer)"/>
                                 </div>
                              </div>
                        </div>
                     </div>
               </div> <!------- END DIV -->
           </div>

           <div class="col-sm-12 col-md-6 col-xl-6 editor-column">
               <div class="card card-border-color card-border-color-primary">
                   <div class="card-header card-header-divider">
                    <div class="d-flex align-items-center">
                        <div v-if="coRegStore.offer.offerId">Edit Offer</div>
                        <div v-else >New Offer</div>
                        <div class="ms-auto">
                        <Button icon="pi pi-plus" class="btn-add p-button-secondary p-button-tiny  " iconPos="right" @click="createOffer()" title="Add New Offer" />
                        </div>
                     </div>

                   </div>

                  <div class="card-body" >
                  <form class="row" novalidate>


                     <div class="col-12 mb-3">
                        <label for="BadgeLabel" class="form-label d-block required">Partner</label>
                        <Dropdown v-model="coRegStore.offer.partnerId" :class="partnerIdRequired" :options="coRegStore.partnersList" optionLabel="name"  optionValue="partnerId" :showClear="true" placeholder="Select a Partner" id="PartnerLabel"  />
                        <a href="#" @click="showAddPartner()">&nbsp;&nbsp;Add New Partner</a>
                    </div>
                     <div id="AddNewPartner" class="col-12 mb-3 " style="display:none">
                        <label for="AddPartner" class="form-label">Add New Partner</label>
                        <InputText style="width:90%; float:left" type="text" class="form-control"  id="AddPartner"    />
                        <Button icon="pi pi-plus" style=" float:right" class="btn-add p-button-secondary p-button-tiny " iconPos="right" @click="addPartner()" title="Add Partner" />

                     </div>
                     <div class="mb-3" >
                        <label for="Offer" class="form-label required">Offer</label>
                        <InputText type="text"  :class="offerNameRequired" class="form-control"    id="Offer" v-model="coRegStore.offer.offerName"  />
                     </div>
                     <div class="mb-3 input-group" :class="logoRequired" >
                        <label for="Logo" class="form-label w-100 required">Logo </label>
                        <InputText type="text" class="form-control" :class="logoRequired" id="Logo" v-model="coRegStore.offer.logo" placeholder="https://" @change="coRegStore.offer.logo = $event.target.value" />
                        <button v-if="coRegStore.offer.logo" class="btn btn-outline-secondary delete" type="button" @click="deleteLogo"><i class="far fa-trash-alt"></i></button>
                        <!-- <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true"  @uploader="uploadLogo($event, 'authorImage')"/> -->
                        <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadLogo($event, 'logo')" />
                        <!--
                              <button v-if="coRegStore.offer.logo" class="btn btn-outline-secondary delete" type="button" @click="deleteLogo"><i class="far fa-trash-alt"></i></button>
                        <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'authorImage')" />
                        -->

                     </div>


                     <div class="mb-3" >

                     <img  id="Logo" :src="coRegStore.offer.logo" class=" preview-image" />

                     </div>

                     <div class="mb-3">
                        <label for="Description" class="form-label required">Description</label>
                        <InputText type="text"  :class="descriptionRequired"  class="form-control"  id="Description" v-model="coRegStore.offer.description"  />
                     </div>
                     <div class=" mb-3">
                        <Button :label="coRegStore.offer.offerId != 0 ? 'Save' : 'Add'"  @click="saveOffer()" />
                        <Button v-if="coRegStore.offer.offerId !=0" label="Delete" class="p-button-danger btn-delete" @click="deleteOffer()"/>
                     </div>
                  </form>
                  </div>
               </div>
           </div>
       </div>
   </form>
</template>
<script>
import { useRoute, useRouter} from 'vue-router';
import {  onBeforeMount, onMounted, watch, ref } from 'vue';
import { useCoRegStore } from '@/stores/CoRegStore';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import MediaService from '@/service/MediaService';
import { isDefined} from '@vueuse/core';
import FileUpload from 'primevue/fileupload';


export default {
   name: 'Offers',
   components: {
     ConfirmDialog,
     FileUpload

   },

   setup(){

      const coRegStore = useCoRegStore();

      const mediaService = new MediaService();

      //const router = useRouter();
      const toast = useToast();
      const route = useRoute();
      const router = useRouter();
      const isLoading = ref(false);
      const confirm = useConfirm();

      const partnerIdRequired = ref('');
      const logoRequired = ref('');
      const descriptionRequired = ref ('');
      const offerNameRequired = ref('');



      //const pageSize = 20;

      watch(
            //() => route.query, async (params) => {
            () => route.query, (params, fromParams) => {
                if(console.log.hide) console.log(params, fromParams);
                //if(console.log.hide) console.log({ params });
                if(route.params.offerId != fromParams.offerId) {
                    // Only if offerId changed
                    if(route.params.offerId) {
                        coRegStore.setOffer(route.params.offerId).catch((err) => {
                            toast.add({ severity: 'error', summary: 'Error Fetching Offer', detail: err.message || err, life: 3000 });
                        });
                    } else {
                        coRegStore.setDefaults();
                    }
                }
                coRegStore.listOffers(params).then((res) => {
                    if(console.log.hide) console.log('res', res);
                });
                //console.log(coRegStore.offer);
            }
        );

        onBeforeMount(() => {
            coRegStore.getPartnersList();
            if(route.params.offerId) {
                coRegStore.setOffer(route.params.offerId).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Offer', detail: err.message || err, life: 3000 });
                });
            } else {
                coRegStore.setDefaults();
            }
        });

      onMounted(() => {

            coRegStore.listOffers(route.query).then((res) => {
                if(console.log.hide) console.log('res', res);

                console.log('CoRegStore Offer', coRegStore.offer);
                console.log('CoRegStore Offers', coRegStore.offers);
            });

        });

        function addPartner() {

            var partner = document.getElementById("AddPartner").value;
            //console.log(partner);
            coRegStore.addPartner(partner).then(() => {
                document.getElementById("AddPartner").value = "";
                showAddPartner();
                toast.add({ severity: 'success', summary: 'Partner Added', detail: 'Partner Added', life: 3000 });

            }).catch((err) => {

                toast.add({ severity: 'error', summary: 'Adding Partner Failed', detail: err.message || err, life: 2000 })
                document.getElementById("AddPartner").value = "";
                showAddPartner();

            });


        }

        function showAddPartner() {
            var x = document.getElementById("AddNewPartner");
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        }

        function activateOffer(offer) {
            console.log("activate Offer");

            var activate = coRegStore.activateOffer(offer);
            if( activate){
                    toast.add({ severity: 'success', summary: 'Activation changed', detail: 'Activation Changed', life: 3000 });
                    router.push({ path: `/tools/coreg/offers/`, query: route.query });
            }
        }

        function createOffer() {
            router.push({ path: `/tools/coreg/offers/` });
            console.log('CoRegStore Offer', coRegStore.offer);
        }
        function editOffer(offerId) {
            router.push({ path: `/tools/coreg/offers/${offerId}`, query: route.query });
            console.log('CoRegStore Offer', coRegStore.offer);
        }

        function deleteOffer(){

            confirm.require({
                message: 'Are you sure you want to permanently delete this offer?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                        isLoading.value = true;
                        coRegStore.deleteOffer().then(()=>{
                        toast.add({ severity: 'success', summary: 'Offer Deleted', detail: 'Offer Saved', life: 3000 });
                        coRegStore.listOffers(route.query);
                        coRegStore.setDefaults();
                        router.push({ path: `/tools/coreg/offers/` });
                    }

                    ).catch((err) => {
                        if (console.log.hide) console.log(err);
                            toast.add({ severity: 'error', summary: 'Offer Delete Failed', detail: err.message || err, life: 2000 })
                        }

                    ).finally(

                    );

                },
            });

        }
        function saveOffer() {
            let invalidFields = [];

            var requiredFields = [coRegStore.offer.partnerId, coRegStore.offer.offerName, coRegStore.offer.logo, coRegStore.offer.description],
                isFormInvalid = false;

            if(coRegStore.offer.partnerId == null) {
                isFormInvalid = true;
                partnerIdRequired.value = 'p-invalid';
                invalidFields.push('Partner');

            } else {
                partnerIdRequired.value = '';
            }
            if(coRegStore.offer.offerName == null) {
                isFormInvalid = true;
                offerNameRequired.value = 'p-invalid';
                invalidFields.push('Offer');

            } else {
                offerNameRequired.value= '';
            }
            if(coRegStore.offer.logo == null ) {
                isFormInvalid = true;
                logoRequired.value = 'p-invalid';
                invalidFields.push('Logo');

            } else {
                logoRequired.value = '';
            }
            if(coRegStore.offer.description == null){
                isFormInvalid = true;
                descriptionRequired.value = 'p-invalid';
                invalidFields.push('Description');
            }else{
                descriptionRequired.value = '';
            }
            requiredFields.forEach((elm) => {
                if (!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields: ' + invalidFields.join(', '), life: 5000 });
                return;
            }

            coRegStore.saveOffer().then(() => {
                            toast.add({ severity: 'success', summary: 'Offer Saved', detail: 'Offer Saved', life: 3000 });
                            coRegStore.listOffers(route.query);
                            coRegStore.setDefaults();
                            router.push({ path: `/tools/coreg/offers/` });
                        })
                        .catch((err) => {
                            if (console.log.hide) console.log(err);
                            toast.add({ severity: 'error', summary: 'Offer Save Failed', detail: err.message || err, life: 2000 })
                        }).finally(() => {
                            //podcastStore.show.images[elm] = null;
                            //isLoading.value = false;
                        });
        }

        function deleteLogo() {
            //isLoading.value = true;
            //https://cdn.townhall.com/media/2023-04/cam---co-1400-x-1400.png
            //var image = 'https://cdn.townhall.com/web/coreg/logos/84e57d0b-8cc1-4ef7-b952-1feb8cb17156.png'
            if (!coRegStore.offer.logo) return;
            //console.log(coRegStore.offer.logo);

            confirm.require({
                message: 'Are you sure you want to permanently delete this upload?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    isLoading.value = true;
                    mediaService.deleteMediaObject('web', coRegStore.offer.logo).then(() => {
                        //podcastStore.show.images[elm] = null;
                        coRegStore.offer.logo = null;
                        //saveOffer();
                    })
                    .catch((err) => {
                        if (console.log.hide) console.log(err);
                        //toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 2000 })
                    }).finally(() => {
                        //podcastStore.show.images[elm] = null;
                        isLoading.value = false;
                    });
                },
            });
        }

        function uploadLogo(event) {
            if (!isDefined(event.files) || !event.files) return;
            isLoading.value = true;
            console.log(`web`, event.files[0]);


            let img = new Image()
            img.src = window.URL.createObjectURL(event.files[0]);
            img.onload = () => {

            mediaService
                .uploadMediaObject(`web/coreg`, event.files[0])
                .then((res) => {
                    console.log('[uploadImage] response:', res);
                    let uploadedFileUrl = 'https://cdn.townhall.com/web/' + res[0].fullPath;
                    if (coRegStore.offer.logo == null) coRegStore.offer.logo = {};
                    coRegStore.offer.logo = uploadedFileUrl;
                })
                .catch((err) => {
                    //podcastStore.show.images[elm] = null;
                    if (console.log.hide) console.log(err);
                    //toast.add({ severity: 'error', summary: 'Upload Error', detail: err.message || err, life: 2000 });
                })
                .finally(() => {
                    isLoading.value = false;
                    if(img.width != 168 && img.height > 60){
                    isLoading.value = true;
                    mediaService.deleteMediaObject('web', coRegStore.offer.logo).then(() => {
                            //podcastStore.show.images[elm] = null;
                            coRegStore.offer.logo = null;
                            toast.add({ severity: 'error', summary: 'Images need to be 168px in length and less than 60px height' })
                            //saveOffer();
                        })
                        .catch((err) => {
                            if (console.log.hide) console.log(err);
                            //toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 2000 })
                        }).finally(() => {
                            //podcastStore.show.images[elm] = null;
                            isLoading.value = false;
                        });

                    }
                });
            }




        }

        return {
            activateOffer,
            addPartner,
            coRegStore,
            createOffer,
            deleteLogo,
            deleteOffer,
            descriptionRequired,
            editOffer,
            isDefined,
            isLoading,
            logoRequired,
            offerNameRequired,
            partnerIdRequired,
            saveOffer,
            showAddPartner,
            uploadLogo,
        };
   }
}

</script>
<style lang="scss" scoped>
.user-list {
    .row.offer {
        padding-bottom: 0.5em;

        &:nth-child(odd) {
            background-color: #f0f2f5;
        }
        &.active {
            background-color: var(--bluegray-100);
        }
    }

    .preview-image {
        background: no-repeat center center / cover;
        display: inline-block;
        vertical-align: middle;
        width: 42px;
        height: 42px;
    }
}
div.card-body {
    label {
        font-weight: 500;
    }
    .preview-image {
        max-height: 75px;
        width: auto;
        /*
        &.cursor {
            cursor: pointer;
        }
        */
    }
    .required::after {
        content: '*';
        color: rgb(255, 0, 0);
    }

    .btn-delete {
        margin-left: 0.4em;
    }
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
}
.form-label{
    font-weight: 500;
}

.newpartner {
    display:none;
}
.p-invalid{
    border-color: #f44336 !important;
}
</style>