<template>
    <div class="col-sm-12 col-md-4">
        <div class="card card-border-color card-border-color-primary shows">
            <div class="card-header card-header-divider">Item List</div>

            <div class="card-body">
                <p>Click to edit:</p>

                <ul>
                    <li v-for="item in items" :key="item.id">
                        <a @click="$emit('edit-item', item)">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListDemo',
    data() {
        return {};
    },
    props: {
        items: Array,
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    a {
        color: #9f2729;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
