<template>
    <MultiSelect id="MultiSites" v-model="model" :options="sites" optionLabel="description" optionValue="siteGroup" class="d-flex" placeholder="Select Sites" :display="props.display" />
</template>

<script>
import { ref, computed, onMounted } from 'vue';
//import { isDefined } from '@vueuse/core';
//import { deviceIsOS } from '@/utils/ThmDeviceUtils';
import AdminService from '@/service/AdminService';

export default {
    name: 'SiteGroupSelectServer',
    emits: ['update:modelValue'],
    props: {
        modelValue: [String, Object],
        display: {
            type: String,
            default: 'comma'
        }
    },
    setup(props, { emit }) {
        const adminService = new AdminService();
        const sites = ref([]);

        onMounted(() => {
            if(!sites.value.length) {
                adminService.getSites().then((response) => {
                    //if(!deviceIsOS('macOS')) console.log(sites.value);
                    sites.value = response;
                }).catch(function (error) {
                    console.error(error);
                });
            }
        });

        const model = computed({
            get() {
                let items = [];
                /*
                if(isDefined(props.modelValue)) {
                    props.modelValue.forEach((item) => {
                        //if(!deviceIsOS('macOS')) console.log('item.siteGroup', item.siteGroup);
                        items.push(item.siteGroup);
                    });
                }
                */
                if(Array.isArray(props.modelValue)) {
                    items = [ ...props.modelValue ];
                }
                return items;
            },
            set(value) {
                let items = [];
                /*
                if(isDefined(value)) {
                    value.forEach((item) => {
                        //if(!deviceIsOS('macOS')) console.log(item)
                        items.push({ siteGroup: item });
                    })
                }
                */
                if(Array.isArray(value)) {
                    items = [ ...value ];
                }
                emit('update:modelValue', items);
            }
        });

        return {
            model,
            props,
            sites
        }
    }
}
</script>
