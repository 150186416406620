<template>
    <div class="col-sm-12 col-md-8">
        <ConfirmDialog />

        <div class="card card-border-color card-border-color-primary" id="podcast_show">
            <div class="card-header card-header-divider"><span v-if="podcastStore.show.id">Edit</span><span v-else>Add</span> Show</div>

            <div class="card-body">
                <div class="mb-3">
                    <label for="Name" class="form-label required">Title / Name</label>
                    <InputText type="text" class="form-control" v-model="podcastStore.show.title" id="Name" />
                    <a v-if="podcastStore.show.slug && podcastStore.show.isPublished" :href="podcastStore.show.slug ? `https://${siteStore.site && typeof siteStore.site.domain != 'undefined' ? siteStore.site.domain : ''}/podcasts/${podcastStore.show.slug}` : ''" class="d-block" target="_blank">
                        {{ podcastStore.show.slug ? `https://${siteStore.site && typeof siteStore.site.domain != 'undefined' ? siteStore.site.domain : ''}/podcasts/${podcastStore.show.slug}` : '' }}
                    </a>
                </div>

                <div class="mb-3">
                    <label for="Byline" class="form-label">Byline</label>
                    <InputText type="text" class="form-control" v-model="podcastStore.show.byline" id="Byline" />
                </div>

                <div class="mb-3">
                    <label for="Slug" class="form-label required">URL Slug</label>
                    <InputText type="text" class="form-control" v-model="podcastStore.show.slug" id="Slug" @keyup="filterSlug($event.target)" :disabled="!userHasAccessToRole('Admin')" />
                    <span class="help">Only letters, numbers and hyphens allowed. Example: <code>my-podcast-name</code></span>
                </div>

                <div class="mb-3 input-group" v-show="podcastStore.show.subscription <= 1">
                    <label for="Url" class="form-label w-100">Feed URL</label>
                    <InputText
                        type="url"
                        class="form-control"
                        readonly="readonly"
                        v-model="feedUrl"
                        id="Url"
                        aria-describedby="btn_copy_feed_url"
                    />
                    <button class="btn btn-outline-secondary" type="button" id="btn_copy_feed_url" @click="copy(podcastStore.show.slug ? `https://${siteStore.site && typeof siteStore.site.domain != 'undefined' ? siteStore.site.domain : ''}/feed/v1/podcast/${podcastStore.show.slug}` : '')">
                        <span v-if="copied">Copied</span><span v-else>Copy</span>
                    </button>
                </div>

                <div class="mb-3">
                    <InputSwitch v-model="podcastStore.show.isExplicit" id="IsExplicit" />
                    <label for="IsExplicit" class="switch-label">Explicit</label>
                </div>

                <div class="mb-3">
                    <label for="SubscriptionLevel" class="form-label">Subscription Level</label>
                    <Dropdown id="SubscriptionLevel" v-model="podcastStore.show.subscription" :options="appSettings.subscriptionLevels" optionLabel="label" optionValue="value" class="d-flex" />
                </div>

                <div class="mb-3">
                    <div class="row">
                        <div class="col">
                            <label for="SecondarySiteIds" class="form-label">Active Site(s)</label>
                            <SiteGroupSelect v-model="podcastStore.show.siteGroup" />
                        </div>
                        <div class="col">
                            <label for="PrimarySiteId" class="form-label required">Primary Site</label>
                            <Dropdown id="PrimarySiteId" v-model="podcastStore.show.siteId" :options="siteStore.sites" optionLabel="description" optionValue="siteId" class="d-flex" />
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="Description" class="form-label">Description</label>
                    <FroalaThm v-model="podcastStore.show.description" :config="{ toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple', 'formatUL', 'insertLink', 'undo', 'redo'], charCounterCount: false, pastePlain: true }" />
                    <span class="help">This description is shown on the podcast show and episode landing pages.</span>
                </div>

                <div class="mb-3">
                    <label for="ShortDescription" class="form-label">Short Description</label>
                    <Textarea v-model="podcastStore.show.shortDescription" :autoResize="true" rows="3" cols="30" class="form-control" id="ShortDescription" />
                    <span class="help">The short description is plain text and used for the show card.</span>
                </div>

                <Divider type="dashed" />

                <div class="mb-3 input-group">
                    <label for="CoverArt" class="form-label w-100">Cover Art</label>
                    <InputText type="text" class="form-control" id="CoverArt" :value="isDefined(podcastStore.show.images) ? podcastStore.show.images.coverArt : ''" placeholder="https://" @change="podcastStore.show.images.coverArt = $event.target.value" />
                    <button v-if="podcastStore.show.images && podcastStore.show.images.coverArt" class="btn btn-outline-secondary delete" type="button" @click="deleteImage('coverArt')"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'coverArt')" />
                    <span class="help w-100">Dimensions: 1400x1400</span>
                </div>

                <div class="mb-3" v-if="podcastStore.show.images && podcastStore.show.images.coverArt">
                    <!--<a :href="podcastStore.show.images.coverArt" target="_blank"><img :src="podcastStore.show.images.coverArt" class="preview-image" title="Cover Art" /></a>-->
                    <Image :src="podcastStore.show.images.coverArt" alt="Page Image" class="preview-image" :preview="true" />
                </div>

                <div class="mb-3 input-group">
                    <label for="SquareImage" class="form-label w-100">Square Image</label>
                    <InputText type="text" class="form-control" id="SquareImage" :value="isDefined(podcastStore.show.images) ? podcastStore.show.images.squareImage : ''" placeholder="https://" @change="podcastStore.show.images.squareImage = $event.target.value" />
                    <button v-if="podcastStore.show.images && podcastStore.show.images.squareImage" class="btn btn-outline-secondary delete" type="button" @click="deleteImage('squareImage')"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'squareImage')" />
                    <span class="help w-100">Dimensions: 300x300</span>
                </div>

                <div class="mb-3" v-if="podcastStore.show.images && podcastStore.show.images.squareImage">
                    <!--<a :href="podcastStore.show.images.squareImage" target="_blank"><img :src="podcastStore.show.images.squareImage" class="preview-image" title="Square Image" /></a>-->
                    <Image :src="podcastStore.show.images.squareImage" alt="Page Image" class="preview-image" :preview="true" />
                </div>

                <div class="mb-3 input-group">
                    <label for="RectangleImage" class="form-label w-100">Rectangle Image</label>
                    <InputText type="text" class="form-control" id="RectangleImage" :value="isDefined(podcastStore.show.images) ? podcastStore.show.images.rectangleImage : ''" placeholder="https://" @change="podcastStore.show.images.rectangleImage = $event.target.value" />
                    <button v-if="podcastStore.show.images && podcastStore.show.images.rectangleImage" class="btn btn-outline-secondary delete" type="button" @click="deleteImage('rectangleImage')"><i class="far fa-trash-alt"></i></button>
                    <FileUpload v-else name="images[]" mode="basic" :auto="true" :fileLimit="1" accept="image/*" :disabled="isLoading" :customUpload="true" @uploader="uploadImage($event, 'rectangleImage')" />
                </div>

                <div class="mb-3" v-if="podcastStore.show.images && podcastStore.show.images.rectangleImage">
                    <!--<a :href="podcastStore.show.images.rectangleImage" target="_blank"><img :src="podcastStore.show.images.rectangleImage" class="preview-image" title="Rectangle Image" /></a>-->
                    <Image :src="podcastStore.show.images.rectangleImage" alt="Page Image" class="preview-image" :preview="true" />
                </div>

                <Divider type="dashed" />

                <div class="mb-3">
                    <InputSwitch v-model="podcastStore.show.includeInSearch" id="IncludeInSearch" />
                    <label for="IncludeInSearch" class="switch-label">Include in Search Results</label>
                </div>

                <div class="mb-3">
                    <InputSwitch v-model="podcastStore.show.isExternal" id="isExternal" />
                    <label for="isExternal" class="switch-label">External Feed</label>
                </div>

                <div class="mb-3" v-show="podcastStore.show.isExternal">
                    <label for="RssSourceFeed" class="form-label">RSS Source Feed</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" id="RssSourceFeed" placeholder="https://" :value="isDefined(podcastStore.show.links) ? podcastStore.show.links.externalRssFeed : ''" @change="podcastStore.show.links.externalRssFeed = $event.target.value" />
                    </IconField>
                </div>

                <div class="mb-3">
                    <label for="GoogleUrl" class="form-label">Google</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" id="GoogleUrl" placeholder="https://" :value="typeof podcastStore.show.links == 'undefined' ? '' : podcastStore.show.links.google" @change="podcastStore.show.links.google = $event.target.value" />
                    </IconField>
                </div>

                <div class="mb-3">
                    <label for="ItunesUrl" class="form-label">iTunes</label>

                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" id="ItunesUrl" placeholder="https://" :value="typeof podcastStore.show.links == 'undefined' ? '' : podcastStore.show.links.itunes" @change="podcastStore.show.links.itunes = $event.target.value" />
                    </IconField>
                </div>

                <div class="mb-3">
                    <label for="SpotifyUrl" class="form-label">Spotify</label>
                    <IconField iconPosition="left">
                        <InputIcon>
                            <i class="pi pi-link" />
                        </InputIcon>
                        <InputText type="url" class="w-100" id="SpotifyUrl" placeholder="https://" :value="typeof podcastStore.show.links == 'undefined' ? '' : podcastStore.show.links.spotify" @change="podcastStore.show.links.spotify = $event.target.value" />
                    </IconField>
                </div>

                <div class="mb-3">
                    <label for="CurrentSeason" class="form-label">Current Season</label>
                    <InputText type="number" class="form-control input__tiny" v-model="podcastStore.show.currentSeason" id="CurrentSeason" />
                </div>

                <div class="mb-3">
                    <InputSwitch v-model="podcastStore.show.isPublished" id="isPublished" />
                    <label for="isPublished" class="switch-label">Publish</label>
                </div>

                <p>
                    <Button :label="podcastStore.show.id ? 'Save Show' : 'Add Show'" @click="savePodcast()" x-title="Save (Ctrl+S or ⌘S)" />
                    <LogViewerThm label="Change Log" :filter="{ logTypeId: 5, referenceId: podcastStore.show.id }" v-if="podcastStore.show.id" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
//import { isDefined, useClipboard, useMagicKeys, whenever } from '@vueuse/core';
import { isDefined, useClipboard } from '@vueuse/core';
import { inject, ref, computed, onBeforeMount, onMounted } from 'vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useRoute, useRouter } from 'vue-router';
import { usePodcastStore } from '@/stores/PodcastStore';
import { useSiteStore } from '@/stores/SiteStore';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import MediaService from '@/service/MediaService';
import SiteGroupSelect from '@/components/SiteGroupSelectClient.vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import FileUpload from 'primevue/fileupload';
import ConfirmDialog from 'primevue/confirmdialog';
import useAuth0 from '@/use/Auth0';
import { seoUrl } from '@/utils/ThmUtils';

export default {
    name: 'PodcastShow',
    components: {
        ConfirmDialog,
        FileUpload,
        FroalaThm,
        SiteGroupSelect,
        LogViewerThm,
    },
    setup() {
        const { copy, copied } = useClipboard();
        const appSettings = inject('appSettings');
        const siteStore = useSiteStore();
        const podcastStore = usePodcastStore();
        const mediaService = new MediaService();
        //const keys = useMagicKeys();
        const route = useRoute();
        const { user } = useAuth0();
        const confirm = useConfirm();
        const toast = useToast();
        const router = useRouter();

        const isLoading = ref(false);

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => savePodcast());
        whenever(meta_s, () => savePodcast());
        */

        // Before Mount
        onBeforeMount(() => {
            siteStore.load();
        });

        // Mounted
        onMounted(async () => {
            if(route.params.showId) {
                return podcastStore.setShow(route.params.showId).then((res) => {
                    if(!podcastStore.show) {
                        toast.add({ severity: 'error', summary: 'Error Loading Podcast', detail: 'Show not found', life: 4000 });
                    } else {
                        siteStore.setSite(res.siteId);
                    }
                });
            }
        });

        /*
        whenever(keys['Ctrl+S'], () => {
            savePodcast();
        });
        */

        const feedUrl = computed(() => {
            if(!podcastStore.show.slug) return '';
            let url = '';
            if(podcastStore.show.siteId) {
                let site = siteStore.sites?.find(({ siteId }) => siteId === podcastStore.show.siteId);
                if(site && site.domain) url = `https://${site.domain}`;
            }
            return url + `/podcastfeed/${podcastStore.show.slug}`;
        });


        function filterSlug(el) {
            //el.value = el.value.toLowerCase().replaceAll(' ', '-').replace(/[^0-9a-z-_]/gi, '');
            el.value = seoUrl(el.value, true);
        }

        function uploadImage(event, elm) {
            if (!isDefined(event.files) || !event.files) return;
            isLoading.value = true;

            mediaService
                .uploadMediaObject(`media`, event.files[0])
                .then((res) => {
                    console.log('[uploadImage] response:', res);
                    let uploadedFileUrl = 'https://cdn.townhall.com/media/' + res[0].fullPath;
                    if (podcastStore.show.images == null) podcastStore.show.images = {};
                    podcastStore.show.images[elm] = uploadedFileUrl;
                })
                .catch((err) => {
                    //podcastStore.show.images[elm] = null;
                    if (console.log.hide) console.log(err);
                    //toast.add({ severity: 'error', summary: 'Upload Error', detail: err.message || err, life: 2000 });
                })
                .finally(() => {
                    isLoading.value = false;
                });
        }

        function deleteImage(elm) {
            //if(typeof podcastStore.show == 'undefined' || !podcastStore.show.images[elm]) return;
            if (!podcastStore.show.images[elm]) return;

            confirm.require({
                message: 'Are you sure you want to permanently delete this upload?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    isLoading.value = true;
                    mediaService.deleteMediaObject('media', podcastStore.show.images[elm]).then(() => {
                        podcastStore.show.images[elm] = null;
                    })
                    .catch((err) => {
                        if (console.log.hide) console.log(err);
                        //toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 2000 })
                    }).finally(() => {
                        podcastStore.show.images[elm] = null;
                        isLoading.value = false;
                    });
                },
            });
        }

        function savePodcast() {
            // Check required fields. Using this method because Vuelidate doesn't support object properties, only variables :/
            let requiredFields = [podcastStore.show.title, podcastStore.show.slug, podcastStore.show.siteId], isFormInvalid = false;
            requiredFields.forEach((elm) => {
                if(!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields', life: 4000 });
                return;
            }

            // Set show ID to zero for new, set undefined properties
            let showDefaults = { id: 0, images: {}, links: {} };
            podcastStore.show = { ...showDefaults, ...podcastStore.show };

            // Save active menu
            podcastStore.save().then((res) => {
                //console.log('Result:', res);
                // Reload shows
                podcastStore.load(true).then(() => {
                    if(res.isNew) {
                        //podcastStore.shows.push(res);
                        router.push(`/manage/podcasts/${res.id}`);
                    }
                });
                toast.add({ severity: 'success', summary: 'Success', detail: 'Podcast Saved', life: 3000 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving Podcast', detail: err.message || err, life: 4000 });
            });
        }

        return {
            appSettings,
            copy,
            copied,
            deleteImage,
            feedUrl,
            filterSlug,
            isDefined,
            isLoading,
            podcastStore,
            savePodcast,
            siteStore,
            uploadImage,
            user,
            userHasAccessToRole
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-body {
    .form-label {
        margin-bottom: 0.2rem;
        font-weight: 500;
    }
    .switch-label {
        padding: 0.15em 0 0 0.5em;
        vertical-align: top;
    }
    .required::after {
        content: '*';
        color: rgb(255, 0, 0);
    }
    /*
    .preview-image {
        max-height: 150px;
    }
    */
    .help {
        &, * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
    .input {
        &__tiny {
            width: 75px;
        }
    }
}
</style>
<style>
#podcast_show .p-image-preview-container img {
    max-height: 150px;
    max-width: 100%;
}
</style>
