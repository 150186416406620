<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">VueUse</div>

                    <div class="card-body">
                        <h4><a href="https://vueuse.org/core/useInfiniteScroll/" target="_blank">useInfiniteScroll</a></h4>

                        <p>Scroll the container below to trigger infinite scroll from the database. See browser console for event logging.</p>
                        <code>This doesn't currently work!</code>
                        <hr />

                        <div id="episodes" ref="container">
                            <div v-for="item in episodes" :key="item" class="episode item">
                                <strong>Episode ID:</strong> {{ item.id }}<br />
                                <strong>Title:</strong> {{ item.title }}<br />
                                <strong>URL:</strong> <a :href="item.audioUrl" target="_blank">{{ item.audioUrl }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import PodcastService from '@/service/PodcastService';
import { useInfiniteScroll } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted } from 'vue';

export default {
    name: 'useInfiniteScrollDemo',
    setup() {
        const podcastService = new PodcastService();
        const toast = useToast();
        const episodes = ref([]);
        const container = ref(null); // The scrollable container, see ref="container" above

        onMounted(() => {
            // Load page one of podcast episodes
            var params = { showId: 2, pageIndex: 1, pageSize: 20 };
            podcastService
                .getEpisodesPaged(params)
                .then((res) => {
                    episodes.value = res;
                    console.log('Page 1: ', res);
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Episodes', detail: err.message || err, life: 3000 });
                });
        });

        useInfiniteScroll(
            container,
            () => {
                // Load more - This never seems to get triggered?
                console.log('LOAD NEW PAGE');
                episodes.value.push(...episodes.value); // This should be replaced with an API call to get the next page of the results
            },
            { distance: 10 } // Not sure what this does
        );

        return { episodes };
    },
};
</script>

<style lang="scss" scoped>
#episodes {
    height: 500px;
    overflow-y: scroll;
}
div.card-body {
    div.episode {
        border-bottom: 1px solid #ccc;
        padding: 1em 0;
    }
}
</style>
