<template>
    <div class="col">
        <div class="card card-border-color card-border-color-primary">
            <div class="card-header card-header-divider">
                {{ title }} ({{ store.list[column].length }})
                <!--<i class="fa-regular fa-triangle-exclamation icon-column-modified" v-show="store.changedColumn.live" title="Column has changed"></i>-->
                <span class="icon-dot-modified" v-show="store.changedColumn[column]" v-tooltip.top="'Unsaved changes'"></span>
            </div>

            <div class="card-body live">
                <draggable class="drag-area" :list="store.list[column]" :group="{ name: 'g1' }" item-key="id" @change="itemDragged($event, column)" :disabled="deviceIsMobile()">
                    <template #item="{ element }">
                        <div :key="element.id" class="post-item">
                            <div class="border">
                                <div class="post-item__toolbar" :class="{ 'bg-warning text-dark': futureDate(element.datePublished) }">
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-up" @click="movePosition(element, column, 'up')"><i class="far fa-arrow-up"></i></button>
                                    <button class="btn-toolbar-button d-inline-block btn-toolbar-down" @click="movePosition(element, column, 'down')"><i class="far fa-arrow-down"></i></button>
                                    <select class="btn-toolbar-button" v-model="element.ordinalPosition" @change="changePosition($event, column, element)">
                                        <option v-for="(item, index) in store.list[column]" :key="item.id" v-bind:value="index + 1">{{ index + 1 }}</option>
                                    </select>
                                </div>

                                <div class="post-item__body d-flex">
                                    <div>
                                        <img :src="getAuthorImage(element)" class="me-2 w-auto" style="height: 60px;" />
                                    </div>

                                    <div class="mb-0">
                                        <dd>
                                            <dl class="m-0">
                                                <a :href="getPermalink(element)" target="_blank">{{ element.title }}</a>
                                            </dl>
                                            <dl class="m-0 list-item">
                                                <span>{{ element.author }}</span>
                                            </dl>
                                            <dl class="m-0 list-item">
                                                <span>{{ formatDateTime(element.datePublished, 'M/dd/yyyy hh:mm a') }} &#8226; Rank: {{ element.rank }}</span>
                                            </dl>
                                            <dl class="m-0">
                                                <span v-if="element.subscription > 1">
                                                    <img :src="getVipBadge(element.subscription)" alt="VIP" class="vip-badge" />
                                                </span>
                                            </dl>
                                        </dd>
                                    </div>
                                </div>
                            </div>
                            <Divider v-if="element.ordinalPosition == store.settings.limit" align="center">
                                <span class="p-tag">Columnists Widget Limit</span>
                            </Divider>
                        </div>
                    </template>
                </draggable>
                <template v-if="store.list['future'].length == 0">
                    <p>No future posts with in the selected duration.</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
//import { useRoute, useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
// import { onBeforeRouteLeave } from 'vue-router';
// import { useMagicKeys } from '@vueuse/core';
import { resizeImageUrl, getVipBadge } from '@/utils/ThmImageUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import { useStore } from '@/stores/ColumnistsWidgetStore';
//import { useSiteStore } from '@/stores/SiteStore';

//import { useToast } from 'primevue/usetoast';
import AdminService from '@/service/AdminService';
//import LayoutService from '@/service/LayoutService';
import Draggable from 'vuedraggable';
import useAuth0 from '@/use/Auth0';
import md5 from 'md5';

export default {
    name: 'ColumnistsWidgetList',
    components: {
        Draggable,
    },
    props: ['title', 'column'],
    setup() {
        const adminService = new AdminService();
        //const layoutService = new LayoutService();
        //const siteStore = useSiteStore();
        const subscriptionTypes = ref([]);
        const store = useStore();
        //const toast = useToast();
        const { user } = useAuth0();

        const now = ref(new Date());
        //const locationId = 4;

        // Before Mount
        onBeforeMount(() => {
            // store.getColumnists(props.column).catch((err) => {
            //     toast.add({ severity: 'error', summary: 'Error Loading columnists', detail: err.message || err, life: 3000 });
            // });
            subscriptionTypes.value = adminService.getSubscriptions();
        });
        function futureDate(dt) {
            let m = Date.parse(dt) >= now.value;
            //console.log(`Date: ${dt} - ${now.value} : ${m} `);
            return m;
        }
        function getPermalink(post) {
            return `https://townhall.com${post.url}`;
        }

        function changePosition(event, status, post) {
            store.movePosition(post, status, event.target.value);
        }

        function moveColumn(post, sourceColumn, targetColumn) {
            store.moveColumn(post, sourceColumn, targetColumn);
        }

        function movePosition(element, status, dir) {
            store.movePosition(element, status, dir);
        }

        function removePost(post, status) {
            store.remove(post, status);
        }

        function postChanged() {
            store.changedColumn[store.post.status] = true;
            store.changedPosts.push(store.post.postId);
            //store.setPostLockStatus(4, user.value);
        }

        function itemDragged(event, status) {
            // Set datePublished when made live
            if (status == 'live' && event.added) {
                //let idx = event.added.newIndex;
                store.posts[status][event.added.newIndex].datePublished = formatDateTime();
            }

            store.changedColumn[status] = true;
            store.renumber(status);
            //store.setPostLockStatus(4, user.value);
        }

        function getAuthorImage(item) {
            if(item.image) {
                if(item.image.startsWith('http')) {
                    return item.image;
                } else {
                    return resizeImageUrl(`https://media.townhall.com/cdn/hodl/${item.image}`, '58x54')
                }
            } else {
                return 'https://secure.gravatar.com/avatar/' + (item.email ? md5(item.email) : 'null') + '?s=150&d=mm&r=g'; // Gravatar support
            }
        }

        return {
            changePosition,
            deviceIsMobile,
            formatDateTime,
            futureDate,
            getAuthorImage,
            getPermalink,
            getVipBadge,
            itemDragged,
            moveColumn,
            movePosition,
            now,
            postChanged,
            removePost,
            store,
            user,
        };
    },
};
</script>

<style lang="scss" scoped>
div.card-header {
    /*
    .icon-column-modified {
        color: var(--red-500);
        vertical-align: middle;
    }
    */
    .icon-dot-modified {
        height: 1em;
        width: 1em;
        background-color: var(--pink-500);
        border-radius: 50%;
        display: inline-block;
        vertical-align: text-bottom;
    }
}
div.card-body {
    .post-item {
        //cursor: grab;
        margin-bottom: 0.75em;

        .d-flex.border {
            padding: 0 !important;
        }

        &__toolbar {
            //background-color: var(--teal-500);
            color: #fff;
            padding: 0.2em 0.4em;
            height: 2.1em;
            cursor: all-scroll;
            .btn-toolbar-button {
                border: none;
                margin-top: 0.1em;
                margin-right: 0.5em;
                //background-color: transparent;
                //background-color: var(--teal-50);
                //color: var(--teal-900);
                //color: #fff;
                padding: 0 0.3em;
                border-radius: 0.2em;
                font-size: 0.95em;
                height: 22px;

                &[disabled] {
                    color: #999 !important;
                }
            }
            .btn-toolbar-up,
            .btn-toolbar-down,
            .btn-toolbar-left,
            .btn-toolbar-right {
                font-weight: bold;
                width: 22px;
            }
            .btn-toolbar-up {
                margin-right: 0.2em;
            }
            .btn-toolbar-close {
                vertical-align: middle;
                //margin-top: -0.15em !important;
                cursor: pointer;
            }
            &.expiresSoon {
                background-color: var(--yellow-600);
            }
            &.isExternal {
                // Darken toolbar with gradient when article is external: https://stackoverflow.com/a/72300152/3799374
                background-image: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 2px, transparent 2px, transparent 5px) !important;
                background-size: 2px 2px;
            }
        }
        &__body {
            padding: 0.5em 0.5em 0.4em 0.5em;

            a.preview-image,
            span.preview-image {
                background: no-repeat center center / cover;
                display: inline-block;
                vertical-align: middle;
                width: 90px;
                height: 90px;
                margin-left: 0.2em;
            }
            h6 {
                a {
                    color: var(--blue-700);

                    &:hover {
                        color: var(--blue-800);
                        text-decoration: underline;
                    }
                }
                &,
                a {
                    font-size: 1em;
                    padding-bottom: 0.3em;
                }
            }
            dd {
                dl.list-item {
                    font-size: 0.85em;
                    list-style-type: none;
                    color: var(--gray-700);
                }
            }
        }
    }
}
div.card-body.live {
    .post-item {
        &__toolbar {
            background-color: #aede28;

            .btn-toolbar-button {
                background-color: var(--teal-50);
                color: var(--teal-900);
            }
        }
        &__toolbar.modified {
            background-color: var(--pink-500);
        }
    }
}
div.card-body.queue {
    .post-item {
        &__toolbar {
            background-color: var(--gray-500);

            .btn-toolbar-button {
                background-color: var(--gray-50);
                color: var(--teal-900);
            }
        }
        &__toolbar.modified {
            background-color: var(--pink-500);
        }
    }
}
#edit_post_form {
    .help {
        &,
        * {
            font-style: italic;
            color: #979797;
            font-size: 90%;
        }
    }
}

label.form-label {
    font-weight: 500;
}
img.vip-badge {
    height: 0.9em;
}
</style>
