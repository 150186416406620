import BaseService from './BaseService';

export default class CacheService extends BaseService {
    async purge(params) {
        // Object: { url|page: 'https://townhall.com/' }, { image: 'https://media.townhall.com/' }
        return await this.apiClient.delete(`/api/cache`, { data: params }).then((res) => {
            if (process.env.NODE_ENV !== 'production') console.log('📕 DELETE Cache', { ...params, ...res.data });
            if (res.data.message && res.data.message.includes('failed')) {
                // This usually occurs when the URL does not contain 'media.townhall.com'
                throw res.data.message;
            } else {
                return res.data;
            }
        });
    }
    async purgeDrafts(params) {
        // Object: { url|page: 'https://townhall.com/' }, { image: 'https://media.townhall.com/' }
        return await this.apiClient.delete(`/api/cache/drafts`, { data: params }).then((res) => {
            if (process.env.NODE_ENV !== 'production') console.log('📕 DELETE Cache', { ...params, ...res.data });
            if (res.data.message && res.data.message.includes('failed')) {
                // This usually occurs when the URL does not contain 'media.townhall.com'
                throw res.data.message;
            } else {
                return res.data;
            }
        });
    }

    async purgeUrl(url) {
        // String URL: 'https://'
        return this.purge({ url });
    }
}
