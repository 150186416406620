<template>

    <div class="container-fluid" id="upload_container">
        <div class="row">
            <div class="col-xs-12 col-md-6 pt-3">
                <ProgressBar mode="indeterminate" style="height: .5em" class="mb-3" v-show="isLoading" />
                <div id="upload_preview" class="pb-3" v-if="image.url">
                    <a :href="getMimeOrResizedImage(image.url, null, true)" target="_blank"><img :src="getMimeOrResizedImage(image.url)" class="mw-100" /></a>
                </div>
                <div class="mb-3">
                    <p class="help pt-1" v-show="!image.license.id">Please choose a license type before uploading. <i class="far fa-arrow-right"></i></p>
                    <FileUpload name="images[]" mode="basic" :auto="true" :fileLimit="1" :accept="uploadMimeTypes" :disabled="isLoading || !image.license.id" :customUpload="true" v-if="!image.path" @uploader="uploadImage($event)" @change="validateFields" /> <!--  @remove="deleteImage($event)" -->
                </div>

                <div v-if="image.url">
                    <Button label="Delete Image" severity="danger" outlined @click="deleteUploadedImage" />
                </div>
            </div>

            <div class="col-xs-12 col-md-6 pt-3">
                <div class="mb-3">
                    <label for="License" class="form-label required w-100">License</label>
                    <Dropdown v-model="image.license.id" :options="licenses" optionValue="id" optionLabel="name" placeholder="Select a License" id="License" :disabled="image.url && image.url.length > 0" />
                    <div class="help pt-1" v-show="[2, 12, 14].includes(image.license.id)">Uploaded images will expire after <strong>60 days</strong></div>
                    <div class="help pt-2" v-show="[3, 9, 11, 13, 15].includes(image.license.id)">Uploaded images will never expire</div>
                </div>
                <div class="mb-3">
                    <label for="Title" class="form-label required">Title</label>
                    <InputText type="text" class="form-control" v-model="image.title" id="Title" @keyup="validateFields" />
                </div>
                <div class="mb-3">
                    <label for="Description" class="form-label required">Description</label>
                    <Textarea v-model="image.description" rows="3" id="Description" class="d-block w-100" @keyup="validateFields" />
                </div>
                <div class="mb-3">
                    <label for="Credit" class="form-label required">Attribution/Credit</label>
                    <InputText type="text" class="form-control" v-model="image.credit" id="Credit" @keyup="validateFields" />
                </div>
                <div class="mb-3">
                    <label for="ImageType" class="form-label required w-100">Type</label>
                    <Dropdown v-model="image.type.id" :options="imageTypes" optionValue="value" optionLabel="name" placeholder="Select an Image Type" id="ImageType" @change="validateFields" />
                </div>
                <div class="mb-3">
                    <label for="Tags" class="form-label w-100 d-block">Tags</label>
                    <Chips v-model="image.tags" separator="," :allowDuplicate="false" style="w-100" />
                </div>
                <!--
                <div class="mb-3">
                    <label for="Caption" class="form-label w-100">Caption</label>
                    <InputText type="text" class="form-control" v-model="image.caption" id="Caption" />
                </div>
                -->
                <div class="mb-3" v-show="userHasAccessToRole('Image-Library-Admin')">
                    <InputSwitch v-model="image.isPublished" id="isPublished" />
                    <label for="isPublished" class="switch-label">Published</label>
                    <div class="help" v-show="!image.isPublished">Note: If not published, the image will be uploaded and publicly available but will not appear in search results. Make note of the URL before clicking save!</div>
                </div>

                <div class="mb-2">
                    <Button label="Save" class="me-2" @click="saveImage" :disabled="!isFormValid" /><!-- :disabled="!uploadIsValid" -->
                    <Button label="Clear" severity="secondary" outlined @click="clearImage(true)" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
//import { ref, onBeforeMount, onMounted, watch } from 'vue';
import { ref, inject, onBeforeMount } from 'vue';
import { formatDateTimeISO } from '@/utils/ThmDateTimeUtils';
import { getAttachmentMimeTypes, getMimeOrResizedImage } from '@/utils/ThmImageUtils';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import FileUpload from 'primevue/fileupload';
import MediaService from '@/service/MediaService';
import ImageService from '@/service/ImageService';
import AdminService from '@/service/AdminService';
import ProgressBar from 'primevue/progressbar';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'ImageLibraryUpload',
    emits: ['upload'],
    components: {
        FileUpload,
        ProgressBar
    },
    setup(_props, { emit }) {
        const appSettings = inject('appSettings');
        const mediaService = new MediaService();
        const imageService = new ImageService();
        const adminService = new AdminService();
        const { user, userRoles } = useAuth0();
        const toast = useToast();
        const router = useRouter();

        const newImage = ref({
            license: {
                //id: 2
            },
            source: {
                site: 'townhall.com',
                user: user.value.name
            },
            type: {},
            isPublished: true
        });
        const uploadMimeTypes = ref('image/*');
        const isLoading = ref(false);
        const imageTypes = ref([]);
        const isFormValid = ref(false);
        const lastUpload = ref({});
        const image = ref({ ...newImage.value });
        const licenses = ref([]);

        onBeforeMount(() => {
            imageTypes.value = imageService.getImageTypes();

            // Set MIME types allowed for upload
            let _role = appSettings.features.imageLibrary.uploadAttachmentsRole;
            if(_role && userHasAccessToRole(_role)) {
                let attachmentMimeTypes = getAttachmentMimeTypes('mime');
                uploadMimeTypes.value = [ uploadMimeTypes.value, ...attachmentMimeTypes ].join(',');
            }

            imageService.getImageLicenses(appSettings.siteId, userRoles).then((res) => {
                if(res && res.length) {
                    licenses.value = res;
                    if(res.length == 1) newImage.value.license.id = image.value.license.id = res[0].id;
                }
            });
        });

        // Get licenses by roles
        // const licenses = computed(() => {
        //     //let result = [];
        //     let lic = imageService.getImageLicenses(true);
        //     console.log('lic', lic);
        //     /*
        //     for(let idx = 0; idx < lic.length; idx++) {
        //         if(Array.isArray(lic[idx].roles)) {
        //             for(let k = 0; k < lic[idx].roles.length; k++) {
        //                 // If the user has one of the required roles, add it to the list of licenses
        //                 if(userRoles?.includes(lic[idx].roles[k])) result.push(lic[idx]);
        //             }
        //         } else {
        //             result.push(lic[idx]);
        //         }
        //     }
        //     */

        //     return lic;
        // });

        function validateFields() {
            isFormValid.value = true;
            var requiredFields = [image.value.title, image.value.description, image.value.credit, image.value.license.id, image.value.type.id, image.value.path];
            requiredFields.forEach((elm) => {
                if(!elm || (typeof elm == 'string' && !elm.trim())) isFormValid.value = false;
            });
        }

        function uploadImage(event) {
            if(typeof event.files == 'undefined' || !event.files || !image.value.license.id) return;
            isLoading.value = true;

            mediaService.uploadImage(event.files[0], image.value.license.id).then((res) => {
                image.value.path = res[0].urlFilePath;

                /*
                let ext = getFilenameExtension(res[0].urlFilePath);
                if(attachmentFileExtensions.value.includes(ext)) {
                    image.value.url = 'https://media2.townhall.com/cdn/hodl/' + res[0].urlFilePath;
                } else {
                    image.value.url = resizeImageUrl('https://media2.townhall.com/cdn/hodl/' + res[0].urlFilePath, '650x0');
                }
                */
                image.value.url = getMimeOrResizedImage('https://media2.townhall.com/cdn/hodl/' + res[0].urlFilePath, '650x0', true);

                //image.value.url = getImagePrefixByLicenseId(image.value.license.id, res[0].urlFilePath);
                validateFields();
                //console1.value = res;
            }).catch((err) => {
                console.error(err.message || err);
                toast.add({ severity: 'error', summary: 'Upload Error', detail: err.message || err, life: 2000 });
            }).finally(() => {
                isLoading.value = false;
            });
        }

        function saveImage() {
            image.value = {
                ...image.value,
                dateCreated: formatDateTimeISO(),
                datePublished: formatDateTimeISO()
            }

            // Set license.name
            let lic = licenses.value?.find(({ id }) => id == image.value.license.id);
            if(lic.name) image.value.license.name = lic.name;

            // Set type.name
            let typ = imageTypes.value?.find(({ value }) => value == image.value.type.id);
            if(typ.name) image.value.type.name = typ.name;

            // Convert tags from associated to indexed array
            if(image.value.tags) {
                let tags = image.value.tags;
                image.value.tags = [];
                for(let idx = 0; idx < tags.length; idx++) {
                    image.value.tags.push(tags[idx]);
                }
            }

            //console2.value = image.value;
            mediaService.saveImage(image.value).then((res) => {
                if(res && res['_id']) image.value.id = res['_id'];
                emit('upload', image.value);
                lastUpload.value = { ...image.value };
                if(image.value.id) adminService.log({ logTypeId: 16, referenceId: image.value.id, message: 'Image Added', content: JSON.stringify(image.value, null, 2), original: JSON.stringify(image.value, null, 2), language: 'json' });
                //image.value = newImage;
                clearImage();
                router.push({ query: null});
                toast.add({ severity: 'success', summary: 'Success', detail: 'Image saved successfully', life: 2000 });
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Saving to Elasticsearch', detail: err.message || err, life: 2000 });
            });
        }

        function deleteUploadedImage() {
            if(!image.value.license.id || !image.value.path) return;
            mediaService.deleteImage(image.value.path, image.value.license.id).then((res) => {
                console.log('Deleted:', res);
            });
            image.value.path = null;
            image.value.url = null;
            validateFields();
        }

        function clearImage(deleteImage = false) {
            if(deleteImage) deleteUploadedImage();
            image.value = { ...newImage.value };
            validateFields();
        }

        return {
            //console1,
            //console2,
            clearImage,
            deleteUploadedImage,
            getMimeOrResizedImage,
            image,
            imageTypes,
            isFormValid,
            isLoading,
            //lastUpload,
            licenses,
            //resizeImageUrl,
            saveImage,
            uploadImage,
            uploadMimeTypes,
            user,
            userHasAccessToRole,
            validateFields
        };
    },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
#upload_container {
    .p-chips {
        &, .p-chips-multiple-container {
            width: 100%;
        }
    }
}
</style>