<template>
    <div class="border rounded p-3 liveblog-thread" :id="'thread_' + props.thread.id">
        <div class="row g-2 header">
            <div class="col-auto">
                <span class="preview-image rounded" v-bind:style="{ backgroundImage: 'url(' + getAvatar(props.thread?.author) + ')' }"></span>
            </div>
            <div class="col ">
                <div class="datetime" :x-id="props.thread.id" :x-datetime="props.thread?.datePublished">
                    <template v-if="props.thread?.datePublished">
                        <!-- {{ formatDateTime(props.thread.datePublished, 'MMMM d, Y') }} -->
                        {{ dateTimeMoment(props.thread.datePublished) }}
                    </template>
                </div>
                <div class="byline">
                    <template v-if="props.thread?.author?.name">
                        {{ props.thread.author.name }}
                    </template>
                    <template v-else>
                        Post Deleted
                    </template>
                </div>
            </div>
            <div class="col-auto liveblog-thread__toolbar">
                <template v-if="instance.parent.type.name != 'LiveBlog_Thread'">
                    <template v-if="props.isPreview">
                        <Button @click="emit('clear')" icon="pi pi-times" severity="danger" outlined class="rounded" :style="{ display: deviceIsTouchEnabled() ? 'inline-flex' : 'none' }" />
                    </template>
                    <template v-else>
                        <Button @click="emit('quote', props.thread)" icon="fa-regular fa-quote-right" severity="danger" class="rounded" title="Quote" :style="{ display: deviceIsTouchEnabled() ? 'inline-flex' : 'none' }" />
                        <Button @click="editThread(props.thread)" icon="fa-regular fa-pen" severity="danger" class="rounded" title="Edit" :style="{ display: deviceIsTouchEnabled() ? 'inline-flex' : 'none' }" v-if="canEditThread" />
                        <span class="d-none d-sm-inline-block">
                        <Button @click="addToTimeline(props.thread)" icon="fa-regular fa-timeline" severity="danger" class="rounded" outlined title="Add to Timeline" :style="{ display: deviceIsTouchEnabled() ? 'inline-flex' : 'none' }" v-if="liveBlogStore.post.showTimeline && userHasAccessToRole('Editor')" />
                        </span>
                        <Button @click="deleteThread(props.thread)" icon="pi pi-trash" severity="danger" outlined class="rounded" title="Delete" :style="{ display: deviceIsTouchEnabled() ? 'inline-flex' : 'none' }" v-if="canEditThread" />
                    </template>
                </template>
            </div>
        </div>
        <div class="mt-3 body">
            <div v-html="props.thread.body"></div>
            <blockquote v-if="props.thread.childId">
                <LiveBlog_Thread :thread="getChildThread(props.thread.childId)" />
            </blockquote>
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance, onBeforeMount, onMounted } from 'vue';
import { DdhqResize, DdhqReceiveMessage, easternDateTimeToLocal } from '@/utils/ThmLiveBlogUtils';
import { deviceIsTouchEnabled } from '@/utils/ThmDeviceUtils';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useLiveBlogStore } from '@/stores/LiveBlogStore';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { dateTimeMoment } from '@/utils/ThmLiveBlogUtils';
import { getInitials } from '@/utils/ThmStringUtils';
import { useConfirm } from 'primevue/useconfirm';
//import { useTimeAgo } from '@vueuse/core';
//import { useToast } from 'primevue/usetoast';
import md5 from 'md5';

export default {
    name: 'LiveBlog_Thread',
    props: {
        thread: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        isPreview: {
            type: Boolean,
            default: false
        },
        index: Number,
    },
    emits: [ 'clear', 'delete', 'edit', 'quote', 'timeline' ],
    components: {
        //ConfirmDialog
    },
    setup(props, { emit }) {
        const liveBlogStore = useLiveBlogStore();
        const instance = getCurrentInstance();
        const confirm = useConfirm();
        //const toast = useToast();

        // watch(() => props.thread.body, () => {
        //     loadTwitterWidgets();
        // });

        onBeforeMount(() => {
            //authorStore.getCurrentAuthor(user.value);
        });

        onMounted(() => {
            loadTwitterWidgets();
            window.addEventListener('message', DdhqResize, false);
            window.addEventListener('message', DdhqReceiveMessage, false);
        });

        const canEditThread = computed(() => {
            if(userHasAccessToRole('Admin')) {
                return true;
            }
            return liveBlogStore.currentAuthor.authorId == props.thread.authorId;
        })

        const loadTwitterWidgets = () => {
            if(window.twttr) window.twttr.widgets.load();
        };

        function editThread(thread) {
            if(thread.childId) thread.childThread = getChildThread(thread.childId);
            emit('edit', props.thread);
        }

        function getChildThread(threadId) {
            let childThread = liveBlogStore.threads.find(obj => obj.id === threadId);
            if(!childThread) childThread = {
                id: 0,
                title: 'Deleted',
                body: null
            }
            return childThread;
        }

        function deleteThread(thread) {
            // if(console.log.hide) console.log(confirm);
            confirm.require({
                message: 'Are you sure you want to delete this thread?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    emit('delete', thread);
                    await liveBlogStore.deleteThread(thread.id);
                    //toast.add({ severity: 'success', summary: 'Deleted', detail: 'Thread deleted', life: 2500 });
                },
                reject: () => {
                    // Do nothing
                },
            });
        }

        function addToTimeline(thread) {
            emit('timeline', thread);
        }

        function getAvatar(author) {
            if(author?.image) {
                return author.image.startsWith('http') ? author.image : `https://media.townhall.com/cdn/hodl/${author.image}`;
            } else {
                let displayName = author?.nickName || author?.name;
                let initials = getInitials(displayName);
                if(initials) {
                    return `https://s.gravatar.com/avatar/${author?.email ? md5(author?.email) : 'null'}?s=150&r=pg&d=https%3A%2F%2Fcdn.townhall.com%2Fweb%2Favatars%2Fsquare%2Fgray%2F${initials.toLowerCase()}.png`
                    //return `https://ui-avatars.com/api/?background=8d949c&color=fff&format=png&name=${encodeURIComponent(displayName)}`;
                } else {
                    return `https://secure.gravatar.com/avatar/${author?.email ? md5(author?.email) : 'null'}?s=150&d=mm&r=g`;
                }
            }
        }

        return {
            addToTimeline,
            canEditThread,
            dateTimeMoment,
            deleteThread,
            deviceIsTouchEnabled,
            editThread,
            emit,
            easternDateTimeToLocal,
            formatDateTime,
            getAvatar,
            getChildThread,
            instance,
            liveBlogStore,
            props,
            userHasAccessToRole,
        };
    },
};
</script>
