import { getAppSettings } from '@/utils/ThmUtils';
import BaseService from './BaseService';

export default class LiveBlogService extends BaseService {
    async getConfig(site = null) {
        if(!site) {
            const appSettings = getAppSettings();
            site = appSettings.slug;
        }
        return await this.apiClient.get('/api/liveblog/config', { params: { site: site }}).then((res) => res.data);
    }
    // Posts
    async getPosts(params = {}) {
        return await this.apiClient.get('/api/liveblog/post', { params }).then((res) => res.data);
    }
    async getPost(postId) {
        //console.log('📗 GET Post ID:', postId);
        return await this.apiClient.get(`/api/liveblog/post/${postId}`).then((res) => res.data);
    }
    async savePost(post) {
        console.log('📙 Save:', post);
        return await this.apiClient.post('/api/liveblog/post', post).then((res) => res.data);
    }
    async deletePost(postId) {
        if(!postId) throw new Error('Missing or empty Post ID');
        console.log('📕 Delete:', postId);
        return await this.apiClient.delete(`/api/liveblog/post/${postId}`).then((res) => res.data);
    }
    // Threads
    async getThreads(params) {
        if(!params.postId) throw new Error('Missing or empty Post ID');
        return await this.apiClient.get(`/api/liveblog/post/${params.postId}/thread`, { params }).then((res) => res.data);
    }
    async getThread(threadId) {
        return await this.apiClient.get(`/api/liveblog/thread/${threadId}`).then((res) => res.data);
    }
    async saveThread(thread) {
        //console.log('thread:', thread);
        return await this.apiClient.post('/api/liveblog/thread', thread).then((res) => res.data);
    }
    async deleteThread(threadId) {
        if(!threadId) throw new Error('Missing or empty Thread ID');
        return await this.apiClient.delete(`/api/liveblog/thread/${threadId}`).then((res) => res.data);
    }
    // Widget Posts
    async getWidgetPosts(postId) {
        if(!postId) throw new Error('Missing or empty Post ID');
        return await this.apiClient.get(`/api/liveblog/post/${postId}/widget`).then((res) => res.data);
    }
    async saveWidgetPosts(postId, widgets = []) {
        if(!postId) throw new Error('Missing or empty Post ID');
        return await this.apiClient.put(`/api/liveblog/post/${postId}/widget`, widgets).then((res) => res.data);
    }
    // Groups
    async getGroup(groupId) {
        if(!groupId) throw new Error('Missing or Group ID');
        return await this.apiClient.get(`/api/liveblog/group/${groupId}`).then((res) => res.data);
    }
    // Threads
    async getTimeline(postId) {
        if(!postId) throw new Error('Missing or empty Post ID');
        return await this.apiClient.get(`/api/liveblog/post/${postId}/timeline`).then((res) => res.data);
    }
    async saveTimeline(postId, timeline = []) {
        if(!postId) throw new Error('Missing or empty Post ID');
        return await this.apiClient.put(`/api/liveblog/post/${postId}/timeline`, timeline).then((res) => res.data);
    }
}
