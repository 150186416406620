<template>
    <AppTopBar :version="version" :hasNewVersion="hasNewVersion" />
    <div class="container-fluid">
        <div class="row">
            <!-- <AppMenu /> -->
            <main class="col-12 ms-sm-auto pt-3">
                <Toast position="top-right" />
                <router-view />
            </main>
            <AppFooter />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { watchThrottled } from '@vueuse/core';
//import { useIntervalFn } from '@vueuse/core';
import { useRoute } from 'vue-router';
//import NavMenu from './components/NavMenu.vue'
import AppTopBar from './AppTopbar.vue';
//import AppProfile from './AppProfile.vue';
//import AppMenu from "./AppMenu.vue";
import AppFooter from './AppFooter.vue';
import { deviceIsMobile, deviceIsTouchEnabled } from '@/utils/ThmDeviceUtils';
import { useSiteStore } from '@/stores/SiteStore';

export default {
    name: 'App',
    components: {
        AppTopBar: AppTopBar,
        //'AppProfile': AppProfile,
        // AppMenu: AppMenu,
        AppFooter: AppFooter,
    },
    setup() {
        const siteStore = useSiteStore();
        const route = useRoute();

        const build = ref(null);
        const version = ref(null);
        const hasNewVersion = ref(false);
        const checkForUpdated = true;

        // Watch for route changes
        watchThrottled(
            () => route.path, (_path, _oldPath) => {
                if(checkForUpdated && _path != _oldPath && process.env.NODE_ENV != 'development') {
                    siteStore.getBuild(true).then((bld) => {
                        //console.log('Build check:', bld);
                        if(version.value && bld.build != version.value) hasNewVersion.value = true;
                    });
                }
            },
            { throttle: 20000 }
        );

        onMounted(() => {
            // Add custom CSS tags to body. Would be nice to detect "phone" vs. "tablet" as well. https://web.wurfl.io/
            if(deviceIsMobile()) document.body.classList.add('mobile');
            if(deviceIsTouchEnabled()) document.body.classList.add('touch');

            // Get version
            siteStore.getBuild().then((bld) => {
                version.value = bld.build;
            });
        });

        return {
            build,
            hasNewVersion,
            siteStore,
            version,
        }
    }
}
</script>

<style lang="scss">
@import './assets/scss/main.scss';
</style>
