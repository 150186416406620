<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <ListDemo :items="items" @edit-item="editItem" />
            <EditFormDemo :item="selectedItem" />
        </div>
    </form>
</template>

<script>
import ListDemo from './components/ListDemo.vue';
import EditFormDemo from './components/EditFormDemo.vue';

export default {
    name: 'FormComponentDemo',
    data() {
        return {
            selectedItem: {},
            items: [
                // Example data; normally, this would be populated from the database
                {
                    id: 1,
                    title: 'Paper Toilet',
                    description: "Just because stores are sold out of toilet paper doesn't mean you have to live without.",
                    link: 'http://papertoilet.com/',
                },
                {
                    id: 2,
                    title: 'Internet Live Stats',
                    description: "This site will tell you — in real time — exactly how many people are actually on the internet. Spoiler alert: It's everyone.",
                    link: 'https://www.internetlivestats.com/',
                },
                {
                    id: 3,
                    title: 'Zoom Quilt',
                    description: 'An animation that infinitely zooms in to reveal new pictures.',
                    link: 'https://zoomquilt.org/',
                },
                {
                    id: 4,
                    title: 'Free Hat',
                    description: 'Fill out a survey to get a free hat.',
                    link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                },
            ],
        };
    },
    methods: {
        editItem(item) {
            this.selectedItem = item;
            console.log('[edit]', item);
        },
    },
    components: {
        ListDemo,
        EditFormDemo,
    },
};
</script>
