import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import MediaService from '@/service/MediaService';
import { isEmptyOrNull } from '@/utils/ThmStringUtils';
import { formatDateTimeISO } from '@/utils/ThmDateTimeUtils';
import { getUserSettings } from '@/utils/ThmUtils';
import useAuth0 from '@/use/Auth0';
import md5 from 'md5';

const adminService = new AdminService();
const mediaService = new MediaService();

export const useAuthorStore = defineStore('Authors', {
    state: () => ({
        loading: false,
        types: [],
        authors: null,
        author: {},
        currentAuthor: { authorId: 0 },
        postCounts: [],
        newAuthor: {
            authorId: 0,
            firstName: null,
            lastName: null,
            fullName: null,
            sortName: null,
            nickName: null,
            bio: null,
            headerShirtTail: null,
            shirtTail: null,
            plug: null,
            slug: null,
            siteGroup: 0,
            siteGroups: null,
            authorTypeId: 0,
            authorTypeName: null,
            dateCreated: null,
            email: null,
            imageObject: null,
            social: {
                facebook: null,
                twitter: null,
                linkedIn: null,
            },
            totalRows: null,
            rank: 0,
        },
        userConfig: getUserSettings()
    }),
    getters: {
        totalRows() {
            return this.authors?.length && typeof this.authors[0].totalRows != 'undefined' ? this.authors[0].totalRows : 0;
        },
        hasCurrentAuthor() {
            return typeof this.currentAuthor == 'object' && this.currentAuthor.authorId && this.currentAuthor.authorId > 0;
        }
    },
    actions: {
        async load() {
            if(!this.types.length) {
                this.loading = true;
                //this.author = this.newAuthor;
                await adminService
                    .getAuthorTypes()
                    .then((res) => {
                        //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Author types', res);
                        this.types = res;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        async getAuthor(authorId) {
            let author = null;
            // See if author exists in paged author results (if any). This saves an API lookup.
            if (this.authors?.length) {
                author = this.authors.find((obj) => obj.authorId == authorId);
            }

            // If not, fetch by author ID
            if (author && author.authorId) {
                return author;
            } else {
                this.loading = false;
                return adminService.getAuthorById(authorId).then((res) => {
                    return res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async setAuthor(authorId) {
            if(this.authors) this.author = this.authors.find((obj) => obj.authorId == authorId);

            if (this.author && this.author.authorId) {
                return this.author;
            } else {
                this.loading = true;
                return await this.getAuthor(authorId)
                    .then((res) => {
                        this.author = res;
                        return this.author;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        async getAuthorsPage(params, pageSize = 15) {
            this.loading = true;
            let defaults = { pageSize: pageSize };

            return await adminService.getAuthorsFiltered({ ...defaults, ...params }).then((res) => {
                return res;
            }).then(async (authors) => {
                if(!this.postCounts.length) {
                    await adminService.getAuthorsPostCounts().then((res) => {
                        this.postCounts = res;
                    });
                }

                let authorsWithPostCounts = authors.map(item => {
                    let found = this.postCounts.find(innerItem => innerItem.authorId === item.authorId);
                    return found ? {...item, ...found} : {...item};
                });
                this.authors = authorsWithPostCounts;
                return authorsWithPostCounts;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });

            /*
            return await adminService.getAuthorsFiltered({ ...defaults, ...params }).then((res) => {
                this.authors = res;
                return this.authors;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
            */
        },
        async save() {
            let original = {};
            this.loading = true;
            let isNew = !this.author.authorId || this.author.authorId < 1;
            let trySlug = this.author.slug || (this.author.firstName || '') + ' ' + (this.author.lastName || '');
            trySlug = trySlug.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');

            return await adminService.getAuthorById(this.author.authorId || 0).then((res) => {
                // Get original author for logging
                original = res;
                delete original.dateCreated;
                delete original.totalRows;
                return adminService.getAuthorsSlug(trySlug);
            }).then((authorSlug) => {
                // Generate and set author slug
                if(!this.author.slug) this.author.slug = authorSlug;
                this.author.dateCreated = formatDateTimeISO(this.author.dateCreated);
                this.author.rank = this.author.rank || 0;
                this.author.authorTypeId = this.author.authorTypeId || 0;
                delete this.author.totalRows;
                //delete this.author.dateCreated;

                // Temp hack to convert author image to absolute URL
                if(this.author.imageObject && !this.author.imageObject.startsWith('http')) this.author.imageObject = 'https://media.townhall.com/cdn/hodl/' + this.author.imageObject;

                return adminService.saveAuthor(this.author);
            }).then((res) => {
                // Save author
                delete res.dateCreated;
                delete res.totalRows;
                let fullName = (res.firstName || '') + ' ' + (res.lastName || '');
                adminService.log({ logTypeId: 3, referenceId: res.authorId, message: (isNew ? 'Added: ' : 'Modified: ') + fullName.trim(), content: JSON.stringify(res, null, 2), original: isNew ? null : JSON.stringify(original, null, 2), language: 'json' });
                res.isNew = isNew;
                this.author = res;
                return res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async delete() {
            if (!this.author) return;
            this.loading = true;

            return await adminService
                .deleteAuthor(this.author.authorId)
                .then(() => {
                    let fullName = (this.author.firstName || '') + ' ' + (this.author.lastName || '');
                    adminService.log({ logTypeId: 3, referenceId: this.author.authorId, message: 'Delete: ' + fullName.trim(), content: null, original: JSON.stringify(this.author, null, 2), language: 'json' });
                    // Delete author image
                    if(this.author.imageObject) {
                        //mediaService.deleteMediaObject('media', this.author.imageObject); // This should probably have a try/catch
                        mediaService.deleteAuthorImage(this.author.imageObject).then((res) => {
                            console.log('🗑 Deleted:', res);
                        });
                    }
                    this.author = {};
                    return this.author;
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getAvatar(author) {
            /*
            if(author.imageObject && typeof author.imageObject != 'undefined' && author.imageObject && author.imageObject.includes('https')) {
                return author.imageObject;
            } else {
                return 'https://secure.gravatar.com/avatar/' + md5(author.email || '') + '?s=100&d=mm&r=g';
            }
            */
            if(!isEmptyOrNull(author.imageObject)) {
                return author.imageObject.startsWith('http') ? author.imageObject : `https://media.townhall.com/cdn/hodl/${author.imageObject}`;
            } else {
                return 'https://secure.gravatar.com/avatar/' + (author.email ? md5(author.email) : 'null') + '?s=150&d=mm&r=g'; // Gravatar support
                //return 'https://secure.gravatar.com/avatar/null?s=150&d=mm&r=g'; // Returns "mystery man" image
            }
        },
        setAuthorImage(val) {
            this.author.imageObject = val;
        },
        async getCurrentAuthor(currentUser = null) {
            //console.log('currentUser', currentUser);
            if(!currentUser) {
                const { user } = useAuth0();
                currentUser = user.value;
            }
            if(typeof this.currentAuthor == 'object' && this.currentAuthor.email == currentUser.email) return this.currentAuthor;

            return await adminService.getAuthorsFiltered({ email: currentUser.email, pageSize: 1 }).then((res) => {
                if(res.length > 0) {
                    this.currentAuthor = res[0];
                } else {
                    this.currentAuthor = { authorId: 0 };
                }
                return this.currentAuthor;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
});
