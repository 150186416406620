<template>
    <!-- Filter bar -->
    <div class="row mb-3">
        <div class="col">
            <section class="card">
                <div class="card-body container-fluid">
                    <div class="row filter-row g-2">

                        <div class="col-8 col-md-10">
                            <!--<InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search... (Ctrl+K or ⌘K)" v-on:keyup.enter="updateFilter" autofocus />-->
                            <InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search..." v-on:keyup.enter="updateFilter" autofocus />
                        </div>
                        <div class="col-4 col-md-2">
                            <Button label="" icon="far fa-search" iconPos="right" @click="updateFilter" />
                            <Button label="" icon="far fa-times" iconPos="right" severity="secondary" outlined class="ms-2" @click="clearFilter" />
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="row mb-3">
        <SeoList />
        <SeoEditor />
    </div>
</template>

<script>
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import SeoEditor from './SeoEditor.vue';
import SeoList from './SeoList.vue';

export default {
    name: 'SeoManager',
    components: {
        SeoList,
        SeoEditor
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        //const keys = useMagicKeys();

        const filter = ref({});

        //whenever(keys['Ctrl+K'], () => document.getElementById('SearchText').select());
        //whenever(keys['Meta+K'], () => document.getElementById('SearchText').select());

        onMounted(() => {
            // Set filter bar based on query string values
            const query = Object.assign({}, route.query);
            filter.value = query;
        });

        /*
        whenever(keys['Meta+K'], () => {
            document.getElementById('SearchText').select();
        });
        */

        function updateFilter() {
            router.push({ query: filter.value });
        }

        function clearFilter() {
            filter.value = {};
            router.push({ query: filter.value });
        }

        return { clearFilter, filter, route, updateFilter }
    }
};
</script>
