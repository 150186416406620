<template>
    <Dropdown v-model="model" :options="subscriptionLevel" optionLabel="label" optionValue="value" :showClear="true" placeholder="Subscription" />
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import AdminService from '@/service/AdminService';

export default {
    name: 'SubscriptionsDropdown',
    emits: ['update:modelValue'],
    props: ['modelValue'],
    setup(_props, { emit }) {
        const adminService = new AdminService();
        const subscriptionLevel = new ref(null);

        onMounted(() => {
            subscriptionLevel.value = adminService.getSubscriptions();
        });

        const model = computed({
            get() {
                return _props.modelValue;
            },
            set(value) {
                emit('update:modelValue', value);
            }
        });

        return {
            model,
            subscriptionLevel
        }
    }
}
</script>
