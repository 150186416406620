<template>
    <p>Froala 1:</p>
    <FroalaOld :tag="'textarea'" v-model="model1" />

    <hr />

    <p>Froala 2:</p>
    <FroalaOld :tag="'textarea'" v-model="model2" />
</template>

<script>
import FroalaOld from '@/components/Froala/FroalaOld.vue';

export default {
    name: 'EditorImageDemo',
    data() {
        return {
            // config: {
            //     events: {
            //         initialized: function () {
            //             console.log('initialized')
            //         },
            //     },
            // },
            model1: 'Hello world',
            model2: null
        };
    },
    components: {
        FroalaOld,
    },
    methods: {},
};
</script>
