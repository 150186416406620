<template>
    {{ formattedDateTime }}
</template>

<script>
import { computed } from 'vue';
import { isDefined } from '@vueuse/core';
import { formatDateTime, timeAgo } from '../utils/ThmDateTimeUtils';

export default {
    name: 'DateFormat',
    props: {
        datetime: {
            type: String,
            default: new Date().toString(),
            //required: true,
        },
        format: {
            type: String,
            default: 'MM/dd/yyyy hh:mm a',
        },
        daysAgo: {
            type: [Boolean, Number], // Example: True means all dates, number = day threshold
            default: false
        }
    },
    setup(_props) {
        const formattedDateTime = computed(() => {
            if(!_props.datetime) return 'n/a';
            if( isDefined(_props.datetime) ) {
                if(_props.daysAgo) {
                    // This return "X hours/minutes ago" if less than 1 day ago
                    return timeAgo(_props.datetime, _props.format, _props.daysAgo);
                } else {
                    // This simply returns the formatted date/time
                    return formatDateTime(_props.datetime, _props.format);
                }
            }
            return _props.datetime;
        });

        return { formattedDateTime }
    }
}
</script>
