<template>
    <ConfirmDialog />

    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-8">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">Alert Bar</div>

                    <div class="card-body">
                        <div class="mb-3">
                            <label for="Title" class="form-label">Title / Prefix</label>
                            <InputText type="text" class="form-control" v-model="alertBarStore.alert.title" id="Title" placeholder="BREAKING" />
                        </div>

                        <div class="mb-3">
                            <label for="Message" class="form-label">Message</label>
                            <InputText type="text" class="form-control" v-model="alertBarStore.alert.message" id="Message" />
                        </div>

                        <div class="mb-3">
                            <label for="Message" class="form-label">Button Text</label>
                            <InputText type="text" class="form-control" v-model="alertBarStore.alert.callToAction" id="Message" placeholder="Read More" style="max-width: 250px;" />
                            <!--<span class="help w-100">Example: Read More</span>-->
                        </div>

                        <div class="mb-3">
                            <label for="Link" class="form-label">Link</label>
                            <IconField iconPosition="left">
                                <InputIcon>
                                    <i class="pi pi-link" />
                                </InputIcon>
                                <InputText type="url" class="w-100" v-model="alertBarStore.alert.link" id="Link" placeholder="https://" />
                            </IconField>
                        </div>

                        <p>
                            <Button label="Save" @click="updateAlert()" x-title="Save (Ctrl+S or ⌘S)" />
                            <LogViewerThm label="Change Log" :filter="{ logTypeId: 7 }" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-4 actions">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">Publish</div>

                    <div class="card-body">
                        <div class="mb-3">
                            <label for="Hours" class="form-label">Duration</label>
                            <Dropdown id="Hours" v-model="alertBarStore.alert.hours" :options="durations" optionLabel="label" optionValue="value" class="d-flex" />
                        </div>

                        <div class="mb-3">
                            <label for="StartDate">Start Date/Time ({{ getTimezone() }})</label>
                            <div class="input-group">
                                <Calendar id="StartDate" v-model="alertStartDate" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" showButtonBar />
                                <!--<CalendarThm id="StartDate" v-model="alertBarStore.alert.startDate" :show-time="true" :show-seconds="false" hour-format="12" :show-icon="true" />-->
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="isEnabled">Status</label>
                            <div class="form-check2 form-switch2">
                                <InputSwitch v-model="alertBarStore.alert.isEnabled" id="isEnabled" />
                                <label for="isEnabled" class="switch-label">Enabled</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="row mb-3 d-none d-md-block">
        <div class="col-xs-12">
            <div id="alertbar_preview" :class="appSettings.slug">
                <a :href="alertBarStore.alert.link" target="_blank" class="btn btn-link" v-if="alertBarStore.alert.link">{{ alertBarStore.alert.callToAction || '??' }}</a>
                <span class="btn btn-link" v-else>{{ alertBarStore.alert.callToAction || '??' }}</span>
                <span class="preview-label fw-bold">{{ alertBarStore.alert.title }}</span>
                <a :href="alertBarStore.alert.link" target="_blank" v-if="alertBarStore.alert.link">{{ alertBarStore.alert.message || '&ndash;' }}</a>
                <span v-else>{{ alertBarStore.alert.message }}&nbsp;</span>
            </div>
        </div>
    </div>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
import LogViewerThm from '@/components/LogViewerThm.vue';
//import CalendarThm from '@/components/CalendarThm.vue';
import { useAlertBarStore } from '@/stores/AlertBarStore';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { ref, computed, inject, onBeforeMount } from 'vue';
import { getTimezone } from '@/utils/ThmDateTimeUtils';

export default {
    name: 'AlertBar',
    components: {
        //CalendarThm,
        ConfirmDialog,
        LogViewerThm,
    },
    setup() {
        const appSettings = inject('appSettings');
        const alertBarStore = useAlertBarStore();
        const toast = useToast();

        const durations = ref([
            { label: 'Never Expire', value: 0 },
            { label: '1 Hour', value: 1 },
            { label: '2 Hours', value: 2 },
            { label: '3 Hours', value: 3 },
            { label: '4 Hours', value: 4 },
            { label: '5 Hours', value: 5 },
            { label: '6 Hours', value: 6 },
            { label: '7 Hours', value: 7 },
            { label: '8 Hours', value: 8 },
            { label: '12 Hours', value: 12 },
            { label: '24 Hours', value: 24 },
            { label: '48 Hours', value: 48 },
        ]);

        const alertStartDate = computed({
            get() {
                return alertBarStore.alert.startDate == null ? null : new Date(alertBarStore.alert.startDate);
            },
            set(newValue) {
                //alertBarStore.alert.startDate = newValue.toLocaleString('en-US');
                alertBarStore.alert.startDate = formatDateTime(newValue, 'yyyy-MM-dd HH:mm:ss');
            },
        });

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => updateAlert());
        whenever(meta_s, () => updateAlert());
        */

        // Before Mount
        onBeforeMount(() => {
            alertBarStore.load().catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading Alert', detail: err.message || err, life: 3000 });
            });
        });

        function updateAlert() {
            alertBarStore
                .save()
                .then(() => {
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Alert Bar Saved', life: 3000 });
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving Alert', detail: err.message || err, life: 3000 });
                });
        }

        return {
            alertBarStore,
            alertStartDate,
            appSettings,
            durations,
            getTimezone,
            updateAlert
        };
    },
};
</script>

<style lang="scss" scoped>
label[for='StartDate'] {
    padding-bottom: 0.5em;
}
.switch-label {
    padding: 0.15em 0 0 0.5em;
    vertical-align: top;
}
#alertbar_preview {
    background-color: #9f2629;
    color: #fff;

    &.redstate { background-color: #B81F24; }

    span,
    a {
        padding: 0.5rem 0.75rem;
        display: inline-flex;
        color: #fff;
    }

    a:not(.btn-link) {
        &:hover {
            text-decoration: underline;
        }
    }

    .btn-link {
        color: #fff;
        border: 1px solid #fff;
        padding: 0.1rem 0.75rem;
        margin: 0.3em 0.5em 0 0;
        text-decoration: none;
        float: right;

        &:hover {
            color: #9f2629;
            background-color: #fff;
        }
    }

    .preview-label {
        padding: 0rem 0.75rem;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
}
</style>
