/**
 * Embed Code plugin for Froala
 * @see https://froala.com/wysiwyg-editor/examples/custom-popup/
 * @see https://townhallmedia.visualstudio.com/th2/_git/th2?path=/Source/UI/Web/Mvc/Support/Resources/js/froala/socialembed.js&_a=contents&version=GBmaster
 */

import FroalaEditor from 'froala-editor/js/froala_editor.min';

// Define popup template
FroalaEditor.POPUP_TEMPLATES['embedCodePlugin.popup'] = '[_BUTTONS_][_CUSTOM_LAYER_]';

// Define popup buttons
Object.assign(FroalaEditor.DEFAULTS, { popupButtons: ['popupClose'] });

// Create plugin
FroalaEditor.PLUGINS.embedCodePlugin = function (editor) {
    // Create embed code popup
    function initPopup() {
        // Create the list of buttons. Note: This plugin doesn't have any at the top of the popup/overlay
        var popup_buttons = '';
        /*
        if (editor.opts.popupButtons.length > 1) {
            popup_buttons += '<div class="fr-buttons">';
            popup_buttons += editor.button.buildList(editor.opts.popupButtons);
            popup_buttons += '</div>';
        }
        */

        // Build popup template
        var template = {
            buttons: popup_buttons,
            custom_layer:
                '<div class="fr-embedcode-embed-layer fr-layer fr-active" id="fr-embedcode-embed-layer-' +
                editor.id +
                '"><div class="fr-input-line"><textarea id="fr-embedcode-embed-layer-text' +
                editor.id +
                '" type="text" placeholder="' +
                editor.language.translate('Embedded Code') +
                '" tabIndex="1" aria-required="true" rows="5"></textarea></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="embedCodeInsert" tabIndex="2" role="button">' +
                editor.language.translate('Insert') +
                '</button></div></div>',
        };

        // Create popup
        var $popup = editor.popups.create('embedCodePlugin.popup', template);

        return $popup;
    }

    // Show embed code popup
    function showPopup() {
        // Get the popup object defined above
        var $popup = editor.popups.get('embedCodePlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container
        editor.popups.setContainer('embedCodePlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup
        // editor.popups.refresh('embedCodePlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="embedCodeButton"]');

        // Set the popup's position
        var left = $btn.offset().left + $btn.outerWidth() / 2;
        var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        // Show the custom popup. The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show('embedCodePlugin.popup', left, top, $btn.outerHeight());
    }

    // Hide the custom popup
    function hidePopup() {
        editor.popups.hide('embedCodePlugin.popup');
    }

    // Methods visible outside the plugin
    return {
        showPopup: showPopup,
        hidePopup: hidePopup,
    };
};

// Define an icon and command for the button that opens the custom popup
// Non-visual list of available icons: https://github.com/froala/wysiwyg-editor/issues/3478#issuecomment-539497338
FroalaEditor.DefineIcon('embedCodeIcon', { NAME: 'insertEmbed', SVG_KEY: 'insertEmbed' });
FroalaEditor.RegisterCommand('embedCodeButton', {
    title: 'Embed Code',
    icon: 'embedCodeIcon',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    plugin: 'embedCodePlugin',
    callback: function () {
        this.embedCodePlugin.showPopup();
    },
});

// Define custom popup close button icon and command.
//FroalaEditor.DefineIcon('popupClose', { NAME: 'times', SVG_KEY: 'times'});
FroalaEditor.RegisterCommand('popupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function () {
        this.embedCodePlugin.hidePopup();
    },
});

// Define custom popup 1.
//$.FroalaEditor.DefineIcon('popupButton1', { NAME: 'bell-o' });
FroalaEditor.RegisterCommand('embedCodeInsert', {
    title: 'Insert Embed Code',
    undo: true,
    focus: true,
    callback: function () {
        var textArea = this.$box.find('.fr-embedcode-embed-layer textarea');

        // START: Transform
        //var _content = textArea.val().replaceAll(/<a (.*)>/ig, '<a target="_blank" $1>'); // Open links in a new window: https://stackoverflow.com/a/46354993/3799374
        var _content = textArea.val().replaceAll('<a href=', '<a target="_blank" href=');
        //console.log('_content', _content);
        textArea.val(_content);
        // END: Transform

        this.html.insert(textArea.val());
        textArea.val('');
        this.embedCodePlugin.hidePopup();
        this.events.focus();
    },
});
