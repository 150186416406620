<template>
    <div class="card mb-3">
        <div class="card-body">

            <div class="row filter-row g-2">
                <div class="col-sm-12 col-lg-3">
                    <Dropdown id="AuthorTypeFilter" v-model="filter.range" :options="dateRanges" optionLabel="label" optionValue="value" placeholder="Date Ranges" class="d-flex" :showClear="true" @change="updateFilterDates($event)" />
                </div>
                <div class="col-sm-12 col-lg-auto">
                    <Calendar v-model="fromDate" :showButtonBar="true" :showIcon="true" placeholder="Date" dateFormat="mm/dd/yy" hourFormat="12" class="me-2" xstyle="max-width: 155px" @date-select="dateSelected($event)" />to
                    <Calendar v-model="toDate" :showButtonBar="true" :showIcon="true" placeholder="Date" dateFormat="mm/dd/yy" hourFormat="12" xstyle="max-width: 155px" @date-select="dateSelected($event)" />
                </div>
                <div class="col-sm-12 col-lg-3">
                    <Button label="" icon="pi pi-refresh" @click="updateFilter" />
                    <Button label="" icon="far fa-times" class="ms-2" outlined @click="clearFilter" />
                    <Button icon="pi pi-download" iconPos="right" label="Export" class="ms-2" severity="secondary" @click="exportCSV('dt', $event)" />
                </div>
            </div>
        </div>
    </div>

    <div class="card card-border-color card-border-color-primary">
        <div class="card-header card-header-divider">
            Posts Report
            <SpinnerThm class="spinner" size="1em" v-if="loading" />
        </div>

        <div class="card-body pb-4">
            <DataTable :value="data" ref="dt" v-model:expandedRows="expandedRows" dataKey="authorId" tableStyle="max-width: 50rem" tableClass="posts-count-report report-theme-night" :exportFilename="formatDateTime(null, 'yyyy-MM-dd') + '_' + appSettings.abbrev.toUpperCase() + '-AuthorPostsReport'" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
                <Column expander style="width: 5rem" />
                <Column field="nickName" sortable header="Name">
                    <template #body="slotProps">
                        <router-link :to="{ name: 'Posts', query: { authorId: slotProps.data.authorId } }">
                            {{ slotProps.data.nickName || slotProps.data.authorId }}
                        </router-link>
                    </template>
                </Column>
                <Column field="free" sortable header="Free" class="text-center"></Column>
                <Column field="vipTotal" sortable header="VIP" class="text-center"></Column>
                <Column field="grandTotal" sortable header="Total" class="text-center"></Column>
                <ColumnGroup type="footer">
                    <Row>
                        <Column footer="Totals:" footerStyle="text-align:right" />
                        <Column :footer="freeTotal" class="text-center" />
                        <Column :footer="vipTotal" class="text-center" />
                        <Column :footer="grandTotal" class="text-center" />
                    </Row>
                </ColumnGroup>
                <template #expansion="slotProps">
                    <div class="px-3 pb-0 pt-3 text-white fst-italic">
                        <div v-if="typeof slotProps.data.detail == 'undefined'">
                            Loading...
                        </div>
                        <div v-else-if="!slotProps.data.detail?.length">
                            No results found
                        </div>
                        <div v-else>
                            <DataTable :value="slotProps.data.detail" :ref="setElementRef('authorPostsReportDetail_' + slotProps.data.authorId)" :exportFilename="formatDateTime(null, 'yyyy-MM-dd') + '_' + appSettings.abbrev.toUpperCase() + '-APR-' + slotProps.data.fullName">
                                <Column field="day" header="Day" :exportHeader="slotProps.data.fullName" sortable>
                                    <template #body="detailProps">
                                        {{ formatDateTime(detailProps.data.day, 'MM/dd/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="free" header="Free" sortable class="text-center"></Column>
                                <Column field="vipTotal" header="VIP" sortable class="text-center"></Column>
                                <Column field="grandTotal" header="Total" sortable class="text-center"></Column>
                            </DataTable>
                            <!--<Button icon="pi pi-download" iconPos="right" label="Export" class="ms-2" severity="secondary" @click="exportCSV(slotProps.data.authorId, $event)" />-->
                            <div class="d-block float-end fst-italic">
                                <Button icon="pi pi-download" link label="Export" class="small text-light p-0" @click="exportCSV(slotProps.data.authorId, $event)" />
                            </div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
//import { ref, inject, onBeforeMount, watch } from 'vue';
import { ref, computed, inject, onMounted, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useToast } from 'primevue/usetoast';
import { format, startOfMonth, subDays } from 'date-fns';
import ReportService from '@/service/ReportService';

import SpinnerThm from '@/components/SpinnerThm.vue';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';

export default {
    name: 'PostStatsReport',
    components: {
        ColumnGroup,
        Row,
        SpinnerThm
    },
    setup() {
        const filter = ref({});
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const reportService = new ReportService();
        const appSettings = inject('appSettings');

        const dt = ref();
        const data = ref([]);
        //const detail = ref([]);
        const loading = ref(false);
        const expandedRows = ref([]);
        const elementsRefs = reactive({})

        const dateRanges = [
            {
                value: 1,
                label: 'Today'
            },
            {
                value: 2,
                label: 'Last 30 Days'
            },
            {
                value: 3,
                label: 'Last 60 Days'
            },
            {
                value: 4,
                label: 'Last 90 Days'
            },
            {
                value: 7,
                label: 'Last Month'
            },
            {
                value: 5,
                label: 'Month-to-Date'
            },
            {
                value: 6,
                label: 'Year-to-Date'
            },
        ];

        const exportCSV = (target, event) => {
            if(console.log.hide) console.log(event)
            if(target == 'dt') {
                if(Array.isArray(data.value)) {
                    data.value.unshift(getTotalLine())
                    dt.value?.exportCSV();
                    data.value.shift();
                }
            } else {
                elementsRefs['authorPostsReportDetail_' + target]?.exportCSV();
            }
        };

        const fromDate = computed({
            get() {
                return filter.value.fromDate == null ? null : new Date(filter.value.fromDate);
            },
            set(newValue) {
                filter.value.fromDate = newValue ? formatDateTime(newValue, 'MM/dd/yyyy') : null;
            },
        });

        const toDate = computed({
            get() {
                return filter.value.toDate == null ? null : new Date(filter.value.toDate);
            },
            set(newValue) {
                filter.value.toDate = newValue ? formatDateTime(newValue, 'MM/dd/yyyy') : null;
            },
        });

        const freeTotal = computed(() => {
            let total = 0;
            for(let item of data.value) {
                total += item.free;
            }
            return total;
        });
        const vipTotal = computed(() => {
            let total = 0;
            for(let item of data.value) {
                total += item.vip;
            }
            return total;
        });
        const grandTotal = computed(() => {
            let total = 0;
            for(let item of data.value) {
                total += item.grandTotal;
            }
            return total;
        });

        const onRowExpand = (event) => {
            let authorDetail = data.value && data.value.find(author => author.authorId === event.data.authorId);
            let hasDetailProperty = authorDetail && authorDetail.detail;

            if(!hasDetailProperty) {
                reportService.getPostCountsByAuthorId(event.data.authorId, filter.value).then((res) => {
                    if(console.log.hide) console.log(res);
                    //console.log(111, data.value);
                    //data.value.detail['event.data.authorId'] = res;

                    data.value = data.value.map(author => {
                        if(author.authorId === event.data.authorId) {
                            return { ...author, detail: res };
                        }
                        return author;
                    });
                });
            }
        };
        const onRowCollapse = (event) => {
            if(console.log.hide) console.log(event);
            //toast.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
        };

        // Watch for route changes
        watch(
            // () => route.query, (params, fromParams) => {
            () => route.query, async (params) => {
                if (console.log.hide) console.log({ params });
                //console.log({ params });
                // authorStore.getAuthorsPage(params).then((res) => {
                //     if (console.log.hide) console.log('res', res);
                //     //currentPage.value = params.pageIndex || 0;
                // });
            }
        );

        // Mounted
        onMounted(async () => {
            const query = Object.assign({}, route.query);
            //filter.value = { range: 5, fromDate: '03/01/2024', ...query };
            filter.value = { ...query };
            if(filter.value.toDate) toDate.value = filter.value.toDate;
            if(filter.value.fromDate) fromDate.value = filter.value.fromDate;
            if(filter.value.range) filter.value.range = parseInt(filter.value.range);
            //console.log('filter.value', filter.value);
            await getData();
        });

        function getTotalLine() {
            let f = 0, v = 0, gt = 0;
            for(let idx = 0; idx < data.value.length; idx++) {
                f += data.value[idx].free;
                v += data.value[idx].vipTotal;
                gt += data.value[idx].grandTotal
            }
            return {
                nickName: 'Totals',
                free: f,
                vipTotal: v,
                grandTotal: gt
            }
        }

        function setElementRef(key) {
            return el => {
                if (el) {
                    elementsRefs[key] = el;
                }
            }
        }

        async function updateFilter() {
            router.push({ query: filter.value });
            expandedRows.value = [];
            await getData();
        }

        function clearFilter() {
            filter.value = {};
            router.push({ query: filter.value });
        }

        async function getData() {
            loading.value = true;
            await reportService.getPostCounts({ orderBy: 'GrandTotal DESC', ...filter.value }).then((res) => {
                data.value = res;
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Stats', detail: err.message || err, life: 4000 });
            }).finally(() => {
                loading.value = false;
            });
        }

        function dateSelected() {
            filter.value.range = null;
        }

        function updateFilterDates(e) {
            let now = new Date()
            let firstDayCurrentMonth = startOfMonth(now);
            let lastDayLastMonth = subDays(firstDayCurrentMonth, 1);
            let firstDayLastMonth = startOfMonth(lastDayLastMonth);

            toDate.value = null;
            switch(e.value) {
                case 1: // Today
                    fromDate.value = format(now, 'MM/dd/yyyy');
                    break;
                case 2: // Last 30 days
                    fromDate.value = format(subDays(now, 30), 'MM/dd/yyyy');
                    break;
                case 3: // Last 60 days
                    fromDate.value = format(subDays(now, 60), 'MM/dd/yyyy');
                    break;
                case 4: // Last 90 days
                    fromDate.value = format(subDays(now, 90), 'MM/dd/yyyy');
                    break;
                case 5: // Month-to-date
                    fromDate.value = format(firstDayCurrentMonth, 'MM/01/yyyy');
                    break;
                case 6: // YTD
                    fromDate.value = format(now, '01/01/yyyy');
                    break;
                case 7: // Last month
                    fromDate.value = format(firstDayLastMonth, 'MM/dd/yyyy');
                    toDate.value = format(lastDayLastMonth, 'MM/dd/yyyy');
                    break;
            }
        }

        return {
            appSettings,
            clearFilter,
            data,
            dt,
            dateRanges,
            dateSelected,
            expandedRows,
            exportCSV,
            filter,
            formatDateTime,
            freeTotal,
            fromDate,
            grandTotal,
            loading,
            onRowCollapse,
            onRowExpand,
            setElementRef,
            toDate,
            updateFilterDates,
            updateFilter,
            vipTotal
        }
    }
};
</script>

<style lang="scss" scoped>
table.posts-count-report {
    td a {
        color: #495057;
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>

<style lang="scss">
table.posts-count-report {
    th.text-center {
        .p-column-title {
            text-align: center;
            width: 100%;
        }
    }
}
</style>
