<template>
    <div class="mb-3">
        <Panel header="Search &amp; Filter" :toggleable="true" :collapsed="deviceIsMobile()" class="w-100" id="post_editor_filter">
            <div class="hstack gap-2 shadow-sm bg-white p-2">
                <div class="me-auto">
                    <InputText @keyup.enter="postStore.filter()" type="text" v-model="postStore.filterPosts.title" class="p-inputtext me-2 mb-2" placeholder="Title" x-title="Ctrl+K or ⌘K" id="SearchText" />
                    <AuthorsAutoComplete @keyup.enter="postStore.filter()" v-model="postStore.autoCompleteAuthor" :authorId="postStore.filterPosts.authorId" class="me-2 mb-2" placeholder="Author" x-authorTypeId="0" />
                    <Dropdown @keyup.enter="postStore.filter()" v-model="postStore.filterPosts.categoryId" :options="postStore.categoryList" optionLabel="name" optionValue="categoryId" placeholder="Category" :showClear="true" class="me-2 mb-2" v-if="!route.params.categoryId" />
                    <SubscriptionsDropdown @keyup.enter="postStore.filter()" v-model="postStore.filterPosts.subscription" class="me-2 mb-2" />
                    <Dropdown @keyup.enter="postStore.filter()" v-model="postStore.filterPosts.status" :options="postStore.statusOptions" optionLabel="label" optionValue="value" placeholder="Status" :showClear="true" class="me-2 mb-2" />
                    <!--<InputText @keyup.enter="postStore.filter()" type="text" v-model="postStore.filterPosts.postId" class="p-inputtext me-2 mb-2" placeholder="Post Id" style="max-width: 80px" />-->
                    <Calendar v-model="filterDatePublished" :showButtonBar="true" :showIcon="true" placeholder="Date" dateFormat="mm/dd/yy" hourFormat="12" class="mb-2" style="max-width: 155px" />
                    <Button label="" title="Search" icon="far fa-search" class="ms-2 mb-2 p-button" @click="postStore.filter()" />
                    <Button label="" title="Reset search..." icon="far fa-times" class="p-button ms-2 mb-2 p-button-secondary" @click="postStore.clearFilter()" />
                </div>
            </div>
        </Panel>
    </div>

    <div class="row">
        <PostList @save-post="onSavePost()" @unlock-post="onUnlockPost()" />
        <PostEdit :key="postStore.postEditKey" :categoryId="categoryId" ref="postEditRef" />
    </div>
</template>
<script>
//import AdminService from '../../service/AdminService';
import AuthorsAutoComplete from '@/components/AuthorsAutoComplete.vue';
import SubscriptionsDropdown from '@/components/SubscriptionsDropdown.vue';
import PostList from './PostList.vue';
import PostEdit from './PostEdit.vue';

import { onMounted, onUnmounted, watchEffect, watch, ref, computed } from 'vue';
import { deviceIsMobile } from '@/utils/ThmDeviceUtils';
import { useRoute } from 'vue-router';
//import { useToast } from 'primevue/usetoast';
import { usePostStore } from '@/stores/PostStore';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
//import { useMagicKeys, whenever } from '@vueuse/core';

export default {
    name: 'PostIndex',
    components: {
        PostEdit,
        PostList,
        SubscriptionsDropdown,
        AuthorsAutoComplete
    },
    props: {
        id: [Number, String],
        categoryId: [Number, String],
    },

    //setup does not have access to this!!
    setup(props, context) {
        //const toast = useToast();
        //const adminService = new AdminService();
        const postStore = usePostStore();
        const route = useRoute();
        const postEditRef = ref(null);
        const onSavePost = async () => {
            //saving on dirty post emitting from PostList
            console.log('saving post from emit! 😶‍🌫️🤐');
            await postEditRef.value.savePost(false);
            //postEditRef.value.takeOverPost();
        };
        const onUnlockPost = async () => {
            //saving on dirty post emitting from PostList
            console.log('unlock post from emit! 😶‍🌫️🤐');
            await postEditRef.value.unlockPost();
        };
        const filterDatePublished = computed({
            // getter .toLocaleString('en-US')
            get() {
                return postStore.filterPosts.datePublished == null ? null : formatDateTime(postStore.filterPosts.datePublished, 'MM/dd/yyyy')//new Date(postStore.filterPosts.datePublished);
            },
            // setter
            set(newValue) {
                postStore.filterPosts.datePublished = formatDateTime(newValue, 'MM/dd/yyyy'); //newValue.toLocaleString('en-US');
            },
        });
        //const autoCompleteAuthor = ref(null);

        //whenever(keys['Ctrl+K'], () => document.getElementById('SearchText').select());
        //whenever(keys['Meta+K'], () => document.getElementById('SearchText').select());

        // watch(
        //     async () => route.params,
        //     async () => {
        //         if ($route.params?.categoryId != null) {
        //             postStore.filterPosts.categoryId = $route.params.categoryId;
        //             postStore.filterPosts.authorId = $route.query.authorId;
        //             //console.log("this.authorId", this.authorId)
        //             //merging properties
        //             const params = { ...postStore.filterPosts, ...$route.query };
        //             postStore.filterPosts = params;
        //             console.log('call posts 😀');
        //             postStore.getPosts(params);
        //             postStore.getPostsCount(params);
        //         }
        //     }
        // );
        watch(
            () => postStore.autoCompleteAuthor,
            (newVal) => {
                postStore.filterPosts.authorId = newVal?.value ?? null;
            }
        );
        // watch(
        //     postStore.autoCompleteAuthor,
        //     (newVal) => {
        //         console.log('hit watch');
        //         postStore.filterPosts.authorId = newVal?.value ?? null;
        //     },
        //     { immediate: true }
        // );

        // Set pageIndex to zero when categoryId changes
        /*
        watch(
            () => route.params, (toParams, fromParams) => {
                if(toParams.categoryId != fromParams.categoryId) {
                    postStore.filterPosts.pageIndex = 0;
                }
            }
        );
        */

        watchEffect(async () => {
            //if(route.params?.categoryId != null) {
                //postStore.filterPosts.categoryId = route.params.categoryId;
                postStore.filterPosts.authorId = route.query.authorId;
                //console.log("this.authorId", this.authorId)
                //merging properties
                //const params = { ...postStore.filterPosts, ...route.query };
                const params = { ...postStore.filterPosts };
                if(route.params.categoryId) params.categoryId = route.params.categoryId;
                postStore.filterPosts = params;
                if(postStore.filterPosts.categoryId && !isNaN(postStore.filterPosts.categoryId)) postStore.filterPosts.categoryId = parseInt(postStore.filterPosts.categoryId);
                //console.log('call posts 😀');
                postStore.getPosts(params);
                postStore.getPostsCount(params);
            //}
        });
        onMounted(async () => {
            //console.log('👌 Mounted');
            let query = { ...route.query };
            if(route.params.categoryId) query.categoryId = route.params.categoryId;
            postStore.filterPosts = { ...postStore.filterPosts, ...query };

            postStore.postId = props.id;
            await postStore.load();

            /*
            adminService
                .getCategories()
                .then((response) => {
                    postStore.categoryList = response;
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error fetching categories', detail: err.message || err, life: 4000 });
                });
            */
        });
        onUnmounted(() => {
            console.log('👍 unmounted posts reset postStore');
            postStore.$reset();
        });

        //returning them for the template to use
        return {
            context,
            deviceIsMobile,
            filterDatePublished,
            onSavePost,
            onUnlockPost,
            postEditRef,
            postStore,
            route,
        };
    },
};
</script>

<style lang="scss">
// Hide filter bar collapsible panel on desktop
body:not(.mobile) {
    #post_editor_filter {
        div.p-panel-header {
            display: none;
        }
        div.p-panel-content {
            padding: 0;
            border: none;
        }
    }
}
</style>
