import BaseService from './BaseService';
import { getAppSettings } from '@/utils/ThmUtils';

export default class LayoutService extends BaseService {
    async getPosts(params) {
        return await this.apiClient.get('/api/layout/posts', { params }).then((res) => res.data);
    }
    async save(posts, status, locationId = 1) {
        return await this.apiClient.put('/api/layout/posts', posts, { params: { isLive: status == 'live', locationId: locationId } }).then((res) => res.data);
    }

    /*
    async getPostStatsOld(posts) {
        let result = [];

        if(Array.isArray(posts)) {
            // Multiple posts
            for(let idx = 0; idx < posts.length; idx++) {
                if(posts[idx].postId > 0) {
                    result.push({
                        ...posts[idx],
                        "stats": {
                            "postId": posts[idx].postId,
                            "concurrents": Math.floor(Math.random() * 100),
                            "5m": Math.floor(Math.random() * 100),
                            "30m": Math.floor(Math.random() * 100),
                            "60m": Math.floor(Math.random() * 100)
                        }
                    });
                }
            }
        } else {
            // Single post
            if(posts.postId > 0) {
                result = { ...posts, "stats": {
                    "concurrents": Math.floor(Math.random() * 100),
                    "5m": Math.floor(Math.random() * 100),
                    "30m": Math.floor(Math.random() * 100),
                    "60m": Math.floor(Math.random() * 100)
                }};
            }
        }

        //console.log('result', result);
        return await result;
    }
    */

    async getPostStats() {
        const appSettings = getAppSettings();
        return await this.apiClient.get('/api/layout/posts/stats', { params: { domain: appSettings.domain } }).then((res) => {
            res = res.data || [];
            let results = [];

            // Strip /podcasts
            for(let idx = 0; idx < res.length; idx++) {
                if(!res[idx].url.includes('/podcasts/')) results.push(res[idx]);
            }

            // Add post IDs
            for(let idx = 0; idx < results.length; idx++) {
                let postId = results[idx].url.match(/-n(\d+$)/ig)?.pop().replace(/[^\d]/g, '');
                if(postId) results[idx].postId = Number(postId);
            }

            return results;
        });
    }
}
