<template>
    <div class="color-picker-thm">
        <ColorPicker v-model="color" format="hex" class="d-inline-block pe-1" />
        <InputText :id="elementId" v-model="color" />
    </div>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue';
import { generateRandomString, isValidHexColor } from '@/utils/ThmStringUtils';
import ColorPicker from 'primevue/colorpicker';

export default {
    name: 'TagInput',
    components: {
        ColorPicker
    },
    props: {
        modelValue: [String, Object],
        inputId: String,
    },
    setup(props, { emit }) {

        const elementId = ref(null);

        onBeforeMount(async () => {
            elementId.value = props.inputId || 'ColorPickerTextInput_' + generateRandomString(18);
        });

        const color = computed({
            get() {
                return props.modelValue;
            },
            set(newValue) {
                let hex = '#' + newValue.replace(/[^a-z0-9]/gi, '');
                if(isValidHexColor(hex)) {
                    emit('update:modelValue', hex);
                }
            },
        });

        return {
            color,
            elementId,
            props,
        };
    },
};
</script>
