<template>
    <form class="needs-validation" novalidate>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header card-header-divider">Notification Demo</div>

                    <div class="card-body">
                        <Message v-for="notice of notices" :severity="notice.type" :key="notice.content" :closable="notice.dismissable" :life="notice.life" :sticky="notice.dismissable">{{ notice.content }}</Message>

                        <p style="margin-top: 1em"><Button label="Toast Example" @click="showToast('This is a Toast message', 'Success Message')" /></p>

                        <p><Button label="Message Example" @click="showMessage('This message will self-destruct in 5 seconds.', 'error', 5000)" /></p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'FormComponentDemo',
    data() {
        return {
            notices: [],
        };
    },
    methods: {
        showToast(msg, title = null, styleType = 'success') {
            // See: https://primefaces.org/primevue/showcase/#/toast
            if (!msg) return;
            this.$toast.add({ severity: styleType, summary: title, detail: msg, life: 3000 });
        },
        showMessage(msg, styleType = 'success', life = null, allowDismiss = false) {
            // See: https://primefaces.org/primevue/showcase/#/message
            if (!msg) return;
            this.notices.push({ type: styleType, content: msg, dismissable: allowDismiss, life: life });
        },
    },
};
</script>
