<template>
    <Dialog header="Add Post" v-model:visible="showModal" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '60vw', '640px': '100vw' }" :style="{ width: '55vw' }" class="thmpostchooser">
        <div id="thmpostchooser_top"></div>
        <div class="thmpostchooser-container">
            <div class="d-flex thmpostchooser-post" v-for="post in posts" :key="post.postId">
                <div class="flex-shrink-0">
                    <a class="preview-image" v-if="post.imageObject" v-bind:style="{ backgroundImage: 'url(' + resizeImageUrl(post.imageObject[0]?.url, '300x168') + ')' }" :title="post.title" @click="choosePost(post)" role="button"></a>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h5 class="mb-1" @click="choosePost(post)" role="button">{{ getActiveHeadline(post) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-item">Post ID: {{ post.postId }}</li>
                        <li class="list-item">Published: {{ formatDateTime(post.datePublished, 'M/dd/yyyy hh:mm a') }}</li>
                        <li class="list-item">
                            <a :href="getAbsoluteUrl(post.url)" target="_blank">Open</a> |
                            <a :href="getEditUrl(post)" target="_blank">Edit</a>
                            <!-- <i class="fa-regular fa-arrow-up-right-from-square small"></i> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="Array.isArray(posts) && !posts.length" class="fst-italic">No posts found matching search criteria.</div>
        <div ref="bottomSentinel" class="bottom-sentinel"></div>

        <template #header>
            <div class="input-group pe-3">
                <input type="search" class="form-control" placeholder="Keywords..." aria-label="Search" v-model="filter.title" @keyup.enter="submitSearch" @change="hasBeenFetched = false" />
                <button class="btn btn-primary thmim-button-primary" type="button" id="thmbrid_search_btn" @click="submitSearch">Search</button>
            </div>
        </template>
        <template #footer>
            <SpinnerThm class="spinner float-end" size="1.3em" v-show="loading" />
        </template>
    </Dialog>
</template>

<script>
import { ref, inject } from 'vue';
import { formatDateTime, formatDateTimeSQL, getDateTimeDaysAgo } from '@/utils/ThmDateTimeUtils';
import { useIntersectionObserver } from '@vueuse/core';
import { getActiveHeadline } from '@/utils/ThmUtils';
import { resizeImageUrl } from '@/utils/ThmImageUtils';
import { useRouter } from 'vue-router'
import PostService from '@/service/PostService';
import SpinnerThm from '@/components/SpinnerThm.vue';
import Dialog from 'primevue/dialog';

export default {
    name: 'PostChooserThm',
    emits: ['update:modelValue', 'choose'],
    props: {
        modelValue: {
            type: Object,
            default() {
                return { postId: 0 }
            }
        }
    },
    components: {
        Dialog,
        SpinnerThm,
    },
    setup(props, { emit, expose }) {
        if(console.log.hide) console.log(emit, expose);

        const appSettings = inject('appSettings');
        const postService = new PostService();
        const router = useRouter();

        const bottomSentinel = ref(null);
        const currentPost = ref({});
        const hasBeenFetched = ref(false);
        const loading = ref(false);
        const posts = ref(null);
        const showModal = ref(false);
        const finishedLoading = ref(false);
        const filter = ref({
            status: 1,
            pageIndex: 0,
            pageSize: 15,
            postTypeId: 1,
            title: '',
        });

        // Infinite scroll trigger observer
        useIntersectionObserver(bottomSentinel, ([{ isIntersecting }]) => {
                handleInfiniteScroll(isIntersecting);
            },
            { threshold: 0 }
        );

        // onMounted(async () => {
        //     openModal();
        // });

        const handleInfiniteScroll = async (isIntersecting) => {
            if(finishedLoading.value || posts.value?.length < filter.value.pageSize || loading.value) return;
            if(isIntersecting) {
                await getPosts();
            }
        };

        async function openModal() {
            filter.value.title = '';
            filter.value.pageIndex = 0;
            await getPosts(true);
            showModal.value = true;
        }
        expose({ openModal });

        async function getPosts(newSearch = false) {
            loading.value = true;
            let params = { ...filter.value };

            // Limit number of days to go back to speed up initial query
            if(newSearch) params.datePublishedStart = formatDateTimeSQL(getDateTimeDaysAgo(90));

            let results = await postService.getPosts(params);
            filter.value.pageIndex++;
            if(results.length < filter.value.pageSize) {
                finishedLoading.value = true;
                console.log('Finished loading posts');
            }
            if(newSearch) posts.value = [];
            posts.value = posts.value.concat(results);
            loading.value = false;
            return posts.value;
        }

        function choosePost(post) {
            currentPost.value = post;
            emit('update:modelValue', post);
            emit('choose', post);
            showModal.value = false;
        }

        function getAbsoluteUrl(path) {
            return appSettings.webroot[process.env.NODE_ENV] + path;
        }

        function getEditUrl(p) {
            let routeUrl = null;
            if(appSettings.siteId == 1) {
                let categoryId = p.categories.length > 0 ? p.categories[0] : null;
                routeUrl = router.resolve({name: 'PostsCategory', params: { id: p.postId, categoryId: categoryId } });
            } else {
                routeUrl = router.resolve({name: 'Posts', params: { id: p.postId } });

            }
            return routeUrl.href;
        }

        async function submitSearch() {
            finishedLoading.value = false;
            filter.value.pageIndex = 0;

            // Scroll to top
            const element = document.getElementById('thmpostchooser_top');
            if(element) element.scrollIntoView();

            await getPosts(true);
        }

        return {
            bottomSentinel,
            choosePost,
            currentPost,
            filter,
            formatDateTime,
            getAbsoluteUrl,
            getActiveHeadline,
            getEditUrl,
            hasBeenFetched,
            loading,
            posts,
            props,
            resizeImageUrl,
            submitSearch,
            showModal,
        }
    }
}
</script>

<style lang="scss" scoped>
div.thmpostchooser-post {
    border-bottom: 1px solid var(--bluegray-200);
    margin-bottom: 1.2em;
    padding-bottom: 1.2em;

    a.preview-image, span.preview-image {
        background: no-repeat center center / cover;
        display: inline-block;
        vertical-align: middle;
        width: 12.4em;
        height: 7em;
        aspect-ratio: auto 640/360;
    }

    ul.list-group {
        li.list-item {
            font-size: 0.85em;
            list-style-type: none;
            color: var(--gray-700);

            &.small {
                font-size: 0.8em;
            }
        }
    }

    &:last-of-type {
        border-bottom: unset;
        margin-bottom: unset;
        padding-bottom: unset;
    }
}
</style>
