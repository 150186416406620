<template>
    <div class="repeater">
        <div class="row mb-1" v-for="color in colors" :key="color">
            <div class="col-5">
                <InputText type="text" class="form-control" v-model="color.label" placeholder="Label" @change="update" />
            </div>
            <div class="col-5">
                <InputText type="text" class="form-control" v-model="color.hexCode" placeholder="Color (Hex Code)" @change="update" />
            </div>
            <div class="col-2">
                <Button icon="pi pi-trash" class="p-button-secondary" @click="remove(color)" />
            </div>
        </div>

        <Button label="Add Color" icon="pi pi-plus" class="p-button-secondary" @click="addColor" />
    </div>
</template>

<script>
//import { isObject } from '@vueuse/core';
//import { ref, watch, onMounted } from 'vue';
//import { computed, watch } from 'vue';
//import { computed, ref } from 'vue';
import { computed, onBeforeMount, watch } from 'vue';
//import { watchArray } from '@vueuse/core';
import { uuid } from 'vue-uuid';

export default {
    name: 'ColorRepeater',
    props: {
        modelValue: [Array]
    },
    setup(_props, { emit }) {

        //const colors = ref([]);

        watch(() => _props.modelValue, (newValue, oldValue) => {
            if(console.log.hide) console.log({oldValue});
            //if(!colors.value.length && newValue && Array.isArray(newValue) && newValue != oldValue) {
            if(newValue && Array.isArray(newValue)) {
                //colors.value = newValue;
            }
        });

        /*
        watch(() => _props.modelValue, (newValue) => {
            emit('update:modelValue', newValue);
        });
        */

        /*
        watchArray(colors.value, (newList, oldList, added, removed) => {
            console.log(newList) // [1, 2, 3, 4]
            console.log(oldList) // [1, 2, 3]
            console.log(added) // [4]
            console.log(removed) // []
        })
        */

        const colors = computed({
            get() {
                return _props.modelValue;
            },
            set(value) {
                //console.log('EMIT:', value);
                emit('update:modelValue', value);
            }
        });

        onBeforeMount(() => {
            colors.value = _props.modelValue;
        })

        function remove(color) {
            colors.value = colors.value.filter(item => item.uuid != color.uuid);
            //emit('update:modelValue', colors.value);
        }

        function update() {
            //console.log('UPDATE', colors.value);
            emit('update:modelValue', colors.value);
        }

        function addColor() {
            colors.value.push({ label: '', hexCode: '', uuid: uuid.v4() });
            emit('update:modelValue', colors.value);
        }

        return {
            addColor,
            colors,
            remove,
            update
        };
    },
};
</script>
