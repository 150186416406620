<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">{{ store.settings.name }}</div>
                <div class="card-body">
                    <p>Sort Columnists and persist for a scheduled time. Please allow up to <strong>30 seconds</strong> before you'll see your changes on the homepage.</p>
                    <p>New posts published during the scheduled custom sorting will sort by the author's rank.</p>
                    <!-- <div class="mb-3">
                        <label for="StartDate">Start at the following time:</label>
                        <div class="input-group">
                            <Calendar id="StartDate" v-model="datePublished" :show-time="true" :show-seconds="false" hour-format="12" dateFormat="mm/dd/yy" :show-icon="true" :manual-input="false" />
                        </div>
                    </div> -->
                    <div class="mb-3">
                        <label for="Hours" class="form-label">Stay active for the following duration:</label>
                        <Dropdown id="Hours" v-model="store.settings.duration" :options="durations" optionLabel="label" optionValue="value" class="d-flex" />
                    </div>

                    <div class="mb-2">
                        <div class="form-check2 form-switch2">
                            <InputSwitch v-model="store.settings.isPublished" id="isEnabled" />
                            <label for="isEnabled" class="switch-label">Use Custom Sorting</label>
                        </div>
                    </div>

                    <div v-if="store.settings && store.settings.isPublished" class="mb-3">
                        <span class="badge text-bg-warning ms-auto">Expires at: {{ formatDateTime(expiresAt) }}</span>
                    </div>
                    <div class="mb-3 d-flex align-items-center">
                        <Button @click="save()" label="Save" x-title="Save (Ctrl+S or ⌘S)" class="me-2" />
                        <Button @click="cancel()" label="Cancel" severity="secondary" outlined class="me-2" />
                        <LogViewerThm label="Logs" :filter="{ logTypeId: 14, referenceId: 1 }" class="p-button-secondary p-button-outlined" tag="button" />
                        <span class="muted-info ms-2"> Last Updated: {{ formatDateTime(store.dateModified, 'M/dd/yyyy hh:mm a') }} </span>
                    </div>
                </div>
            </div>
        </div>
        <ColumnistsList title="Live" column="live" />

    </div>
</template>

<script>
import { ref, onBeforeMount, computed } from 'vue';
//import { useMagicKeys, whenever } from '@vueuse/core';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useStore } from '@/stores/ColumnistsWidgetStore';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import ColumnistsList from './ColumnistsList.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'ColumnistsWidget',
    components: {
        ColumnistsList,
        LogViewerThm,
    },
    setup() {

        //remove future section
        //new posts that show up during the duration sort should get added and sorted by rank
        const store = useStore();
        const router = useRouter();
        const toast = useToast();
        const { user } = useAuth0();
        const expiresAt = computed(() => {
            let expires = new Date(store.settings.datePublished);
            let duration = store.settings.duration;

            expires.setMinutes(expires.getMinutes() + duration);

            console.log('dd', formatDateTime(expires));
            return `Expires at ${expires}`;
        });

        /*
        const { ctrl_s, meta_s } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') e.preventDefault();
            },
        });
        whenever(ctrl_s, () => save());
        whenever(meta_s, () => save());
        */

        //const loaded = ref(false);
        const datePublished = computed({
            // getter
            get() {
                //.toLocaleString('en-US')
                return store.settings.datePublished == null ? null : new Date(store.settings.datePublished);
            },
            // setter
            set(newValue) {
                store.settings.datePublished = newValue.toLocaleString('en-US');
            },
        });
        const durations = ref([
            { label: '30 Min', value: 30 },
            { label: '1 Hour', value: 1 * 60 },
            { label: '2 Hours', value: 2 * 60 },
            { label: '3 Hours', value: 3 * 60 },
            { label: '4 Hours', value: 4 * 60 },
            { label: '5 Hours', value: 5 * 60 },
            { label: '6 Hours', value: 6 * 60 },
            { label: '7 Hours', value: 7 * 60 },
            { label: '8 Hours', value: 8 * 60 },
            { label: '9 Hours', value: 9 * 60 },
            { label: '10 Hours', value: 10 * 60 },
            { label: '11 Hours', value: 11 * 60 },
            { label: '12 Hours', value: 12 * 60 },
        ]);

        onBeforeMount(() => {
            store.getSettings(1).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading settings', detail: err.message || err, life: 3000 });
            });
            store.getColumnists('live').catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading columnists', detail: err.message || err, life: 3000 });
            });
        });
        // function onChange() {
        //     if (store.loading) {
        //         console.log('loading: ', store.loading);
        //     } else {
        //         console.log('loading: finished ', store.loading);
        //         store.getColumnists('future').catch((err) => {
        //             toast.add({ severity: 'error', summary: 'Error Loading columnists', detail: err.message || err, life: 3000 });
        //             loaded.value = true;
        //         });
        //     }
        // }
        function cancel() {
            store.$reset();
            store.getSettings(1).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading settings', detail: err.message || err, life: 3000 });
            });
            store.getColumnists('live').catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading columnists', detail: err.message || err, life: 3000 });
            });
            store.getColumnists('future').catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading columnists', detail: err.message || err, life: 3000 });
            });
            router.push({ name: 'ColumnistsWidget' });
        }
        function save() {
            store
                .save()
                .then((res) => {
                    console.log({ res });
                    //console1.value = res;
                    store.changedColumn['live'] = false;
                    store.changedColumn['future'] = false;
                    store.changedPosts = [];
                    //layoutStore.setPostLockStatus(4, null);
                    toast.add({ severity: 'success', summary: 'Success', detail: ' Widget Changes Saved', life: 3000 });
                })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving Widget', detail: err.message || err, life: 3000 });
                });
        }
        return { durations, store, toast, user, formatDateTime, save, expiresAt, datePublished, cancel };
    },
};
</script>

<style lang="scss" scoped></style>
