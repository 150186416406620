<template>
    <header v-if="isAuthenticated" class="navbar thm-header-nav sticky-top flex-md-nowrap p-0 shadow-sm">
        <div class="nav">
            <Menubar :model="roleBasedMenuItems" class="th-menubar">
                <template #start>
                    <router-link to="/" class="mx-2 th-logo">
                        <!--<img src="https://cdn.townhall.com/web/thm/logo-townhall.svg" alt="" style="width: 100px; height: auto" />-->
                        <img :x-db="siteStore.build?.db" :src="appSettings.brand.logo" :alt="appSettings.title" class="h-auto logo" :class="vueAppProfile" />
                    </router-link>
                </template>
                <template #item="{ label, item, props, root }">
                    <router-link v-if="item.route" v-slot="routerProps" :to="item.route">
                        <a :href="routerProps.href" v-bind="props.action">
                            <span v-bind="props.icon" />
                            <span v-bind="props.label">{{ label }}</span>
                        </a>
                    </router-link>
                    <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                        <span v-bind="props.icon" />
                        <span v-bind="props.label">{{ label }}</span>
                        <span :class="[root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right']" v-bind="props.submenuicon" />
                    </a>
                </template>
                <template #end>
                    <!-- <InputText placeholder="Search" type="text" /> -->
                </template>
            </Menubar>
        </div>
        <div class="nav nav justify-content-end me-2">
            <!--<Button icon="far fa-user" class="p-button-rounded p-button-info" @click="toggle" />-->
            <Avatar :image="profilePhoto" size="large" class="profile-avatar" shape="circle" @click="toggle" role="button" />
            <!--<Menu ref="menu" :model="profile(authState)" :popup="true" />-->
            <Menu ref="menu" :model="profile(authState)" :popup="true" class="pt-1 ps-0">
                <template #item="{ label, item, props }">
                    <router-link v-if="item.route" v-slot="routerProps" :to="item.route">
                        <a :href="routerProps.href" v-bind="props.action">
                            <span v-bind="props.icon" />
                            <span v-bind="props.label">{{ label }}</span>
                        </a>
                    </router-link>
                    <a v-else :href="item.url" v-bind="props.action">
                        <span v-bind="props.icon" />
                        <span v-bind="props.label">{{ label }}</span>
                    </a>
                </template>
            </Menu>
            <!--<Avatar label="P" shape="circle" />-->
        </div>
    </header>
    <Message severity="warn" class="update-notice m-0" v-if="hasNewVersion" :closable="false">A new version of the CMS is available. Please <a href="#" @click="reloadPage">refresh</a> your browser.</Message>
</template>

<script>
//import AdminService from './service/AdminService';
import useAuth0 from '@/use/Auth0';
import { ref, computed, inject, onMounted } from 'vue';
import { userHasAccessToRole, userHasAccessToRoles } from '@/utils/ThmAuthUtils';
import { useSiteStore } from '@/stores/SiteStore';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import md5 from 'md5';
//import { useIntervalFn } from '@vueuse/core';
//import { auth0 } from '@/main.js';

export default {
    name: 'AppTopbar',
    props: {
        version: [String, Number],
        hasNewVersion: Boolean,
    },
    setup() {
        const { authState, isAuthenticated, logout } = useAuth0();
        const siteStore = useSiteStore();
        //const adminService = new AdminService();
        const appSettings = inject('appSettings');
        const vueAppProfile = process.env.VUE_APP_PROFILE;
        //const { user, getAccessTokenSilently } = useAuth0();
        const { user, userRoles } = useAuth0();

        const menu = ref(null);
        const menuItems = ref([
                {
                    label: 'Posts',
                    icon: 'pi pi-fw pi-pencil',
                    items: [
                        {
                            label: 'Tipsheet',
                            icon: 'far fa-pen-to-square',
                            route: '/post/5',
                            sites: [1],
                        },
                        {
                            label: 'Columns',
                            icon: 'far fa-pen-to-square',
                            route: '/post/1',
                            sites: [1],
                        },
                        {
                            label: 'Posts',
                            icon: 'far fa-pen-to-square',
                            route: '/posts',
                            sites: [2, 3, 4, 5, 6],
                        },
                        {
                            label: 'Archived',
                            icon: 'far fa-archive',
                            //roles: ['Admin', 'Developer'],
                            sites: [1],
                            items: [
                                {
                                    label: 'Notebook',
                                    id: 13,
                                    icon: 'far fa-sticky-note',
                                    route: '/post/13',
                                },
                                {
                                    label: 'Capitol Voices',
                                    id: 16,
                                    icon: 'far fa-sticky-note',
                                    route: '/post/16',
                                },
                                {
                                    id: 17,
                                    label: 'Entertainment',
                                    icon: 'far fa-sticky-note',
                                    route: '/post/17',
                                },
                                {
                                    id: 4,
                                    label: 'Videos',
                                    icon: 'far fa-sticky-note',
                                    route: '/post/4',
                                },
                            ],
                        },
                        {
                            label: 'Tags',
                            icon: 'far fa-tags',
                            route: '/tags',
                            roles: ['Admin', 'Developer']
                        },
                    ],
                },
                {
                    label: 'Sections',
                    icon: 'pi pi-fw pi-credit-card',
                    items: [
                        {
                            label: 'Home Page',
                            icon: 'far fa-home',
                            route: '/manage/home',
                            sites: [1],
                            roles: ['Editor', 'Admin', 'Developer'],
                        },
                        {
                            label: 'Home Page',
                            icon: 'far fa-home',
                            route: '/manage/home',
                            sites: [2, 3, 4, 5, 6],
                            roles: ['Admin', 'Developer'],
                        },
                        {
                            label: 'Cartoons',
                            icon: 'far fa-laugh-beam',
                            route: '/manage/cartoons',
                            roles: ['Editor', 'Admin', 'Developer'],
                            sites: [1],
                        },
                        {
                            label: "Editor's Choice",
                            icon: 'far fa-crown',
                            route: '/section/editors-choice',
                            roles: ['Admin', 'Developer'],
                            sites: [2, 4],
                        },
                        {
                            label: 'Headlines',
                            icon: 'far fa-newspaper',
                            route: '/cpt/4',
                            sites: [5],
                            roles: ['Editor', 'Admin', 'Developer'],
                        },
                        {
                            label: 'Live Blog',
                            icon: 'far fa-comment-lines',
                            route: '/liveblog'
                        },
                        // {
                        //     label: 'Podcasts NEW',
                        //     icon: 'far fa-podcast',
                        //     route: '/manage/podcasts2',
                        //     roles: ['Admin', 'Developer'],
                        //     sites: [1],
                        // },
                        {
                            label: 'Podcasts',
                            icon: 'far fa-podcast',
                            route: '/manage/podcasts',
                            roles: ['Admin', 'Developer'],
                            sites: [1],
                        },
                        {
                            label: 'Newsletters',
                            icon: 'far fa-newspaper',
                            roles: ['Admin', 'Developer'],
                            sites: [1],
                            items: [
                                {
                                    label: 'Townhall Afternoon',
                                    icon: 'far fa-ad',
                                    route: '/newsletters/townhallafternoon',
                                },
                            ],
                        },
                        {
                            label: 'Widgets',
                            icon: 'far fa-box-open-full',
                            roles: ['Editor', 'Admin', 'Developer'],
                            sites: [1, 2],
                            items: [
                                {
                                    label: 'Columnists Widget',
                                    icon: 'far fa-people-group',
                                    route: '/settings/widgets/columnists',
                                    roles: ['Editor', 'Admin', 'Developer'],
                                },
                                {
                                    label: 'Block Copy',
                                    icon: 'far fa-cube',
                                    route: '/manage/blockcopy',
                                    roles: ['Developer']
                                },
                            ],
                        },
                        {
                            label: 'Block Copy',
                            icon: 'far fa-cube',
                            route: '/manage/blockcopy',
                            sites: [3, 4, 5, 6],
                            roles: ['Developer']
                        },
                        {
                            label: 'Virtual Page',
                            icon: 'far fa-paperclip',
                            route: '/manage/virtualpage',
                            roles: ['Developer']
                        }
                    ],
                },
                // SETTINGS: Townhall
                {
                    label: 'Settings',
                    icon: 'far fa-cog',
                    roles: ['Editor', 'Admin', 'Developer'],
                    sites: [1],
                    items: [
                    {
                            label: 'Alert Bar',
                            icon: 'far fa-megaphone',
                            route: '/settings/alertbar',
                            roles: ['Admin', 'Developer'],
                        },
                        {
                            label: 'Authors',
                            icon: 'far fa-users',
                            route: '/settings/authors',
                            roles: ['Editor', 'Admin', 'Developer'],
                        },
                        // {
                        //     label: 'Badges',
                        //     icon: 'far fa-tags',
                        //     route: '/settings/badges',
                        //     roles: ['Developer'],
                        //     sites: [1],
                        // },
                        {
                            label: 'Menus',
                            icon: 'far fa-indent',
                            route: '/settings/menu',
                            roles: ['Developer'],
                        },
                        {
                            label: 'SEO',
                            icon: 'pi pi-fw pi-chart-line',
                            route: '/settings/seo',
                            roles: ['Developer'],
                        },                    ],
                },
                // SETTINGS: Non-Townhall
                {
                    label: 'Settings',
                    icon: 'far fa-cog',
                    roles: ['Admin', 'Developer'],
                    sites: [2, 3, 4, 5, 6],
                    items: [
                        {
                            label: 'Alert Bar',
                            icon: 'far fa-megaphone',
                            route: '/settings/alertbar',
                            //roles: ['Admin', 'Developer'],
                        },
                        {
                            label: 'Authors',
                            icon: 'far fa-users',
                            route: '/settings/authors',
                            //roles: ['Admin', 'Developer'],
                        },
                        {
                            label: 'Menus',
                            icon: 'far fa-indent',
                            route: '/settings/menu',
                            roles: ['Developer'],
                        },
                        {
                            label: 'SEO',
                            icon: 'pi pi-fw pi-chart-line',
                            route: '/settings/seo',
                            roles: ['Developer'],
                        },
                    ],
                },
                {
                    label: 'Tools',
                    icon: 'far fa-tools',
                    roles: ['Admin', 'Developer'],
                    items: [
                        {
                            label: 'Cache Purge',
                            icon: 'far fa-bolt',
                            route: '/tools/cache',
                            roles: ['Developer']
                        },
                        {
                            label: 'Image Library',
                            icon: 'far fa-images',
                            route: '/imagelib',
                        },
                        {
                            label: 'Log Search',
                            icon: 'far fa-square-list',
                            route: '/tools/logs',
                            //roles: ['Admin', 'Developer']
                        },
                        {
                            label: 'CoReg',
                            sites: [1],
                            icon: 'far fa-newspaper',
                            items: [
                                {
                                    label: 'Reports',
                                    icon: 'far fa-ad',
                                    route: '/tools/coreg/reports',
                                },
                                {
                                    label: 'Api Report',
                                    icon: 'far fa-ad',
                                    route: '/tools/coreg/partnersreports',
                                },
                                {
                                    label: 'Offers',
                                    icon: 'far fa-ad',
                                    route: '/tools/coreg/offers',
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Reports',
                    icon: 'far fa-table',
                    roles: ['Admin', 'Developer', 'Reports - VIP Revenue'],
                    items: [
                        {
                            label: 'Post Counts',
                            icon: 'far fa-calculator',
                            route: '/reports/postcounts?range=5&fromDate=' + format(new Date(), 'MM/01/yyyy'),
                        },
                        {
                            label: 'Post Statistics',
                            icon: 'far fa-chart-mixed',
                            route: '/reports/statsreport',
                        },
                        {
                            label: 'VIP Revenue',
                            icon: 'far fa-money-bill-trend-up',
                            route: '/reports/viprevenue',
                            roles: ['Developer', 'Reports - VIP Revenue'],
                        },
                    ],
                },
                /*
                {
                    label: 'SignalR',
                    icon: 'far fa-acorn',
                    //roles: [], // Hide for everyone
                    roles: ['Debug'],
                    route: '/demos/signalr'
                },
                */
                {
                    label: 'Demos',
                    icon: 'far fa-acorn',
                    //roles: [], // Hide for everyone
                    roles: ['Hidden'],
                    items: [
                        {
                            label: 'CMS Standards',
                            icon: 'pi pi-fw pi-pencil',
                            route: '/demos/tabledemo',
                        },
                        {
                            label: 'PrimeVue',
                            icon: 'pi pi-fw pi-vimeo',
                            items: [
                                {
                                    label: 'Calendar Demo',
                                    icon: 'pi pi-fw pi-calendar',
                                    route: '/demos/calendardemo',
                                },
                                {
                                    label: 'Notification Demo',
                                    icon: 'far fa-comment-alt-dots',
                                    route: '/demos/notificationdemo',
                                },
                                {
                                    label: 'File Upload',
                                    icon: 'pi pi-fw pi-upload',
                                    route: '/demos/fileuploaddemo',
                                },
                                {
                                    label: 'Pagination',
                                    icon: 'far fa-page',
                                    route: '/demos/pagination',
                                }
                            ],
                        },
                        {
                            label: 'Archived',
                            icon: 'far fa-archive',
                            items: [
                                {
                                    label: 'Counter Demo',
                                    icon: 'pi pi-fw pi-pencil',
                                    route: '/demos/counterdemo',
                                },
                                {
                                    label: 'Draggable Demo',
                                    icon: 'far fa-indent',
                                    route: '/demos/draggable',
                                },
                                {
                                    label: 'Menu Test',
                                    icon: 'pi pi-fw pi-pencil',
                                    route: '/demos/menutest',
                                },
                                {
                                    label: 'Site Store (options)',
                                    icon: 'far fa-store',
                                    route: '/demos/archived/sitestoreoptionsapi',
                                },
                            ],
                        },
                        {
                            label: 'Froala',
                            icon: 'far fa-money-check-edit',
                            items: [
                                {
                                    label: 'Froala-Thm Editor',
                                    icon: 'far fa-money-check-edit',
                                    route: '/demos/editordemo',
                                },
                                {
                                    label: 'Froala - Image Mgr',
                                    icon: 'far fa-money-check-edit',
                                    route: '/sandbox/froalaimagemgr',
                                },
                            ],
                        },
                        {
                            label: 'VueUse',
                            icon: 'far fa-union',
                            items: [
                                {
                                    label: 'useGeneralDemo',
                                    icon: 'far fa-browser',
                                    route: '/demos/vueuse/UseGeneralDemo',
                                },
                                {
                                    label: 'useBrowserLocation',
                                    icon: 'far fa-browser',
                                    route: '/demos/vueuse/UseBrowserLocation?hello=world&ID=1234',
                                },
                                {
                                    label: 'useInfiniteScroll',
                                    icon: 'far fa-browser',
                                    route: '/demos/vueuse/useInfiniteScroll',
                                },
                            ],
                        },
                        {
                            label: 'Image Library',
                            icon: 'far fa-images',
                            route: '/sandbox/imagemanager',
                        },
                        {
                            label: 'MultiDemo',
                            icon: 'pi pi-fw pi-pencil',
                            route: '/demos/multidemo',
                        },
                        {
                            label: 'Emit Component',
                            icon: 'far fa-starship',
                            route: '/demos/formcomponentdemo',
                        },
                        {
                            label: 'Promises Demo',
                            icon: 'far fa-hand-fingers-crossed',
                            route: '/demos/promises',
                        },
                        {
                            label: 'Log Viewer',
                            icon: 'far fa-line-columns',
                            route: '/demos/ThmLogViewer',
                        },
                    ],
                },
            ]
        );
        const items = ref([
            {
                id: 1,
                label: 'Profile',
                route: '/profile',
            },
            {
                id: 2,
                label: 'Version: Unknown',
                route: '/env',
            },
            {
                id: 3,
                label: 'Logout',
                icon: 'far fa-sign-out-alt',
                command: async () => {
                    let cookieDomain = process.env.NODE_ENV == 'development' ? null : '.' + appSettings.domain;
                    Cookies.remove('_wpadmin', { domain: cookieDomain, path: '/' });
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin
                        }
                    });
                },
            },
        ]);
        const roleBasedMenuItems = computed(() => {
            if(!isAuthenticated.value) return menuItems.value;
            let menuItemsBySite = filterItemsBySite(menuItems.value);
            return filterItemsByRole(menuItemsBySite);

        });
        const profilePhoto = computed(() => {
            return `https://ui-avatars.com/api/?background=3b86cb&color=fff&rounded=true&name=${encodeURIComponent(user.value.name || 'TH')}`;
        });
        /*
        useIntervalFn(async () => {
            // Update Auth0 token
            let token = await auth0.getAccessTokenSilently();
            siteStore.accessToken = token;
            //console.log('Updated token:', token?.substring(0,10));
            //await getAccessTokenSilently();
        }, (process.env.NODE_ENV == 'production' ? 3600 : 21600) * 1000); // 1 hour, 6 hours
        */
        onMounted(() => {
            //siteStore.load();

            /*
            let token = { ...authState };
            if(token && token.body) {
                delete token.body['access_token'];
                delete token.body['client_id'];
                delete token.body['id_token'];
                if(token) console.log('\u26D1 authState', token);
            }
            */

            siteStore.getAccessToken().then(() => {
                siteStore.load().then((res) => {
                    items.value.find((n) => n.id == 2).label = `Version: ${res.build}`;

                    if(!Cookies.get('_wpadmin')) setAdminBarCookie();
                }).catch((err) => {
                    if(console.log.hide) console.log({err});
                    //console.error('Get version:', err);
                }).finally(() => {
                    // Remove "code" querystring from URL to prevent errors on refresh
                    const params = new URLSearchParams(window.location.search);
                    //if(params.get('code')) window.location = window.location.origin;
                    if(params.get('code')) window.history.pushState({}, null, window.location.origin);
                });
            });

            console.log('\u26D4 AppSettings:', appSettings);
        });
        function toggle(event) {
            menu.value.toggle(event);
        }
        function profile(authState) {
            if(console.log.hide) console.log(authState);
            items.value.find((n) => n.id == 1).label = user.value.name;
            /*
            adminService.getVersion().then((response) => {
                this.items.find((n) => n.id == 2).label = `Version: ${response.build}`;
            }).catch((err) => {
                console.error('get version failed:', err);
            }).finally(() => {
                // Remove "code" querystring from URL to prevent errors on refresh
                const params = new URLSearchParams(window.location.search);
                //if(params.get('code')) window.location = window.location.origin;
                if(params.get('code')) window.history.pushState({}, null, window.location.origin);
            });
            */

            // Create local storage variable to store user object
            if(user.value.name) localStorage.setItem('thm-auth-user', JSON.stringify(user.value));

            return items.value;
        }
        function filterItemsByRole(menuArray) {
            let items = [];
            let _user = localStorage.getItem('thm-auth-user') ? JSON.parse(localStorage.getItem('thm-auth-user')) : user.value;
            for(let idx = 0; idx < menuArray.length; idx++) {
                if(!menuArray[idx].roles || userHasAccessToRoles(menuArray[idx].roles, _user)) {
                    // Recursively check roles
                    if(menuArray[idx].items && Array.isArray(menuArray[idx].items))
                        menuArray[idx].items = filterItemsByRole(menuArray[idx].items);
                    // If the menu items roles are null or if the roles are specified and
                    // the user has access, add the menu item to the array.
                    items.push(menuArray[idx]);
                }
            }
            return items;
        }

        function filterItemsBySite(menuItems) {
            let items = [];
            for(let idx = 0; idx < menuItems.length; idx++) {
                if(!menuItems[idx].sites || menuItems[idx].sites.includes(appSettings.siteId)) {
                    // Recursively check site IDs
                    if(menuItems[idx].items && Array.isArray(menuItems[idx].items))
                        menuItems[idx].items = filterItemsBySite(menuItems[idx].items);
                    // If the menu items roles are null or if the siteId exists
                    // in the array of siteIds, add the menu item to the array.
                    items.push(menuItems[idx]);
                }
            }
            return items;
        }

        function reloadPage() {
            location.reload();
        }

        function parseName(fullName) {
            if(!fullName) return { first: null, last: null };
            fullName = fullName.trim();
            if(!fullName.includes(' ')) return { first: fullName, last: null };
            let space = fullName.indexOf(' ');
            return {
                first: fullName.substring(0, space),
                last: fullName.substring(space+1)
            }
        }

        function setAdminBarCookie() {

            // Consier wrapping this in getTokenSilently
            if(user.value) {
                let name = parseName(user.value.name);
                let isAdminOrHigher = userHasAccessToRole('Admin');
                let cookie = {
                    site_name: appSettings.name,
                    user: {
                        id: 0,
                        sub: user.value.sub,
                        show_toolbar: true,
                        display_name: user.value.name,
                        first_name: name.first,
                        last_name: name.last,
                        username: user.value.email,
                        nickname: user.value.name,
                        email: user.value.email,
                        gravatar: md5(user.value.email),
                        roles: userRoles,
                        capabilities: {
                            publish_posts: true,
                            publish_pages: isAdminOrHigher,
                            edit_posts: true,
                            edit_pages: isAdminOrHigher,
                            manage_options: isAdminOrHigher,
                            edit_theme_options: isAdminOrHigher,
                            edit_users: isAdminOrHigher
                        }
                    }
                }

                let in7hours = new Date(new Date().getTime() + 7 * 60 * 60 * 1000);
                let cookieDomain = process.env.NODE_ENV == 'development' ? null : '.' + appSettings.domain;
                Cookies.set('_wpadmin', window.btoa(JSON.stringify(cookie)), { domain: cookieDomain, path: '/', expires: in7hours });
            }
        }

        return {
            appSettings,
            authState,
            isAuthenticated,
            items,
            menu,
            profile,
            profilePhoto,
            reloadPage,
            roleBasedMenuItems,
            siteStore,
            toggle,
            user,
            vueAppProfile
        };
    }
}
</script>

<style lang="scss" scoped>
header.navbar {
    img.logo {
        width: 100px;
        max-height: 30px;

        &.redstate {
            width: 110px;
        }
        &.bearingarms {
            width: 140px;
            margin-top: -5px;
        }
    }
}
div.update-notice {
    a {
        color: #6d5100;
        text-decoration: underline;
    }
}
.profile-avatar {
    background-color: #000 !important;
    max-height: 36px !important;
    max-width: 36px !important;
}
</style>
<style lang="scss">
.profile-avatar {
    img {
        &:hover {
            transition: opacity 0.2s linear;
            opacity: 0.9;
        }
    }
}
</style>
