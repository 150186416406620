<template>
    <Button :label="props.label" :icon="props.icon" :class="props.class ? props.class : 'p-button-secondary p-button-outlined'" @click="openModal" v-if="props.tag == 'button'" />
    <a href="#" @click="openModal" v-else>{{ props.label }}</a>

    <!-- Modal: Log Entry Listing -->
    <Dialog v-model:visible="showModal" :dismissableMask="true" @hide="hideModal" :keepInViewport="false">
        <template #header>
            <h3 v-if="props.filter.referenceId">Reference ID: {{ props.filter.referenceId }}</h3>
            <h3 v-else-if="props.filter.userName">{{ props.filter.userName }}</h3>
            <h3 v-else>Log</h3>
        </template>

        <table class="table table-striped" v-if="logs.length">
            <thead>
                <tr>
                    <th scope="col" v-show="!props.filter.referenceId">Ref ID</th>
                    <th scope="col" v-show="!props.filter.userName">Name</th>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Message</th>
                    <th scope="col">Type</th>
                    <th scope="col"></th>
                    <th scope="col" v-show="props.showLink"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="log in logs" :key="log">
                    <th scope="row" v-show="!props.filter.referenceId">{{ log.referenceId }}</th>
                    <td v-show="!props.filter.userName">{{ log.userName }}</td>
                    <td>{{ formatDateTime(log.timestamp) }}</td>
                    <td>{{ log.message }}</td>
                    <td>{{ log.logTypeName }}</td>
                    <td>
                        <div v-show="log.content">
                            <a href="#" @click="displayDiff(log)" v-if="log.original">Diff</a>
                            <a href="#" @click="displayContent(log)" v-else>View</a>
                        </div>
                    </td>
                    <td v-show="props.showLink">
                        <a :href="`/log/${log.logId}`" target="_blank" v-show="props.showLink"><i class="fa-regular fa-arrow-up-right-from-square"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>

        <p class="fst-italic text-muted pe-5" v-else>No log entries found for specified context.</p>

        <template #footer>
            <Paginator :totalRecords="totalRecords" :rows="props.filter.pageSize || 20" @page="fetchLogs($event)" />
        </template>
    </Dialog>

    <!-- Modal: View -->
    <Dialog v-model:visible="showContentModal" :dismissableMask="true" :style="{width: '80vw'}">
        <template #header>
            <h4>Log ID: {{ activeLog.logId }}, {{ activeLog.userName }}</h4>
        </template>

        <Diff
            mode="unified"
            theme="light"
            :language="activeLog.language || 'plaintext'"
            :prev="activeLog.content"
            :current="activeLog.content"
        />

        <Diff
            :mode="splitView ? 'split' : 'unified'"
            theme="light"
            language="html"
            :prev="activeLog.htmlBefore || activeLog.htmlAfter"
            :current="activeLog.htmlAfter"
            v-if="activeLog.htmlBefore || activeLog.htmlAfter"
        />
    </Dialog>

    <!-- Modal: Diff -->
    <Dialog v-model:visible="showDiffModal" :dismissableMask="true" :maximizable="true" :style="{width: '95vw'}">
        <template #header>
            <h4>Diff &ndash; Log ID: {{ activeLog.logId }}, {{ activeLog.userName }}</h4>
        </template>

        <Diff
            :mode="splitView ? 'split' : 'unified'"
            theme="light"
            :language="activeLog.language || 'plaintext'"
            :prev="activeLog.original"
            :current="activeLog.content"
        />

        <div v-if="activeLog.htmlBefore || activeLog.htmlAfter">
            <h5 class="mb-0">Body</h5>

            <Diff
                :mode="splitView ? 'split' : 'unified'"
                theme="light"
                language="html"
                :prev="activeLog.htmlBefore || activeLog.htmlAfter"
                :current="activeLog.htmlAfter"
            />
        </div>

        <template #footer>
            <InputSwitch v-model="splitView" /> <div class="ms-1 split-view-label">Split View</div>
        </template>
    </Dialog>

</template>

<script>
import { ref, onBeforeMount } from 'vue';
import AdminService from '@/service/AdminService';
import { useToast } from 'primevue/usetoast';
//import VueDiff from 'vue-diff';
import { formatDateTime } from '../utils/ThmDateTimeUtils';

export default {
    name: 'LogViewerThm',
    components: {
        //VueDiff
    },
    props: {
        filter: {
            type: Object,
            required: true
        },
        tag: {
            type: String,
            default: 'a'
        },
        label: {
            type: String,
            default: 'View Log'
        },
        icon: {
            type: String
        },
        class: {
            type: String
        },
        showLink: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const toast = useToast();
        const showModal = ref(false);
        const showDiffModal = ref(false);
        const showContentModal = ref(false);
        const adminService = new AdminService();
        const logs = ref([]);
        const activeLog = ref({});
        const totalRecords = ref(0);
        const splitView = ref(false);

        onBeforeMount(() => {
            //if(!props.filter.referenceId && !props.filter.userName && !props.filter.logTypeId) {
            if(props.filter.referenceId == null && props.filter.userName == null && props.filter.logTypeId == null) {
                console.error('At least one of the following properties may not be null: logTypeId, referenceId, userName');
            }
        });

        // Fetch the next page of logs
        async function fetchLogs(e) {
            let pageIndex = e.page || 0;
            return await adminService.getLogs({ ...props.filter, pageIndex: pageIndex }).then((res) => {
                logs.value = res;
                if(res.length) totalRecords.value = res[0].totalRows;
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Episodes', detail: err.message || err, life: 3000 });
            });
        }

        // Open the modal when View Logs button/link clicked
        function openModal() {
            fetchLogs({ page: 0 }).then(() => {
                showModal.value = true;
            });
        }

        // Clear the log list when the modal is closed
        function hideModal() {
            showModal.value = false;
            logs.value = [];
        }

        // Show Content
        function displayContent(log) {
            activeLog.value = log;
            showContentModal.value = true;
        }

        // Show Diff
        function displayDiff(log) {
            activeLog.value = log;
            showDiffModal.value = true;
        }

        return { activeLog, displayContent, displayDiff, fetchLogs, formatDateTime, hideModal, logs, openModal, props, showModal, showDiffModal, showContentModal, splitView, totalRecords };
    }
}
</script>

<style lang="scss" scoped>
pre.scrollable-content {
    //max-width: 80vw;
    overflow-y: scroll;
}
div.split-view-label {
    vertical-align: top;
    display: inline-block;
    margin-top: 0.1em;
}
</style>
