import BaseService from './BaseService';

export default class BlockCopyService extends BaseService {
    async getBlockCopies(params) {
        return await this.apiClient.get('/api/blockcopy', { params }).then((res) => res.data);
    }

    async getBlockCopy(id) {
        return await this.apiClient.get(`/api/blockcopy/${id}`).then((res) => res.data);
    }

    async deleteBlockCopy(id) {
        return await this.apiClient.delete(`/api/blockcopy/${id}`).then((res) => res.data);
    }
    async saveBlockCopy(blockcopy) {
        return await this.apiClient.post('/api/blockcopy', blockcopy).then((res) => res.data);
    }
}
