<template>
    <div class="liveblog-group" ref="liveBlogGroup">
        <AvatarGroup class="p-avatar-group" v-if="liveBlogStore.groupMembers?.length">
            <template v-for="(user, idx) in liveBlogStore.groupMembers" :key="user.authorId">
                <Avatar :image="getAvatar(user)" size="large" shape="circle" :title="user.nickName || user.name || 'Unknown'" v-if="idx+1 <= limit" />
            </template>
            <Avatar :label="'+' + (liveBlogStore.groupMembers?.length - 1)" shape="circle" size="large" class="liveblog-group-overflow" v-if="liveBlogStore.groupMembers?.length > limit" :title="getOverflowNames(liveBlogStore.groupMembers)" />

            <!--
            <template v-for="(user, idx) in demoData" :key="user.authorId">
                <Avatar :image="user.imageUrl" size="large" shape="circle" :title="user.nickName || user.name || 'Unknown'" v-if="idx+1 <= limit" />
            </template>
            <Avatar label="+28" shape="circle" size="large" class="liveblog-group-overflow" v-if="demoData?.length > limit" :title="getOverflowNames(demoData)" />
            -->
        </AvatarGroup>
        <div v-else-if="liveBlogStore.groupMembers != null">No participants found...</div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useLiveBlogStore } from '@/stores/LiveBlogStore';
import { getInitials } from '@/utils/ThmStringUtils';
import AvatarGroup from 'primevue/avatargroup';
import md5 from 'md5';

export default {
    name: 'LiveBlog_Groups',
    props: {
    },
    components: {
        AvatarGroup,
    },
    setup(props) {
        const liveBlogStore = useLiveBlogStore();
        const demoData = ref([]);
        const liveBlogGroup = ref(null);

        const limit = 12;

        onMounted(() => {
            if(console.log.hide) console.log({generateRandomUsers});
            //demoData.value = generateRandomUsers(28);
        });

        function getAvatar(author = {}) {
            if(author?.imageObject) {
                return author.imageObject.startsWith('http') ? author.imageObject : `https://media.townhall.com/cdn/hodl/${author.imageObject}`;
            } else {
                let authorName = author.nickName || author.name;
                let initials = getInitials(authorName).toLowerCase() || 'an';
                return `https://s.gravatar.com/avatar/${author?.email ? md5(author?.email) : 'null'}?s=150&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${initials}.png`;
            }
        }

        function generateRandomUsers(count = 30) {
            const genders = ["men", "women"];
            const urls = [];

            for (let i = 0; i < count; i++) {
                const gender = genders[Math.floor(Math.random() * genders.length)];
                const number = Math.floor(Math.random() * 30) + 1;
                const url = `https://randomuser.me/api/portraits/${gender}/${number}.jpg`;
                urls.push({
                    imageUrl: url,
                    name: 'User Name ' + i,
                    nickName: 'User Name ' + i
                });
            }

            return urls;
        }

        function getOverflowNames(arr) {
            return arr
                .slice(limit !== null ? limit : 0) // skip the first X items if limit is set
                .map(item => item.nickName || item.name)
                .filter(name => name) // filter out empty names
                .join('\n');
        }

        return {
            demoData,
            getAvatar,
            getOverflowNames,
            limit,
            liveBlogGroup,
            liveBlogStore,
            props,
        };
    },
};
</script>
