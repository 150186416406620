<template>
    <div class="row mb-3">
        <div class="col">
            <div class="card card-border-color card-border-color-primary" v-show="hasLoaded">
                <div class="card-header card-header-divider">Author Profile</div>

                <div class="profile-editor-component p-3" v-if="authorStore.author.authorId">
                    <ProfileEditorComponent :store="authorStore" :isLoading="isLoading" />

                    <p>
                        <Button :label="authorStore.author.authorId ? 'Save' : 'Add User'" @click="saveUser()" x-title="Save (Ctrl+S or ⌘S)" :disabled="authorStore.author.authorId == 0 || authorStore.currentAuthor.authorId != authorStore.author.authorId" />
                        <LogViewerThm label="Change Log" :filter="{ logTypeId: 3, referenceId: authorStore.author.authorId, ...logFilterDefaults }" v-if="authorStore.author.authorId" class="p-button-secondary p-button-outlined ms-2" tag="button" />
                    </p>

                </div>

                <div v-else class="p-3">
                    <div class="alert alert-warning" role="alert">
                        Unable to find author associated with current logged in user.
                    </div>
                    <p>An administrator may need to add an author for the following e-mail address: <code>{{ user.email }}</code></p>
                    <Button :label="'Add Author'" @click="addAuthorClick" v-show="userHasAccessToRole('Admin')" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import ProfileEditorComponent from '@/components/ProfileEditor.vue';
import LogViewerThm from '@/components/LogViewerThm.vue';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
//import { saveUserSettings } from '@/utils/ThmUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useAuthorStore } from '@/stores/AuthorStore';
import { useRouter } from 'vue-router';
import { subMonths } from 'date-fns';
import { useToast } from 'primevue/usetoast';
import useAuth0 from '@/use/Auth0';

export default {
    name: 'Profile',
    components: {
        LogViewerThm,
        ProfileEditorComponent,
    },
    setup() {
        const authorStore = useAuthorStore();
        const isLoading = ref(false);
        const router = useRouter();
        const toast = useToast();
        const { user } = useAuth0();

        const hasLoaded = ref(false);

        const logFilterDefaults = {
            dateStart: new Date(formatDateTime(subMonths(new Date, 6))) // Default start date is 6 months ago
        };

        onBeforeMount(() => {
            authorStore.getCurrentAuthor(user.value).then(() => {
                authorStore.author = authorStore.currentAuthor;
                hasLoaded.value = true;
            });

        });

        function addAuthorClick() {
            router.push({ name: 'AuthorManager' });
        }

        function saveUser() {
            if(!authorStore.author.firstName && !authorStore.author.lastName && !authorStore.author.nickName) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Author First/Last Name or Display Name required', life: 4000 });
                return;
            }

            // Save personalized settings
            //saveUserSettings(authorStore.userConfig);

            // Save user
            if(authorStore.currentAuthor.authorId == authorStore.author.authorId) {
                authorStore.author.siteGroup = 0;
                authorStore.save().then((res) => {
                    if (console.log.hide) console.log('res', res);
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Author Saved', life: 3000 });
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Saving Author', detail: err.message || err, life: 3000 });
                });
            } else {
                toast.add({ severity: 'error', summary: 'Author Mismatch', detail: 'Current author does not match logged in user', life: 4000 });
            }
        }

        return {
            addAuthorClick,
            authorStore,
            hasLoaded,
            isLoading,
            logFilterDefaults,
            saveUser,
            user,
            userHasAccessToRole,
        }
    }
};
</script>
