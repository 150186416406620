<template>
    <div class="thmim-container" :id="uuid">
        <Dialog header="Image Manager" v-model:visible="showModal" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{ width: '90vw' }" class="thmim-modal thm-dialog-footer-center">
            <TabView @tab-click="activeTab = $event.index" :activeIndex="activeTab">
                <!-- TAB: Search -->
                <TabPanel header="Search">

                    <div class="input-group mb-1">
                        <input type="search" class="form-control" placeholder="Keywords..." aria-label="Search" aria-describedby="thmim_search" x-ref="image_search_input" v-model="searchKeywords" @keyup.enter="submitSearch" />
                        <select class="form-select" name="sort" style="max-width: 8rem;" title="Rank By" v-model="searchSort">
                            <option value="date">Date</option>
                            <option value="relevance" selected>Relevance</option>
                        </select>
                        <button class="btn btn-primary thmim-button-primary" type="button" id="thmim_search_btn" @click="submitSearch">Search</button>
                    </div>

                    <p class="fst-italic ps-1" v-if="lsImageLibray.searchHistory && lsImageLibray.searchHistory.length > 0">
                        <span v-for="(history, index) in lsImageLibray.searchHistory" :key="history" class="me-1 badge bg-secondary search-history-item" :class="{ 'd-none d-md-inline': index > 2 }">
                            <a @click="setSearchKeyword(history)">{{ history }}</a>
                        </span>
                    </p>

                    <div class="container-fluid">
                        <div class="row" v-if="images.length > 0">
                            <div v-for="image in images" :key="image['_id']" class="thmim-result col-xs-12 col-sm-6 col-md-3 py-0">
                                <div class="card mb-4 me-2">
                                    <!-- Result: Collapsed -->
                                    <div class="thmim-result__card-body thmim-result__short" v-show="!image.showDetail">
                                        <img :src="getMimeOrResizedImageFn('https://media.townhall.com/cdn/hodl/' +  image['_source'].path, '400x230')" class="card-img-top" :alt="image['_source'].title" @click="chooseImage(image)" />
                                        <a class="far fa-caret-circle-down float-end mt-2 me-1" @click="toggleDetail(image)"></a>
                                        <div class="card-text text-muted p-2"><date-format :datetime="image['_source'].datePublished" format="MMM d, yyyy" :days-ago="3" /> - <span :style="{ color: getLicenseColor(image['_source'].license.id) }">{{ image['_source'].license.name }}</span></div>
                                    </div>
                                    <!-- Result: Expanded -->
                                    <div class="thmim-result__card-body thmim-result__detail position-absolute" v-show="image.showDetail">
                                        <img :src="getMimeOrResizedImageFn('https://media.townhall.com/cdn/hodl/' +  image['_source'].path, '400x230')" class="card-img-top" :alt="image['_source'].title" @click="chooseImage(image)" />
                                        <a class="far fa-caret-circle-up float-end mt-2 me-1" @click="toggleDetail(image)"></a>
                                        <div class="card-text text-muted p-2">
                                            <date-format :datetime="image['_source'].datePublished" format="MMM d, yyyy" :days-ago="3" /> - <span :style="{ color: getLicenseColor(image['_source'].license.id) }">{{ image['_source'].license.name }}</span>
                                            <div v-if="image['_source'].title">
                                                <div class="card-text__label">Title</div>
                                                {{ ellipsis(image['_source'].title, 200) }}
                                            </div>
                                            <div v-if="image['_source'].description">
                                                <div class="card-text__label">Description</div>
                                                {{ ellipsis(image['_source'].description, 300) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <p class="py-3 fst-italic">No results found matching search keywords.</p>
                        </div>
                    </div>
                </TabPanel>

                <!-- TAB: Upload -->
                <TabPanel header="Upload" v-if="appSettings.features.imageLibrary.minimumUploadRole && userHasAccessToRoleFn(appSettings.features.imageLibrary.minimumUploadRole)">
                    <UploadTab :isValid="uploadIsValid" :hasImage="uploadHasImage" :command="uploadCmd" @after-upload="afterUpload" @close-modal="closeModal" />
                </TabPanel>
            </TabView>

            <template #footer v-if="activeTab == 0">
                <Button label="" icon="pi pi-info-circle" severity="secondary" class="p-button-text float-end btn-help mt-3" @click="toggleHelp" />
                <!--<Paginator :totalRecords="totalRows" :rows="16" @page="onPage($event)" />-->
                <PaginationThm v-model="currentPage" :totalRecords="totalRows" :pageSize="resultsPerPage" @page="onPage($event)" v-show="images.length > 0" :showTotalRecords="true" class="mt-2" />
            </template>
            <!--
            <template #footer v-else>
                <div class="mt-2">
                    <Button label="Clear" class="p-button-secondary p-button-outlined" @click="uploadCmd = 'delete'" :disabled="!uploadHasImage" />
                    <Button label="Save &amp; Insert" class="p-button-primary" @click="uploadButton" :disabled="!uploadIsValid" />
                </div>
            </template>
            -->
        </Dialog>

        <!-- Preview -->
        <div v-show="mode != 'froala'">
            <span class="far fa-times float-end2 thmim-remove" @click="removeImage" v-if="(typeof currentImage == 'string' && currentImage) || (currentImage && typeof currentImage.url != 'undefined' && currentImage.url)"></span>
            <div class="thmim-preview text-center mb-2" v-if="currentConfig.showPreview">
                <img :src="currentImage && typeof currentImage.url != 'undefined' && currentImage.url ? currentImage.url : previewImage" class="thmim-preview__image" @click="openModal" />
            </div>
            <div class="mb-2" v-else>
                <div v-if="currentImage" class="input-group">
                    <input type="text" class="form-control" :value="currentImage && typeof currentImage.url != 'undefined' ? currentImage.url : null" :readonly="true" />
                    <Button label="" icon="pi pi-trash" class="p-button-secondary p-button-outlined" @click="removeImage" />
                    <Button label="Choose" class="p-button-secondary" :id="uuid + '_button'" @click="openModal" />
                </div>
                <div v-else class="input-group">
                    <input type="text" class="form-control" placeholder="https://" :readonly="true" />
                    <Button label="Choose" class="p-button-secondary" :id="uuid + '_button'" @click="openModal" />
                </div>
            </div>
        </div>
        <input type="hidden" :id="uuid + '_value'" :value="currentImage && typeof currentImage.url != 'undefined' && currentImage.url ? currentImage.url : ''" ref="hiddenVaue" />
    </div>

    <Sidebar v-model:visible="showHelp" position="right" class="p-sidebar-md">
        <ImageLibrarySearchHelp />
    </Sidebar>
</template>

<script>
import UploadTab from './_Upload.vue';
import ImageService from '@/service/ImageService';
import DateFormat from '@/components/DateFormat.vue';
import PaginationThm from '@/components/PaginationThm.vue';
import ImageLibrarySearchHelp from './SearchHelp.vue';
import ellipsize from 'ellipsize';
import Dialog from 'primevue/dialog';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { getMimeOrResizedImage, resizeImageUrl } from '@/utils/ThmImageUtils';
import { uuid } from 'vue-uuid';

export default {
    name: 'ImageLibraryComponent',
    emits: ['update:modelValue'],
    inject: ['appSettings'],
    data() {
        return {
            imageService: new ImageService(),
            uuid: 'image_manager_' + uuid.v4().replaceAll('-', ''),
            currentImage: null,
            previewImage: '/images/image-placeholder-ctr.gif',
            currentConfig: {
                showPreview: true
            },
            licenses: [],
            nullImage: {
                url: null,
                //credit: null
            },
            uploadIsValid: false,
            uploadHasImage: false,
            uploadCmd: null,
            showModal: false,
            showHelp: false,
            searchKeywords: null,
            searchSort: 'date',
            activeTab: 0,
            images: [],
            totalRows: 0,
            resultsPerPage: 16,
            lsImageLibray: {},
            currentPage: 0
        }
    },
    props: {
        modelValue: [Object, String],
        config: Object,
        id: String,
        mode: String
    },
    components: {
        Dialog,
        DateFormat,
        ImageLibrarySearchHelp,
        PaginationThm,
        UploadTab
    },
    methods: {
        buttonDisabled() {
            this.$toast.add({ severity: 'info', summary: 'Disabled', detail: "This doesn't work", life: 3000 });
        },
        openModal() {
            this.fetchImages(0);
            this.showModal = true;
        },
        removeImage() {
            this.currentImage = typeof this.modelValue == 'object' ? this.nullImage : '';
            this.$emit('update:modelValue', this.currentImage);
        },
        fetchImages(page) {
            this.currentPage = page;
            // Sorting strictSortByDate: true reduces relevance - better to search with date decay
            //this.mediaService.imageSearch({ keywords: this.searchKeywords, pageSize: this.resultsPerPage, offset: this.resultsPerPage * page, strictSortByDate: true }).then((res) => {
            //this.imageService.search({ searchText: this.searchKeywords, pageSize: this.resultsPerPage, pageIndex: page, decay: 0.1, logicalOperator: "AND" }).then((res) => {
            this.imageService.search({ searchText: this.searchKeywords, domain: this.appSettings.domain, pageSize: this.resultsPerPage, pageIndex: page, sort: this.searchSort, logicalOperator: "AND" }).then((res) => {
                this.images = res.hits?.hits;
                //this.totalRows = !isNaN(res.count) || res.count > 1000 ? 1000 : res.count;
                this.totalRows = isNaN(res.hits.total.value) ? 0 : res.hits.total.value;
                let maxQuerySize = 50000;
                if(this.totalRows > maxQuerySize) this.totalRows = maxQuerySize;
            }).catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'Error Searching Images', detail: err.message || err });
            });
        },
        submitSearch() {
            this.updateSearchHistory(this.searchKeywords);
            this.fetchImages(0);
        },
        toggleDetail(image) {
            //this.images.forEach(img => { if(img != image) img.showDetail = false }); // Collapse all the other detail boxes
            image.showDetail = !image.showDetail;
        },
        toggleHelp() {
            this.showHelp = !this.showHelp;
        },
        chooseImage(image) {
            if(image['_source']) {
                // Image search
                this.currentImage = {
                    id: image['_id'],
                    score: image['_score'],
                    url: `https://media.townhall.com/cdn/hodl/${image['_source'].path}`,
                    license: image['_source'].license,
                    //url: getImagePrefixByLicenseId(image['_source'].license.id, image['_source'].path),
                    /*
                    location: image['_source'].path,
                    license: image['_source'].license.name,
                    licenseId: image['_source'].license.id,
                    */
                    ...image['_source']
                }
            } else {
                // Uploaded
                this.currentImage = image;
            }

            this.$emit('update:modelValue', this.getSelectedImageValue());
            //setTimeout(() => { this.showModal = false; }, 1000);
            this.showModal = false;
        },
        getSelectedImageValue() {
            // Return the same variable type that was passed to v-model (ie, image object or URL string)
            if(typeof this.modelValue == 'string') {
                return this.currentImage && typeof this.currentImage.url != 'undefined' ? this.currentImage.url : this.currentImage;
            } else {
                return this.currentImage;
            }
        },
        onPage(e) {
            this.fetchImages(e.page);
        },
        setModelValue() {
            this.currentConfig = Object.assign(this.currentConfig, this.config);
            if (this.modelValue) this.currentImage = this.modelValue;
            if (typeof this.currentImage === 'string') this.currentImage = { url: this.modelValue };
        },
        updateSearchHistory(keywords) {
            if(!keywords || !keywords.trim()) return;
            if(!this.lsImageLibray.searchHistory) this.lsImageLibray.searchHistory = [];

            if(this.lsImageLibray.searchHistory.includes(keywords.trim())) {
                // Move existing item to beginning: https://stackoverflow.com/a/23921775/3799374
                this.lsImageLibray.searchHistory.sort(function(x,y){ return x == keywords.trim() ? -1 : y == keywords.trim() ? 1 : 0; });
            } else {
                // Add to beginning:
                this.lsImageLibray.searchHistory.unshift(keywords.trim());
            }
            this.lsImageLibray.searchHistory = this.lsImageLibray.searchHistory.slice(0, 5);
            localStorage.setItem('adminImageLibray', JSON.stringify(this.lsImageLibray));
        },
        userHasAccessToRoleFn(role = null) {
            return userHasAccessToRole(role);
        },
        ellipsis(txt, leng) {
            return ellipsize(txt, leng);
        },
        setSearchKeyword(keywords) {
            this.searchKeywords = keywords;
            this.fetchImages(0);
        },
        getResizedImage(url, size) {
            return resizeImageUrl(url, size);
        },
        getMimeOrResizedImageFn(url, size, original) {
            return getMimeOrResizedImage(url, size, original);
        },
        /*
        uploadButton() {
            //console.log('uploadButton', this.currentImage);
            this.uploadCmd = 'submit';
            //
            this.currentImage = {
                url: `https://media.townhall.com/cdn/hodl/${image['_source'].path}`,
                title: '',
                description: '',
                credit: ''
            }
            //
            //console.log('this.getSelectedImageValue()', this.getSelectedImageValue());

            // Add code to insert image meta into database here

            this.currentImage.close = true;
            this.chooseImage(this.currentImage);
            /*
            this.currentImage.close = true;
            this.$emit('update:modelValue', this.getSelectedImageValue(true), (foo, done) => {
                console.log(foo, done);
            });
            this.$refs.hiddenVaue.dispatchEvent(event);
            //this.showModal = false;
        },
        */
        closeModal(close) {
            if(close) this.showModal = false;
        },
        afterUpload(uploadState) {
            //console.log('after-upload', uploadState);
            this.uploadIsValid = uploadState.valid;
            this.uploadHasImage = uploadState.hasImage;
            this.uploadCmd = uploadState.cmd;
            this.currentImage = uploadState;
            this.$emit('update:modelValue', this.getSelectedImageValue());
            this.closeModal(true);
            this.activeTab = 0;
        },
        getLicenseColor(licenseId) {
            let lic = this.licenses?.find(({ id }) => id == licenseId);
            return lic?.color ?? null;
        }
    },
    watch: {
        modelValue() {
            this.setModelValue();
            var event = new Event('change');
            //setTimeout(() => { this.$refs.hiddenVaue.dispatchEvent(event); }, 100);
            if(this.currentImage && typeof this.currentImage.url != 'undefined' && this.currentImage.url) {
                this.$refs.hiddenVaue.dispatchEvent(event); // Trigger change for Froala
            }
        }
    },
    mounted() {
        //this.$emit('update:modelValue', this.currentImage);
        //if(typeof this.modelValue === 'string') this.$emit('update:modelValue', {url: this.modelValue});
        if(this.id) this.uuid = this.id;
        this.setModelValue(); // This seems to have broken at some point?
        // Remember last search
        this.lsImageLibray = JSON.parse(localStorage.getItem('adminImageLibray')) || {};

        if(this.showModal) this.fetchImages(0);

        this.imageService.getImageLicenses().then((res) => {
            if(res && res.length) this.licenses = res;
        });
    }
}
</script>

<style lang="scss">
/* Field style */
div.thmim-container {
    .thmim-preview {
        //background-color: #F5F5F5;
        border: 2px dashed rgba(0, 0, 0, 0.1);
        min-height: 50px;
        cursor: pointer;

        &__image {
            max-width: 100%;
            max-height: 300px;
        }
    }

    .thmim-remove {
        background-color: #fff;
        border: 2px solid #666;
        color: #666;
        font-size: 16px;
        border-radius: 50%;
        position: absolute;
        padding: 3px 5.7px;
        width: 26px;
        height: 26px;
        vertical-align: middle;
        margin: 0.5em;
        float: left;
        cursor: pointer;
    }
}

/* Modal contents */
.thmim-modal {
    .thmim-button-primary {
        background-color: #2196f3;
        border: #2196f3;

        &:hover {
            background-color: darken(#2196f3, 6%);
            border: darken(#2196f3, 6%);
        }
    }
    span.search-history-item a {
        color: #fff;
        font-weight: 500;
        cursor: pointer;
    }
    .p-dialog-content {
        min-height: 50vh;
    }
    .thmim-pager {
        position: fixed;
        bottom: 0;
    }
    .thmim-result {
        padding: 1em;
        //width: 24%;
        min-height: 195px;

        img {
            cursor: pointer;
            min-height: 135px;
        }
        a.fa-caret-circle-up,
        a.fa-caret-circle-down {
            color: var(--primary-color);
            padding-top: 0.65em;
            cursor: pointer;
            padding: 0.1em;
        }
        &__card-body {
            .card-text {
                font-size: 0.8em;
                //min-height: 56px;
            }
        }
        /*
        &__short {
            .card-text {
                max-width: 207px;
                overflow: auto;
                display: inline-block;
                white-space: nowrap;
            }
        }
        */
        &__detail {
            background-color: var(--bluegray-100);
            z-index: 10;

            .card-text {
                &__label {
                    color: var(--bluegray-700);
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
