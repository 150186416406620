<template>
    <div class="revision">
        <Message v-if="!props.hidden && props.post && props.post.postId && props.post.postId > 0 && revision && revision.postId && checkIfDifferent()" :severity="'info'" icon="fa-regular fa-file-exclamation">An unsaved revision exists for this post. <a @click="view">View</a></Message>

        <Dialog v-model:visible="showDiffModal" :dismissableMask="true" :maximizable="true" :style="{width: '95vw'}" class="revision-dialog">
            <template #header>
                <h4>Revision: {{ revision.userName }}, {{ formatDateTime(revision.timestamp) }}</h4>
            </template>

            <h5 class="mb-0">Body <i class="ps-1 far fa-copy small cursor" @click="copy(JSON.parse(revision.content).body)" v-tooltip="'Copy revision HTML (right) to clipboard'"></i></h5>
            <Diff
                :mode="'split'"
                theme="light"
                language="html"
                :prev="pretty(props.post.body)"
                :current="pretty(JSON.parse(revision.content).body)"
            />

            <h5 class="mb-0">Fields <i class="ps-1 far fa-copy small cursor" @click="copy(compareAfter)" v-tooltip="'Copy revision fields (right) to clipboard'"></i></h5>
            <Diff
                :mode="'split'"
                theme="light"
                :language="'json'"
                :prev="compareBefore"
                :current="compareAfter"
            />

            <template #footer>
                <div class="mt-3 text-success float-start copied-text" v-show="copied">Copied to Clipboard</div>
                <Button class="mt-2" label="Restore" @click="restore()" />
            </template>
        </Dialog>
    </div>
</template>

<script>
//import { ref, onBeforeMount, onMounted, watch } from 'vue';
import { ref, computed, onMounted, watch } from 'vue';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useClipboard } from '@vueuse/core';
import PostService from '@/service/PostService';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import pretty from '@/../node_modules/pretty/index.js';

export default {
    name: 'RevisionComponent',
    props: {
        post: {
            type: Object,
            default() {
                return { postId: 0 }
            }
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const postService = new PostService();
        const { copy, copied } = useClipboard();
        const confirm = useConfirm();
        const toast = useToast();

        const revision = ref({ postId: 0 });
        //const revisionClone = ref({});
        const showDiffModal = ref(false);
        const currentPost = ref({});

        onMounted(() => {
            if(props.post.postId) {
                currentPost.value = { ...props.post };
                getRevision(props.post.postId);
                /*
                postService.getRevision(props.post.postId).then((res) => {
                    revision.value = res;
                }).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Fetching Revision', detail: err.message || err });
                });
                */
            }
        });

        watch(() => props.post, async (newPost) => {
            console.log('newPost', newPost.postId);
            if(currentPost.value.postId != newPost.postId) currentPost.value = newPost;
            await getRevision(newPost.postId);
        });

        const compareBefore = computed(() => {
            //let cloneObj = filterObject({ ...props.post });
            let cloneObj = filterObject({ ...currentPost.value });
            return JSON.stringify(cloneObj, null, 2);
        });

        const compareAfter = computed(() => {
            let cloneObj = filterObject(JSON.parse(revision.value.content || '{}'));
            return JSON.stringify(cloneObj, null, 2);
        });


        function filterObject(obj) {
            delete obj.dateModified;
            delete obj.body;
            delete obj.extendedProperties?.postStats;
            delete obj.userIdLock;
            delete obj.connectionId;
            obj.datePublished = formatDateTime(obj.datePublished);
            obj.dateCreated = formatDateTime(obj.dateCreated);
            return obj;
        }

        /*
        function getBodyHtml(html) {
            return pretty()
        }
        */

        function view() {
            /*
            revisionClone.value = JSON.parse(revision.value.content);
            delete revisionClone.value.dateModified;
            delete revisionClone.value.body;
            revisionClone.value = JSON.stringify(revisionClone.value, null, 2);
            */
            showDiffModal.value = true;
        }

        async function getRevision(postId) {
            return postService.getRevision(postId).then((res) => {
                if(res && res.postId) {
                    console.log('Revision found:', res);
                    revision.value = res;

                    revision.value = res;
                } else {
                    revision.value = { postId: 0 };
                }
                return res;
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Revision', detail: err.message || err });
            });
        }

        // Checks if the revision is actually different from the current post
        function checkIfDifferent() {
            let different = false;
            if(!revision.value) return false;
            let before = compareBefore.value.replaceAll(' ', '').replace(/(\r\n|\n|\r)/gm, ''); // Remove all spaces and line breaks
            let after = compareAfter.value.replaceAll(' ', '').replace(/(\r\n|\n|\r)/gm, ''); // Remove all spaces and line breaks
            different = before != after;
            //console.log('_different1', different);

            before = props.post.body;
            after = JSON.parse(revision.value.content || '{}').body;
            //console.log('_after', after);
            if(!different) different = before != after;
            //console.log('_different2', different);

            return different;
        }

        function restore() {
            confirm.require({
                message: 'Are you sure you want to restore this revision? Existing content for this post will be overwritten.',
                header: 'Confirm Restore',
                icon: 'pi pi-exclamation-triangle',
                //acceptClass: 'p-button-danger',
                accept: () => {
                    emit('restore', JSON.parse(revision.value.content));
                    revision.value = {};
                    showDiffModal.value = false;
                }
            });
        }

        return {
            checkIfDifferent,
            compareAfter,
            compareBefore,
            copied,
            copy,
            formatDateTime,
            pretty,
            props,
            restore,
            revision,
            //revisionClone,
            showDiffModal,
            view
        }
    }
}
</script>

<style lang="scss" scoped>
.revision {
    a {
        text-decoration: underline;
        color: #044868;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
.revision-dialog {
    .cursor {
        cursor: copy;
    }
}
</style>
