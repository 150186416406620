import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { parseISO, subHours } from 'date-fns';
import { getAppSettings } from '@/utils/ThmUtils';
import { formatDateTime } from '@/utils/ThmDateTimeUtils';
import { useTimeAgo } from '@vueuse/core';

/**
 * Determines if a live blog post is live
 * @param {Object} post The post object to check
 */
export function isLiveblogPostLive(post) {
    if (post.status !== 1) return false;

    let timeZone = 'America/New_York'; // Eastern Time
    let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Get publish date in local time
    let datePublishedObj = toZonedTime(
        fromZonedTime(parseISO(post.datePublished), timeZone),
        localTimeZone
    );

    // Get publish expires in local time
    let dateExpiresObj = post.dateExpires
        ? toZonedTime(
            fromZonedTime(parseISO(post.dateExpires), timeZone),
            localTimeZone
        )
        : null;

    let _now = new Date();

    if(datePublishedObj <= _now) {
        if (!dateExpiresObj || dateExpiresObj >= _now) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

/**
 * Decision Desk HQ resize event
 */
export function DdhqResize(event) {
    if (["https://e.ddhq.io", "https://embeds.ddhq.io"].includes(event.origin) && event.data.id && event.data.height) {
        document.getElementById(event.data.id).style.height = event.data.height + "px";
    }
}

/**
 * Decision Desk HQ resize event
 */
export function DdhqReceiveMessage(event) {
    if (event.data?.event !== "resize-polling-widget") return;
    if (!event.data?.height || event.data.height < 10) return;

    const iframe = document.getElementById("client-delegate");

    if (iframe) {
        iframe.height = `${event.data.height}px`;
    }
}

/**
 * Build frontend or preview link
 */
export function getPermalink(post, preview = false) {
    if(!post) return '';
    let appSettings = getAppSettings();
    let url = appSettings.webroot[process.env.NODE_ENV] + `/liveblog/${formatDateTime( post.datePublished, 'y/MM/dd' )}/${post.urlSlug}-n${post.postId}`;

    if(preview) {
        url += '?preview=' + new Date().getTime();
    }
    return url;
}

/**
 * Check if a date is within the last 24 hours
 */
export function isWithinLast24Hours(dt) {
    let givenDate = parseISO(dt);
    let now = new Date();

    // Calculate the date 24 hours ago from now
    let twentyFourHoursAgo = subHours(now, 24);

    // Check if the given date is within the interval
    //return isWithinInterval(givenDate, { start: twentyFourHoursAgo, end: now });
    return givenDate >= twentyFourHoursAgo;
}

/**
 * Convert an Eastern Time (from the database) to local time
 */
export function easternDateTimeToLocal(easternTimeString) {
    if(console.log.hide) console.log(fromZonedTime, toZonedTime)
    let easternTimeZone = 'America/New_York';
    let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let easternDate = fromZonedTime(easternTimeString, easternTimeZone);
    let localDate = toZonedTime(easternDate, localTimeZone);
    return localDate;
}

/**
 * Stop SignalR connection
 */
export async function stopConnection(connection) {
    try {
        await connection.stop();
        console.log('%cSignalR Disconnected: /api/hub/liveblog', 'color: #F4A460');
    } catch (err) {
        console.error('Error while stopping connection: ' + err);
    }
}

/**
 * Calculate moment (ie, time ago) based on provided eastern date/time, adjusted for locale
 */
export function dateTimeMoment(dt, fmt = 'MMMM d, y h:mm a') {
    if(isWithinLast24Hours(dt)) {
        let moment = useTimeAgo(easternDateTimeToLocal(dt));
        return moment.value;
    }
    return formatDateTime(dt, fmt);
}
