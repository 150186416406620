<template>
    <section class="card" id="author_filter_bar">
        <div class="card-body container-fluid">
            <div class="row filter-row g-2">
                <div class="col-xs-12 col-sm-6 col-lg-8">
                    <!--<InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search... (Ctrl+K or ⌘K)" v-on:keyup.enter="updateFilter" autofocus />-->
                    <InputText class="form-control" v-model="filter.searchText" id="SearchText" placeholder="Search..." v-on:keyup.enter="updateFilter" autofocus />
                </div>
                <!--
                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <SiteGroupSelect v-model="filter.siteGroup" />
                </div>
                -->
                <div class="col-xs-12 col-sm-6 col-lg-2" v-show="appSettings.features.authors.authorTypeFieldRole">
                    <Dropdown id="AuthorTypeFilter" v-model="filter.authorTypeId" :options="authorStore.types" optionLabel="name" optionValue="authorTypeId" placeholder="Type" class="d-flex" :showClear="true" />
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-2">
                    <Button label="" icon="far fa-search" iconPos="right" @click="updateFilter" />
                    <Button label="" icon="far fa-times" iconPos="right" severity="secondary" outlined class="ms-2" @click="clearFilter" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, inject, onBeforeMount, onMounted } from 'vue';
import { useAuthorStore } from '@/stores/AuthorStore';
//import SiteGroupSelect from '@/components/SiteGroupSelectClient.vue';
//import { useMagicKeys, whenever } from '@vueuse/core';

export default {
    name: 'AuthorsFilterBar',
    components: {
        //SiteGroupSelect,
    },
    setup() {
        const appSettings = inject('appSettings');
        const authorStore = useAuthorStore();
        const router = useRouter();
        const route = useRoute();
        //const keys = useMagicKeys();

        const filter = ref({});

        //whenever(keys['Ctrl+K'], () => document.getElementById('SearchText').select());
        //whenever(keys['Meta+K'], () => document.getElementById('SearchText').select());

        // Before Mount
        onBeforeMount(() => {
            // Load author types for dropdown
            authorStore.load();
        });

        // Mounted
        onMounted(() => {
            // Set filter bar based on query string values
            const query = Object.assign({}, route.query);
            filter.value = query;
            // Convert authorTypeId type from string to number (required by Dropdown)
            if (filter.value.authorTypeId) {
                filter.value.authorTypeId = parseInt(filter.value.authorTypeId);
            }
        });

        function updateFilter() {
            router.push({ query: filter.value });
        }

        function clearFilter() {
            filter.value = {};
            router.push({ query: filter.value });
        }

        return {
            appSettings,
            authorStore,
            clearFilter,
            filter,
            updateFilter,
        };
    },
};
</script>

<style lang="scss" scoped>
#author_filter_bar {
    input.p-inputtext {
        height: 42px;
    }
}
</style>
