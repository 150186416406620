//import axios from 'axios';
import BaseService from './BaseService';
import { deviceIsOS } from '@/utils/ThmDeviceUtils';

export default class PostService extends BaseService {
    async getPosts(params) {
        if (!deviceIsOS('macOS')) console.log('getPosts:', params);
        //params.expandedCategories = true;
        return await this.apiClient.get('/api/posts', { params }).then((res) => res.data);
    }
    async getPostsCount(params) {
        //console.log('getPostsCount:', params);
        return await this.apiClient.get('/api/posts/count', { params }).then((res) => res.data);
    }
    async getPost(id = 0) {
        console.log('getPost:', id);
        return await this.apiClient.get(`/api/posts/${id}`).then((res) => res.data);
    }
    async savePost(post) {
        console.log('Saving Post:', post);
        return await this.apiClient.post(`/api/posts`, post).then((res) => res.data);
    }
    async deletePost(id) {
        console.log('Deleting Post:', id);
        return await this.apiClient.delete(`/api/posts/${id}`).then((res) => res.data);
    }
    async getPostTypes() {
        return await this.apiClient.get('/api/posts/types').then((res) => res.data);
    }
    async postLockStatus(id) {
        console.log('Getting Post Lock Status');
        return await this.apiClient.get(`/api/posts/lockstatus/${id}`).then((res) => res.data);
    }
    async toggleLockPost(status) {
        console.log('Setting post lock for ', status.postId, status.userIdLock);
        return await this.apiClient.post(`/api/posts/togglelockpost`, status).then((res) => res.data);
    }
    async getPostStats(post) {
        // Fetches stats for a string. post syntax = { "body": "Hello World" }
        console.log('Fetching stats for post:', post);
        return await this.apiClient.post('/api/posts/stats', post).then((res) => res.data);
    }
    async getVipPostStats() {
        // Fetches VIP stats for front page of CMS
        return await this.apiClient.get('/api/posts/vipstats').then((res) => res.data);
    }
    /* REVISIONS */
    async getRevision(postId) {
        return await this.apiClient.get(`/api/log/revision/${postId}`).then((res) => res.data);
    }
    async saveRevision(revision) {
        if(!revision.postId) return revision;
        return await this.apiClient.post('/api/log/revision', revision).then((res) => res.data);
    }
    async deleteRevisions(postId) {
        return await this.apiClient.delete(`/api/log/revision/${postId}`).then((res) => res.data);
    }

}
