<template>
    <div class="row mb-3">
        <div class="col-sm-8">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Froala Editor</div>

                <div class="card-body">
                    <p class="mb-0">In order to use this component, it must be loaded locally:</p>
                    <code>import FroalaText from '@/components/FroalaText';</code>

                    <!-- Type: Basic -->
                    <h5 class="pt-4 card-header-divider">Type: Basic</h5>
                    <div class="fst-italic">A layout that contains a basic toolbar.</div>

                    <code class="d-block mt-1 mb-3"> &lt;FroalaText v-model=&quot;content&quot; :config=&quot;config&quot; type=&quot;basic&quot; /&gt; </code>

                    <FroalaText v-model="sampleContentShort" :config="config" type="basic" />

                    <Button label="Submit" class="mt-3" @click="submitButton()" />

                    <!-- Type: Standard -->
                    <h5 class="pt-4 card-header-divider">Type: Standard</h5>
                    <div class="fst-italic">A standard preset with more options for things like articles. Syntax highlighting (CodeMirror) is enabled by default.</div>

                    <code class="d-block mt-1 mb-3"> &lt;FroalaText v-model=&quot;content&quot; :config=&quot;config&quot; type=&quot;standard&quot; /&gt; </code>

                    <FroalaText v-model="sampleContentLong" :config="config" type="standard" />

                    <!-- Type: Custom w/ Inline Overrides -->
                    <h5 class="pt-4 card-header-divider">Type: Custom with Change Event Handler</h5>
                    <div class="fst-italic">All default preset <a href="https://froala.com/wysiwyg-editor/docs/options/" target="_blank">options</a> can be overridden. In this example, we also enable syntax highlighting (CodeMirror).</div>

                    <code class="d-block mt-1 mb-3"> &lt;FroalaText v-model=&quot;content&quot; :config=&quot;myCustomConfig&quot; @change=&quot;valueChanged&quot; /&gt; </code>

                    <h6>JavaScript</h6>

                    <code>
                        <pre>
export default {
    data() {
        return {
            content: '&lt;p&gt;Hello world!&lt;p&gt;',
            myCustomConfig: {
                toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple'],
                heightMin: 200,
                codeMirror: true // Enable syntax highlighting
            }
        }
    },
    methods: {
        valueChanged(editor) {
            console.log(`valueChanged(${editor.id}):`, editor.html.get());
        }
    }
}</pre
                        >
                    </code>

                    <p>Begin typing below and you will see the on <code>change</code> event triggering in the browser console:</p>

                    <FroalaText v-model="sampleContentHelloWorld" :config="{ toolbarButtons: ['html', 'bold', 'italic', 'formatOLSimple'], heightMin: 200, codeMirror: true }" @change="valueChanged" />
                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card card-border-color card-border-color-primary">
                <div class="card-header card-header-divider">Properties</div>

                <div class="card-body">
                    <p>All properties are optional, though v-model is needed for reactivity.</p>
                    <ul>
                        <li><code>modelValue</code> <span class="text-muted fst-italic">(v-model)</span></li>
                        <li>
                            <code>type</code>: <span class="text-success">String</span> - Config preset
                            <ul>
                                <li><span class="monospace">null</span> (default)</li>
                                <li>"basic"</li>
                                <li>"standard"</li>
                            </ul>
                        </li>
                        <li>
                            <code>config</code>: <span class="text-success">Object</span> <a href="https://froala.com/wysiwyg-editor/docs/options/" target="_blank" class="far fa-external-link" style="font-size: 80%"></a> - Override options specified by <span class="monospace">type</span> preset.
                        </li>
                    </ul>
                </div>

                <div class="card-header card-header-divider">Events</div>

                <div class="card-body">
                    <ul>
                        <li><code>change</code> - Triggered when content is changed (debounced).</li>
                        <li><code>keyup</code> - Similar to <code>change</code>, but fired whenever a key is pressed.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FroalaText from '@/components/FroalaText.vue';

export default {
    name: 'ModalDemo',
    inject: ['appSettings'],
    data() {
        return {
            sampleContentShort: '<p>Almost before we <strong>knew it</strong>, we had <em>left</em> the ground.</p><ul><li>One</li><li>Two</li><li>Three</li></ul>',
            sampleContentLong:
                '<p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p><img src="https://s.abcnews.com/images/Entertainment/GTY_chips_1_kab_150323_16x9_992.jpg" title="Eric Estrada" /></p>',
            sampleContentHelloWorld: '<p>Hello world!</p>',
            config: {
                // Optional. Config override, if any, goes here
                placeholderText: 'Edit your content here',
                key: this.appSettings.keys.froala,
            },
        };
    },
    components: {
        FroalaText,
    },
    methods: {
        valueChanged(editor) {
            console.log(`valueChanged(${editor.id}):`, editor.html.get());
        },
        submitButton() {
            this.$toast.add({ severity: 'success', summary: 'Submit Clicked', detail: 'See browser console for output of editor contents.', life: 3000 });
            console.log('Value:', this.sampleContentShort);
        },
    },
};
</script>

<style lang="scss" scoped>
.monospace {
    font-family: monospace;
}
</style>
