<template>
    <div class="card card-border-color card-border-color-primary liveblog-timeline mb-3 d-none d-md-block">
        <div class="card-header card-header-divider">
            <Button icon="pi pi-plus" severity="secondary" class="btn-add p-button-tiny float-end" iconPos="right" @click="toggleAddItemForm()" v-tooltip="'Add Unlinked Item'" v-show="userHasAccessToRole('Editor')" />
            Timeline
            <SpinnerThm class="spinner me-2 float-end" size="1.3em" v-show="loading" />
        </div>

        <BlockUI class="card-body" :blocked="props.isBlocked">

            <div v-if="showAddUnlinkedForm" class="mb-2 pb-3 border-bottom">
                <div class="mb-2">
                    <FroalaThm v-model="timelineItem.body" id="Body" :config="froalaConfig" />
                </div>
                <div class="mb-2">
                    <label for="DateCreated" class="form-label required fw-500">Date/Time</label>
                    <Calendar id="DateCreated" v-model="timelineItemDateTime" :showTime="true" :show-icon="true" :manual-input="false" :showButtonBar="true" hourFormat="12" dateFormat="mm/dd/yy" />
                </div>

                <Button :label="timelineItem.id ? 'Save' : 'Add Item'" @click="addUnlinkedItem" autofocus class="me-2" />
                <Button label="Cancel" severity="secondary" outlined @click="showAddUnlinkedForm = false;" autofocus />
            </div>

            <template v-if="liveBlogStore.timeline?.length">
                <Draggable class="drag-area" :list="liveBlogStore.timeline" :group="{ name: 'timeline' }" item-key="id" @change="itemDragged" :disabled="!userHasAccessToRole('Editor')">
                    <template #item="{ element }">
                        <div :key="element.id">
                            <div :key="element.id" class="border-bottom py-2 liveblog-featured-item">
                                <div class="row g-0">
                                    <div class="col-1 text-center align-self-center">
                                        <i class="fa-solid fa-bars"></i>
                                    </div>
                                    <div class="col pe-2 align-self-center">
                                        <div v-html="element.body"></div>
                                        <small class="text-muted text-capitalize">{{ dateTimeMoment(element.dateCreated) }}</small>

                                        <OverlayPanel :ref="setElementRef('timelineItem_' + element.threadId)">
                                            <!--<PostThread :thread="getThread(element.threadId)" class="mb-3" :isPreview="true" :key="element.threadId" @clear="toggleOverlay(element.threadId, $event)" />-->
                                            <PostThread :thread="thread" class="mb-3" :isPreview="true" :key="element.threadId" @clear="toggleOverlay(element.threadId, $event)" />
                                        </OverlayPanel>
                                    </div>
                                    <div class="col-auto" v-if="userHasAccessToRole('Editor')">
                                        <Button icon="pi pi-search" severity="secondary" outlined @click="toggleOverlay(element.threadId, $event)" text v-if="element.threadId" />
                                        <SplitButton label="Save" menuButtonIcon="pi pi-ellipsis-v" :model="getActions(element)" class="hide-dropdown-button" severity="secondary" text />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Draggable>
            </template>
            <div v-else-if="liveBlogStore.timeline != null">
                Nothing here...
            </div>
        </BlockUI>

    </div>

    <Dialog header="Insert Linked Timeline Item" v-model:visible="showDialog" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '95vw', '640px': '100vw' }" :style="{width: '50vw'}">
        <div class="mb-2">
            <label for="Body" class="form-label required fw-500">Caption</label>
            <FroalaThm v-model="timelineItem.body" id="Body" :config="froalaConfig" />
        </div>

        <PostThread :thread="timelineThread" class="mb-0" :isPreview="true" :key="timelineThread.id" @clear="showDialog = false;" />

        <template #footer>
            <Button label="Save" @click="saveLinkedItem" autofocus />
            <Button label="Cancel" severity="secondary" outlined @click="showDialog = false" autofocus />
        </template>
    </Dialog>
</template>

<script>
import { ref, computed, reactive } from 'vue';
import { formatDateTime, formatDateTimeET } from '@/utils/ThmDateTimeUtils';
import { userHasAccessToRole } from '@/utils/ThmAuthUtils';
import { useLiveBlogStore } from '@/stores/LiveBlogStore';
import { dateTimeMoment } from '@/utils/ThmLiveBlogUtils';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import LiveBlogService from '@/service/LiveBlogService';
import OverlayPanel from 'primevue/overlaypanel';
import SpinnerThm from '@/components/SpinnerThm.vue';
import PostThread from './_Thread.vue';
import FroalaThm from '@/components/Froala/FroalaThm.vue';
import Draggable from 'vuedraggable';
import BlockUI from 'primevue/blockui';

export default {
    name: 'LiveBlog_Timeline',
    emits: [ 'change' ],
    props: {
        isBlocked: {
            type: Boolean,
            default: false
        }
    },
    components: {
        BlockUI,
        Draggable,
        FroalaThm,
        OverlayPanel,
        PostThread,
        SpinnerThm,
    },
    setup(props, { emit, expose }) {
        if(console.log.hide) console.log(emit);

        const liveBlogService = new LiveBlogService();
        const liveBlogStore = useLiveBlogStore();
        const confirm = useConfirm();
        const toast = useToast();

        const menu = ref();
        const loading = ref(false);
        const elementsRefs = reactive({});
        const showDialog = ref(false);
        const showAddUnlinkedForm = ref(false);
        const timelineItem = ref({});
        const timelineThread = ref({});
        const thread = ref({});

        const froalaConfig = {
            toolbarButtons: ['html', 'textColor', 'bold', 'italic', 'undo', 'redo', 'insertLink', 'clearFormatting'],
            colorsText: ['#C90110', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8'],
            charCounterCount: false,
            enter: 2, //FroalaEditor.ENTER_BR
            pastePlain: true,
            height: 75,
        }

        const timelineItemDateTime = computed({
            get() {
                return timelineItem.value.dateCreated == null ? null : new Date(timelineItem.value.dateCreated);
            },
            set(newValue) {
                timelineItem.value.dateCreated = newValue ? newValue.toLocaleString('en-US') : newValue;
            },
        });

        function getActions(item) {
            let actions = [
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        timelineItem.value = { ...item };
                        showAddUnlinkedForm.value = true;
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: async () => {
                        await confirmDeleteTimelineItem(item);
                    }
                },
            ];
            return actions;
        }

        /*
        async function getThread(threadId) {

            let item = liveBlogStore.threads.find(item => item.id === threadId);
            return thread.value;
        }
        */

        function toggleAddItemForm() {
            clearTimelineItem();
            showAddUnlinkedForm.value = !showAddUnlinkedForm.value;
        }

        function setElementRef(key) {
            return el => {
                if (el) {
                    elementsRefs[key] = el;
                }
            }
        }

        const toggleMenu = (event) => {
            menu.value.toggle(event);
        };

        const toggleOverlay = async (threadId, event) => {
            elementsRefs['timelineItem_' + threadId]?.toggle(event);
            thread.value = {};
            let item = liveBlogStore.threads.find(item => item.id === threadId);
            thread.value = item ? item : await liveBlogService.getThread(threadId);
        };

        function addLinkedItem(thread) {
            showDialog.value = true;
            timelineThread.value = thread;
            timelineItem.value = {
                postId: liveBlogStore.post.postId,
                threadId: thread.id,
                body: '',
                url: null,
                dateCreated: thread.datePublished
            };
        }
        expose({ addLinkedItem });

        async function saveLinkedItem() {
            if(!timelineItem.value.body?.trim()) {
                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Caption cannot be empty', life: 3500 });
                return;
            }
            showDialog.value = false;
            await liveBlogStore.saveTimelineItem(timelineItem.value);
            emit('change', true);
            clearTimelineItem();
        }

        async function addUnlinkedItem() {
            timelineItem.value = {
                postId: liveBlogStore.post.postId,
                threadId: null,
                ...timelineItem.value
            };

            showAddUnlinkedForm.value = false;
            await liveBlogStore.saveTimelineItem(timelineItem.value);
            showDialog.value = false;
            emit('change', true);
            clearTimelineItem();
        }

        function clearTimelineItem() {
            timelineItem.value = { dateCreated: formatDateTimeET(null, 'MM/dd/yyyy hh:mm aaa') };
        }

        async function confirmDeleteTimelineItem(item) {
            confirm.require({
                message: 'Are you sure you want to delete this item?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    await liveBlogStore.deleteTimelineItem(item.id);
                    emit('change', true);
                },
                reject: () => {
                    // Do nothing
                },
            });
        }

        async function itemDragged(ev) {
            if(console.log.hide) console.log(ev);
            loading.value = true;
            await liveBlogStore.saveTimeline();
            // toast.add({ severity: 'success', summary: 'Success', detail: 'Highlighted Stories Saved', life: 3000 });
            emit('change', true);
            loading.value = false;
        }

        return {
            addUnlinkedItem,
            dateTimeMoment,
            froalaConfig,
            formatDateTime,
            getActions,
            //getThread,
            itemDragged,
            liveBlogStore,
            loading,
            menu,
            props,
            saveLinkedItem,
            setElementRef,
            showDialog,
            showAddUnlinkedForm,
            thread,
            timelineItem,
            timelineItemDateTime,
            timelineThread,
            toggleAddItemForm,
            toggleMenu,
            toggleOverlay,
            userHasAccessToRole,
        };
    },
};
</script>
