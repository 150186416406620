<template>
    <div class="row mb-3">
       <div class="col-sm-12 col-md-12">
          <div class="card card-border-color card-border-color-primary">

               <form class="needs-validation" novalidate>
                  <div class="mb-3">
                        <div class="hstack gap-2  bg-white p-2">
                           <div class="me-2">
                            <Calendar id= "DateFrom" v-model="filter.fromDate" :class="dateFromRequired" v-on:update:model-value="filter.fromDate" :showButtonBar="true" :showIcon="true" placeholder="Date"  class="me-2" style="max-width: 155px" @date-select="dateCheck()" /><span class="required"/>&nbsp;to
                            <Calendar id= "DateTo"  v-model="filter.toDate" :class="dateToRequired" v-on:update:model-value="filter.toDate" :showButtonBar="true" :showIcon="true" placeholder="Date" class="me-2" style="max-width: 155px" @date-select="dateCheck()" /><span class="required"/>&nbsp;&nbsp;&nbsp;
                            <Dropdown id="TimePeriod" v-model="filter.timePeriod"  @change="onChange()" optionLabel="label" :options="timePeriods" optionValue="value" placeholder="Time Period" :showClear="true" class="me-2" /><span class="required"/>&nbsp;
                            <Dropdown id="PartnersList" v-model="filter.partnerId" :class="partnerRequired" :options="coRegStore.partnersList" optionLabel="name"  optionValue="partnerId" :showClear="true" placeholder="Select a Partner"  />&nbsp;<span class="required"/>&nbsp;
                            <Button label="Run" title="Run" icon="far fa-search" class="ms-2  p-button" @click="getPartnersStats" />
                            <Button label="Reset" title="Reset" icon="far fa-times"  class="p-button ms-2  p-button-secondary" @click="clearFilter"  />
                           </div>
                           <div class="me-2">
                           </div>
                        </div>
                  </div>
               </form>
           </div>
      </div>
   </div>
   <div class="row mb-3">
        <div class="col-sm-12 col-md-12">
            <div class="card card-border-color card-border-color-primary report">
                <div class="card-header card-header-divider ">API Report</div>

                <div class="card-body">
                    <DataTable :value="coRegStore.partnersStats" ref="dt" stripedRows responsiveLayout="scroll">
                        <Column field="date" header="Date" exportHeader="Date">
                            <template #body="slotProps">
                                <date-format dateFormat="mm/dd/yy" :datetime="slotProps.data.date" />
                            </template>
                        </Column>
                        <Column field="partnerName" header="Partner"></Column>
                        <Column field="offerName" header="Offer" ></Column>
                        <Column field="duplicate" header="Duplicates" ></Column>
                        <Column field="netNew" header="Net New" ></Column>
                        <Column field="failedValidation" header="Failed Validation (bad email)" ></Column>
                        <Column field="processed" header="Processed" ></Column>
                        <Column field="total" header="Total" ></Column>
                    </DataTable>
                </div>


            </div>
        </div>
    </div>

</template>

<script>
 import { useCoRegStore } from '@/stores/CoRegStore';
//  import CoRegService from '@/service/CoRegService';
 import {  onBeforeMount, ref } from 'vue';
 import { useToast } from 'primevue/usetoast';
 import { formatDateTime } from '@/utils/ThmDateTimeUtils';
 import DateFormat from '@/components/DateFormat.vue';
//  import { isEmptyOrNull } from '@/utils/ThmStringUtils';

export default {

    components: {
        DateFormat
    },
    setup(){
        //sevice setup
        // const coRegService = new CoRegService();

        const coRegStore = useCoRegStore();


        const filter = ref({
            fromDate: null,
            toDate: null,
            timePeriod: 0,
            partnerId: 0,
            siteId: 0,
            siteGroup : 128,
            title : null,
            pageIndex: 0,
            pageSize: 0,
            sortColumn: null,
            sortDirection: null,
            dataView: null,
            isSearch: false,
            campaignId: 0,
            offerId: 0
        });
        const timePeriods = [
            { label: 'Today', value: '1' },
            { label: 'Last 7 Days', value: '2' },
            { label: 'Month to Date', value: '3' },
            { label: 'Year to Date', value: '4' },

        ];
        const dateFromRequired = ref('');
        const dateToRequired = ref('');
        const partnerRequired = ref('');

        const partnersStats = [];

        const toast = useToast();

        onBeforeMount(() => {
            coRegStore.getPartnersList();
            console.log("partnerRequired: " +partnerRequired.value);


        });
        async function clearFilter() {
            filter.value.fromDate = null;
            filter.value.toDate = null;
            filter.value.timePeriod = 0;
            filter.value.partnerId = 0;
            coRegStore.partnersStats =[];
            partnerRequired.value = '';
            dateFromRequired.value = '';
            dateToRequired.value = '';
            console.log("partnerRequired: " +partnerRequired.value);

        }
        function onChange() {

           var today = new Date(new Date().setDate(new Date().getDate()));
        //    const timePeriod = filter.value.timePeriod;

            switch(true){

                case (filter.value.timePeriod == 4):   // YTD
                filter.value.fromDate = new Date(today.getFullYear(), 0,   1);
                filter.value.toDate = today;
                    break;

                case (filter.value.timePeriod == 3): // MTD
                filter.value.fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
                filter.value.toDate = today;
                    break;

                case (filter.value.timePeriod == 2): // Last 7 days
                filter.value.fromDate = new Date(new Date().setDate(new Date().getDate()-7));
                filter.value.toDate = today;
                    break;

                case (filter.value.timePeriod == 1): // Today
                filter.value.fromDate = today;
                filter.value.toDate = today;
                    break;

                default:
                    filter.value.fromDate = null;
                    filter.value.toDate = null;

            }


        }

        function dateCheck() {
            console.log("fromDate: " + filter.value.fromDate);
            console.log("toDate: " + filter.value.toDate)
            if ((filter.value.fromDate != null) & (filter.value.toDate != null)) {
                filter.value.timePeriod = null;
            }
        }

        async function getPartnersStats() {

            let invalidFields = [];
            var requiredFields = [filter.value.fromDate, filter.value.toDate,  filter.value.partnerId],
            isFormInvalid = false;

            if(filter.value.fromDate == null) {
                isFormInvalid = true;
                dateFromRequired.value = 'p-invalid';
                invalidFields.push('Date From');

            } else {
                dateFromRequired.value = '';
            }
            if(filter.value.toDate == null) {
                isFormInvalid = true;
                dateToRequired.value = 'p-invalid';
                invalidFields.push('Date To');

            } else {
                dateToRequired.value = '';
            }
            if(filter.value.partnerId == 0 ) {
                isFormInvalid = true;
                partnerRequired.value = 'p-invalid';
                invalidFields.push('Partner');

            } else {
                partnerRequired.value = '';
            }
            requiredFields.forEach((elm) => {

                if (!elm) isFormInvalid = true;
            });
            if (isFormInvalid) {

                toast.add({ severity: 'error', summary: 'Invalid fields', detail: 'Please enter all required fields: ' + invalidFields.join(', '), life: 5000 });
                return;
            }

            filter.value.timePeriod = 4;








             await coRegStore.getPartnersStats(
               filter.value
             )
             .then((res) => {
                if(res.length == 0){
                    toast.add({ severity: 'success', summary: 'No Results found', detail: 'No Results found', life: 4000 });

                    return;
                }
                coRegStore.partnersStats = res;
                console.log(res);

             }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Fetching Stats', detail: err.message || err });
             }).finally(() => {});
             console.log("partnerRequired: " + partnerRequired.value);

            return partnersStats;

        }


        return {  coRegStore, DateFormat, timePeriods, formatDateTime, dateCheck, getPartnersStats,filter, clearFilter, onChange, partnerRequired, dateFromRequired, dateToRequired};
    }


}
</script>
<style lang="scss" scoped>
.report{
 min-height:300px;
}
</style>
